import React from 'react';
import PropTypes from 'prop-types';
import ServiceLists from './';

const TreatmentLists = ({ offices }) => {
  return (
    <ServiceLists
      offices={offices}
      collection='scheduledTreatment'
      offering='treatment'
    />
  );
};

TreatmentLists.propTypes = {
  offices: PropTypes.array.isRequired
};

export default TreatmentLists;
