import _ from 'lodash';
import React, { Component } from 'react';
import ChangeFive9SkillForm from './ChangeFive9SkillForm';
import ChangeFive9SkillNameForm from './ChangeFive9SkillNameForm';
import CreateFive9SkillForm from './CreateFive9SkillForm';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import SelectField from '../../../components/SelectField';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../services/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfficeFive9DataClick,
  dialogEditOfficeFive9DataItem
} from '../../../actions';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';

const EDIT_OPTIONS = [
  { id: 1, label: 'Edit Current Skill Name' },
  { id: 2, label: 'Select Different Skill' },
  { id: 3, label: 'Create New Skill' }
];

class DialogEditOfficeFive9Data extends Component {
  state = { editOption: null };

  onClose = () => {
    this.props.dialogEditOfficeFive9DataClick(!this.props.open);
    this.props.dialogEditOfficeFive9DataItem(null);
    this.setState({ editOption: null });
  };

  renderFive9Form = () => {
    const { availableSkills, initialValues, item } = this.props;
    const { editOption } = this.state;

    switch (editOption) {
      case 1:
        return (
          <ChangeFive9SkillNameForm
            initialValues={initialValues}
            item={item}
            onClose={this.onClose}
          />
        );
      case 2:
        return (
          <ChangeFive9SkillForm
            availableSkills={availableSkills.filter(s => {
              if (typeof s.five9Account !== 'string') return true; // this is to short circuit and default to include if five9Account isn't set for some reason
              if (item.key === 'answering') {
                return s.five9Account === 'inbound';
              } else {
                return s.five9Account === 'recall';
              }
            })}
            initialValues={initialValues}
            item={item}
            onClose={this.onClose}
          />
        );
      case 3:
        return (
          <CreateFive9SkillForm
            initialValues={initialValues}
            item={item}
            onClose={this.onClose}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { classes, editOptionValue, fullScreen, item, open } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              Edit Five9{' '}
              {item && item.key && capitalizeFirstLetter(item && item.key)}{' '}
              Skill
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent
          className={classes.dialogContent}
          style={{ paddingTop: 10 }}
        >
          <Field
            name='editOption'
            component={SelectField}
            selected={editOptionValue === null ? false : true}
            label='Edit Skill Option'
            type='text'
            options={
              item &&
              item.key &&
              !t(item, `data.five9.skills.${item.key}`).safeObject
                ? _.filter(EDIT_OPTIONS, o => o.id !== 1)
                : EDIT_OPTIONS
            }
            optionKey='id'
            optionText='label'
            optionValue='id'
            onChange={e =>
              this.setState({
                editOption: Number(e.target.value)
              })
            }
            margin='none'
            textFieldContainerClass={classes.dialogTextField}
          />
          {this.renderFive9Form()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const {
    dialogEditOfficeFive9DataOpen: open,
    dialogEditOfficeFive9DataItem: item
  } = state;
  const selector = formValueSelector('DialogEditOfficeFive9Data');
  let availableSkills = item && item.skills ? item.skills : [];
  let initialValues = {};

  if (item && item.data) {
    const { skillName, five9 } = item.data;

    initialValues = {
      [item.key]: five9 ? five9.skills[item.key] : null,
      skillName,
      changedSkillName: skillName
    };
  }

  return {
    availableSkills,
    editOptionValue: selector(state, 'editOption'),
    initialValues,
    item,
    open
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeFive9DataClick,
    dialogEditOfficeFive9DataItem
  }),
  reduxForm({
    enableReinitialize: true,
    form: 'DialogEditOfficeFive9Data'
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeFive9Data);
