import React from 'react';
import Card from '@material-ui/core/Card';
import CountUp from 'react-countup';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const DashboardCard = props => {
  const { classes, elevation } = props;

  return (
    <Paper className={classes.root} elevation={elevation}>
      <Card
        className={classes.accentIconRoot}
        style={{ backgroundColor: props.accentColor }}
        elevation={8}
      >
        <Typography variant='h3' style={{ color: 'white' }}>
          <CountUp duration={1} end={props.stat} separator=',' decimals={0} />
        </Typography>
      </Card>
      <div className={`${classes.section} ${classes.titleContainer}`}>
        <div className={classes.titleSubcontainer}>
          <Typography
            variant='subtitle2'
            color='primary'
            style={{
              fontSize: '14px'
            }}
          >
            {props.icon}
            <br />
            {props.titleText}
          </Typography>
        </div>
      </div>
      <div className={`${classes.section} ${classes.statContainer}`}>
        <Typography
          variant='button'
          style={{ color: '#999999', fontSize: '14px' }}
        >
          {props.secondStatText}:{' '}
          <span className={classes.subCount}>
            <CountUp
              duration={1}
              end={props.secondStat}
              separator=','
              decimals={0}
            />
          </span>
        </Typography>
      </div>
      <div className={`${classes.section} ${classes.subtitleContainer}`}>
        <div className={classes.subtitleSubcontainer}>
          <Typography
            variant='button'
            color='primary'
            style={{ fontSize: '12px' }}
          >
            {props.subtitleText}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    position: 'relative',
    width: '100%'
  },
  accentIconRoot: {
    width: '145px',
    height: '105px',
    position: 'absolute',
    top: '15px',
    left: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: 'white',
      fontSize: '40px'
    }
  },
  section: {
    padding: '10px'
  },
  titleContainer: {
    textAlign: 'right',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '0px auto',
      display: 'block'
    }
  },
  titleSubcontainer: {
    width: '25%',
    marginLeft: 'auto',
    '& svg': {
      fontSize: '18px'
    }
  },
  statContainer: {
    textAlign: 'right',
    height: 80,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
    // [theme.breakpoints.down('xs')]: {
    //   width: '90%',
    //   margin: '0px auto',
    //   display: 'block',
    // },
  },
  subCount: {
    // fontSize: theme.spacing.unit * 3,
    // color: theme.palette.primary.main
  },
  subtitleContainer: {
    padding: '5px 10px',
    borderTop: '1px solid #bfbfbf',
    textAlign: 'left',
    height: '20px',
    width: '88%',
    margin: '0px auto',
    display: 'block'
  }
});

export default withStyles(styles, { theme: true })(DashboardCard);
