import React, { useContext } from 'react';
import helpers from '../../../helpers';
import TeamInformation from './TeamInformation';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../contexts';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'typy';
import { styles } from './styles';
import DeleteTeamButton from './DeleteTeamButton';
import DialogDeleteTeam from './DialogDeleteTeam';

const Details = ({ classes, team, sitemapPath }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToEdit = helpers.allowedTo('edit', sitemapPath, userGroups);
  return (
    <Paper elevation={2} className={classes.detailsPaper}>
      <TeamInformation team={team} allowedToEdit={allowedToEdit} />
      <DeleteTeamButton team={team} showEdit={allowedToEdit} />
      <DialogDeleteTeam />
    </Paper>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  team: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(Details);
