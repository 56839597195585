import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, withStyles } from '@material-ui/core';
import { styles } from './styles';

const Reports = ({ classes, office }) => {
  if (office) {
    return (
      <Paper className={classes.detailsPaper}>
        <div className={classes.officeInfoRoot}>
          <div>
            <h4>
              {`
                Click below to view reports for 
                ${office.officeInformation.name}
              `}
            </h4>
          </div>
          <div>
            <a
              href={`https://portal.getcallforce.com/${office.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                className={classes.button}
              >
                View In Portal
              </Button>
            </a>
          </div>
        </div>
      </Paper>
    );
  }
  return null;
};

export default withStyles(styles, { withTheme: true })(Reports);
