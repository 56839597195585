import { createMuiTheme } from '@material-ui/core/styles';

const createCustomTheme = () => {
  const customTheme = createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: '#696969'
      },
      secondary: {
        main: '#055f5b',
        lighter: '#4b9c99'
      },
      tertiary: {
        main: '#EDB33E'
      },
      text: {
        primary: '#696969',
        white: '#ffffff'
      },
      success: {
        main: '#4CAF50'
      },
      warning: {
        main: '#FFEB3B'
      },
      danger: {
        main: '#F44336'
      },
      onboarding: {
        main: '#ff9900'
      }
    },
    containers: {
      paper: {
        height: {
          default: '65vh',
          min: '65vh'
        },
        margin: '8px auto 0px auto',
        width: {
          default: '65%',
          lg: '90%',
          md: '100%',
          sm: '100%'
        },
        content: {
          padding: {
            default: 32,
            xs: '32px 16px'
          }
        }
      }
    },
    spacing: {
      appBarHeight: 64,
      appBarHeightSm: 56,
      headingHeight: 40
    },
    textField: {
      width: '100%',
      margin: '16px auto 8px auto'
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '8px'
        }
      },
      MuiExpansionPanel: {
        expanded: {
          '&:first-child': {
            marginTop: 'none'
          },
          '&:last-child': {
            marginBottom: 'none'
          }
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#696969'
          }
        }
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: '2px solid #696969'
          }
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: 8
        }
      },
      MuiNotchedOutline: {
        root: {
          '&$focused': {
            borderColor: '#696969',
            borderWidth: '1px'
          }
        }
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderRadius: '8px'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8
        }
      },
      MuiOutlinedInput: {
        inputMarginDense: {
          paddingTop: 12,
          paddingBottom: 12
        }
      },
      MuiInputLabel: {
        outlined: {
          '&$marginDense': {
            transform: 'translate(14px, 14px) scale(1)'
          }
        },
        shrink: {
          transform: 'translate(0, -1.5px) scale(0.75)'
        }
      }
    }
  });

  customTheme.containers.paper.variants = {
    keyValueData: {
      dataRow: {
        display: 'flex',
        marginBottom: 24
      },
      dataLabel: {
        width: '20%',
        marginRight: 5,
        '& > div:first-child': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '& > p:first-child': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        [customTheme.breakpoints.down('sm')]: {
          width: '30%'
        }
      },
      dataContent: {
        width: '80%',
        '& svg': {
          fontSize: 12,
          marginRight: 4
        },
        [customTheme.breakpoints.down('sm')]: {
          width: '70%'
        }
      },
      dataLoading: {
        display: 'inline-block',
        height: 15,
        backgroundColor: '#DDD',
        width: '90%'
      }
    }
  };

  customTheme.overrides.MuiSnackbarContent = {
    root: {
      [customTheme.breakpoints.up('md')]: {
        borderRadius: 8
      }
    }
  };

  customTheme.overrides.MuiTablePagination = {
    toolbar: {
      '& > span': {
        [customTheme.breakpoints.down('xs')]: {
          display: 'none'
        }
      },
      [customTheme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
      }
    },
    input: {
      [customTheme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    selectRoot: {
      [customTheme.breakpoints.down('xs')]: {
        marginRight: '8px'
      }
    },
    actions: {
      [customTheme.breakpoints.down('xs')]: {
        marginLeft: '20px'
      }
    }
  };

  return customTheme;
};

const theme = createCustomTheme();

export default theme;
