export const addCredit = (firestore, credit) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await firestore.add({ collection: 'credits' }, credit);
      return resolve(res);
    } catch (e) {
      return reject(e);
    }
  });

export const addSignUpCredits = (
  firestore,
  signUpCredits,
  officeId,
  officeName,
  createdTime
) =>
  new Promise(async (resolve, reject) => {
    try {
      const promises = [];

      signUpCredits.forEach(credit => {
        promises.push(
          firestore.add(
            { collection: 'credits' },
            {
              client: officeId,
              clientName: officeName,
              createdTime,
              description: credit.description ? credit.description : '',
              immediateRefund: false,
              remaining: parseFloat(credit.value),
              status: 'available',
              title: credit.title ? credit.title : '',
              type: 'promotion',
              value: parseFloat(credit.value)
            }
          )
        );
      });

      const r = Promise.all(promises);
      return resolve(r);
    } catch (e) {
      return reject(e);
    }
  });
