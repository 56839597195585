/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import {
  FieldCheckbox,
  FieldDate,
  FieldPhone,
  FieldSelect,
  FieldText,
  FieldTime
} from '../../../../../components/Fields';
import { date, phone, required, time } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import ManageEntryFields from '../../components/ManageEntryFields';
import helpers from '../../../../../helpers';
import EmployeeFilteredSelect from '../../../../../components/EmployeeFilteredSelect';
import { validations as serviceValidation } from '../../../../../services/validation';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const WebsiteChatItemForm = ({
  classes,
  dialogData,
  manConnectData,
  dialogOnClose,
  route,
  item,
  employees,
  offices
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [doNotBillReasons, setDoNotBillReasons] = useState([]);
  const [mistakeReasons, setMistakeReasons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [validateError, setValidateError] = useState(null);
  const [scheduled, setScheduled] = useState(
    item ? !!item.appointmentDate : false
  );
  const {
    state: { auth, firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;
  const onSubmit = (values, { setSubmitting }) => {
    if (item && values.createdBy.employeeId) {
      let {
        createdBy: { employeeId, employeeFirstName, employeeLastName }
      } = values;
      let createdByName = `${employeeFirstName} ${employeeLastName}`;
      values.createdBy = employeeId;
      values.createdByName = createdByName;
    }
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    helpers
      .transformEntryDataForSave(firebase, auth, values)
      .then(data => data)
      .then(data => helpers.saveEntryData(firebase, data, 'websiteChat'))
      .then(() => {
        dialogOnClose(true);
        setError(null);
        setLoading(null);
        setSubmitting(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(null);
        setSubmitting(false);
      });
  };
  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };
  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );
  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(_.toLower(selectName))}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(_.toLower(selectName))}s
        </span>
      );
    }
    return vanityName || _.startCase(_.toLower(selectName));
  };
  const validations = {
    appointmentDate: v => helpers.composeFieldValidation(v, [required, date]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time]),
    lastVisitDate: date,
    office: required,
    patientName: required,
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    spanishSpEmp: required,
    type: required,
    doNotBillCategory: required,
    mistakeCategory: required,
    category: required
  };

  const generateNewCategoryNotes = (
    notesTemplate,
    previousCategory,
    currentNotes
  ) => {
    const offeringsCategories = categories;
    const notes = currentNotes;
    let isCustomNote = false;
    let newNote = '';

    if (!notes) {
      newNote = notesTemplate;
    }
    if (previousCategory) {
      const oldNoteTemplate = _.mapKeys(offeringsCategories, 'id')[
        previousCategory
      ].notesTemplate;

      if (notes.search(oldNoteTemplate) === -1) {
        isCustomNote = true;
      }

      if (isCustomNote && notes) {
        newNote = notes;
      }
      if (!isCustomNote) {
        const oldNote = notes.split(oldNoteTemplate);
        const oldNoteTrimmed = oldNote.map(string => string.trim());
        newNote = notesTemplate + ' ' + oldNoteTrimmed[1];
      }
    }
    if (!previousCategory && notes) {
      newNote = notesTemplate + ' ' + notes;
    }

    return newNote;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userAllowedToManage) {
          const doNotBillReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('doNotBill')
            .collection('reasons')
            .orderBy('value')
            .get();
          const mistakeReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('mistake')
            .collection('reasons')
            .orderBy('value')
            .get();
          const doNotBillReasonsDocs = [];
          const mistakeReasonsDocs = [];

          doNotBillReasonsQuerySnapshot.forEach(doc => {
            doNotBillReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          mistakeReasonsQuerySnapshot.forEach(doc => {
            mistakeReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          setDoNotBillReasons(doNotBillReasonsDocs);
          setMistakeReasons(mistakeReasonsDocs);
        }

        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .where('offering', '==', 'websiteChat')
          .get();

        const treatmentTypesQuerySnapshot = await firebase
          .firestore()
          .collection('options')
          .doc('scheduledTreatment')
          .collection('types')
          .orderBy('name')
          .get();
        const typesDocs = [];
        const categoriesDocs = [];

        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });

        treatmentTypesQuerySnapshot.forEach(doc => {
          typesDocs.push({ ...doc.data(), id: doc.id });
        });

        setCategories(categoriesDocs);
        setLoading(null);
      } catch (err) {
        setDoNotBillReasons(null);
        setMistakeReasons(null);
        setCategories(null);
        setLoading(null);
        setError(err.toString());
      }
    };
    fetchData();
  }, [firebase, userAllowedToManage]);

  const initialValues = dialogData
    ? dialogData
    : manConnectData
    ? manConnectData
    : {};

  const validate = value => {
    if (serviceValidation.required(value)) {
      setValidateError('Required');
      return true;
    } else {
      setValidateError(null);
      return false;
    }
  };

  return (
    <Formik initialValues={initialValues || {}} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          {dialogData && userAllowedToManage ? (
            <>
              <ManageEntryFields
                doNotBillReasons={doNotBillReasons}
                loading={loading}
                mistakeReasons={mistakeReasons}
                selectLabel={selectLabel}
                validations={validations}
                values={values}
              />
            </>
          ) : null}
          <Field name='reason.category' validate={validations.category}>
            {({ form, field }) => {
              return (
                <FieldSelect
                  margin='none'
                  form={form}
                  field={field}
                  loading={categories.length === 0}
                  disabled={categories.length === 0 || loading !== null}
                  label={selectLabel(categories, 'Category')}
                  options={_.sortBy(categories, 'category')}
                  optionKey='id'
                  optionText='category'
                  optionValue='id'
                  onChange={newCategory => {
                    if (!values.reason) {
                      values.reason = {};
                    }
                    if (newCategory === 'D9SienKEUH8XrHz1eVOj') {
                      setScheduled(newCategory);
                    }
                    if (newCategory !== 'D9SienKEUH8XrHz1eVOj') {
                      setScheduled(false);
                    }
                    if (newCategory !== '') {
                      if (values.reason) {
                        const newTemplate = _.filter(
                          categories,
                          category => category.id === newCategory
                        )[0].notesTemplate;

                        values.reason.notes = generateNewCategoryNotes(
                          newTemplate,
                          values.reason ? values.reason.category : null,
                          values.reason.notes
                        );
                      }
                    } else {
                      if (values.reason) {
                        values.reason.notes = '';
                      }
                    }
                  }}
                />
              );
            }}
          </Field>
          {scheduled ? (
            <>
              <Field
                name='patientName'
                label='Patient Name'
                variant='outlined'
                component={FieldText}
                validate={validations.patientName}
              />
              <div className={classes.row}>
                <Field
                  name='patientPhone'
                  label='Patient Phone'
                  variant='outlined'
                  component={FieldPhone}
                  disabled={loading !== null}
                  validate={validations.patientPhone}
                />
                <FieldCheckbox
                  checked={!!values.newPatient}
                  name='newPatient'
                  label='New Patient'
                  disabled={loading !== null}
                />
              </div>
            </>
          ) : (
            <div className={classes.row}>
              <Field
                name='patientName'
                label='Patient Name'
                variant='outlined'
                component={FieldText}
                validate={validations.patientName}
              />
              <Field
                name='patientPhone'
                label='Patient Phone'
                variant='outlined'
                component={FieldPhone}
                disabled={loading !== null}
                validate={validations.patientPhone}
              />
            </div>
          )}
          {!values.newPatient && scheduled ? (
            <Field
              name='lastVisitDate'
              label='Last Visit Date'
              variant='outlined'
              placeholder='mm/dd/yyyy'
              component={FieldDate}
              disabled={loading !== null}
              validate={validations.lastVisitDate}
            />
          ) : null}
          <Field
            name='office'
            loading={offices.length === 0}
            disabled={offices.length === 0 || loading !== null}
            label={selectLabel(offices, 'office')}
            options={offices}
            optionKey='id'
            optionText='officeInformation.name'
            optionValue='id'
            margin='none'
            component={FieldSelect}
            validate={validations.office}
          />
          {scheduled ? (
            <div className={classes.row}>
              <Field
                name='appointmentDate'
                label='Appointment Date'
                variant='outlined'
                placeholder='mm/dd/yyyy'
                component={FieldDate}
                disabled={loading !== null}
                validate={validations.appointmentDate}
              />
              <Field
                name='appointmentTime'
                label='Appointment Time'
                variant='outlined'
                placeholder='hh:mm'
                component={FieldTime}
                disabled={loading !== null}
                validate={validations.appointmentTime}
              />
            </div>
          ) : null}
          <FieldCheckbox
            checked={!!values.actionItem}
            name='actionItem'
            label='Mark as Action Item'
            disabled={loading !== null}
          />
          <FieldCheckbox
            checked={!!values.urgent}
            name='urgent'
            label='Mark as Urgent'
            disabled={loading !== null}
          />
          <FieldCheckbox
            checked={!!values.spanishSpEmpHelp}
            name='spanishSpEmpHelp'
            label='Spanish-Speaking Caller Helped'
            disabled={loading !== null}
          />
          {values.spanishSpEmpHelp ? (
            <Field
              name='spanishSpEmp'
              loading={employees.length === 0}
              disabled={employees.length === 0 || loading !== null}
              label={selectLabel(
                employees,
                'employee',
                'Spanish-Speaking Caller'
              )}
              options={employees}
              optionKey='id'
              optionText={['firstName', 'lastName']}
              optionValue='id'
              component={FieldSelect}
              validate={validations.spanishSpEmp}
            />
          ) : null}
          <Field
            name='reason.notes'
            label='Notes'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={FieldText}
          />
          {item ? (
            <Field name='createdBy' label='Created By' validate={validate}>
              {({ field }) => {
                return (
                  <EmployeeFilteredSelect
                    label='Created by Employee'
                    onChange={employee => {
                      setFieldValue(
                        field.name,
                        employee
                          ? {
                              employeeId: employee.id,
                              employeeFirstName: employee.firstName,
                              employeeLastName: employee.lastName
                            }
                          : ''
                      );
                    }}
                    previouslySelectedEmployeeId={item ? item.createdBy : null}
                    error={validateError}
                  />
                );
              }}
            </Field>
          ) : null}
          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

WebsiteChatItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  offices: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(WebsiteChatItemForm);
