import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import BaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import AnsweringIcon from '@material-ui/icons/PhoneCallback';
import RecallIcon from '@material-ui/icons/Schedule';
import RELOsIcon from '@material-ui/icons/CallMissedOutgoing';
import ReschedulingIcon from '@material-ui/icons/Restore';
import TreatmentIcon from '@material-ui/icons/SettingsBackupRestore';
import WebsiteChatIcon from '@material-ui/icons/ChatBubbleOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  dialogEditOfficeOfferingsItem,
  dialogEditOfficeOfferingsClick
} from '../../../actions';
import { styles } from './styles';
import EditButton from './EditButton';

const OfficeOfferingsData = ({
  classes,
  data,
  data: { offerings },
  dialogEditOfficeOfferingsItem,
  dialogEditOfficeOfferingsClick,
  label,
  name,
  showEdit
}) => {
  const onClick = () => {
    dialogEditOfficeOfferingsItem({ key: name, label, data });
    dialogEditOfficeOfferingsClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div
        className={`${
          offerings.recallplus ? classes.dataTextRecallPlus : classes.dataText
        }`}
      >
        {offerings.base ? (
          <div>
            <BaseIcon /> Base
          </div>
        ) : null}
        {offerings.answering ? (
          <div>
            <AnsweringIcon /> Answering
          </div>
        ) : null}
        {offerings.recall && !offerings.recallplus ? (
          <div>
            <RecallIcon /> Recall
          </div>
        ) : null}
        {offerings.relos ? (
          <div>
            <RELOsIcon /> RELOs
          </div>
        ) : null}
        {offerings.insuranceVerification ? (
          <div>
            <DoneOutlineIcon /> Insurance Verification
          </div>
        ) : null}
        {offerings.treatment ? (
          <div>
            <TreatmentIcon /> Treatment
          </div>
        ) : null}
        {offerings.recallplus ? (
          <div className={classes.recallplusContainer}>
            <RecallIcon className={classes.recallplusIcon} />
            <AddIcon className={classes.recallplusAddIcon} /> Recall+
          </div>
        ) : null}
        {offerings.rescheduling ? (
          <div>
            <ReschedulingIcon /> Rescheduling
          </div>
        ) : null}
        {offerings.websiteChat ? (
          <div>
            <WebsiteChatIcon /> Website Chat
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    dialogEditOfficeOfferingsItem,
    dialogEditOfficeOfferingsClick
  }),
  withStyles(styles, { withTheme: true })
)(OfficeOfferingsData);
