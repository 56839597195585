import React from 'react';
import Chip from '@material-ui/core/Chip';

const FilterClearChip = ({ filtersApplied, onClearFilter }) => {
  if (filtersApplied) {
    return (
      <Chip label='Clear Filter' onDelete={onClearFilter} color='secondary' />
    );
  }

  return null;
};

export default FilterClearChip;
