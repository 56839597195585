import React, { useState } from 'react';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Loader from 'react-loader-spinner';
import qs from 'qs';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { remail } from '../../services/validation';
import { withFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FieldText } from '../../components/Fields';
import { compose } from 'redux';
import { slideUpTransitionOptions } from '../../services/transitions';
import logo from '../../assets/ReachLogo.png';
import styles from './styles';
import { functions } from '../../../src';

const ForgotPassword = ({ classes, history, reset, redirectUri }) => {
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onSubmit = async (values, actions) => {
    const { email } = values;
    const { resetForm } = actions;
    const host = `${window.location.protocol}//${window.location.hostname}`;
    const reqBody = { email, host };

    if (redirectUri) {
      reqBody.redirectUri = redirectUri;
    }

    handleSubmissionStates({
      formSuccess: false,
      formError: false,
      loadingAfterSubmit: true
    });

    try {
      await functions().httpsCallable('auth-resetPassword')(reqBody);
      resetForm({ email: '' });
      setSuccessText(
        <span>
          <b>Success!</b>
          <br />
          {/* leaving this commented line for next story */}
          {/* {`An email with instructions on how to ${setResetText} your password was send to `} */}
          {`An email with instructions on how to reset your password was send to `}
          <br />
          <i>{email}</i>
        </span>
      );
      handleSubmissionStates({
        formSuccess: true,
        formError: false,
        loadingAfterSubmit: false
      });
    } catch (e) {
      console.error(e);
      setErrorText('There was an error; please try again');
      handleSubmissionStates({
        formSuccess: false,
        formError: true,
        loadingAfterSubmit: false
      });
    }
  };

  const handleSubmissionStates = ({
    formError,
    loadingAfterSubmit,
    formSuccess
  }) => {
    setFormError(formError);
    setLoadingAfterSubmit(loadingAfterSubmit);
    setFormSuccess(formSuccess);
  };

  const renderText = classes => {
    if (formError) {
      return (
        <Typography variant='body1' className={classes.loginError}>
          {errorText}
        </Typography>
      );
    } else if (formSuccess) {
      return (
        <Typography variant='body1' style={{ textAlign: 'center' }}>
          {successText}
        </Typography>
      );
    } else {
      return (
        <Typography variant='body1'>
          {/* leaving this commented line for next story */}
          {/* {`Please enter your email address to receive instructions on how to 
            ${setResetText} your password.`} */}
          Please enter your email address to receive instructions on how to
          reset your password.
        </Typography>
      );
    }
  };

  const renderControls = () => {
    return (
      <div className={classes.controls}>
        <Button
          onClick={() => {
            history.push('/login');
          }}
          variant='outlined'
          color='secondary'
          className={classes.logoutButton}
        >
          <BackIcon /> Login
        </Button>
      </div>
    );
  };

  const renderButtonText = () => {
    if (loadingAfterSubmit) {
      return <Loader type='Oval' color='#ffffff' height='13' width='13' />;
    } else {
      return 'Submit';
    }
  };

  const validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Please enter your email address';
    }

    if (values.email) {
      if (!remail.test(values.email)) {
        errors.email = 'Please enter a valid email address';
      }
    }

    return errors;
  };

  return (
    <div className={`ForgotPassword ${classes.root}`}>
      {renderControls(classes, history)}
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.content}>
            <div className={classes.header}>
              <img src={logo} alt='Logo' className={classes.logo} />
            </div>
            <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
              <span>{renderText(classes)}</span>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
                validate={validate}
              >
                {!formSuccess ? (
                  <Form className={classes.form}>
                    <Field name='email'>
                      {({ form, field }) => {
                        return (
                          <FieldText
                            form={form}
                            field={field}
                            label='Email'
                            variant='outlined'
                            onChange={e => {
                              field.onBlur(e);
                              field.onChange(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <div className={classes.submitButtonContainer}>
                      <Button
                        size='large'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        type='submit'
                      >
                        {renderButtonText()}
                      </Button>
                    </div>
                  </Form>
                ) : null}
              </Formik>
            </ReactCSSTransitionGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let params = qs.parse(ownProps.location.search.slice(1));

  let redirectUri;

  if (
    params.redirectUri &&
    !params.redirectUri.includes('login') &&
    !params.redirectUri.includes('forgot-password') &&
    !params.redirectUri.includes('reset-password')
  ) {
    redirectUri = params.redirectUri;
  } else {
    redirectUri = ' ';
  }

  return {
    redirectUri,
    setResetText: params.hlip === 'false' ? 'set' : 'reset'
  };
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter,
  withStyles(styles)
)(ForgotPassword);
