export const styles = theme => ({
  root: {
    height: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing.unit
    },
    maxHeight: 1100
  },
  heading: {
    height: theme.spacing.unit * 5,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing.unit
    }
  },
  headText: {
    color: 'white',
    lineHeight: 1,
    fontSize: '1.55rem'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    height: 'calc(100% - 120px)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px',
      margin: '32px 32px 0px 32px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px',
      margin: '32px auto 0px auto',
      maxWidth: '1000px'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1100px'
    }
  }
});
