import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../contexts';
import {
  Fade,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@material-ui/core';
import { UPDATE } from '../../../types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  popper: {
    zIndex: '1'
  },
  typography: {
    padding: theme.spacing.unit * 2,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    textAlign: 'center'
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white
      }
    }
  },
  primary: {},
  icon: {}
});

const ProfilePopper = ({ anchorEl, classes, open, placement }) => {
  const {
    state: { auth, firebase },
    dispatch
  } = useContext(AppContext);
  const displayName = auth.data ? auth.data.displayName : null;
  const logOut = async () => {
    try {
      await firebase.auth().signOut();
      dispatch({
        type: UPDATE,
        payload: {
          authenticated: false,
          auth: { data: null, isLoaded: true, isEmpty: true },
          profile: { data: null, isLoaded: true, isEmpty: true }
        }
      });
    } catch (err) {
      return err;
    }
  };

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Typography variant='subtitle1' className={classes.typography}>
              {displayName}
            </Typography>
            <MenuList>
              <MenuItem className={classes.menuItem} onClick={() => logOut()}>
                Logout
              </MenuItem>
            </MenuList>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

ProfilePopper.propTypes = {
  anchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(ProfilePopper);
