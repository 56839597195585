import axios from 'axios';

const searchFirestore = async ({
  collection,
  query,
  page,
  hitsPerPage,
  token
}) => {
  try {
    let index;
    switch (collection) {
      case 'callsAnswered':
        index = 'calls answered';
        break;
      case 'notScheduled':
        index = 'not scheduled';
        break;
      case 'scheduledTreatment':
        index = 'scheduled treatment';
        break;
      default:
        index = collection;
        break;
    }

    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/search-algolia-http-firestore`,
      headers: { Authorization: `Bearer ${token}` },
      params: { collection, index, query, page, hitsPerPage }
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export default searchFirestore;
