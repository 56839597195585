import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../../../../contexts';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { t } from 'typy';
import { addCredit } from '../../../../../../../services/credits';
import TextField from '../../../../../../../components/TextField';
import SelectField from '../../../../../../../components/SelectField';
import { validations } from '../../../../../../../services/validation';

const AddCreditForm = ({
  change,
  classes,
  clients,
  creditTypes,
  firestore,
  formValues,
  handleSubmit,
  onSave
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [stripeCharges, setStripeCharges] = useState([]);
  const [needStripeCharges, setNeedStripeCharges] = useState(false);
  const saveDisabled = clients.length === 0 && creditTypes.length === 0;

  useEffect(() => {
    const { client, immediateRefund } = formValues;
    const fetchStripeCharges = async () => {
      try {
        setError(null);

        if (client && immediateRefund && needStripeCharges) {
          const clientStripeDoc = await firebase
            .firestore()
            .collection('clients')
            .doc(formValues.client)
            .collection('stripe')
            .doc('customer')
            .get();
          const token = await firebase.auth().currentUser.getIdToken();
          const {
            data: { data }
          } = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/billing-stripe-http-charges`,
            headers: { Authorization: `Bearer ${token}` },
            params: {
              stripeEnv: process.env.REACT_APP_ENV === 'prod' ? 'live' : 'test',
              customer: clientStripeDoc.data().id
            }
          });
          const stripeCharges = _.filter(
            data,
            o => !o.refunded && o.status === 'succeeded'
          );

          setStripeCharges(stripeCharges);
          setNeedStripeCharges(false);
        }
      } catch (e) {
        const err = _.get(e, 'response.data') ? e.response.data : e;

        setStripeCharges([]);
        setError(err.toString());
      }
    };

    fetchStripeCharges();
  }, [firebase, formValues, needStripeCharges]);

  const onSubmit = async values => {
    const credit = {
      ..._.omit(values, ['stripeCharge']),
      clientName: t(
        _.mapKeys(clients, 'id')[values.client],
        'officeInformation.name'
      ).safeObject,
      createdTime: firestore.Timestamp.now().toDate(),
      immediateRefund: !!values.immediateRefund,
      remaining: values.immediateRefund ? 0 : parseFloat(values.value),
      sendNotification: !!(values.immediateRefund && values.sendNotification),
      status: values.immediateRefund ? 'used' : 'available',
      value: parseFloat(values.value),
      ...(values.stripeCharge &&
        values.immediateRefund && { stripe: { charge: values.stripeCharge } })
    };

    try {
      setError(null);
      await addCredit(firestore, credit);
      onSave();
    } catch (e) {
      setError(e.toString());
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(values => onSubmit(values))}
    >
      <div className={classes.client}>
        <Field
          name='client'
          component={SelectField}
          disabled={clients.length === 0}
          selected={formValues.client !== null}
          label={
            creditTypes.length === 0 ? (
              <span className={classes.loadingOptionsContainer}>
                <Loader type='Oval' color='#696969' height='12' width='12' />
                Fetching Offices
              </span>
            ) : (
              'Office'
            )
          }
          type='text'
          options={clients}
          optionKey='id'
          optionText='officeInformation.name'
          optionValue='id'
          margin='none'
          textFieldContainerClass={classes.dialogTextField}
          onChange={() => setNeedStripeCharges(true)}
          validate={[validations.required]}
        />
      </div>
      <div className={classes.immediateRefund}>
        <Field
          name='immediateRefund'
          label='Immediate Refund'
          component={({ input, label }) => (
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={!!input.value}
                  onChange={() => change('immediateRefund', !input.value)}
                />
              }
              label={
                <Typography variant='body1' className={classes.checkboxLabel}>
                  {label}
                </Typography>
              }
            />
          )}
        />
      </div>
      <div className={classes.stripeCharge}>
        {formValues.immediateRefund ? (
          <Field
            name='stripeCharge'
            component={SelectField}
            disabled={stripeCharges.length === 0 || needStripeCharges}
            selected={formValues.stripeCharge !== null || needStripeCharges}
            label={
              !formValues.client ? (
                'Select Office to Fetch Stripe Charges'
              ) : stripeCharges.length === 0 && !needStripeCharges ? (
                'No Eligible Stripe Charges'
              ) : stripeCharges.length === 0 || needStripeCharges ? (
                <span className={classes.loadingOptionsContainer}>
                  <Loader type='Oval' color='#696969' height='12' width='12' />
                  Fetching Stripe Charges
                </span>
              ) : (
                'Stripe Charge'
              )
            }
            type='text'
            options={stripeCharges}
            optionKey='id'
            optionText={option => {
              const dateFormat = 'MM/DD/YYYY h:mm A z';
              const amount = _.round(option.amount / 100, 2).toFixed(2);
              const chargeDate = moment
                .unix(option.created)
                .tz(process.env.REACT_APP_DEFAULT_TIMEZONE)
                .format(dateFormat);

              return `$${amount} - ${chargeDate}`;
            }}
            optionValue='id'
            margin='none'
            textFieldContainerClass={classes.dialogTextField}
            onChange={(event, newValue, previousValue, name) => {
              const charge = _.filter(stripeCharges, { id: newValue })[0];
              change(
                'value',
                _.round(_.get(charge, 'amount', 0) / 100, 2).toFixed(2)
              );
            }}
            validate={[validations.required]}
          />
        ) : null}
      </div>
      <div className={classes.sendNotification}>
        {formValues.immediateRefund ? (
          <Field
            name='sendNotification'
            label='Send Notification'
            component={({ input, label }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={!!input.value}
                    onChange={() => change('sendNotification', !input.value)}
                  />
                }
                label={
                  <Typography variant='body1' className={classes.checkboxLabel}>
                    {label}
                  </Typography>
                }
              />
            )}
          />
        ) : null}
      </div>
      <div className={classes.type}>
        <Field
          name='type'
          component={SelectField}
          disabled={creditTypes.length === 0}
          selected={formValues.type !== null}
          label={
            creditTypes.length === 0 ? (
              <span className={classes.loadingOptionsContainer}>
                <Loader type='Oval' color='#696969' height='12' width='12' />
                Fetching Credit Types
              </span>
            ) : (
              'Credit Type'
            )
          }
          type='text'
          options={creditTypes}
          optionKey='key'
          optionText='name'
          optionValue='key'
          margin='none'
          textFieldContainerClass={classes.dialogTextField}
          validate={[validations.required]}
        />
      </div>
      <div className={classes.value}>
        <Field
          name='value'
          disabled={formValues.immediateRefund ? true : false}
          component={TextField}
          label='Value'
          type='number'
          step='0.01'
          className={classes.dialogTextField}
          validate={[validations.required, validations.isPositive]}
        />
      </div>
      <div className={classes.title}>
        <Field
          name='title'
          component={TextField}
          label='Title'
          className={classes.dialogTextField}
          validate={[validations.required]}
        />
      </div>
      <div className={classes.description}>
        <Field
          name='description'
          component={TextField}
          label='Description'
          className={classes.dialogTextField}
          validate={[validations.required]}
        />
      </div>
      <div className={classes.formActions}>
        <Typography className={classes.errorMessage} variant='body1'>
          {error || null}
        </Typography>
        <Button color='primary' type='submit' disabled={saveDisabled}>
          Save
        </Button>
      </div>
    </form>
  );
};

const styles = theme => ({
  form: {
    '& > div': {
      marginTop: 8
    },
    '& > div:first-child': {
      marginTop: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 5
    }
  },
  dialogTextField: {
    width: '100%',
    margin: '8px auto 4px auto'
  },
  checkbox: {
    marginLeft: 12
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  }
});

AddCreditForm.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  creditTypes: PropTypes.array.isRequired,
  firestore: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default compose(
  connect((state, props) => {
    const selector = formValueSelector('AddCreditForm');
    const cData = state.firestore.ordered.clients;
    const ctData = state.firestore.ordered.creditTypes;
    let clients = [];
    let creditTypes = [];
    if (isLoaded(cData) && !isEmpty(cData)) clients = cData;
    if (isLoaded(ctData) && !isEmpty(ctData)) creditTypes = ctData;

    return {
      clients,
      creditTypes,
      formValues: {
        client: selector(state, 'client'),
        type: selector(state, 'type'),
        immediateRefund: selector(state, 'immediateRefund')
      },
      initialValues: {
        sendNotification: true
      }
    };
  }),
  firestoreConnect(props => [
    {
      collection: 'clients',
      orderBy: ['officeInformation.name'],
      storeAs: 'clients'
    },
    {
      collection: 'options',
      doc: 'credit',
      subcollections: [
        {
          collection: 'types'
        }
      ],
      storeAs: 'creditTypes'
    }
  ]),
  reduxForm({ form: 'AddCreditForm' }),
  withStyles(styles, { withTheme: true })
)(AddCreditForm);
