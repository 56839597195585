import {
  DIALOG_TEAM_MISTAKES_DETAILS_CLICK,
  DIALOG_TEAM_MISTAKES_DETAILS_ITEM
} from '../actions/types';

export const dialogTeamMistakesDetailsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_TEAM_MISTAKES_DETAILS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogTeamMistakesDetailsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_TEAM_MISTAKES_DETAILS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogTeamMistakesDetailsReducer = {
  dialogTeamMistakesDetailsOpen,
  dialogTeamMistakesDetailsItem
};
