import React from 'react';
import Grid from '@material-ui/core/Grid';

const Column = ({ children, justify }) => (
  <Grid item xs={4} container alignItems='center' justify={justify}>
    {children}
  </Grid>
);

export default Column;
