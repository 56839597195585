import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import AscIcon from '@material-ui/icons/ArrowDownward';
import ButtonBase from '@material-ui/core/ButtonBase';
import DescIcon from '@material-ui/icons/ArrowUpward';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DialogClientGroupOffice from './components/DialogClientGroupOffice';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

const ClientGroupOfficesTable = ({
  allowedToManage,
  classes,
  clientGroupOffices,
  history,
  loading,
  location,
  addOfficeDialogOpen,
  params
}) => {
  const [orderByValue, setOrderByValue] = useState(['name', 'offices.length']);
  const [orderByOrder, setOrderByOrder] = useState(['asc', 'asc']);
  const [currentlySelectedOffice, setCurrentlySelectedOffice] = useState(null);

  const renderClientGroupOfficeTable = () => {
    return (
      <div className={classes.tableContainer}>
        {allowedToManage ? (
          <DialogClientGroupOffice
            params={params}
            history={history}
            location={location}
            clientGroupOffices={clientGroupOffices}
            clientGroupOffice={currentlySelectedOffice}
            setOffice={setCurrentlySelectedOffice}
            onClose={() => {
              let searchObj = queryString.parse(location.search.slice(1));
              delete searchObj.addOfficeDialogOpen;
              history.push(
                `${location.pathname}?${queryString.stringify(searchObj)}`
              );
            }}
            onOfficesAddRedirect={() => {
              history.push(
                `/clientGroup/${params.clientGroupId}/offices?newClientGroupOffices=true`
              );
            }}
            openDialog={addOfficeDialogOpen}
          />
        ) : null}
        {!loading && !clientGroupOffices[0] ? (
          <Typography className={classes.messageText} variant='body1'>
            No offices have been added to this client group
          </Typography>
        ) : (
          _.orderBy(clientGroupOffices, orderByValue, orderByOrder).map(
            (office, index) => (
              <ButtonBase
                className={classes.buttonBase}
                key={office.id}
                onClick={() => {
                  if (allowedToManage) {
                    setCurrentlySelectedOffice(office);
                    const pathname = location.pathname;
                    const param =
                      location.search === ''
                        ? '?addOfficeDialogOpen=true'
                        : '&addOfficeDialogOpen=true';
                    history.push(`${pathname}${location.search}${param}`);
                  }
                }}
              >
                <Grid container spacing={24} className={classes.tableRow}>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    className={`${classes.cell} ${classes.firstCell}`}
                    style={{ paddingLeft: 12 }}
                  >
                    <Typography
                      variant='body1'
                      className={`
                      ${classes.tableText}
                      ${classes.rowIndex}
                      ${index + 1 > 9 ? classes.rowIndexDoubleDig : ''}
                    `}
                    >
                      {index + 1}
                    </Typography>
                    <Typography variant='body1' className={classes.tableText}>
                      {office.officeInformation.name
                        ? office.officeInformation.name
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </ButtonBase>
            )
          )
        )}
      </div>
    );
  };

  const getOrderArr = (key, firstDefaultOrder) => {
    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, 'asc'];
  };

  const changeSort = key => {
    switch (key) {
      case 'name':
        setOrderByValue(['name', 'offices']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'offices':
        setOrderByValue(['offices', 'name']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      default:
        setOrderByValue(['name', 'offices']);
        setOrderByOrder(['asc', 'asc']);
    }
  };

  const renderSortIcon = key => {
    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  };

  const renderRegionTableHeaderRow = () => {
    return (
      <Grid container spacing={24} className={classes.headerRow}>
        <Grid item xs={6} sm={3} md={3} onClick={() => changeSort('name')}>
          <Typography
            variant='button'
            className={`${classes.headerRowText} ${classes.clickableText}`}
          >
            Office Name
          </Typography>
          {renderSortIcon('name')}
        </Grid>
      </Grid>
    );
  };

  return (
    <span>
      <div
        className={
          allowedToManage
            ? classes.titleContainer
            : `${classes.titleContainer} ${classes.titleContainerNoAdd}`
        }
      >
        <Typography variant='h6' className={classes.title}></Typography>
        {allowedToManage ? (
          <Tooltip title='Add Office'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Add Office'
              onClick={() => {
                const pathname = location.pathname;
                const param =
                  location.search === ''
                    ? '?addOfficeDialogOpen=true'
                    : '&addOfficeDialogOpen=true';
                history.push(`${pathname}${location.search}${param}`);
              }}
            >
              <AddIcon className={classes.addIcon} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      {renderRegionTableHeaderRow()}
      {renderClientGroupOfficeTable(allowedToManage)}
    </span>
  );
};

const styles = theme => ({
  titleContainer: {
    padding: '8px 24px 8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5'
  },
  titleContainerNoAdd: {
    padding: '16px 24px',
    height: theme.spacing.unit * 3
  },
  sortIcon: {
    marginRight: 3,
    marginBottom: 2,
    fontSize: 12
  },
  headerRow: {
    height: 24,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px !important'
    },
    '& > div:first-child': {
      padding: '0px !important',
      paddingLeft: '34px !important',
      justifyContent: 'flex-start'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    },
    [theme.breakpoints.down('xs')]: {}
  },
  headerRowText: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  clickableText: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addIcon: {
    color: '#696969'
  },
  tableContainer: {
    width: '100%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '55vh',
    [theme.breakpoints.down('xs')]: {
      height: '49vh'
    }
  },
  buttonBase: {
    width: '100%',
    marginBottom: 0,
    minHeight: 48,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
    fontSize: theme.spacing.unit * 2.5
  },
  firstCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '28px !important'
  },
  rowIndex: {
    marginRight: 10,
    fontSize: '10px !important',
    paddingRight: 2
  },
  rowIndexDoubleDig: {
    marginRight: 6
  },
  avatar: {
    '& img': {
      height: 30,
      width: 30,
      borderRadius: '50%'
    },
    marginRight: 10
  },
  tableText: {
    fontSize: theme.spacing.unit * 1.5,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  icon: {
    fontSize: theme.spacing.unit * 1.2
  },
  nonEssentialColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  },
  messageText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '8vh',
    fontSize: 14,
    fontStyle: 'italic'
  }
});

const mapStateToProps = (state, props) => {
  const officesData = state.firestore.ordered['officesData'];
  let offices;

  if (isLoaded(officesData) && !isEmpty(officesData)) {
    offices = officesData;
  }

  return { offices };
};

ClientGroupOfficesTable.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    let queries = [];

    if (props.clientGroup && props.clientGroup.id) {
      queries.push({
        collection: 'clientGroups',
        doc: props.clientGroup.id,
        storeAs: 'officesData'
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true }),
  withRouter
)(ClientGroupOfficesTable);
