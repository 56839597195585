import {
  DIALOG_EDIT_PRICING_PLAN_CLICK,
  DIALOG_EDIT_PRICING_PLAN_ITEM
} from './types';

export const dialogEditPricingPlanClick = status => ({
  type: DIALOG_EDIT_PRICING_PLAN_CLICK,
  payload: status
});

export const dialogEditPricingPlanItem = item => ({
  type: DIALOG_EDIT_PRICING_PLAN_ITEM,
  payload: item
});
