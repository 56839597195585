import {
  CLIENTS_ADD,
  CLIENTS_DELETE,
  CLIENTS_EDIT,
  CLIENTS_INITIALIZE,
  CLIENTS_SEARCH
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case CLIENTS_ADD:
      return action.payload;
    case CLIENTS_DELETE:
      return action.payload;
    case CLIENTS_EDIT:
      return action.payload;
    case CLIENTS_INITIALIZE:
      return action.payload;
    case CLIENTS_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
