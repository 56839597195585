import React, { useReducer } from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';
import MomentUtils from '@date-io/moment';
import { createFirestoreInstance } from 'redux-firestore';
import { BrowserRouter, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { AppInit, AppReducer } from './reducers';
import { config } from './services/firebase';
import { AppContext } from './contexts';
import theme from './services/theme';
import createReduxStore from './store';
import App from './scenes';

export const rrfConfig = {
  userProfile: 'employees',
  useFirestoreForProfile: true,
  logListenerError: true
};

// initialize firebase instance
firebase.initializeApp(config);

// initialize other services on firebase instance
firebase.analytics();
firebase.auth();
firebase.storage();

if (process.env.REACT_APP_ENV === 'local') {
  firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
} else {
  firebase.firestore().settings({});
  firebase.functions();
}

// export authRef and provider
export const authRef = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const voicemailStorage = firebase
  .app()
  .storage(process.env.REACT_APP_FIREBASE_STORAGE_VOICEMAIL_BUCKET);
export const provider = new firebase.auth.GoogleAuthProvider();
export const functions = () => {
  let fn = firebase.functions();
  // fn.useFunctionsEmulator("http://localhost:5001");
  return fn;
};

// create redux store
const store = createReduxStore();

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const Root = () => {
  const [state, dispatch] = useReducer(AppReducer, { ...AppInit, firebase });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <BrowserRouter>
                <Route path='/' component={App} />
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </AppContext.Provider>
  );
};

// render react app
ReactDOM.render(<Root />, document.getElementById('root'));
