import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import DialogTransition from '../../../../../components/DialogTransition';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'react-loader-spinner';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import {
  FieldPhone,
  FieldSelect,
  FieldText
} from '../../../../../components/Fields';
import { AppContext } from '../../../../../contexts';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { validations } from '../../../../../services/validation';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const DialogEditReloInfo = ({
  classes,
  relo,
  fullScreen,
  open,
  setOpen,
  item
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [options, setOptions] = useState([]);
  const {
    state: { firebase }
  } = useContext(AppContext);

  useEffect(() => {
    const selectFields = ['officeName', 'appointmentType'];
    const renderOptions = async item => {
      if (item.key === 'officeName') {
        try {
          const officesQuerySnapshot = await firebase
            .firestore()
            .collection('clients')
            .where('offerings.relos', '==', true)
            .where('status', '==', 'active')
            .get();
          let officeDocs = [];
          officesQuerySnapshot.forEach(doc => {
            officeDocs.push({ ...doc.data(), id: doc.id });
          });
          officeDocs = _.sortBy(officeDocs, 'officeInformation.name');
          setOptions(officeDocs);
          setLoading(null);
        } catch (e) {
          console.error(e);
          setError('Failed to Fetch Offices');
        }
      }

      if (item.key === 'appointmentType') {
        try {
          const appointmentTypesQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('appointmentTypes')
            .collection('types')
            .orderBy('name')
            .get();
          const typesDocs = [];
          appointmentTypesQuerySnapshot.forEach(doc => {
            typesDocs.push({ ...doc.data(), id: doc.id });
          });
          setOptions(typesDocs);
          setLoading(null);
        } catch (e) {
          console.error(e);
          setError('Failed to Fetch Appointment Types');
        }
      }
    };

    if (item && selectFields.includes(item.key)) {
      setLoading('Fetching Options');
      renderOptions(item);
    }
  }, [item, firebase]);

  const onClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const onSubmit = async values => {
    let entry;
    let data =
      item.key === 'patientPhone'
        ? values[item.key].trim().replace(/[^0-9]/g, '')
        : values[item.key].trim();

    if (item.key === 'officeName') {
      const office = options.find(office => office.id === data);
      const officeName = office?.officeInformation.name;
      entry = { office: data, officeName: officeName };
    } else {
      entry = { [item.key]: data };
    }
    setError(null);
    try {
      await firebase
        .firestore()
        .collection('relos')
        .doc(relo.id)
        .update(entry);

      setLoading(false);
      onClose();
      setOptions([]);
    } catch (e) {
      console.error(e);
      setError('Failed to Update Info');
      setLoading(false);
    }
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {item?.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              {options[0] ? (
                <Field name={item.key} validate={validations.required}>
                  {({ form, field }) => {
                    return (
                      <FieldSelect
                        margin='none'
                        form={form}
                        field={field}
                        loading={options.length === 0}
                        disabled={options.length === 0 || loading !== null}
                        label={item.label}
                        options={options}
                        optionKey='id'
                        optionText={
                          item.key === 'officeName'
                            ? 'officeInformation.name'
                            : 'name'
                        }
                        optionValue='key'
                        component={FieldSelect}
                      />
                    );
                  }}
                </Field>
              ) : item ? (
                <Field name={item.key}>
                  {({ form, field }) => {
                    if (item.key === 'patientName') {
                      return (
                        <FieldText
                          form={form}
                          field={field}
                          label={item.label}
                          variant='outlined'
                          type='text'
                          validate={
                            validations[`${item.key}`]
                              ? [
                                  validations.required,
                                  validations[`${item.key}`]
                                ]
                              : []
                          }
                        />
                      );
                    } else {
                      return (
                        <FieldPhone
                          form={form}
                          field={field}
                          label={item.label}
                          variant='outlined'
                          disabled={loading !== null}
                          validate={validations.phone}
                        />
                      );
                    }
                  }}
                </Field>
              ) : null}
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(styles, { withTheme: true }),
  firestoreConnect(),
  withMobileDialog()
)(DialogEditReloInfo);
