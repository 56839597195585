import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { Button, TextField, Typography, withStyles } from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../services/helpers';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';
import firebase from 'firebase/app';

class CreateFive9SkillForm extends Component {
  state = { formError: null, loadingAfterSubmit: false, loadingText: null };

  onSubmit = values => {
    this.setState({
      formError: null,
      loadingAfterSubmit: true,
      loadingText: null
    });
    this.updateFive9(values);
  };

  updateFive9 = async values => {
    const {
      item: { key }
    } = this.props;
    const { newSkillName } = values;
    const data = {
      name: newSkillName,
      five9Account: key === 'answering' ? 'inbound' : 'recall'
    };

    let createSkill = firebase.functions().httpsCallable('five9-createSkill');

    try {
      this.setState({ loadingText: 'Adding new skill in Five9 system' });
      let response = await createSkill(data);
      let newSkillType = key;
      let newSkillId = response.data.id;
      let newSkillName = response.data.name;
      let oldSkillId = values[key];
      this.updateFirestore(newSkillType, newSkillId, newSkillName, oldSkillId);
    } catch (e) {
      let formError = `Error: ${e.message}`;
      this.setState({
        formError,
        loadingAfterSubmit: false,
        loadingText: null
      });
    }
  };

  updateFirestore = async (
    newSkillType,
    newSkillId,
    newSkillName,
    oldSkillId
  ) => {
    const {
      firestore,
      item: {
        data: { id: clientId }
      }
    } = this.props;
    this.setState({ loadingText: 'Adding new skill in CallForce system' });

    try {
      let newSkillRef = {
        collection: 'five9',
        doc: 'skills',
        subcollections: [{ collection: 'all', doc: newSkillId }]
      };
      let clientRef = { collection: 'clients', doc: clientId };

      await firestore.set(newSkillRef, {
        name: newSkillName
      });
      await firestore.update(clientRef, {
        [`five9.skills.${newSkillType}`]: newSkillId
      });

      this.setState({
        formError: null,
        loadingAfterSubmit: false,
        loadingText: null
      });
      this.props.onClose();
    } catch (e) {
      console.log(e);
      let formError = 'Error: Skill name not updated in CallForce system.';
      this.setState({
        formError,
        loadingAfterSubmit: false,
        loadingText: null
      });
    }
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin='normal'
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...field.input}
      />
    );
  };

  render() {
    const { classes, handleSubmit, item, submitting } = this.props;
    const { formError, loadingAfterSubmit, loadingText } = this.state;

    return (
      <form className={classes.dialogForm} noValidate autoComplete='off'>
        <Field
          name='newSkillName'
          component={this.renderTextField}
          label={`New ${item &&
            item.key &&
            capitalizeFirstLetter(item && item.key)} Skill Name`}
          type='text'
          className={classes.dialogTextField}
          validate={[validations.required]}
        />
        <div
          className={
            formError || loadingText
              ? classes.dialogButtonContainerWithText
              : classes.dialogButtonContainer
          }
          style={{ marginBottom: 24 }}
        >
          {formError ? (
            <Typography variant='body1' className={classes.dialogFormErrorText}>
              {formError}
            </Typography>
          ) : null}
          {loadingText ? (
            <Typography variant='body1' className={classes.dialogLoadingText}>
              {loadingText}
            </Typography>
          ) : null}
          <Button
            size='large'
            variant='outlined'
            color='secondary'
            className={classes.dialogButton}
            type='submit'
            onClick={handleSubmit(this.onSubmit)}
            disabled={submitting ? true : false}
          >
            {loadingAfterSubmit ? (
              <Loader type='Oval' color='#055f5b' height='13' width='13' />
            ) : (
              'Create Skill'
            )}
          </Button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'DialogEditOfficeCreateFive9Skill',
    enableReinitialize: true
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(CreateFive9SkillForm);
