import React, { useState, useEffect, useContext } from 'react';
import DialogTransition from '../../../../../components/DialogTransition';
import helpers from '../../../../../helpers';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import {
  Dialog,
  DialogContent,
  withMobileDialog,
  Typography,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core';
import {
  FieldSelect,
  FieldDate,
  FieldTime
} from '../../../../../components/Fields';
import { Formik, Form, Field } from 'formik';
import { AppContext } from '../../../../../contexts';
import { futureDate, required, time } from '../../../../../validation';
import { Close } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { styles } from './styles';

const DialogEditContactCategory = ({
  classes,
  relo,
  contactCategoryOpen,
  onClose,
  fullScreen,
  contactId
}) => {
  const [loading, setLoading] = useState('Fetching Categories');
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const {
    state: { firebase }
  } = useContext(AppContext);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          //change answering to relos once db is updated
          .where('offerings', 'array-contains', 'answering')
          .get();

        const categoriesDocs = [];
        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });

        setCategories(categoriesDocs);
        setLoading(null);
      } catch (e) {
        console.error(e);
        setCategories(null);
        setLoading(null);
        setError('Failed to fetch Categories');
      }
    };

    const fetchSubcategories = async () => {
      try {
        const subcategoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .doc(currentCategory)
          .collection('subcategories')
          .where('offerings', 'array-contains', 'answering')
          .get();

        const subcategoriesDocs = [];
        subcategoriesSnapshot.forEach(doc => {
          subcategoriesDocs.push({ ...doc.data(), id: doc.id });
        });

        setSubcategories(subcategoriesDocs);
        setLoading(null);
      } catch (e) {
        console.error(e);
        setSubcategories(null);
        setLoading(null);
        setError('Failed to Fetch Subcategories');
      }
    };

    fetchCategories();
    if (currentCategory) {
      fetchSubcategories();
    }
  }, [firebase, currentCategory]);

  const onSubmit = async values => {
    setError(null);
    setLoading('Saving Entry; Please Keep Browser Window Open');
    try {
      const contacts = relo.contacts;
      if (values.appointmentDate) {
        const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;
        const reloOffice = await firebase
          .firestore()
          .collection('clients')
          .doc(relo.office)
          .get();

        const officeTimezone = reloOffice.data().officeInformation.timezone;
        const appointmentTime = moment.tz(apptDateTime, officeTimezone);
        contacts[contactId].appointmentTime = new Date(
          appointmentTime.format()
        );
      }
      contacts[contactId].reason = values.reason;
      const reloRef = firebase.firestore().collection('relos').doc(relo.id);
      if (values.appointmentTime) {
        await reloRef.set(
          { contacts, status: 'completed', result: 'scheduled' },
          { merge: true }
        );
      } else {
        await reloRef.set({ contacts }, { merge: true });
      }
      setLoading(null);
      onClose();
      setSubcategories([]);
      setCurrentCategory(null);
    } catch (e) {
      console.error(e);
      setError('Failed To Update Contact');
      setLoading(null);
    }
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }
    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  const validations = {
    appointmentDate: v =>
      helpers.composeFieldValidation(v, [required, futureDate]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time]),
    category: required,
    subCategory: required
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={contactCategoryOpen}
      onClose={() => onClose()}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit Category
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton
            color='inherit'
            aria-label='Close'
            onClick={() => onClose()}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <Field name='reason.category' validate={validations.category}>
                {({ form, field }) => {
                  return (
                    <FieldSelect
                      margin='none'
                      form={form}
                      field={field}
                      loading={categories.length === 0}
                      disabled={categories.length === 0 || loading !== null}
                      label='Category'
                      options={categories}
                      optionKey='id'
                      optionText='category'
                      optionValue='id'
                      onChange={newCategory => {
                        setCurrentCategory(newCategory);
                      }}
                    />
                  );
                }}
              </Field>
              {subcategories[0] ? (
                <Field
                  name='reason.subcategory'
                  validate={validations.subCategory}
                >
                  {({ form, field }) => {
                    return (
                      <FieldSelect
                        margin='none'
                        form={form}
                        field={field}
                        loading={subcategories.length === 0}
                        disabled={
                          subcategories.length === 0 || loading !== null
                        }
                        label='Subcategory'
                        options={subcategories}
                        optionKey='id'
                        optionText='category'
                        optionValue='id'
                        component={FieldSelect}
                      />
                    );
                  }}
                </Field>
              ) : null}
              {/* this uid is the offeringsCategories => appointment Scheduled*/}
              {currentCategory === 'lmW1HPZWpEOUjChoTSi9' &&
              subcategories[0] ? (
                <div className={classes.row}>
                  <Field
                    name='appointmentDate'
                    label='Appointment Date'
                    variant='outlined'
                    placeholder='mm/dd/yyyy'
                    component={FieldDate}
                    disabled={loading !== null}
                    validate={validations.appointmentDate}
                  />
                  <Field
                    name='appointmentTime'
                    label='Appointment Time'
                    variant='outlined'
                    placeholder='HH:mm'
                    component={FieldTime}
                    disabled={loading !== null}
                    validate={validations.appointmentTime}
                  />
                </div>
              ) : null}
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter
)(DialogEditContactCategory);
