import thunk from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as formReducer } from 'redux-form';
import { AuthReducer } from './reducers/AuthReducer';
import CallsAnsweredReducer from './reducers/CallsAnsweredReducer';
import ClientsReducer from './reducers/ClientsReducer';
import NotScheduledReducer from './reducers/NotScheduledReducer';
import OfferingsCategoriesReducer from './reducers/OfferingsCategoriesReducer';
import RescheduledReducer from './reducers/RescheduledReducer';
import ScheduledReducer from './reducers/ScheduledReducer';
import { DialogDoNotBillReducer } from './reducers/DialogDoNotBillReducer';
import { DialogAddEditEntryReducer } from './reducers/DialogAddEditEntryReducer';
import { DialogAddEditOfficeUserReducer } from './reducers/DialogAddEditOfficeUserReducer';
import { DialogBillingItemReducer } from './reducers/DialogBillingItemReducer';
import { DialogEditOfferingTimeActiveEntryReducer } from './reducers/DialogEditOfferingTimeActiveEntryReducer';
import { DialogEditOfficeAddressReducer } from './reducers/DialogEditOfficeAddressReducer';
import { DialogEditOfficeFive9DataReducer } from './reducers/DialogEditOfficeFive9DataReducer';
import { DialogEditOfficeHoursReducer } from './reducers/DialogEditOfficeHoursReducer';
import { DialogEditOfficeInfoReducer } from './reducers/DialogEditOfficeInfoReducer';
import { DialogEditOfficeOfferingsReducer } from './reducers/DialogEditOfficeOfferingsReducer';
import { DialogEditOfficeStatusReducer } from './reducers/DialogEditOfficeStatusReducer';
import { DialogEditPaymentInfoReducer } from './reducers/DialogEditPaymentInfoReducer';
import { DialogEditPricingPlanReducer } from './reducers/DialogEditPricingPlanReducer';
import { DialogIndiEntriesDetailsReducer } from './reducers/DialogIndiEntriesDetailsReducer';
import { DialogIndiMistakesDetailsReducer } from './reducers/DialogIndiMistakesDetailsReducer';
import { DialogMistakeReducer } from './reducers/DialogMistakeReducer';
import { DialogOverallEntriesDetailsReducer } from './reducers/DialogOverallEntriesDetailsReducer';
import { DialogOverallMistakesDetailsReducer } from './reducers/DialogOverallMistakesDetailsReducer';
import { DialogTeamEntriesDetailsReducer } from './reducers/DialogTeamEntriesDetailsReducer';
import { DialogTeamMistakesDetailsReducer } from './reducers/DialogTeamMistakesDetailsReducer';
import { EmployeesReducer } from './reducers/EmployeesReducer';
import { InfiniteScrollReducer } from './reducers/InfiniteScrollReducer';
import { SearchReducer } from './reducers/SearchReducer';
import { SnackbarReducer } from './reducers/SnackbarReducer';
import { StatusReducer } from './reducers/StatusReducer';
import { TableFilterReducer } from './reducers/TableFilterReducer';
import { TableSelectReducer } from './reducers/TableSelectReducer';
import { DialogEditTeamLeadReducer } from './reducers/DialogEditTeamLeadReducer';
import { DialogEditTeamNameReducer } from './reducers/DialogEditTeamNameReducer';
import { DialogEditTeamServiceReducer } from './reducers/DialogEditTeamServiceReducer';
import { DialogEditTeamMembersReducer } from './reducers/DialogEditTeamMembersReducer';
import { DialogDeleteTeamReducer } from './reducers/DialogDeleteTeamReducer';
import { DialogDrProfileReducer } from './reducers/DialogDrProfileReducer';
import { DialogEditOfficeOpsStatusReducer } from './reducers/DialogEditOfficeOpsStatuses';

import 'firebase/firestore';
import 'firebase/auth';

const reducer = combineReducers({
  ...AuthReducer,
  callsAnswered: CallsAnsweredReducer,
  clients: ClientsReducer,
  ...DialogAddEditOfficeUserReducer,
  ...DialogAddEditEntryReducer,
  ...DialogBillingItemReducer,
  ...DialogDoNotBillReducer,
  ...DialogEditOfferingTimeActiveEntryReducer,
  ...DialogEditOfficeAddressReducer,
  ...DialogEditOfficeFive9DataReducer,
  ...DialogEditOfficeHoursReducer,
  ...DialogEditOfficeInfoReducer,
  ...DialogEditOfficeOfferingsReducer,
  ...DialogEditOfficeStatusReducer,
  ...DialogEditPaymentInfoReducer,
  ...DialogEditPricingPlanReducer,
  ...DialogIndiEntriesDetailsReducer,
  ...DialogIndiMistakesDetailsReducer,
  ...DialogMistakeReducer,
  ...DialogOverallEntriesDetailsReducer,
  ...DialogOverallMistakesDetailsReducer,
  ...DialogTeamEntriesDetailsReducer,
  ...DialogTeamMistakesDetailsReducer,
  ...EmployeesReducer,
  ...InfiniteScrollReducer,
  notScheduled: NotScheduledReducer,
  offeringsCategories: OfferingsCategoriesReducer,
  rescheduled: RescheduledReducer,
  scheduled: ScheduledReducer,
  ...SearchReducer,
  ...SnackbarReducer,
  ...StatusReducer,
  ...TableFilterReducer,
  ...TableSelectReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  ...DialogEditTeamLeadReducer,
  ...DialogEditTeamNameReducer,
  ...DialogEditTeamServiceReducer,
  ...DialogEditTeamMembersReducer,
  ...DialogDeleteTeamReducer,
  ...DialogDrProfileReducer,
  ...DialogEditOfficeOpsStatusReducer
});

const initialState = {};

export default () => createStore(reducer, initialState, applyMiddleware(thunk));
