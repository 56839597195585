export const styles = theme => ({
  planTitleContainer: {
    marginBottom: 4,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  planTitle: {},
  pricingDetails: {
    fontSize: 12,
    '& > div': {
      padding: '12px 12px 4px 12px !important'
    }
  },
  notSubPlanTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  notSubscribedButton: {
    fontSize: 10,
    marginLeft: 8,
    marginBottom: 2,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editButton: {
    fontSize: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
