import {
  DIALOG_OVERALL_ENTRIES_DETAILS_CLICK,
  DIALOG_OVERALL_ENTRIES_DETAILS_ITEM
} from './types';

export const dialogOverallEntriesDetailsClick = status => ({
  type: DIALOG_OVERALL_ENTRIES_DETAILS_CLICK,
  payload: status
});

export const dialogOverallEntriesDetailsItem = item => ({
  type: DIALOG_OVERALL_ENTRIES_DETAILS_ITEM,
  payload: item
});
