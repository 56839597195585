import React from 'react';
import qs from 'qs';
import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { t } from 'typy';
import { styles } from './styles';
import SignUpCreditArray from './SignUpCreditArray';

const SignUpCredit = ({
  classes,
  change,
  formData,
  formSectionValues,
  onSubmit,
  handleSubmit,
  previousPage
}) => (
  <form onSubmit={handleSubmit(() => onSubmit(formSectionValues))}>
    <div className={classes.sectionTitle}>
      <Typography variant='subtitle2'>Sign-Up Credit</Typography>
    </div>
    <div className={classes.credit}>
      <FieldArray
        name='signUpCredit'
        formData={formData}
        change={change}
        component={SignUpCreditArray}
      />
    </div>
    <div className={classes.sectionActions}>
      <Button
        color='primary'
        type='button'
        onClick={() => previousPage(formSectionValues)}
      >
        Previous
      </Button>
      <Button color='primary' type='submit'>
        Next
      </Button>
    </div>
  </form>
);

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  const selector = formValueSelector('DialogAddOfficeForm');

  return {
    formData,
    formSectionValues: {
      signUpCredit: selector(state, 'signUpCredit')
        ? selector(state, 'signUpCredit')
        : [{}]
    }
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(SignUpCredit);
