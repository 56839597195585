import {
  DIALOG_DO_NOT_BILL_CLICK,
  DIALOG_DO_NOT_BILL_ITEM
} from '../actions/types';

export const dialogDoNotBillItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_DO_NOT_BILL_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogDoNotBillOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_DO_NOT_BILL_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogDoNotBillReducer = {
  dialogDoNotBillItem,
  dialogDoNotBillOpen
};
