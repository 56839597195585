import _ from 'lodash';
import React, { useContext } from 'react';
import EntriesManagement from '../../../components/EntriesManagement';
import HeaderTitleWithTabs from '../../../components/HeaderTitleWithTabs';
import HeaderTitleText from '../../../components/HeaderTitleText';
import PropTypes from 'prop-types';
import { AppContext } from '../../../contexts';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';

const Management = props => {
  const {
    state: { profile }
  } = useContext(AppContext);
  let tabs = [];

  Object.keys(props.tabs).forEach((key, index) => {
    const route = props.tabs[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: index - 1,
        name: key,
        title: route.metadata.title,
        handleChangePath: '/dashboards/management/$tabName',
        renderComponent: () => (
          <EntriesManagement mistakes={key === 'mistakes'} />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      params={props.match.params}
      renderTitle={() => <HeaderTitleText text='Management' />}
      tabId={props.match.params.tabId}
      tabs={tabs}
    />
  );
};

Management.propTypes = {
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Management);
