import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import helpers from '../../../../../../../../helpers';
import Grid from '@material-ui/core/Grid';
import ClientGroupOfficesTable from './ClientGroupOfficesTable';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../../../../../contexts';
import { t } from 'typy';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  offices: {
    height: theme.containers.paper.height.default,
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  }
});

const Offices = ({
  classes,
  sitemapPath,
  params,
  location,
  clientGroup,
  history
}) => {
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToManage = helpers.allowedTo('manage', sitemapPath, userGroups);
  const addOfficeDialogOpen =
    queryString.parse(location.search.slice(1)).addOfficeDialogOpen === 'true';
  const [offices, setOffices] = useState([]);
  const [checkedOffices, setCheckedOffices] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getOfficeData = async officeId => {
      try {
        const officeRef = firebase
          .firestore()
          .collection('clients')
          .doc(officeId);

        const officeData = await officeRef.get();
        const officeObj = officeData.data();
        officeObj.id = officeId;
        return officeObj;
      } catch (e) {
        console.error(e);
      }
    };

    const getOffices = async () => {
      const retrievedClientGroupOffices = [];
      try {
        const clientGroupOffices = await firebase
          .firestore()
          .collection('clientGroups')
          .doc(params.clientGroupId)
          .get();

        const { offices } = clientGroupOffices.data();

        for (const officeId in offices) {
          retrievedClientGroupOffices.push(getOfficeData(offices[officeId]));
        }

        let officeDatas = await Promise.all(retrievedClientGroupOffices);
        setCheckedOffices(true);
        setOffices(officeDatas);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    if (!offices.length && !checkedOffices) {
      getOffices();
    }

    if (location.search === '?newClientGroupOffices=true') {
      history.push({ search: '?pathname=/offices/clientGroups/regions' });
      getOffices();
    }
  }, [firebase, offices, checkedOffices, params, location, history]);

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.offices} elevation={4}>
            <ClientGroupOfficesTable
              clientGroupOffices={offices}
              clientGroup={clientGroup}
              allowedToManage={allowedToManage}
              addOfficeDialogOpen={addOfficeDialogOpen}
              loading={loading}
              params={params}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Offices.propTypes = {
  classes: PropTypes.object.isRequired,
  offices: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(Offices);
