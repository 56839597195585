import _ from 'lodash';
import isValidZipcode from 'is-valid-zipcode';
import moment from 'moment';
import {} from 'redux-form';
import { t } from 'typy';

export const remail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rphone = /^\d{10}$/;

const required = value => (value ? undefined : 'Required');
const officeName = (value, allValues, props, name) =>
  new Promise(async (resolve, reject) => {
    try {
      if (value[name]) {
        let snapshot = await props.firestore.get({
          collection: 'clients',
          where: ['officeInformation.name', '==', value[name]]
        });
        let nameError = new Error(`${value[name]} already exists`);

        if (snapshot.size > 0) throw nameError;
      }
      return resolve();
    } catch (e) {
      return reject({ name: e.toString().replace('Error: ', '') });
    }
  });
const email = value =>
  value && !remail.test(value) ? 'Invalid Email Address' : undefined;
const phone = value =>
  value && !rphone.test(value.replace(/[^0-9]/g, ''))
    ? 'Invalid Phone Number'
    : undefined;
const zipUS = value =>
  value && !isValidZipcode(value, 'US') ? 'Invalid US Zip Code' : undefined;
const zipCA = value =>
  value && !isValidZipcode(value, 'CA') ? 'Invalid CA Postal Code' : undefined;
const afterStartDate = (value, allValues) =>
  allValues.startDate && value <= allValues.startDate
    ? 'End date must be after the start date'
    : undefined;
const beforeEndDate = (value, allValues) =>
  allValues.endDate && value >= allValues.endDate
    ? 'Start date must be before the end date'
    : undefined;
const conflictingSubEntry = (value, allValues, props) => {
  const otherSubEntries = _.filter(
    props.otherSubEntries,
    o => !_.isEqual(o, props.item.entryItem)
  );
  const conflicts = _.filter(otherSubEntries, o =>
    t(o, 'endDate.seconds').safeObject
      ? value > moment.unix(t(o, 'startDate.seconds').safeObject) &&
        value < moment.unix(t(o, 'endDate.seconds').safeObject)
      : value > moment.unix(t(o, 'startDate.seconds').safeObject)
  );

  return conflicts.length > 0
    ? `This time conflicts with at least one existing subscription entry; 
       either select a different time, or adjust the existing entry`
    : undefined;
};
const lessThanCloseTime = (value, allValues, props, name) =>
  moment(value, 'kk:mm') >=
  moment(allValues[`${name.replace('Open', '')}Close`], 'kk:mm')
    ? 'Open time must be before close time'
    : undefined;
const greaterThanOpenTime = (value, allValues, props, name) =>
  moment(value, 'kk:mm') <=
  moment(allValues[`${name.replace('Close', '')}Open`], 'kk:mm')
    ? 'Close time must be after open time'
    : undefined;
const isPositive = value =>
  value <= 0 ? 'Value must be greater than zero' : undefined;
const isNotNegative = value =>
  value < 0 ? 'Value must be greater than or equal to zero' : undefined;

export const validations = {
  afterStartDate,
  beforeEndDate,
  conflictingSubEntry,
  email,
  greaterThanOpenTime,
  isNotNegative,
  isPositive,
  lessThanCloseTime,
  officeName,
  phone,
  required,
  zipUS,
  zipCA
};
