export const officesColumns = [
  {
    index: 1,
    key: 'officeInformation.name',
    essential: true,
    text: 'Name',
    spacing: { xs: 8, sm: 4 },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'officeInformation.address.city',
    essential: false,
    text: 'City',
    spacing: { xs: 3, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'officeInformation.address.state',
    essential: false,
    text: 'State/Province',
    spacing: { xs: 3, sm: 3 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'status',
    essential: true,
    text: 'Status',
    spacing: { xs: 4, sm: 1 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 5,
    key: 'offerings',
    essential: false,
    text: 'Subscriptions',
    spacing: { xs: 3, sm: 2 },
    icon: true,
    sortable: false,
    defaultSortColumn: false,
    defaultSort: 'asc'
  }
];
