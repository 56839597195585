import React from 'react';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

const RunReport = ({
  classes,
  previousPage,
  values,
  allOffices,
  updatePage,
  isLoading
}) => {
  const renderOfficeNames = () => {
    if (typeof values.offices[0] !== 'string') {
      values.offices.forEach((office, index) => {
        values.offices.splice(index, 1, office.id);
      });
    }
    return values.offices.map((officeId, index) => {
      const office = allOffices.find(office => office.id === officeId);
      if (office) {
        return (
          <div key={index} className={classes.officeName}>
            <Typography key={index} variant='body1'>
              {office.officeInformation.name}
            </Typography>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const transformDate = date => {
    return moment(date).calendar();
  };

  const renderReportTypeSection = () => {
    const editPage = -2;
    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'> Report Information </Typography>
          <span onClick={() => updatePage(editPage)} className={classes.edit}>
            Edit
          </span>
        </div>
        <div className={classes.sectionBody}>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Report Type:</Typography>
            <Typography variant='body1'>{values.reportType}</Typography>
          </div>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Date Range:</Typography>
            <Typography variant='body1'>
              {transformDate(values.startDate)}
              &nbsp; to &nbsp;
              {transformDate(values.endDate)}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderOfficeSelectionSection = () => {
    const editPage = -1;
    if (!values.clientGroupName) {
      values.clientGroupName = 'Custom Offices';
    }
    if (!values.regionName) {
      values.regionName = 'No Region Selected';
    }
    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'> DSO/Office Information</Typography>
          <span onClick={() => updatePage(editPage)} className={classes.edit}>
            Edit
          </span>
        </div>
        <div className={classes.sectionBody}>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Client Group Selected:</Typography>
            <Typography variant='body1'>{values.clientGroupName}</Typography>
          </div>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Region Selected:</Typography>
            <Typography variant='body1'>{values.regionName}</Typography>
          </div>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Offices:</Typography>
            <div className={classes.officeList}>{renderOfficeNames()}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.section}>
      {renderReportTypeSection()}

      {renderOfficeSelectionSection()}

      <div className={classes.sectionActions}>
        <div className={classes.buttonContainer}>
          <Button color='secondary' type='reset' variant='outlined'>
            Cancel
          </Button>
          <Button color='primary' onClick={previousPage} variant='outlined'>
            Back
          </Button>
          <Button
            color='primary'
            type='submit'
            disabled={isLoading}
            variant='outlined'
          >
            {isLoading ? (
              <Loader type='Oval' color='#696969' height='12' width='12' />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

RunReport.propTypes = {
  updatePage: PropTypes.func.isRequired,
  allOffices: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  previousPage: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default RunReport;
