import _ from 'lodash';
import React, { useState } from 'react';
import ClosedIcon from '@material-ui/icons/KeyboardArrowRight';
import OpenIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import PricingPlanDetails from '../../../../../components/Billing/PricingPlanDetails';

const PricingPlan = ({ classes, id, plan }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const planName = plan.name ? plan.name : _.startCase(id);
  const planDetailsIcon = detailsVisible ? <OpenIcon /> : <ClosedIcon />;

  return (
    <>
      <div className={classes.root}>
        <Typography
          onClick={() => setDetailsVisible(!detailsVisible)}
          onKeyPress={e => {
            if (e.which === 13) setDetailsVisible(!detailsVisible);
          }}
          tabIndex={0}
          variant='body2'
          className={
            detailsVisible
              ? `${classes.planName} ${classes.planNameTextDetailsVisible}`
              : `${classes.planName} ${classes.planNameText}`
          }
        >
          <span>{planName}</span>
          <span className={classes.showHideButton}>{planDetailsIcon}</span>
        </Typography>
      </div>
      {detailsVisible ? (
        <div className={classes.planDetails}>
          <PricingPlanDetails pricingDetails={plan} />
        </div>
      ) : null}
    </>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  planName: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 4,
    marginTop: 1,
    outline: 'none',
    '&:hover': {
      backgroundColor: '#ddd',
      borderRadius: 8,
      cursor: 'pointer'
    },
    '&:focus': {
      backgroundColor: '#ddd',
      borderRadius: 8,
      outline: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      width: '100%',
      '& > span': {
        flex: 1,
        justifyContent: 'flex-start'
      },
      '& > span:first-child': {
        flex: 2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left'
      },
      '& > span:last-child': {
        display: 'flex',
        '& > svg': {
          marginBottom: 4
        }
      }
    }
  },
  planNameTextDetailsVisible: {
    fontWeight: 500
  },
  planNameText: {
    fontWeight: 400
  },
  showHideButton: {
    fontSize: 10,
    fontStyle: 'italic',
    padding: '0px 4px',
    marginTop: 4
  },
  planDetails: {
    marginLeft: 6,
    marginBottom: 24
  }
});

PricingPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PricingPlan);
