import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import TeamNameData from './TeamNameData';
import TeamLeadData from './TeamLeadData';
import TeamServiceData from './TeamServiceData';
import TeamInfoMembers from './TeamMembersData';
import DialogEditTeamName from './DialogEditTeamName';
import DialogEditTeamService from './DialogEditTeamService';
import DialogEditTeamLead from './DialogEditTeamLead';
import DialogEditTeamMembers from './DialogEditTeamMembers';
import DialogDeleteTeamMember from './DialogDeleteTeamMember';
import { firestoreConnect } from 'react-redux-firebase';

const TeamInformation = ({
  classes,
  teamData,
  relevantEmployeeLead,
  relevantEmployeesMembers,
  allowedToEdit
}) => (
  <div className={classes.teamInfoRoot}>
    <TeamNameData
      data={teamData}
      label='Team Name'
      name='name'
      showEdit={allowedToEdit}
    />

    <TeamLeadData
      data={teamData}
      label='Team Lead'
      name='lead'
      membersKey='members'
      showEdit={allowedToEdit}
      relevantEmployeeLead={relevantEmployeeLead}
    />

    <TeamServiceData
      data={teamData}
      label='Service Offering'
      name='serviceOffering'
      showEdit={allowedToEdit}
    />

    <TeamInfoMembers
      data={teamData}
      label='Team Members'
      name='members'
      showEdit={allowedToEdit}
      relevantEmployeesMembers={relevantEmployeesMembers}
    />

    <DialogEditTeamName team={teamData && teamData.id} />

    <DialogEditTeamLead team={teamData && teamData.id} />

    <DialogEditTeamService team={teamData && teamData.id} />

    <DialogEditTeamMembers team={teamData && teamData.id} />

    <DialogDeleteTeamMember />
  </div>
);

const mapStateToProps = (state, { team }) => {
  const relevantEmployees = state.firestore.data.employees;
  const teamData = team;
  const relevantEmployeeLead =
    team && relevantEmployees ? relevantEmployees[team.lead] : null;
  const relevantEmployeesMembers =
    team && relevantEmployeeLead
      ? team.members.map(employee => {
          relevantEmployees[employee]
            ? (employee = {
                id: employee,
                name: `${relevantEmployees[employee].firstName} ${relevantEmployees[employee].lastName}`
              })
            : (employee = { id: employee });
          return employee;
        })
      : relevantEmployees;

  return { teamData, relevantEmployeesMembers, relevantEmployeeLead };
};

export default compose(
  firestoreConnect(props => {
    let teamLeadUuid = props && props.team && props.team.lead,
      membersUuids = props && props.team && props.team.members;
    let relevantEmployeeQueries = [];

    if (teamLeadUuid && membersUuids) {
      const relevantEmployees = [];
      relevantEmployees.push({
        role: 'lead',
        uuid: teamLeadUuid
      });

      membersUuids.forEach(memberUuid => {
        relevantEmployees.push({
          role: 'member',
          uuid: memberUuid
        });
      });

      relevantEmployees.forEach(employee => {
        const { uuid } = employee;
        relevantEmployeeQueries.push({
          collection: `employees`,
          doc: uuid
        });
      });
    }

    return relevantEmployeeQueries;
  }),
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(TeamInformation);
