import _ from 'lodash';
import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import DialogIndiEntriesDetails from './DialogIndiEntriesDetails';
import DialogIndiMistakesDetails from './DialogIndiMistakesDetails';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import theme from '../services/theme';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogIndiEntriesDetailsClick,
  dialogIndiEntriesDetailsItem,
  dialogIndiMistakesDetailsClick,
  dialogIndiMistakesDetailsItem
} from '../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class EmployeesStatsTable extends Component {
  state = { employees: null };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { collection, emps } = this.props;

    if (!_.isEqual(collection, nextProps.collection)) {
      this.setState({ employees: null });
    }

    if (!_.isEqual(emps, nextProps.emps)) {
      this.setState({ employees: nextProps.emps });
    }
  }

  renderLoader() {
    const { employees } = this.state;
    const { classes } = this.props;

    if (!employees) {
      return (
        <div className={classes.loaderContainer}>
          <Loader
            type='Oval'
            color={theme.palette.primary.main}
            height='30'
            width='30'
          />
        </div>
      );
    }
  }

  renderDialogs() {
    const { mistakes, collection } = this.props;

    return mistakes ? (
      <DialogIndiMistakesDetails collection={collection} />
    ) : (
      <DialogIndiEntriesDetails collection={collection} />
    );
  }

  render() {
    const {
      classes,
      mistakes,
      orderByValue,
      orderByOrder,
      dialogIndiEntriesDetailsClick,
      dialogIndiEntriesDetailsItem,
      dialogIndiMistakesDetailsClick,
      dialogIndiMistakesDetailsItem
    } = this.props;
    const { employees } = this.state;
    const now = moment(new Date());
    const today = now.format('YYYY-MM-DD');
    const currentMonth = now.format('YYYY-MM');

    return (
      <div className={classes.tableContainer}>
        {this.renderLoader()}
        {this.renderDialogs()}
        {employees
          ? _.orderBy(employees, orderByValue, orderByOrder).map(
              (employee, index) => (
                <ButtonBase
                  key={employee.id}
                  className={classes.buttonBase}
                  onClick={() => {
                    if (mistakes) {
                      dialogIndiMistakesDetailsItem({ ...employee, index });
                      dialogIndiMistakesDetailsClick(true);
                    } else {
                      dialogIndiEntriesDetailsItem({ ...employee, index });
                      dialogIndiEntriesDetailsClick(true);
                    }
                  }}
                >
                  <Grid container spacing={24} className={classes.tableRow}>
                    <Grid
                      item
                      xs={6}
                      className={`${classes.cell} ${classes.firstCell}`}
                      style={{ padding: 0, paddingLeft: 12 }}
                    >
                      <div className={classes.avatar}>
                        <img
                          src={
                            employee.avatarUrl
                              ? employee.avatarUrl
                              : `https://picsum.photos/100?image=${400 + index}`
                          }
                          alt={employee.displayName}
                        />
                      </div>
                      <Typography
                        variant='button'
                        className={classes.employeeName}
                      >
                        {employee.displayName
                          ? employee.displayName
                          : `${employee.firstName} ${employee.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.cell}
                      style={{ padding: 0 }}
                    >
                      {employee.stats
                        ? employee.stats.daily[today] >= 0
                          ? employee.stats.daily[today]
                          : null
                        : null}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.cell}
                      style={{ padding: 0 }}
                    >
                      {employee.stats
                        ? employee.stats.monthly[currentMonth] >= 0
                          ? employee.stats.monthly[currentMonth]
                          : null
                        : null}
                    </Grid>
                  </Grid>
                </ButtonBase>
              )
            )
          : null}
      </div>
    );
  }
}

const styles = theme => ({
  loaderContainer: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableContainer: {
    width: '90%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: theme.spacing.unit * 34,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 39
    }
  },
  buttonBase: {
    width: '100%',
    marginBottom: 0,
    marginTop: 3,
    minHeight: 40
  },
  tableRow: {
    borderBottom: `1px solid #f5f5f5`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    fontSize: theme.spacing.unit * 2
  },
  firstCell: {
    justifyContent: 'flex-start'
  },
  avatar: {
    '& img': {
      height: 35,
      width: 35,
      borderRadius: '50%'
    },
    marginRight: 10
  },
  employeeName: {
    fontSize: theme.spacing.unit * 1.4
  }
});

const mapStateToProps = (state, props) => {
  const now = moment(new Date());
  const today = now.format('YYYY-MM-DD');
  const currentMonth = now.format('YYYY-MM');
  const orderedData = state.firestore.ordered;
  let emps = _.filter(JSON.parse(JSON.stringify(props.employees)), o =>
    props.collection === 'scheduled' || props.collection === 'callsAnswered'
      ? o.groups.includes(props.collection) || o.groups.includes('teamLead')
      : props.collection === 'notScheduled'
      ? o.groups.includes('scheduled') || o.groups.includes('teamLead')
      : o.groups.includes(props.collection)
  );

  emps.forEach(({ id }) => {
    _.mapKeys(emps, 'id')[id].stats = {
      daily: { [`${today}`]: 0 },
      monthly: { [`${currentMonth}`]: 0 }
    };
  });

  if (!_.isEmpty(orderedData)) {
    for (let key in orderedData) {
      let data = orderedData[key][0];
      let empId = key.split('-')[1];

      if (_.mapKeys(emps, 'id')[empId]) {
        let statsKey =
          key.split('-')[0] === 'employeeStats'
            ? 'daily'
            : key.split('-')[0] === 'employeeStatsMonthly'
            ? 'monthly'
            : null;

        if (statsKey) {
          let statsObj = _.mapKeys(emps, 'id')[empId].stats[statsKey];
          _.mapKeys(emps, 'id')[empId].stats[statsKey] = {
            ...statsObj,
            ...data
          };
        }
      }
    }
  }

  return { emps };
};

export default compose(
  connect(mapStateToProps, {
    dialogIndiEntriesDetailsClick,
    dialogIndiEntriesDetailsItem,
    dialogIndiMistakesDetailsClick,
    dialogIndiMistakesDetailsItem
  }),
  firestoreConnect(props => {
    const employees = props.employees;
    let queries = [];

    if (employees) {
      employees.forEach(employee => {
        if (props.mistakes) {
          queries.push(
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: employee.id,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: 'mistakes',
                      doc: props.collection,
                      subcollections: [
                        { collection: 'statistics', doc: 'daily' }
                      ]
                    }
                  ]
                }
              ],
              storeAs: `employeeStats-${employee.id}`
            },
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: employee.id,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: 'mistakes',
                      doc: props.collection,
                      subcollections: [
                        { collection: 'statistics', doc: 'monthly' }
                      ]
                    }
                  ]
                }
              ],
              storeAs: `employeeStatsMonthly-${employee.id}`
            }
          );
        } else {
          queries.push(
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: employee.id,
                  doc: 'statistics',
                  subcollections: [
                    { collection: props.collection, doc: 'daily' }
                  ]
                }
              ],
              storeAs: `employeeStats-${employee.id}`
            },
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: employee.id,
                  doc: 'statistics',
                  subcollections: [
                    { collection: props.collection, doc: 'monthly' }
                  ]
                }
              ],
              storeAs: `employeeStatsMonthly-${employee.id}`
            }
          );
        }
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(EmployeesStatsTable);
