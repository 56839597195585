import React, { useState, useContext } from 'react';
import helpers from '../../../helpers';
import OfficeInformation from './OfficeInformation';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { AppContext } from '../../../contexts';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'typy';
import { styles } from './styles';

const Details = ({ office, sitemapPath, match }) => {
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToEdit = helpers.allowedTo('edit', sitemapPath, userGroups);
  const [officeRetrieved, setOfficeRetrieved] = useState(false);

  let officeFromParams;
  if (!office && !officeRetrieved) {
    const { officeId } = match.params;
    const getOffice = async () => {
      const office = await firebase
        .firestore()
        .collection('clients')
        .doc(officeId)
        .get();

      officeFromParams = office.data();
      setOfficeRetrieved(true);
    };
    getOffice();
  }

  return (
    <OfficeInformation
      office={office ? office : officeFromParams}
      allowedToEdit={allowedToEdit}
    />
  );
};

Details.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  office: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(Details);
