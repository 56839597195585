import qs from 'qs';

export const generateRandomKey = () =>
  (Math.random() * (10000 - 1000) + 1000).toString();

export const capitalizeFirstLetter = string => {
  let newString = '';

  string.split(' ').forEach((word, index) => {
    if (word) {
      if (index === 0) {
        newString = `${word[0].toUpperCase() + word.slice(1)}`;
      } else {
        newString = `${newString} ${word[0].toUpperCase() + word.slice(1)}`;
      }
    }
  });

  return newString;
};

export const emailInCollection = (email, collection) => {
  let emailInCol = false;

  if (collection) {
    collection.forEach(obj => {
      if (obj.email && obj.email === email) {
        emailInCol = true;
      }
    });
  }

  return emailInCol;
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const removeQueryParam = (queryString, removeKey) => {
  const queryObj = qs.parse(queryString.slice(1));
  delete queryObj[removeKey];
  const newQueryString = `?${qs.stringify(queryObj)}`;

  return newQueryString;
};

export const getNthOccurrence = (string, character, nth) => {
  const firstIndex = string.indexOf(character);
  const lengthUpToFirstIndex = firstIndex + 1;

  if (nth === 1) {
    return firstIndex;
  }
  const stringAfterFirstOccurrence = string.slice(lengthUpToFirstIndex);
  const nextOccurrence = getNthOccurrence(
    stringAfterFirstOccurrence,
    character,
    nth - 1
  );

  return nextOccurrence === -1 ? -1 : lengthUpToFirstIndex + nextOccurrence;
};

export const replaceAt = (string, index, newCharacter) =>
  string.replace(/./g, (c, i) => (i === index ? newCharacter : c));

export const isDateRangeIdentifier = id => {
  const dateRangeIdentifiers = ['period', 'time', 'date'];
  const idExemptions = ['periodCharge'];
  const identifiersMatched = [];

  dateRangeIdentifiers.forEach(identifier => {
    identifiersMatched.push(
      !!(id.toLowerCase().includes(identifier) && !idExemptions.includes(id))
    );
  });

  return identifiersMatched.includes(true);
};

export const datesToISOStringInArray = array => {
  const newArray = [];

  if (array) {
    array.forEach(item => {
      newArray.push(
        new Date(item).toString() !== 'Invalid Date'
          ? new Date(item).toISOString()
          : item
      );
    });
  }

  return newArray;
};
