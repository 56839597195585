import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  dialogEditOfficeFive9DataItem,
  dialogEditOfficeFive9DataClick
} from '../../../actions';
import EditButton from './EditButton';

const OfficeFive9Data = ({
  classes,
  data,
  dialogEditOfficeFive9DataItem,
  dialogEditOfficeFive9DataClick,
  label,
  name,
  showEdit,
  skillName,
  skills
}) => {
  const onClick = () => {
    dialogEditOfficeFive9DataItem({
      key: name,
      label,
      data: { ...data, skillName },
      skills
    });
    dialogEditOfficeFive9DataClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        {t(data, `five9.skills.${name}`).safeObject
          ? data.five9.skills[name]
          : '-'}
        {skillName ? ` — ${skillName}` : ''}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { name, data }) => {
  const five9Data = state.firestore.ordered[`${name}${data.id}Five9Data`];
  const five9SkillsData =
    state.firestore.ordered[`${name}${data.id}Five9SkillsData`];
  const skillName =
    isLoaded(five9Data) && !isEmpty(five9Data) && data.five9.skills[name]
      ? five9Data[0].name
      : '';
  const skills =
    isLoaded(five9SkillsData) && !isEmpty(five9SkillsData)
      ? five9SkillsData
      : [];

  return { skillName, skills: _.filter(skills, o => !o.usedByClient) };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeFive9DataItem,
    dialogEditOfficeFive9DataClick
  }),
  firestoreConnect(props => {
    const queries = [];
    const { data, name } = props;

    if (data) {
      if (data.five9 && data.five9.skills) {
        queries.push({
          collection: 'five9',
          doc: 'skills',
          subcollections: [
            {
              collection: 'all',
              doc: data.five9.skills[name]
            }
          ],
          storeAs: `${name}${data.id}Five9Data`
        });
      }

      queries.push({
        collection: 'five9',
        doc: 'skills',
        subcollections: [{ collection: 'all' }],
        storeAs: `${name}${data.id}Five9SkillsData`
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(OfficeFive9Data);
