import { db } from '../../../../index';

export const creditsFilters = [
  {
    index: 1,
    key: 'client',
    options: () =>
      new Promise((resolve, reject) => {
        const options = [];

        return db
          .collection('clients')
          .orderBy('officeInformation.name', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const client = doc.data();
              const clientName = client.officeInformation.name;

              options.push({
                key: doc.id,
                text: clientName,
                value: `client,==,${doc.id}`
              });
            });

            return resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Office'
  },
  {
    index: 2,
    key: 'createdTime',
    type: 'dateRange',
    label: 'Create Date'
  },
  {
    index: 3,
    key: 'type',
    options: [
      { key: 'correction', text: 'Correction' },
      { key: 'promotion', text: 'Promotion' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Type'
  },
  {
    index: 4,
    key: 'status',
    options: [
      { key: 'available', text: 'Available' },
      { key: 'used', text: 'Used' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Status'
  }
];
