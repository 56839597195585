import {
  DIALOG_ADD_EDIT_OFFICE_USER_CLICK,
  DIALOG_ADD_EDIT_OFFICE_USER_ITEM
} from '../actions/types';

export const dialogAddEditOfficeUserOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_OFFICE_USER_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditOfficeUserItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_OFFICE_USER_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogAddEditOfficeUserReducer = {
  dialogAddEditOfficeUserOpen,
  dialogAddEditOfficeUserItem
};
