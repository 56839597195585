import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import DataTable from '../../../../components/DataTable';
// import DialogBillingItem from '../../components/DialogBillingItem';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
// import TransactionItemForm from './components/TransactionItemForm';
// import qs from 'qs';
import Tooltip from '@material-ui/core/Tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { transactionsColumns } from './columns';
import { transactionsFilters } from './filters';

class Transactions extends Component {
  state = { anchorEl: null };

  handlePopoverClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  // renderBillingItemDialog() {
  //   const { history, location } = this.props;

  //   return (
  //     <DialogBillingItem
  //       title='Transactions'
  //       collection='transactions'
  //       dialogBody={(data) => (
  //         <TransactionItemForm
  //           data={data}
  //           history={history}
  //           location={location}
  //         />
  //       )}
  //       history={history}
  //       location={location}
  //     />
  //   );
  // }

  render() {
    const { tabId } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <span>
        <DataTable
          {...this.props}
          collection='transactions'
          columns={transactionsColumns}
          clearSearchPath={`/billing${
            tabId && tabId !== 'undefined' ? '/' + tabId : ''
          }`}
          sceneUri='billing'
          title='Transactions'
          // onRowClickLink={(data, pathname) => {
          //   let path = pathname;

          //   if (pathname.indexOf('dialogData') > -1) {
          //     let searchObj = qs.parse(pathname.split('?')[1])
          //     delete searchObj.dialogData
          //     let search = qs.stringify(searchObj)

          //     path = `${pathname.split('?')[0]}?${search}`
          //   }

          //   return `${path}${path.indexOf('?') > -1 ? '&' : '?'}dialogData=${JSON.stringify({ id: data.id })}`;
          // }}
          tableActionIcons={[
            {
              render: (key, filtersApplied, onClearFilter) => (
                <span key={key}>
                  <FilterClearChip
                    filtersApplied={filtersApplied}
                    onClearFilter={onClearFilter}
                  />
                  <Tooltip title='Filters'>
                    <IconButton
                      aria-label='Filters'
                      aria-owns={open ? 'simple-popper' : null}
                      onClick={this.handlePopoverClick}
                    >
                      {filtersApplied ? (
                        <Badge color='secondary' variant='dot'>
                          <FilterIcon />
                        </Badge>
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </span>
              )
            }
          ]}
          tableFilterable={true}
          filterData={{
            anchorEl,
            filters: transactionsFilters,
            open,
            handleClose: this.handlePopoverClose
          }}
        />
        {/* {this.renderBillingItemDialog()} */}
      </span>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  return { location };
};

export default compose(connect(mapStateToProps, {}))(Transactions);
