import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import OfferingPricingPlan from './components/OfferingPricingPlan';

const Pricing = ({ classes, office, offeringId, pricing, offering }) => (
  <div className={classes.dataRow}>
    <div className={classes.dataLabel}>
      {offering || pricing.plan === 'custom' ? (
        <div className={classes.offeringLabel}>
          {pricing.plan === 'custom' ? _.startCase(offeringId) : offering.name}
          <br />
          {office.offerings[offeringId] ? (
            <span className={classes.offeringStatus}>Subscribed</span>
          ) : (
            <span className={classes.offeringStatusNotSubscribed}>
              Not Subscribed
            </span>
          )}
        </div>
      ) : (
        <div className={classes.loading} />
      )}
    </div>
    <div className={classes.dataText}>
      {(offering && pricing) || (pricing && pricing.plan === 'custom') ? (
        <OfferingPricingPlan
          office={office}
          offering={offering}
          pricing={pricing}
          subscribed={!!office.offerings[offeringId]}
        />
      ) : (
        <div className={classes.loading} />
      )}
    </div>
  </div>
);

const mapStateToProps = (state, { offeringId }) => {
  const offeringData = state.firestore.ordered[`${offeringId}Data`];
  let offering = null;

  if (isLoaded(offeringData) && !isEmpty(offeringData)) {
    offering = offeringData[0];
  }

  return { offering };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(({ offeringId, pricing }) => {
    const queries = [];

    if (offeringId && pricing.plan !== 'custom') {
      queries.push({
        collection: 'offerings',
        doc: offeringId,
        storeAs: `${offeringId}Data`
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(Pricing);
