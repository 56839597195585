import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

class SelectField extends Component {
  state = { labelWidth: 0 };

  componentDidMount() {
    const { autoPopulateFromParam, editField, selected } = this.props;

    if (editField || autoPopulateFromParam || selected) {
      this.handleSelectClick();
    }
  }

  componentDidUpdate(prevProps) {
    const { label } = this.props;

    if (prevProps.label !== label) {
      this.handleSelectClick();
    }
  }

  handleSelectClick() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  resolvePath(obj, objPath) {
    let path = objPath;
    path = path.split('.');
    let current = obj;
    while (path.length) {
      if (typeof current !== 'object') return undefined;
      current = current[path.shift()];
    }

    return current;
  }

  renderOptions(options) {
    const {
      optionKey,
      optionText,
      optionValue,
      optionTextDelimiter
    } = this.props;

    return _.map(options, option => {
      return (
        <option key={option[optionKey]} value={option[optionValue]}>
          {optionText instanceof Function
            ? optionText(option)
            : optionText.includes('.')
            ? this.resolvePath(option, optionText)
            : optionText instanceof Array
            ? optionTextDelimiter
              ? `${option[optionText[0]]}${optionTextDelimiter}${
                  option[optionText[1]]
                }`
              : `${option[optionText[0]]} ${option[optionText[1]]}`
            : option[optionText]}
        </option>
      );
    });
  }

  render() {
    const {
      classes,
      disabled,
      label,
      meta: { error, touched },
      options,
      textFieldContainerClass,
      margin
    } = this.props;
    const { labelWidth } = this.state;

    return (
      <FormControl
        variant='outlined'
        className={`${classes.selectField} ${textFieldContainerClass}`}
        disabled={disabled}
        error={touched && error ? true : false}
      >
        <InputLabel ref={ref => (this.InputLabelRef = ref)}>{label}</InputLabel>
        <Select
          native
          onFocus={() => this.handleSelectClick()}
          onClick={() => this.handleSelectClick()}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              margin={margin ? margin : 'dense'}
            />
          }
          {...this.props.input}
        >
          <option value='' />
          {options ? this.renderOptions(options) : null}
        </Select>
        {touched && error ? <FormHelperText>{error}</FormHelperText> : ''}
      </FormControl>
    );
  }
}

const styles = theme => ({
  selectField: {
    width: theme.textField.width,
    margin: '8px auto 4px auto',
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

export default withStyles(styles, { withTheme: true })(SelectField);
