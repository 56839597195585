import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  FieldCheckbox,
  FieldDate,
  FieldSelect,
  FieldText,
  FieldTime,
  DecoratedFieldText
} from '../../../../../components/Fields';
import { futureDate, date, required, time } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import helpers from '../../../../../helpers';
import { firestore } from 'firebase';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const ContactItemForm = ({
  classes,
  relo,
  dialogOnClose,
  route,
  offices,
  employees
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [needsFollowUp, setNeedsFollowUp] = useState(false);
  const [actionItem, setActionItem] = useState(false);
  const [actionNeeded, setActionNeeded] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = profile?.data?.groups ?? [];
  const manageGroups = route?.metadata?.permissions?.manage ?? [];
  const userAllowedToManage = userGroups.some(g => manageGroups.includes(g));

  const onSubmit = async (values, { setSubmitting }) => {
    let newContact = {};
    newContact.contactedBy = profile.data.id;
    newContact.contactedByName = profile.data.displayName;
    newContact.contactedOn = new Date();
    newContact.urgent = !!values.urgent;
    newContact.actionItem = actionNeeded || needsFollowUp ? true : false;
    newContact.needsFollowUp = !!needsFollowUp;
    newContact.notes = values.notes;
    newContact.reason = values.reason;

    if (values.actionTaken) {
      newContact.actionTaken = values.actionTaken;
    }

    if (values.neededFollowUp) {
      newContact.neededFollowUp = values.neededFollowUp;
    }

    if (values.appointmentTime && values.appointmentDate) {
      const reloOffice = offices.find(office => office.id === relo.office);
      const officeTimezone = reloOffice.officeInformation.timezone;
      const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;
      const appointmentTime = moment.tz(apptDateTime, officeTimezone);
      newContact.appointmentTime = new Date(appointmentTime.format());
    }

    if (values.spanishSpEmpHelp) {
      newContact.spanishSpEmpHelp = values.spanishSpEmpHelp;
      newContact.spanishSpEmp = values.spanishSpEmp;
    }

    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    try {
      const reloRef = firebase.firestore().collection('relos').doc(relo.id);

      await reloRef.update({
        contacts: firestore.FieldValue.arrayUnion(newContact)
      });

      let setObj = {};

      if (relo.contacts.length < 2) {
        setObj.status = 'inProgress';
      }

      if (values.appointmentTime) {
        setObj.status = 'completed';
        setObj.result = 'scheduled';
        setObj.scheduledOn = new Date();
      }

      if (relo.contacts.length === 2 && !values.appointmentTime) {
        setObj.status = 'completed';
        setObj.result = 'unableToSchedule';
      }

      await reloRef.set(setObj, { merge: true });

      dialogOnClose(true);
    } catch (e) {
      console.error(e);
      setError('Failed to save call entry');
      setLoading(null);
      setSubmitting(false);
    }
  };

  const NeedsFollowUpIcon = () => {
    return needsFollowUp ? (
      <RemoveIcon />
    ) : (
      <AddCircleIcon
        onClick={() => {
          setActionItem(!actionItem);
        }}
      />
    );
  };

  const ActionNeededIcon = () => {
    return actionNeeded ? <RemoveIcon /> : <AddCircleIcon />;
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(selectName)}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(selectName)}s
        </span>
      );
    }
    return vanityName || _.startCase(selectName);
  };

  const validations = {
    appointmentDate: v =>
      helpers.composeFieldValidation(v, [required, futureDate]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time]),
    lastVisitDate: date,
    spanishSpEmp: required,
    type: required,
    doNotBillCategory: required,
    category: required,
    subCategory: required,
    notes: required
  };

  const getSubCategory = async category => {
    setSubCategories([]);
    const ref = firebase
      .firestore()
      .collection('offeringsCategories')
      .doc(category)
      .collection('subcategories')
      .where('offerings', 'array-contains', 'relos');

    await ref.get().then(snapShots => {
      const subCategoriesDocs = [];
      snapShots.forEach(doc => {
        subCategoriesDocs.push({ ...doc.data(), id: doc.id });
      });
      setSubCategories(subCategoriesDocs);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .where('offerings', 'array-contains', 'relos')
          .get();

        const categoriesDocs = [];
        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });

        setCategories(categoriesDocs);
        setLoading(null);
      } catch (err) {
        console.error(err);
        setCategories(null);
        setLoading(null);
        setError(err.toString());
      }
    };
    fetchData();
  }, [firebase, userAllowedToManage]);

  const handleSubCategory = newCategory => {
    if (newCategory !== '') {
      getSubCategory(newCategory);
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          <div className={classes.sectionTitle}>
            <Typography variant='subtitle2'>Entry Details</Typography>
          </div>
          <Field name='reason.category' validate={validations.category}>
            {({ form, field }) => {
              return (
                <FieldSelect
                  margin='none'
                  form={form}
                  field={field}
                  loading={categories.length === 0}
                  disabled={categories.length === 0 || loading !== null}
                  label={selectLabel(categories, 'Category')}
                  options={_.sortBy(categories, 'category')}
                  optionKey='id'
                  optionText='category'
                  optionValue='id'
                  onChange={newCategory => {
                    handleSubCategory(newCategory);
                    if (
                      values.reason &&
                      newCategory !== values.reason.category &&
                      values.reason.subcategory
                    ) {
                      values.reason.subcategory = '';
                    }
                    if (!values.reason) {
                      values.reason = {};
                    }
                    // lmW1HPZWpEOUjChoTSi9 is Appointment Scheduled in
                    // offerings categories
                    if (newCategory === 'lmW1HPZWpEOUjChoTSi9') {
                      setScheduled(newCategory);
                    }
                    if (newCategory !== 'lmW1HPZWpEOUjChoTSi9') {
                      if (values.appointmentDate || values.appointmentTime) {
                        delete values.appointmentDate;
                        delete values.appointmentTime;
                      }
                      setScheduled(false);
                    }
                  }}
                />
              );
            }}
          </Field>
          {values.reason ? (
            <>
              <Field
                name='reason.subcategory'
                validate={validations.subCategory}
              >
                {({ form, field }) => {
                  return (
                    <FieldSelect
                      margin='none'
                      form={form}
                      field={field}
                      loading={subCategories.length === 0}
                      disabled={subCategories.length === 0 || loading !== null}
                      label={selectLabel(subCategories, 'Subcategory')}
                      options={_.sortBy(subCategories, 'category')}
                      optionKey='id'
                      optionText='category'
                      optionValue='id'
                      component={FieldSelect}
                      onChange={newSubCategory => {
                        if (!values.reason) {
                          values.reason = {};
                        }
                      }}
                    />
                  );
                }}
              </Field>
            </>
          ) : null}
          {scheduled ? (
            <div className={classes.row}>
              <Field
                name='appointmentDate'
                label='Appointment Date'
                variant='outlined'
                placeholder='mm/dd/yyyy'
                component={FieldDate}
                disabled={loading !== null}
                validate={validations.appointmentDate}
              />
              <Field
                name='appointmentTime'
                label='Appointment Time'
                variant='outlined'
                placeholder='HH:mm'
                component={FieldTime}
                disabled={loading !== null}
                validate={validations.appointmentTime}
              />
            </div>
          ) : null}
          <FieldCheckbox
            name='urgent'
            label='Mark as Urgent'
            disabled={loading !== null}
            checked={!!values.urgent}
          />
          <FieldCheckbox
            name='spanishSpEmpHelp'
            label='Spanish-Speaking Caller Helped'
            disabled={loading !== null}
            checked={!!values.spanishSpEmpHelp}
          />
          {values.spanishSpEmpHelp ? (
            <Field
              name='spanishSpEmp'
              loading={employees.length === 0}
              disabled={employees.length === 0 || loading !== null}
              label={selectLabel(
                employees,
                'employee',
                'Spanish-Speaking Caller'
              )}
              options={employees}
              optionKey='id'
              optionText={['firstName', 'lastName']}
              optionValue='id'
              component={FieldSelect}
              validate={validations.spanishSpEmp}
            />
          ) : null}
          <Field
            name='notes'
            label='Call Notes'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={FieldText}
            validate={validations.notes}
          />
          <Field
            name='actionTaken'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={DecoratedFieldText}
            id='actionTaken'
            label='Action Taken'
            oniconclick={() => {
              if (actionNeeded) {
                delete values.actionTaken;
              }
              setActionNeeded(!actionNeeded);
            }}
            disabler={actionNeeded.toString()}
            iconswitch={actionNeeded.toString()}
            Icon={ActionNeededIcon}
          />
          <Field
            name='neededFollowUp'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={DecoratedFieldText}
            id='needsFollowUp'
            label='Needed Follow Up'
            oniconclick={() => {
              if (needsFollowUp) {
                delete values.neededFollowUp;
              }
              setNeedsFollowUp(!needsFollowUp);
            }}
            disabler={needsFollowUp.toString()}
            iconswitch={needsFollowUp.toString()}
            Icon={NeedsFollowUpIcon}
          />
          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ContactItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  offices: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(ContactItemForm);
