import {
  DIALOG_TEAM_MISTAKES_DETAILS_CLICK,
  DIALOG_TEAM_MISTAKES_DETAILS_ITEM
} from './types';

export const dialogTeamMistakesDetailsClick = status => ({
  type: DIALOG_TEAM_MISTAKES_DETAILS_CLICK,
  payload: status
});

export const dialogTeamMistakesDetailsItem = item => ({
  type: DIALOG_TEAM_MISTAKES_DETAILS_ITEM,
  payload: item
});
