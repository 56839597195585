import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const DataTableBase = ({ classes, children }) => (
  <div className={classes.root}>
    <Paper className={classes.table} elevation={4}>
      {children}
    </Paper>
  </div>
);

const styles = theme => ({
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  table: {
    height: theme.containers.paper.height.default,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  }
});

export default withStyles(styles, { withTheme: true })(DataTableBase);
