import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { dialogDeleteTeamClick, dialogDeleteTeamItem } from '../../../actions';
import { Button } from '@material-ui/core';
import { styles } from './styles';

const DeleteTeamButton = ({
  classes,
  dialogDeleteTeamClick,
  dialogDeleteTeamItem,
  team,
  showEdit
}) => {
  const onClick = () => {
    dialogDeleteTeamItem({ team });
    dialogDeleteTeamClick(true);
  };

  return showEdit ? (
    <Button onClick={onClick} className={classes.deleteTeamButton}>
      Delete
    </Button>
  ) : null;
};

export default compose(
  connect(null, { dialogDeleteTeamClick, dialogDeleteTeamItem }),
  withStyles(styles, { withTheme: true })
)(DeleteTeamButton);
