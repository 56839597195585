import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTransition from './DialogTransition';
import DialogAddEditOfficeUserContent from './DialogAddEditOfficeUserContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { capitalizeFirstLetter } from '../services/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogAddEditOfficeUserClick,
  dialogAddEditOfficeUserItem
} from '../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class DialogAddEditOfficeUser extends Component {
  state = {};

  render() {
    const {
      classes,
      dialogAddEditOfficeUserClick,
      dialogAddEditOfficeUserItem,
      fullScreen,
      item,
      open,
      office
    } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          dialogAddEditOfficeUserClick(!open);
          dialogAddEditOfficeUserItem(null);
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.titleArea}>
          <div className={classes.titleTextArea}>
            <Typography variant='h6' className={classes.title}>
              {item && item.firstName && item.lastName
                ? `${capitalizeFirstLetter(
                    item.firstName
                  )} ${capitalizeFirstLetter(item.lastName)}`
                : item && item.email
                ? item.email
                : 'Add User'}
            </Typography>
            <Typography variant='caption' className={classes.subtitle}>
              {office && office.officeInformation
                ? `${office.officeInformation.name}`
                : ''}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.closeButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={() => {
                dialogAddEditOfficeUserClick(!open);
                dialogAddEditOfficeUserItem(null);
              }}
            >
              <CloseIcon classes={classes.closeIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          <DialogAddEditOfficeUserContent item={item} office={office} />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  titleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    minHeight: theme.spacing.unit * 8,
    display: 'flex',
    padding: theme.spacing.unit * 2,
    paddingBottom: 2,
    paddingTop: 0
  },
  titleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  title: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit,
    width: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: { width: 250 }
  },
  subtitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 1.4,
    marginTop: -theme.spacing.unit
  },
  closeButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: (theme.spacing.unit * 3) / 2
  }
});

const mapStateToProps = (state, props) => {
  return {
    open: state.dialogAddEditOfficeUserOpen,
    item: state.dialogAddEditOfficeUserItem
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogAddEditOfficeUserClick,
    dialogAddEditOfficeUserItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddEditOfficeUser);
