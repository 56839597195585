import {
  DIALOG_ADD_EDIT_OFFICE_USER_CLICK,
  DIALOG_ADD_EDIT_OFFICE_USER_ITEM
} from './types';

export const dialogAddEditOfficeUserClick = status => ({
  type: DIALOG_ADD_EDIT_OFFICE_USER_CLICK,
  payload: status
});

export const dialogAddEditOfficeUserItem = item => ({
  type: DIALOG_ADD_EDIT_OFFICE_USER_ITEM,
  payload: item
});
