const resolvePath = (obj, objPath) => {
  let path = objPath;
  path = path.split('.');
  let current = obj;
  while (path.length) {
    if (typeof current !== 'object') return undefined;
    current = current[path.shift()];
  }

  return current;
};

export default resolvePath;
