import React from 'react';
import ReportsWizard from '../Wizard/index';
import DialogTransition from '../../../../components/DialogTransition';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';

const WizardOverlay = props => {
  const { offices, clientGroups, open, cancel, classes } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={true}
      open={open}
      onClose={cancel}
      onEscapeKeyDown={cancel}
      aria-labelledby='responsive-dialog-title'
      disableBackdropClick
    >
      <ReportsWizard
        offices={offices}
        clientGroups={clientGroups}
        classes={classes}
        cancel={cancel}
      />
    </Dialog>
  );
};

WizardOverlay.propTypes = {
  offices: PropTypes.array.isRequired,
  clientGroups: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired
};

export default WizardOverlay;
