import _ from 'lodash';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import toRenderProps from 'recompose/toRenderProps';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { Area, AreaChart, Tooltip, XAxis } from 'recharts';
import theme from '../../../../../services/theme';

const WithWidth = toRenderProps(withWidth());

const graphWidths = {
  xs: 300,
  sm: 450,
  md: 875,
  lg: 950,
  xl: 950
};

class SectionOverviewGraph extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <WithWidth>
        {({ width }) => (
          <Paper style={styles.root} className={classes.sectionBody}>
            <Grid container>
              <Grid item xs={12} style={styles.graphContainer}>
                <AreaChart
                  width={graphWidths[width]}
                  height={200}
                  data={data}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient
                      id='colorScheduled'
                      x1='0'
                      y1='0'
                      x2='0'
                      y2='1'
                    >
                      <stop
                        offset='5%'
                        stopColor={theme.palette.secondary.main}
                        stopOpacity={1}
                      />
                      <stop
                        offset='95%'
                        stopColor={theme.palette.secondary.main}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient
                      id='colorCallsAnswered'
                      x1='0'
                      y1='0'
                      x2='0'
                      y2='1'
                    >
                      <stop
                        offset='5%'
                        stopColor={theme.palette.tertiary.main}
                        stopOpacity={1}
                      />
                      <stop
                        offset='95%'
                        stopColor={theme.palette.tertiary.main}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey='date'
                    minTickGap={20}
                    axisLine={false}
                    tickSize={3}
                    orientation='bottom'
                  />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Scheduled'
                    stroke={theme.palette.secondary.main}
                    fillOpacity={1}
                    fill='url(#colorScheduled)'
                  />
                  <Area
                    type='monotone'
                    dataKey='Calls Answered'
                    stroke={theme.palette.tertiary.main}
                    fillOpacity={1}
                    fill='url(#colorCallsAnswered)'
                  />
                </AreaChart>
              </Grid>
            </Grid>
            <div
              style={
                width === 'sm' || width === 'xs'
                  ? { ...styles.subtitleContainer, width: '90%' }
                  : styles.subtitleContainer
              }
            >
              <Typography
                variant='button'
                color='primary'
                style={{ fontSize: 12 }}
              >
                30-Day Overview
              </Typography>
            </div>
          </Paper>
        )}
      </WithWidth>
    );
  }
}

const styles = {
  root: {
    width: '100%',
    minHeight: theme.spacing.unit * 3
  },
  graphContainer: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subtitleContainer: {
    borderTop: '1px solid #bfbfbf',
    textAlign: 'left',
    width: '95%',
    margin: '0px auto',
    display: 'block',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10
  }
};

const mapStateToProps = (state, props) => {
  const startMonth = moment(new Date()).subtract(1, 'month');
  const endMonth = moment(new Date());
  const colls = [
    {
      key: 'scheduled',
      name: 'Scheduled',
      data: state.firestore.ordered.overallStatsSch
    },
    {
      key: 'callsAnswered',
      name: 'Calls Answered',
      data: state.firestore.ordered.overallStatsCallsAns
    }
  ];
  let dataReady = false;
  const rawData = [];
  const data = [];

  colls.forEach(({ data }) => (dataReady = isLoaded(data) && !isEmpty(data)));

  if (dataReady) {
    colls.forEach(collection => {
      const dailyStats = collection.data[0];
      delete dailyStats.id;

      for (const day in dailyStats) {
        if (moment(day) >= startMonth && moment(day) < endMonth) {
          rawData.push({
            rawDate: moment(day),
            date: moment(day).format('DD-MMM'),
            [collection.name]: dailyStats[day]
          });
        }
      }
    });

    rawData.forEach(item => {
      if (_.mapKeys(data, 'date')[item.date]) {
        const itemIndex = _.indexOf(data, _.mapKeys(data, 'date')[item.date]);
        data[itemIndex] = { ...data[itemIndex], ...item };
      } else {
        data.push(item);
      }
    });

    data.forEach(item => {
      const itemIndex = _.indexOf(data, item);

      colls.forEach(collection => {
        if (!item[collection.name]) data[itemIndex][collection.name] = 0;
      });
    });
  }

  return { data: _.orderBy(data, ['rawDate'], ['asc']) };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(_ => [
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'scheduled', doc: 'daily' }],
      storeAs: 'overallStatsSch'
    },
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'callsAnswered', doc: 'daily' }],
      storeAs: 'overallStatsCallsAns'
    }
  ])
)(SectionOverviewGraph);
