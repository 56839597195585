import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EditButton from './EditButton';
import {
  dialogEditTeamNameItem,
  dialogEditTeamNameClick
} from '../../../actions';
import { styles } from './styles';

const TeamNameData = ({
  classes,
  data,
  label,
  name,
  showEdit,
  dialogEditTeamNameClick,
  dialogEditTeamNameItem
}) => {
  const onClick = () => {
    dialogEditTeamNameItem({ key: name, label, data });
    dialogEditTeamNameClick(true);
  };
  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>{data && data[name]}</div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditTeamNameClick, dialogEditTeamNameItem }),
  withStyles(styles, { withTheme: true })
)(TeamNameData);
