import {
  DIALOG_ADD_EDIT_SCHEDULED_CLICK,
  DIALOG_ADD_EDIT_NOTSCHEDULED_CLICK,
  DIALOG_ADD_EDIT_RESCHEDULED_CLICK,
  DIALOG_ADD_EDIT_CALLSANSWERED_CLICK,
  DIALOG_ADD_EDIT_SCHEDULED_ITEM,
  DIALOG_ADD_EDIT_NOTSCHEDULED_ITEM,
  DIALOG_ADD_EDIT_RESCHEDULED_ITEM,
  DIALOG_ADD_EDIT_CALLSANSWERED_ITEM
} from '../actions/types';

export const dialogAddEditScheduledOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_SCHEDULED_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditScheduledItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_SCHEDULED_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditNotScheduledOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_NOTSCHEDULED_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditNotScheduledItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_NOTSCHEDULED_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditRescheduledOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_RESCHEDULED_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditRescheduledItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_RESCHEDULED_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditCallsAnsweredOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_CALLSANSWERED_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogAddEditCallsAnsweredItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_ADD_EDIT_CALLSANSWERED_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogAddEditEntryReducer = {
  dialogAddEditScheduledOpen,
  dialogAddEditScheduledItem,
  dialogAddEditNotScheduledOpen,
  dialogAddEditNotScheduledItem,
  dialogAddEditRescheduledOpen,
  dialogAddEditRescheduledItem,
  dialogAddEditCallsAnsweredOpen,
  dialogAddEditCallsAnsweredItem
};
