import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';

const OfficeName = ({ officeName }) => <span>{officeName || null}</span>;

const mapStateToProps = (state, props) => {
  const officeNameData = state.firestore.ordered[`officeName-${props.office}`];
  let officeName;

  if (isLoaded(officeNameData) && !isEmpty(officeNameData)) {
    officeName = officeNameData[0].officeInformation.name;
  }

  return { officeName };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'clients',
      doc: props.office,
      storeAs: `officeName-${props.office}`
    }
  ])
)(OfficeName);
