import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { AppContext } from '../../../../contexts';
import { withRouter } from 'react-router-dom';
import { t } from 'typy';
import _ from 'lodash';
import { Add, FilterList } from '@material-ui/icons';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import filters from './filters';
import DataTable from '../../../../components/DataTable';
import DialogAddEmployeeInfo from './components/DialogAddEmployeeInfo';
import EmployeeInfoForm from './components/EmployeeInfoForm';
import { employeesColumns } from './columns';

const EmployeesData = props => {
  const { history, location, route, tabId } = props;
  const {
    state: { firebase }
  } = useContext(AppContext);
  const dialogDataParam = queryString.parse(location.search).dialogData;
  const allParams = queryString.parse(location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [manConnectData, setManConnectData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [offices, setOffices] = useState([]);
  const param = dialogDataParam ? JSON.parse(dialogDataParam) : {};
  const loadingEditData = !!(param.id && !dialogData);
  const open = Boolean(anchorEl);
  const dialogOnClose = refreshData => {
    const searchObj = queryString.parse(location.search);
    if (refreshData) searchObj.refreshData = true;
    delete searchObj.dialogData;
    const search = queryString.stringify(searchObj);
    history.push(`${location.pathname}?${search}`);
  };
  const handlePopoverClick = e => setAnchorEl(e.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  useEffect(() => {
    const fetchDialogData = async () => {
      const data = dialogDataParam ? JSON.parse(dialogDataParam) : {};
      try {
        if (data.id) {
          const entryDataSnap = await firebase
            .firestore()
            .collection('employees')
            .doc(data.id)
            .get();

          const entryData = { ...entryDataSnap.data(), id: data.id };

          setDialogData(entryData);
        } else {
          setDialogData(null);
        }
      } catch (err) {
        setDialogData(null);
      }
    };
    const fetchData = async () => {
      try {
        const officesQuerySnapshot = await firebase
          .firestore()
          .collection('clients')
          .orderBy('officeInformation.name')
          .get();
        const employeesQuerySnapshot = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        const employeeDocs = [];
        const officeDocs = [];

        employeesQuerySnapshot.forEach(doc => {
          employeeDocs.push({ ...doc.data(), id: doc.id });
        });

        officesQuerySnapshot.forEach(doc => {
          officeDocs.push({ ...doc.data(), id: doc.id });
        });

        setEmployees(employeeDocs);
        setOffices(officeDocs);
      } catch (err) {
        setEmployees(null);
        setOffices(null);
      }
    };
    fetchData();
    setDialogOpen(!!dialogDataParam);
    fetchDialogData();
  }, [dialogDataParam, firebase]);

  useEffect(() => {
    const handleManConnect = () => {
      if (
        !_.isEmpty(allParams) &&
        !allParams.dialogData &&
        offices.length > 0 &&
        !allParams.refreshData &&
        !allParams.term
      ) {
        const path = props.location.pathname;
        const separator = path.indexOf('?') > -1 ? '&' : '?';
        const office = allParams.skill_id
          ? _.find(
              offices,
              o =>
                t(o, 'five9.skills.answering').safeObject === allParams.skill_id
            )
          : '';
        const manConnectFields = {
          callId: allParams.call_id ? allParams.call_id : '',
          patientPhone:
            allParams && allParams.number1
              ? allParams.number1
              : allParams && allParams.patient_phone
              ? allParams.patient_phone
              : allParams && allParams.ANI
              ? allParams.ANI
              : '',
          patientName: allParams.Full_Name ? allParams.Full_Name : '',
          office: office ? office.id : ''
        };
        props.history.push(
          `${path}${separator}dialogData=${JSON.stringify(manConnectFields)}`
        );
        setManConnectData(manConnectFields);
      }
    };
    handleManConnect();
  });
  return (
    <>
      <DataTable
        {...props}
        collection='employees'
        columns={employeesColumns}
        clearSearchPath={`/employees${
          tabId && tabId !== 'undefined' ? `/${tabId}` : ''
        }`}
        sceneUri='employees'
        title='Employees'
        onRowClickLink={(data, pathname) => {
          const dialogData = JSON.stringify({ id: data.id });
          let path = pathname;
          let search;
          const searchObj = queryString.parse(pathname.split('?')[1]);
          delete searchObj.refreshData;
          if (pathname.indexOf('dialogData') > -1) {
            delete searchObj.dialogData;
            search = queryString.stringify(searchObj);
          }
          path = search
            ? `${pathname.split('?')[0]}?${search}`
            : `${pathname.split('?')[0]}`;
          const separator = path.indexOf('?') > -1 ? '&' : '?';

          return `${path}${separator}dialogData=${dialogData}`;
        }}
        tableActionIcons={[
          {
            render: (key, filtersApplied, onClearFilter) => {
              return (
                <span key={key}>
                  <FilterClearChip
                    filtersApplied={filtersApplied}
                    onClearFilter={onClearFilter}
                  />
                  <Tooltip title='Filters'>
                    <IconButton
                      aria-label='Filters'
                      aria-owns={open ? 'simple-popper' : null}
                      onClick={handlePopoverClick}
                    >
                      {filtersApplied ? (
                        <Badge color='secondary' variant='dot'>
                          <FilterList />
                        </Badge>
                      ) : (
                        <FilterList />
                      )}
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
          },
          {
            render: key => (
              <span key={key}>
                <Tooltip title='Add Employee'>
                  <IconButton
                    variant='contained'
                    color='primary'
                    aria-label='Add Employee'
                    onClick={() => {
                      const searchObj = queryString.parse(location.search);
                      delete searchObj.dialogData;
                      delete searchObj.refreshData;
                      const search = queryString.stringify(searchObj);
                      const { pathname } = location;
                      const param =
                        search === '' ? '?dialogData={}' : '&dialogData={}';
                      history.push(`${pathname}${search}${param}`);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </span>
            )
          }
        ]}
        tableFilterable={true}
        filterData={{
          anchorEl,
          filters,
          open,
          handleClose: handlePopoverClose
        }}
      />
      <DialogAddEmployeeInfo
        title='Employee Info'
        collection='employees'
        dialogBody={() =>
          loadingEditData ? null : (
            <EmployeeInfoForm
              dialogData={dialogData}
              manConnectData={manConnectData}
              dialogOnClose={dialogOnClose}
              loadingEditData={loadingEditData}
              route={route}
              item={dialogData}
              employees={employees}
              offices={offices}
            />
          )
        }
        dialogOnClose={dialogOnClose}
        dialogOpen={!loadingEditData && dialogOpen}
        history={history}
        item={dialogData}
        location={location}
      />
    </>
  );
};

EmployeesData.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tabId: PropTypes.string.isRequired
};

export default withRouter(EmployeesData);
