import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PricingArray from './PricingArray';

const PricingPlanDetails = ({ classes, office, pricingDetails, wide }) => {
  const leftColumn = wide ? 4 : 3;
  const rightColumn = wide ? 8 : 9;

  return (
    <div className={classes.planDetailsContainer}>
      <Grid container spacing={24} className={classes.pricingDetails}>
        <Grid item xs={leftColumn}>
          Entry Price:
        </Grid>
        <Grid item xs={rightColumn}>
          {pricingDetails && pricingDetails.entryPrice instanceof Array ? (
            <PricingArray
              pricingArray={pricingDetails.entryPrice}
              type='entryPrice'
              minId='entryMin'
              maxId='entryMax'
            />
          ) : (
            `$${parseFloat(pricingDetails.entryPrice).toFixed(2)}`
          )}
        </Grid>
      </Grid>
      <Grid container spacing={24} className={classes.pricingDetails}>
        <Grid item xs={leftColumn}>
          Period Price:
        </Grid>
        <Grid item xs={rightColumn}>
          {pricingDetails && pricingDetails.periodPrice instanceof Array ? (
            <PricingArray
              office={office}
              pricingArray={pricingDetails.periodPrice}
              type='periodPrice'
              minId='listSizeMin'
              maxId='listSizeMax'
            />
          ) : (
            `$${parseFloat(pricingDetails.periodPrice).toFixed(2)}`
          )}
        </Grid>
      </Grid>
      <Grid container spacing={24} className={classes.pricingDetails}>
        <Grid item xs={leftColumn}>
          Period Type:
        </Grid>
        <Grid item xs={rightColumn}>
          {_.startCase(pricingDetails.periodType)}
        </Grid>
      </Grid>
      <Grid container spacing={24} className={classes.pricingDetails}>
        <Grid item xs={leftColumn}>
          Period Entry Max:
        </Grid>
        <Grid item xs={rightColumn}>
          {pricingDetails.periodEntryMax ? pricingDetails.periodEntryMax : '-'}
        </Grid>
      </Grid>
    </div>
  );
};

const styles = theme => ({
  planDetailsContainer: {
    marginTop: 3,
    marginBottom: 8
  },
  pricingDetails: {
    fontSize: 12,
    '& > div': {
      padding: '12px 12px 4px 12px !important'
    }
  }
});

export default withStyles(styles, { withTheme: true })(PricingPlanDetails);
