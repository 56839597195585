import _ from 'lodash';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FilterField from './Field';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { isDateRangeIdentifier } from '../../../services/helpers';
import { withStyles } from '@material-ui/core/styles';

class FilterForm extends Component {
  state = {
    selected: null,
    where: null,
    dateRangeIdentifiers: ['period', 'time', 'date']
  };

  UNSAFE_componentWillMount() {
    const { filters, where } = this.props;

    if (where) {
      this.setState(
        {
          selected:
            where[0] instanceof Array
              ? where[0][0] in _.mapKeys(filters, 'key')
                ? where[0][0]
                : 'period'
              : where[0]
        },
        () => {
          if (isDateRangeIdentifier(this.state.selected)) {
            where[0][2] =
              where[0][2] instanceof Date
                ? where[0][2].toISOString()
                : where[0][2];
            where[1][2] =
              where[1][2] instanceof Date
                ? where[1][2].toISOString()
                : where[1][2];

            this.setState({ where });
          }
        }
      );
    }
  }

  onSubmit = values => {
    const { where } = this.state;
    const { onClose, onFilter } = this.props;

    if (where) {
      onFilter(where);
      onClose();
    }
  };

  renderFilterOption(field) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Radio
          checked={field.checked}
          value={field.value}
          onChange={e => field.input.onChange()}
          aria-label={field.name}
        />
        <Typography variant='body1'>{field.label}</Typography>
      </span>
    );
  }

  renderForm() {
    const { classes, filters, where } = this.props;
    const { selected } = this.state;

    return (
      <div className={classes.fields}>
        {_.orderBy(filters, 'index', 'asc').map(filter => (
          <span key={filter.index}>
            <Field
              name={filter.key}
              label={`By ${filter.label}`}
              checked={
                selected === filter.key
                  ? true
                  : where in _.mapKeys(filter.options, 'value')
                  ? true
                  : false
              }
              value={filter.key}
              component={this.renderFilterOption}
              onChange={() => this.setState({ selected: filter.key })}
            />
            <FilterField
              filter={filter}
              onSelect={where => {
                if (isDateRangeIdentifier(this.state.selected)) {
                  where[0][2] =
                    where[0][2] instanceof Date
                      ? where[0][2].toISOString()
                      : where[0][2];
                  where[1][2] =
                    where[1][2] instanceof Date
                      ? where[1][2].toISOString()
                      : where[1][2];
                }
                this.setState({ where });
              }}
              selected={selected}
              where={where}
            />
          </span>
        ))}
        <div className={classes.buttonContainer}>
          <Button
            size='large'
            variant='contained'
            color='primary'
            className={classes.button}
            type='submit'
          >
            Filter
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div className={classes.root}>
        <form
          className={classes.form}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          {this.renderForm()}
        </form>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  field: {
    width: '100%',
    margin: '16px auto 8px auto',
    '&:hover': {
      borderColor: '#696969'
    }
  },
  fields: {
    display: 'block',
    marginBottom: 12
  },
  buttonContainer: {
    textAlign: 'right'
  },
  button: {
    margin: '16px 0px 12px 0px'
  }
});

export default compose(
  connect(null),
  reduxForm({
    validate: values => {},
    form: 'FilterForm',
    enableReinitialize: false
  }),
  withStyles(styles, { withTheme: true })
)(FilterForm);
