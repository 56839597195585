import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  dialogEditTeamMembersClick,
  dialogEditTeamMembersItem
} from '../../../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import EmployeeFilteredSelect from '../../../components/EmployeeFilteredSelect';

const DialogEditTeamMembers = ({
  classes,
  item,
  fullScreen,
  firestore,
  open,
  dialogEditTeamMembersItem,
  dialogEditTeamMembersClick
}) => {
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);
  const [validateError, setValidateError] = useState(false);

  const onClose = () => {
    setLoadingAfterSubmit(false);
    dialogEditTeamMembersClick(!open);
    dialogEditTeamMembersItem(null);
  };

  const onSubmit = async values => {
    const { employeeId } = values;
    if (validateError) return;
    if (!employeeId) return onClose();
    setLoadingAfterSubmit(true);
    const {
      key,
      data: { id, members }
    } = item;
    members.push(employeeId);
    const ref = { collection: 'teams', doc: id };
    try {
      await firestore.update(ref, { [key]: members });
      onClose();
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  const validate = value => {
    const {
      data: { members }
    } = item;
    if (members.find(employee => employee === value)) {
      setValidateError('That employee is already on this team.');
    } else {
      setValidateError(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth='md'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Add {item && item.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentTeamLead}>
        <Formik initialValues={{ employeeId: '' }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              {item ? (
                <Field
                  name='employeeId'
                  label='Choose Employee'
                  validate={validate}
                >
                  {({ field }) => {
                    return (
                      <EmployeeFilteredSelect
                        error={validateError}
                        label='Employee'
                        onChange={employee => {
                          setFieldValue(
                            field.name,
                            employee ? employee.id : ''
                          );
                        }}
                        previouslySelectedEmployeeId={null}
                      />
                    );
                  }}
                </Field>
              ) : null}
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Add'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogEditTeamMembersOpen: open, dialogEditTeamMembersItem: item },
  props
) => {
  let initialValues = item ? { [`${item.key}`]: item.data[item.key] } : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditTeamMembersClick,
    dialogEditTeamMembersItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditTeamMembers);
