export const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    position: 'absolute',
    maxWidth: '165px',
    right: '25px'
  },
  dialogButton: {
    padding: '8px 24px',
    margin: '0px 25px 0px',
    fontSize: '0.9375rem'
  },
  dialogButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: 24
  },
  dialogOfficesList: {
    height: 150,
    width: '100%',
    overflowY: 'scroll',
    overflow: 'hidden',
    border: 'solid #cbcbcb 1px',
    borderRadius: '8px'
  },
  dialogText: {
    fontSize: 20,
    textAlign: 'center'
  },
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    minHeight: 48,
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12,
    marginBottom: 25
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  officeName: {
    width: '100%',
    color: '#212121',
    margin: 6,
    padding: 4,
    '& svg': { fontSize: 12, marginRight: 0 },
    [theme.breakpoints.down('sm')]: { width: '70%' }
  },
  officeNames: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column'
  },
  officeRemovalIcon: {
    padding: 0,
    marginLeft: 10,
    fontSize: 14,
    '& svg': {
      fontSize: '20px !important',
      height: 20,
      marginRight: 0
    },
    '&:focus': {
      backgroundColor: '#ddd',
      height: 20,
      marginRight: 0
    }
  }
});
