import _ from 'lodash';
import React, { Component } from 'react';
import callforceIcons from '../../services/icons';
import DataTableBase from './Base';
import DataTableHeader from './Header';
import DataTableBody from './Body';
import DataTableTitleRow from './TitleRow';
import DataTableFooter from './Footer';
import FilterPopover from './Filter/Popover';
import getData from '../../services/firestore/getPaginatedData';
import qs from 'qs';
import searchFirestore from '../../services/firestore/searchFirestore';
import status from '../../services/helpers/statusObject';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import {
  getNthOccurrence,
  removeQueryParam,
  replaceAt,
  isDateRangeIdentifier,
  datesToISOStringInArray
} from '../../services/helpers';
import { t } from 'typy';

class DataTable extends Component {
  state = {
    collection: this.props.collection,
    docs: [],
    tableData: status('loading'),
    tableDataError: null,
    firstVisible: null,
    lastVisible: null
  };
  defaults = {
    count: null,
    orderByKey: _.filter(
      this.props.columns,
      o => o.defaultSortColumn === true
    )[0].key,
    orderBySort: _.filter(
      this.props.columns,
      o => o.defaultSortColumn === true
    )[0].defaultSort,
    page: 0,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    pageForward: true,
    firstVisible: null,
    lastVisible: null,
    prevPagination: null,
    where: null
  };
  initialPaginationBase = {
    firstVisible: null,
    lastVisible: null,
    page: 0,
    pageForward: true
  };

  UNSAFE_componentWillMount = () => {
    const { params, searchActive } = this.props;

    searchActive
      ? this.fetchSearchResultsData(params, searchActive)
      : this.fetchData(params);
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const { params, refreshData, searchActive } = this.props;

    if (
      !_.isEqual(params, nextProps.params) ||
      !_.isEqual(searchActive, nextProps.searchActive) ||
      (!_.isEqual(refreshData, nextProps.refreshData) && nextProps.refreshData)
    ) {
      this.setState({ rowsCount: null, tableData: status('loading') }, () => {
        nextProps.searchActive
          ? this.fetchSearchResultsData(
              nextProps.params,
              nextProps.searchActive
            )
          : this.fetchData(nextProps.params);
      });
    }
  }

  constructUri(params, resetVisToDefaults) {
    const { history, location, sceneUri } = this.props;
    const { firstVisible: firstVis, lastVisible: lastVis } = this.state;
    const { tabId } = params;
    const {
      where,
      orderByKey,
      orderBySort,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      pageForward,
      lastVisible,
      firstVisible
    } = this.getParams(params);
    const first = !resetVisToDefaults && firstVis ? firstVis : firstVisible;
    const last = !resetVisToDefaults && lastVis ? lastVis : lastVisible;
    const queryString = removeQueryParam(location.search, 'refreshData');
    const commaCount = where && where.join(',').split(',').length - 1;
    const whereString = datesToISOStringInArray(where).join(',');
    const whereAdjusted =
      commaCount > 2
        ? replaceAt(whereString, getNthOccurrence(whereString, ',', 3), '&&')
        : where;
    this.setState({ tableData: status('loading') }, () => {
      history.push(
        `/${sceneUri}/${tabId}/${whereAdjusted}/${orderByKey}/${orderBySort}/${page}/${rowsPerPage}/${rowsPerPageOptions}/${pageForward}/${last}/${first}${queryString}`
      );
    });
  }

  onChangePage = (event, page) => {
    const params = {
      ...this.defaults,
      ...this.props.params
    };
    const newParams = {
      ...params,
      page,
      pageForward:
        page === 0
          ? true
          : parseInt(params.page) < parseInt(page)
          ? true
          : false,
      lastVisible: page === 0 ? null : params.lastVisible,
      firstVisible: page === 0 ? null : params.firstVisible,
      where: this.getParam('where', this.props.params)
    };
    this.constructUri(newParams, newParams.page === 0 ? true : false);
  };

  onChangeRowsPerPage = event => {
    const newParams = {
      ...this.props.params,
      ...this.defaults,
      where: this.getParam('where', this.props.params),
      rowsPerPage: event.target.value
    };
    this.constructUri(newParams, true);
  };

  onClearFilter = () => {
    const { tabId } = this.props.params;
    this.constructUri({ ...this.defaults, tabId, where: null }, true);
  };

  onSortClick = column => {
    const {
      columns,
      params,
      params: { orderByKey, orderBySort }
    } = this.props;
    const defaultSort = _.mapKeys(columns, 'key')[column].defaultSort;
    const where = this.getParam('where', params);
    const sort =
      column === orderByKey
        ? orderBySort === 'desc'
          ? 'asc'
          : 'desc'
        : orderByKey
        ? defaultSort
        : defaultSort === 'desc'
        ? 'asc'
        : 'desc';
    const newParams = {
      ...this.props.params,
      ...this.defaults,
      where,
      orderByKey: column,
      orderBySort: sort
    };
    this.constructUri(newParams, true);
  };

  getParam(key, params) {
    let param = params[key];
    let letters = /[a-zA-Z]/;
    if (param === 'true' || param === true) return true;
    if (param === 'false' || param === false) return false;
    if (param) {
      if (typeof param !== 'string') param = param.toString();
      if (param.indexOf(',') === -1) {
        if (param === 'null') return this.defaults[key];
        if (!isNaN(parseInt(param)) && !letters.test(param)) {
          return parseInt(param);
        }

        return param;
      } else if (param.indexOf('&&') > -1) {
        let newParam = [];

        param.split('&&').forEach(item => {
          let np = [];
          let parseDate = isDateRangeIdentifier(item.split(',')[0]);

          item.split(',').forEach((i, index) => {
            if (index === 2 && parseDate) {
              np.push(new Date(i));
            } else if (
              !isNaN(parseInt(i)) &&
              item.indexOf(' ') === -1 &&
              !letters.test(item)
            ) {
              np.push(parseInt(i));
            } else {
              np.push(i);
            }
          });

          newParam.push(np);
        });

        return newParam;
      } else if (param.indexOf(',') > -1) {
        let newParam = [];

        param.split(',').forEach(item => {
          if (item === 'true' || item === true) {
            newParam.push(true);
          } else if (item === 'false' || item === false) {
            newParam.push(false);
          } else if (
            !isNaN(parseInt(item)) &&
            param.indexOf(' ') === -1 &&
            !letters.test(param)
          ) {
            newParam.push(parseInt(item));
          } else {
            newParam.push(item);
          }
        });

        return newParam;
      } else {
        return this.defaults[key];
      }
    } else {
      return this.defaults[key];
    }
  }

  getParams(params) {
    let paramData = {};
    for (let key in this.defaults) {
      paramData[key] = this.getParam(key, params);
    }

    return paramData;
  }

  fetchSearchResultsData = async (params, query) => {
    const { collection } = this.state;
    const { firebase } = this.props;
    const page = this.getParam('page', params);
    const hitsPerPage = this.getParam('rowsPerPage', params);
    const pageForward = this.getParam('pageForward', params);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const res = await searchFirestore({
        collection,
        query,
        page,
        hitsPerPage,
        token
      });
      if (this.props.mistake) res.data = res.data.filter(doc => doc.mistake);

      this.setState({
        docs: await this.transformData(res.data, !pageForward),
        firstVisible: null,
        lastVisible: null,
        tableData: status('ready'),
        rowsCount: res.nbHits
      });
    } catch (e) {
      this.setState({
        tableData: status('error'),
        tableDataError: e
      });
    }
  };

  fetchData = async params => {
    const { collection } = this.state;
    const { firestore, mistake } = this.props;
    const orderBy = [
      this.getParam('orderByKey', params),
      this.getParam('orderBySort', params)
    ];
    const where = this.getParam('where', params);
    const page = this.getParam('page', params);
    const rowsPerPage = this.getParam('rowsPerPage', params);
    const pageForward = this.getParam('pageForward', params);
    const firstVisible = this.getParam('firstVisible', params);
    const lastVisible = this.getParam('lastVisible', params);
    const limit = rowsPerPage;
    const startAfter =
      page === 0 && where ? null : pageForward ? lastVisible : firstVisible;
    const adjustedOrderBy = pageForward
      ? orderBy
      : [orderBy[0], orderBy[1] === 'asc' ? 'desc' : 'asc'];

    try {
      const { docs, firstVisible, lastVisible } = await getData(
        collection,
        adjustedOrderBy,
        limit,
        startAfter,
        pageForward,
        where,
        firestore,
        mistake
      );
      this.setState({
        docs: await this.transformData(docs, !pageForward),
        firstVisible,
        lastVisible,
        tableData: status('ready')
      });
    } catch (e) {
      console.warn(e);
      this.setState({ tableData: status('error') });
    }
  };

  transformData = async (data, reverse) => {
    let docs = [];
    for (const item of data) {
      let doc = {};

      for (const column of this.props.columns) {
        doc[column.key] = column.formatData
          ? await column.formatData(item)
          : column.icon && t(item, column.key).safeObject !== undefined
          ? typeof item[column.key] === 'boolean'
            ? callforceIcons.booleanIcons[column.key](12, item[column.key])
            : typeof item[column.key] === 'object'
            ? callforceIcons[column.key](12, item[column.key])
            : callforceIcons[item[column.key]](12)
          : t(item, column.key).safeObject;
      }

      doc.id = item.id;
      docs.push(doc);
    }

    return reverse ? docs.reverse() : docs;
  };

  renderFilterPopover = () => {
    const {
      filterData: { anchorEl, filters, open, handleClose },
      params,
      params: { tabId },
      tableFilterable,
      title
    } = this.props;
    const where = this.getParam('where', params);

    if (tableFilterable) {
      return (
        <FilterPopover
          anchorEl={anchorEl}
          filters={filters}
          handleClose={handleClose}
          onFilter={where => {
            this.constructUri({ ...this.defaultParams, tabId, where }, true);
          }}
          open={open}
          title={title}
          where={where}
        />
      );
    }

    return null;
  };

  render() {
    const {
      collection,
      docs,
      rowsCount,
      tableData,
      tableDataError
    } = this.state;
    const {
      columns,
      clearSearchPath,
      history,
      location,
      onRowClickLink,
      params,
      searchActive,
      tableActionIcons,
      title
    } = this.props;
    const where = this.getParam('where', params);
    const orderBy = [
      this.getParam('orderByKey', params),
      this.getParam('orderBySort', params)
    ];
    const page = this.getParam('page', params);
    const rowsPerPage = this.getParam('rowsPerPage', params);
    const rowsPerPageOptions = this.getParam('rowsPerPageOptions', params);

    return (
      <DataTableBase>
        <DataTableHeader
          title={title}
          history={history}
          location={location}
          onClearFilter={this.onClearFilter}
          tableActionIcons={tableActionIcons}
          searchActive={searchActive}
          clearSearchPath={clearSearchPath}
          where={where}
        />
        <DataTableTitleRow
          columns={columns}
          orderBy={orderBy}
          onSortClick={this.onSortClick}
          searchActive={searchActive}
          where={where}
        />
        <DataTableBody
          collection={collection}
          columns={columns}
          docs={docs}
          location={location}
          onRowClickLink={onRowClickLink}
          page={page}
          rowsPerPage={rowsPerPage}
          tableData={tableData}
          tableDataError={tableDataError}
        />
        <DataTableFooter
          {...this.props}
          docs={docs}
          page={page}
          rowsCount={rowsCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          tableData={tableData}
        />
        {this.renderFilterPopover()}
      </DataTableBase>
    );
  }
}

export default compose(
  connect(
    (state, { location }) => ({
      location,
      searchActive: t(qs.parse(location.search.slice(1)), 'term').safeObject,
      refreshData: t(qs.parse(location.search.slice(1)), 'refreshData')
        .safeObject
    }),
    {}
  ),
  firebaseConnect(),
  firestoreConnect()
)(DataTable);
