import _ from 'lodash';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DAYS } from '../../../services/options';
import {
  dialogEditOfficeHoursClick,
  dialogEditOfficeHoursItem
} from '../../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { transformOfficeHoursData } from '../../../services/transform';
import { db } from '../../../index';

class DialogEditOfficeHours extends Component {
  state = { loadingAfterSubmit: false };

  onClose = () => {
    this.props.dialogEditOfficeHoursClick(!this.props.open);
    this.props.dialogEditOfficeHoursItem(null);
  };

  onSubmit = async values => {
    const { office } = this.props;
    let data = transformOfficeHoursData(values);
    const drProfileRef = await db
      .collection('clients')
      .doc(office)
      .collection('drProfile')
      .doc('profile');

    try {
      await drProfileRef.set({ officeHours: data }, { merge: true });
      this.setState({ loadingAfterSubmit: false }, () => this.onClose());
    } catch (e) {
      console.log(e);
      this.setState({ loadingAfterSubmit: false });
    }
  };

  renderCheckbox = field => {
    const {
      input: { onChange, value },
      label
    } = field;

    return (
      <span className={field.dialogCheckboxLabelContainer}>
        <Checkbox
          className={field.dialogCheckbox}
          checked={value}
          onChange={e => onChange(!value)}
        />
        <Typography variant='body1' className={field.dialogCheckboxLabel}>
          {label}
        </Typography>
      </span>
    );
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      margin,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin={margin ? margin : 'normal'}
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...field.input}
      />
    );
  };

  render() {
    const { classes, fullScreen, handleSubmit, open, formValues } = this.props;
    const { loadingAfterSubmit } = this.state;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              Edit Office Hours
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          <form
            className={classes.dialogForm}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {_.map(DAYS, day => (
              <div key={day.key} style={{ width: '100%' }}>
                <Typography
                  variant='caption'
                  className={classes.dialogSubSectionTitle}
                >
                  {day.name}
                </Typography>
                <div className={classes.dialogDayContainer}>
                  <div className={classes.dialogOpenTime}>
                    <Field
                      name={`${day.key}Open`}
                      label='Open Time'
                      variant='outlined'
                      type='time'
                      component={this.renderTextField}
                      disabled={formValues[`${day.key}Closed`] ? true : false}
                      className={classes.dialogTextField}
                      margin='dense'
                    />
                  </div>
                  <div className={classes.dialogTimeSeparator}>-</div>
                  <div className={classes.dialogCloseTime}>
                    <Field
                      name={`${day.key}Close`}
                      label='Open Time'
                      variant='outlined'
                      type='time'
                      component={this.renderTextField}
                      disabled={formValues[`${day.key}Closed`] ? true : false}
                      className={classes.dialogTextField}
                      margin='dense'
                    />
                  </div>
                  <div className={classes.dialogClosed}>
                    <Field
                      name={`${day.key}Closed`}
                      label='Closed'
                      component={this.renderCheckbox}
                      dialogCheckboxLabelContainer={
                        classes.dialogCheckboxLabelContainer
                      }
                      dialogCheckbox={classes.dialogCheckbox}
                      dialogCheckboxLabel={classes.dialogCheckboxLabel}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const validate = values => {
  const errors = {};

  DAYS.forEach(day => {
    if (!values[`${day.key}Closed`]) {
      if (values[`${day.key}Close`] <= values[`${day.key}Open`]) {
        errors[`${day.key}Close`] = 'Invalid close time';
      }

      if (values[`${day.key}Close`] && !values[`${day.key}Open`]) {
        errors[`${day.key}Open`] = 'Required';
      }

      if (!values[`${day.key}Close`] && values[`${day.key}Open`]) {
        errors[`${day.key}Close`] = 'Required';
      }
    }
  });

  return errors;
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DialogEditOfficeHours');
  const {
    dialogEditOfficeHoursOpen: open,
    dialogEditOfficeHoursItem: item
  } = state;
  let initialValues = {};
  let formValues = {};
  if (item && item.data) {
    DAYS.forEach(day => {
      for (let d in item.data) {
        if (d.includes(day.key)) {
          initialValues = {
            ...initialValues,
            [`${d}`]: item.data[d] ? item.data[d] : ''
          };
        }
      }
    });
  }

  for (let key in initialValues) {
    formValues = { ...formValues, [key]: selector(state, key) };
  }

  return { open, formValues, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeHoursClick,
    dialogEditOfficeHoursItem
  }),
  reduxForm({
    validate,
    form: 'DialogEditOfficeHours',
    enableReinitialize: true
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeHours);
