import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import OfferingTimeActive from './components/OfferingTimeActive';

const SubscriptionHistory = ({
  classes,
  office,
  offeringId,
  offeringTimeActive,
  offering
}) => (
  <div className={classes.dataRow}>
    <div className={classes.dataLabel}>
      {offering && offeringTimeActive ? (
        <div className={classes.offeringLabel}>
          {offering.name}
          {/* <br/>
                {
                  office.offerings[offeringId]
                    ? <span className={classes.offeringStatus}>Subscribed</span>
                    :
                      <span className={classes.offeringStatusNotSubscribed}>
                        Not Subscribed
                      </span>
                } */}
        </div>
      ) : (
        <div className={classes.loading} />
      )}
    </div>
    <div className={classes.dataText}>
      {offering && offeringTimeActive ? (
        <OfferingTimeActive
          office={office}
          offeringId={offeringId}
          offering={offering}
          offeringTimeActive={offeringTimeActive}
        />
      ) : (
        <div className={classes.loading} />
      )}
    </div>
  </div>
);

const mapStateToProps = (state, { offeringId }) => {
  const offeringData = state.firestore.ordered[`${offeringId}Data`];
  let offering = null;

  if (isLoaded(offeringData) && !isEmpty(offeringData)) {
    offering = offeringData[0];
  }

  return { offering };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(({ offeringId }) => {
    const queries = [];

    if (offeringId) {
      queries.push({
        collection: 'offerings',
        doc: offeringId,
        storeAs: `${offeringId}Data`
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(SubscriptionHistory);
