import {
  DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK,
  DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM
} from './types';

export const dialogEditOfferingTimeActiveEntryClick = status => ({
  type: DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK,
  payload: status
});

export const dialogEditOfferingTimeActiveEntryItem = item => ({
  type: DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM,
  payload: item
});
