import React from 'react';
import PropTypes from 'prop-types';
import ServiceLists from './';

const RecallLists = ({ offices }) => {
  return (
    <ServiceLists offices={offices} collection='scheduled' offering='recall' />
  );
};

RecallLists.propTypes = {
  offices: PropTypes.array.isRequired
};

export default RecallLists;
