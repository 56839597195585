/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-named-as-default-member */

import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import {
  FieldPhone,
  FieldSelect,
  FieldText
} from '../../../../../components/Fields';
import { phone, required } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import helpers from '../../../../../helpers';
import ManageEntryFields from '../../components/ManageEntryFields';
import { notScheduledEntryTypes } from '../../../../../services/options';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const NotScheduledItemForm = ({
  classes,
  dialogData,
  manConnectData,
  dialogOnClose,
  route,
  item,
  employees,
  offices
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [notesTemplate, setNotesTemplate] = useState('');
  const {
    state: { auth, firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const [doNotBillReasons, setDoNotBillReasons] = useState([]);
  const [mistakeReasons, setMistakeReasons] = useState([]);
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;
  const onSubmit = (values, { setSubmitting }) => {
    if (item && values.createdBy.employeeId) {
      let {
        createdBy: { employeeId, employeeFirstName, employeeLastName }
      } = values;
      let createdByName = `${employeeFirstName} ${employeeLastName}`;
      values.createdBy = employeeId;
      values.createdByName = createdByName;
    }
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    helpers
      .transformEntryDataForSave(firebase, auth, values)
      .then(data => data)
      .then(data => helpers.saveEntryData(firebase, data, 'notScheduled'))
      .then(() => {
        dialogOnClose(true);
        setError(null);
        setLoading(null);
        setSubmitting(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(null);
        setSubmitting(false);
      });
  };
  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );
  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(_.toLower(selectName))}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(_.toLower(selectName))}s
        </span>
      );
    }
    return vanityName || _.startCase(_.toLower(selectName));
  };
  const validations = {
    office: required,
    patientName: required,
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    entryType: required,
    category: required,
    subCategory: required,
    createdByName: required
  };
  const getSubCategory = category => {
    setSubCategories([]);
    firebase
      .firestore()
      .collection('offeringsCategories')
      .doc(category)
      .collection('subcategories')
      .get()
      .then(snapShots => {
        const subCategoriesDocs = [];
        snapShots.forEach(doc => {
          subCategoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setSubCategories(subCategoriesDocs);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userAllowedToManage) {
          const doNotBillReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('doNotBill')
            .collection('reasons')
            .orderBy('value')
            .get();
          const mistakeReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('mistake')
            .collection('reasons')
            .orderBy('value')
            .get();
          const doNotBillReasonsDocs = [];
          const mistakeReasonsDocs = [];

          doNotBillReasonsQuerySnapshot.forEach(doc => {
            doNotBillReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          mistakeReasonsQuerySnapshot.forEach(doc => {
            mistakeReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          setDoNotBillReasons(doNotBillReasonsDocs);
          setMistakeReasons(mistakeReasonsDocs);
        }
        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .where('offering', '==', 'notScheduled')
          .get();
        if (item) {
          getSubCategory(item.reason.category);
        }
        const categoriesDocs = [];

        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setCategories(categoriesDocs);
        setLoading(null);
      } catch (err) {
        setLoading(null);
        setCategories(null);
        setError(err.toString());
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [firebase, userAllowedToManage]);

  const handleSubCategory = newCategory => {
    if (newCategory !== '') {
      getSubCategory(newCategory);
    }
  };

  const initialValues = dialogData
    ? dialogData
    : manConnectData
    ? manConnectData
    : {};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          {dialogData && userAllowedToManage ? (
            <>
              <ManageEntryFields
                doNotBillReasons={doNotBillReasons}
                loading={loading}
                mistakeReasons={mistakeReasons}
                selectLabel={selectLabel}
                validations={validations}
                values={values}
              />
            </>
          ) : null}
          <div className={classes.row}>
            <Field name='patientName' validate={validations.patientName}>
              {({ form, field }) => {
                return (
                  <FieldText
                    disabled={true}
                    form={form}
                    field={field}
                    label='Patient Name'
                    variant='outlined'
                    onChange={e => {
                      field.onBlur(e);
                      field.onChange(e);
                      if (values.reason) {
                        if (e.target.value === '') {
                          values.reason.notes = '';
                        } else if (notesTemplate !== '') {
                          values.reason.notes = notesTemplate.replace(
                            '{{ name }}',
                            e.target.value
                          );
                        }
                      }
                    }}
                  />
                );
              }}
            </Field>
            <Field
              name='patientPhone'
              label='Patient Phone'
              variant='outlined'
              component={FieldPhone}
              disabled={true}
              validate={validations.patientPhone}
            />
          </div>
          <Field
            name='entryType'
            loading={notScheduledEntryTypes.length === 0}
            disabled={notScheduledEntryTypes.length === 0 || true}
            label={selectLabel(notScheduledEntryTypes, 'Entry Type')}
            options={notScheduledEntryTypes}
            optionKey='key'
            optionText='name'
            optionValue='key'
            margin='none'
            component={FieldSelect}
            validate={validations.entryType}
          />
          <Field
            name='office'
            loading={offices.length === 0}
            disabled={offices.length === 0 || true}
            label={selectLabel(offices, 'office')}
            options={_.sortBy(offices, 'officeInformation.name')}
            optionKey='id'
            optionText='officeInformation.name'
            optionValue='id'
            margin='none'
            component={FieldSelect}
            validate={validations.office}
          />
          <Field name='reason.category' validate={validations.category}>
            {({ form, field }) => {
              return (
                <FieldSelect
                  margin='none'
                  form={form}
                  field={field}
                  loading={categories.length === 0}
                  disabled={categories.length === 0 || true}
                  label={selectLabel(categories, 'Category')}
                  options={_.sortBy(categories, 'category')}
                  optionKey='id'
                  optionText='category'
                  optionValue='id'
                  onChange={newCategory => {
                    handleSubCategory(newCategory);
                    if (values.reason) {
                      values.reason.notes = '';
                    }
                  }}
                />
              );
            }}
          </Field>
          {values.reason ? (
            <>
              <Field
                name='reason.subcategory'
                validate={validations.subCategory}
              >
                {({ form, field }) => {
                  return (
                    <FieldSelect
                      margin='none'
                      form={form}
                      field={field}
                      loading={subCategories.length === 0}
                      disabled={subCategories.length === 0 || true}
                      label={selectLabel(subCategories, 'Subcategory')}
                      options={_.sortBy(subCategories, 'category')}
                      optionKey='id'
                      optionText='category'
                      optionValue='id'
                      component={FieldSelect}
                      onChange={newSubCategory => {
                        if (!values.reason) {
                          values.reason = {};
                        }
                        if (newSubCategory !== '') {
                          const notesTemplate = _.filter(
                            subCategories,
                            category => category.id === newSubCategory
                          )[0];
                          setNotesTemplate(notesTemplate.notesTemplate);
                          if (values.patientName) {
                            values.reason.notes = notesTemplate.notesTemplate.replace(
                              '{{ name }}',
                              values.patientName
                            );
                          }
                        } else {
                          values.reason.notes = '';
                        }
                      }}
                    />
                  );
                }}
              </Field>
            </>
          ) : null}
          {values.spanishSpEmpHelp ? (
            <Field
              name='spanishSpEmp'
              loading={employees.length === 0}
              disabled={employees.length === 0 || true}
              label={selectLabel(
                employees,
                'employee',
                'Spanish-Speaking Caller'
              )}
              options={employees}
              optionKey='id'
              optionText={['firstName', 'lastName']}
              optionValue='id'
              component={FieldSelect}
              validate={validations.spanishSpEmp}
            />
          ) : null}
          {values.reason ? (
            <Field
              name='reason.notes'
              value='Hello'
              label='Notes'
              variant='outlined'
              multiline
              rowsMax={4}
              disabled={true}
              component={FieldText}
            />
          ) : null}
          {item ? (
            <Field
              name='createdByName'
              label='Created by Employee'
              variant='outlined'
              component={FieldText}
              validate={validations.createdByName}
              disabled={true}
            />
          ) : null}
          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

NotScheduledItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  offices: PropTypes.array,
  employees: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(NotScheduledItemForm);
