import React, { useState } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import DialogEditOfficeOpsStatuses from './DialogEditOfficeOpsStatuses';
import DialogEditOfficeInfo from './DialogEditOfficeInfo';
import DialogEditOfficeAddress from './DialogEditOfficeAddress';
import DialogEditOfficeStatus from './DialogEditOfficeStatus';
import DialogEditOfficeOfferings from './DialogEditOfficeOfferings';
import DialogEditOfficeFive9Data from './DialogEditOfficeFive9Data';
import DialogEditOfficeTeams from './DialogEditOfficeTeams';
import OfficeAddressData from './OfficeAddressData';
import OfficeFive9Data from './OfficeFive9Data';
import OfficeInfoData from './OfficeInfoData';
import OfficeOfferingsData from './OfficeOfferingsData';
import OfficeTeams from './OfficeTeams';
import OfficeStatusData from './OfficeStatusData';
import Paper from '@material-ui/core/Paper';
import { DAYS } from '../../../services/options';
import { styles } from './styles';
import OfficeOpsStateData from './OfficeOpsStateData';

const OfficeInformation = ({ classes, officeData, allowedToEdit }) => {
  const [open, setOpen] = useState(false);
  const [offering, setOffering] = useState(null);

  const renderTeams = () => {
    const filteredOfferings = [];
    for (const offering in officeData.offerings) {
      if (officeData.offerings[offering] && offering !== 'base') {
        filteredOfferings.push(offering);
      }
    }
    return _.orderBy(filteredOfferings).map((o, i) => {
      const teamType = o.charAt(0).toUpperCase() + o.slice(1);
      return (
        <OfficeTeams
          office={officeData}
          key={i}
          offering={o}
          teamIds={officeData.teams[o] ?? []}
          label={`${teamType} Teams`}
          name={`${o}Teams`}
          showEdit={allowedToEdit}
          open={open}
          setOpen={setOpen}
          setOffering={setOffering}
        />
      );
    });
  };

  return (
    <div>
      <Typography variant='caption' className={classes.title}>
        General
      </Typography>
      <Paper elevation={2} className={classes.detailsPaper}>
        <div className={classes.officeInfoRoot}>
          <OfficeInfoData
            data={officeData}
            label='UID'
            name='id'
            showEdit={false}
          />
          <OfficeInfoData
            data={officeData}
            label='Name'
            name='name'
            showEdit={allowedToEdit}
          />
          <OfficeInfoData
            data={officeData}
            label='Phone'
            name='phone'
            showEdit={allowedToEdit}
          />
          <OfficeInfoData
            data={officeData}
            label='Doctor(s)'
            name='doctor'
            showEdit={allowedToEdit}
          />
          <OfficeAddressData
            data={officeData}
            label='Address'
            name='address'
            showEdit={allowedToEdit}
          />
          <OfficeOfferingsData
            data={officeData}
            label='Subscriptions'
            name='offerings'
            showEdit={allowedToEdit}
          />
          <OfficeStatusData
            data={officeData}
            label='Status'
            name='status'
            showEdit={allowedToEdit}
          />
          <OfficeOpsStateData
            data={officeData}
            label='Billing Status'
            name='billing'
            showEdit={allowedToEdit}
          />
          <OfficeInfoData
            data={officeData}
            label='Location ID'
            name='locationId'
            showEdit={allowedToEdit}
          />
        </div>
      </Paper>
      <Typography variant='caption' className={classes.title}>
        Teams
      </Typography>
      <Paper elevation={2} className={classes.detailsPaper}>
        <div className={classes.officeInfoRoot}>{renderTeams()}</div>
      </Paper>
      <Typography variant='caption' className={classes.title}>
        Five9 Info
      </Typography>
      <Paper elevation={2} className={classes.detailsPaper}>
        <div className={classes.officeInfoRoot}>
          <OfficeFive9Data
            data={officeData}
            label='Five9 Recall Skill'
            name='recall'
            showEdit={allowedToEdit}
          />
          <OfficeFive9Data
            data={officeData}
            label='Five9 Answering Skill'
            name='answering'
            showEdit={allowedToEdit}
          />
        </div>
      </Paper>
      <DialogEditOfficeInfo office={officeData.id} />
      <DialogEditOfficeAddress office={officeData.id} />
      <DialogEditOfficeOfferings office={officeData.id} />
      <DialogEditOfficeStatus office={officeData.id} />
      <DialogEditOfficeFive9Data office={officeData.id} />
      <DialogEditOfficeOpsStatuses office={officeData.id} />
      <DialogEditOfficeTeams
        office={officeData}
        classes={classes}
        open={open}
        setOpen={setOpen}
        offering={offering}
      />
    </div>
  );
};

const mapStateToProps = (state, { office }) => {
  let officeData = {
    name: null,
    phone: null,
    doctor: null,
    address: null,
    city: null,
    state: null,
    country: null,
    zip: null,
    mondayOpen: '',
    mondayClose: '',
    mondayClosed: true,
    tuesdayOpen: '',
    tuesdayClose: '',
    tuesdayClosed: true,
    wednesdayOpen: '',
    wednesdayClose: '',
    wednesdayClosed: true,
    thursdayOpen: '',
    thursdayClose: '',
    thursdayClosed: true,
    fridayOpen: '',
    fridayClose: '',
    fridayClosed: true,
    saturdayOpen: '',
    saturdayClose: '',
    saturdayClosed: true,
    sundayOpen: '',
    sundayClose: '',
    sundayClosed: true,
    offerings: {
      answering: false,
      recall: false,
      rescheduling: false,
      treatment: false,
      websiteChat: false
    },
    teams: {
      answering: [],
      recall: [],
      rescheduling: [],
      treatment: [],
      websiteChat: []
    },
    status: '-',
    five9: { skills: { answering: null, recall: null } },
    id: office ? office.id : null
  };

  if (office) {
    const { doctor, name, phone, timezone } = office.officeInformation;
    const {
      address,
      city,
      country,
      state,
      zip
    } = office.officeInformation.address;
    const opsStatuses = office.opsStatuses ? office.opsStatuses : null;

    officeData = {
      ...officeData,
      offerings: office.offerings,
      teams: office.teams ?? officeData.teams,
      five9: office.five9,
      status: office.status,
      name,
      phone,
      doctor,
      timezone,
      address,
      city,
      country,
      state,
      zip,
      opsStatuses
    };

    if (office.metadata && office.metadata.locationId) {
      officeData.locationId = office.metadata.locationId;
    }
  }

  if (office && office.officeHours) {
    const { officeHours } = office;

    DAYS.forEach(day => {
      for (const d in officeHours) {
        if (day.key === d) {
          officeData = {
            ...officeData,
            [`${day.key}Open`]: officeHours[d][0] ? officeHours[d][0] : '',
            [`${day.key}Close`]: officeHours[d][1] ? officeHours[d][1] : '',
            [`${day.key}Closed`]: officeHours[d].length === 0
          };
        }
      }
    });
  }

  return { officeData };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(OfficeInformation);
