import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Button, Typography } from '@material-ui/core';

const ClientGroupReview = ({
  values,
  previousPage,
  updatePage,
  pageNum,
  classes,
  submitting
}) => {
  const renderOfficeInformation = (page, values) => {
    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Client Group Information</Typography>
          <span onClick={() => updatePage(page - pageNum, values)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Name:</Typography>
            <Typography variant='body1'>{values.clientGroupName}</Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderClientGroupOffices = (page, values) => {
    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Offices</Typography>
          <span onClick={() => updatePage(page - pageNum, values)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          {values.offices.map((office, index) => (
            <div key={index} className={classes.sectionRow}>
              <Typography variant='body1'>Office #{index + 1}:</Typography>
              <Typography variant='body1'>{office.label}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderOfficeInformation(0, values)}
      {values.offices &&
      values.offices.length > 0 &&
      Object.keys(values.offices[0]).length > 0
        ? renderClientGroupOffices(1, values)
        : null}
      <div className={classes.sectionActions}>
        <Button
          color='primary'
          type='button'
          onClick={() => previousPage(values)}
        >
          Previous
        </Button>
        <Button color='primary' type='submit'>
          {submitting ? (
            <Loader type='Oval' color='#696969' height='10' width='10' />
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </>
  );
};

ClientGroupReview.propTypes = {
  values: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default ClientGroupReview;
