import _ from 'lodash';
import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import PricingPlanDetailsInLine from '../../../../../../components/Billing/PricingPlanDetailsInLine';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditPricingPlanClick,
  dialogEditPricingPlanItem
} from '../../../../../../actions';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';

class DialogEditPricingPlan extends Component {
  state = { loadingAfterSubmit: false, error: false, errorMessage: '' };

  onClose = () => {
    this.props.dialogEditPricingPlanClick(false);
    this.props.dialogEditPricingPlanItem(null);
    this.setState({
      error: false,
      errorMessage: '',
      loadingAfterSubmit: false
    });
  };

  onSubmit = async values => {
    const { firestore, item, pricingPlan } = this.props;
    const planChanged = values.pricingPlan !== pricingPlan;
    const ref = { collection: 'clients', doc: item.office.id };

    try {
      this.setState({ loadingAfterSubmit: true });

      if (planChanged) {
        await firestore.update(ref, {
          [`offeringsPricing.${item.offering.id}.plan`]: values.pricingPlan
        });
      }

      this.onClose();
    } catch (e) {
      this.setState({
        error: true,
        errorMessage: e.toString(),
        loadingAfterSubmit: false
      });
    }
  };

  radioButton = ({ input, ...rest }) => {
    const { classes, item } = this.props;
    const pricingPlans = t(item, 'offering.pricing').safeObject;

    return (
      <FormControl className={classes.pricingPlans}>
        <RadioGroup {...input} {...rest}>
          {pricingPlans &&
            Object.keys(pricingPlans).map((key, index) =>
              pricingPlans[key].hideAsOption ? null : (
                <FormControlLabel
                  key={index}
                  value={key}
                  control={<Radio />}
                  label={
                    <Typography variant='body1' className={classes.radioLabel}>
                      {pricingPlans[key].name
                        ? pricingPlans[key].name
                        : `${_.startCase(key)} Plan`}
                      <br />
                      <PricingPlanDetailsInLine
                        pricingPlans={pricingPlans}
                        planKey={key}
                      />
                    </Typography>
                  }
                />
              )
            )}
        </RadioGroup>
      </FormControl>
    );
  };

  renderSubmitError() {
    const { classes } = this.props;
    const { error, errorMessage } = this.state;

    return (
      <div>
        {error ? (
          <Typography className={classes.errorMessage} variant='body1'>
            {errorMessage}
          </Typography>
        ) : null}
      </div>
    );
  }

  renderForm() {
    const { classes, handleSubmit } = this.props;

    return (
      <form
        className={classes.dialogForm}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Field name='pricingPlan' component={this.radioButton} />
        <div className={classes.dialogButtonContainer}>
          {this.renderSubmitError()}
          <Button
            size='large'
            variant='outlined'
            color='secondary'
            className={classes.dialogButton}
            type='submit'
          >
            {this.state.loadingAfterSubmit ? (
              <Loader type='Oval' color='#055f5b' height='13' width='13' />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const { classes, fullScreen, item, open } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              {`${t(item, 'offering.name').safeObject} Pricing`}
            </Typography>
            <Typography variant='caption' className={classes.subtitle}>
              {t(item, 'office.officeInformation.name').safeObject}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          {this.renderForm()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    dialogEditPricingPlanOpen: open,
    dialogEditPricingPlanItem: item
  } = state;
  const pricingPlan = t(item, 'pricing.plan').safeObject;
  let initialValues = { pricingPlan };

  return { open, item, pricingPlan, initialValues };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditPricingPlanClick,
    dialogEditPricingPlanItem
  }),
  reduxForm({ form: 'DialogEditPricingPlan', enableReinitialize: true }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditPricingPlan);
