import _ from 'lodash';
import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import AscIcon from '@material-ui/icons/ArrowDownward';
import ButtonBase from '@material-ui/core/ButtonBase';
import CheckIcon from '@material-ui/icons/Check';
import DescIcon from '@material-ui/icons/ArrowUpward';
import DialogAddEditOfficeUser from '../../../components/DialogAddEditOfficeUser';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogAddEditOfficeUserClick,
  dialogAddEditOfficeUserItem
} from '../../../actions';
import { emailInCollection } from '../../../services/helpers';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class OfficeUsersTable extends Component {
  state = {
    orderByValue: ['email', 'firstName'],
    orderByOrder: ['asc', 'asc']
  };

  renderUserTable() {
    const {
      allowedToManage,
      classes,
      dialogAddEditOfficeUserClick,
      dialogAddEditOfficeUserItem,
      officeUsers,
      office
    } = this.props;
    const { orderByOrder, orderByValue } = this.state;

    return (
      <div className={classes.tableContainer}>
        {allowedToManage ? <DialogAddEditOfficeUser office={office} /> : null}
        {_.orderBy(officeUsers, orderByValue, orderByOrder).map(
          (user, index) => (
            <ButtonBase
              key={user.id}
              className={classes.buttonBase}
              onClick={() => {
                if (allowedToManage) {
                  dialogAddEditOfficeUserClick(true);
                  dialogAddEditOfficeUserItem(user);
                }
              }}
            >
              <Grid container spacing={24} className={classes.tableRow}>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  className={`${classes.cell} ${classes.firstCell}`}
                  style={{ paddingLeft: 12 }}
                >
                  <Typography
                    variant='body1'
                    className={`
											${classes.tableText} 
											${classes.rowIndex} 
											${index + 1 > 9 ? classes.rowIndexDoubleDig : ''}
										`}
                  >
                    {index + 1}
                  </Typography>
                  <Typography variant='body1' className={classes.tableText}>
                    {user.email ? user.email : ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`${classes.cell} ${classes.nonEssentialColumn}`}
                >
                  <Typography variant='body1' className={classes.tableText}>
                    {user.firstName ? user.firstName : '-'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={`${classes.cell} ${classes.nonEssentialColumn}`}
                >
                  <Typography variant='body1' className={classes.tableText}>
                    {user.lastName ? user.lastName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={2} md={2} className={classes.cell}>
                  {user.email &&
                  office &&
                  emailInCollection(
                    user.email,
                    office.officeNotes.reportEmails
                  ) ? (
                    <CheckIcon className={classes.icon} />
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={2} className={classes.cell}>
                  {user.email &&
                  office &&
                  emailInCollection(
                    user.email,
                    office.officeNotes.billingEmails
                  ) ? (
                    <CheckIcon className={classes.icon} />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </ButtonBase>
          )
        )}
      </div>
    );
  }

  getOrderArr(key, firstDefaultOrder) {
    const { orderByValue, orderByOrder } = this.state;

    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, 'asc'];
  }

  changeSort(key) {
    switch (key) {
      case 'email':
        this.setState({
          orderByValue: ['email', 'firstName'],
          orderByOrder: this.getOrderArr(key, 'asc')
        });
        break;
      case 'firstName':
        this.setState({
          orderByValue: ['firstName', 'lastName'],
          orderByOrder: this.getOrderArr(key, 'asc')
        });
        break;
      case 'lastName':
        this.setState({
          orderByValue: ['lastName', 'firstName'],
          orderByOrder: this.getOrderArr(key, 'asc')
        });
        break;
      default:
        this.setState({
          orderByValue: ['email', 'firstName'],
          orderByOrder: ['asc', 'asc']
        });
    }
  }

  renderSortIcon(key) {
    const { classes } = this.props;
    const { orderByValue, orderByOrder } = this.state;

    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  }

  renderUserTableHeaderRow() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.headerRow}>
        <Grid
          item
          xs={6}
          sm={3}
          md={3}
          onClick={() => this.changeSort('email')}
        >
          <Typography
            variant='button'
            className={`${classes.headerRowText} ${classes.clickableText}`}
          >
            Email Address
          </Typography>
          {this.renderSortIcon('email')}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.nonEssentialColumn}
          onClick={() => this.changeSort('firstName')}
        >
          <Typography
            variant='button'
            className={`${classes.headerRowText} ${classes.clickableText}`}
          >
            First Name
          </Typography>
          {this.renderSortIcon('firstName')}
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.nonEssentialColumn}
          onClick={() => this.changeSort('lastName')}
        >
          <Typography
            variant='button'
            className={`${classes.headerRowText} ${classes.clickableText}`}
          >
            Last Name
          </Typography>
          {this.renderSortIcon('lastName')}
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <Typography variant='button' className={classes.headerRowText}>
            Report Email
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <Typography variant='button' className={classes.headerRowText}>
            Billing Email
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      allowedToManage,
      classes,
      dialogAddEditOfficeUserClick,
      dialogAddEditOfficeUserItem
    } = this.props;

    return (
      <span>
        <div
          className={
            allowedToManage
              ? classes.titleContainer
              : `${classes.titleContainer} ${classes.titleContainerNoAdd}`
          }
        >
          <Typography variant='h6' className={classes.title}></Typography>
          {allowedToManage ? (
            <Tooltip title='Add User'>
              <IconButton
                variant='contained'
                color='secondary'
                aria-label='Add User'
                onClick={() => {
                  dialogAddEditOfficeUserClick(true);
                  dialogAddEditOfficeUserItem(null);
                }}
              >
                <AddIcon className={classes.addIcon} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
        {this.renderUserTableHeaderRow()}
        {this.renderUserTable(allowedToManage)}
      </span>
    );
  }
}

const styles = theme => ({
  titleContainer: {
    padding: '8px 24px 8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5'
  },
  titleContainerNoAdd: {
    padding: '16px 24px',
    height: theme.spacing.unit * 3
  },
  sortIcon: {
    marginRight: 3,
    marginBottom: 2,
    fontSize: 12
  },
  headerRow: {
    height: 24,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px !important'
    },
    '& > div:first-child': {
      padding: '0px !important',
      paddingLeft: '34px !important',
      justifyContent: 'flex-start'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    },
    [theme.breakpoints.down('xs')]: {}
  },
  headerRowText: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  clickableText: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addIcon: {
    color: '#696969'
  },
  tableContainer: {
    width: '100%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '55vh',
    [theme.breakpoints.down('xs')]: {
      height: '49vh'
    }
  },
  buttonBase: {
    width: '100%',
    marginBottom: 0,
    minHeight: 48,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
    fontSize: theme.spacing.unit * 2.5
  },
  firstCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '28px !important'
  },
  rowIndex: {
    marginRight: 10,
    fontSize: '10px !important',
    paddingRight: 2
  },
  rowIndexDoubleDig: {
    marginRight: 6
  },
  avatar: {
    '& img': {
      height: 30,
      width: 30,
      borderRadius: '50%'
    },
    marginRight: 10
  },
  tableText: {
    fontSize: theme.spacing.unit * 1.5,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  icon: {
    fontSize: theme.spacing.unit * 1.2
  },
  nonEssentialColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  }
});

const mapStateToProps = (state, props) => {
  const officeUsersData = state.firestore.ordered['officeUsersData'];
  let officeUsers;

  if (isLoaded(officeUsersData) && !isEmpty(officeUsersData)) {
    officeUsers = officeUsersData;
  }

  return { officeUsers };
};

export default compose(
  connect(mapStateToProps, {
    dialogAddEditOfficeUserClick,
    dialogAddEditOfficeUserItem
  }),
  firestoreConnect(props => {
    let queries = [];

    if (props.office && props.office.id) {
      queries.push({
        collection: 'accounts',
        where: ['offices', 'array-contains', props.office.id],
        storeAs: 'officeUsersData'
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(OfficeUsersTable);
