/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { voicemailStorage, db, rrfProps } from '../../../../index';

const createData = (
  name,
  date,
  file,
  number,
  callId,
  employeeListened,
  recordingsDocId,
  filePath,
  skillId
) => {
  return {
    name,
    date,
    file,
    number,
    callId,
    employeeListened,
    recordingsDocId,
    filePath,
    skillId
  };
};

const getVoicemailsForOffice = async (
  officeInfo,
  setVoiceMails,
  componentVoiceMails
) => {
  const voicemailsStorageRef = voicemailStorage.ref();
  const { skillId, officeName } = officeInfo;
  const allVoicemailRefs = await db
    .collection('recordings')
    .where('pathInfo.skillId', '==', skillId)
    .where('kind', '==', 'VOICEMAIL')
    .get();
  const arrOfVoiceMailDocs = allVoicemailRefs.docs.map(doc => {
    return { id: doc.id, ...doc.data() };
  });
  let voicemails = [];

  for (const doc of arrOfVoiceMailDocs) {
    const {
      finalPath,
      pathInfo: { date, phoneNumber, callId, skillId },
      employeeListened,
      id
    } = doc;

    const number = phoneNumber ? phoneNumber : 'N/A';
    const dateString = date.month + '-' + date.day + '-' + date.year;
    const url = await voicemailsStorageRef.child(finalPath).getDownloadURL();
    const filePath = finalPath;
    voicemails.push({
      url,
      dateString,
      officeName,
      number,
      callId,
      employeeListened,
      recordingsDocId: id,
      filePath,
      skillId
    });
  }

  for (const voicemailObj of voicemails) {
    const {
      url,
      dateString,
      officeName,
      callId,
      number,
      employeeListened,
      recordingsDocId,
      filePath,
      skillId
    } = voicemailObj;
    componentVoiceMails.push(
      createData(
        officeName,
        dateString,
        url,
        number,
        callId,
        employeeListened,
        recordingsDocId,
        filePath,
        skillId
      )
    );
  }

  componentVoiceMails.sort((a, b) =>
    a.name > b.name ? 1 : a.name === b.name ? (a.date > b.date ? 1 : -1) : -1
  );

  setVoiceMails(componentVoiceMails);
};

const getSkillId = async officeId => {
  const officeRef = await db.collection('clients').doc(officeId).get();
  const officeData = officeRef.data();
  const officeName = officeData.officeInformation.name;
  const skillId = officeData.five9.skills.answering
    ? officeData.five9.skills.answering
    : null;
  return { officeName, skillId };
};

export const getVoicemails = async (
  officeId,
  setVoiceMails,
  componentVoiceMails
) => {
  const officeInfo = await getSkillId(officeId);
  getVoicemailsForOffice(officeInfo, setVoiceMails, componentVoiceMails);
};

export const setlistened = async docId => {
  await db
    .collection('recordings')
    .doc(docId)
    .update({ employeeListened: true });
};

export const deleteVoicemail = async ({ docId, filePath, skillId }) => {
  const host = `${window.location.protocol}//${window.location.hostname}`;
  const deleteFive9Recording = rrfProps.firebase
    .functions()
    .httpsCallable('five9-deleteFive9Recording');
  const reqBody = {
    host,
    role: 'employee',
    recordingSkillId: skillId,
    recordingDocId: docId,
    recordingFilePath: filePath
  };

  await deleteFive9Recording(reqBody);
};
