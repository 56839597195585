export const styles = theme => ({
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textTransform: 'uppercase',
    '& > h6': {
      color: theme.palette.secondary.main
    },
    '& > span': {
      fontSize: 10,
      marginLeft: 5,
      fontStyle: 'italic',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  subSectionTitle: {
    width: '100%',
    textTransform: 'uppercase',
    marginTop: 24
  },
  sectionInfoMessage: {
    fontStyle: 'italic',
    marginTop: 24,
    textAlign: 'center',
    width: '100%'
  },
  sectionActions: {
    flex: '0 0 auto',
    margin: '24px 0px 8px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  name: {
    '& > div': {
      width: '100%'
    }
  },
  phone: {
    flex: 1,
    padding: '0 5px 0 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  doctor: {
    flex: 1,
    padding: '0 0 0 5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  status: {
    '& > div': {
      width: '100%',
      marginTop: 16
    }
  },
  offerings: {
    width: '100%',
    marginTop: 16,
    paddingLeft: 12
  },
  pricingPlans: {
    marginBottom: 16
  },
  radioButtonControlLabel: {
    marginTop: 16
  },
  creditRow: {
    display: 'flex',
    flex: 1,
    '& > div': {
      flex: 1,
      marignLeft: 8,
      marginRight: 8
    },
    '& > div:first-child': {
      marignLeft: 0
    },
    '& > div:nth-child(2)': {
      flex: 2
    },
    '& > div:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        display: 'block',
        marginRight: 0,
        marginLeft: 0,
        width: '100%'
      },
      '& > div > div': {
        width: '100%'
      },
      '& > div:last-child > div': {
        width: 'calc(100% - 14px)'
      }
    }
  },
  address: {
    '& > div': {
      width: '100%'
    }
  },
  city: {
    flex: 4,
    padding: '0 5px 0 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  state: {
    flex: 2,
    padding: '0 5px 0 5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0'
    },
    '& > div': {
      width: '100%',
      marginTop: 16,
      marginBottom: 8
    }
  },
  zip: {
    flex: 3,
    padding: '0 5px 0 5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  country: {
    flex: 2,
    padding: '0 0 0 5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      padding: '0'
    },
    '& > div': {
      width: '100%',
      marginTop: 16,
      marginBottom: 8
    }
  },
  dayContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  openTime: {
    flex: 6,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  separator: {
    flex: 1,
    padding: '0 5px 0 5px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  closeTime: {
    flex: 6,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0'
    },
    '& > div': {
      width: '100%'
    }
  },
  closed: {
    flex: 4,
    padding: '0 0 0 25px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      padding: '0'
    }
  },
  userRow: {
    display: 'flex',
    '& > div': {
      marignLeft: 8,
      marginRight: 8
    },
    '& > div:first-child': {
      marignLeft: 0
    },
    '& > div:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        display: 'block',
        marginRight: 0,
        marginLeft: 0,
        width: '100%'
      },
      '& > div > div': {
        width: '100%'
      },
      '& > div:last-child > div': {
        width: 'calc(100% - 14px)'
      },
      '& > label': {
        width: '100%',
        marginTop: 8,
        marginLeft: 0
      }
    }
  },
  reviewSection: {
    marginBottom: 32
  },
  sectionBody: {
    marginTop: 16
  },
  sectionRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& > p:first-child': {
      display: 'flex',
      flex: 2,
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    '& > p:last-child': {
      display: 'flex',
      flex: 10,
      [theme.breakpoints.down('xs')]: {
        flex: 4
      }
    },
    '& > div:last-child': {
      flex: 10,
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        flex: 4
      }
    }
  },
  pricingSeperator: {
    marginLeft: 5,
    marginRight: 5
  }
});
