import {
  NOT_SCHEDULED_ADD,
  NOT_SCHEDULED_DELETE,
  NOT_SCHEDULED_EDIT,
  NOT_SCHEDULED_INITIALIZE,
  NOT_SCHEDULED_SEARCH
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case NOT_SCHEDULED_ADD:
      return action.payload;
    case NOT_SCHEDULED_DELETE:
      return action.payload;
    case NOT_SCHEDULED_EDIT:
      return action.payload;
    case NOT_SCHEDULED_INITIALIZE:
      return action.payload;
    case NOT_SCHEDULED_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
