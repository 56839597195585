import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../../../../components/DialogTransition';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditDrProfileClick,
  dialogEditDrProfileItem
} from '../../../../../../actions';
import { firestoreConnect } from 'react-redux-firebase';
import ListConfigSettingForm from './Forms/ListConfigSettingForm';
import GeneralSettingForm from './Forms/GeneralSettingForm';
import { db } from '../../../../../../';

const DrProfileSettingEdit = ({
  classes,
  drProfile,
  itemObj,
  fullScreen,
  open,
  dialogEditDrProfileClick,
  dialogEditDrProfileItem,
  location
}) => {
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);
  const [settingType, setSettingType] = useState('');
  const [listConfig, setListConfig] = useState({
    ageParams: {
      maxAge: 0,
      minAge: 0
    },
    balance: 0,
    filterMedicaid: false,
    location: '',
    pediatric: false,
    timeOverdue: {
      maxMonths: 0,
      minMonths: 0
    }
  });

  useEffect(() => {
    if (itemObj && itemObj.drProfileSetting) {
      setSettingType(itemObj.drProfileSetting);
    }

    if (location && !drProfile) {
      const listConfigCopy = listConfig;
      listConfigCopy.location = location;
      setListConfig(listConfigCopy);
    }
  }, [itemObj, location, drProfile, listConfig]);

  const onSubmit = async values => {
    const { originalProp } = itemObj;
    setLoadingAfterSubmit(true);
    const drProfileCopy = drProfile;
    const { id } = drProfileCopy;
    const drProfileRef = db
      .collection('clients')
      .doc(id)
      .collection('drProfile')
      .doc('profile');
    delete drProfileCopy.id;
    if (settingType === 'listConfig') {
      if (!drProfileCopy.listConfig) {
        drProfileCopy.listConfig = {};
        drProfileCopy['listConfig'][originalProp] = values[originalProp];
      } else {
        drProfileCopy['listConfig'][originalProp] = values[originalProp];
      }
    } else {
      if (originalProp === 'insurances') {
        const cleanedVals = removeUnnamedInsurances(values[originalProp]);
        drProfileCopy[originalProp] = cleanedVals;
      } else {
        drProfileCopy[originalProp] = values[originalProp];
      }
    }

    if (!drProfile['officeName']) drProfileCopy['officeName'] = location;

    try {
      await drProfileRef.set(drProfileCopy, { merge: true });
      onClose();
    } catch (e) {
      console.error(e);
      onClose();
    }
  };

  const removeUnnamedInsurances = insurances => {
    const namedInsurances = [];
    for (const insurance in insurances) {
      if (insurances[insurance].name) {
        insurances[insurance].status = insurances[insurance].status.value;
        namedInsurances.push(insurances[insurance]);
      }
    }
    return namedInsurances;
  };

  const onClose = () => {
    setLoadingAfterSubmit(false);
    setSettingType('');
    dialogEditDrProfileItem(null);
    dialogEditDrProfileClick(!open);
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      className={classes.settingDialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {itemObj && itemObj.properSettingLabel}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentSettings}>
        {itemObj && itemObj.drProfileSetting === 'listConfig' ? (
          <ListConfigSettingForm
            initialValues={
              itemObj && {
                [itemObj.originalProp]:
                  drProfile.listConfig &&
                  drProfile.listConfig[itemObj.originalProp]
                    ? drProfile.listConfig[itemObj.originalProp]
                    : listConfig[itemObj.originalProp]
              }
            }
            handleSubmit={onSubmit}
            classes={classes}
            loadingAfterSubmit={loadingAfterSubmit}
            settingLabel={itemObj && itemObj.properSettingLabel}
            originalProp={itemObj && itemObj.originalProp}
          />
        ) : (
          <GeneralSettingForm
            initialValues={itemObj && { [itemObj.originalProp]: itemObj.item }}
            handleSubmit={onSubmit}
            classes={classes}
            loadingAfterSubmit={loadingAfterSubmit}
            settingLabel={itemObj && itemObj.properSettingLabel}
            originalProp={itemObj && itemObj.originalProp}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const {
    dialogEditDrProfileOpen: open,
    dialogEditDrProfileItem: itemObj
  } = state;
  return { open, itemObj };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditDrProfileClick,
    dialogEditDrProfileItem
  }),
  firestoreConnect(),
  withMobileDialog()
)(DrProfileSettingEdit);
