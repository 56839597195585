import {
  DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK,
  DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM
} from '../actions/types';

export const dialogEditOfferingTimeActiveEntryItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfferingTimeActiveEntryOpen = (
  state = false,
  action
) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfferingTimeActiveEntryReducer = {
  dialogEditOfferingTimeActiveEntryItem,
  dialogEditOfferingTimeActiveEntryOpen
};
