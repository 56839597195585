import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import CountUp from 'react-countup';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import moment from 'moment';
import { functions } from '../index';
import Loader from 'react-loader-spinner';
import { CONTRACTOR_COMMISSION_RATE } from '../services/constants';

const CallsAnsweredCommissions = ({ classes, employeeUid, ...rest }) => {
  const [answeredCallsCommissions, setAnsweredCallsCommissions] = useState(
    null
  );
  const [answeredCallsNumbers, setAnsweredCallsNumbers] = useState(null);
  const [loadingCommissions, setLoadingCommissions] = useState(false);
  const { memberContractor } = rest;
  const commisionRate = memberContractor ? CONTRACTOR_COMMISSION_RATE : 1;

  const newPatientCommissionsCalc = numberOfNewPatients => {
    /* $1.00 per newPatient*/
    return parseFloat(numberOfNewPatients.toFixed(2));
  };

  const scheduledCommissionsCalc = numberOfScheduled => {
    /* $.60 per scheduled*/
    numberOfScheduled = numberOfScheduled * 0.6;
    return parseFloat(numberOfScheduled.toFixed(2));
  };

  const rescheduledCommissionsCalc = numberOfRescheduled => {
    /* $.40 per rescheduled*/
    numberOfRescheduled = numberOfRescheduled * 0.4;
    return parseFloat(numberOfRescheduled.toFixed(2));
  };

  const allOtherCallsCommissionsCalc = numberOfAllOtherCalls => {
    /* $.20 per allOtherCalls*/
    numberOfAllOtherCalls = numberOfAllOtherCalls * 0.2;
    return parseFloat(numberOfAllOtherCalls.toFixed(2));
  };

  const totalCommissionsCalc = TotalCommissions => {
    let total = 0;
    Object.keys(TotalCommissions).forEach(commissionsKey => {
      total += TotalCommissions[commissionsKey];
    });
    return parseFloat(total.toFixed(2));
  };

  useEffect(() => {
    const formatDateTime = (time, type) => {
      if (type === 'startDate') {
        time = time.replace('T', ' ').split(' ')[0];
      } else {
        time = time.replace('T', ' ').split(' ')[0];
      }

      return time;
    };

    const getAnsweredCallsCommissions = async () => {
      const now = moment();
      const firstDateOfMonth = formatDateTime(
        moment()
          .startOf('month')
          .format(),
        'startDate'
      );
      const currentDate = formatDateTime(now.format(), 'endDate');
      const body = {
        employeeId: '"' + employeeUid + '"',
        startDate: firstDateOfMonth,
        endDate: currentDate
      };

      const getCallsAnsweredStatistics = await functions().httpsCallable(
        'statistics-getAnsweredCallsCommissions'
      );
      getCallsAnsweredStatistics(body)
        .then(res => {
          const {
            data: {
              total_calls_handled,
              total_rescheduled,
              total_scheduled,
              total_scheduled_new
            }
          } = res;

          const newPatients = total_scheduled_new,
            scheduled = total_scheduled - total_scheduled_new,
            rescheduledAppointments = total_rescheduled,
            allOtherCalls =
              total_calls_handled - total_rescheduled - total_scheduled,
            totalCalls = total_calls_handled;

          const newPatientCommissions = newPatientCommissionsCalc(newPatients);
          const scheduledCommissions = scheduledCommissionsCalc(scheduled);
          const rescheduledAppointmentCommissions = rescheduledCommissionsCalc(
            rescheduledAppointments
          );
          const allOtherCallsCommissions = allOtherCallsCommissionsCalc(
            allOtherCalls
          );
          const totalCommissions = totalCommissionsCalc({
            newPatientCommissions,
            scheduledCommissions,
            rescheduledAppointmentCommissions,
            allOtherCallsCommissions
          });

          const commissions = {
            newPatientCommissions,
            scheduledCommissions,
            rescheduledAppointmentCommissions,
            allOtherCallsCommissions,
            totalCommissions
          };

          const numbers = {
            newPatients,
            scheduled,
            rescheduledAppointments,
            allOtherCalls,
            totalCalls
          };

          setLoadingCommissions(false);
          setAnsweredCallsCommissions(commissions);
          setAnsweredCallsNumbers(numbers);
        })
        .catch(error => {
          console.error(error);
          setAnsweredCallsCommissions('Failed');
        });
    };

    if (!answeredCallsCommissions) {
      setLoadingCommissions(true);
      getAnsweredCallsCommissions();
    }
  }, [employeeUid, answeredCallsCommissions]);

  const SubStat = ({ classes, commisionRate, totalCount }) => {
    const end = totalCount * commisionRate;

    return (
      <Typography variant='button' className={classes.subStat}>
        {loadingCommissions ? (
          <Loader type='Oval' color='#055f5b' height='19' width='15' />
        ) : (
          <>
            ${' '}
            <CountUp
              duration={1}
              end={end}
              separator=','
              decimals={2}
            />
          </>
        )}
      </Typography>
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography
          variant='button'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <MoneyIcon />
          Calls Answered Commission
        </Typography>
      </div>
      <div className={classes.totalContainer}>
        <Typography variant='h4' className={classes.typoBlock}>
          {loadingCommissions ? (
            <Loader type='Oval' color='#055f5b' height='28' width='28' />
          ) : (
            <>
              ${' '}
              <CountUp
                duration={1}
                end={
                  answeredCallsCommissions &&
                  answeredCallsCommissions.totalCommissions
                    ? (answeredCallsCommissions.totalCommissions * commisionRate).toFixed(2)
                    : 0
                }
                separator=','
                decimals={2}
              />
            </>
          )}
        </Typography>
        <Typography variant='button' style={{ fontSize: 12 }}>
          Total Commissions For The Month
        </Typography>
        <Typography variant='button' className={classes.subStatLabel}>
          {answeredCallsNumbers && answeredCallsNumbers.totalCalls} Total Phone
          Calls Handled
        </Typography>
      </div>
      <Grid container spacing={24} className={classes.subStats}>
        <Grid item xs={3} sm={3}>
          <div className={classes.subStatContainer}>
            <SubStat
              totalCount={
                answeredCallsCommissions &&
                answeredCallsCommissions.allOtherCallsCommissions
              }
              classes={classes}
              commisionRate={commisionRate}
            />
            <Typography variant='button' className={classes.subStatLabel}>
              All Other Calls
            </Typography>
            <Typography variant='button' className={classes.subStatLabel}>
              {answeredCallsNumbers && answeredCallsNumbers.allOtherCalls} x
              ${(0.2 * commisionRate).toFixed(2)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} sm={3}>
          <div className={classes.subStatContainer}>
            <SubStat
              totalCount={
                answeredCallsCommissions &&
                answeredCallsCommissions.rescheduledAppointmentCommissions
              }
              classes={classes}
              commisionRate={commisionRate}
            />
            <Typography variant='button' className={classes.subStatLabel}>
              Rescheduled
            </Typography>
            <Typography variant='button' className={classes.subStatLabel}>
              {answeredCallsNumbers &&
                answeredCallsNumbers.rescheduledAppointments}{' '} x 
              ${(0.4 * commisionRate).toFixed(2)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} sm={3}>
          <div className={classes.subStatContainer}>
            <SubStat
              totalCount={
                answeredCallsCommissions &&
                answeredCallsCommissions.scheduledCommissions
              }
              classes={classes}
              commisionRate={commisionRate}
            />
            <Typography variant='button' className={classes.subStatLabel}>
              Scheduled
            </Typography>
            <Typography variant='button' className={classes.subStatLabel}>
              {answeredCallsNumbers && answeredCallsNumbers.scheduled} x 
              ${(0.6 * commisionRate).toFixed(2)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3} sm={3}>
          <div className={classes.subStatContainer}>
            <SubStat
              totalCount={
                answeredCallsCommissions &&
                answeredCallsCommissions.newPatientCommissions
              }
              classes={classes}
              commisionRate={commisionRate}
            />
            <Typography variant='button' className={classes.subStatLabel}>
              New Patients
            </Typography>
            <Typography variant='button' className={classes.subStatLabel}>
              {answeredCallsNumbers && answeredCallsNumbers.newPatients} x 
              ${(1 * commisionRate).toFixed(2)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 50
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingTop: 18,
    paddingRight: 8
  },
  totalContainer: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  subtextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  subStats: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    margin: 0,
    width: '100%',
    position: 'relative',
    marginTop: 50
  },
  subStatContainer: {
    flexDirection: 'column'
  },
  subStat: {
    fontSize: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center'
  },
  subStatLabel: {
    fontSize: 10
  },
  typoBlock: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default compose(withStyles(styles))(CallsAnsweredCommissions);
