/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import CardSummary from './components/CardSummary';
import {
  dialogEditPaymentInfoClick,
  dialogEditPaymentInfoItem
} from '../../../../../../actions';
import { styles } from './styles';
import { db } from '../../../../../../index';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Loader from 'react-loader-spinner';
import { Button, Grid } from '@material-ui/core';

const Payment = ({
  office,
  classes,
  theme: {
    palette: { primary }
  }
}) => {
  const [stripeCusKey, setStripeCusKey] = useState({
    val: '',
    fetched: false,
    saving: false
  });
  const stripeBaseUrl = 'https://dashboard.stripe.com/customers/';
  let stripeUrl;
  const regexStripeText = /^cus_/;
  if (regexStripeText.test(stripeCusKey.val) && stripeCusKey.fetched) {
    stripeUrl = stripeBaseUrl + stripeCusKey.val;
  }

  const saveStripeCusKey = async () => {
    setStripeCusKey({ ...stripeCusKey, saving: true });

    if (!stripeCusKey.val || !regexStripeText.test(stripeCusKey.val)) {
      setStripeCusKey({ ...stripeCusKey, saving: false });
      return console.error('Invalid. Stripe Key requires "cus_" prefix.');
    }
    const { id } = office;
    const { val } = stripeCusKey;
    const subStripeCollectionRef = db
      .collection('clients')
      .doc(id)
      .collection('stripe')
      .doc('customer');

    try {
      await subStripeCollectionRef.set({ id: val }, { merge: true });
    } catch (e) {
      console.error(e);
    }

    setStripeCusKey({ ...stripeCusKey, saving: false });
  };

  const updateStripe = e => {
    const {
      target: { value }
    } = e;
    setStripeCusKey({ ...stripeCusKey, val: value });
  };

  useEffect(() => {
    const fetchStripeCusKey = async clientId => {
      try {
        let stripe = await db
          .collection('clients')
          .doc(clientId)
          .collection('stripe')
          .doc('customer')
          .get();

        if (stripe.exists) {
          const { id } = stripe.data();
          setStripeCusKey({ fetched: true, val: id });
        } else {
          setStripeCusKey({ fetched: true, val: 'No stripe Key.' });
        }
      } catch (e) {
        console.error(e);
        setStripeCusKey({ fetched: true, val: 'No stripe Key.' });
      }
    };

    if (!stripeCusKey.fetched && office) {
      const id = office.id;
      fetchStripeCusKey(id);
    }
  }, [stripeCusKey, office]);

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataColumn}>
        <div className={classes.dataLabel}>
          <div className={classes.offeringLabel}>Payment Method</div>
        </div>
        <div className={classes.dataText}>
          <CardSummary office={office} />
        </div>
        <div className={classes.dataText}>
          <FormControl
            fullWidth
            className={classes.formControl}
            variant='outlined'
            margin='normal'
          >
            <InputLabel
              variant='outlined'
              className={classes.offeringLabel}
              animated='false'
              shrink={true}
            >
              {'Stripe Key'}
            </InputLabel>
            <OutlinedInput
              focused='true'
              name='stripeCusKey'
              fullWidth
              labelWidth={100}
              notched={true}
              margin='dense'
              onChange={updateStripe}
              value={stripeCusKey.val}
            />
            <Grid
              item
              xs={12}
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              {stripeUrl ? (
                <div className={classes.stripeLink}>
                  <a rel='noopener noreferrer' target='_blank' href={stripeUrl}>
                    Customer Stripe Page
                  </a>
                </div>
              ) : null}
              <Button onClick={saveStripeCusKey} disabled={stripeCusKey.saving}>
                {stripeCusKey.saving ? (
                  <>
                    <span>saving</span>
                    <Loader
                      type='Oval'
                      color={primary.main}
                      height='10'
                      width='10'
                      style={{ padding: '5px' }}
                    />
                  </>
                ) : (
                  'Save Key'
                )}
              </Button>
            </Grid>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    dialogEditPaymentInfoClick,
    dialogEditPaymentInfoItem
  }),
  withStyles(styles, { withTheme: true }),
  firestoreConnect()
)(Payment);
