import React, { useState, useContext } from 'react';
import DialogTransition from '../../../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import helpers from '../../../../../helpers';
import moment from 'moment-timezone';
import { AppContext } from '../../../../../contexts';
import { futureDate, time, required } from '../../../../../validation';
import { Close } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import { FieldDate, FieldTime } from '../../../../../components/Fields';
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Typography,
  Tooltip,
  withMobileDialog
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { styles } from './styles';

const DialogEditAppointmentTime = ({
  classes,
  relo,
  apptTimeOpen,
  onClose,
  fullScreen,
  contactId
}) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const {
    state: { firebase }
  } = useContext(AppContext);

  const onSubmit = async values => {
    setError(null);
    setLoading('Saving Entry; Please Keep Browser Window Open');
    try {
      const contacts = relo.contacts;
      const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;
      const reloOffice = await firebase
        .firestore()
        .collection('clients')
        .doc(relo.office)
        .get();

      const officeTimezone = reloOffice.data().officeInformation.timezone;
      const appointmentTime = moment.tz(apptDateTime, officeTimezone);
      contacts[contactId].appointmentTime = new Date(appointmentTime.format());
      const reloRef = firebase.firestore().collection('relos').doc(relo.id);

      await reloRef.set({ contacts }, { merge: true });
      setLoading(null);
      onClose();
    } catch (e) {
      console.error(e);
      setError('Failed To Update Appointment Time');
      setLoading(null);
    }
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  const validations = {
    appointmentDate: v =>
      helpers.composeFieldValidation(v, [required, futureDate]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time])
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={apptTimeOpen}
      onClose={() => onClose()}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit Appointment Time
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton
            color='inherit'
            aria-label='Close'
            onClick={() => onClose()}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <div className={classes.row}>
                <Field
                  name='appointmentDate'
                  label='Appointment Date'
                  variant='outlined'
                  placeholder='mm/dd/yyyy'
                  component={FieldDate}
                  disabled={loading !== null}
                  validate={validations.appointmentDate}
                />
                <Field
                  name='appointmentTime'
                  label='Appointment Time'
                  variant='outlined'
                  placeholder='HH:mm'
                  component={FieldTime}
                  disabled={loading !== null}
                  validate={validations.appointmentTime}
                />
              </div>
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DialogEditAppointmentTime.propTypes = {
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter
)(DialogEditAppointmentTime);
