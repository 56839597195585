/* eslint-disable import/prefer-default-export */
import { storage } from '../index';

export const getRecording = async (
  firestore,
  callId,
  setLoading,
  setAudio,
  setNoRecording
) => {
  let voicemailDoc;
  try {
    voicemailDoc = await firestore
      .firestore()
      .collection('recordings')
      .where('pathInfo.callId', '==', callId)
      .get();
  } catch (e) {
    console.error(e);
    setLoading(false);
    setNoRecording(true);
  }

  const voicemailDocData = voicemailDoc
    ? voicemailDoc.docs.map(doc => doc.data())[0]
    : null;

  if (voicemailDocData) {
    const { finalPath } = voicemailDocData;
    const recordingsStorageRef = storage.ref();
    recordingsStorageRef
      .child(finalPath)
      .getDownloadURL()
      .then(url => {
        const playElement = document.getElementById('playButton');
        const xnewAudioTag = document.createElement('audio');
        xnewAudioTag.src = url;
        playElement.appendChild(xnewAudioTag);
        setAudio(xnewAudioTag);
        setLoading(false);
        setNoRecording(false);
      })
      .catch(err => {
        setLoading(false);
        setNoRecording(true);
        console.error(err);
      });
  } else {
    setLoading(false);
    setNoRecording(true);
  }
};
