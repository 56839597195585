import _ from 'lodash';
import React, { Component } from 'react';
import CountUp from 'react-countup';
import ChangeIcon from '@material-ui/icons/SwapVert';
import DetailIcon from '@material-ui/icons/Timeline';
import DialogIndiEntriesDetails from './DialogIndiEntriesDetails';
import DialogIndiMistakesDetails from './DialogIndiMistakesDetails';
import DialogOverallEntriesDetails from './DialogOverallEntriesDetails';
import DialogOverallMistakesDetails from './DialogOverallMistakesDetails';
import DialogTeamEntriesDetails from './DialogTeamEntriesDetails';
import DialogTeamMistakesDetails from './DialogTeamMistakesDetails';
import EntryTypePopover from './EntryTypePopover';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import t from 'typy';
import theme from '../services/theme';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { capitalizeFirstLetter } from '../services/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogOverallEntriesDetailsClick,
  dialogOverallMistakesDetailsClick,
  dialogTeamEntriesDetailsClick,
  dialogTeamEntriesDetailsItem,
  dialogTeamMistakesDetailsClick,
  dialogTeamMistakesDetailsItem,
  dialogIndiEntriesDetailsClick,
  dialogIndiEntriesDetailsItem,
  dialogIndiMistakesDetailsClick,
  dialogIndiMistakesDetailsItem
} from '../actions';
import { entryTypes } from '../services/options';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { LineChart, Line } from 'recharts';
import { withStyles } from '@material-ui/core/styles';

class QuickOverallStats extends Component {
  state = {
    anchorEl: null,
    openPopover: false,
    entryCode:
      this.props.profile && this.props.profile.groups
        ? `${
            this.props.personalManagement &&
            this.props.profile.groups.includes('callsAnswered') &&
            !this.props.profile.groups.includes('teamLead')
              ? 'callsAnswered'
              : 'scheduled'
          }`
        : null,
    monthTotal: 0,
    dayTotal: 0,
    weekTotal: 0,
    yearTotal: 0,
    chartData: [],
    initialized: false
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { overallStats } = this.props;
    const { entryCode } = this.state;

    if (
      !_.isEqual(entryCode, nextState.entryCode) ||
      !_.isEqual(overallStats, nextProps.overallStats)
    ) {
      this.calculateStats(nextState.entryCode, nextProps.overallStats);
    } else if (nextProps.overallStats && !nextState.initialized) {
      this.calculateStats(nextState.entryCode, nextProps.overallStats);
    }

    if (nextState.entryCode === null && nextProps.profile.groups) {
      this.setState({
        entryCode: `${
          nextProps.personalManagement &&
          nextProps.profile.groups.includes('callsAnswered') &&
          !nextProps.profile.groups.includes('teamLead')
            ? 'callsAnswered'
            : 'scheduled'
        }`
      });
    }
  }

  calculateStats(collection, overallStats) {
    const currentMonth = moment(new Date()).format('YYYY-MM');
    const today = moment(new Date()).format('YYYY-MM-DD');
    const startWeek = moment(new Date()).startOf('week');
    const endWeek = moment(new Date()).endOf('week');
    const startMonth = moment(new Date()).subtract(1, 'month');
    const endMonth = moment(new Date());
    let monthTotal = null;
    let dayTotal = null;
    let weekTotal = null;
    let yearTotal = null;
    let chartData = [];

    if (overallStats[collection]) {
      let dailyStats = overallStats[collection].daily;
      let monthlyStats = overallStats[collection].monthly;

      monthTotal = isEmpty(overallStats)
        ? 0
        : t(overallStats, `${collection}.monthly[${currentMonth}]`).safeObject;
      dayTotal = t(overallStats, `${collection}.daily[${today}]`).safeObject;

      for (let month in monthlyStats) {
        if (month.includes(moment(new Date()).format('YYYY'))) {
          yearTotal = yearTotal + monthlyStats[month];
        }
      }

      for (let day in dailyStats) {
        if (moment(day) >= startMonth && moment(day) < endMonth) {
          chartData.push({ date: day, value: dailyStats[day] });
        }

        if (moment(day) >= startWeek && moment(day) < endWeek) {
          weekTotal = weekTotal + dailyStats[day];
        }
      }

      this.setState({
        monthTotal,
        dayTotal,
        weekTotal,
        yearTotal,
        chartData,
        initialized: true
      });
    }
  }

  handleEntryTypeClick = () => event => {
    const { currentTarget } = event;

    this.setState({
      anchorEl: currentTarget,
      openPopover: !this.state.openPopover
    });
  };

  handleClickEntry = code => {
    this.handleClose();
    this.setState({ entryCode: code });
  };

  handleClose() {
    this.setState({ openPopover: false });
  }

  renderOverallStatsDialogs() {
    const { mistakes, personalManagement, teamManagement } = this.props;
    const { entryCode } = this.state;

    if (personalManagement) {
      return mistakes ? (
        <DialogIndiMistakesDetails collection={entryCode} />
      ) : (
        <DialogIndiEntriesDetails collection={entryCode} />
      );
    } else if (teamManagement) {
      return mistakes ? (
        <DialogTeamMistakesDetails collection={entryCode} />
      ) : (
        <DialogTeamEntriesDetails collection={entryCode} />
      );
    } else {
      return mistakes ? (
        <DialogOverallMistakesDetails collection={entryCode} />
      ) : (
        <DialogOverallEntriesDetails collection={entryCode} />
      );
    }
  }

  render() {
    const {
      classes,
      dialogOverallEntriesDetailsClick,
      dialogOverallMistakesDetailsClick,
      dialogTeamEntriesDetailsClick,
      dialogTeamEntriesDetailsItem,
      dialogTeamMistakesDetailsClick,
      dialogTeamMistakesDetailsItem,
      dialogIndiEntriesDetailsClick,
      dialogIndiEntriesDetailsItem,
      dialogIndiMistakesDetailsClick,
      dialogIndiMistakesDetailsItem,
      mistakes,
      profile,
      personDetails,
      personalManagement,
      teamDetails,
      teamManagement
    } = this.props;
    const {
      anchorEl,
      entryCode,
      openPopover,
      chartData,
      monthTotal,
      dayTotal,
      weekTotal,
      yearTotal
    } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.entryTypeSwitcherContainer}>
          <Typography
            variant='button'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {entryCode && _.mapKeys(entryTypes, 'code')[entryCode].icon(18, 5)}
            {entryCode && _.mapKeys(entryTypes, 'code')[entryCode].text}
          </Typography>
          <Tooltip title='View Details'>
            <IconButton
              color='inherit'
              aria-label='View Details'
              className={classes.viewDetailsButton}
              onClick={() => {
                if (personalManagement) {
                  if (mistakes) {
                    dialogIndiMistakesDetailsItem(personDetails);
                    dialogIndiMistakesDetailsClick(true);
                  } else {
                    dialogIndiEntriesDetailsItem(personDetails);
                    dialogIndiEntriesDetailsClick(true);
                  }
                } else if (teamManagement) {
                  if (mistakes) {
                    dialogTeamMistakesDetailsItem(teamDetails);
                    dialogTeamMistakesDetailsClick(true);
                  } else {
                    dialogTeamEntriesDetailsItem(teamDetails);
                    dialogTeamEntriesDetailsClick(true);
                  }
                } else {
                  if (mistakes) {
                    dialogOverallMistakesDetailsClick(true);
                  } else {
                    dialogOverallEntriesDetailsClick(true);
                  }
                }
              }}
            >
              <DetailIcon style={styles.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Select Entry Type'>
            <IconButton
              color='inherit'
              aria-label='Select Entry Type'
              onClick={this.handleEntryTypeClick()}
            >
              <ChangeIcon style={styles.icon} />
            </IconButton>
          </Tooltip>
          {this.renderOverallStatsDialogs()}
          <EntryTypePopover
            open={openPopover}
            anchorEl={anchorEl}
            handleClose={() => this.handleClose()}
            handleClickEntry={this.handleClickEntry.bind(this)}
            teams={teamManagement}
            personal={personalManagement}
            profile={profile}
          />
        </div>
        <Grid container spacing={24} className={classes.mainStats}>
          <Grid item xs={12} sm={4} className={classes.thisMonthStat}>
            {monthTotal || monthTotal === undefined || monthTotal === 0 ? (
              <Typography variant='h4'>
                <CountUp
                  duration={1}
                  end={monthTotal ? monthTotal : 0}
                  separator=','
                  decimals={0}
                />
              </Typography>
            ) : (
              <Loader
                type='Oval'
                color={theme.palette.primary.main}
                height='20'
                width='20'
              />
            )}
            <Typography variant='button' style={{ fontSize: 10 }}>
              This Month
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.quickStatsGraph}>
            <LineChart
              width={300}
              height={100}
              data={chartData}
              margin={{ top: 16, right: 16, left: 16, bottom: 16 }}
            >
              <Line
                type='monotone'
                dataKey='value'
                stroke={theme.palette.secondary.main}
                dot={false}
              />
            </LineChart>
            <Typography variant='button' style={{ fontSize: 10 }}>
              30-Day Trend
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.subStats}>
          <Grid item xs={4} sm={4} style={{ justifyContent: 'flex-end' }}>
            <div className={classes.subStatContainer}>
              <SubStat totalCount={dayTotal} classes={classes} />
              <Typography variant='button' className={classes.subStatLabel}>
                Today
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className={classes.subStatContainer}>
              <SubStat totalCount={weekTotal} classes={classes} />
              <Typography variant='button' className={classes.subStatLabel}>
                This Week
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} style={{ justifyContent: 'flex-start' }}>
            <div className={classes.subStatContainer}>
              <SubStat totalCount={yearTotal} classes={classes} />
              <Typography variant='button' className={classes.subStatLabel}>
                This Year
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const SubStat = ({ classes, totalCount }) => {
  return (
    <Typography variant='button' className={classes.subStat}>
      <CountUp
        duration={1}
        end={totalCount ? totalCount : 0}
        separator=','
        decimals={0}
      />
    </Typography>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 5
  },
  entryTypeSwitcherContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  viewDetailsButton: {
    marginLeft: 'auto'
  },
  icon: {
    color: theme.palette.secondary.main
  },
  titleContainer: {
    padding: theme.spacing.unit * 2
  },
  mainStats: {
    marginTop: theme.spacing.unit * 3
  },
  thisMonthStat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  quickStatsGraph: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  subStats: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    marginTop: theme.spacing.unit * 6
  },
  subStatContainer: {
    flexDirection: 'column'
  },
  subStat: {
    fontSize: theme.spacing.unit * 2
  },
  subStatLabel: {
    fontSize: 10
  }
});

const mapStateToProps = (state, props) => {
  const orderedData = state.firestore.ordered;
  let overallStats = null;
  let overallMemberStats = null;

  if (props.teamManagement) {
    overallStats = {};
    const orderedData = state.firestore.ordered;

    entryTypes.forEach(type => {
      let typeDataDaily;
      let typeDataMonthly;
      overallMemberStats = {
        ...overallMemberStats,
        [`${type.code}`]: {}
      };

      for (let key in orderedData) {
        const keyArr = key.split('-');
        const keyName = keyArr[0];
        const entryType = keyArr[1];
        const team = keyArr[3];
        const member = keyArr[4];

        if (keyName === 'teamStats' && entryType === type.code) {
          typeDataDaily =
            orderedData[`teamStats-${entryType}-daily-${team}-${member}`];
          typeDataMonthly =
            orderedData[`teamStats-${entryType}-monthly-${team}-${member}`];
          if (
            isLoaded(typeDataDaily) &&
            !isEmpty(typeDataDaily) &&
            isLoaded(typeDataMonthly) &&
            !isEmpty(typeDataMonthly)
          ) {
            delete typeDataDaily[0].id;
            delete typeDataMonthly[0].id;
            if (!overallMemberStats[type.code][member]) {
              overallMemberStats[type.code][member] = {
                daily: {},
                monthly: {}
              };
            }

            overallMemberStats[type.code][member].daily = typeDataDaily[0];
            overallMemberStats[type.code][member].monthly = typeDataMonthly[0];
          }
        }
      }
    });

    for (let type in overallMemberStats) {
      overallStats[type] = {};
      overallStats[type].daily = {};
      overallStats[type].monthly = {};
      overallStats[type].total = 0;

      for (let member in overallMemberStats[type]) {
        if (overallMemberStats[type][member]) {
          for (let month in overallMemberStats[type][member].monthly) {
            overallStats[type].monthly[month] = overallStats[type].monthly[
              month
            ]
              ? overallStats[type].monthly[month] +
                overallMemberStats[type][member].monthly[month]
              : overallMemberStats[type][member].monthly[month];
          }

          for (let day in overallMemberStats[type][member].daily) {
            overallStats[type].daily[day] = overallStats[type].daily[day]
              ? overallStats[type].daily[day] +
                overallMemberStats[type][member].daily[day]
              : overallMemberStats[type][member].daily[day];
          }

          overallStats[type].total = overallStats[type].total
            ? overallStats[type].total + overallMemberStats[type][member].total
            : overallMemberStats[type][member].total;
        }
      }
    }
  } else if (!props.mistakes && props.personalManagement) {
    overallStats = {};
    const orderedData = state.firestore.ordered;

    entryTypes.forEach(type => {
      let typeDataDaily;
      let typeDataMonthly;
      overallStats = {
        ...overallStats,
        [`${type.code}`]: { daily: {}, monthly: {} }
      };

      for (let key in orderedData) {
        const keyArr = key.split('-');
        const keyName = keyArr[0];
        const entryType = keyArr[1];
        const member = keyArr[3];

        if (keyName === 'personalStats' && entryType === type.code) {
          typeDataDaily =
            orderedData[`personalStats-${entryType}-daily-${member}`];
          typeDataMonthly =
            orderedData[`personalStats-${entryType}-monthly-${member}`];
          if (isLoaded(typeDataDaily) && isLoaded(typeDataMonthly)) {
            if (typeDataDaily[0] && typeDataMonthly[0]) {
              delete typeDataDaily[0].id;
              delete typeDataMonthly[0].id;
              overallStats[`${type.code}`].daily = typeDataDaily[0];
              overallStats[`${type.code}`].monthly = typeDataMonthly[0];
            }
          }
        }
      }
    });
  } else {
    entryTypes.forEach(type => {
      let typeDataDaily;
      let typeDataMonthly;
      overallStats = {
        ...overallStats,
        [`${type.code}`]: { daily: {}, monthly: {} }
      };

      if (props.mistakes) {
        typeDataDaily = orderedData[`mistakeStats-${type.code}Daily`];
        typeDataMonthly = orderedData[`mistakeStats-${type.code}Monthly`];
      } else {
        typeDataDaily = orderedData[`overallStats-${type.code}Daily`];
        typeDataMonthly = orderedData[`overallStats-${type.code}Monthly`];
      }

      if (
        isLoaded(typeDataDaily) &&
        !isEmpty(typeDataDaily) &&
        isLoaded(typeDataMonthly) &&
        !isEmpty(typeDataMonthly)
      ) {
        delete typeDataDaily[0].id;
        delete typeDataMonthly[0].id;
        overallStats[`${type.code}`].daily = typeDataDaily[0];
        overallStats[`${type.code}`].monthly = typeDataMonthly[0];
      }
    });
  }

  return { profile: state.firebase.profile, overallStats };
};

export default compose(
  connect(mapStateToProps, {
    dialogOverallEntriesDetailsClick,
    dialogOverallMistakesDetailsClick,
    dialogTeamEntriesDetailsClick,
    dialogTeamEntriesDetailsItem,
    dialogTeamMistakesDetailsClick,
    dialogTeamMistakesDetailsItem,
    dialogIndiEntriesDetailsClick,
    dialogIndiEntriesDetailsItem,
    dialogIndiMistakesDetailsClick,
    dialogIndiMistakesDetailsItem
  }),
  firestoreConnect(props => {
    let queries = [];

    if (props.teamManagement) {
      let team = props.teamDetails;

      if (team && team.members) {
        const entriesQuery = (team, member, periodType, entryTypeCode) => ({
          collection: 'statistics',
          doc: 'employees',
          subcollections: [
            {
              collection: member,
              doc: 'statistics',
              subcollections: [
                {
                  collection: entryTypeCode,
                  doc: periodType
                }
              ]
            }
          ],
          storeAs: `teamStats-${entryTypeCode}-${periodType}-${team}-${member}`
        });
        const mistakesQuery = (team, member, periodType, entryTypeCode) => ({
          collection: 'statistics',
          doc: 'employees',
          subcollections: [
            {
              collection: member,
              doc: 'statistics',
              subcollections: [
                {
                  collection: 'mistakes',
                  doc: entryTypeCode,
                  subcollections: [
                    {
                      collection: 'statistics',
                      doc: periodType
                    }
                  ]
                }
              ]
            }
          ],
          storeAs: `teamStats-${entryTypeCode}-${periodType}-${team}-${member}`
        });

        entryTypes.forEach(type => {
          team.members.forEach(member => {
            if (props.mistakes) {
              queries.push(
                mistakesQuery(props.teamDetails.id, member, 'daily', type.code),
                mistakesQuery(
                  props.teamDetails.id,
                  member,
                  'monthly',
                  type.code
                )
              );
            } else {
              queries.push(
                entriesQuery(props.teamDetails.id, member, 'daily', type.code),
                entriesQuery(props.teamDetails.id, member, 'monthly', type.code)
              );
            }
          });
        });
      }
    } else if (props.personalManagement) {
      const entriesQuery = (periodType, entryTypeCode) => ({
        collection: 'statistics',
        doc: 'employees',
        subcollections: [
          {
            collection: props.personDetails.uid,
            doc: 'statistics',
            subcollections: [
              {
                collection: entryTypeCode,
                doc: periodType
              }
            ]
          }
        ],
        storeAs: `personalStats-${entryTypeCode}-${periodType}-${props.personDetails.uid}`
      });
      const mistakesQuery = (periodType, entryTypeCode) => ({
        collection: 'statistics',
        doc: 'employees',
        subcollections: [
          {
            collection: props.personDetails.uid,
            doc: 'statistics',
            subcollections: [
              {
                collection: 'mistakes',
                doc: entryTypeCode,
                subcollections: [
                  {
                    collection: 'statistics',
                    doc: periodType
                  }
                ]
              }
            ]
          }
        ],
        storeAs: `mistakeStats-${entryTypeCode}${capitalizeFirstLetter(
          periodType
        )}`
      });

      entryTypes.forEach(type => {
        if (props.mistakes) {
          queries.push(
            mistakesQuery('daily', type.code),
            mistakesQuery('monthly', type.code)
          );
        } else {
          queries.push(
            entriesQuery('daily', type.code),
            entriesQuery('monthly', type.code)
          );
        }
      });
    } else {
      const entriesQuery = (periodType, entryTypeCode) => ({
        collection: 'statistics',
        doc: 'overall',
        subcollections: [
          {
            collection: entryTypeCode,
            doc: periodType
          }
        ],
        storeAs: `overallStats-${entryTypeCode}${capitalizeFirstLetter(
          periodType
        )}`
      });
      const mistakesQuery = (periodType, entryTypeCode) => ({
        collection: 'statistics',
        doc: 'overall',
        subcollections: [
          {
            collection: 'mistakes',
            doc: entryTypeCode,
            subcollections: [{ collection: 'statistics', doc: periodType }]
          }
        ],
        storeAs: `mistakeStats-${entryTypeCode}${capitalizeFirstLetter(
          periodType
        )}`
      });

      entryTypes.forEach(type => {
        if (props.mistakes) {
          queries.push(
            mistakesQuery('daily', type.code),
            mistakesQuery('monthly', type.code)
          );
        } else {
          queries.push(
            entriesQuery('daily', type.code),
            entriesQuery('monthly', type.code)
          );
        }
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(QuickOverallStats);
