export const styles = theme => ({
  dialogBody: {
    maxWidth: '1000px',
    margin: '0 auto',
    marginBottom: '20px',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    width: '100%'
  },
  noReportDialogContent: {
    padding: 'auto',
    margin: 'auto',
    marginTop: '10px',
    height: '50px'
  },
  dialogContent: {
    height: '500px'
  },
  clientTypeSection: {
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    width: 20,
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  dialogTitle: {
    color: theme.palette.primary.main
  },
  stepperContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileStepperContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  mobileStepper: {
    maxWidth: 400,
    flexGrow: 1,
    backgroundColor: 'unset',
    alignItems: 'center',
    '& > div': {
      margin: '0px auto'
    }
  },
  sectionActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonContainer: {
    width: '270px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  officesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  type: {
    width: '50%',
    '& label': {
      marginLeft: 14
    }
  },
  dateRange: {
    width: '50%'
  },
  reviewSection: {
    marginBottom: 32
  },
  sectionBody: {
    marginTop: 16
  },
  sectionRow: {
    display: 'flex',
    flex: 1,
    height: '30px',
    alignItems: 'center',
    '& > p:first-child': {
      display: 'flex',
      flex: 2,
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    '& > p:last-child': {
      display: 'flex',
      flex: 10,
      [theme.breakpoints.down('xs')]: {
        flex: 4
      }
    }
  },
  edit: {
    fontSize: 12,
    color: 'red',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  officeList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '83.5%',
    height: '100%',
    position: 'relative'
  },
  dialogOfficeList: {
    height: 150,
    width: '47.75%',
    overflowY: 'scroll',
    overflow: 'hidden',
    border: 'solid #cbcbcb 1px',
    borderRadius: '8px',
    marginLeft: '48px'
  },
  officeNames: {
    width: '100%',
    color: '#212121',
    margin: 6,
    padding: 4,
    '& svg': { fontSize: 12, marginRight: 0 },
    [theme.breakpoints.down('sm')]: { width: '70%' }
  },
  officeRemovalIcon: {
    padding: 0,
    marginLeft: 10,
    fontSize: 14,
    '& svg': {
      fontSize: '20px !important',
      height: 20
    },
    '&:focus': {
      backgroundColor: '#ddd',
      height: 20
    }
  },
  officeSelect: {
    marginLeft: -3,
    width: '47.75%'
  },
  regionSelect: {
    marginLeft: '50px',
    maxWidth: '95%'
  },
  allOffices: {
    display: 'flex',
    alignItems: 'center'
  },
  titleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    minHeight: theme.spacing.unit * 8,
    display: 'flex',
    padding: theme.spacing.unit * 2,
    paddingBottom: 2,
    paddingTop: 0
  },
  titleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  title: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit,
    width: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: { width: 250 }
  },
  subtitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 1.4,
    marginTop: -theme.spacing.unit
  }
});
