import React, { useContext } from 'react';
import helpers from '../../../helpers';
import Grid from '@material-ui/core/Grid';
import OfficeUsersTable from './OfficeUsersTable';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../contexts';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'typy';

const styles = theme => ({
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  officeUsers: {
    height: theme.containers.paper.height.default,
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  }
});

const Users = ({ classes, office, sitemapPath }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToManage = helpers.allowedTo('manage', sitemapPath, userGroups);

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.officeUsers} elevation={4}>
            <OfficeUsersTable
              office={office}
              allowedToManage={allowedToManage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(Users);
