import React from 'react';
import { Button, Typography } from '@material-ui/core';
import FieldSelect from '../../../../../components/FieldSelect';
import { reportTypesOptions as reportTypes } from '../../../../../services/options';
import FieldDate from '../../../../../components/FieldDate';
import PropTypes from 'prop-types';

const ReportType = ({
  name,
  Field,
  classes,
  nextPage,
  errors,
  values,
  validations
}) => {
  const valuesCheck = () => {
    if (!values.startDate || !values.endDate || !values.reportType) {
      return true;
    } else {
      return false;
    }
  };

  const errorsCheck = () => {
    if (errors.startDate || errors.endDate || errors.reportType) {
      return true;
    } else {
      return false;
    }
  };

  const renderDateFields = () => {
    return (
      <>
        <div className={classes.startDate}>
          <Field
            name='startDate'
            label='Start Date'
            variant='outlined'
            component={FieldDate}
            validate={validations.startDate}
          />
          <Field
            name='endDate'
            label='End Date'
            variant='outlined'
            component={FieldDate}
            validate={validations.endDate}
          />
        </div>
      </>
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Report Type</Typography>
      </div>
      <div className={classes.type}>
        <Field
          name={name}
          label='Report Type'
          variant='outlined'
          validate={validations.reportType}
        >
          {({ form, field }) => {
            return (
              <FieldSelect
                name={name}
                form={form}
                field={field}
                options={reportTypes}
                margin='none'
                optionValue='name'
                optionKey='key'
                optionText='name'
                label='Select'
              />
            );
          }}
        </Field>
      </div>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Date Range</Typography>
      </div>
      <div className={classes.dateRange}>{renderDateFields()}</div>
      <div className={classes.sectionActions}>
        <div className={classes.buttonContainer}>
          <Button variant='outlined' color='secondary' type='reset'>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='outlined'
            onClick={nextPage}
            disabled={valuesCheck() || errorsCheck()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

ReportType.propTypes = {
  validations: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired
};

export default ReportType;
