import {
  DIALOG_EDIT_TEAM_SERVICE_CLICK,
  DIALOG_EDIT_TEAM_SERVICE_ITEM
} from './types';

export const dialogEditTeamServiceClick = status => ({
  type: DIALOG_EDIT_TEAM_SERVICE_CLICK,
  payload: status
});

export const dialogEditTeamServiceItem = item => ({
  type: DIALOG_EDIT_TEAM_SERVICE_ITEM,
  payload: item
});
