import _ from 'lodash';
import React, { Component } from 'react';
import AscIcon from '@material-ui/icons/ArrowDownward';
import ChangeIcon from '@material-ui/icons/SwapVert';
import DescIcon from '@material-ui/icons/ArrowUpward';
import EntryTypePopover from './EntryTypePopover';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TeamStatsTable from './TeamStatsTable';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { entryTypes } from '../services/options';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class QuickTeamStats extends Component {
  state = {
    anchorEl: null,
    openPopover: false,
    entryCode: 'scheduled',
    orderByValue: [`monthTotal`, `name`],
    orderByOrder: ['desc', 'asc']
  };

  handleEntryTypeClick = () => event => {
    const { currentTarget } = event;

    this.setState({
      anchorEl: currentTarget,
      openPopover: !this.state.openPopover
    });
  };

  handleClickEntry = code => {
    this.handleClose();
    this.setState({ entryCode: code });
  };

  handleClose() {
    this.setState({ openPopover: false });
  }

  getOrderArr(key, firstDefaultOrder, secondDefaultOrder) {
    const { orderByValue, orderByOrder } = this.state;

    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, secondDefaultOrder];
  }

  changeSort(key) {
    switch (key) {
      case 'name':
        this.setState({
          orderByValue: [`name`, `monthTotal`],
          orderByOrder: this.getOrderArr(key, 'asc', 'desc')
        });
        break;
      case 'dayTotal':
        this.setState({
          orderByValue: [`dayTotal`, `name`],
          orderByOrder: this.getOrderArr(key, 'desc', 'asc')
        });
        break;
      case 'monthTotal':
        this.setState({
          orderByValue: [`monthTotal`, `name`],
          orderByOrder: this.getOrderArr(key, 'desc', 'asc')
        });
        break;
      default:
        this.setState({
          orderByValue: [`monthTotal`, `name`],
          orderByOrder: ['desc', 'asc']
        });
    }
  }

  renderSortIcon(key) {
    const { classes } = this.props;
    const { orderByValue, orderByOrder } = this.state;

    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  }

  renderTeamStatsHeaderRow() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.headerRow}>
        <Grid
          item
          xs={3}
          style={{ padding: 0, paddingLeft: 12, justifyContent: 'flex-start' }}
          onClick={() => this.changeSort('name')}
        >
          <Typography variant='button'>Team</Typography>
          {this.renderSortIcon('name')}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ padding: 0 }}
          onClick={() => this.changeSort('dayTotal')}
        >
          <Typography variant='button'>Today</Typography>
          {this.renderSortIcon('dayTotal')}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: 0 }}
          onClick={() => this.changeSort('monthTotal')}
        >
          <Typography variant='button'>Month</Typography>
          {this.renderSortIcon('monthTotal')}
        </Grid>
        <Grid item xs={4} style={{ padding: 0 }}>
          <Typography variant='button'>Members</Typography>
        </Grid>
      </Grid>
    );
  }

  renderTeamStatsTable() {
    const { mistakes, teams } = this.props;
    const { orderByValue, orderByOrder, entryCode } = this.state;

    if (teams) {
      return (
        <TeamStatsTable
          mistakes={mistakes}
          teams={teams}
          collection={entryCode}
          orderByValue={orderByValue}
          orderByOrder={orderByOrder}
        />
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, openPopover, entryCode } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.entryTypeSwitcherContainer}>
          <Typography
            variant='button'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {_.mapKeys(entryTypes, 'code')[entryCode].icon(18, 5)}
            {_.mapKeys(entryTypes, 'code')[entryCode].text}
          </Typography>
          <Tooltip title='Select Entry Type'>
            <IconButton
              color='inherit'
              className={classes.entryTypeButton}
              aria-label='Select Entry Type'
              onClick={this.handleEntryTypeClick()}
            >
              <ChangeIcon style={styles.icon} />
            </IconButton>
          </Tooltip>
          <EntryTypePopover
            open={openPopover}
            anchorEl={anchorEl}
            handleClose={() => this.handleClose()}
            handleClickEntry={this.handleClickEntry.bind(this)}
            teams={true}
          />
        </div>
        {this.renderTeamStatsHeaderRow()}
        {this.renderTeamStatsTable()}
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 1.5
  },
  sortIcon: {
    marginRight: 3,
    marginBottom: 2,
    fontSize: 12
  },
  entryTypeSwitcherContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  icon: {
    color: theme.palette.secondary.main
  },
  titleContainer: {
    padding: theme.spacing.unit * 2
  },
  headerRow: {
    width: '95%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    }
  }
});

const mapStateToProps = (state, props) => {
  const teamData = state.firestore.ordered['teams'];
  let teams = [];

  if (isLoaded(teamData) && !isEmpty(teamData)) {
    teams = teamData;
  }

  return { teams };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'teams',
        storeAs: 'teams'
      }
    ];
  }),
  withStyles(styles, { withTheme: true })
)(QuickTeamStats);
