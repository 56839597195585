import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const DataRow = ({ classes, label, loading, content }) => {
  const loadElem = <div className={classes.dataLoading} />;

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>{loading ? loadElem : label}</div>
      <div className={classes.dataContent}>{loading ? loadElem : content}</div>
    </div>
  );
};

const styles = theme => ({
  dataRow: theme.containers.paper.variants.keyValueData.dataRow,
  dataLabel: theme.containers.paper.variants.keyValueData.dataLabel,
  dataContent: theme.containers.paper.variants.keyValueData.dataContent,
  dataLoading: theme.containers.paper.variants.keyValueData.dataLoading
});

DataRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loading: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default withStyles(styles, { withTheme: true })(DataRow);
