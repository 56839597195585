/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
// import { useField } from 'formik';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 12
  }
});

const GroupsFieldCheckbox = ({
  classes,
  arrayHelpers,
  groupKey,
  disabled,
  label,
  groupsHash,
  ...props
}) => {
  // const [field, helpers] = useField(props);

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            checked={groupsHash[groupKey].checked}
            disabled={disabled}
            onChange={event => {
              const { checked } = event.target;
              const { index } = groupsHash[groupKey];

              if (checked) {
                arrayHelpers.push(groupKey);
              } else {
                arrayHelpers.remove(index);
              }
            }}
          />
        }
        label={<Typography variant='body1'>{label}</Typography>}
      />
    </div>
  );
};

GroupsFieldCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(GroupsFieldCheckbox);
