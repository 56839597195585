export const CALLFORCE_TZ = 'America/Denver';
export const CLOUD_FUNC_BASE_URL =
  process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL;
export const CONTRACTOR_COMMISSION_RATE = (1 / 3);
export const RECALL_BASE_COMMISSION_RATE = 2;
export const RECALL_COMMSSIONS_TIERS = [
  {
    current: true,
    tiers: [
      {
        min: 0,
        max: 99,
        commission: 0
      },
      {
        min: 100,
        max: 149,
        commission: 2
      },
      {
        min: 150,
        max: 199,
        commission: 2.5
      },
      {
        min: 200,
        max: 249,
        commission: 3
      },
      {
        min: 250,
        max: null,
        commission: 3.5
      }
    ]
  }
];
