import _ from 'lodash';
import React, { Component } from 'react';
import AscIcon from '@material-ui/icons/ArrowUpward';
import DescIcon from '@material-ui/icons/ArrowDownward';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

class DataTableTitleRow extends Component {
  renderSortIcon = (column, whereKeyMatches) => {
    const { classes, orderBy, searchActive } = this.props;

    if (orderBy[0] === column.key && !searchActive && !whereKeyMatches) {
      if (orderBy[1] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  };

  renderColumns = () => {
    const { classes, columns, onSortClick, searchActive, where } = this.props;

    return _.orderBy(columns, 'index', 'asc').map((column, idx) => {
      const whereKeyMatches = where instanceof Array && where[0] === column.key;

      return (
        <Grid
          item
          {...{
            key: idx,
            className: `${classes.rowItem} ${
              !column.essential
                ? classes.nonEssentialColumn
                : idx === 0
                ? classes.firstColumn
                : null
            }`,
            ...(column.spacing.xs && { xs: column.spacing.xs }),
            ...(column.spacing.sm && { sm: column.spacing.sm }),
            ...(column.spacing.md && { md: column.spacing.md }),
            ...(column.spacing.lg && { lg: column.spacing.lg }),
            ...(column.spacing.xl && { xl: column.spacing.xl }),
            ...(column.sortable && {
              onClick: () =>
                !searchActive && !whereKeyMatches && onSortClick(column.key)
            })
          }}
        >
          <Typography
            variant='button'
            className={`
              ${classes.titleRowText}
              ${
                column.sortable && !searchActive && !whereKeyMatches
                  ? classes.sortableColumn
                  : null
              }
            `}
          >
            {column.text}
          </Typography>
          {this.renderSortIcon(column, whereKeyMatches)}
        </Grid>
      );
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.root}>
        {this.renderColumns()}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    height: 24,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    }
  },
  titleRowText: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  sortableColumn: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rowItem: {
    padding: '0px !important'
  },
  firstColumn: {
    padding: '0px !important',
    paddingLeft: '34px !important',
    justifyContent: 'flex-start !important'
  },
  nonEssentialColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  },
  sortIcon: {
    marginLeft: 2,
    marginBottom: 0,
    fontSize: 11
  }
});

export default withStyles(styles, { withTheme: true })(DataTableTitleRow);
