import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EditButton from './EditButton';
import {
  dialogEditOfficeInfoItem,
  dialogEditOfficeInfoClick
} from '../../../../../../../../../../../actions';
import { formatPhoneNumber } from '../../../../../../../../../../../services/helpers';
import { styles } from './styles';

const RegionInfoData = ({
  classes,
  data,
  dialogEditOfficeInfoItem,
  dialogEditOfficeInfoClick,
  label,
  name,
  showEdit
}) => {
  const onClick = () => {
    dialogEditOfficeInfoItem({ key: name, label, data });
    dialogEditOfficeInfoClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        {name === 'phone' ? formatPhoneNumber(data[name]) : data[name]}
      </div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditOfficeInfoItem, dialogEditOfficeInfoClick }),
  withStyles(styles, { withTheme: true })
)(RegionInfoData);
