import moment from 'moment-timezone';
import iconNames from '../icon_names';
import { CALLFORCE_TZ } from '../../../../services/constants';

const recallColumns = [
  {
    index: 1,
    key: 'patientName',
    essential: true,
    text: 'Patient Name',
    spacing: { xs: 6, sm: 3 },
    formatData: doc => {
      let { patientName } = doc;
      if (doc.actionItem) {
        patientName += iconNames.actionIconName;
      }
      if (doc.urgent) {
        patientName += iconNames.urgentIconName;
      }
      if (doc.mistake) {
        patientName += iconNames.mistakeIconName;
      }
      if (doc.doNotBill) {
        patientName += iconNames.noBillIconName;
      }
      if (doc.emergency) {
        patientName += iconNames.emergencyIconName;
      }
      return patientName;
    },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'officeName',
    essential: true,
    text: 'Office',
    spacing: { xs: 6, sm: 3 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 3,
    key: 'createdTime',
    essential: false,
    text: 'Created On',
    spacing: { xs: 3, sm: 3 },
    formatData: doc => {
      const createdTime = doc.createdTime._seconds
        ? doc.createdTime._seconds
        : doc.createdTime.seconds;

      return `
            ${moment
              .unix(createdTime)
              .tz(CALLFORCE_TZ)
              .format('MMM D, YYYY, h:mm a')}
          `;
    },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'desc'
  },
  {
    index: 4,
    key: 'createdByName',
    essential: false,
    text: 'Created By',
    spacing: { xs: 3, sm: 3 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  }
];

export default recallColumns;
