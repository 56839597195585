import {
  DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK,
  DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM
} from '../actions/types';

export const dialogEditOfficeFive9DataItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeFive9DataOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeFive9DataReducer = {
  dialogEditOfficeFive9DataItem,
  dialogEditOfficeFive9DataOpen
};
