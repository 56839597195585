import {
  DIALOG_EDIT_PAYMENT_INFO_CLICK,
  DIALOG_EDIT_PAYMENT_INFO_ITEM
} from '../actions/types';

export const dialogEditPaymentInfoItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_PAYMENT_INFO_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditPaymentInfoOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_PAYMENT_INFO_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditPaymentInfoReducer = {
  dialogEditPaymentInfoItem,
  dialogEditPaymentInfoOpen
};
