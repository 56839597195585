import {
  DIALOG_EDIT_OFFICE_OFFERINGS_CLICK,
  DIALOG_EDIT_OFFICE_OFFERINGS_ITEM
} from '../actions/types';

export const dialogEditOfficeOfferingsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_OFFERINGS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeOfferingsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_OFFERINGS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeOfferingsReducer = {
  dialogEditOfficeOfferingsItem,
  dialogEditOfficeOfferingsOpen
};
