import {
  AUTH_IS_ADMIN,
  AUTH_IS_CALLER,
  AUTH_IS_CALLER_SCHEDULED,
  AUTH_IS_CALLER_CALLS_ANSWERED,
  AUTH_IS_CALLER_RESCHEDULED,
  AUTH_IS_CALLING_MANAGER,
  AUTH_IS_CUSTOMER_MANAGER,
  AUTH_IS_FINANCIAL_CONTROLLER,
  AUTH_IS_ONBOARDER,
  AUTH_IS_TEAM_LEAD,
  AUTH_IS_TECHNICAL_SUPPORT
} from '../actions/types';

const isAdmin = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_ADMIN:
      return action.payload;
    default:
      return state;
  }
};

const isCaller = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_CALLER:
      return action.payload;
    default:
      return state;
  }
};

const isCallerScheduled = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_CALLER_SCHEDULED:
      return action.payload;
    default:
      return state;
  }
};

const isCallerCallsAnswered = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_CALLER_CALLS_ANSWERED:
      return action.payload;
    default:
      return state;
  }
};

const isCallerRescheduled = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_CALLER_RESCHEDULED:
      return action.payload;
    default:
      return state;
  }
};

const isCallingManager = (state = false, action) => {
  switch (action.type) {
    case AUTH_IS_CALLING_MANAGER:
      return action.payload;
    default:
      return state;
  }
};

const isCustomerManager = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_CUSTOMER_MANAGER:
      return action.payload;
    default:
      return state;
  }
};

const isFinancialController = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_FINANCIAL_CONTROLLER:
      return action.payload;
    default:
      return state;
  }
};

const isOnboarder = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_ONBOARDER:
      return action.payload;
    default:
      return state;
  }
};

const isTeamLead = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_TEAM_LEAD:
      return action.payload;
    default:
      return state;
  }
};

const isTechnicalSupport = (state = null, action) => {
  switch (action.type) {
    case AUTH_IS_TECHNICAL_SUPPORT:
      return action.payload;
    default:
      return state;
  }
};

export const AuthReducer = {
  isAdmin,
  isCaller,
  isCallerScheduled,
  isCallerCallsAnswered,
  isCallerRescheduled,
  isCallingManager,
  isCustomerManager,
  isFinancialController,
  isOnboarder,
  isTeamLead,
  isTechnicalSupport
};
