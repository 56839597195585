/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { getRecording } from '../actions/FetchRecordings';

const TableRecording = props => {
  const { callId, createdTime, firestore } = props;
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audio, setAudio] = useState(null);
  const [noRecording, setNoRecording] = useState(false);

  const manageRecording = () => {
    if (playing) {
      audio.pause();
    } else {
      audio.play();
    }
    setPlaying(!playing);
  };

  const rewind = () => {
    audio.currentTime -= 5;
  };

  const fastForward = () => {
    audio.currentTime += 5;
  };

  const renderFastForwardRewindButtin = direction => {
    return playing ? (
      <Tooltip title='Rewind (5s)'>
        <IconButton
          color='inherit'
          disabled={!playing}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            direction === 'rewind' ? rewind() : fastForward();
          }}
          aria-label='Rewind (5s)'
        >
          {direction === 'rewind' ? <FastRewind /> : <FastForward />}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        color='inherit'
        disabled={!playing}
        onClick={() => {
          rewind();
        }}
        aria-label='Rewind (5s)'
      >
        {direction === 'rewind' ? <FastRewind /> : <FastForward />}
      </IconButton>
    );
  };

  useEffect(() => {
    if (audio) {
      audio.pause();
      setPlaying(false);
      setAudio(null);
      setNoRecording(false);
    }
    setLoading(true);

    if (createdTime) {
      getRecording(firestore, callId, setLoading, setAudio, setNoRecording);
    }

    // eslint-disable-next-line
  }, [callId, createdTime]);
  return (
    <span>
      {renderFastForwardRewindButtin('rewind')}
      <Tooltip title='Play Recording'>
        <IconButton
          id='playButton'
          color='inherit'
          disabled={noRecording}
          onClick={() => {
            manageRecording();
          }}
          aria-label='Play Recording'
        >
          {loading ? (
            <Loader type='Oval' color='#696969' height='20' width='20' />
          ) : playing ? (
            <PauseIcon />
          ) : (
            <PlayIcon />
          )}
        </IconButton>
      </Tooltip>
      {renderFastForwardRewindButtin('forward')}
    </span>
  );
};

export default TableRecording;
