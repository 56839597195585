import queryToCollection from './queryToCollection';
import getAllEmployees from './getAllEmployees';

const getPaginatedData = (
  collection,
  orderBy,
  limit,
  startAfter,
  pageForward,
  where,
  firestore,
  mistake
) =>
  new Promise(async (resolve, reject) => {
    try {
      let query;
      let allEmployees;
      const baseQuery = where
        ? { collection, where, orderBy, limit }
        : { collection, orderBy, limit };
      if (startAfter) {
        const startAfterDoc = await firestore.get({
          collection,
          doc: startAfter
        });
        query = { ...baseQuery, startAfter: startAfterDoc };
      } else {
        query = baseQuery;
      }

      if (query.where && query.where[0] instanceof Array) {
        query.orderBy = [[query.where[0][0], 'asc'], query.orderBy];
      }

      if (
        query.where &&
        query.where[1] &&
        query.where[1] === '==' &&
        query.where[0] === orderBy[0]
      ) {
        delete query.orderBy;
        delete query.startAfter;
      }

      if (
        query.orderBy[0] instanceof Array &&
        query.orderBy[1] instanceof Array &&
        query.orderBy[0][0] === query.orderBy[1][0]
      ) {
        query = { ...query, orderBy: query.orderBy[1] };
      }

      if (mistake) {
        if (query.where && query.where[0][0] === 'mistake.reportedOn') {
          query.where[0][2] = new Date(query.where[0][2]);
          query.where[1][2] = new Date(query.where[1][2]);
          query.orderBy = [
            ['mistake.reportedOn', 'desc'],
            ['createdBy', 'asc']
          ];
        } else {
          query.orderBy = [['mistake.reportedOn', 'desc']];
        }
      }
      const querySnapshot = await firestore.get(query);
      let docs = queryToCollection(querySnapshot);

      if (query.collection === 'teams') {
        allEmployees = await getAllEmployees(firestore);
        docs = docs.map(document => {
          allEmployees.forEach(employee => {
            if (employee.id === document.lead) {
              const leadName = employee.active
                ? `${employee.firstName} ${employee.lastName}`
                : `${employee.firstName} ${employee.lastName} (Inactive)`;

              document.leadName = {
                leadName,
                lead: document.lead
              };
            }
          });

          return document;
        });
      }

      return resolve({
        docs,
        firstVisible:
          docs.length > 0
            ? pageForward
              ? querySnapshot.docs[0].id
              : querySnapshot.docs[querySnapshot.docs.length - 1].id
            : null,
        lastVisible:
          docs.length > 0
            ? pageForward
              ? querySnapshot.docs[querySnapshot.docs.length - 1].id
              : querySnapshot.docs[0].id
            : null
      });
    } catch (e) {
      return reject(e);
    }
  });

export default getPaginatedData;
