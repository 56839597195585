export const styles = theme => ({
  root: {
    minWidth: 600,
    paddingTop: 16,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  header: {
    paddingBottom: 24,
    textAlign: 'center'
  },
  clientName: {
    fontSize: 18
  },
  dateRange: {
    fontSize: 14
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  statusText: {
    fontSize: 12,
    fontStyle: 'italic',
    marginRight: 3
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  body: {
    marginTop: 24,
    marginBottom: 24
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
    fontSize: 12,
    fontStyle: 'italic'
  }
});
