import React, { Component } from 'react';
import _ from 'lodash';
import qs from 'qs';
import Loader from 'react-loader-spinner';
import SelectField from '../../../../../../../components/SelectField';
import countryState from '../../../../../../../services/options/geoData';
import getZipTzPromise from '../../../../../../../services/timezone';
import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';
import TextField from '../../../../../../../components/TextField';
import { validations } from '../../../../../../../services/validation';

class OfficeInformation extends Component {
  state = { errorFetchingTz: false, fetchingTz: false };

  componentDidMount() {
    const { change, formSectionValues, formData } = this.props;

    Object.keys(formSectionValues).map(key =>
      key === 'country' && !formSectionValues[key]
        ? change(key, 'US')
        : change(key, t(formData, key).safeObject)
    );
  }

  componentDidUpdate(prevProps) {
    const { formSectionValues } = this.props;

    if (!_.isEqual(formSectionValues.zip, prevProps.formSectionValues.zip)) {
      this.setState({ errorFetchingTz: false });
    }
  }

  onSubmit = async values => {
    const { onSubmit } = this.props;

    try {
      this.setState({ fetchingTz: true });
      let timezone = await getZipTzPromise(values.zip);
      values.timezone = timezone;
      return onSubmit(values);
    } catch (e) {
      console.log(e.toString());
      this.setState({ errorFetchingTz: true });
    }
  };

  render() {
    const { classes, formSectionValues, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(() => this.onSubmit(formSectionValues))}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Office Information</Typography>
        </div>
        <div className={classes.name}>
          <Field
            name='name'
            component={TextField}
            label='Office Name'
            className={classes.dialogTextField}
            validate={[validations.required]}
          />
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.phone}>
            <Field
              name='phone'
              component={TextField}
              label='Office Phone'
              className={classes.dialogTextField}
              shrink
              validate={[validations.required, validations.phone]}
            />
          </div>
          <div className={classes.doctor}>
            <Field
              name='doctor'
              component={TextField}
              label='Doctor'
              className={classes.dialogTextField}
              validate={[validations.required]}
            />
          </div>
        </div>
        <div className={classes.name}>
          <Field
            name='locationId'
            component={TextField}
            label='Location ID (Optional)'
            className={classes.dialogTextField}
          />
        </div>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Office Address</Typography>
        </div>
        <div className={classes.address}>
          <Field
            name='address'
            component={TextField}
            label='Address'
            className={classes.dialogTextField}
            validate={[validations.required]}
          />
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.city}>
            <Field
              name='city'
              component={TextField}
              label='City'
              className={classes.dialogTextField}
              validate={[validations.required]}
            />
          </div>
          <div className={classes.state}>
            <Field
              name='state'
              component={SelectField}
              selected={formSectionValues.state === null ? false : true}
              label={formSectionValues.country === 'CA' ? 'Province' : 'State'}
              type='text'
              options={
                formSectionValues.country &&
                _.sortBy(
                  _.mapKeys(countryState, 'abbreviation')[
                    formSectionValues.country
                  ].states,
                  'name'
                )
              }
              optionKey='abbreviation'
              optionText='name'
              optionValue='abbreviation'
              margin='none'
              textFieldContainerClass={classes.dialogTextField}
              validate={[validations.required]}
            />
          </div>
          <div className={classes.zip}>
            <Field
              name='zip'
              component={TextField}
              label={
                formSectionValues.country === 'CA' ? 'Postal Code' : 'Zip Code'
              }
              className={classes.dialogTextField}
              validate={
                formSectionValues.country === 'CA'
                  ? [validations.required, validations.zipCA]
                  : [validations.required, validations.zipUS]
              }
            />
          </div>
          <div className={classes.country}>
            <Field
              name='country'
              component={SelectField}
              selected={formSectionValues.state === null ? false : true}
              label='Country'
              type='text'
              options={_.sortBy(
                _.mapKeys(countryState, 'abbreviation'),
                'name'
              )}
              optionKey='abbreviation'
              optionText='abbreviation'
              optionValue='abbreviation'
              margin='none'
              textFieldContainerClass={classes.dialogTextField}
              validate={[validations.required]}
            />
          </div>
        </div>
        <div className={classes.sectionActions}>
          {this.state.fetchingTz ? (
            <div className={classes.loadingContainer}>
              <Loader type='Oval' color='#696969' height='24' width='24' />
              <Typography className={classes.loadingMessage} variant='body1'>
                Please Keep The Browser Window Open
              </Typography>
            </div>
          ) : (
            <div />
          )}
          <Button
            color='primary'
            type='submit'
            disabled={this.state.fetchingTz}
          >
            Next
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  const selector = formValueSelector('DialogAddOfficeForm');
  let timezone;

  return {
    formData,
    formSectionValues: {
      name: selector(state, 'name'),
      phone: selector(state, 'phone'),
      doctor: selector(state, 'doctor'),
      locationId: selector(state, 'locationId'),
      status: 'onboarding',
      address: selector(state, 'address'),
      city: selector(state, 'city'),
      state: selector(state, 'state'),
      zip: selector(state, 'zip'),
      country: selector(state, 'country'),
      timezone
    }
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(),
  reduxForm({
    form: 'DialogAddOfficeForm',
    asyncValidate: validations.officeName,
    asyncBlurFields: ['name'],
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(OfficeInformation);
