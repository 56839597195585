import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { t } from 'typy';
import { AppContext } from '../../../../contexts';
import queryString from 'query-string';
import HeaderTitleWithTabs from '../../../../components/HeaderTitleWithTabs';
import ReloName from './components/ReloName';
import ReloScenes from './components';

const RELO = props => {
  const {
    location,
    match: { params }
  } = props;
  const [relo, setRelo] = useState(null);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const searchParams = queryString.parse(location.search);
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const getRelo = firebase
      .firestore()
      .collection('relos')
      .doc(params.reloId)
      .onSnapshot(doc => {
        const relo = { ...doc.data(), id: doc.id };
        setRelo(relo);
      });

    return () => {
      getRelo();
    };
  }, [firebase, setRelo, params]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const ReloScene = ReloScenes[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray;
      const readGroups = t(route, 'metadata.permissions.read').safeArray;
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: `/data/relos/${params.reloId}/$tabName?pathname=${searchParams.pathname}`,
        renderComponent: () => (
          <ReloScene
            relo={relo}
            sitemapPath={props.sitemapPath}
            profile={profile}
            route={route}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      largeAppBar
      params={params}
      renderTitle={() => (
        <ReloName reloId={params.reloId} pathname={searchParams.pathname} />
      )}
      tabId={params.tabId}
      tabs={tabs}
    />
  );
};

RELO.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sitemapPath: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(RELO);
