import React, { Component } from 'react';
import CountUp from 'react-countup';
import moment from 'moment-timezone';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  CONTRACTOR_COMMISSION_RATE,
  RECALL_BASE_COMMISSION_RATE
} from '../services/constants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';

class PersonalCommissionsDetail extends Component {
  state = {};

  render() {
    const {
      classes,
      currentCommission,
      employeeRecallMonthTotal,
      commissionRate
    } = this.props;

    return (
      <Paper className={classes.root}>
        <div className={classes.headerContainer}>
          <Typography
            variant='button'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <MoneyIcon />
            Commission
          </Typography>
        </div>
        <div className={classes.totalContainer}>
          <Typography variant='h4'>
            $
            <CountUp
              duration={1}
              end={currentCommission}
              separator=','
              decimals={2}
            />
          </Typography>
          <Typography variant='button' style={{ fontSize: 12 }}>
            {employeeRecallMonthTotal}{' '}
            {employeeRecallMonthTotal === 1 ? 'appt' : 'appts'} This Month
            &#8226; ${commissionRate.toFixed(2)} per appt
          </Typography>
        </div>
        {/* {countToNextTier > 0 ? (
          <div className={classes.subtextContainer}>
            <Typography
              variant='button'
              style={{ marginTop: 80, fontSize: 12 }}
            >
              {countToNextTier} more {countToNextTier === 1 ? 'appt' : 'appts'}{' '}
              for
            </Typography>
            <Typography variant='button' style={{ fontSize: 12 }}>
              ${commissionAtNextTier.toFixed(2)} per appt &#8226; $
              {(
                commissionAtNextTier *
                (employeeRecallMonthTotal + countToNextTier)
              ).toFixed(2)}{' '}
              cmsn
            </Typography>
          </div>
        ) : null} */}
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 50
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingTop: 16,
    paddingRight: 8
  },
  totalContainer: {
    marginTop: 110,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  subtextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

const mapStateToProps = (state, props) => {
  const employeeRecallStatsData =
    state.firestore.ordered['employeeRecallStats'];
  const currentMonth = moment().format('YYYY-MM');
  let employeeRecallMonthTotal = null;
  const commissionRate = props?.memberContractor
    ? RECALL_BASE_COMMISSION_RATE * CONTRACTOR_COMMISSION_RATE
    : RECALL_BASE_COMMISSION_RATE;

  if (isLoaded(employeeRecallStatsData) && !isEmpty(employeeRecallStatsData)) {
    employeeRecallMonthTotal = t(
      employeeRecallStatsData[0],
      `[${currentMonth}]`
    ).safeObject
      ? employeeRecallStatsData[0][currentMonth]
      : 0;
  }

  const currentCommission = commissionRate * employeeRecallMonthTotal;

  return {
    employeeRecallMonthTotal,
    currentCommission,
    commissionRate
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    let employeeUid = props.employeeUid;
    let queries = [];

    queries.push({
      collection: 'statistics',
      doc: 'employees',
      subcollections: [
        {
          collection: employeeUid,
          doc: 'statistics',
          subcollections: [{ collection: 'scheduled', doc: 'monthly' }]
        }
      ],
      storeAs: 'employeeRecallStats'
    });

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(PersonalCommissionsDetail);
