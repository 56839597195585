import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';
import logo from '../../assets/ReachLogo.png';
import Lottie from 'react-lottie';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import theme from '../../services/theme';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ERRORS } from '../../services/errors';
import { authLogout } from '../../actions';
import { slideUpTransitionOptions } from '../../services/transitions';
import { styles } from './styles';
import { withLastLocation } from 'react-router-last-location';
import { withStyles } from '@material-ui/core/styles';

class Error extends Component {
  state = { loggingOut: false };

  handleLogout() {
    const { history, authLogout } = this.props;

    this.setState({ loggingOut: true });
    authLogout();
    setTimeout(() => history.push('/login'), 1000);
  }

  renderActionButtons() {
    const { classes, history, lastLocation, profile } = this.props;

    return (
      <div className={classes.actionButtonsContainer}>
        {lastLocation ? (
          <Button variant='outlined' color='secondary' onClick={history.goBack}>
            Go Back
          </Button>
        ) : (
          <span></span>
        )}
        {profile ? (
          <Button
            variant='outlined'
            color='secondary'
            onClick={this.handleLogout.bind(this)}
          >
            {this.state.loggingOut ? (
              <Loader
                type='Oval'
                color={theme.palette.secondary.main}
                height='13'
                width='13'
              />
            ) : (
              'Logout'
            )}
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    const { animationFile, classes, errorMessage } = this.props;
    const options = {
      loop: true,
      autoplay: true,
      animationData: require(`./${animationFile}`),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className={`Error ${classes.root}`}>
        <div className={classes.cardContainer}>
          {this.renderActionButtons()}
          <div className={classes.card}>
            <div className={classes.content}>
              <div className={classes.header}>
                <img src={logo} alt='Logo' className={classes.logo} />
              </div>
              <div>
                <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
                  <Typography variant='body1'>{errorMessage}</Typography>
                  <Lottie options={options} height={80} width={80} />
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const profile = state.firebase.profile;
  const errorCode = ownProps.match.params.errorCode
    ? ownProps.match.params.errorCode
    : '404';

  return {
    animationFile: ERRORS[errorCode].animationFile,
    errorMessage: ERRORS[errorCode].error,
    profile
  };
};

export default compose(
  connect(mapStateToProps, { authLogout }),
  withStyles(styles)
)(withLastLocation(Error));
