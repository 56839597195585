import React, { useState, useContext } from 'react';
import { Close, Remove } from '@material-ui/icons';
import DialogTransition from '../../../../../components/DialogTransition';
import {
  Dialog,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  Button,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Formik, Form, FieldArray } from 'formik';
import OfficeFilteredSelect from '../../../../../components/OfficeFilteredSelect';
import { AppContext } from '../../../../../contexts';
import { styles } from './styles';

const DialogAddTeamOffices = ({
  classes,
  fullScreen,
  open,
  setOpen,
  team,
  setOfficesRetrieved
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [validateError, setValidateError] = useState(false);
  const [loading, setLoading] = useState(null);

  const onSubmit = async values => {
    setLoading('Updating Offices, Please Keep Browswer Open');
    const updateOfficeTeams = async office => {
      let newOfferingTeams;

      if (office.teams && office.teams[team.serviceOffering[0]]) {
        newOfferingTeams = office.teams[team.serviceOffering[0]];
        newOfferingTeams.push(team.id);
      } else {
        newOfferingTeams = [team.id];
      }

      if (team.totalOffices) {
        team.totalOffices++;
      } else {
        team.totalOffices = 1;
      }
      const newTeams = {
        ...office.teams,
        [team.serviceOffering[0]]: newOfferingTeams
      };

      try {
        const clientRef = firebase
          .firestore()
          .collection('clients')
          .doc(office.id);

        const teamRef = firebase.firestore().collection('teams').doc(team.id);

        await clientRef.update({ teams: newTeams });
        await teamRef.update({ totalOffices: team.totalOffices });
      } catch (e) {
        console.error(e);
        setError('Unable to update one or more offices');
      }
    };

    const promises = [];
    values.newOffices.forEach(o => promises.push(updateOfficeTeams(o)));
    try {
      await Promise.all(promises);
    } catch (e) {
      console.error(e);
    }
    onClose();
  };

  const onClose = () => {
    setOpen(false);
    setLoading(null);
    setOfficesRetrieved(false);
  };

  const validate = newOffices => {
    const officeIds = [];
    newOffices.map(o => {
      if (
        o.teams &&
        o.teams[team.serviceOffering[0]] &&
        o.teams[team.serviceOffering[0]].includes(team.id)
      ) {
        setValidateError(
          `${team.name} is already assigned to ${o.officeInformation.name}`
        );
        newOffices.pop();
      } else {
        officeIds.push(o.id);
      }
      if (officeIds.slice(0, -1).includes(o.id)) {
        setValidateError(
          `${o.officeInformation.name} is already set to be assigned to ${team.name}`
        );
        newOffices.pop();
      }
      return validateError;
    });
  };

  const displayOffices = (newOffices, arrayHelpers) => {
    return newOffices.map((o, i) =>
      o.officeInformation.name ? (
        <div key={i} className={classes.officeName}>
          {o.officeInformation.name}
          <IconButton
            color='inherit'
            aria-label={`Remove ${o.officeInformation.name}`}
            className={classes.officeRemovalIcon}
            onClick={i => arrayHelpers.remove(i)}
            component='span'
            disableRipple
          >
            <Remove />
          </IconButton>
        </div>
      ) : null
    );
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }
    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Add Office Teams
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{ newOffices: [] }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue, initialValues }) => (
            <Form className={classes.form}>
              <FieldArray
                name='newOffices'
                label='Select Offices'
                render={arrayHelpers => (
                  <>
                    <OfficeFilteredSelect
                      label='Office Selection'
                      error={validateError}
                      offering={team.serviceOffering[0]}
                      onChange={office => {
                        setValidateError(false);
                        if (!office) return setValidateError(false);
                        const { newOffices } = values;
                        newOffices.push(office);
                        validate(newOffices);
                        setFieldValue('newOffices', newOffices);
                      }}
                      previouslySelectedTeamId={null}
                    />
                    <div className={classes.dialogOfficesList}>
                      {displayOffices(values.newOffices, arrayHelpers)}
                    </div>
                  </>
                )}
              />
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DialogAddTeamOffices.propTypes = {
  classes: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(DialogAddTeamOffices);
