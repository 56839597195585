import { offeringsObject as serviceOfferings } from '../../../../services/options';

export const teamColumns = [
  {
    index: 1,
    key: 'name',
    essential: true,
    text: 'Name',
    spacing: { xs: null, sm: 3 },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'lead',
    essential: false,
    text: 'Lead',
    spacing: { xs: null, sm: 2 },
    formatData: data => {
      const {
        leadName: { leadName }
      } = data;
      return leadName;
    },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 3,
    key: 'serviceOffering',
    essential: false,
    text: 'Service Offering',
    spacing: { xs: null, sm: 2 },
    formatData: doc => {
      let { serviceOffering } = doc;
      const formattedServices = serviceOffering.map(service =>
        serviceOfferings[service]
          ? (service = serviceOfferings[service])
          : service
      );
      return formattedServices.join(', ');
    },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'members',
    valueAsLengthOfArray: true,
    essential: false,
    text: 'Number of Members',
    spacing: { xs: null, sm: 2 },
    formatData: data => {
      return data.members.length;
    },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 5,
    key: 'totalOffices',
    essential: false,
    text: 'Offices',
    spacing: { xs: null, sm: 2 },
    icon: false,
    sortable: false
  }
];
