import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EditButton from './EditButton';
import countryState from '../../../services/options/geoData';
import {
  dialogEditOfficeAddressItem,
  dialogEditOfficeAddressClick
} from '../../../actions';
import { styles } from './styles';

const OfficeAddressData = ({
  classes,
  data,
  dialogEditOfficeAddressItem,
  dialogEditOfficeAddressClick,
  label,
  name,
  showEdit
}) => {
  const onClick = () => {
    dialogEditOfficeAddressItem({ key: name, label, data });
    dialogEditOfficeAddressClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        <div>{data.address}</div>
        <div>
          {data.city ? `${data.city},` : ''} {data.state} {data.zip}
        </div>
        <div>
          {data.country &&
            _.mapKeys(countryState, 'abbreviation')[data.country].name}
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditOfficeAddressItem, dialogEditOfficeAddressClick }),
  withStyles(styles, { withTheme: true })
)(OfficeAddressData);
