import {
  DIALOG_EDIT_PRICING_PLAN_CLICK,
  DIALOG_EDIT_PRICING_PLAN_ITEM
} from '../actions/types';

export const dialogEditPricingPlanItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_PRICING_PLAN_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditPricingPlanOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_PRICING_PLAN_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditPricingPlanReducer = {
  dialogEditPricingPlanItem,
  dialogEditPricingPlanOpen
};
