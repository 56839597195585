import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';

const DataTableFooter = ({
  classes,
  docs,
  page,
  rowsCount,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  tableData
}) => {
  const { isLoading, isReady } = tableData;

  return (
    <div className={classes.root}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={1000}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          disabled: !!(isLoading || page === 0)
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          disabled: !!(isLoading || (docs && docs.length < rowsPerPage))
        }}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          rowsCount && isReady
            ? `${from}-${rowsCount <= to ? rowsCount : to} of ${rowsCount}`
            : `${from}-${to}`
        }
      />
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    backgroundColor: '#F5F5F5',
    height: 64,
    position: 'absolute',
    bottom: -48,
    width: '100%',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: '0 1px 2px 1px rgba(0,0,0,0.13);',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    [theme.breakpoints.down('xs')]: {
      height: 56
    }
  }
});

export default withStyles(styles, { withTheme: true })(DataTableFooter);
