import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import PersonalCommissionsDetail from './PersonalCommissionsDetail';
import PersonalMistakesDetail from './PersonalMistakesDetail';
import QuickOverallStats from './QuickOverallStats';
import QuickIndiStats from './QuickIndiStats';
import QuickTeamStats from './QuickTeamStats';
import CallsAnsweredCommissions from './CallsAnsweredCommissions';

class EntriesManagement extends Component {
  renderFirstRowSecondColumn() {
    const {
      auth,
      mistakes,
      personalManagement,
      profile,
      teamDetails,
      teamManagement,
      classes,
      firebase
    } = this.props;
    const memberCallsAnswered = profile.groups.includes('callsAnswered');
    const memberContractor = profile.groups.includes('contractor');
    const memberScheduled = profile.groups.includes('scheduled');
    const memberNoCommissions = profile.groups.includes('noCommissions');

    if (personalManagement) {
      if (mistakes) {
        return (
          <Grid item xs={12} md={6}>
            <PersonalMistakesDetail />
          </Grid>
        );
      }
      if (memberScheduled && !memberNoCommissions) {
        return (
          <Grid item xs={12} md={6}>
            <PersonalCommissionsDetail
              employeeUid={auth.uid}
              memberContractor={memberContractor}
            />
          </Grid>
        );
      }
      if (memberCallsAnswered && !memberNoCommissions) {
        return (
          <Grid item xs={12} md={6}>
            <CallsAnsweredCommissions
              classes={classes}
              employeeUid={auth.uid}
              firebase={firebase}
              memberContractor={memberContractor}
            />
          </Grid>
        );
      }
    } else {
      return (
        <Grid item xs={12} md={5}>
          <QuickIndiStats
            mistakes={mistakes}
            teamManagement={teamManagement}
            teamDetails={teamManagement ? teamDetails : null}
          />
        </Grid>
      );
    }
  }

  render() {
    const {
      auth,
      classes,
      mistakes,
      personalManagement,
      teamManagement,
      teamDetails
    } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12} md={personalManagement ? 6 : 7}>
            <QuickOverallStats
              mistakes={mistakes}
              teamManagement={teamManagement}
              teamDetails={teamManagement ? teamDetails : null}
              personalManagement={personalManagement}
              personDetails={personalManagement ? auth : null}
            />
          </Grid>
          {this.renderFirstRowSecondColumn()}
        </Grid>

        {!teamManagement && !personalManagement ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <QuickTeamStats mistakes={mistakes} />
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    postition: 'relative'
  }
});

const mapStateToProps = (state, props) => {
  const orderedData = state.firestore.ordered;
  let teamDetails;

  if (props.teamManagement) {
    const team = orderedData.teamManagementTeamDetails;

    if (isLoaded(team) && !isEmpty(team)) {
      teamDetails = team[0];
    }
  }

  return { teamDetails };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const queries = [];

    if (props.teamManagement) {
      queries.push({
        collection: 'teams',
        where: ['lead', '==', props.auth.uid],
        storeAs: 'teamManagementTeamDetails'
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(EntriesManagement);
