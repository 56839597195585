import _ from 'lodash';
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import qs from 'qs';
import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { reduxForm } from 'redux-form';
import { t } from 'typy';
import { styles } from './styles';
import PricingPlanDetails from '../../../../../../../components/Billing/PricingPlanDetails';

class OfficeReview extends Component {
  renderOfficeInformation(page) {
    const { classes, formData, pageNum, updatePage } = this.props;

    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Office Information</Typography>
          <span onClick={() => updatePage(page - pageNum, formData)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Name:</Typography>
            <Typography variant='body1'>{formData.name}</Typography>
          </div>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Phone:</Typography>
            <Typography variant='body1'>{formData.phone}</Typography>
          </div>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Doctor:</Typography>
            <Typography variant='body1'>{formData.doctor}</Typography>
          </div>
          {formData.locationId ? (
            <div className={classes.sectionRow}>
              <Typography variant='body1'>Location ID:</Typography>
              <Typography variant='body1'>{formData.locationId}</Typography>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderOfferingSubscriptions(page) {
    const { classes, formData, offerings, pageNum, updatePage } = this.props;

    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Offering Subscriptions</Typography>
          <span onClick={() => updatePage(page - pageNum, formData)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          {Object.keys(formData.offeringsPricing).map((key, index) =>
            formData.offerings[key]
              ? offerings && (
                  <div
                    key={index}
                    className={classes.sectionRow}
                    style={{ alignItems: 'flex-start' }}
                  >
                    <Typography variant='body1'>
                      {_.mapKeys(offerings, 'id')[key].name}:
                    </Typography>
                    <div className={classes.pricingContainer}>
                      <Typography variant='body1'>
                        {_.startCase(formData.offeringsPricing[key])} Plan
                      </Typography>
                      <PricingPlanDetails
                        office={null}
                        pricingDetails={
                          _.mapKeys(offerings, 'id')[key].pricing[
                            formData.offeringsPricing[key]
                          ]
                        }
                      />
                    </div>
                  </div>
                )
              : null
          )}
        </div>
      </div>
    );
  }

  renderSetupFee(page) {
    const { classes, formData, pageNum, updatePage } = this.props;

    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Setup Fee</Typography>
          <span onClick={() => updatePage(page - pageNum, formData)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          <div className={classes.sectionRow}>
            <Typography variant='body1'>Amount:</Typography>
            <Typography variant='body1'>${formData.setupFeeAmount}</Typography>
          </div>
        </div>
      </div>
    );
  }

  renderSignUpCredit(page) {
    const { classes, formData, pageNum, updatePage } = this.props;

    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Sign-Up Credit</Typography>
          <span onClick={() => updatePage(page - pageNum, formData)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          {formData.signUpCredit.map((credit, index) => (
            <div
              key={index}
              className={classes.sectionRow}
              style={{ alignItems: 'flex-start', marginBottom: 8 }}
            >
              <Typography variant='body1'>Credit #{index + 1}:</Typography>
              <Typography variant='body1' className={classes.creditDetails}>
                {credit.title}
                <br />
                {credit.description}
                <br />$
                {(Math.round(parseFloat(credit.value) * 100) / 100).toFixed(2)}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderOfficeUsers(page) {
    const { classes, formData, pageNum, updatePage } = this.props;

    return (
      <div className={classes.reviewSection}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Office Users</Typography>
          <span onClick={() => updatePage(page - pageNum, formData)}>Edit</span>
        </div>
        <div className={classes.sectionBody}>
          {formData.officeUsers.map((user, index) => (
            <div key={index} className={classes.sectionRow}>
              <Typography variant='body1'>User #{index + 1}:</Typography>
              <Typography variant='body1'>
                {user.firstName} {user.lastName} - {user.email}{' '}
                {user.reportEmail || user.billingEmail ? '(' : null}
                {user.reportEmail ? 'reports' : null}
                {user.reportEmail && user.billingEmail ? ', ' : null}
                {user.billingEmail ? 'billing' : null}
                {user.reportEmail || user.billingEmail ? ')' : null}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      formData,
      formSectionValues,
      onSubmit,
      handleSubmit,
      previousPage,
      submitting
    } = this.props;

    return (
      <form onSubmit={handleSubmit(() => onSubmit(formData))}>
        {this.renderOfficeInformation(0)}
        {this.renderOfferingSubscriptions(1)}
        {this.renderSetupFee(3)}
        {formData.signUpCredit &&
        formData.signUpCredit.length > 0 &&
        Object.keys(formData.signUpCredit[0]).length > 0
          ? this.renderSignUpCredit(4)
          : null}
        {formData.officeUsers &&
        formData.officeUsers.length > 0 &&
        Object.keys(formData.officeUsers[0]).length > 0
          ? this.renderOfficeUsers(5)
          : null}
        <div className={classes.sectionActions}>
          <Button
            color='primary'
            type='button'
            onClick={() => previousPage(formSectionValues)}
          >
            Previous
          </Button>
          <Button color='primary' type='submit'>
            {submitting ? (
              <Loader type='Oval' color='#696969' height='10' width='10' />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const offeringsData = state.firestore.ordered.offerings;
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  let offerings;

  if (isLoaded(offeringsData) && !isEmpty(offeringsData)) {
    offerings = offeringsData;
  }

  return { formData, offerings };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'offerings',
      storeAs: 'offerings'
    }
  ]),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(OfficeReview);
