import _ from 'lodash';
import React, { useContext } from 'react';
import HeaderTitleWithTabs from '../../components/HeaderTitleWithTabs';
import HeaderTitleText from '../../components/HeaderTitleText';
import PropTypes from 'prop-types';
import { AppContext } from '../../contexts';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';
import EmployeeScenes from './scenes';

const Employees = props => {
  const {
    state: { profile }
  } = useContext(AppContext);
  let tabs = [];
  let tabIndex = 0;
  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const TabScene = EmployeeScenes[key];
    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: '/employees/$tabName',
        renderComponent: () => (
          <TabScene
            {...props}
            tabId={props.match.params.tabId}
            params={props.match.params}
            route={route}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex = tabIndex + 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      params={props.match.params}
      renderTitle={() => <HeaderTitleText text='Employees' />}
      tabId={props.match.params.tabId}
      tabs={tabs}
    />
  );
};

Employees.propTypes = {
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Employees);
