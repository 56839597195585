import React, { useState } from 'react';
import DialogTransition from '../../../../../../components/DialogTransition';
import qs from 'qs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Dialog,
  Tooltip,
  Typography,
  DialogContent,
  withStyles,
  IconButton,
  Button
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import RemoveIcon from '@material-ui/icons/Remove';
import { Formik, Form, Field, FieldArray } from 'formik';
import FieldText from '../../../../../../components/FieldText';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';
import EmployeeFilteredSelect from '../../../../../../components/EmployeeFilteredSelect';
import { validations } from '../../../../../../services/validation';
import { OFFERINGS as serviceOfferingOptions } from '../../../../../../services/options';
import { FieldSelect } from '../../../../../../components/Fields';
import { Close } from '@material-ui/icons';

const DialogAddTeam = props => {
  const { classes, onClose, openDialog, onTeamAddRedirect, fullScreen } = props;
  const [validateError, setValidateError] = useState(false);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    const { firestore } = props;
    values.members.push(values.lead);
    values.members = removeMemberNames(values.members);
    values.serviceOffering = [values.serviceOffering];
    values.totalOffices = 0;
    try {
      let res = await firestore.add({ collection: 'teams' }, values);
      setLoadingAfterSubmit(false);
      onTeamAddRedirect(res.id, true);
    } catch (e) {
      console.log(e);
      setLoadingAfterSubmit(false);
      onClose();
    }
  };

  const removeMemberNames = members => {
    return members.map(member => (member.id ? (member = member.id) : member));
  };

  const displayMembers = (members, arrayHelpers) => {
    return members.map((member, key) =>
      member.name ? (
        <div key={key} className={classes.teamMembersNames}>
          {member.name}
          <IconButton
            color='inherit'
            aria-label={`Remove ${member.name}`}
            className={classes.teamMemberRemovalIcon}
            onClick={() => arrayHelpers.remove(key)}
            component='span'
            disableRipple
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null
    );
  };

  const validate = value => {
    if (validations.required(value)) {
      setValidateError('Required');
      return true;
    } else {
      setValidateError(null);
      return false;
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={openDialog}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Add Team
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            name: '',
            lead: '',
            serviceOffering: 'base',
            members: []
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue, initialValues }) => (
            <Form className={classes.dialogAddTeamForm}>
              <Field
                className={classes.dialogTextField}
                name='name'
                label='Team Name'
                component={FieldText}
                variant='outlined'
                validate={validations.required}
              />

              <Field
                name='serviceOffering'
                label='Service Offering'
                options={serviceOfferingOptions}
                optionKey='key'
                optionText='name'
                optionValue='key'
                margin='none'
                component={FieldSelect}
                validate={validations.required}
              />

              <Field name='lead' label='Team Lead' validate={validate}>
                {({ field }) => {
                  return (
                    <EmployeeFilteredSelect
                      label='Team Lead'
                      error={validateError}
                      onChange={employee => {
                        setFieldValue(field.name, employee ? employee.id : '');
                      }}
                      previouslySelectedEmployeeId={''}
                    />
                  );
                }}
              </Field>

              <FieldArray
                name='members'
                label='Choose Employee'
                render={arrayHelpers => (
                  <>
                    <EmployeeFilteredSelect
                      label='Team Member Selection'
                      onChange={employee => {
                        if (!employee) return;
                        if (employee.id === values.lead) return;
                        const { members } = values;
                        if (members.find(member => member.id === employee.id))
                          return;
                        members.push({
                          name: `${employee.firstName} ${employee.lastName}`,
                          id: employee.id
                        });
                        setFieldValue('members', members);
                      }}
                      previouslySelectedEmployeeId={null}
                    />

                    <div className={classes.dialogEmployeeList}>
                      {values.members.length > 0
                        ? displayMembers(values.members, arrayHelpers)
                        : null}
                    </div>
                  </>
                )}
              />

              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='default'
                  className={classes.dialogButton}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addTeamFormData').safeObject
    ? JSON.parse(t(searchObj, 'addTeamFormData').safeObject)
    : {};

  return { formData };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddTeam);
