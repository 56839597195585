import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import AnsweringIcon from '@material-ui/icons/PhoneCallback';
import BackIcon from '@material-ui/icons/ArrowBack';
import BaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import RecallIcon from '@material-ui/icons/Schedule';
import ReschedulingIcon from '@material-ui/icons/Restore';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import TreatmentIcon from '@material-ui/icons/SettingsBackupRestore';
import Typography from '@material-ui/core/Typography';
import WebsiteChatIcon from '@material-ui/icons/ChatBubbleOutline';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { IconButton, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const OfficeName = ({ classes, history, office, pathname }) => {
  const onClick = () =>
    pathname ? history.push(pathname) : history.push('/offices');

  return (
    <div>
      <div className={classes.officeNameHeading}>
        <Typography variant='h6' className={classes.officeBreadcrumb}>
          Offices
        </Typography>
        <Typography variant='h6' className={classes.officeNameHeadText}>
          <Tooltip title='Back to Offices'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Back to Offices'
              onClick={onClick}
              className={classes.officeNameBackIcon}
            >
              <BackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.officeNameText}>
            {office && office.officeInformation
              ? office.officeInformation.name
              : ''}
          </div>
          <span
            className={
              office && office.status === 'paused'
                ? classes.pausedStatus
                : office && office.status === 'inactive'
                ? classes.inactiveStatus
                : office && office.status === 'active'
                ? classes.activeStatus
                : office && office.status === 'onboarding'
                ? classes.onboardingStatus
                : office && office.status === 'failedOnboarding'
                ? classes.failedOnboardingStatus
                : ''
            }
          >
            <StatusIcon />
          </span>
          <span className={classes.officeNameOfferingIcons}>
            {office && office.offerings.base ? (
              <span>
                <BaseIcon />
              </span>
            ) : (
              ''
            )}
            {office &&
            office.offerings.recall &&
            !office.offerings.recallplus ? (
              <span>
                <RecallIcon />
              </span>
            ) : (
              ''
            )}
            {office && office.offerings.recallplus ? (
              <span>
                <RecallIcon className={classes.recallplusIcon} />
                <AddIcon className={classes.recallplusAddIconTitle} />
              </span>
            ) : null}
            {office && office.offerings.answering ? (
              <span>
                <AnsweringIcon />
              </span>
            ) : (
              ''
            )}
            {office && office.offerings.rescheduling ? (
              <span>
                <ReschedulingIcon />
              </span>
            ) : (
              ''
            )}
            {office && office.offerings.treatment ? (
              <span>
                <TreatmentIcon />
              </span>
            ) : (
              ''
            )}
            {office && office.offerings.websiteChat ? (
              <span>
                <WebsiteChatIcon />
              </span>
            ) : (
              ''
            )}
          </span>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const officeData = state.firestore.ordered.officeDetailInfo;
  const office =
    isLoaded(officeData) &&
    !isEmpty(officeData) &&
    officeData[0].id === props.officeId
      ? officeData[0]
      : null;
  return { office };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'clients',
      doc: props.officeId,
      storeAs: 'officeDetailInfo'
    }
  ]),
  withStyles(styles, { withTheme: true }),
  withRouter
)(OfficeName);
