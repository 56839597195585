import moment from 'moment-timezone';
import { CALLFORCE_TZ } from '../../../../services/constants';

export const invoicesColumns = [
  {
    index: 1,
    key: 'clientName',
    essential: true,
    text: 'Office',
    spacing: { xs: 6, sm: 4 },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'period',
    essential: false,
    text: 'Period',
    spacing: { xs: 3, sm: 3 },
    formatData: doc => {
      const dateStart = doc.dateStart._seconds
        ? doc.dateStart._seconds
        : doc.dateStart.seconds;
      const dateEnd = doc.dateEnd._seconds
        ? doc.dateEnd._seconds
        : doc.dateEnd.seconds;

      return `
        ${moment.unix(dateStart).tz(CALLFORCE_TZ).format('MMM D, YYYY')}
        -
        ${moment.unix(dateEnd).tz(CALLFORCE_TZ).format('MMM D, YYYY')}
      `;
    },
    icon: false,
    sortable: false,
    defaultSortColumn: false,
    defaultSort: 'desc'
  },
  {
    index: 3,
    key: 'status',
    essential: true,
    text: 'Status',
    spacing: { xs: 3, sm: 2 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'total',
    essential: true,
    text: 'Total',
    spacing: { xs: 3, sm: 3 },
    formatData: doc => `$${parseFloat(doc.total).toFixed(2)}`,
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'desc'
  }
];
