import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

// function checkDateInput() {
//     var input = document.createElement('input');
//     input.setAttribute('type','date');
//
//     var notADateValue = 'not-a-date';
//     input.setAttribute('value', notADateValue);
//
//     return (input.value !== notADateValue);
// }

class FieldDate extends Component {
  UNSAFE_componentWillUpdate(nextProps) {
    const { field, handleValueChange, setFieldValue } = this.props;

    if (handleValueChange && field.value !== nextProps.field.value) {
      handleValueChange(nextProps.field.value);
    }

    if (setFieldValue && field.value !== nextProps.value) {
      setFieldValue(field.name, nextProps.value);
    }
  }

  render() {
    const {
      classes,
      field,
      form: { touched, errors },
      label,
      variant
    } = this.props;

    return (
      <div>
        <TextField
          variant={variant}
          type='date'
          error={!!(touched[field.name] && errors[field.name])}
          label={label}
          className={classes.textField}
          margin='normal'
          helperText={
            touched[field.name] && errors[field.name] ? errors[field.name] : ''
          }
          InputLabelProps={{
            shrink: true
          }}
          {...field}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FieldDate);
