import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import helpers from '../../../../../../../../../../../../helpers';
import Grid from '@material-ui/core/Grid';
import RegionOfficesTable from './RegionOfficesTable';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../../../../../../../../../contexts';
import { t } from 'typy';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  regionOffices: {
    height: theme.containers.paper.height.default,
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  }
});

const RegionOffices = ({
  classes,
  region,
  sitemapPath,
  params,
  location,
  history
}) => {
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToManage = helpers.allowedTo('manage', sitemapPath, userGroups);
  const addRegionOfficesDialogOpen =
    queryString.parse(location.search.slice(1)).addRegionOfficesDialogOpen ===
    'true';
  const [regionOffices, setRegionOffices] = useState([]);
  const [checkedOffices, setCheckedOffices] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getOfficeData = async officeId => {
      try {
        const officeRef = firebase
          .firestore()
          .collection('clients')
          .doc(officeId);

        const officeData = await officeRef.get();
        const office = officeData.data();
        office.id = officeId;
        return office;
      } catch (e) {
        console.error(e);
      }
    };

    const getRegionOffices = async () => {
      const retrievedRegionOffices = [];
      try {
        if (region) {
          const { regionOffices } = region;
          for (const officeId in regionOffices) {
            retrievedRegionOffices.push(getOfficeData(regionOffices[officeId]));
          }
          let officeDatas = await Promise.all(retrievedRegionOffices);
          setCheckedOffices(true);
          setRegionOffices(officeDatas);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (!checkedOffices) {
      getRegionOffices();
    }

    if (location.search === '?newRegionOffices=true') {
      history.push({ search: '?pathname=/offices/clientGroups/regions' });
      getRegionOffices();
    }
  }, [
    firebase,
    region,
    regionOffices,
    location,
    checkedOffices,
    params,
    history
  ]);

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.regionOffices} elevation={4}>
            <RegionOfficesTable
              offices={regionOffices}
              clientGroupId={params.clientGroupId}
              allowedToManage={allowedToManage}
              addRegionOfficesDialogOpen={addRegionOfficesDialogOpen}
              loading={loading}
              params={params}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

RegionOffices.propTypes = {
  classes: PropTypes.object.isRequired,
  regionOffices: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(RegionOffices);
