export const styles = theme => ({
  dialogBody: {
    maxWidth: '1000px',
    margin: '0 auto',
    marginBottom: '20px',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    width: '100%'
  },
  dialogTitle: {
    color: theme.palette.primary.main
  },
  stepperContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileStepperContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  mobileStepper: {
    maxWidth: 400,
    flexGrow: 1,
    backgroundColor: 'unset',
    alignItems: 'center',
    '& > div': {
      margin: '0px auto'
    }
  }
});
