import { fade } from '@material-ui/core/styles/colorManipulator';

const drawerWidth = 240;

export const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  popper: {
    zIndex: '1'
  },
  appBar: {
    position: 'fixed',
    marginLeft: drawerWidth,
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  toolBar: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 16
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  accountIconContainer: {
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer'
    },
    '& img': {
      height: '32px',
      width: '32px',
      borderRadius: '50%'
    }
  },
  accountIcon: {
    marginLeft: 'auto'
  },
  typography: {
    padding: theme.spacing.unit * 2
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  navIconHide: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    padding: '5px',
    marginRight: '10px'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    display: 'block',
    width: '45%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  drawerPaper: {
    width: drawerWidth,
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    minheight: '100vh'
  }
});
