const DataScenes = {};

DataScenes['calls-answered'] = require('./CallsAnswered').default;
DataScenes['not-scheduled'] = require('./NotScheduled').default;
DataScenes.recall = require('./Recall').default;
DataScenes.reschedule = require('./Reschedule').default;
DataScenes.treatment = require('./Treatment').default;
DataScenes.websiteChat = require('./WebsiteChat').default;

export default DataScenes;
