import React, { useContext, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import queryString from 'query-string';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { AppContext } from '../../contexts';
import { Button } from '@material-ui/core';
import { compose } from 'redux';
import { FieldText } from '../../components/Fields';
import { NavLink } from 'react-router-dom';
import { remail } from '../../services/validation';
import { slideUpTransitionOptions } from '../../services/transitions';
import { UPDATE } from '../../types';
import { useLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import googleGLogo from '../../assets/google-g-logo.png';
import logo from '../../assets/ReachLogo.png';

const Login = ({ classes, history, location }) => {
  const {
    state: { firebase },
    dispatch
  } = useContext(AppContext);
  const [hasLoggedInPortal] = useState(false);
  const [emailChecked] = useState(false);
  const [logInError, setLoginError] = useState(false);
  const [loadingAfterLogin, setLoadingAfterLogin] = useState(false);
  const lastLocation = useLastLocation();

  const onSubmit = values => signIn(null, values);

  const signIn = async (signInMethod, values) => {
    setLoginError(false);
    setLoadingAfterLogin(true);

    try {
      let res;
      if (signInMethod === 'popupGoogle') {
        const provider = new firebase.auth.GoogleAuthProvider();
        res = await firebase.auth().signInWithPopup(provider);
      } else {
        res = await firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password);
      }

      const user = res.user;
      const doc = await firebase
        .firestore()
        .collection('employees')
        .doc(user.uid)
        .get();
      const profile = { ...doc.data(), id: doc.id };
      const searchParams = queryString.parse(location.search);

      dispatch({
        type: UPDATE,
        payload: {
          authenticated: true,
          auth: { data: user, isLoaded: true, isEmpty: false },
          profile: { data: profile, isLoaded: true, isEmpty: false }
        }
      });

      history.push(searchParams.redirectUri ? searchParams.redirectUri : '/');
    } catch (err) {
      console.error(err);
      setLoginError(true);
      setLoadingAfterLogin(false);
    }
  };

  const renderForgotPasswordText = classes => {
    return (
      <Typography variant='body2' className={classes.forgotPassword}>
        Forgot Password
      </Typography>
    );
  };

  const validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Please enter your email address';
    }

    if (values.email) {
      if (!remail.test(values.email)) {
        errors.email = 'Please enter a valid email address';
      }
    }

    if (!values.password) {
      errors.password = 'Please enter your password';
    }

    return errors;
  };

  return (
    <div className={`Login ${classes.root}`}>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.content}>
            <div className={classes.header}>
              <img src={logo} alt='Logo' className={classes.logo} />
            </div>
            <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={onSubmit}
                validate={validate}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form className={classes.form}>
                    {logInError ? (
                      <div className={classes.loginError}>
                        Invalid email or password.
                      </div>
                    ) : (
                      ''
                    )}
                    <Field name='email'>
                      {({ form, field }) => {
                        return (
                          <FieldText
                            form={form}
                            field={field}
                            label='Email'
                            variant='outlined'
                            onChange={e => {
                              field.onBlur(e);
                              field.onChange(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Field name='password'>
                      {({ form, field }) => {
                        return (
                          <FieldText
                            form={form}
                            field={field}
                            label='Password'
                            variant='outlined'
                            type='password'
                            onChange={e => {
                              field.onBlur(e);
                              field.onChange(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <div className={classes.buttonContainer}>
                      <NavLink
                        to={`/forgot-password?hlip=${
                          !hasLoggedInPortal && emailChecked ? 'false' : 'true'
                        }${
                          lastLocation
                            ? `&redirectUri=${encodeURIComponent(
                                `${lastLocation.pathname}${lastLocation.search}`
                              )}`
                            : ''
                        }`}
                      >
                        {renderForgotPasswordText(classes)}
                      </NavLink>
                      <Button
                        size='large'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        type='submit'
                      >
                        {loadingAfterLogin ? (
                          <Loader
                            type='Oval'
                            color='#ffffff'
                            height='13'
                            width='13'
                          />
                        ) : (
                          'Login'
                        )}
                      </Button>
                    </div>
                    <div className={classes.socialLogin}>
                      <div className={classes.socialText}>OR</div>
                      <Button
                        size='large'
                        variant='contained'
                        color='inherit'
                        className={classes.googleLogin}
                        onClick={() => signIn('popupGoogle', null)}
                      >
                        <img
                          className={classes.googleGLogo}
                          src={googleGLogo}
                          alt='Google'
                        />
                        SIGN IN WITH G SUITE
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </ReactCSSTransitionGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(Login);
