import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import DialogTransition from '../../../../../../components/DialogTransition';
import AddCreditForm from './components/AddCreditForm';
import { styles } from './styles';

const DialogAddCredit = ({
  classes,
  fullScreen,
  onClose,
  openDialog,
  onSave
}) => (
  <Dialog
    TransitionComponent={DialogTransition}
    fullScreen={fullScreen}
    fullWidth
    open={openDialog}
    onClose={onClose}
    maxWidth='sm'
    disableBackdropClick
  >
    <div className={classes.dialogTitleArea}>
      <div className={classes.dialogTitleTextArea}>
        <Typography variant='h6' className={classes.dialogTitle}>
          Add Credit
        </Typography>
      </div>
      <Tooltip title='Close' className={classes.dialogCloseButton}>
        <IconButton color='inherit' aria-label='Close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
    <DialogContent className={classes.dialogContent}>
      <AddCreditForm onSave={onSave} />
    </DialogContent>
  </Dialog>
);

DialogAddCredit.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default compose(
  connect((state, props) => ({}), {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddCredit);
