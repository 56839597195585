import {
  SNACKBAR_ADDED,
  SNACKBAR_ADDING,
  SNACKBAR_DELETED,
  SNACKBAR_DELETING,
  SNACKBAR_EDITED,
  SNACKBAR_UPDATING
} from '../actions/types';

export const snackbarAddedOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_ADDED:
      return action.payload;
    default:
      return state;
  }
};

export const snackbarAddingOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_ADDING:
      return action.payload;
    default:
      return state;
  }
};

export const snackbarDeletedOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_DELETED:
      return action.payload;
    default:
      return state;
  }
};

export const snackbarDeletingOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_DELETING:
      return action.payload;
    default:
      return state;
  }
};

export const snackbarEditedOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_EDITED:
      return action.payload;
    default:
      return state;
  }
};

export const snackbarUpdatingOpen = (state = false, action) => {
  switch (action.type) {
    case SNACKBAR_UPDATING:
      return action.payload;
    default:
      return state;
  }
};

export const SnackbarReducer = {
  snackbarAddedOpen,
  snackbarAddingOpen,
  snackbarDeletedOpen,
  snackbarDeletingOpen,
  snackbarEditedOpen,
  snackbarUpdatingOpen
};
