import React, { Component } from 'react';
import DialogTransition from '../../../../../../components/DialogTransition';
import helpers from '../../../../../../helpers';
import OfficeInformation from './components/OfficeInformation';
import OfferingSubscriptions from './components/OfferingSubscriptions';
import OfferingsPricing from './components/OfferingsPricing';
import OfficeUsers from './components/OfficeUsers';
import OfficeReview from './components/OfficeReview';
import qs from 'qs';
import SetupFee from './components/SetupFee';
import SignUpCredit from './components/SignUpCredit';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { addUsers } from '../../../../../../services/auth';
import { addSignUpCredits } from '../../../../../../services/credits';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  DAYS,
  defaultOfferingPrices
} from '../../../../../../services/options';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  MobileStepper,
  Stepper,
  Step,
  StepLabel,
  withStyles
} from '@material-ui/core';
import {
  sanitizeAddOfficeValues,
  transformAddOfficeData
} from '../../../../../../services/transform';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';

class DialogAddOffice extends Component {
  steps = [
    'Info',
    'Subscriptions',
    'Pricing',
    'Setup Fee',
    'Credit',
    'Users',
    'Review'
  ];

  onSubmit = async values => {
    const { firestore, onOfficeAddRedirect } = this.props;
    const currentTime = firestore.Timestamp.now().toDate();
    let vals = transformAddOfficeData(sanitizeAddOfficeValues(values), DAYS);

    try {
      let res = await firestore.add(
        { collection: 'clients' },
        { ...vals, createdTime: currentTime }
      );

      const charge = {
        officeId: res.id,
        officeName: values.name,
        immediateCharge: false,
        entryCharge: true,
        periodCharge: false,
        quantity: 1,
        type: 'setup',
        entryPrice: values.setupFeeAmount,
        status: 'open'
      };
      await helpers.addCharge({ firestore, charge });

      if (
        values.officeUsers &&
        values.officeUsers.length > 0 &&
        Object.keys(values.officeUsers[0]).length > 0
      ) {
        await addUsers(values.officeUsers, res.id);
      }

      if (
        values.signUpCredit &&
        values.signUpCredit.length > 0 &&
        Object.keys(values.signUpCredit[0]).length > 0
      ) {
        await addSignUpCredits(
          firestore,
          values.signUpCredit,
          res.id,
          values.name,
          currentTime
        );
      }

      onOfficeAddRedirect(res.id, true);
    } catch (e) {
      console.log(e.toString());
    }
  };

  updatePage = (updatePageNum, values) => {
    const { formData, history, location, page } = this.props;
    const addOfficeFormData = JSON.stringify({ ...formData, ...values });
    let searchObj = qs.parse(location.search.slice(1));
    delete searchObj.addOfficeDialogPage;
    searchObj = { ...searchObj, addOfficeFormData };
    const nextPage = page + updatePageNum;
    const pathname = location.pathname;
    const param = `?addOfficeDialogPage=${nextPage}&`;

    history.push(`${pathname}${param}${qs.stringify(searchObj)}`);
  };

  nextPage = values => this.updatePage(1, values);

  previousPage = values => this.updatePage(-1, values);

  render() {
    const { classes, location, onClose, openDialog, page } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={true}
        open={openDialog}
        onClose={onClose}
        onEscapeKeyDown={onClose}
        aria-labelledby='responsive-dialog-title'
        disableBackdropClick
      >
        <div className={classes.dialogBody}>
          <div className={classes.stepperContainer}>
            <Stepper activeStep={page}>
              {this.steps.map(label => {
                const props = {};
                const labelProps = {};

                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div className={classes.mobileStepperContainer}>
            <MobileStepper
              variant='dots'
              steps={this.steps.length}
              position='static'
              activeStep={page}
              className={classes.mobileStepper}
              nextButton={null}
              backButton={null}
            />
          </div>
          <DialogTitle id='responsive-dialog-title'>
            <span className={classes.dialogTitle}>Add Office</span>
          </DialogTitle>
          <DialogContent>
            {page === 0 && (
              <OfficeInformation
                location={location}
                onSubmit={values => this.nextPage(values)}
              />
            )}
            {page === 1 && (
              <OfferingSubscriptions
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.nextPage(values)}
              />
            )}
            {page === 2 && (
              <OfferingsPricing
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.nextPage(values)}
                defaultOfferingPrices={defaultOfferingPrices}
              />
            )}
            {page === 3 && (
              <SetupFee
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.nextPage(values)}
              />
            )}
            {page === 4 && (
              <SignUpCredit
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.nextPage(values)}
              />
            )}
            {page === 5 && (
              <OfficeUsers
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.nextPage(values)}
              />
            )}
            {page === 6 && (
              <OfficeReview
                location={location}
                previousPage={values => this.previousPage(values)}
                onSubmit={values => this.onSubmit(values)}
                updatePage={(updatePageNum, values) =>
                  this.updatePage(updatePageNum, values)
                }
                pageNum={6}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const page = searchObj.addOfficeDialogPage
    ? parseInt(searchObj.addOfficeDialogPage)
    : 0;
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};

  return { formData, page };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddOffice);
