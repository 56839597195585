import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  DialogTitle,
  DialogContent,
  Dialog,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReportType from './ReportTypeStep';
import SelectOffices from './SelectOfficesStep';
import RunReport from './RunReportStep';
import { Formik, Form, Field, FieldArray } from 'formik';
import helpers from '../../../../../helpers';
import { date, required } from '../../../../../validation';
import PropTypes from 'prop-types';
import downloadCsv from 'download-csv';

const CustomReports = ({
  classes,
  location,
  firestore,
  cancel,
  clientGroups,
  offices,
  functions
}) => {
  const [steps] = useState(['Report Type', 'Select Offices', 'Run Report']);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [noReportData, setNoReportData] = useState(false);

  const handleReset = () => {
    setIsLoading(false);
    cancel();
  };

  const onSubmit = async values => {
    setIsLoading(true);
    values.currentTime = firestore.Timestamp.now().toDate();
    values.host = `${window.location.protocol}//${window.location.hostname}`;
    const cleanedValues = helpers.transformCustomReportValues(values);
    try {
      const customReport = functions.httpsCallable('reports-customReport');
      customReport(cleanedValues)
        .then(res => {
          const {
            data: { headers, reportData, fileName }
          } = res;
          if (!reportData.length) {
            setNoReportData(true);
          } else {
            downloadCsv(reportData, headers, fileName);
            handleReset();
          }
        })
        .catch(err => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const updatePage = updatePageNum => {
    const nextPage = page + updatePageNum;
    setPage(nextPage);
  };

  const nextPage = values => updatePage(1, values);

  const previousPage = values => updatePage(-1, values);

  const validations = {
    reportType: v => helpers.composeFieldValidation(v, [required]),
    startDate: v => helpers.composeFieldValidation(v, [required, date]),
    endDate: v => helpers.composeFieldValidation(v, [required, date]),
    offices: v => required
  };

  return (
    <div className={classes.dialogBody}>
      <div className={classes.stepperContainer}>
        <Stepper activeStep={page}>
          {steps.map(label => {
            const props = {};
            const labelProps = {};

            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className={classes.mobileStepperContainer}>
        <MobileStepper
          variant='dots'
          steps={steps.length}
          position='static'
          activeStep={page}
          className={classes.mobileStepper}
          nextButton={null}
          backButton={null}
        />
      </div>
      <DialogTitle id='responsive-dialog-title'>
        <span classes={classes.dialogTitle}>Report Wizard</span>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          className={classes.wizardForm}
          initialValues={{
            reportType: '',
            startDate: '',
            endDate: '',
            offices: [],
            clientGroupsRegions: [],
            clientGroupName: '',
            regionName: ''
          }}
          onSubmit={onSubmit}
          onReset={handleReset}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              {page === 0 && (
                <ReportType
                  location={location}
                  nextPage={values => nextPage(values)}
                  Field={Field}
                  classes={classes}
                  name='reportType'
                  cancel={cancel}
                  errors={errors}
                  values={values}
                  validations={validations}
                />
              )}
              {page === 1 && (
                <SelectOffices
                  location={location}
                  previousPage={values => previousPage(values)}
                  nextPage={values => nextPage(values)}
                  FieldArray={FieldArray}
                  setFieldValue={setFieldValue}
                  classes={classes}
                  name='offices'
                  clientGroups={clientGroups}
                  allOffices={offices}
                  cancel={cancel}
                  values={values}
                  errors={errors}
                  validations={validations}
                />
              )}
              {page === 2 && (
                <RunReport
                  location={location}
                  previousPage={values => previousPage(values)}
                  values={values}
                  onSubmit={values => onSubmit(values)}
                  Field={Field}
                  classes={classes}
                  cancel={cancel}
                  errors={errors}
                  validations={validations}
                  allOffices={offices}
                  updatePage={updatePage}
                  isLoading={isLoading}
                />
              )}
            </Form>
          )}
        </Formik>
      </DialogContent>

      <Dialog
        open={noReportData}
        className={classes.dialog}
        aria-labelledby='responsive-dialog-title'
        disableBackdropClick
      >
        <div className={classes.titleArea}>
          <div className={classes.titleTextArea}>
            <Typography variant='h6' className={classes.title}>
              No Report Data
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.closeButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={() => {
                setIsLoading(false);
                setNoReportData(false);
                setPage(0);
              }}
            >
              <CloseIcon classes={classes.closeIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.noReportDialogContent}>
          There is no report data based on the parameters chosen for these
          selected offices.
        </DialogContent>
      </Dialog>
    </div>
  );
};

CustomReports.propTypes = {
  classes: PropTypes.object.isRequired,
  clientGroups: PropTypes.array.isRequired,
  offices: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired
};

export default CustomReports;
