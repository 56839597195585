import React, { Component } from 'react';
import qs from 'qs';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { t } from 'typy';
import { OFFERINGS } from '../../../../../../../services/options';
import { styles } from './styles';

class OfferingSubscriptions extends Component {
  componentDidMount() {
    const { change, formSectionValues, formData } = this.props;

    Object.keys(formSectionValues.offerings).map(key =>
      key === 'base'
        ? change(key, true)
        : change(key, t(formData, `offerings.${key}`).safeObject)
    );
  }

  renderOfferingsSubscriptions() {
    const {
      classes,
      change,
      formSectionValues: { offerings }
    } = this.props;

    return OFFERINGS.map((offering, index) => (
      <div key={index} className={classes[offering.key]}>
        <Field
          name={offering.key}
          label={offering.label}
          component={({ input }) => (
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.offeringCheckbox}
                  checked={!!input.value}
                  onChange={
                    offering.key === 'recall'
                      ? () => {
                          if (input.value && offerings.recallplus) {
                            change('recall', false);
                            change('recallplus', false);
                          } else {
                            change('recall', !input.value);
                          }
                        }
                      : offering.key === 'recallplus'
                      ? () => {
                          if (!input.value) {
                            change('recall', true);
                            change('recallplus', true);
                          } else {
                            change('recallplus', !input.value);
                          }
                        }
                      : input.onChange
                  }
                />
              }
              label={
                <Typography
                  variant='body1'
                  className={classes.offeringCheckboxLabel}
                >
                  {offering.name}
                </Typography>
              }
            />
          )}
        />
      </div>
    ));
  }

  render() {
    const {
      classes,
      formSectionValues,
      onSubmit,
      handleSubmit,
      previousPage
    } = this.props;

    return (
      <form onSubmit={handleSubmit(() => onSubmit(formSectionValues))}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Offering Subscriptions</Typography>
        </div>
        <div className={classes.offerings}>
          {this.renderOfferingsSubscriptions()}
        </div>
        <div className={classes.sectionActions}>
          <Button
            color='primary'
            type='button'
            onClick={() => previousPage(formSectionValues)}
          >
            Previous
          </Button>
          <Button color='primary' type='submit'>
            Next
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  const selector = formValueSelector('DialogAddOfficeForm');
  const offerings = {};

  OFFERINGS.forEach(offering => {
    offerings[offering.key] =
      selector(state, offering.key) !== undefined
        ? selector(state, offering.key)
        : offering.key === 'base';
  });

  return {
    formData,
    formSectionValues: { offerings }
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(OfferingSubscriptions);
