import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const HeaderTitleText = ({ classes, text }) => (
  <div>
    <div className={classes.heading}>
      <Typography variant='h6' className={classes.headText}>
        <div className={classes.titleText}>{text}</div>
      </Typography>
    </div>
  </div>
);

export default withStyles(styles, { withTheme: true })(HeaderTitleText);
