export const officesFilters = [
  {
    index: 1,
    key: 'status',
    options: [
      { key: 'active', text: 'Active', value: 'status,==,active' },
      { key: 'onboarding', text: 'Onboarding', value: 'status,==,onboarding' },
      { key: 'paused', text: 'Paused', value: 'status,==,paused' },
      { key: 'inactive', text: 'Inactive', value: 'status,==,inactive' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Status'
  }
];
