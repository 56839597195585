import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import DialogTransition from '../../../../../../components/DialogTransition';
import AddPricingPlanForm from './components/AddPricingPlanForm';
import { styles } from './styles';

const DialogAddPricingPlan = ({
  addPricingPlanDialogOpen,
  classes,
  fullScreen,
  offering,
  setAddPricingPlanDialogOpen
}) => {
  const onClose = () => setAddPricingPlanDialogOpen(false);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={addPricingPlanDialogOpen}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Add Pricing Plan
          </Typography>
          <Typography variant='caption' className={classes.dialogSubtitle}>
            {offering.name}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <AddPricingPlanForm offering={offering} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

DialogAddPricingPlan.propTypes = {
  addPricingPlanDialogOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  offering: PropTypes.object.isRequired,
  setAddPricingPlanDialogOpen: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddPricingPlan);
