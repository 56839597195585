import {
  DIALOG_OVERALL_MISTAKES_DETAILS_CLICK,
  DIALOG_OVERALL_MISTAKES_DETAILS_ITEM
} from './types';

export const dialogOverallMistakesDetailsClick = status => ({
  type: DIALOG_OVERALL_MISTAKES_DETAILS_CLICK,
  payload: status
});

export const dialogOverallMistakesDetailsItem = item => ({
  type: DIALOG_OVERALL_MISTAKES_DETAILS_ITEM,
  payload: item
});
