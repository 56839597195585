import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../contexts';
import EditButton from './EditButton';

const ReloContactData = ({
  classes,
  data,
  contactId,
  setApptTimeOpen,
  setContactCategoryOpen,
  setMiscOpen,
  setMiscItem,
  label,
  name,
  showEdit
}) => {
  const [categoryName, setCategoryName] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(null);
  const {
    state: { firebase }
  } = useContext(AppContext);

  const onClick = () => {
    if (name === 'appointmentTime') {
      setApptTimeOpen(true);
    } else if (name === 'category') {
      setContactCategoryOpen(true);
    } else {
      setMiscOpen(true);
      setMiscItem({ key: name, label, data, contactId });
    }
  };

  useEffect(() => {
    if (name === 'category') {
      formatCategory(data['category']);
    }

    if (name === 'subcategory') {
      formatSubcategory(data['category'], data['subcategory']);
    }
  });

  const formatCategory = async categoryId => {
    try {
      const categoryData = await firebase
        .firestore()
        .collection('offeringsCategories')
        .doc(categoryId)
        .get();

      setCategoryName(categoryData.data().category);
    } catch (e) {
      console.error(e);
    }
  };

  const formatSubcategory = async (categoryId, subcategoryId) => {
    try {
      const subcategoryData = await firebase
        .firestore()
        .collection('offeringsCategories')
        .doc(categoryId)
        .collection('subcategories')
        .doc(subcategoryId)
        .get();

      setSubcategoryName(subcategoryData.data().category);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        {name === 'category'
          ? categoryName
          : name === 'subcategory'
          ? subcategoryName
          : data[name]}
      </div>
    </div>
  );
};

export default ReloContactData;
