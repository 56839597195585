import {
  DIALOG_INDI_MISTAKES_DETAILS_CLICK,
  DIALOG_INDI_MISTAKES_DETAILS_ITEM
} from '../actions/types';

export const dialogIndiMistakesDetailsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_INDI_MISTAKES_DETAILS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogIndiMistakesDetailsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_INDI_MISTAKES_DETAILS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogIndiMistakesDetailsReducer = {
  dialogIndiMistakesDetailsOpen,
  dialogIndiMistakesDetailsItem
};
