export const styles = theme => ({
  card: {
    width: '45%',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0 0 20px'
  },
  tile: {
    width: '23%',
    height: '90px',
    marginBottom: '12px'
  },
  tiles: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  tileTitle: {
    fontSize: '12px',
    padding: '3px 10px',
    borderTop: '1px solid #bfbfbf',
    margin: '16px 12px'
  }
});
