import {
  DIALOG_INDI_MISTAKES_DETAILS_CLICK,
  DIALOG_INDI_MISTAKES_DETAILS_ITEM
} from './types';

export const dialogIndiMistakesDetailsClick = status => ({
  type: DIALOG_INDI_MISTAKES_DETAILS_CLICK,
  payload: status
});

export const dialogIndiMistakesDetailsItem = item => ({
  type: DIALOG_INDI_MISTAKES_DETAILS_ITEM,
  payload: item
});
