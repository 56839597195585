import React from 'react';
import helpers from '../../../../../helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditDrProfileClick,
  dialogEditDrProfileItem,
  dialogEditOfficeHoursItem,
  dialogEditOfficeHoursClick
} from '../../../../../actions';
import { pmsObj } from '../../../../../services/options';
import moment from 'moment';

const SettingItem = ({
  classes,
  settingName,
  item,
  dialogEditDrProfileClick,
  dialogEditDrProfileItem,
  dialogEditOfficeHoursItem,
  dialogEditOfficeHoursClick,
  drProfileSetting,
  allowedToEdit,
  timezone
}) => {
  const fTime = (t, tz) => moment.tz(t, 'kk:mm:ss', tz).format('h:mm a');
  const fTz = (t, tz) => moment.tz(t, 'kk:mm:ss', tz).format('z');

  const paramHelperObj = {
    maxAge: 'Maximum Age',
    minAge: 'Minimum Age',
    minMonths: 'Minimum Months',
    maxMonths: 'Maximum Months'
  };

  const statusHelper = {
    accepted: 'Accepted',
    notAccepted: 'Not Accepted',
    conditional: 'Conditional'
  };

  const createHoursObj = dayObj => {
    const hoursObj = {
      mondayOpen: '',
      mondayClose: '',
      mondayClosed: false,
      tuesdayOpen: '',
      tuesdayClose: '',
      tuesdayClosed: false,
      wednesdayOpen: '',
      wednesdayClose: '',
      wednesdayClosed: false,
      thursdayOpen: '',
      thursdayClose: '',
      thursdayClosed: false,
      fridayOpen: '',
      fridayClose: '',
      fridayClosed: false,
      saturdayOpen: '',
      saturdayClose: '',
      saturdayClosed: false,
      sundayOpen: '',
      sundayClose: '',
      sundayClosed: false
    };

    if (dayObj) {
      for (const [day, val] of Object.entries(dayObj)) {
        if (val.length) {
          hoursObj[`${day}Open`] = val[0];
          hoursObj[`${day}Close`] = val[1];
        } else {
          hoursObj[`${day}Closed`] = true;
        }
      }
    }

    return hoursObj;
  };

  const changeSettingToReadableName = () => {
    switch (settingName) {
      case 'pms':
        return settingName.toUpperCase();
      case 'ageParams':
        return 'Age Parameters';
      case 'filterMedicaid':
        return 'Filter Medicaid';
      case 'pediatric':
        return 'Pediatric';
      case 'location':
        return 'Location';
      case 'timeOverdue':
        return 'Time Overdue';
      case 'balance':
        return 'Maximum Unpaid Balance';
      case 'officeName':
        return 'Office Name';
      case 'officeHours':
        return 'Office Hours';
      case 'insurances':
        return 'Insurances';
      default:
        break;
    }
  };

  const handleEdit = ({ properSettingLabel, originalProp }) => {
    if (settingName === 'officeHours') {
      if (!allowedToEdit) return;
      dialogEditOfficeHoursClick(true);
      dialogEditOfficeHoursItem({
        data: { ...createHoursObj(item) },
        key: 'officeHours',
        label: 'Hours',
        drProfileSetting,
        properSettingLabel,
        originalProp
      });
    } else {
      if (!allowedToEdit) return;
      dialogEditDrProfileClick(true);
      dialogEditDrProfileItem({
        item,
        drProfileSetting,
        properSettingLabel,
        originalProp
      });
    }
  };

  const renderEditButton = () => {
    const properSettingLabel = changeSettingToReadableName(settingName),
      originalProp = settingName;
    return (
      <span
        onClick={() => handleEdit({ properSettingLabel, originalProp })}
        className={classes.editButton}
      >
        Edit
      </span>
    );
  };

  const convertMilitaryToStandard = timeBlock => {
    const time = fTime(timeBlock, timezone);
    const timezoneBlock = fTz(timeBlock, timezone);
    return `${time} ${timezoneBlock}`;
  };

  const renderSettingItems = itemName => {
    let itemArray = [];

    if (
      typeof itemName === 'object' &&
      settingName !== 'officeHours' &&
      settingName !== 'insurances'
    ) {
      itemArray = Object.keys(helpers.flattenObj(itemName));
    } else if (typeof item === 'number') {
      if (settingName === 'balance') {
        itemName = `$${itemName}`;
      } else {
        itemName = itemName.toString();
      }
    } else if (pmsObj[itemName]) {
      itemName = pmsObj[itemName];
    } else if (settingName === 'balance' && itemName === '-') {
      itemName = '$0';
    } else if (settingName === 'officeHours') {
      itemArray = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ];
    } else if (settingName === 'insurances' && Array.isArray(itemName)) {
      itemArray = itemName;
    }

    return (
      <div className={classes.settingItem}>
        {typeof itemName === 'string' ? (
          <div className={classes.settingItemValues}>
            {itemName} {allowedToEdit && renderEditButton()}
          </div>
        ) : (
          itemArray.map((itemLabel, index) => {
            let additionalVals = '';
            let value = item[itemLabel];
            itemLabel = paramHelperObj[itemLabel]
              ? paramHelperObj[itemLabel]
              : itemLabel;
            if (settingName === 'officeHours') {
              itemLabel = itemLabel[0].toUpperCase();
              value =
                Array.isArray(value) && value.length
                  ? value
                      .map(time => convertMilitaryToStandard(time))
                      .join(' - ')
                  : 'Closed';
            } else if (settingName === 'insurances') {
              value = statusHelper[itemLabel.status];
              if (itemLabel.notes) additionalVals = `(${itemLabel.notes})`;
              itemLabel = itemLabel.name;
            }

            return (
              <div className={classes.settingItemObj} key={index}>
                <span>
                  {' '}
                  {itemLabel}: {value} {additionalVals ? additionalVals : null}
                </span>
                {settingName !== 'officeHours' && renderEditButton()}
              </div>
            );
          })
        )}
      </div>
    );
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        {settingName ? (
          <div className={classes.offeringLabel}>
            {changeSettingToReadableName()}
            <br />
          </div>
        ) : (
          <div className={classes.loading} />
        )}
      </div>
      <div className={classes.dataText}>
        {item && renderSettingItems(item)}
        {settingName === 'officeHours' ? renderEditButton() : null}
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    dialogEditDrProfileClick,
    dialogEditDrProfileItem,
    dialogEditOfficeHoursItem,
    dialogEditOfficeHoursClick
  })
)(SettingItem);
