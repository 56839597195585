import ReactSelect from 'react-select';
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FormHelperText } from '@material-ui/core';
import { compose } from 'redux';

////
// Pieces
// These just make the styling look material
////

function CustomNoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customNoOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomInput({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function CustomControl(props) {
  const { classes, error, label } = props.selectProps;
  return (
    <FormControl
      fullWidth
      className={classes.formControl}
      variant='outlined'
      margin='normal'
      error={error ? true : false}
    >
      <InputLabel
        variant='outlined'
        className={classes.label}
        animated='false'
        shrink={true}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        focused='true'
        fullWidth
        labelWidth={100}
        notched={true}
        margin='dense'
        children={props.children}
        inputComponent={CustomInput}
        inputProps={{
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}

function CustomOption(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function CustomPlaceholder(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customPlaceholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomSingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.customSingleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomValueContainer(props) {
  return (
    <div className={props.selectProps.classes.customValueContainer}>
      {props.children}
    </div>
  );
}

function CustomMultiValue(props) {
  const chipClass = props.selectProps.classes.chip;
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={
        chipClass
          ? chipClass
          : '' + props.isFocused
          ? props.selectProps.classes.chipFocused
          : ''
      }
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function CustomMenu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control: CustomControl,
  Menu: CustomMenu,
  MultiValue: CustomMultiValue,
  NoOptionsMessage: CustomNoOptionsMessage,
  Option: CustomOption,
  Placeholder: CustomPlaceholder,
  SingleValue: CustomSingleValue,
  ValueContainer: CustomValueContainer
};

////
// styles
////
const styles = theme => ({
  root: {
    display: 'flex'
  },
  base: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: '10px 14px',
    paddingRight: '0px'
  },
  customValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  customNoOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  customSingleValue: {
    fontSize: 16
  },
  customPlaceholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    paddingLeft: '14px'
  },
  paper: {
    position: 'relative',
    marginTop: '-8px',
    left: 0,
    right: 0
  },
  label: {
    backgroundColor: theme.palette.background.default,
    paddingRight: '5px',
    paddingLeft: '5px'
  }
});

////
// FilteredSelect
////

/**
 * FilteredSelect
 * @param {{
 *    onChange: (option: object) => void,
 *    optionsProvider: () => Promise<object[]>,
 *    previousOption?: object,
 *    optionsAreEqual?: (optionLeft: object, optionRight: object) => boolean,
 *    error?: boolean, // https://material-ui.com/api/form-control/#props
 *    showLimit?: number,
 *    selectOptions: {
 *      label: string,
 *      getOptionLabel?: (option: object) => any,
 *      getOptionValue?: (option: object) => any,
 *      maxMenuHeight?: number,
 *      minMenuHeight?: number,
 *      isClearable?: boolean
 *    }
 * }} props `selectProps` are passed through to the underlying [React Select component](https://react-select.com/components)
 *
 * `onChange` passes though new selections, including `null for a cleared select field
 *
 * `optionsProvider` is required to seed the FilteredSelect with options
 *
 * `previousOption` can be set to seed the Select with an existing selection, but should also be set with `optionsAreEqual`
 *
 * `optionsAreEqual` should be a function capable is seeing if two options are the same. Take, for example an employee Firebase object. It will have a `.id` that determines if two employees are the same.
 *
 * `error` is a boolean that makes its way to the [material ui form control](https://material-ui.com/api/form-control/#props)
 *
 * `showLimit` is useful when there are many options (~300 or more). It makes it so the user must type at least `showLimit` chars before options that match will be rendered. This helps a lot with perfomance. *Typically this should be 2-4 inclusive*.
 */
function FilteredSelect(props) {
  let [options, setOptions] = useState(null);
  let [currentSelection, setCurrentSelection] = useState(null);

  useEffect(() => {
    // sadly can't use async here, react expects either a function or undefined, not a promise
    props.optionsProvider().then(o => {
      setOptions(o);
      if (props.previousOption) {
        setCurrentSelection(
          o.find(o => {
            if (props.optionsAreEqual) {
              return props.optionsAreEqual(o, props.previousOption);
            }
            return o === props.previousOption;
          })
        );
      }
    });
    // eslint-disable-next-line
  }, []); // eslint wanted me to watch the `props` here, but since I want this effect to run a single time only, that doesn't make sense

  const maxMenuHeight = props.selectOptions.maxMenuHeight
    ? props.selectOptions.maxMenuHeight
    : 250;
  const minMenuHeight = props.selectOptions.minMenuHeight
    ? props.selectOptions.minMenuHeight
    : 100;

  const onChange = newOption => {
    setCurrentSelection(newOption);
    props.onChange(newOption);
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: props.theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };

  let filterOptions;
  let noOptionsMessage;
  if (props.showLimit) {
    filterOptions = ({ label, value, data }, query) => {
      if (query.length < props.showLimit) {
        return false;
      }

      const lcLabel = label.toLowerCase();
      const lcQuery = query.toLowerCase();

      if (lcLabel.includes(lcQuery)) {
        return true;
      }
      return false;
    };
    noOptionsMessage = ({ inputValue }) => {
      if (inputValue.length < props.showLimit) {
        return (
          <span>Please type at least {props.showLimit} characters...</span>
        );
      }
      return <span>No matches...</span>;
    };
  }

  return (
    <div className={props.classes.root}>
      <ReactSelect
        {...props.selectOptions}
        className={props.classes.base}
        classes={props.classes}
        styles={selectStyles}
        options={options}
        onChange={onChange}
        components={components}
        value={currentSelection}
        isLoading={options === null}
        isDisabled={options === null}
        filterOption={filterOptions}
        noOptionsMessage={noOptionsMessage}
        maxMenuHeight={maxMenuHeight}
        minMenuHeight={minMenuHeight}
        error={props.error}
      ></ReactSelect>
    </div>
  );
}

FilteredSelect.propTypes = {
  previousOption: PropTypes.any,
  optionsAreEqual: PropTypes.func,
  optionsProvider: PropTypes.func.isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showLimit: PropTypes.number,
  selectOptions: PropTypes.shape({
    label: PropTypes.string.isRequired,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    maxMenuHeight: PropTypes.number,
    minMenuHeight: PropTypes.number,
    isClearable: PropTypes.bool
  }).isRequired
};

export default compose(withStyles(styles, { withTheme: true }))(FilteredSelect);
