import React, { Component, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTransition from './DialogTransition';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import DashIcon from '@material-ui/icons/Remove';
import StatsChart from './StatsChart';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DatePicker } from 'material-ui-pickers';
import {
  dialogOverallMistakesDetailsClick,
  dialogOverallMistakesDetailsItem
} from '../actions';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class DialogOverallMistakesDetails extends Component {
  state = {
    dateRangeStart: moment(new Date())
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    dateRangeEnd: moment(new Date()).format('YYYY-MM-DD')
  };

  onSubmit(values) {
    console.log(values);
  }

  renderDateField(field) {
    const {
      meta: { touched, error }
    } = field;
    const [selectedDate, handleDateChange] = useState(new Date());

    return (
      <DatePicker
        label={field.label}
        className={field.className}
        value={selectedDate}
        onChange={handleDateChange}
        format={field.formatDate}
        animateYearScrolling
        disableFuture
        maxDate={field.maxDate}
        minDate={field.minDate}
        margin='normal'
        error={touched && error ? true : false}
        helperText={touched && error}
        variant='outlined'
        {...field.input}
      />
    );
  }

  renderDateRange() {
    const { classes, form, handleSubmit } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.state;

    return (
      <form
        className={classes.form}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        <Field
          name='dateStart'
          component={this.renderDateField}
          label='Start Date'
          formatDate='YYYY-MM-DD'
          className={classes.dateStartField}
          maxDate={moment(dateRangeEnd).subtract(1, 'days').toDate()}
          onChange={value => {
            this.setState({
              dateRangeStart: moment(value.toDate()).format('YYYY-MM-DD')
            });
          }}
        />
        <span>
          <DashIcon className={classes.dashIcon} />
        </span>
        <Field
          name='dateEnd'
          component={this.renderDateField}
          label='End Date'
          formatDate='YYYY-MM-DD'
          className={classes.dateEndField}
          minDate={moment(dateRangeStart).add(1, 'days').toDate()}
          onChange={value => {
            this.setState({
              dateRangeEnd: moment(value.toDate()).format('YYYY-MM-DD')
            });
          }}
        />
      </form>
    );
  }

  renderChart() {
    const { collection } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.state;

    return (
      <StatsChart
        collection={collection}
        dateRangeStart={dateRangeStart}
        dateRangeEnd={dateRangeEnd}
        overall={true}
        mistakes={true}
        dataLabel={`
					${collection.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
            return str.toUpperCase();
          })} Mistakes
				`}
        dateLabelFormat='YYYY-MM-DD'
      />
    );
  }

  render() {
    const {
      classes,
      collection,
      dialogOverallMistakesDetailsClick,
      dialogOverallMistakesDetailsItem,
      fullScreen,
      open
    } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          dialogOverallMistakesDetailsClick(!open);
          dialogOverallMistakesDetailsItem(null);
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.titleArea}>
          <div className={classes.titleTextArea}>
            <Typography variant='h6' className={classes.title}>
              Overall Statistics
            </Typography>
            <Typography variant='caption' className={classes.subtitle}>
              {collection
                ? `${collection
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} Mistakes`
                : ''}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.closeButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={() => {
                dialogOverallMistakesDetailsClick(!open);
                dialogOverallMistakesDetailsItem(null);
              }}
            >
              <CloseIcon classes={classes.closeIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          {this.renderDateRange()}
          <div className={classes.chartContainer}>{this.renderChart()}</div>
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  titleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    minHeight: theme.spacing.unit * 8,
    display: 'flex',
    padding: theme.spacing.unit * 2,
    paddingBottom: 2,
    paddingTop: 0
  },
  titleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  title: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit
  },
  subtitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 1.4,
    marginTop: -theme.spacing.unit,
    marginLeft: 2
  },
  closeButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: (theme.spacing.unit * 3) / 2
  },
  form: {
    width: '40%',
    margin: '0px 0px 0px auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  dashIcon: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: theme.spacing.unit * 1.2
  },
  dateStartField: {
    '& input': {
      padding: '12px 14px'
    }
  },
  dateEndField: {
    '& input': {
      padding: '12px 14px'
    }
  },
  chartContainer: {
    display: 'flex',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const validate = values => {
  const errors = {};

  return errors;
};

const mapStateToProps = (state, props) => {
  const now = new Date();
  let initialValues = {
    dateStart: moment(now).subtract(1, 'month').format('YYYY-MM-DD'),
    dateEnd: moment(now).format('YYYY-MM-DD')
  };

  return {
    open: state.dialogOverallMistakesDetailsOpen,
    initialValues,
    item: state.dialogOverallMistakesDetailsItem
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogOverallMistakesDetailsClick,
    dialogOverallMistakesDetailsItem
  }),
  firestoreConnect(),
  reduxForm({
    validate,
    form: 'OverallMistakesDateRangeForm',
    enableReinitialize: true
  }),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogOverallMistakesDetails);
