/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { t } from 'typy';
import HeaderTitleWithTabs from '../../components/HeaderTitleWithTabs';
import OfficeName from './components/OfficeName';
import OfficeScenes from './components';
import { AppContext } from '../../contexts';
import { offeringsObject } from '../../services/options/index';

const FirebaseReference = Symbol('FirebaseReference');

const Office = props => {
  const {
    location,
    match: { params }
  } = props;
  const [office, setOffice] = useState(null);
  const [drProfile, setProfile] = useState(null);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const searchParams = queryString.parse(location.search);
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('clients')
      .doc(params.officeId)
      .onSnapshot(doc => {
        const office = {
          ...doc.data(),
          id: doc.id,
          [FirebaseReference]: doc.ref
        };
        setOffice(office);
      });

    const drProfileUnsubscribe = firebase
      .firestore()
      .collection('clients')
      .doc(params.officeId)
      .collection('drProfile')
      .doc('profile')
      .onSnapshot(doc => {
        const profile = { ...doc.data(), id: params.officeId };
        setProfile(profile);
      });

    return () => {
      unsubscribe();
      drProfileUnsubscribe();
    };
  }, [firebase, setOffice, params]);

  useEffect(() => {
    if (!office) {
      // don't run if the office info hasn't been retrieved yet
      return;
    }

    const ensureReferencesNewOfferings = async () => {
      const offeringHasTimeArray = Object.keys(office.offeringsTimeActive);

      const offeringNeedsArray = Object.fromEntries(
        Object.keys(offeringsObject)
          .filter(offering => !offeringHasTimeArray.includes(offering))
          .map(offering => [`offeringsTimeActive.${offering}`, []])
      );

      if (!t(offeringNeedsArray).isEmptyObject) {
        await office[FirebaseReference].update(offeringNeedsArray);
      }
    };

    ensureReferencesNewOfferings();
  }, [office]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const OfficeScene = OfficeScenes[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: `/office/${params.officeId}/$tabName?pathname=${searchParams.pathname}`,
        renderComponent: () => (
          <OfficeScene
            office={office}
            sitemapPath={props.sitemapPath}
            profile={profile}
            drProfile={drProfile}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      largeAppBar
      params={params}
      renderTitle={() => (
        <OfficeName
          officeId={params.officeId}
          pathname={searchParams.pathname}
        />
      )}
      tabId={params.tabId}
      tabs={tabs}
    />
  );
};

Office.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sitemapPath: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Office);
