import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { Typography, withStyles } from '@material-ui/core';
import Column from './Column';
import { CALLFORCE_TZ } from '../../../../../services/constants';
import { OFFERINGS } from '../../../../../services/options';

const Charges = ({ classes, data: { charges } }) => (
  <div>
    {charges.map(charge => (
      <Grid container spacing={24} key={charge.id} className={classes.row}>
        <Column className={classes.chargeTitle} justify='flex-start'>
          <ChargeTitle charge={charge} />
          <ChargeSubtitle charge={charge} />
        </Column>
        <Column justify='center'>
          <ChargeDetail charge={charge} />
        </Column>
        <Column justify='flex-end'>${charge.total.toFixed(2)}</Column>
      </Grid>
    ))}
  </div>
);

const ChargeDetail = ({ charge }) => {
  if (charge.entryCharge) {
    const itemText =
      charge.type === 'answering'
        ? 'call'
        : charge.type === 'recall' ||
          charge.type === 'rescheduling' ||
          charge.type === 'treatment'
        ? 'appt'
        : charge.type === 'setup'
        ? 'setup'
        : null;
    const pluralItemText = charge.quantity > 1 ? 's' : '';
    const entryItem = `${itemText}${pluralItemText}`;

    return (
      <Typography variant='caption' style={{ fontSize: 12 }}>
        {`
          ${charge.quantity} 
          ${entryItem} 
          @$${charge.unitPrice.toFixed(2)}/${itemText}
        `}
      </Typography>
    );
  }

  return null;
};

const ChargeTitle = ({ charge }) => {
  const title = _.mapKeys(OFFERINGS, 'key')[charge.type]
    ? _.mapKeys(OFFERINGS, 'key')[charge.type].name
    : _.capitalize(charge.type);

  if (charge.entryCharge) {
    return <Typography variant='body1'>{title}</Typography>;
  }
  if (charge.periodCharge) {
    return <Typography variant='body1'>{title} Subscription</Typography>;
  }
  if (charge.immediateCharge) {
    return <Typography variant='body1'>{title} Custom Charge</Typography>;
  }
  return <Typography variant='body1'>{title}</Typography>;
};

const ChargeSubtitle = ({ charge }) => {
  if (charge.periodCharge) {
    return (
      <Typography variant='caption'>
        {`
          ${moment
            .unix(charge.dateStart.seconds)
            .tz(CALLFORCE_TZ)
            .format('MMM D, YYYY')} - ${moment
          .unix(charge.dateEnd.seconds)
          .tz(CALLFORCE_TZ)
          .format('MMM D, YYYY')}

        `}
      </Typography>
    );
  }

  return null;
};

const styles = theme => ({
  row: {
    marginBottom: 12
  },
  chargeTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default withStyles(styles, { withTheme: true })(Charges);
