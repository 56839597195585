import {
  DIALOG_DELETE_TEAM_CLICK,
  DIALOG_DELETE_TEAM_ITEM
} from '../actions/types';

const dialogDeleteTeamOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_DELETE_TEAM_CLICK:
      return action.payload;
    default:
      return state;
  }
};

const dialogDeleteTeamItem = (state = false, action) => {
  switch (action.type) {
    case DIALOG_DELETE_TEAM_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogDeleteTeamReducer = {
  dialogDeleteTeamOpen,
  dialogDeleteTeamItem
};
