import React, { useContext } from 'react';
import helpers from '../../../../helpers';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../contexts';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'typy';
import { styles } from '../styles';
import SettingItem from './components/SettingItem';
import DrProfileSettingEdit from './components/DialogEdit/DrProfileSettingEdit';
import DialogEditOfficeHours from '../../components/DialogEditOfficeHours';

const Settings = ({ classes, office, sitemapPath, drProfile, timezone }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToEdit = helpers.allowedTo('edit', sitemapPath, userGroups);

  const renderGeneralSettings = () => {
    let generalSettingsObj = {
      pms: '-',
      pediatric: '-',
      officeName: false,
      officeHours: '-',
      insurances: '-'
    };

    if (drProfile) {
      generalSettingsObj.pms = drProfile.pms
        ? JSON.parse(JSON.stringify(drProfile.pms))
        : generalSettingsObj.pms;
      generalSettingsObj.pediatric =
        drProfile &&
        drProfile.listConfig &&
        typeof drProfile.listConfig.pediatric === 'boolean'
          ? drProfile.listConfig.pediatric.toString()
          : generalSettingsObj.pediatric;
      generalSettingsObj.officeName = drProfile && drProfile.officeName;
      generalSettingsObj.officeHours = drProfile && drProfile.officeHours;
      generalSettingsObj.insurances =
        drProfile && drProfile.insurances && drProfile.insurances.length
          ? drProfile.insurances
          : generalSettingsObj.insurances;
    }

    return (
      <div>
        <Typography variant='caption' className={classes.sectionTitle}>
          General
        </Typography>

        <Paper elevation={2}>
          <div className={classes.sectionContainer}>
            {generalSettingsObj &&
              Object.keys(generalSettingsObj).map((setting, index) => (
                <SettingItem
                  settingName={setting}
                  item={generalSettingsObj[setting]}
                  classes={classes}
                  key={index}
                  drProfileSetting={
                    setting === 'pediatric' ? 'listConfig' : 'general'
                  }
                  allowedToEdit={allowedToEdit && setting !== 'officeName'}
                  timezone={
                    office &&
                    office.officeInformation &&
                    office.officeInformation.timezone
                  }
                />
              ))}
          </div>
        </Paper>
      </div>
    );
  };

  const isBoolean = val => {
    return typeof val === 'boolean';
  };

  const renderRecallConfigurationSettings = () => {
    let recallConfigurationObj = {
      ageParams: {
        maxAge: '-',
        minAge: '-'
      },
      balance: '-',
      filterMedicaid: '-',
      location:
        office && office.officeInformation && office.officeInformation.name,
      timeOverdue: {
        maxMonths: '-',
        minMonths: '-'
      }
    };

    if (drProfile && drProfile.listConfig) {
      recallConfigurationObj = {
        ...recallConfigurationObj,
        ...JSON.parse(JSON.stringify(drProfile.listConfig))
      };
      recallConfigurationObj.filterMedicaid = isBoolean(
        recallConfigurationObj.filterMedicaid
      )
        ? recallConfigurationObj.filterMedicaid.toString()
        : '-';
      recallConfigurationObj.location = recallConfigurationObj.location
        ? recallConfigurationObj.location
        : '" "';
      if (recallConfigurationObj) delete recallConfigurationObj.pediatric;
      if (recallConfigurationObj.balance === 0)
        recallConfigurationObj.balance = '-';
    }

    return (
      <div>
        <Typography variant='caption' className={classes.sectionTitle}>
          Recall Configuration
        </Typography>

        <Paper elevation={2}>
          <div className={classes.sectionContainer}>
            {recallConfigurationObj &&
              Object.keys(recallConfigurationObj).map((setting, index) => (
                <SettingItem
                  settingName={setting}
                  item={recallConfigurationObj[setting]}
                  classes={classes}
                  key={index}
                  drProfileSetting='listConfig'
                  allowedToEdit={allowedToEdit}
                />
              ))}
          </div>
        </Paper>
      </div>
    );
  };

  return (
    <div className={classes.settingsContainer}>
      {renderGeneralSettings()}
      {renderRecallConfigurationSettings()}
      <DrProfileSettingEdit
        classes={classes}
        drProfile={drProfile}
        location={
          office && office.officeInformation && office.officeInformation.name
        }
      />
      <DialogEditOfficeHours office={office && office.id} />
    </div>
  );
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(Settings);
