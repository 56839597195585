import _ from 'lodash';
import React, { Component } from 'react';
import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { styles } from './styles';

class HeaderTitleWithTabs extends Component {
  state = { tabValue: 0 };

  UNSAFE_componentWillMount() {
    this.handleTabValueChange(this.props.tabId);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { tabId } = this.props;

    if (!_.isEqual(tabId, nextProps.tabId)) {
      this.handleTabValueChange(nextProps.tabId);
    }
  }

  componentDidMount() {
    document.getElementById('main').scrollTop = 0;
  }

  handleChange = (event, value) => {
    const { tabs } = this.props;
    const tabName = _.mapKeys(tabs, 'index')[value].name;
    const handleChangePath = _.mapKeys(tabs, 'index')[
      value
    ].handleChangePath.replace('$tabName', tabName);

    this.props.history.push(handleChangePath);
  };

  handleTabValueChange(tabId) {
    const { tabs } = this.props;

    this.setState({
      tabValue: tabId
        ? _.mapKeys(tabs, 'name')[tabId]
          ? _.mapKeys(tabs, 'name')[tabId].index
          : 0
        : 0
    });
  }

  renderTabs() {
    const { classes, tabs } = this.props;

    return tabs.map(tab => (
      <Tab
        key={tab.name}
        label={tab.title}
        className={classes.headerTab}
        disableRipple={true}
      />
    ));
  }

  render() {
    const { classes, largeAppBar, renderTitle, tabs } = this.props;
    const { tabValue } = this.state;

    return (
      <div className={classes.root}>
        {renderTitle()}
        <AppBar
          position='static'
          className={largeAppBar ? classes.tabBarLarge : classes.tabBar}
        >
          <Tabs
            value={tabValue}
            onChange={this.handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
            variant='scrollable'
            scrollButtons='off'
          >
            {this.renderTabs()}
            <Tab key={null} label={null} disabled={true} />
          </Tabs>
        </AppBar>
        <div className={classes.headerPadding}></div>
        <div className={largeAppBar ? classes.bodyAppBarLarge : classes.body}>
          {_.mapKeys(tabs, 'index')[tabValue].renderComponent()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, {}),
  withStyles(styles, { withTheme: true })
)(HeaderTitleWithTabs);
