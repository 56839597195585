import {
  DIALOG_BILLING_ITEM_CLICK,
  DIALOG_BILLING_ITEM,
  DIALOG_BILLING_ITEM_STATUS_CHANGE
} from './types';

export const dialogBillingItemClick = status => ({
  type: DIALOG_BILLING_ITEM_CLICK,
  payload: status
});

export const dialogBillingItem = item => ({
  type: DIALOG_BILLING_ITEM,
  payload: item
});

export const dialogBillingStatusChange = changeOccurred => ({
  type: DIALOG_BILLING_ITEM_STATUS_CHANGE,
  payload: changeOccurred
});
