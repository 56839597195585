import _ from 'lodash';
import React, { Component } from 'react';
import AscIcon from '@material-ui/icons/ArrowDownward';
import ChangeIcon from '@material-ui/icons/SwapVert';
import DescIcon from '@material-ui/icons/ArrowUpward';
import EmployeesStatsTable from './EmployeesStatsTable';
import EntryTypePopover from './EntryTypePopover';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { entryTypes } from '../services/options';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

const statGroups = ['scheduled', 'callsAnswered', 'teamLead', 'rescheduled'];

class QuickIndiStats extends Component {
  state = {
    anchorEl: null,
    openPopover: false,
    entryCode: 'scheduled',
    orderByValue: [
      `stats.monthly[${moment(new Date()).format('YYYY-MM')}]`,
      `firstName`
    ],
    orderByOrder: ['desc', 'asc']
  };

  handleEntryTypeClick = () => event => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      openPopover: !this.state.openPopover
    });
  };

  handleClickEntry = code => {
    this.handleClose();
    this.setState({ entryCode: code });
  };

  handleClose() {
    this.setState({ openPopover: false });
  }

  getOrderArr(key, firstDefaultOrder) {
    const { orderByValue, orderByOrder } = this.state;

    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, 'asc'];
  }

  getValArr(key, format) {
    return [`stats[${key}][${moment(new Date()).format(format)}]`, `firstName`];
  }

  changeSort(key) {
    switch (key) {
      case 'firstName':
        this.setState({
          orderByValue: [`firstName`, `lastName`],
          orderByOrder: this.getOrderArr(key, 'asc')
        });
        break;
      case 'daily':
        this.setState({
          orderByValue: this.getValArr(key, 'YYYY-MM-DD'),
          orderByOrder: this.getOrderArr(key, 'desc')
        });
        break;
      case 'monthly':
        this.setState({
          orderByValue: this.getValArr(key, 'YYYY-MM'),
          orderByOrder: this.getOrderArr(key, 'desc')
        });
        break;
      default:
        this.setState({
          orderByValue: this.getValArr('monthly', 'YYYY-MM-DD'),
          orderByOrder: ['desc', 'asc']
        });
    }
  }

  renderSortIcon(key) {
    const { classes } = this.props;
    const { orderByValue, orderByOrder } = this.state;

    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  }

  renderEmployeeStatsHeaderRow() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.headerRow}>
        <Grid
          item
          xs={6}
          style={{ padding: 0, justifyContent: 'flex-start' }}
          onClick={() => this.changeSort('firstName')}
        >
          <Typography variant='button'>Employee</Typography>
          {this.renderSortIcon('firstName')}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: 0 }}
          onClick={() => this.changeSort('daily')}
        >
          <Typography variant='button'>Today</Typography>
          {this.renderSortIcon('daily')}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: 0, paddingLeft: 12 }}
          onClick={() => this.changeSort('monthly')}
        >
          <Typography variant='button'>Month</Typography>
          {this.renderSortIcon('monthly')}
        </Grid>
      </Grid>
    );
  }

  renderEmployeeStatsTable() {
    const { employees, mistakes } = this.props;
    const { entryCode, orderByOrder, orderByValue } = this.state;

    if (employees) {
      return (
        <EmployeesStatsTable
          mistakes={mistakes}
          employees={employees}
          collection={entryCode}
          orderByOrder={orderByOrder}
          orderByValue={orderByValue}
        />
      );
    }

    return null;
  }

  renderTypeSwitcherContainer() {
    const { classes, teamManagement } = this.props;
    const { anchorEl, entryCode, openPopover } = this.state;

    return (
      <div className={classes.entryTypeSwitcherContainer}>
        <Typography
          variant='button'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {_.mapKeys(entryTypes, 'code')[entryCode].icon(18, 5)}
          {_.mapKeys(entryTypes, 'code')[entryCode].text}
        </Typography>
        <Tooltip title='Select Entry Type'>
          <IconButton
            color='inherit'
            className={classes.entryTypeButton}
            aria-label='Select Entry Type'
            onClick={this.handleEntryTypeClick()}
          >
            <ChangeIcon style={styles.icon} />
          </IconButton>
        </Tooltip>
        <EntryTypePopover
          open={openPopover}
          anchorEl={anchorEl}
          handleClose={() => this.handleClose()}
          handleClickEntry={this.handleClickEntry.bind(this)}
          teams={teamManagement}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.renderTypeSwitcherContainer()}
        {this.renderEmployeeStatsHeaderRow()}
        {this.renderEmployeeStatsTable()}
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 50
    }
  },
  sortIcon: {
    marginRight: 3,
    marginBottom: 2,
    fontSize: 12
  },
  entryTypeSwitcherContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  icon: {
    color: theme.palette.secondary.main
  },
  titleContainer: {
    padding: theme.spacing.unit * 2
  },
  headerRow: {
    width: '90%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    }
  }
});

const mapStateToProps = (state, props) => {
  const employeesData = state.firestore.ordered['activeEmployees'];
  let employees = [];

  if (props.teamManagement) {
    const orderedData = state.firestore.ordered;

    for (let key in orderedData) {
      let keyArr = key.split('-');
      let keyName = keyArr[0];
      let team = keyArr[1];
      let member = keyArr[2];

      if (keyName === 'teamEmp' && team === props.teamDetails.id) {
        if (isLoaded(orderedData[`teamEmp-${team}-${member}`])) {
          employees.push(orderedData[`teamEmp-${team}-${member}`][0]);
        }
      }
    }
  } else {
    if (isLoaded(employeesData) && !isEmpty(employeesData)) {
      employees = _.filter(employeesData, o =>
        o.groups.some(r => statGroups.indexOf(r) >= 0)
      );
    }
  }

  return { employees };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    let queries = [];

    if (props.teamManagement) {
      let team = props.teamDetails;

      if (team && team.members) {
        team.members.forEach(member => {
          queries.push({
            collection: 'employees',
            doc: member,
            storeAs: `teamEmp-${props.teamDetails.id}-${member}`
          });
        });
      }
    } else {
      queries.push({
        collection: 'employees',
        where: [['active', '==', true]],
        storeAs: 'activeEmployees'
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(QuickIndiStats);
