import _ from 'lodash';
import React, { Component } from 'react';
import PricingPlanDetails from '../../../../../../../../components/Billing/PricingPlanDetails';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditPricingPlanClick,
  dialogEditPricingPlanItem
} from '../../../../../../../../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';

class OfferingPricingPlan extends Component {
  state = { notSubscribedOpen: false, planName: null };

  async componentDidMount() {
    const { firestore, pricing, offering } = this.props;
    let planName;

    try {
      if (offering) {
        const doc = await firestore.get({
          collection: 'offerings',
          doc: offering.id
        });
        const offeringData = { ...doc.data(), id: doc.id };

        if (t(offeringData, `pricing.${pricing.plan}.name`).safeString) {
          planName = `${offeringData.pricing[pricing.plan].name.replace(
            ' Plan',
            ''
          )} Plan`;
        }
      }

      if (!planName) {
        planName = `${_.startCase(pricing.plan)} Plan`;
      }

      this.setState({ planName });
    } catch (err) {
      console.log(err);
    }
  }

  changePricingView = () => {
    this.setState({ notSubscribedOpen: !this.state.notSubscribedOpen });
  };

  renderNotSubscribedButton() {
    const { classes, subscribed } = this.props;
    const { notSubscribedOpen } = this.state;

    if (!subscribed) {
      return (
        <span
          onClick={this.changePricingView}
          className={classes.notSubscribedButton}
        >
          {notSubscribedOpen ? 'Hide' : 'Show'}
        </span>
      );
    }

    return null;
  }

  renderEditButton() {
    const { classes, office, offering, pricing, subscribed } = this.props;

    if (subscribed) {
      return (
        <span
          onClick={() => {
            this.props.dialogEditPricingPlanClick(true);
            this.props.dialogEditPricingPlanItem({
              office,
              offering,
              pricing
            });
          }}
          className={classes.editButton}
        >
          Edit
        </span>
      );
    }

    return null;
  }

  render() {
    const { classes, office, offering, pricing, subscribed } = this.props;
    const { notSubscribedOpen, planName } = this.state;

    return (
      <div>
        <div className={classes.planTitleContainer}>
          <div
            className={subscribed ? classes.planTitle : classes.notSubPlanTitle}
            onClick={() => this.changePricingView()}
          >
            {planName ? planName : null}
          </div>
          {this.renderNotSubscribedButton()}
        </div>
        <span
          style={!subscribed && !notSubscribedOpen ? { display: 'none' } : {}}
        >
          <PricingPlanDetails
            office={office}
            pricingDetails={
              pricing.plan === 'custom'
                ? pricing.pricing
                : offering.pricing[pricing.plan]
            }
          />
        </span>
        {this.renderEditButton()}
      </div>
    );
  }
}

export default compose(
  connect(null, {
    dialogEditPricingPlanClick,
    dialogEditPricingPlanItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(OfferingPricingPlan);
