export const styles = theme => ({
  dialogBody: {
    maxWidth: '1000px',
    margin: '0 auto',
    marginBottom: '20px',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    width: '100%'
  },
  dialogTitle: {
    color: theme.palette.primary.main
  },
  stepperContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileStepperContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  mobileStepper: {
    maxWidth: 400,
    flexGrow: 1,
    backgroundColor: 'unset',
    alignItems: 'center',
    '& > div': {
      margin: '0px auto'
    }
  },
  sectionActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textTransform: 'uppercase',
    '& > h6': {
      color: theme.palette.secondary.main
    },
    '& > span': {
      fontSize: 10,
      marginLeft: 5,
      fontStyle: 'italic',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  name: {
    '& > div': {
      width: '100%'
    }
  },
  officeRow: {
    display: 'flex',
    width: '100%',
    '& > div': {
      marignLeft: 8,
      marginRight: 8
    },
    '& > div:first-child': {
      marignLeft: 0
    },
    '& > div:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        display: 'block',
        marginRight: 0,
        marginLeft: 0,
        width: '100%'
      },
      '& > div > div': {
        width: '100%'
      },
      '& > div:last-child > div': {
        width: 'calc(100% - 14px)'
      },
      '& > label': {
        width: '100%',
        marginTop: 8,
        marginLeft: 0
      }
    }
  },
  office: {
    padding: '0 5px 0 5px',
    marginTop: '16px',
    marginBottom: '8px'
  }
});
