import {
  RESCHEDULED_ADD,
  RESCHEDULED_DELETE,
  RESCHEDULED_EDIT,
  RESCHEDULED_INITIALIZE,
  RESCHEDULED_SEARCH
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case RESCHEDULED_ADD:
      return action.payload;
    case RESCHEDULED_DELETE:
      return action.payload;
    case RESCHEDULED_EDIT:
      return action.payload;
    case RESCHEDULED_INITIALIZE:
      return action.payload;
    case RESCHEDULED_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
