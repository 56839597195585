import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { t } from 'typy';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DialogEditPricingPlan from './components/DialogEdit/PricingPlan';
import DialogEditOfferingTimeActiveEntry from './components/DialogEdit/OfferingTimeActiveEntry';
import Pricing from './components/Pricing';
import Payment from './components/Payment';
import SubscriptionHistory from './components/SubscriptionHistory';
import { styles } from './styles';

class Billing extends Component {
  componentDidMount() {
    document.getElementById('main').scrollTop = 0;
  }

  componentWillUnmount() {
    document.getElementById('main').scrollTop = 0;
  }

  renderPricing() {
    const { classes, office } = this.props;
    return (
      <div>
        <Typography variant='caption' className={classes.sectionTitle}>
          Pricing
        </Typography>
        <Paper elevation={2}>
          <div className={classes.sectionContainer}>
            {office &&
              Object.keys(office.offeringsPricing).map(key => (
                <Pricing
                  key={key}
                  office={office}
                  offeringId={key}
                  pricing={office.offeringsPricing[key]}
                />
              ))}
          </div>
        </Paper>
      </div>
    );
  }

  renderPaymentInfo() {
    const { classes, office } = this.props;
    return (
      <div>
        <Typography variant='caption' className={classes.sectionTitle}>
          Payment Information
        </Typography>
        <Paper elevation={2}>
          <div className={classes.sectionContainer}>
            {office && <Payment office={office} />}
          </div>
        </Paper>
      </div>
    );
  }

  renderSubscriptionHistory() {
    const { classes, office } = this.props;

    return (
      <div>
        <Typography variant='caption' className={classes.sectionTitle}>
          Subscription History
        </Typography>
        <Paper elevation={2}>
          <div className={classes.sectionContainer}>
            {t(office, 'offeringsTimeActive').safeObject &&
              Object.keys(office.offeringsTimeActive).map(key =>
                office.offeringsTimeActive[key].length > 0 ? (
                  <SubscriptionHistory
                    key={key}
                    office={office}
                    offeringId={key}
                    offeringTimeActive={office.offeringsTimeActive[key]}
                  />
                ) : null
              )}
          </div>
        </Paper>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.billingContainer}>
        {this.renderPricing()}
        {this.renderPaymentInfo()}
        {this.renderSubscriptionHistory()}
        <DialogEditPricingPlan />
        <DialogEditOfferingTimeActiveEntry />
      </div>
    );
  }
}

export default compose(
  connect(null),
  withStyles(styles, { withTheme: true })
)(Billing);
