import _ from 'lodash';
import React, { Component } from 'react';
import AscIcon from '@material-ui/icons/ArrowDownward';
import ChangeIcon from '@material-ui/icons/SwapVert';
import DescIcon from '@material-ui/icons/ArrowUpward';
import EmployeeMistakesTable from './EmployeeMistakesTable';
import EntryTypePopover from './EntryTypePopover';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { entryTypes } from '../services/options';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class PersonalMistakesDetail extends Component {
  state = {
    anchorEl: null,
    openPopover: false,
    entryCode:
      this.props.profile && this.props.profile.groups
        ? `${
            this.props.profile.groups.includes('callsAnswered') &&
            !this.props.profile.groups.includes('teamLead')
              ? 'callsAnswered'
              : 'scheduled'
          }`
        : null,
    orderByValue: ['date', 'category'],
    orderByOrder: ['desc', 'asc']
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.entryCode === null && nextProps.profile.groups) {
      this.setState({
        entryCode: `${
          nextProps.profile.groups.includes('callsAnswered') &&
          !nextProps.profile.groups.includes('teamLead')
            ? 'callsAnswered'
            : 'scheduled'
        }`
      });
    }
  }

  handleEntryTypeClick = () => event => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      openPopover: !this.state.openPopover
    });
  };

  handleClickEntry = code => {
    this.handleClose();
    this.setState({ entryCode: code });
  };

  handleClose() {
    this.setState({ openPopover: false });
  }

  getOrderArr(key, firstDefaultOrder, secondDefaultOrder) {
    const { orderByValue, orderByOrder } = this.state;

    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, secondDefaultOrder];
  }

  changeSort(key) {
    switch (key) {
      case 'date':
        this.setState({
          orderByValue: [`date`, `category`],
          orderByOrder: this.getOrderArr(key, 'desc', 'asc')
        });
        break;
      case 'category':
        this.setState({
          orderByValue: [`category`, `date`],
          orderByOrder: this.getOrderArr(key, 'asc', 'desc')
        });
        break;
      default:
        this.setState({
          orderByValue: [`date`, `cateogry`],
          orderByOrder: ['desc', 'asc']
        });
    }
  }

  renderSortIcon(key) {
    const { classes } = this.props;
    const { orderByValue, orderByOrder } = this.state;

    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }

    return null;
  }

  renderPersonalMistakesHeaderRow() {
    const { classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.headerRow}>
        <Grid item xs={3} style={{ padding: 0, justifyContent: 'flex-start' }}>
          <Typography variant='button'>Details</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ padding: 0 }}
          onClick={() => this.changeSort('date')}
        >
          <Typography variant='button'>Date</Typography>
          {this.renderSortIcon('date')}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: 0 }}
          onClick={() => this.changeSort('category')}
        >
          <Typography variant='button'>Category</Typography>
          {this.renderSortIcon('category')}
        </Grid>
        <Grid item xs={4} style={{ padding: 0, paddingLeft: 12 }}>
          <Typography variant='button'>Notes</Typography>
        </Grid>
      </Grid>
    );
  }

  renderPersonalMistakesTable() {
    const { entryCode, orderByOrder, orderByValue } = this.state;
    const { auth } = this.props;

    if (entryCode) {
      return (
        <EmployeeMistakesTable
          auth={auth}
          collection={entryCode}
          orderByValue={orderByValue}
          orderByOrder={orderByOrder}
        />
      );
    }
  }

  renderTypeSwitcherContainer() {
    const { classes, profile } = this.props;
    const { anchorEl, entryCode, openPopover } = this.state;

    return (
      <div className={classes.entryTypeSwitcherContainer}>
        <Typography
          variant='button'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {entryCode && _.mapKeys(entryTypes, 'code')[entryCode].icon(18, 5)}
          {entryCode && _.mapKeys(entryTypes, 'code')[entryCode].text}
        </Typography>
        <Tooltip title='Select Entry Type'>
          <IconButton
            color='inherit'
            className={classes.entryTypeButton}
            aria-label='Select Entry Type'
            onClick={this.handleEntryTypeClick()}
          >
            <ChangeIcon style={styles.icon} />
          </IconButton>
        </Tooltip>
        <EntryTypePopover
          open={openPopover}
          anchorEl={anchorEl}
          handleClose={() => this.handleClose()}
          handleClickEntry={this.handleClickEntry.bind(this)}
          personal={true}
          profile={profile}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.renderTypeSwitcherContainer()}
        {this.renderPersonalMistakesHeaderRow()}
        {this.renderPersonalMistakesTable()}
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 50
    }
  },
  sortIcon: {
    marginRight: 3,
    marginBottom: 2,
    fontSize: 12
  },
  entryTypeSwitcherContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  icon: {
    color: theme.palette.secondary.main
  },
  titleContainer: {
    padding: theme.spacing.unit * 2
  },
  headerRow: {
    width: '90%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    }
  }
});

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(PersonalMistakesDetail);
