/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

const DecoratedTextField = ({
  classes,
  field,
  form,
  oniconclick,
  disabler,
  id,
  label,
  Icon,
  ...props
}) => {
  const { errors, touched } = form;

  return (
    <FormControl className={classes.textField} variant='outlined'>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        {...field}
        {...props}
        type='text'
        disabled={!(disabler === 'true')}
        error={!!(touched[field.name] && errors[field.name])}
        labelWidth={label.length * 7.75}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              onClick={() => {
                oniconclick();
              }}
              onMouseDown={() => {}}
              edge='end'
            >
              <Icon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

DecoratedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(DecoratedTextField);
