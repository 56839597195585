import {
  CALLS_ANSWERED_ADD,
  CALLS_ANSWERED_DELETE,
  CALLS_ANSWERED_EDIT,
  CALLS_ANSWERED_INITIALIZE,
  CALLS_ANSWERED_SEARCH
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case CALLS_ANSWERED_ADD:
      return action.payload;
    case CALLS_ANSWERED_DELETE:
      return action.payload;
    case CALLS_ANSWERED_EDIT:
      return action.payload;
    case CALLS_ANSWERED_INITIALIZE:
      return action.payload;
    case CALLS_ANSWERED_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
