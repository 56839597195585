import { DIALOG_MISTAKE_CLICK, DIALOG_MISTAKE_ITEM } from '../actions/types';

export const dialogMistakeItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_MISTAKE_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogMistakeOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_MISTAKE_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogMistakeReducer = {
  dialogMistakeItem,
  dialogMistakeOpen
};
