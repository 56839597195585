import React, { useEffect, useState, useContext } from 'react';
import { Typography, withStyles, Paper, Card } from '@material-ui/core';
import CountUp from 'react-countup';
import { AppContext } from '../../../../contexts';
import TeamsTable from '../../components/TeamsTable';
import PropTypes from 'prop-types';
import { styles } from '../../styles';

const TeamLists = ({ classes, offering, collection }) => {
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [teamsRetrieved, setTeamsRetrieved] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [updatedTeams, setUpdatedTeams] = useState([]);
  const [tilesUpdated, setTilesUpdated] = useState(false);
  const [assignedOffices, setAssignedOffices] = useState(0);
  const [officesCalled, setOfficesCalled] = useState(0);
  const [overdueOffices, setOverdueOffices] = useState(0);
  const [newOffices, setNewOffices] = useState(0);
  const {
    state: { firebase }
  } = useContext(AppContext);

  useEffect(() => {
    const getTeams = async () => {
      await firebase
        .firestore()
        .collection('teams')
        .where('serviceOffering', 'array-contains', offering)
        .get()
        .then(snapshots => {
          const teamDocs = [];
          snapshots.forEach(doc => {
            teamDocs.push({ ...doc.data(), id: doc.id });
          });
          if (!teamDocs[0]) {
            setLoading(false);
          }
          setTeams(teamDocs);
          setRowsCount(teamDocs.length);
          setTeamsRetrieved(true);
        });
    };

    if (!teamsRetrieved) {
      getTeams();
    }
  }, [firebase, teamsRetrieved, offering]);

  useEffect(() => {
    const updateTeam = async team => {
      try {
        const teamOfficeDocs = [];
        await firebase
          .firestore()
          .collection('clients')
          .where(`teams.${offering}`, 'array-contains', team.id)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              teamOfficeDocs.push({ ...doc.data(), id: doc.id });
            });
          });

        const updateOffice = async office => {
          try {
            const scheduledOrNotScheduled = [];
            let lastContact;
            await firebase
              .firestore()
              .collection(collection)
              .where('office', '==', `${office.id}`)
              .orderBy('createdTime', 'desc')
              .limit(1)
              .get()
              .then(snapshot => {
                snapshot.forEach(doc => {
                  scheduledOrNotScheduled.push({ ...doc.data(), id: doc.id });
                });
              });
            await firebase
              .firestore()
              .collection('notScheduled')
              .where('office', '==', `${office.id}`)
              .where('entryType', '==', team.serviceOffering[0])
              .orderBy('createdTime', 'desc')
              .limit(1)
              .get()
              .then(snapshot => {
                snapshot.forEach(doc => {
                  scheduledOrNotScheduled.push({ ...doc.data(), id: doc.id });
                });
              });

            if (scheduledOrNotScheduled?.length > 1) {
              if (
                scheduledOrNotScheduled[0].createdTime.seconds >
                scheduledOrNotScheduled[1].createdTime.seconds
              ) {
                lastContact = scheduledOrNotScheduled[0].createdTime;
              } else {
                lastContact = scheduledOrNotScheduled[1].createdTime;
              }
            } else if (!scheduledOrNotScheduled[0]) {
              lastContact = { seconds: 0 };
            } else {
              lastContact = scheduledOrNotScheduled[0].createdTime;
            }

            office.lastContact = lastContact;
            return office;
          } catch (e) {
            console.error(e);
          }
        };

        const promises = [];
        teamOfficeDocs.forEach(o => {
          promises.push(updateOffice(o));
        });

        const updatedTeam = {
          ...team,
          teamOffices: await Promise.all(promises)
        };

        return updatedTeam;
      } catch (e) {
        console.error(e);
      }
    };

    const getTeamStats = async team => {
      const monthStart = Date.parse(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      const twoWeeksPrior = new Date() - 12096e5;

      let calledOffices = 0;
      let overdueOffices = 0;
      let newOffices = 0;
      if (team.teamOffices.length > 0) {
        team.teamOffices.forEach((o, i) => {
          const { seconds } = o.lastContact;
          if (seconds * 1000 > monthStart) {
            calledOffices++;
          }
          if (seconds * 1000 - twoWeeksPrior < 0) {
            overdueOffices++;
          }
          if (!seconds) {
            newOffices++;
          }
        });
      }

      const teamWithStats = {
        ...team,
        calledOffices: calledOffices,
        overdueOffices: overdueOffices,
        newOffices: newOffices
      };

      return teamWithStats;
    };

    const updated = [];
    teams.forEach(t => {
      updateTeam(t)
        .then(res => getTeamStats(res))
        .then(res => {
          updated.push(res);
          if (updated.length === teams.length) {
            setUpdatedTeams(updated);
            setLoading(false);
          }
        });
    });
  }, [firebase, teams, collection, offering]);

  useEffect(() => {
    const monthStart = Date.parse(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
    const twoWeeksPrior = new Date() - 12096e5;
    let allOffices = [];
    let totalCalledOffices = 0;
    let totalOverdueOffices = 0;
    let totalNewOffices = 0;
    const updateTiles = () => {
      updatedTeams.forEach(t => {
        if (t.teamOffices[0]) {
          t.teamOffices.map(o => {
            if (!allOffices.includes(o.id)) {
              allOffices.push(o.id);

              if (o.lastContact.seconds * 1000 > monthStart) {
                totalCalledOffices++;
              }
              if (o.lastContact.seconds * 1000 - twoWeeksPrior < 0) {
                totalOverdueOffices++;
              }
              if (o.lastContact.seconds === 0) {
                totalNewOffices++;
              }
            }
            return true;
          });
        }
      });
      setAssignedOffices(allOffices.length);
      setOfficesCalled(totalCalledOffices);
      setOverdueOffices(totalOverdueOffices);
      setNewOffices(totalNewOffices);
    };

    if (!tilesUpdated && updatedTeams[0]) {
      updateTiles();
      setTilesUpdated(true);
    }
  }, [updatedTeams, tilesUpdated]);

  const renderTiles = () => {
    return (
      <div className={classes.tiles}>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={officesCalled}
                separator=','
                decimals={0}
              />
              /
              <CountUp
                duration={1}
                end={assignedOffices}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Offices Called
          </Typography>
        </Paper>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={overdueOffices}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Overdue Offices
          </Typography>
        </Paper>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={newOffices}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            New Offices
          </Typography>
        </Paper>
      </div>
    );
  };

  return (
    <>
      {renderTiles()}
      <TeamsTable
        loading={loading}
        rowsCount={rowsCount}
        setRowsCount={setRowsCount}
        updatedTeams={updatedTeams}
      />
    </>
  );
};

TeamLists.propTypes = {
  classes: PropTypes.object.isRequired,
  offering: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(TeamLists);
