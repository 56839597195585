import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import { styles } from './styles';
import ReloInfoData from './ReloInfoData';
import Loader from 'react-loader-spinner';
import DialogEditReloInfo from './DialogEditReloInfo';

const ReloInformation = ({ classes, reloData, allowedToEdit }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  return (
    <div className={classes.reloInfoRoot}>
      <div className={classes.dataRow}>
        <div className={classes.dataTitle}>Patient Info</div>
      </div>
      {reloData.patientName ? (
        <>
          <ReloInfoData
            classes={classes}
            data={reloData}
            label='UID'
            name='id'
            showEdit={false}
          />
          <ReloInfoData
            classes={classes}
            data={reloData}
            label='Name'
            name='patientName'
            showEdit={true}
            setOpen={setOpen}
            setItem={setItem}
          />
          <ReloInfoData
            classes={classes}
            data={reloData}
            label='Office'
            name='officeName'
            showEdit={allowedToEdit(reloData)}
            setOpen={setOpen}
            setItem={setItem}
          />
          <ReloInfoData
            classes={classes}
            data={reloData}
            label='Patient Phone'
            name='patientPhone'
            showEdit={true}
            setOpen={setOpen}
            setItem={setItem}
          />
          <ReloInfoData
            classes={classes}
            data={reloData}
            label='Appointment Type'
            name='appointmentType'
            showEdit={true}
            setOpen={setOpen}
            setItem={setItem}
          />
          <DialogEditReloInfo
            classes={classes}
            relo={reloData}
            item={item}
            open={open}
            setOpen={setOpen}
          />
        </>
      ) : (
        <>
          <div className={classes.loadingContainerCard}>
            <Loader type='Oval' color='#696969' height='75' width='75' />
            <Typography className={classes.loadingMessageCard} variant='body1'>
              Loading RELO Info
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

const mapstateToProps = (state, { relo }) => {
  let reloData = {
    patientName: relo ? relo.patientName : null,
    patientPhone: relo ? relo.patientPhone : null,
    officeName: relo ? relo.officeName : null,
    appointmentType:
      relo && relo.appointmentType
        ? relo.appointmentType.charAt(0).toUpperCase() +
          relo.appointmentType.slice(1)
        : null,
    createdTime: relo ? relo.createdTime : null,
    id: relo ? relo.id : null
  };

  return { reloData };
};

export default compose(
  connect(mapstateToProps),
  withStyles(styles, { withTheme: true })
)(ReloInformation);
