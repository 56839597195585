import {
  SEARCH_BAR_CLEAR,
  SEARCH_RUNNING,
  SEARCH_STATS,
  SEARCH_ACTIVATE,
  SEARCH_RESULTS_UPDATE,
  SEARCH_TERM_UPDATE
} from '../actions/types';

const searchBarClear = (state = null, action) => {
  switch (action.type) {
    case SEARCH_BAR_CLEAR:
      return action.payload;
    default:
      return state;
  }
};

const searchRunning = (state = null, action) => {
  switch (action.type) {
    case SEARCH_RUNNING:
      return action.payload;
    default:
      return state;
  }
};

const searchStats = (state = null, action) => {
  switch (action.type) {
    case SEARCH_STATS:
      return action.payload;
    default:
      return state;
  }
};

const searchActive = (state = false, action) => {
  switch (action.type) {
    case SEARCH_ACTIVATE:
      return action.payload;
    default:
      return state;
  }
};

const searchResults = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RESULTS_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

const searchTerm = (state = null, action) => {
  switch (action.type) {
    case SEARCH_TERM_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const SearchReducer = {
  searchBarClear,
  searchRunning,
  searchStats,
  searchActive,
  searchResults,
  searchTerm
};
