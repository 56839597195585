export const AUTH_IS_ADMIN = 'AUTH_IS_ADMIN';
export const AUTH_IS_CALLER = 'AUTH_IS_CALLER';
export const AUTH_IS_CALLER_SCHEDULED = 'AUTH_IS_CALLER_SCHEDULED';
export const AUTH_IS_CALLER_CALLS_ANSWERED = 'AUTH_IS_CALLER_CALLS_ANSWERED';
export const AUTH_IS_CALLER_RESCHEDULED = 'AUTH_IS_CALLER_RESCHEDULED';
export const AUTH_IS_CALLING_MANAGER = 'AUTH_IS_CALLING_MANAGER';
export const AUTH_IS_CUSTOMER_MANAGER = 'AUTH_IS_CUSTOMER_MANAGER';
export const AUTH_IS_FINANCIAL_CONTROLLER = 'AUTH_IS_FINANCIAL_CONTROLLER';
export const AUTH_IS_ONBOARDER = 'AUTH_IS_ONBOARDER';
export const AUTH_IS_TEAM_LEAD = 'AUTH_IS_TEAM_LEAD';
export const AUTH_IS_TECHNICAL_SUPPORT = 'AUTH_IS_TECHNICAL_SUPPORT';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const CALLS_ANSWERED_ADD = 'CALLS_ANSWERED_ADD';
export const CALLS_ANSWERED_DELETE = 'CALLS_ANSWERED_DELETE';
export const CALLS_ANSWERED_EDIT = 'CALLS_ANSWERED_EDIT';
export const CALLS_ANSWERED_INITIALIZE = 'CALLS_ANSWERED_INITIALIZE';
export const CALLS_ANSWERED_SEARCH = 'CALLS_ANSWERED_SEARCH';
export const CLIENTS_ADD = 'CLIENT_ADD';
export const CLIENTS_DELETE = 'CLIENT_DELETE';
export const CLIENTS_EDIT = 'CLIENT_EDIT';
export const CLIENTS_INITIALIZE = 'CLIENT_INITIALIZE';
export const CLIENTS_SEARCH = 'CLIENT_SEARCH';
export const DIALOG_ADD_EDIT_SCHEDULED_CLICK =
  'DIALOG_ADD_EDIT_SCHEDULED_CLICK';
export const DIALOG_ADD_EDIT_NOTSCHEDULED_CLICK =
  'DIALOG_ADD_EDIT_NOTSCHEDULED_CLICK';
export const DIALOG_ADD_EDIT_RESCHEDULED_CLICK =
  'DIALOG_ADD_EDIT_RESCHEDULED_CLICK';
export const DIALOG_ADD_EDIT_CALLSANSWERED_CLICK =
  'DIALOG_ADD_EDIT_CALLSANSWERED_CLICK';
export const DIALOG_ADD_EDIT_SCHEDULED_ITEM = 'DIALOG_ADD_EDIT_SCHEDULED_ITEM';
export const DIALOG_ADD_EDIT_NOTSCHEDULED_ITEM =
  'DIALOG_ADD_EDIT_NOTSCHEDULED_ITEM';
export const DIALOG_ADD_EDIT_RESCHEDULED_ITEM =
  'DIALOG_ADD_EDIT_RESCHEDULED_ITEM';
export const DIALOG_ADD_EDIT_CALLSANSWERED_ITEM =
  'DIALOG_ADD_EDIT_CALLSANSWERED_ITEM';
export const DIALOG_ADD_EDIT_OFFICE_USER_CLICK =
  'DIALOG_ADD_EDIT_OFFICE_USER_CLICK';
export const DIALOG_ADD_EDIT_OFFICE_USER_ITEM =
  'DIALOG_ADD_EDIT_OFFICE_USER_ITEM';
export const DIALOG_BILLING_ITEM_CLICK = 'DIALOG_BILLING_ITEM_CLICK';
export const DIALOG_BILLING_ITEM = 'DIALOG_BILLING_ITEM';
export const DIALOG_BILLING_ITEM_STATUS_CHANGE =
  'DIALOG_BILLING_ITEM_STATUS_CHANGE';
export const DIALOG_DO_NOT_BILL_CLICK = 'DIALOG_DO_NOT_BILL_CLICK';
export const DIALOG_DO_NOT_BILL_ITEM = 'DIALOG_DO_NOT_BILL_ITEM';
export const DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK =
  'DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_CLICK';
export const DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM =
  'DIALOG_EDIT_OFFERING_TIME_ACTIVE_ENTRY_ITEM';
export const DIALOG_EDIT_OFFICE_ADDRESS_CLICK =
  'DIALOG_EDIT_OFFICE_ADDRESS_CLICK';
export const DIALOG_EDIT_OFFICE_ADDRESS_ITEM =
  'DIALOG_EDIT_OFFICE_ADDRESS_ITEM';
export const DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK =
  'DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK';
export const DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM =
  'DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM';
export const DIALOG_EDIT_OFFICE_HOURS_CLICK = 'DIALOG_EDIT_OFFICE_HOURS_CLICK';
export const DIALOG_EDIT_OFFICE_HOURS_ITEM = 'DIALOG_EDIT_OFFICE_HOURS_ITEM';
export const DIALOG_EDIT_OFFICE_INFO_CLICK = 'DIALOG_EDIT_OFFICE_INFO_CLICK';
export const DIALOG_EDIT_OFFICE_INFO_ITEM = 'DIALOG_EDIT_OFFICE_INFO_ITEM';
export const DIALOG_EDIT_OFFICE_OFFERINGS_CLICK =
  'DIALOG_EDIT_OFFICE_OFFERINGS_CLICK';
export const DIALOG_EDIT_OFFICE_OFFERINGS_ITEM =
  'DIALOG_EDIT_OFFICE_OFFERINGS_ITEM';
export const DIALOG_EDIT_OFFICE_STATUS_CLICK =
  'DIALOG_EDIT_OFFICE_STATUS_CLICK';
export const DIALOG_EDIT_OFFICE_STATUS_ITEM = 'DIALOG_EDIT_OFFICE_STATUS_ITEM';
export const DIALOG_EDIT_PRICING_PLAN_CLICK = 'DIALOG_EDIT_PRICING_PLAN_CLICK';
export const DIALOG_EDIT_PRICING_PLAN_ITEM = 'DIALOG_EDIT_PRICING_PLAN_ITEM';
export const DIALOG_INDI_ENTRIES_DETAILS_CLICK =
  'DIALOG_INDI_ENTRIES_DETAILS_CLICK';
export const DIALOG_INDI_ENTRIES_DETAILS_ITEM =
  'DIALOG_INDI_ENTRIES_DETAILS_ITEM';
export const DIALOG_INDI_MISTAKES_DETAILS_CLICK =
  'DIALOG_INDI_MISTAKES_DETAILS_CLICK';
export const DIALOG_INDI_MISTAKES_DETAILS_ITEM =
  'DIALOG_INDI_MISTAKES_DETAILS_ITEM';
export const DIALOG_MISTAKE_CLICK = 'DIALOG_MISTAKE_CLICK';
export const DIALOG_MISTAKE_ITEM = 'DIALOG_MISTAKE_ITEM';
export const DIALOG_OVERALL_ENTRIES_DETAILS_CLICK =
  'DIALOG_OVERALL_ENTRIES_DETAILS_CLICK';
export const DIALOG_OVERALL_ENTRIES_DETAILS_ITEM =
  'DIALOG_OVERALL_ENTRIES_DETAILS_ITEM';
export const DIALOG_OVERALL_MISTAKES_DETAILS_CLICK =
  'DIALOG_OVERALL_MIKSTAKES_DETAILS_CLICK';
export const DIALOG_OVERALL_MISTAKES_DETAILS_ITEM =
  'DIALOG_OVERALL_MISTAKES_DETAILS_ITEM';
export const DIALOG_TEAM_ENTRIES_DETAILS_CLICK =
  'DIALOG_TEAM_ENTRIES_DETAILS_CLICK';
export const DIALOG_TEAM_ENTRIES_DETAILS_ITEM =
  'DIALOG_TEAM_ENTRIES_DETAILS_ITEM';
export const DIALOG_TEAM_MISTAKES_DETAILS_CLICK =
  'DIALOG_TEAM_MISTAKES_DETAILS_CLICK';
export const DIALOG_TEAM_MISTAKES_DETAILS_ITEM =
  'DIALOG_TEAM_MISTAKES_DETAILS_ITEM';
export const EMPLOYEES_ADD = 'EMPLOYEES_ADD';
export const EMPLOYEES_DELETE = 'EMPLOYEES_DELETE';
export const EMPLOYEES_EDIT = 'EMPLOYEES_EDIT';
export const EMPLOYEES_INITIALIZE = 'EMPLOYEES_INITIALIZE';
export const EMPLOYEES_INITIALIZE_BY_UID = 'EMPLOYEES_INITIALIZE_BY_UID';
export const EMPLOYEES_INITIALIZE_BY_USERNAME =
  'EMPLOYEES_INITIALIZE_BY_USERNAME';
export const EMPLOYEES_SEARCH = 'EMPLOYEES_SEARCH';
export const INFINITE_SCROLL_SCHEDULED_CLEAR_FILTERS =
  'INFINITE_SCROLL_SCHEDULED_CLEAR_FILTERS';
export const INFINITE_SCROLL_RESCHEDULED_CLEAR_FILTERS =
  'INFINITE_SCROLL_RESCHEDULED_CLEAR_FILTERS';
export const INFINITE_SCROLL_NOTSCHEDULED_CLEAR_FILTERS =
  'INFINITE_SCROLL_NOTSCHEDULED_CLEAR_FILTERS';
export const INFINITE_SCROLL_CALLSANSWERED_CLEAR_FILTERS =
  'INFINITE_SCROLL_CALLSANSWERED_CLEAR_FILTERS';
export const INFINITE_SCROLL_SCHEDULED_HIDE_LISTENER_INDICATOR =
  'INFINITE_SCROLL_SCHEDULED_HIDE_LISTENER_INDICATOR';
export const INFINITE_SCROLL_NOTSCHEDULED_HIDE_LISTENER_INDICATOR =
  'INFINITE_SCROLL_NOTSCHEDULED_HIDE_LISTENER_INDICATOR';
export const INFINITE_SCROLL_CALLSANSWERED_HIDE_LISTENER_INDICATOR =
  'INFINITE_SCROLL_CALLSANSWERED_HIDE_LISTENER_INDICATOR';
export const INFINITE_SCROLL_RESCHEDULED_HIDE_LISTENER_INDICATOR =
  'INFINITE_SCROLL_RESCHEDULED_HIDE_LISTENER_INDICATOR';
export const INFINITE_SCROLL_SCHEDULED_PAUSE_LISTENERS =
  'INFINITE_SCROLL_SCHEDULED_PAUSE_LISTENERS';
export const INFINITE_SCROLL_CALLSANSWERED_PAUSE_LISTENERS =
  'INFINITE_SCROLL_CALLSANSWERED_PAUSE_LISTENERS';
export const INFINITE_SCROLL_NOTSCHEDULED_PAUSE_LISTENERS =
  'INFINITE_SCROLL_NOTSCHEDULED_PAUSE_LISTENERS';
export const INFINITE_SCROLL_RESCHEDULED_PAUSE_LISTENERS =
  'INFINITE_SCROLL_RESCHEDULED_PAUSE_LISTENERS';
export const INFINITE_SCROLL_SCHEDULED_LOAD_MORE =
  'INFINITE_SCROLL_SCHEDULED_LOAD_MORE';
export const INFINITE_SCROLL_CALLSANSWERED_LOAD_MORE =
  'INFINITE_SCROLL_CALLSANSWERED_LOAD_MORE';
export const INFINITE_SCROLL_NOTSCHEDULED_LOAD_MORE =
  'INFINITE_SCROLL_NOTSCHEDULED_LOAD_MORE';
export const INFINITE_SCROLL_RESCHEDULED_LOAD_MORE =
  'INFINITE_SCROLL_RESCHEDULED_LOAD_MORE';
export const INITIALIZED_CLIENTS = 'INITIALIZED_CLIENTS';
export const OFFERINGS_CATEGORIES_INITIALIZE =
  'OFFERINGS_CATEGORIES_INITIALIZE';
export const NOT_SCHEDULED_ADD = 'NOT_SCHEDULED_ADD';
export const NOT_SCHEDULED_DELETE = 'NOT_SCHEDULED_DELETE';
export const NOT_SCHEDULED_EDIT = 'NOT_SCHEDULED_EDIT';
export const NOT_SCHEDULED_INITIALIZE = 'NOT_SCHEDULED_INITIALIZE';
export const NOT_SCHEDULED_SEARCH = 'NOT_SCHEDULED_SEARCH';
export const RESCHEDULED_ADD = 'RESCHEDULED_ADD';
export const RESCHEDULED_DELETE = 'RESCHEDULED_DELETE';
export const RESCHEDULED_EDIT = 'RESCHEDULED_EDIT';
export const RESCHEDULED_INITIALIZE = 'RESCHEDULED_INITIALIZE';
export const RESCHEDULED_SEARCH = 'RESCHEDULED_SEARCH';
export const SCHEDULED_ADD = 'SCHEDULED_ADD';
export const SCHEDULED_DELETE = 'SCHEDULED_DELETE';
export const SCHEDULED_EDIT = 'SCHEDULED_EDIT';
export const SCHEDULED_INITIALIZE = 'SCHEDULED_INITIALIZE';
export const SCHEDULED_SEARCH = 'SCHEDULED_SEARCH';
export const SEARCH_BAR_CLEAR = 'SEARCH_BAR_CLEAR';
export const SEARCH_RUNNING = 'SEARCH_RUNNING';
export const SEARCH_STATS = 'SEARCH_STATS';
export const SEARCH_ACTIVATE = 'SEARCH_ACTIVATE';
export const SEARCH_RESULTS_UPDATE = 'SEARCH_RESULTS_UPDATE';
export const SEARCH_TERM_UPDATE = 'SEARCH_TERM_UPDATE';
export const SNACKBAR_ADDED = 'SNACKBAR_ADDED';
export const SNACKBAR_ADDING = 'SNACKBAR_ADDING';
export const SNACKBAR_DELETED = 'SNACKBAR_DELETED';
export const SNACKBAR_DELETING = 'SNACKBAR_DELETING';
export const SNACKBAR_EDITED = 'SNACKBAR_EDITED';
export const SNACKBAR_UPDATING = 'SNACKBAR_UPDATING';
export const TABLE_FILTER_CALLSANSWERED_APPLY_FILTERS =
  'TABLE_FILTER_CALLSANSWERED_APPLY_FILTERS';
export const TABLE_FILTER_SCHEDULED_APPLY_FILTERS =
  'TABLE_FILTER_SCHEDULED_APPLY_FILTERS';
export const TABLE_FILTER_NOTSCHEDULED_APPLY_FILTERS =
  'TABLE_FILTER_NOTSCHEDULED_APPLY_FILTERS';
export const TABLE_FILTER_RESCHEDULED_APPLY_FILTERS =
  'TABLE_FILTER_RESCHEDULED_APPLY_FILTERS';
export const TABLE_SELECT_SCHEDULED_SELECT_ENTRY =
  'TABLE_SELECT_SCHEDULED_SELECT_ENTRY';
export const TABLE_SELECT_RESCHEDULED_SELECT_ENTRY =
  'TABLE_SELECT_RESCHEDULED_SELECT_ENTRY';
export const TABLE_SELECT_NOTSCHEDULED_SELECT_ENTRY =
  'TABLE_SELECT_NOTSCHEDULED_SELECT_ENTRY';
export const TABLE_SELECT_CALLSANSWERED_SELECT_ENTRY =
  'TABLE_SELECT_CALLSANSWERED_SELECT_ENTRY';
export const DIALOG_EDIT_PAYMENT_INFO_CLICK = 'DIALOG_EDIT_PAYMENT_INFO_CLICK';
export const DIALOG_EDIT_PAYMENT_INFO_ITEM = 'DIALOG_EDIT_PAYMENT_INFO_ITEM';
export const DIALOG_EDIT_TEAM_LEAD_ITEM = 'DIALOG_EDIT_TEAM_LEAD_ITEM';
export const DIALOG_EDIT_TEAM_LEAD_CLICK = 'DIALOG_EDIT_TEAM_LEAD_CLICK';
export const DIALOG_EDIT_TEAM_NAME_ITEM = 'DIALOG_EDIT_TEAM_NAME_ITEM';
export const DIALOG_EDIT_TEAM_NAME_CLICK = 'DIALOG_EDIT_TEAM_NAME_CLICK';
export const DIALOG_EDIT_TEAM_SERVICE_ITEM = 'DIALOG_EDIT_TEAM_SERVICE_ITEM';
export const DIALOG_EDIT_TEAM_SERVICE_CLICK = 'DIALOG_EDIT_TEAM_SERVICE_CLICK';
export const DIALOG_EDIT_TEAM_MEMBERS_ITEM = 'DIALOG_EDIT_TEAM_MEMBERS_ITEM';
export const DIALOG_EDIT_TEAM_MEMBERS_CLICK = 'DIALOG_EDIT_TEAM_MEMBERS_CLICK';
export const DIALOG_DELETE_TEAM_MEMBERS_ITEM =
  'DIALOG_DELETE_TEAM_MEMBERS_ITEM';
export const DIALOG_DELETE_TEAM_MEMBERS_CLICK =
  'DIALOG_DELETE_TEAM_MEMBERS_CLICK';
export const DIALOG_DELETE_TEAM_CLICK = 'DIALOG_DELETE_TEAM_CLICK';
export const DIALOG_DELETE_TEAM_ITEM = 'DIALOG_DELETE_TEAM_ITEM';
export const DIALOG_UPLOAD_LIST_CLICK = 'DIALOG_UPLOAD_LIST_CLICK';
export const DIALOG_UPLOAD_LIST_ITEM = 'DIALOG_UPLOAD_LIST_ITEM';
export const DIALOG_EDIT_DRPROFILE_CLICK = 'DIALOG_EDIT_DRPROFILE_CLICK';
export const DIALOG_EDIT_DRPROFILE_ITEM = 'DIALOG_EDIT_DRPROFILE_ITEM';
export const DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK =
  'DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK';
export const DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM =
  'DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM';
