import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../contexts';
import { t } from 'typy';
import PropTypes from 'prop-types';
import ListScenes from './scenes';
import HeaderTitleWithTabs from '../../../../components/HeaderTitleWithTabs';
import HeaderTitleText from '../../../../components/HeaderTitleText';

const Lists = props => {
  const { match, location } = props;
  const {
    state: { profile, firebase }
  } = useContext(AppContext);
  const [team, setTeam] = useState({});
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const getTeamInfo = async () => {
      await firebase
        .firestore()
        .collection('teams')
        .where('lead', '==', profile.data.id)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            setTeam({ ...doc.data(), id: doc.id });
          });
        });
    };

    getTeamInfo();
  }, [firebase, profile]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const ListScene = ListScenes[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray;
      const readGroups = t(route, 'metadata.permissions.read').safeArray;
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: '/dashboards/lists/$tabName',
        renderComponent: () => (
          <ListScene
            location={location}
            params={match.params}
            route={route}
            tabId={match.params.tabId}
            team={team}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      params={match.params}
      renderTitle={() => <HeaderTitleText text={team.name ?? 'Team'} />}
      tabId={match.params.tabId}
      tabs={tabs}
    />
  );
};

Lists.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired
};

export default Lists;
