import _ from 'lodash';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

class FieldText extends Component {
  UNSAFE_componentWillUpdate(nextProps) {
    const { field, handleValueChange, setFieldValue } = this.props;
    if (handleValueChange && field.value !== nextProps.field.value) {
      handleValueChange(nextProps.field.value);
    }

    if (setFieldValue && field.value !== nextProps.value) {
      setFieldValue(field.name, nextProps.value);
    }
  }

  render() {
    const {
      classes,
      field,
      form: { touched, errors },
      label,
      multiline,
      rowsMax,
      variant
    } = this.props;

    return (
      <div>
        <TextField
          variant={variant}
          multiline={multiline || false}
          rowsMax={rowsMax || null}
          error={!!(_.get(touched, field.name) && _.get(errors, field.name))}
          label={label}
          className={classes.textField}
          margin='normal'
          helperText={
            _.get(touched, field.name) && _.get(errors, field.name)
              ? _.get(errors, field.name)
              : ''
          }
          {...field}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FieldText);
