import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import RegionInfoData from './RegionInfoData';
import { styles } from './styles';

const RegionInformation = ({ classes, regionGroupData, allowedToEdit }) => (
  <div className={classes.regionInfoRoot}>
    <RegionInfoData
      data={regionGroupData}
      label='UID'
      name='id'
      showEdit={false}
    />
    <RegionInfoData
      data={regionGroupData}
      label='Name'
      name='name'
      showEdit={allowedToEdit}
    />
  </div>
);

const mapStateToProps = (state, { region }) => {
  let regionGroupData = {
    name: region ? region.name : null,
    status: '-',
    id: region ? region.id : null
  };

  return { regionGroupData };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(RegionInformation);
