import {
  DIALOG_OVERALL_ENTRIES_DETAILS_CLICK,
  DIALOG_OVERALL_ENTRIES_DETAILS_ITEM
} from '../actions/types';

export const dialogOverallEntriesDetailsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_OVERALL_ENTRIES_DETAILS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogOverallEntriesDetailsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_OVERALL_ENTRIES_DETAILS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogOverallEntriesDetailsReducer = {
  dialogOverallEntriesDetailsOpen,
  dialogOverallEntriesDetailsItem
};
