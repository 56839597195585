import {
  DIALOG_EDIT_TEAM_LEAD_CLICK,
  DIALOG_EDIT_TEAM_LEAD_ITEM
} from './types';

export const dialogEditTeamLeadClick = status => ({
  type: DIALOG_EDIT_TEAM_LEAD_CLICK,
  payload: status
});

export const dialogEditTeamLeadItem = item => ({
  type: DIALOG_EDIT_TEAM_LEAD_ITEM,
  payload: item
});
