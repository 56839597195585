import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddButton from './AddButton';
import RemoveIcon from '@material-ui/icons/Remove';
import DialogDeleteOfficeTeam from './DialogDeleteOfficeTeam';
import { IconButton } from '@material-ui/core';
import { AppContext } from '../../../contexts';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const OfficeTeams = ({
  teamIds,
  classes,
  label,
  showEdit,
  open,
  setOpen,
  setOffering,
  offering,
  office
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [teams, setTeams] = useState([]);
  const [teamNamesRetrieved, setTeamNamesRetrieved] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(null);

  useEffect(() => {
    const getTeamNames = async () => {
      const teamNames = [];
      const getTeamName = async id => {
        const teamName = await firebase
          .firestore()
          .collection('teams')
          .doc(id)
          .get();

        return teamName.data();
      };
      if (teamIds[0]) {
        teamIds.forEach(t => {
          getTeamName(t).then(res => {
            teamNames.push({
              name: res.name,
              totalOffices: res.totalOffices,
              id: t
            });
            if (teamNames.length === teamIds.length) {
              setTeams(teamNames);
            }
          });
        });
      } else {
        setTeams([]);
      }
    };

    if (!teamNamesRetrieved || !open) {
      getTeamNames();
      setTeamNamesRetrieved(true);
    }
  }, [firebase, open, teamIds, teamNamesRetrieved]);

  const onClick = () => {
    setOpen(true);
    setOffering(offering);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <AddButton onClick={onClick} /> : null}
      </div>
      <div className={classes.teamNames}>
        {teams[0]
          ? teams.map((t, i) => {
              return (
                <div key={i} className={classes.dataText}>
                  {t.name}
                  <IconButton
                    color='inherit'
                    aria-label={`Remove ${t.name}`}
                    className={classes.teamRemovalIcon}
                    onClick={() => setRemoveOpen(t)}
                    component='span'
                    disableRipple
                  >
                    <RemoveIcon />
                  </IconButton>
                  {removeOpen ? (
                    <DialogDeleteOfficeTeam
                      offering={offering}
                      office={office}
                      team={removeOpen}
                      open={!!removeOpen}
                      setOpen={setRemoveOpen}
                      setTeamNamesRetrieved={setTeamNamesRetrieved}
                    />
                  ) : null}
                </div>
              );
            })
          : '-'}
      </div>
    </div>
  );
};

OfficeTeams.propTypes = {
  teamIds: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  showEdit: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(OfficeTeams);
