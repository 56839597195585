import React, { useContext } from 'react';
import helpers from '../../../../../../../../../../../helpers';
import RegionInformation from './RegionInformation';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../../../../../../../../contexts';
import { withStyles } from '@material-ui/core/styles';
import { t } from 'typy';
import { styles } from './styles';

const Details = ({ classes, region, sitemapPath }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToEdit = helpers.allowedTo('edit', sitemapPath, userGroups);

  return (
    <Paper elevation={2} className={classes.detailsPaper}>
      <RegionInformation region={region} allowedToEdit={allowedToEdit} />
    </Paper>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  clientGroup: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(Details);
