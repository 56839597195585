import React from 'react';
import EditButton from './EditButton';
import { formatPhoneNumber } from '../../../../../services/helpers';

const ReloInfoData = ({
  classes,
  data,
  label,
  name,
  setItem,
  setOpen,
  showEdit
}) => {
  const onClick = () => {
    setOpen(true);
    setItem({ key: name, label, data });
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        {name === 'patientPhone' ? formatPhoneNumber(data[name]) : data[name]}
      </div>
    </div>
  );
};

export default ReloInfoData;
