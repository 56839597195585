import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Grid,
  Paper,
  withStyles,
  Typography,
  TablePagination,
  ButtonBase,
  InputBase
} from '@material-ui/core';
import AscIcon from '@material-ui/icons/ArrowDownward';
import DescIcon from '@material-ui/icons/ArrowUpward';
import { Link } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { styles } from '../styles';

const TeamsTable = ({
  classes,
  loading,
  rowsCount,
  setRowsCount,
  updatedTeams
}) => {
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderByValue, setOrderByValue] = useState(['name', 'newOffices']);
  const [orderByOrder, setOrderByOrder] = useState(['asc', 'asc']);
  const [searchTerm, setSearchTerm] = useState(null);
  const rowsPerPageOptions = [10, 25, 50, 100];
  const startIndex = rowsPerPage * page;
  const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    const currentTeams = [];
    if (searchTerm) {
      const searchResults = [];
      updatedTeams.forEach(t => {
        if (t.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          searchResults.push(t);
        }
      });
      _.orderBy(searchResults, orderByValue, orderByOrder).forEach((t, i) => {
        if (i >= startIndex && i < endIndex) {
          t.index = i + 1;
          currentTeams.push(t);
          setTeams(currentTeams);
          setRowsCount(searchResults.length);
        }
      });
    } else {
      _.orderBy(updatedTeams, orderByValue, orderByOrder).forEach((t, i) => {
        if (i >= startIndex && i < endIndex) {
          t.index = i + 1;
          currentTeams.push(t);
          if (currentTeams.length === rowsPerPage) {
            setTeams(currentTeams);
          } else if (currentTeams.length === rowsCount - startIndex) {
            setTeams(currentTeams);
          }
        }
        setRowsCount(updatedTeams.length);
      });
    }
  }, [
    updatedTeams,
    rowsPerPage,
    page,
    searchTerm,
    orderByOrder,
    endIndex,
    orderByValue,
    rowsCount,
    setRowsCount,
    startIndex
  ]);

  const renderSearchBar = () => (
    <div className={classes.searchFieldContainer}>
      <span className={classes.searchIcon}>
        <Search />
      </span>
      <InputBase
        className={classes.searchField}
        onChange={e => {
          setSearchTerm(e.target.value);
          setPage(0);
        }}
        placeholder={'Search Teams...'}
        value={searchTerm ?? ''}
      />
    </div>
  );

  const renderTableHeaderRow = () => (
    <Grid container spacing={24} className={classes.headerRow}>
      <Grid item xs={6} sm={3} md={3} onClick={() => changeSort('name')}>
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Team Name
        </Typography>
        {renderSortIcon('name')}
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('calledOffices')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Called Offices
        </Typography>
        {renderSortIcon('calledOffices')}
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('overdueOffices')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Overdue Offices
        </Typography>
        {renderSortIcon('overdueOffices')}
      </Grid>
      <Grid item xs={6} sm={3} md={3} onClick={() => changeSort('newOffices')}>
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          New Offices
        </Typography>
        {renderSortIcon('newOffices')}
      </Grid>
    </Grid>
  );

  const renderTableBody = () => {
    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <Loader type='Oval' color='#696969' height='40' width='40' />
        </div>
      );
    } else if (!loading && !updatedTeams[0]) {
      return (
        <Typography className={classes.messageText}>
          No teams were found for this service
        </Typography>
      );
    } else {
      return (
        <div className={classes.tableContainer}>
          {teams.map((t, i) => {
            return (
              <Link
                key={t.id}
                //take to team details page
                to={`/team/${t.id}/details?pathname=/employees/teams`}
              >
                <ButtonBase className={classes.buttonBase}>
                  <Grid container spacing={24} className={classes.tableRow}>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={3}
                      className={`${classes.cell} ${classes.firstCell}`}
                      style={{ paddingLeft: 12 }}
                    >
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.tableText}
                          ${classes.rowIndex}
                          ${
                            t.index > 99
                              ? classes.rowIndexTripleDig
                              : t.index > 9
                              ? classes.rowIndexDoubleDig
                              : ''
                          }
                        `}
                      >
                        {t.index}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`${classes.tableText} ${classes.firstColumn}`}
                      >
                        {t.name ?? ''}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`${classes.lastContact} ${classes.tableText}`}
                      >
                        {`${t.calledOffices}/${t.teamOffices.length}`}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.ptsContacted}
                          ${classes.tableText}
                        `}
                      >
                        {t.overdueOffices}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.apptsScheduled}
                          ${classes.tableText}
                        `}
                      >
                        {t.newOffices}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </Link>
            );
          })}
          <div className={classes.emptyRow} />
        </div>
      );
    }
  };

  const renderTableFooter = () => (
    <div className={classes.footer}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={1000}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          disabled: !!(loading || page === 0)
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          disabled: !!(loading || (teams && teams.length < rowsPerPage))
        }}
        onChangePage={(e, page) => onChangePage(e, page)}
        onChangeRowsPerPage={e => onChangeRowsPerPage(e)}
        labelDisplayedRows={({ from, to, count }) =>
          rowsCount && !loading
            ? `${from}-${rowsCount <= to ? rowsCount : to} of ${rowsCount}`
            : `${from}-${to}`
        }
      />
    </div>
  );

  const renderSortIcon = key => {
    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }
    return null;
  };

  const getOrderArr = (key, firstDefaultOrder) => {
    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, 'asc'];
  };

  const changeSort = key => {
    setPage(0);
    switch (key) {
      case 'name':
        setOrderByValue(['name', 'newOffices']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'calledOffices':
        setOrderByValue(['calledOffices', 'newOffices']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'overdueOffices':
        setOrderByValue(['overdueOffices', 'newOffices']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'newOffices':
        setOrderByValue(['newOffices', 'overdueOffices']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      default:
        setOrderByValue(['name', 'newOffices']);
        setOrderByOrder(['asc', 'asc']);
    }
  };

  const onChangeRowsPerPage = e => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const onChangePage = (e, page) => {
    setPage(page);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.body} elevation={4}>
            <div className={classes.titleContainer}>{renderSearchBar()}</div>
            {renderTableHeaderRow()}
            {renderTableBody()}
            {renderTableFooter()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

TeamsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  rowsCount: PropTypes.number.isRequired,
  setRowsCount: PropTypes.func.isRequired,
  updatedTeams: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(TeamsTable);
