import React, { useState, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'react-loader-spinner';
import DialogTransition from '../../../components/DialogTransition';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Tooltip,
  withStyles
} from '@material-ui/core';
import { AppContext } from '../../../contexts';
import { Formik, Form } from 'formik';
import { styles } from './styles';

const DialogDeleteOfficeTeam = ({
  team,
  open,
  setOpen,
  fullScreen,
  classes,
  office,
  setTeamNamesRetrieved,
  offering
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);
  const onClose = () => {
    setLoadingAfterSubmit(false);
    setTeamNamesRetrieved(false);
    setOpen(null);
  };

  const onSubmit = async () => {
    const newTotalOffices = team.totalOffices - 1;
    setLoadingAfterSubmit(true);
    office.teams[offering].forEach((t, i) => {
      if (t === team.id) {
        office.teams[offering].splice(i, 1);
      }
      if (!office.teams[offering][0]) {
        delete office.teams[offering];
      }
    });

    try {
      const clientRef = firebase
        .firestore()
        .collection('clients')
        .doc(office.id);

      const teamRef = firebase
        .firestore()
        .collection('teams')
        .doc(team.id);

      await clientRef.update({ teams: office.teams });
      await teamRef.update({ totalOffices: newTotalOffices });
      onClose();
    } catch (e) {
      console.error(e);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Remove Team
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentRemoveTeam}>
        <DialogContentText className={classes.dialogText}>
          {`Please Confirm The Removal Of ${team.name}`}
        </DialogContentText>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(DialogDeleteOfficeTeam);
