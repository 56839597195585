import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import helpers from '../../../../../../../../helpers';
import Grid from '@material-ui/core/Grid';
import RegionsTable from './RegionsTable';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../../../../../contexts';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  regions: {
    height: theme.containers.paper.height.default,
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  }
});

const Regions = ({ classes, sitemapPath, params, location, clientGroup }) => {
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const allowedToManage = helpers.allowedTo('manage', sitemapPath, userGroups);
  const addRegionDialogOpen =
    queryString.parse(location.search.slice(1)).addRegionDialogOpen === 'true';
  const [regions, setRegions] = useState([]);
  const [checkedRegions, setCheckedRegions] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRegions = async () => {
      const regionsOfDsos = [];

      const clientRegions = await firebase
        .firestore()
        .collection('clientGroups')
        .doc(params.clientGroupId)
        .collection('regions')
        .get();

      if (clientRegions.docs) {
        clientRegions.docs.forEach(regionObj => {
          let obj = {};
          obj.id = regionObj.id;
          obj.name = regionObj.data().name;
          obj.regionOffices = regionObj.data().regionOffices;
          regionsOfDsos.push(obj);
        });
      }

      setRegions(regionsOfDsos);
      setCheckedRegions(true);
      setLoading(false);
    };

    if (!regions.length && !checkedRegions) {
      getRegions();
    }
  }, [firebase, regions, checkedRegions, params]);

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.regions} elevation={4}>
            <RegionsTable
              clientGroupRegions={regions}
              clientGroup={clientGroup}
              allowedToManage={allowedToManage}
              addRegionDialogOpen={addRegionDialogOpen}
              loading={loading}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Regions.propTypes = {
  classes: PropTypes.object.isRequired,
  regions: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Regions);
