import {
  INFINITE_SCROLL_SCHEDULED_CLEAR_FILTERS,
  INFINITE_SCROLL_RESCHEDULED_CLEAR_FILTERS,
  INFINITE_SCROLL_NOTSCHEDULED_CLEAR_FILTERS,
  INFINITE_SCROLL_CALLSANSWERED_CLEAR_FILTERS,
  INFINITE_SCROLL_SCHEDULED_HIDE_LISTENER_INDICATOR,
  INFINITE_SCROLL_NOTSCHEDULED_HIDE_LISTENER_INDICATOR,
  INFINITE_SCROLL_CALLSANSWERED_HIDE_LISTENER_INDICATOR,
  INFINITE_SCROLL_RESCHEDULED_HIDE_LISTENER_INDICATOR,
  INFINITE_SCROLL_SCHEDULED_PAUSE_LISTENERS,
  INFINITE_SCROLL_CALLSANSWERED_PAUSE_LISTENERS,
  INFINITE_SCROLL_NOTSCHEDULED_PAUSE_LISTENERS,
  INFINITE_SCROLL_RESCHEDULED_PAUSE_LISTENERS,
  INFINITE_SCROLL_SCHEDULED_LOAD_MORE,
  INFINITE_SCROLL_CALLSANSWERED_LOAD_MORE,
  INFINITE_SCROLL_NOTSCHEDULED_LOAD_MORE,
  INFINITE_SCROLL_RESCHEDULED_LOAD_MORE
} from '../actions/types';

const INITIAL_STATE = {
  startAt: 0,
  endAt: 15,
  pausedListeners: false,
  loadMore: false,
  hideListenerIndicator: false,
  clearFilters: false
};

const infiniteScrollScheduled = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INFINITE_SCROLL_SCHEDULED_PAUSE_LISTENERS:
      return { ...state, pausedListeners: action.payload };
    case INFINITE_SCROLL_SCHEDULED_LOAD_MORE:
      return { ...state, loadMore: action.payload };
    case INFINITE_SCROLL_SCHEDULED_HIDE_LISTENER_INDICATOR:
      return { ...state, hideListenerIndicator: action.payload };
    case INFINITE_SCROLL_SCHEDULED_CLEAR_FILTERS:
      return { ...state, clearFilters: action.payload };
    default:
      return state;
  }
};

const infiniteScrollNotScheduled = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INFINITE_SCROLL_NOTSCHEDULED_PAUSE_LISTENERS:
      return { ...state, pausedListeners: action.payload };
    case INFINITE_SCROLL_NOTSCHEDULED_LOAD_MORE:
      return { ...state, loadMore: action.payload };
    case INFINITE_SCROLL_NOTSCHEDULED_HIDE_LISTENER_INDICATOR:
      return { ...state, hideListenerIndicator: action.payload };
    case INFINITE_SCROLL_NOTSCHEDULED_CLEAR_FILTERS:
      return { ...state, clearFilters: action.payload };
    default:
      return state;
  }
};

const infiniteScrollRescheduled = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INFINITE_SCROLL_RESCHEDULED_PAUSE_LISTENERS:
      return { ...state, pausedListeners: action.payload };
    case INFINITE_SCROLL_RESCHEDULED_LOAD_MORE:
      return { ...state, loadMore: action.payload };
    case INFINITE_SCROLL_RESCHEDULED_HIDE_LISTENER_INDICATOR:
      return { ...state, hideListenerIndicator: action.payload };
    case INFINITE_SCROLL_RESCHEDULED_CLEAR_FILTERS:
      return { ...state, clearFilters: action.payload };
    default:
      return state;
  }
};

const infiniteScrollCallsAnswered = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INFINITE_SCROLL_CALLSANSWERED_PAUSE_LISTENERS:
      return { ...state, pausedListeners: action.payload };
    case INFINITE_SCROLL_CALLSANSWERED_LOAD_MORE:
      return { ...state, loadMore: action.payload };
    case INFINITE_SCROLL_CALLSANSWERED_HIDE_LISTENER_INDICATOR:
      return { ...state, hideListenerIndicator: action.payload };
    case INFINITE_SCROLL_CALLSANSWERED_CLEAR_FILTERS:
      return { ...state, clearFilters: action.payload };
    default:
      return state;
  }
};

export const InfiniteScrollReducer = {
  infiniteScrollScheduled,
  infiniteScrollNotScheduled,
  infiniteScrollRescheduled,
  infiniteScrollCallsAnswered
};
