/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  FieldCheckbox,
  FieldDate,
  FieldPhone,
  FieldSelect,
  FieldText,
  FieldTime,
  DecoratedFieldText
} from '../../../../../components/Fields';
import {
  futureDate,
  date,
  phone,
  required,
  time
} from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import ManageEntryFields from './ManageEntryFields';
import helpers from '../../../../../helpers';
import TableRecording from '../../../../../components/TableRecording';
import EmployeeFilteredSelect from '../../../../../components/EmployeeFilteredSelect';
import { validations as serviceValidation } from '../../../../../services/validation';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const ScheduleItemForm = ({
  classes,
  dialogData,
  dialogOnClose,
  route,
  item,
  employees,
  offices,
  manConnectData
}) => {
  const [disableCategory, setDisableCategory] = useState(false);
  const [updatedValues, setUpdatedCategoryValues] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [showSpinnerWhenLoading, setShowSpinnerWhenLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [doNotBillReasons, setDoNotBillReasons] = useState([]);
  const [mistakeReasons, setMistakeReasons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [notesTemplate, setNotesTemplate] = useState('');
  const [validateError, setValidateError] = useState(null);
  const [needsFollowUp, setNeedsFollowUp] = useState(
    item && item.neededFollowUp ? true : false
  );
  const [actionItem, setActionItem] = useState(false);
  const [actionNeeded, setActionNeeded] = useState(
    item && item.actionTaken ? true : false
  );
  const [reasonForCall, setReasonForCall] = useState(
    item && item.reason && item.reason.notes ? true : false
  );
  const [reasonForCallChanged, setReasonForCallChanged] = useState(false);
  const {
    state: { auth, firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const deleteGroups = t(route, 'metadata.permissions.delete').safeArray || [];
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToDelete =
    _.intersection(userGroups, deleteGroups).length > 0;
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;
  const onSubmit = (values, { setSubmitting }) => {
    if (actionItem || values.neededFollowUp) {
      values.actionItem = true;
    }
    if (item && values.createdBy.employeeId) {
      const {
        createdBy: { employeeId, employeeFirstName, employeeLastName }
      } = values;
      const createdByName = `${employeeFirstName} ${employeeLastName}`;
      values.createdBy = employeeId;
      values.createdByName = createdByName;
    }
    // if (!reasonForCall && values.reason.notes) {
    //   delete values.reason.notes;
    // }
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    helpers
      .transformEntryDataForSave(firebase, auth, values)
      .then(data => data)
      .then(data => helpers.saveEntryData(firebase, data, 'scheduled'))
      .then(() => {
        dialogOnClose(true);
        setError(null);
        setLoading(null);
        setSubmitting(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(null);
        setSubmitting(false);
      });
  };

  const NeedsFollowUpIcon = () => {
    return needsFollowUp ? (
      <RemoveIcon />
    ) : (
      <AddCircleIcon
        onClick={() => {
          setActionItem(!actionItem);
        }}
      />
    );
  };

  const ActionNeededIcon = () => {
    return actionNeeded ? <RemoveIcon /> : <AddCircleIcon />;
  };

  const ReasonForCallIcon = () => {
    return reasonForCall ? <RemoveIcon /> : <AddCircleIcon />;
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          {showSpinnerWhenLoading ? (
            <Loader type='Oval' color='#696969' height='12' width='12' />
          ) : null}
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };
  const renderConfirmDelete = () => (
    <>
      <Button
        color='secondary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
          setError(null);
          setLoading(`Deleting Entry; Please Keep Browser Window Open`);
          helpers
            .deleteEntry(firebase, dialogData.id, 'scheduled')
            .then(() => {
              dialogOnClose(true);
              setError(null);
              setLoading(null);
            })
            .catch(err => {
              setError(err.toString());
              setLoading(null);
              setShowSpinnerWhenLoading(true);
            });
        }}
      >
        Yes, Delete it
      </Button>
      <Button
        color='primary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setError(null);
          setLoading(null);
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
        }}
      >
        No, Nevermind
      </Button>
    </>
  );
  const renderActionButtons = isSubmitting => (
    <>
      {userAllowedToDelete && dialogData ? (
        <Button
          color='secondary'
          type='button'
          onClick={() => {
            setShowSpinnerWhenLoading(false);
            setLoading('Please confirm...');
            setConfirmDelete(true);
          }}
          disabled={isSubmitting || loading !== null}
        >
          Delete
        </Button>
      ) : null}
      <Button
        color='primary'
        type='submit'
        disabled={isSubmitting || loading !== null}
      >
        Save
      </Button>
    </>
  );
  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(_.toLower(selectName))}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(_.toLower(selectName))}s
        </span>
      );
    }

    return vanityName || _.startCase(_.toLower(selectName));
  };
  const validations = {
    appointmentDate: v =>
      helpers.composeFieldValidation(v, [required, futureDate]),
    existingAppointmentDate: v =>
      helpers.composeFieldValidation(v, [required, date]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time]),
    lastVisitDate: date,
    office: required,
    patientName: required,
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    spanishSpEmp: required,
    type: required,
    doNotBillCategory: required,
    mistakeCategory: required,
    category: required,
    subCategory: required
  };

  const generateNewCategoryNotes = (
    patientName,
    notesTemplate,
    previousSubCategory,
    currentNotes,
    previousSubCategirynotesTemplate
  ) => {
    const notes = currentNotes
      ? currentNotes.search(patientName) === -1
        ? currentNotes
        : currentNotes.replace(patientName, '{{ name }}')
      : null;
    let isCustomNote = false;
    let newNote = '';

    if (!notes) {
      newNote = notesTemplate.replace('{{ name }}', patientName);
    }

    if (previousSubCategory) {
      let oldNoteTemplate = _.filter(
        subCategories,
        category => category.id === previousSubCategory
      );

      if (oldNoteTemplate.length > 0) {
        oldNoteTemplate = oldNoteTemplate[0].notesTemplate.replace(
          patientName,
          '{{ name }}'
        );
      } else {
        oldNoteTemplate = previousSubCategirynotesTemplate.replace(
          patientName,
          '{{ name }}'
        );
      }

      if (notes && notes.search(oldNoteTemplate) === -1) {
        isCustomNote = true;
      }

      if (isCustomNote && notes) {
        newNote = notes.replace('{{ name }}', patientName);
      }
      if (!isCustomNote && notes) {
        const oldNote = notes.split(oldNoteTemplate);
        const oldNoteTrimmed = oldNote.map(string => string.trim());
        newNote = `${notesTemplate.replace('{{ name }}', patientName)} ${
          oldNoteTrimmed[1] ? oldNoteTrimmed[1] : ''
        }`;
      }
    }

    if (!previousSubCategory && notes) {
      newNote = `${notesTemplate.replace('{{ name }}', patientName)} ${notes}`;
    }

    return newNote;
  };

  const getSubCategory = category => {
    setSubCategories([]);
    firebase
      .firestore()
      .collection('offeringsCategories')
      .doc(category)
      .collection('subcategories')
      .where('offerings', 'array-contains-any', ['recall', 'recallPlus'])
      .get()
      .then(snapShots => {
        const subCategoriesDocs = [];
        snapShots.forEach(doc => {
          subCategoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setSubCategories(subCategoriesDocs);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userAllowedToManage) {
          const doNotBillReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('doNotBill')
            .collection('reasons')
            .orderBy('value')
            .get();
          const mistakeReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('mistake')
            .collection('reasons')
            .orderBy('value')
            .get();
          const doNotBillReasonsDocs = [];
          const mistakeReasonsDocs = [];

          doNotBillReasonsQuerySnapshot.forEach(doc => {
            doNotBillReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          mistakeReasonsQuerySnapshot.forEach(doc => {
            mistakeReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          setDoNotBillReasons(doNotBillReasonsDocs);
          setMistakeReasons(mistakeReasonsDocs);
        }

        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .where('offerings', 'array-contains', 'scheduled')
          .get();
        if (item && item.reason) {
          getSubCategory(item.reason.category);
        }

        const categoriesDocs = [];

        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setCategories(categoriesDocs);
        // setDisableCategory(true);

        const officesQuerySnapshot = await firebase
          .firestore()
          .collection('clients')
          .where('offerings.recall', '==', true)
          .where('status', '==', 'active')
          .get();
        const employeesQuerySnapshot = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        const treatmentTypesQuerySnapshot = await firebase
          .firestore()
          .collection('options')
          .doc('scheduledTreatment')
          .collection('types')
          .orderBy('name')
          .get();
        const employeeDocs = [];
        const officeDocs = [];
        const typesDocs = [];

        employeesQuerySnapshot.forEach(doc => {
          employeeDocs.push({ ...doc.data(), id: doc.id });
        });

        officesQuerySnapshot.forEach(doc => {
          officeDocs.push({ ...doc.data(), id: doc.id });
        });

        treatmentTypesQuerySnapshot.forEach(doc => {
          typesDocs.push({ ...doc.data(), id: doc.id });
        });

        setLoading(null);
      } catch (err) {
        setDoNotBillReasons(null);
        setMistakeReasons(null);
        setLoading(null);
        setError(err.toString());
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [firebase, userAllowedToManage, item]);

  const updateDialogData = dialogData => {
    const updatedDialogData = dialogData;
    if (!dialogData.reason) {
      updatedDialogData.reason = {
        notes: dialogData.notes ? dialogData.notes : '',
        category: 'lmW1HPZWpEOUjChoTSi9',
        subcategory: 'qWKs0gTgZZQV8er8Qmov'
      };
    }
    if (dialogData.notes && dialogData.reason && !dialogData.reason.notes) {
      updatedDialogData.reason.notes = dialogData.notes;
    }

    return updatedDialogData;
  };

  const updateInitialValues = blankInitialValues => {
    blankInitialValues.reason = {
      notes: '',
      category: 'lmW1HPZWpEOUjChoTSi9',
      subcategory: 'qWKs0gTgZZQV8er8Qmov'
    };

    return blankInitialValues;
  };

  const initialValues = dialogData
    ? updateDialogData(dialogData)
    : manConnectData && updatedValues
    ? { ...manConnectData, ...updatedValues }
    : manConnectData
    ? manConnectData
    : updateInitialValues({});

  const handleSubCategory = newCategory => {
    if (newCategory !== '') {
      getSubCategory(newCategory);
    }
  };

  const validate = value => {
    if (serviceValidation.required(value)) {
      setValidateError('Required');
      return true;
    } else {
      setValidateError(null);
      return false;
    }
  };

  useEffect(() => {
    const getSubCategoryOnUpdate = category => {
      setSubCategories([]);
      firebase
        .firestore()
        .collection('offeringsCategories')
        .doc(category)
        .collection('subcategories')
        .where('offerings', 'array-contains-any', ['recall', 'recallPlus'])
        .get()
        .then(snapShots => {
          const subCategoriesDocs = [];
          snapShots.forEach(doc => {
            subCategoriesDocs.push({ ...doc.data(), id: doc.id });
          });
          setSubCategories(subCategoriesDocs);
        });
    };

    if (
      categories.length === 1 &&
      categories[0].category === 'Appointment Scheduled' &&
      !updatedValues
    ) {
      if (item) {
        setDisableCategory(true);
      } else {
        getSubCategoryOnUpdate(categories[0].id);
        const newValues = {};
        newValues.reason = {};
        newValues.reason.category = categories[0].id;
        setUpdatedCategoryValues(newValues);
        setDisableCategory(true);
      }
    }

    if (
      !item &&
      subCategories.length > 0 &&
      categories[0].category === 'Appointment Scheduled' &&
      updatedValues
    ) {
      const filteredSubId = subCategories.filter(
        subCategory => subCategory.category === 'Scheduled Appointment'
      )[0].id;
      updatedValues.reason.subcategory = filteredSubId;
      setUpdatedCategoryValues(updatedValues);
    }
  }, [categories, subCategories, item, updatedValues, firebase]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          {dialogData && userAllowedToManage ? (
            <>
              <ManageEntryFields
                doNotBillReasons={doNotBillReasons}
                loading={loading}
                mistakeReasons={mistakeReasons}
                selectLabel={selectLabel}
                validations={validations}
                values={values}
              />
            </>
          ) : null}
          {dialogData ? (
            <>
              <div className={classes.sectionTitle}>
                <Typography variant='subtitle2'>Entry Recording</Typography>
              </div>
              <div className={classes.row}>
                <TableRecording
                  callId={item ? item.callId : null}
                  createdTime={item ? item.createdTime : null}
                  firestore={firebase}
                />
              </div>
            </>
          ) : null}
          <div className={classes.sectionTitle}>
            <Typography variant='subtitle2'>Entry Details</Typography>
          </div>
          {!item || values.reason ? (
            <Field name='reason.category' validate={validations.category}>
              {({ form, field }) => {
                return (
                  <FieldSelect
                    margin='none'
                    forceShrinkLabel={updatedValues && true}
                    form={form}
                    field={field}
                    loading={categories.length === 0}
                    disabled={
                      categories.length === 0 ||
                      loading !== null ||
                      disableCategory
                    }
                    label={
                      updatedValues
                        ? 'Category'
                        : selectLabel(categories, 'Category')
                    }
                    options={_.sortBy(categories, 'category')}
                    optionKey='id'
                    optionText='category'
                    optionValue='id'
                    onChange={newCategory => {
                      if (
                        values.reason &&
                        newCategory !== values.reason.category &&
                        values.reason.subcategory
                      ) {
                        values.reason.subcategory = '';
                      }
                      handleSubCategory(newCategory);
                    }}
                  />
                );
              }}
            </Field>
          ) : null}
          {values.reason ? (
            <>
              <Field
                name='reason.subcategory'
                validate={validations.subCategory}
              >
                {({ form, field }) => {
                  return (
                    <FieldSelect
                      margin='none'
                      form={form}
                      field={field}
                      loading={subCategories.length === 0}
                      disabled={subCategories.length === 0 || loading !== null}
                      label={selectLabel(subCategories, 'Subcategory')}
                      options={_.sortBy(subCategories, 'category')}
                      optionKey='id'
                      optionText='category'
                      optionValue='id'
                      component={FieldSelect}
                      onChange={newSubCategory => {
                        if (!values.reason) {
                          values.reason = {};
                        }
                        if (newSubCategory !== '') {
                          const subCategory = _.filter(
                            subCategories,
                            category => category.id === newSubCategory
                          )[0];
                          if (values.patientName) {
                            values.reason.notes = generateNewCategoryNotes(
                              values.patientName,
                              subCategory.notesTemplate,
                              values.reason ? values.reason.subcategory : null,
                              values.reason.notes,
                              notesTemplate
                            );
                          }
                          setNotesTemplate(subCategory.notesTemplate);
                        } else {
                          values.reason.notes = '';
                        }
                      }}
                    />
                  );
                }}
              </Field>
            </>
          ) : null}
          <Field name='patientName' validate={validations.patientName}>
            {({ form, field }) => {
              return (
                <FieldText
                  form={form}
                  field={field}
                  label='Patient Name'
                  variant='outlined'
                  onChange={e => {
                    field.onBlur(e);
                    field.onChange(e);
                    if (values.reason && reasonForCall) {
                      if (e.target.value === '') {
                        values.reason.notes = '';
                      } else if (notesTemplate !== '' && !values.patientName) {
                        values.reason.notes = generateNewCategoryNotes(
                          e.target.value,
                          notesTemplate,
                          null,
                          values.reason.notes,
                          null
                        );
                      } else {
                        values.reason.notes = values.reason.notes.replace(
                          values.patientName,
                          e.target.value
                        );
                      }
                    }
                  }}
                />
              );
            }}
          </Field>
          <Field name='patientId'>
            {({ form, field }) => (
              <FieldText
                form={form}
                field={field}
                label='Patient ID'
                variant='outlined'
              ></FieldText>
            )}
          </Field>
          <div className={classes.row}>
            <Field
              name='patientPhone'
              label='Patient Phone'
              variant='outlined'
              component={FieldPhone}
              disabled={loading !== null}
              validate={validations.patientPhone}
            />
            <FieldCheckbox
              name='newPatient'
              label='New Patient'
              disabled={loading !== null}
              checked={!!values.newPatient}
            />
          </div>
          {!values.newPatient ? (
            <Field
              name='lastVisitDate'
              label='Last Visit Date'
              variant='outlined'
              placeholder='mm/dd/yyyy'
              component={FieldDate}
              disabled={loading !== null}
              validate={validations.lastVisitDate}
            />
          ) : null}
          <Field
            name='office'
            loading={offices.length === 0}
            disabled={offices.length === 0 || loading !== null}
            label={selectLabel(offices, 'office')}
            options={offices}
            optionKey='id'
            optionText='officeInformation.name'
            optionValue='id'
            margin='none'
            component={FieldSelect}
            validate={validations.office}
          />
          <div className={classes.row}>
            <Field
              name='appointmentDate'
              label='Appointment Date'
              variant='outlined'
              placeholder='mm/dd/yyyy'
              component={FieldDate}
              disabled={loading !== null}
              validate={
                !dialogData
                  ? validations.appointmentDate
                  : validations.existingAppointmentDate
              }
            />
            <Field
              name='appointmentTime'
              label='Appointment Time'
              variant='outlined'
              placeholder='hh:mm'
              component={FieldTime}
              disabled={loading !== null}
              validate={validations.appointmentTime}
            />
          </div>
          <FieldCheckbox
            name='urgent'
            label='Mark as Urgent'
            disabled={loading !== null}
            checked={!!values.urgent}
          />
          <FieldCheckbox
            name='spanishSpEmpHelp'
            label='Spanish-Speaking Caller Helped'
            disabled={loading !== null}
            checked={!!values.spanishSpEmpHelp}
          />
          {values.spanishSpEmpHelp ? (
            <Field
              name='spanishSpEmp'
              loading={employees.length === 0}
              disabled={employees.length === 0 || loading !== null}
              label={selectLabel(
                employees,
                'employee',
                'Spanish-Speaking Caller'
              )}
              options={employees}
              optionKey='id'
              optionText={['firstName', 'lastName']}
              optionValue='id'
              component={FieldSelect}
              validate={validations.spanishSpEmp}
            />
          ) : null}
          <Field
            name='reason.notes'
            label='Call Notes'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={DecoratedFieldText}
            id='reason.notes'
            oniconclick={() => {
              if (
                !reasonForCallChanged &&
                !reasonForCall &&
                values.reason &&
                values.patientName
              ) {
                values.reason.notes = generateNewCategoryNotes(
                  values.patientName,
                  notesTemplate,
                  null,
                  values.reason.notes,
                  null
                );
                setReasonForCallChanged(!reasonForCallChanged);
              }
              setReasonForCall(!reasonForCall);
            }}
            disabler={reasonForCall.toString()}
            iconswitch={reasonForCall.toString()}
            Icon={ReasonForCallIcon}
          />
          <Field
            name='actionTaken'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={DecoratedFieldText}
            id='actionTaken'
            label='Action Taken'
            oniconclick={() => {
              if (actionNeeded) {
                delete values.actionTaken;
              }
              setActionNeeded(!actionNeeded);
            }}
            disabler={actionNeeded.toString()}
            iconswitch={actionNeeded.toString()}
            Icon={ActionNeededIcon}
          />
          <Field
            name='neededFollowUp'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={DecoratedFieldText}
            id='needsFollowUp'
            label='Needed Follow Up'
            oniconclick={() => {
              if (needsFollowUp) {
                delete values.neededFollowUp;
              }
              setNeedsFollowUp(!needsFollowUp);
            }}
            disabler={needsFollowUp.toString()}
            iconswitch={needsFollowUp.toString()}
            Icon={NeedsFollowUpIcon}
          />
          {item ? (
            <Field name='createdBy' label='Created By' validate={validate}>
              {({ field }) => {
                return (
                  <EmployeeFilteredSelect
                    label='Created by Employee'
                    error={validateError}
                    onChange={employee => {
                      setFieldValue(
                        field.name,
                        employee
                          ? {
                              employeeId: employee.id,
                              employeeFirstName: employee.firstName,
                              employeeLastName: employee.lastName
                            }
                          : ''
                      );
                    }}
                    previouslySelectedEmployeeId={item ? item.createdBy : null}
                  />
                );
              }}
            </Field>
          ) : null}
          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {confirmDelete
                ? renderConfirmDelete()
                : renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ScheduleItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  offices: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(ScheduleItemForm);
