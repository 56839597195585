/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Add, FilterList } from '@material-ui/icons';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import columns from './columns';
import { t } from 'typy';
import helpers from '../../../../helpers';
import DataTable from '../../../../components/DataTable';
import DialogAddWebsiteChat from './components/DialogAddWebsiteChat';
import WebsiteChatItemForm from './components/WebsiteChatItemForm';
import filters from './filters';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import { AppContext } from '../../../../contexts';
import _ from 'lodash';

const WebsiteChat = props => {
  const { history, location, route, tabId } = props;
  const {
    state: { firebase }
  } = useContext(AppContext);
  const dialogDataParam = queryString.parse(location.search).dialogData;
  const allParams = queryString.parse(location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [manConnectData, setManConnectData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [offices, setOffices] = useState([]);
  const param = dialogDataParam ? JSON.parse(dialogDataParam) : {};
  const loadingEditData = !!(param.id && !dialogData);
  const open = Boolean(anchorEl);
  const dialogOnClose = refreshData => {
    const searchObj = queryString.parse(location.search);
    if (refreshData) searchObj.refreshData = true;
    delete searchObj.dialogData;
    const search = queryString.stringify(searchObj);
    history.push(`${location.pathname}?${search}`);
  };
  const handlePopoverClick = e => setAnchorEl(e.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        let offices = await firebase
          .firestore()
          .collection('clients')
          .where('offerings.websiteChat', '==', true)
          .where('status', '==', 'active')
          .get();

        offices = offices.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });

        offices = _.sortBy(offices, 'officeInformation.name')

        setOffices(offices);
      } catch (e) {
        console.error(e);
      }
    };

    if (!offices.length) {
      fetchOffices();
    }
  }, [offices, firebase]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let employees = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        employees = employees.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        setEmployees(employees);
      } catch (e) {
        console.error(e);
      }
    };

    if (!employees.length) {
      fetchEmployees();
    }
  }, [employees, firebase]);

  useEffect(() => {
    const fetchDialogData = async () => {
      const data = dialogDataParam ? JSON.parse(dialogDataParam) : {};
      try {
        if (data.id) {
          const entryData = await helpers.transformEntryDataForEdit(
            firebase,
            data.id,
            'websiteChat'
          );
          setDialogData(entryData);
        } else {
          setDialogData(null);
        }
      } catch (err) {
        setDialogData(null);
      }
    };

    setDialogOpen(!!dialogDataParam);
    fetchDialogData();
  }, [dialogDataParam, firebase]);

  useEffect(() => {
    const handleManConnect = () => {
      if (
        !_.isEmpty(allParams) &&
        !allParams.dialogData &&
        offices.length > 0 &&
        !allParams.refreshData &&
        !allParams.term
      ) {
        const path = props.location.pathname;
        const separator = path.indexOf('?') > -1 ? '&' : '?';
        const office = allParams.skill_id
          ? _.find(
              offices,
              o =>
                t(o, 'five9.skills.answering').safeObject === allParams.skill_id
            )
          : '';
        const manConnectFields = {
          callId: allParams.call_id ? allParams.call_id : '',
          patientPhone:
            allParams && allParams.number1
              ? allParams.number1
              : allParams && allParams.patient_phone
              ? allParams.patient_phone
              : allParams && allParams.ANI
              ? allParams.ANI
              : '',
          patientName: allParams.Full_Name ? allParams.Full_Name : '',
          office: allParams.office_uid
            ? allParams.office_uid
            : office
            ? office.id 
            : ''
        };
        props.history.push(
          `${path}${separator}dialogData=${JSON.stringify(manConnectFields)}`
        );
        setManConnectData(manConnectFields);
      }
    };
    handleManConnect();
  });

  return (
    <>
      <DataTable
        {...props}
        collection='websiteChat'
        columns={columns}
        clearSearchPath={`/data${
          tabId && tabId !== 'undefined' ? `/${tabId}` : ''
        }`}
        sceneUri='data'
        title='Website Chat Entries'
        onRowClickLink={(data, pathname) => {
          const dialogData = JSON.stringify({ id: data.id });
          let path = pathname;
          let search;
          const searchObj = queryString.parse(pathname.split('?')[1]);
          delete searchObj.refreshData;
          if (pathname.indexOf('dialogData') > -1) {
            delete searchObj.dialogData;
            search = queryString.stringify(searchObj);
          }
          path = search
            ? `${pathname.split('?')[0]}?${search}`
            : `${pathname.split('?')[0]}`;
          const separator = path.indexOf('?') > -1 ? '&' : '?';
          const term = searchObj.term ? `&term=${searchObj.term}` : '';

          return `${path}${separator}dialogData=${dialogData}${term}`;
        }}
        tableActionIcons={[
          {
            render: (key, filtersApplied, onClearFilter) => (
              <span key={key}>
                <FilterClearChip
                  filtersApplied={filtersApplied}
                  onClearFilter={onClearFilter}
                />
                <Tooltip title='Filters'>
                  <IconButton
                    aria-label='Filters'
                    aria-owns={open ? 'simple-popper' : null}
                    onClick={handlePopoverClick}
                  >
                    {filtersApplied ? (
                      <Badge color='secondary' variant='dot'>
                        <FilterList />
                      </Badge>
                    ) : (
                      <FilterList />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )
          },
          {
            render: key => (
              <span key={key}>
                <Tooltip title='Add Website Chat Entry'>
                  <IconButton
                    variant='contained'
                    color='primary'
                    aria-label='Add Website Chat Entry'
                    onClick={() => {
                      const searchObj = queryString.parse(location.search);
                      delete searchObj.dialogData;
                      delete searchObj.refreshData;
                      const search = queryString.stringify(searchObj);
                      const { pathname } = location;
                      const param =
                        search === '' ? '?dialogData={}' : '&dialogData={}';
                      history.push(`${pathname}${search}${param}`);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </span>
            )
          }
        ]}
        tableFilterable
        filterData={{
          anchorEl,
          filters,
          open,
          handleClose: handlePopoverClose
        }}
      />
      <DialogAddWebsiteChat
        title='Website Chat Entry'
        collection='websiteChat'
        dialogBody={() =>
          loadingEditData ? null : (
            <WebsiteChatItemForm
              dialogData={dialogData}
              manConnectData={manConnectData}
              dialogOnClose={dialogOnClose}
              loadingEditData={loadingEditData}
              route={route}
              item={dialogData}
              employees={employees}
              offices={offices}
            />
          )
        }
        dialogOnClose={dialogOnClose}
        dialogOpen={!loadingEditData && dialogOpen}
        history={history}
        item={dialogData}
        location={location}
      />
    </>
  );
};

WebsiteChat.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tabId: PropTypes.string.isRequired
};

export default withRouter(WebsiteChat);
