import React, { useState } from 'react';
import DialogTransition from '../../../../../../../../../../components/DialogTransition';
import qs from 'qs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Dialog,
  Tooltip,
  Typography,
  DialogContent,
  withStyles,
  IconButton,
  Button
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { Formik, Form, Field } from 'formik';
import FieldText from '../../../../../../../../../../components/FieldText';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';
import { validations } from '../../../../../../../../../../services/validation';
import { Close } from '@material-ui/icons';

const DialogAddRegion = props => {
  const {
    classes,
    onClose,
    openDialog,
    onRegionAddRedirect,
    fullScreen,
    clientGroup
  } = props;
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    const { firestore } = props;

    try {
      let res = await firestore
        .collection('clientGroups')
        .doc(clientGroup.id)
        .collection('regions')
        .add(values);

      setLoadingAfterSubmit(false);
      onRegionAddRedirect(res.id, true);
    } catch (e) {
      console.log(e);
      setLoadingAfterSubmit(false);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={openDialog}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Add Region
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{ name: '', regionOffices: [] }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue, initialValues }) => (
            <Form className={classes.dialogAddRegionForm}>
              <Field
                className={classes.dialogTextField}
                name='name'
                label='Region Name'
                component={FieldText}
                variant='outlined'
                validate={validations.required}
              />

              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='default'
                  className={classes.dialogButton}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                  disabled={!values.name}
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addRegionFormData').safeObject
    ? JSON.parse(t(searchObj, 'addRegionFormData').safeObject)
    : {};

  return { formData };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddRegion);
