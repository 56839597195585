import React, { useContext, useEffect, useState } from 'react';
import ListsTable from '../../../../../components/ListsTable';
import PropTypes from 'prop-types';
import { Typography, withStyles, Paper, Card } from '@material-ui/core';
import CountUp from 'react-countup';
import { AppContext } from '../../../../../contexts';
import { functions } from '../../../../../';
import { styles } from '../styles';
import moment from 'moment';

const TeamLists = ({ team, classes }) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [teamOffices, setTeamOffices] = useState([]);
  const [updatedOffices, setUpdatedOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contactsRetrieved, setContactsRetrieved] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [tileStats, setTileStats] = useState({
    officesCalled: 0,
    overdueOffices: 0,
    newOffices: 0,
    totalPatients: 0
  });
  const [tilesUpdated, setTilesUpdated] = useState(false);
  const [officesRetrieved, setOfficesRetrieved] = useState(false);

  useEffect(() => {
    const getTeamOffices = async () => {
      try {
        const offices = [];
        await firebase
          .firestore()
          .collection('clients')
          .where('status', '==', 'active')
          .where(`teams.${team.serviceOffering[0]}`, 'array-contains', team.id)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              offices.push({ ...doc.data(), id: doc.id });
            });
          });
        setTeamOffices(offices);
        setOfficesRetrieved(true);
      } catch (e) {
        console.error(e);
      }
    };

    if (team.id) {
      getTeamOffices();
    }
  }, [firebase, team]);

  useEffect(() => {
    const activeOffices = [];
    teamOffices.forEach(o => {
      if (o.offerings[team.serviceOffering[0]] === true) {
        activeOffices.push(o);
      }
    });
    if (officesRetrieved && !activeOffices[0]) {
      setLoading(false);
    }
    setRowsCount(activeOffices.length);

    const updateOffice = async office => {
      try {
        const scheduledOrNotScheduled = [];
        let lastContact;
        await firebase
          .firestore()
          // collection should be dynamic based on the team's service offering.
          // for now, there are only recall teams, so I'll leave this 'scheduled'
          .collection('scheduled')
          .where('office', '==', `${office.id}`)
          .orderBy('createdTime', 'desc')
          .limit(1)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              scheduledOrNotScheduled.push({ ...doc.data(), id: doc.id });
            });
          });
        await firebase
          .firestore()
          .collection('notScheduled')
          .where('office', '==', `${office.id}`)
          .where('entryType', '==', team.serviceOffering[0])
          .orderBy('createdTime', 'desc')
          .limit(1)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              scheduledOrNotScheduled.push({ ...doc.data(), id: doc.id });
            });
          });

        if (scheduledOrNotScheduled?.length > 1) {
          if (
            moment(scheduledOrNotScheduled[0].createdTime.seconds).isAfter(
              moment(scheduledOrNotScheduled[1].createdTime.seconds)
            )
          ) {
            lastContact = scheduledOrNotScheduled[0].createdTime;
          } else {
            lastContact = scheduledOrNotScheduled[1].createdTime;
          }
        } else if (!scheduledOrNotScheduled[0]) {
          lastContact = { seconds: 0 };
        } else {
          lastContact = scheduledOrNotScheduled[0].createdTime;
        }

        const getApptsScheduled = async () => {
          let apptsScheduled;
          const currentMonth = moment(new Date()).format('YYYY-MM');
          try {
            const monthlyAppts = await firebase
              .firestore()
              .collection('statistics')
              .doc('clients')
              .collection(office.id)
              .doc('statistics')
              // collection should be dynamic based on the team's service offering
              // for now, there are only recall teams so I'll leave this 'scheduled'
              .collection('scheduled')
              .doc('monthly')
              .get();

            const monthlyApptsObj = monthlyAppts.data();
            if (monthlyApptsObj) {
              for (const date in monthlyApptsObj) {
                if (date === currentMonth) {
                  apptsScheduled = monthlyApptsObj[date];
                }
              }
            } else {
              apptsScheduled = 0;
            }
          } catch (e) {
            console.error(e);
          }
          return apptsScheduled;
        };

        let apptsScheduled;
        await getApptsScheduled().then(res => {
          if (res) {
            apptsScheduled = res;
          } else {
            apptsScheduled = 0;
          }
        });

        const updatedOffice = {
          ...office,
          lastContact: lastContact,
          patientsContacted: null,
          apptsScheduled: apptsScheduled
        };

        return updatedOffice;
      } catch (e) {
        console.error(e);
      }
    };

    const getPatientsContacted = async updated => {
      const offices = updated.map(o => o.id);

      const date = new Date();
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
        .toString()
        .slice(0, 15);
      const endDate = date.toString().slice(0, 15);

      const reqBody = {
        startDate,
        endDate,
        offices,
        entryType: team.serviceOffering[0],
        // collection should be dynamic based on the team's service offering
        // for now, there are only recall teams so I'll leave this 'scheduled'
        collection: 'scheduled'
      };

      try {
        const res = await functions().httpsCallable(
          'statistics-listsPatientsContacted'
        )(reqBody);
        for (const office in res.data) {
          const officeToUpdate = updated.find(o => o.id === office);
          officeToUpdate.patientsContacted = res.data[office];
        }
      } catch (e) {
        console.error(e);
      }

      const getTotalListPatients = async office => {
        try {
          const totalListPatients = await firebase
            .firestore()
            .collection('statistics')
            .doc('clients')
            .collection(office.id)
            .doc('statistics')
            .collection('listPatients')
            .doc('totalListPatients')
            .get();

          if (totalListPatients.data()?.totalListPatients) {
            office.totalPatients = totalListPatients.data().totalListPatients;
          }
          return office;
        } catch (e) {
          console.error(e);
        }
      };

      const promises = [];
      updated.forEach(o => {
        promises.push(getTotalListPatients(o));
        if (o.patientsContacted === null) {
          o.patientsContacted = 0;
        }
      });
      const officesWithTotalPatients = await Promise.all(promises);
      setUpdatedOffices(officesWithTotalPatients);
      setLoading(false);
    };

    const updated = [];
    activeOffices.forEach(o => {
      updateOffice(o).then(res => {
        updated.push(res);
        if (updated.length === activeOffices.length) {
          setContactsRetrieved(true);
          getPatientsContacted(updated);
        }
      });
    });
    // eslint-disable-next-line
  }, [teamOffices]);

  useEffect(() => {
    const updateTiles = () => {
      const monthStart = Date.parse(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      const twoWeeksPrior = new Date() - 12096e5;
      let calledOn = 0;
      let overdue = 0;
      let uncalled = 0;
      let totalPatients = 0;
      updatedOffices.forEach(o => {
        if (o.lastContact.seconds * 1000 > monthStart) {
          calledOn++;
        }
        if (o.lastContact.seconds * 1000 - twoWeeksPrior < 0) {
          overdue++;
        }
        if (o.lastContact.seconds === 0) {
          uncalled++;
        }
        if (o.totalPatients) {
          totalPatients += o.totalPatients;
        }
      });
      setTileStats({
        officesCalled: calledOn,
        overdueOffices: overdue,
        newOffices: uncalled,
        totalPatients: totalPatients
      });
    };

    if (!tilesUpdated && updatedOffices[0]) {
      updateTiles();
      setTilesUpdated(true);
    }
  }, [updatedOffices, tilesUpdated]);

  const renderTiles = () => {
    return (
      <div className={classes.tiles}>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={tileStats.officesCalled}
                separator=','
                decimals={0}
              />
              /
              <CountUp
                duration={1}
                end={updatedOffices.length}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Offices Called
          </Typography>
        </Paper>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={tileStats.overdueOffices}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Overdue Offices
          </Typography>
        </Paper>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={tileStats.newOffices}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Uncalled/New Offices
          </Typography>
        </Paper>
        <Paper className={classes.tile} elevation={4}>
          <Card
            className={classes.card}
            style={{ backgroundColor: '#055f5bE6' }}
            elevation={8}
          >
            <Typography
              variant='h3'
              style={{ color: 'white', fontSize: '25px' }}
            >
              <CountUp
                duration={1}
                end={tileStats.totalPatients}
                separator=','
                decimals={0}
              />
            </Typography>
          </Card>
          <Typography variant='button' className={classes.tileTitle}>
            Total Patients
          </Typography>
        </Paper>
      </div>
    );
  };

  return (
    <>
      {renderTiles()}
      <ListsTable
        updatedOffices={updatedOffices}
        loading={loading}
        contactsRetrieved={contactsRetrieved}
        rowsCount={rowsCount}
        setRowsCount={setRowsCount}
      />
    </>
  );
};

TeamLists.propTypes = {
  team: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(TeamLists);
