import React from 'react';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { IconButton, Tooltip } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const TeamName = ({ classes, history, team, pathname }) => {
  const onClick = () => history.push('/employees/teams');

  return (
    <div>
      <div className={classes.teamNameHeading}>
        <Typography variant='h6' className={classes.teamBreadcrumb}>
          Teams
        </Typography>
        <Typography variant='h6' className={classes.teamNameHeadText}>
          <Tooltip title='Back to teams'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Back to teams'
              onClick={onClick}
              className={classes.teamNameBackIcon}
            >
              <BackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.teamNameText}>
            {team && team.name ? team.name : 'name'}
          </div>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const teamData = state.firestore.ordered.teamDetailInfo;
  const team =
    isLoaded(teamData) && !isEmpty(teamData) && teamData[0].id === props.teamId
      ? teamData[0]
      : null;
  return { team };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'teams',
      doc: props.teamId,
      storeAs: 'teamDetailInfo'
    }
  ]),
  withStyles(styles, { withTheme: true }),
  withRouter
)(TeamName);
