import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  dialogEditTeamMembersItem,
  dialogEditTeamMembersClick,
  dialogDeleteTeamMembersItem,
  dialogDeleteTeamMembersClick
} from '../../../actions';
import EditButton from './EditButton';
import { styles } from './styles';

const TeamMembersData = ({
  classes,
  data,
  label,
  name,
  showEdit,
  dialogEditTeamMembersItem,
  dialogEditTeamMembersClick,
  dialogDeleteTeamMembersItem,
  dialogDeleteTeamMembersClick,
  relevantEmployeesMembers
}) => {
  const editOnClick = () => {
    dialogEditTeamMembersItem({ key: name, label, data });
    dialogEditTeamMembersClick(true);
  };

  const deleteOnClick = member => {
    const memberId = member.id,
      memberName = member.name;
    dialogDeleteTeamMembersItem({ key: name, memberId, memberName, data });
    dialogDeleteTeamMembersClick(true);
  };

  const formatNames = () => {
    return relevantEmployeesMembers.map((member, key) => {
      return member.name && member.id !== data.lead ? (
        <div key={key} className={classes.teamMembersNames}>
          {member.name}
          <IconButton
            color='inherit'
            aria-label={`Remove ${member.name} from ${data.name}`}
            className={classes.teamMemberRemovalIcon}
            onClick={() => {
              deleteOnClick(member);
            }}
            component='span'
            disableRipple
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null;
    });
  };

  return (
    <div className={classes.dataRow} style={{ marginBottom: 24 }}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton label='Add' onClick={editOnClick} /> : null}
      </div>
      <div>
        {data &&
          relevantEmployeesMembers &&
          relevantEmployeesMembers.length > 1 &&
          formatNames()}
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    dialogEditTeamMembersClick,
    dialogEditTeamMembersItem,
    dialogDeleteTeamMembersClick,
    dialogDeleteTeamMembersItem
  }),
  withStyles(styles, { withTheme: true })
)(TeamMembersData);
