import React, { useState } from 'react';
import { DatePicker } from 'material-ui-pickers';

const CustomDatePicker = field => {
  const {
    meta: { touched, error }
  } = field;
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <DatePicker
      label={field.label}
      className={field.className}
      value={selectedDate}
      onChange={handleDateChange}
      format={field.formatDate}
      animateYearScrolling
      disableFuture
      margin='normal'
      error={!!(touched && error)}
      helperText={touched && error}
      variant='outlined'
      {...field.input}
    />
  );
};

export default CustomDatePicker;
