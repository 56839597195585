const actionIconName = 'actionItem';
const urgentIconName = 'urgentItem';
const mistakeIconName = 'mistakeItem';
const noBillIconName = 'noBillItem';
const emergencyIconName = 'emergencyItem';

const mistakeNotYetHandledIconName = 'mistakeNotYetHandledItem';
const mistakeHandledIconName = 'mistakeHandledItem';

const iconNames = {
  actionIconName,
  urgentIconName,
  mistakeIconName,
  noBillIconName,
  emergencyIconName,
  mistakeNotYetHandledIconName,
  mistakeHandledIconName
};

export default iconNames;
