import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const Uploader = ({ classes, handleUpload, maxByteSize }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles = removeUnacceptableFiles(acceptedFiles);
      handleUpload(acceptedFiles);
    },
    [handleUpload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: maxByteSize
  });

  const removeUnacceptableFiles = fileArray => {
    const regexAcceptableFileExtensions = /(\.txt|\.lst|\.csv|\.xls)/i;
    const regexAcceptablePrefix = /^callforce_/i;
    const regexNoWhiteSpace = /^\S+$/;
    const acceptableFiles = fileArray.filter(file => {
      return (
        regexAcceptableFileExtensions.test(file.name) &&
        regexAcceptablePrefix.test(file.name) &&
        regexNoWhiteSpace.test(file.name)
      );
    });
    return acceptableFiles;
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    justifyContent: 'center'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      acceptStyle,
      activeStyle,
      baseStyle,
      rejectStyle
    ]
  );

  return (
    <>
      <div {...getRootProps({ style })} className={classes.dropzoneArea}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <span className={classes.dropzoneText}>Drop the files here ...</span>
        ) : (
          <span className={classes.dropzoneText}>
            Drag 'n' drop some files here, or click to select files
          </span>
        )}
      </div>
    </>
  );
};

export default Uploader;
