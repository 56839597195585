import React, { useState } from 'react';
import UrgentIcon from '@material-ui/icons/Error';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import ReloContactData from './ReloContactData';
import DialogEditReloContact from './DialogEditReloContact';
import DialogEditContactCategory from './DialogEditContactCategory';
import DialogEditAppointmentTime from './DialogEditAppointmentTime';
import moment from 'moment-timezone';
import { CALLFORCE_TZ } from '../../../../../services/constants';

const ReloContact = ({
  classes,
  relo,
  contactData,
  allowedToEdit,
  contactId
}) => {
  const [apptTimeOpen, setApptTimeOpen] = useState(false);
  const [contactCategoryOpen, setContactCategoryOpen] = useState(false);
  const [miscOpen, setMiscOpen] = useState(false);
  const [miscItem, setMiscItem] = useState(null);

  return (
    <div className={classes.reloInfoRoot}>
      <div className={classes.dataRow}>
        <div className={classes.dataTitle}>
          Contact #{contactData.id} {contactData.urgent ? <UrgentIcon /> : null}
        </div>
      </div>
      <ReloContactData
        classes={classes}
        data={contactData}
        label='Date'
        name='date'
      />
      <ReloContactData
        classes={classes}
        setContactCategoryOpen={setContactCategoryOpen}
        data={contactData.reason}
        label='Category'
        name='category'
        showEdit={allowedToEdit(contactData)}
        contactId={contactId - 1}
      />
      <ReloContactData
        classes={classes}
        data={contactData.reason}
        label='Subcategory'
        name='subcategory'
        showEdit={allowedToEdit(contactData)}
        setMiscOpen={setMiscOpen}
        setMiscItem={setMiscItem}
        contactId={contactId - 1}
      />
      {contactData.appointmentTime ? (
        <ReloContactData
          classes={classes}
          setApptTimeOpen={setApptTimeOpen}
          data={contactData}
          label='Appointment Time'
          name='appointmentTime'
          showEdit={allowedToEdit(contactData)}
        />
      ) : null}
      <ReloContactData
        classes={classes}
        data={contactData}
        label='Notes'
        name='notes'
        showEdit={allowedToEdit(contactData)}
        setMiscOpen={setMiscOpen}
        setMiscItem={setMiscItem}
        contactId={contactId - 1}
      />
      {contactData.actionTaken ? (
        <ReloContactData
          classes={classes}
          data={contactData}
          label='Action Taken'
          name='actionTaken'
          showEdit={allowedToEdit(contactData)}
          setMiscOpen={setMiscOpen}
          setMiscItem={setMiscItem}
          contactId={contactId - 1}
        />
      ) : null}
      {contactData.needsFollowUp ? (
        <ReloContactData
          classes={classes}
          data={contactData}
          label='Follow Up Needed'
          name='neededFollowUp'
          showEdit={allowedToEdit(contactData)}
          setMiscOpen={setMiscOpen}
          setMiscItem={setMiscItem}
          contactId={contactId - 1}
        />
      ) : null}
      <DialogEditReloContact
        classes={classes}
        relo={relo}
        contact={contactData}
        miscOpen={miscOpen}
        item={miscItem}
        onClose={() => setMiscOpen(false)}
      />
      <DialogEditContactCategory
        classes={classes}
        relo={relo}
        contactCategoryOpen={contactCategoryOpen}
        onClose={() => setContactCategoryOpen(false)}
        contactId={contactId - 1}
      />
      <DialogEditAppointmentTime
        classes={classes}
        relo={relo}
        apptTimeOpen={apptTimeOpen}
        onClose={() => setApptTimeOpen(false)}
        contactId={contactId - 1}
      />
    </div>
  );
};

const mapStateToProps = (state, { contact, contactId }) => {
  const formatDate = time => {
    const contactedOn = time.seconds;

    return moment
      .unix(contactedOn)
      .tz(CALLFORCE_TZ)
      .format('MMM D, YYYY, h:mm a');
  };

  let contactData = {
    reason: {
      category: contact ? contact.reason.category : null,
      subcategory: contact ? contact.reason.subcategory : null
    },
    notes: contact ? contact.notes : null,
    actionItem: contact ? contact.actionItem : false,
    actionTaken: contact ? contact.actionTaken : null,
    needsFollowUp: contact ? contact.needsFollowUp : null,
    neededFollowUp: contact ? contact.neededFollowUp : null,
    urgent: contact ? contact.urgent : null,
    contactedOn: contact ? contact.contactedOn : null,
    date: contact ? formatDate(contact.contactedOn) : null,
    appointmentTime: contact?.appointmentTime
      ? formatDate(contact.appointmentTime)
      : null,
    id: contact ? contactId : null
  };

  return { contactData };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(ReloContact);
