/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withStyles } from '@material-ui/core/styles';
import { firestoreConnect } from 'react-redux-firebase';
import Loader from 'react-loader-spinner';
import {
  Grid,
  Button,
  Typography,
  Hidden,
  Card,
  CardContent
} from '@material-ui/core';
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import defaultCard from 'payment-icons/min/flat/default.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import axios from 'axios';
import CardForm from './CardForm';
import { AppContext } from '../../../../../../../contexts';

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === 'prod'
    ? 'pk_live_2gwJcdMzzpxDT63OYenSDbiz00ToONqscC'
    : 'pk_test_l4Bq12a1u9alLHr7dFU27KKa00hwHwkSP8'
);

const CardSummary = props => {
  const { classes, office, firestore } = props;
  const [hideCardField, setHideCardField] = useState(true);
  const [editingCard, setEditingCard] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [card, setCard] = useState(null);
  const [stripeId, setStripeId] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    state: { firebase }
  } = useContext(AppContext);
  const toggleSaveEditButton = () => {
    setHideCardField(!hideCardField);
    setEditingCard(!editingCard);
  };
  const CARDS = {
    visa,
    mastercard,
    discover,
    amex,
    diners,
    jcb
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken();
      setToken(token);
    };

    if (!token) {
      fetchToken();
    }

    const fetchStripeId = async () => {
      const officeId = office.id;

      try {
        const stripeSnap = await firestore
          .collection('clients')
          .doc(officeId)
          .collection('stripe')
          .doc('customer')
          .get();
        const stripeInfo = stripeSnap.data();

        if (stripeInfo && stripeInfo.id) {
          const token = await firebase.auth().currentUser.getIdToken();

          setToken(token);
          setHasPaymentMethod(true);
          setHideCardField(true);
          setEditingCard(false);
          setStripeId(stripeInfo.id);

          const {
            data: { data }
          } = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/billing-stripe-http-paymentMethods`,
            headers: { Authorization: `Bearer ${token}` },
            params: {
              stripeEnv: process.env.REACT_APP_ENV === 'prod' ? 'live' : 'test',
              customer: stripeInfo.id
            }
          });

          if (data[0]) {
            setCard({
              last4: data[0].card.last4,
              exp_month: data[0].card.exp_month,
              exp_year: data[0].card.exp_year,
              type: data[0].card.brand,
              id: data[0].id
            });
            setLoading(false);
          } else {
            setHasPaymentMethod(false);
            setHideCardField(false);
            setEditingCard(true);
            setLoading(false);
          }
        } else {
          setSaving(false);
          setHasPaymentMethod(false);
          setHideCardField(false);
          setEditingCard(true);
          setLoading(false);
        }
      } catch (e) {
        console.error(e.message);
        setHasPaymentMethod(false);
        setHideCardField(false);
        setEditingCard(true);
        setLoading(false);
      }
    };

    if (token) {
      fetchStripeId();
    }
  }, [office, firebase, firestore, stripeId, token]);

  return (
    <Elements stripe={stripePromise}>
      {errorMessage && <Typography> {errorMessage} </Typography>}
      {loading ? (
        <Loader type='Oval' color='#696969' height='13' width='13' />
      ) : (
        <>
          <Hidden xsUp={!hasPaymentMethod}>
            {card ? (
              <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item xs={12} sm={2}>
                      <img
                        className={classes.cardImage}
                        src={CARDS[card.type] ? CARDS[card.type] : defaultCard}
                        alt={card.type.toUpperCase()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography
                        className={classes.title}
                        color='textSecondary'
                        gutterBottom
                      >
                        **** **** **** {card.last4}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography
                        className={classes.title}
                        color='textSecondary'
                        gutterBottom
                      >
                        {card.exp_month}/{card.exp_year}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.updateButton}>
                      <Button
                        variant='outlined'
                        color='primary'
                        disabled={editingCard}
                        onClick={toggleSaveEditButton}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : null}
          </Hidden>
          <Hidden xsUp={hideCardField}>
            <Grid
              container
              boxshadow={3}
              direction='row'
              justify='flex-start'
              alignItems='center'
              spacing={0}
            >
              <Grid item xs={12}>
                <CardForm
                  token={token}
                  old_id={card ? card.id : null}
                  setCard={setCard}
                  toggleSaveEditButton={toggleSaveEditButton}
                  stripeId={stripeId}
                  office={office}
                  setStripeId={setStripeId}
                  editingCard={editingCard}
                  noCard={!card}
                  setSaving={setSaving}
                  saving={saving}
                  setErrorMessage={setErrorMessage}
                />
              </Grid>
            </Grid>
          </Hidden>
        </>
      )}
    </Elements>
  );
};

const styles = theme => ({
  updateButton: {
    display: 'flex',
    justifyContext: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContext: 'flex-start'
    }
  },
  cardType: {
    textDecoration: 'underline'
  },
  cardImage: {
    width: '50px'
  },
  cardContent: {
    padding: 'unset',
    paddingLeft: 5
  },
  title: {
    fontSize: '1rem'
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  firestoreConnect()
)(CardSummary);
