import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import FilteredSelect from 'react-select';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FormHelperText } from '@material-ui/core';

function CustomNoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customNoOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomInput({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

class CustomControl extends Component {
  render() {
    const { classes, error } = this.props.selectProps;
    const label = this.props.selectProps.label
      ? this.props.selectProps.label
      : 'Employee';
    return (
      <FormControl
        fullWidth
        className={classes.formControl}
        variant='outlined'
        margin='normal'
        error={error ? true : false}
      >
        <InputLabel
          variant='outlined'
          className={classes.label}
          animated='false'
          shrink={true}
          ref={ref => {
            this.labelRef = ReactDOM.findDOMNode(ref);
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          focused='true'
          fullWidth
          labelWidth={100}
          notched={true}
          margin='dense'
          children={this.props.children}
          inputComponent={CustomInput}
          inputProps={{
            className: classes.input,
            inputRef: this.props.innerRef,
            children: this.props.children,
            ...this.props.innerProps
          }}
        />
        <FormHelperText>{error ? error : null}</FormHelperText>
      </FormControl>
    );
  }
}

function CustomOption(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function CustomPlaceholder(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customPlaceholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomSingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.customSingleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomValueContainer(props) {
  return (
    <div className={props.selectProps.classes.customValueContainer}>
      {props.children}
    </div>
  );
}

function CustomMultiValue(props) {
  const chipClass = props.selectProps.classes.chip;
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={
        chipClass
          ? chipClass
          : '' + props.isFocused
          ? props.selectProps.classes.chipFocused
          : ''
      }
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function CustomMenu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
const components = {
  Control: CustomControl,
  Menu: CustomMenu,
  MultiValue: CustomMultiValue,
  NoOptionsMessage: CustomNoOptionsMessage,
  Option: CustomOption,
  Placeholder: CustomPlaceholder,
  SingleValue: CustomSingleValue,
  ValueContainer: CustomValueContainer
};

const styles = theme => ({
  root: {
    display: 'flex'
  },
  base: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: '10px 14px',
    paddingRight: '0px'
  },
  customValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  customNoOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  customSingleValue: {
    fontSize: 16
  },
  customPlaceholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    paddingLeft: '14px'
  },
  paper: {
    position: 'relative',
    marginTop: '-8px',
    left: 0,
    right: 0
  },
  label: {
    backgroundColor: theme.palette.background.default,
    paddingRight: '5px',
    paddingLeft: '5px'
  }
});

class EmployeeFilteredSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { employees: [], ready: false };

    this.getEmployees().then(emps => {
      let previouslySelectedEmployeeId;
      if (this.props.previouslySelectedEmployeeId) {
        previouslySelectedEmployeeId = emps.find(
          emp => emp.id === this.props.previouslySelectedEmployeeId
        );
      }
      this.setState({
        ...this.state,
        employees: emps,
        ready: true,
        currentSelectedEmployee: previouslySelectedEmployeeId
      });
    });
  }
  getEmployees() {
    return this.props.firestore
      .collection('employees')
      .where('active', '==', true)
      .get()
      .then(snapshot => {
        return Promise.resolve(
          snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
          })
        );
      });
  }
  getOptionLabel(option) {
    return `${option.firstName} ${option.lastName}`;
  }
  getOptionValue(option) {
    return option.id;
  }
  handleChange = employee => {
    this.props.onChange(employee);
    this.setState({
      ...this.state,
      currentSelectedEmployee: employee
    });
  };
  render() {
    const { classes, theme, label, error } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    };
    const maxMenuHeight = this.props.maxMenuHeight
      ? this.props.maxMenuHeight
      : 250;
    const minMenuHeight = this.props.minMenuHeight
      ? this.props.minMenuHeight
      : 100;
    return (
      <div className={classes.root}>
        <FilteredSelect
          className={classes.base}
          classes={classes}
          styles={selectStyles}
          options={this.state.employees}
          onChange={this.handleChange}
          components={components}
          value={this.state.currentSelectedEmployee}
          isLoading={!this.state.ready}
          isDisabled={!this.state.ready}
          isClearable={true}
          getOptionValue={this.getOptionValue}
          getOptionLabel={this.getOptionLabel}
          maxMenuHeight={maxMenuHeight}
          minMenuHeight={minMenuHeight}
          label={label}
          error={error}
        ></FilteredSelect>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(EmployeeFilteredSelect);
