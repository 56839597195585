import {
  DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK,
  DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM
} from './types';

export const dialogEditOfficeFive9DataClick = status => ({
  type: DIALOG_EDIT_OFFICE_FIVE9_DATA_CLICK,
  payload: status
});

export const dialogEditOfficeFive9DataItem = item => ({
  type: DIALOG_EDIT_OFFICE_FIVE9_DATA_ITEM,
  payload: item
});
