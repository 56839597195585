import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  dialogDeleteTeamMembersItem,
  dialogDeleteTeamMembersClick
} from '../../../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';

const DialogDeleteTeamMember = ({
  classes,
  item,
  fullScreen,
  firestore,
  open,
  dialogDeleteTeamMembersItem,
  dialogDeleteTeamMembersClick,
  initialValues
}) => {
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onClose = () => {
    setLoadingAfterSubmit(false);
    dialogDeleteTeamMembersClick(!open);
    dialogDeleteTeamMembersItem(null);
  };

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    const {
      key,
      data: { id }
    } = item;
    const ref = { collection: 'teams', doc: id };
    try {
      await firestore.update(ref, { [key]: values });
      onClose();
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth='md'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Remove Team Member
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentTeamLead}>
        <DialogContentText className={classes.dialogText}>
          Please confirm the removal of '{item && item.memberName}.'
        </DialogContentText>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogDeleteTeamMembersOpen: open, dialogDeleteTeamMembersItem: item },
  props
) => {
  let initialValues = item
    ? item.data[item.key].filter(member => member !== item.memberId)
    : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogDeleteTeamMembersClick,
    dialogDeleteTeamMembersItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogDeleteTeamMember);
