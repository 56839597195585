const appBarHeight = 64;
const appBarHeightSm = 56;
const headingHeight = 64;

export const styles = theme => ({
  clientGroupBreadcrumb: {
    fontSize: 16,
    color: '#d2d2d2',
    marginLeft: 6,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: { marginLeft: 0 }
  },
  clientGroupNameBackIcon: {
    padding: 0,
    '& svg': { fontSize: '24px !important' }
  },
  clientGroupNameHeading: {
    height: headingHeight,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: 0,
    alignItems: 'center',
    position: 'fixed',
    top: appBarHeight,
    width: '100%',
    zIndex: 1100,
    [theme.breakpoints.down('xs')]: {
      top: appBarHeightSm
    },
    '& span': {
      color: '#ffffff',
      marginRight: 6,
      '& svg': {
        fontSize: theme.spacing.unit * 1.7
      }
    }
  },
  clientGroupNameHeadText: {
    color: 'white',
    lineHeight: 1,
    fontSize: '1.55rem',
    marginTop: 6,
    '& svg': {
      fontSize: theme.spacing.unit * 2,
      marginLeft: 4
    }
  },
  clientGroupNameOfferingIcons: {
    '& > span': {
      margin: 0
    }
  },
  clientGroupNameText: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: 120,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  detailsPaper: {
    minHeight: theme.containers.paper.height.min,
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  clientGroupInfoRoot: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  dataRow: { display: 'flex', marginBottom: 24 },
  dataLabel: {
    width: '20%',
    marginRight: 5,
    '& > div:first-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    [theme.breakpoints.down('sm')]: { width: '30%' }
  },
  dataText: {
    width: '80%',
    color: '#212121',
    '& svg': { fontSize: 12, marginRight: 4 },
    [theme.breakpoints.down('sm')]: { width: '70%' }
  },
  dataTextRecallPlus: {
    width: '80%',
    color: '#212121',
    '& svg': { fontSize: 12, marginRight: 8 },
    [theme.breakpoints.down('sm')]: { width: '70%' }
  },
  recallplusContainer: { display: 'flex', alignItems: 'center' },
  recallplusIcon: { fontSize: 12, marginRight: '0px !important' },
  recallplusAddIcon: {
    fontSize: '8px !important',
    marginRight: '4px !important'
  },
  recallplusAddIconTitle: {
    fontSize: '8px !important',
    margin: '0px -1px 2px -1px !important'
  },
  dataEdit: {
    fontSize: 12,
    '&:hover': { cursor: 'pointer' }
  },
  dayHoursContainer: { display: 'flex' },
  dayHoursDay: {
    width: '5%',
    [theme.breakpoints.down('xs')]: { width: '15%' }
  },
  dayHoursHours: {
    width: '95%',
    [theme.breakpoints.down('xs')]: { width: '85%' }
  },
  activeStatus: { '& svg': { color: theme.palette.success.main } },
  inactiveStatus: { '& svg': { color: theme.palette.danger.main } },
  pausedStatus: { '& svg': { color: theme.palette.warning.main } },
  onboardingStatus: { '& svg': { color: '#ff9900' } },
  failedOnboardingStatus: { '& svg': { color: theme.palette.danger.main } },
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  settingDialog: {
    minWidth: theme.spacing.unit * 120,
    [theme.breakpoints.down('md')]: {
      minWidth: 200
    }
  },
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    minHeight: 56,
    display: 'flex',
    padding: '0px 16px'
  },
  dialogWarningTitleArea: {
    backgroundColor: '#fe5252',
    minHeight: 56,
    padding: '0px 16px'
  },
  dialogWarningTitleRow: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogWarningTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingBottom: 16
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  dialogWarningTitleTextArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    '& svg': { marginRight: 10 }
  },
  dialogWarningSubtitleTextArea: { padding: '0px 0px 16px 0px', maxWidth: 500 },
  dialogWarningSubtitle: { fontSize: 15, color: '#ffffff' },
  dialogTitle: { color: 'white', fontSize: theme.spacing.unit * 2.5 },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': { color: 'white' }
  },
  dialogContent: {
    paddingBottom: (theme.spacing.unit * 3) / 2
  },
  dialogForm: {
    marginTop: 12,
    width: '100%',
    [theme.breakpoints.up('md')]: { width: 500 }
  },
  dialogErrorText: {
    textAlign: 'center',
    color: '#F44336',
    fontStyle: 'italic'
  },
  dialogTextField: {
    width: '100%'
  },
  dialogButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
    marginBottom: 12
  },
  dialogButtonContainerWithText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12
  },
  dialogFormErrorText: {
    fontSize: 14,
    fontStyle: 'italic',
    color: theme.palette.danger.main
  },
  dialogLoadingText: {
    fontSize: 14,
    fontStyle: 'italic'
  },
  dialogCheckboxLabelContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'space-between',
    justifyContent: 'center'
  },
  dialogCheckbox: { display: 'flex', flex: 1 },
  dialogCheckboxLabel: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 16
  },
  dialogDayContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'space-between',
    alignItems: 'center',
    paddingRight: 24,
    [theme.breakpoints.down('xs')]: { display: 'block' }
  },
  dialogOpenTime: {
    flex: 6,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0',
      padding: '0'
    }
  },
  dialogTimeSeparator: {
    flex: 1,
    padding: '0 5px 0 5px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: { display: 'none' }
  },
  dialogCloseTime: {
    flex: 6,
    [theme.breakpoints.down('sm')]: { width: '100%', padding: '0' }
  },
  dialogClosed: {
    flex: 3,
    padding: '0 0 0 5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      padding: '0'
    }
  },
  dialogSubSectionTitle: {
    width: '100%',
    textTransform: 'uppercase',
    marginTop: 8
  },
  dialogFormOrSeparator: {
    backgroundColor: '#ffffff',
    width: '10%',
    margin: '-10px auto 22px auto'
  },
  dialogFormSection: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    textAlign: 'center',
    marginTop: '7px'
  },
  sectionTitle: {
    marginBottom: 16,
    fontSize: 16
  },
  settingsContainer: {
    minHeight: theme.containers.paper.height.min,
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm },
    '& > div': {
      marginTop: 30
    },
    '& > div:first-child': {
      marginTop: 0
    },
    '& > div:last-child': {
      marginBottom: 48
    }
  },
  sectionContainer: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  dialogFormSectionHeader: { fontSize: 14, marginTop: 16, marginBottom: 8 },
  settingItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  settingItemObj: {
    display: 'flex',
    flexDirection: 'column'
  },
  settingItemValues: {
    display: 'flex',
    flexDirection: 'column'
  },
  editButton: {
    fontSize: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
