import axios from 'axios';
import qs from 'qs';
import { CLOUD_FUNC_BASE_URL } from '../constants';

export const ALLOWED_IPs = Object.freeze([
  '97.75.167.230', // callforce office static IP
  '38.95.111.10', // VPN IP - US
  '65.181.53.70', // new office static IP
  '67.213.247.231', // Mortenson IP address
  '177.54.148.160' // VPN IP - South America
]);

const CREATE_USER_URL = `${CLOUD_FUNC_BASE_URL}/createUser`;

export const addUsers = (users, office) =>
  new Promise(async (resolve, reject) => {
    try {
      const promises = [];

      users.forEach(user => {
        promises.push(addUser(user, office));
      });

      const r = await Promise.all(promises);

      return resolve(r);
    } catch (e) {
      return reject(e.toString());
    }
  });

const addUser = (user, office) =>
  new Promise(async (resolve, reject) => {
    const profile = {
      email: '',
      firstName: '',
      lastName: '',
      offices: [office],
      username: ''
    };
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };

    for (const key in user) {
      if (key === 'email') {
        profile.email = user[key];
        profile.username = user[key].split('@')[0];
      } else {
        profile[key] = user[key];
      }
    }

    try {
      await axios.post(
        CREATE_USER_URL,
        qs.stringify({ email: profile.email, profile }),
        config
      );

      return resolve();
    } catch (e) {
      return reject(e.toString());
    }
  });
