import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import columns from './columns';
import DataTable from '../../../../components/DataTable';
import { Add } from '@material-ui/icons';
import queryString from 'query-string';
import { IconButton, Tooltip } from '@material-ui/core';
import DialogAddVerification from './components/DialogAddVerification';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { AppContext } from '../../../../contexts';

function InsuranceVerifications(props) {
  let { location } = props;
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [offices, setOffices] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        let offices = await firebase
          .firestore()
          .collection('clients')
          .where('offerings.insuranceVerification', '==', true)
          .where('status', '==', 'active')
          .get();

        offices = offices.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });

        offices = _.sortBy(offices, 'officeInformation.name');

        setOffices(offices);
      } catch (e) {
        console.error(e);
      }
    };

    if (!offices.length) {
      fetchOffices();
    }
  }, [offices, firebase]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let employees = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        employees = employees.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        setEmployees(employees);
      } catch (e) {
        console.error(e);
      }
    };

    if (!employees.length) {
      fetchEmployees();
    }
  }, [employees, firebase]);

  useEffect(() => {
    (async () => {
      const dialogDataParam = queryString.parse(props.location.search)
        .dialogData;
      if (dialogDataParam) {
        setDialogOpen(!!dialogDataParam);
        // it's open, if there's an id in the dataParam, then we're gonna need to pass in some data for an existing item
        let data = JSON.parse(dialogDataParam);
        if (data.id) {
          let doc = await props.firestore
            .collection('insuranceVerifications')
            .doc(data.id)
            .get();
          setDialogData(doc);
        } else {
          setDialogData({});
        }
      }
    })();
  }, [location, props.location.search, props.firestore]);

  return (
    <>
      <DataTable
        {...props}
        key={offices?.length ?? 0}
        collection='insuranceVerifications'
        clearSearchPath={`/data/${props.tabId}`}
        columns={columns(offices)}
        sceneUri='data'
        title='Verifications'
        tableFilterable={false}
        onRowClickLink={(data, pathname) => {
          const dialogData = JSON.stringify({ id: data.id });
          let path = pathname;
          let search;
          const searchObj = queryString.parse(pathname.split('?')[1]);
          delete searchObj.refreshData;
          if (pathname.indexOf('dialogData') > -1) {
            delete searchObj.dialogData;
            search = queryString.stringify(searchObj);
          }
          path = search
            ? `${pathname.split('?')[0]}?${search}`
            : `${pathname.split('?')[0]}`;
          const separator = path.indexOf('?') > -1 ? '&' : '?';
          const term = searchObj.term ? `&term=${searchObj.term}` : '';

          return `${path}${separator}dialogData=${dialogData}${term}`;
        }}
        filterData={{}}
        tableActionIcons={[
          {
            render: key => (
              <span key={key}>
                <Tooltip title='Add Verification'>
                  <IconButton
                    variant='contained'
                    color='primary'
                    aria-label='Add Verification'
                    onClick={() => {
                      const searchObj = queryString.parse(
                        props.location.search
                      );
                      delete searchObj.refreshData;
                      delete searchObj.dialogData;
                      const search = queryString.stringify(searchObj);
                      const { pathname } = props.location;
                      const param =
                        search === '' ? '?dialogData={}' : '&dialogData={}';
                      props.history.push(`${pathname}${search}${param}`);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </span>
            )
          }
        ]}
      ></DataTable>
      {dialogOpen ? (
        <DialogAddVerification
          collection='insuranceVerifications'
          offices={offices}
          employees={employees}
          item={dialogData}
          officesPromise={offices}
          dialogOpen={dialogOpen}
          route={props.route}
          dialogOnClose={refreshTable => {
            const searchObj = queryString.parse(location.search);

            setDialogOpen(false);
            setDialogData(null);

            if (refreshTable) searchObj.refreshData = true;
            delete searchObj.dialogData;
            const search = queryString.stringify(searchObj);
            props.history.push(`${location.pathname}?${search}`);
          }}
        ></DialogAddVerification>
      ) : null}
    </>
  );
}

export default compose(withRouter, firestoreConnect())(InsuranceVerifications);
