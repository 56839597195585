import { DIALOG_DELETE_TEAM_CLICK, DIALOG_DELETE_TEAM_ITEM } from './types';

export const dialogDeleteTeamClick = status => ({
  type: DIALOG_DELETE_TEAM_CLICK,
  payload: status
});

export const dialogDeleteTeamItem = item => ({
  type: DIALOG_DELETE_TEAM_ITEM,
  payload: item
});
