import _ from 'lodash';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import sitemap from '../../../sitemap';
import { AppContext } from '../../../contexts';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { t } from 'typy';

const styles = theme => ({
  root: {
    '& .active span': {
      fontWeight: 'bold'
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 5,
    '& svg': {
      fontSize: theme.typography.pxToRem(18)
    },
    '& span': {
      fontSize: theme.typography.pxToRem(15)
    }
  }
});

const DrawerItemGroup = ({ classes, route, setDrawerOpen }) => {
  const [open, setOpen] = useState(true);
  let routes = route;

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>{route.metadata.icon()}</ListItemIcon>
        <ListItemText inset primary={route.metadata.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {Object.keys(routes).map((key, i) =>
            key !== 'metadata' ? (
              <DrawerItem
                key={i}
                classes={classes}
                nested
                route={routes[key]}
                setDrawerOpen={setDrawerOpen}
              />
            ) : null
          )}
        </List>
      </Collapse>
    </>
  );
};

const DrawerItem = ({ classes, nested, route, setDrawerOpen }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
  const to = route.metadata.route;

  if (
    route.metadata.hide ||
    (route.metadata.restricted &&
      _.intersection(userGroups, readGroups).length <= 0)
  ) {
    return null;
  }

  return (
    <NavLink to={to}>
      <ListItem
        button
        onClick={setDrawerOpen}
        className={nested ? classes.nested : null}
      >
        <ListItemIcon>{route.metadata.icon()}</ListItemIcon>
        <ListItemText primary={route.metadata.title} />
      </ListItem>
    </NavLink>
  );
};

const DrawerList = ({ classes, setDrawerOpen }) => (
  <List className={classes.root}>
    {Object.keys(sitemap).map((key, i) =>
      t(sitemap[key], 'metadata.routesType').safeString === 'scenes' ? (
        <DrawerItemGroup
          key={i}
          classes={classes}
          route={sitemap[key]}
          setDrawerOpen={setDrawerOpen}
        />
      ) : (
        <DrawerItem
          key={i}
          classes={classes}
          route={sitemap[key]}
          setDrawerOpen={setDrawerOpen}
        />
      )
    )}
  </List>
);

DrawerItemGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  setDrawerOpen: PropTypes.func.isRequired
};

DrawerItem.propTypes = {
  classes: PropTypes.object.isRequired,
  nested: PropTypes.bool,
  route: PropTypes.object.isRequired,
  setDrawerOpen: PropTypes.func.isRequired
};

DrawerList.propTypes = {
  classes: PropTypes.object.isRequired,
  setDrawerOpen: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(DrawerList);
