/* eslint-disable no-nested-ternary */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Audio from './Audio';
import './styles.css';

const VoicemailTable = props => {
  const { classes, voicemails, setVoicemails, loading } = props;
  return (
    <Paper className={classes.tableRoot}>
      <Table aria-label='simple table' className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableTools}>
            <Hidden mdDown>
              <TableCell colSpan={4} className={classes.stickyHeaders}>
                <Typography
                  variant='h6'
                  className={classes.tableTitle}
                >{`Total Number of Voicemails (${voicemails.length})`}</Typography>
              </TableCell>
            </Hidden>
            <Hidden mdUp>
              <TableCell colSpan={3} className={classes.stickyHeaders}>
                <Typography
                  variant='h6'
                  className={classes.tableTitle}
                >{`Total Number of Voicemails (${voicemails.length})`}</Typography>
              </TableCell>
            </Hidden>
          </TableRow>
          <TableRow className={classes.tableColumnHeaders}>
            <TableCell align='left' className={classes.stickyHeadersTitles}>
              Office
            </TableCell>
            <Hidden mdDown>
              <TableCell align='left' className={classes.stickyHeadersTitles}>
                Date
              </TableCell>
            </Hidden>
            <TableCell align='left' className={classes.stickyHeadersTitles}>
              Phone Number
            </TableCell>
            <TableCell align='center' className={classes.stickyHeadersTitles}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {voicemails[0] ? (
            voicemails.map(voicemail => (
              <TableRow
                className={classes.tableRow}
                key={`${voicemail.callId}`}
              >
                <TableCell align='left' component='th' scope='row'>
                  {voicemail.name}
                </TableCell>
                <Hidden mdDown>
                  <TableCell align='left'>
                    {moment(voicemail.date).format('MMMM Do, YYYY')}
                  </TableCell>
                </Hidden>
                <TableCell align='left'>{voicemail.number}</TableCell>
                <TableCell align='center'>
                  <Audio
                    file={voicemail.file}
                    voicemails={voicemails}
                    callId={voicemail.callId}
                    filePath={voicemail.filePath}
                    docId={voicemail.recordingsDocId}
                    hasListened={voicemail.employeeListened}
                    setVoicemails={setVoicemails}
                    skillId={voicemail.skillId}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : loading ? (
            <TableRow key='loading'>
              <TableCell align='center' scope='row' colSpan={4}>
                <Loader type='Oval' color='black' height='20' width='20' />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow key='loading'>
              <TableCell align='center' scope='row' colSpan={4}>
                There are no current voicemails
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = theme => ({
  stickyHeaders: {
    width: '100%',
    top: -1,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    backgroundColor: '#F5F5F5'
  },
  stickyHeadersTitles: {
    top: 68,
    left: 0,
    zIndex: 1,
    position: 'sticky',
    backgroundColor: '#F5F5F5',
    borderTop: '.5px solid rgba(224, 224, 224, 1)'
  },
  tableRoot: {
    height: 440,
    overflow: 'auto',
    marginTop: theme.spacing.unit
  },
  tableTools: {
    height: 69
  },
  table: {
    borderCollapse: 'separate'
  },
  tableColumnHeaders: {
    height: 24,
    backgroundColor: '#F5F5F5'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  tableTitle: {
    marginRight: 'auto'
  }
});
const mapStateToProps = (state, { voicemails }) => {
  return {
    voicemails
  };
};
export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(VoicemailTable);
