import React from 'react';
import qs from 'qs';
import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { t } from 'typy';
import { styles } from './styles';
import OfficeUsersArray from './OfficeUsersArray';

const OfficeUsers = ({
  classes,
  change,
  formData,
  formSectionValues,
  onSubmit,
  handleSubmit,
  previousPage
}) => (
  <form onSubmit={handleSubmit(() => onSubmit(formSectionValues))}>
    <div className={classes.sectionTitle}>
      <Typography variant='subtitle2'>Office Users</Typography>
    </div>
    <div className={classes.users}>
      <FieldArray
        name='officeUsers'
        formData={formData}
        change={change}
        component={OfficeUsersArray}
      />
    </div>
    <div className={classes.sectionActions}>
      <Button
        color='primary'
        type='button'
        onClick={() => previousPage(formSectionValues)}
      >
        Previous
      </Button>
      <Button color='primary' type='submit'>
        Next
      </Button>
    </div>
  </form>
);

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  const selector = formValueSelector('DialogAddOfficeForm');

  return {
    formData,
    formSectionValues: {
      officeUsers: selector(state, 'officeUsers')
        ? selector(state, 'officeUsers')
        : [{}]
    }
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(OfficeUsers);
