import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import AnsweringIcon from '@material-ui/icons/PhoneCallback';
import AvailableIcon from '@material-ui/icons/HourglassFull';
import BaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CorrectionIcon from '@material-ui/icons/SettingsBackupRestore';
import InvoicedIcon from '@material-ui/icons/Receipt';
import OpenIcon from '@material-ui/icons/FolderOpen';
import PaidIcon from '@material-ui/icons/AttachMoney';
import PendingIcon from '@material-ui/icons/CallMade';
import PromotionIcon from '@material-ui/icons/Redeem';
import RecallIcon from '@material-ui/icons/Schedule';
import RemoveIcon from '@material-ui/icons/Remove';
import ReschedulingIcon from '@material-ui/icons/Restore';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import RELOsIcon from '@material-ui/icons/CallMissedOutgoing';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TreatmentIcon from '@material-ui/icons/SettingsBackupRestore';
import UsedIcon from '@material-ui/icons/HourglassEmpty';
import WebsiteChatIcon from '@material-ui/icons/ChatBubbleOutline';
import VoidIcon from '@material-ui/icons/Close';
import theme from '../theme';

const centerContentStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: 20
};

const booleanIcons = {
  active: (fontSize, value) =>
    value ? (
      <CheckIcon style={{ fontSize, color: theme.palette.success.main }} />
    ) : (
      <RemoveIcon style={{ fontSize, color: theme.palette.danger.main }} />
    )
};

const offeringIcons = {
  answering: (fontSize, key) => (
    <AnsweringIcon style={{ fontSize }} key={key} />
  ),
  base: (fontSize, key) => <BaseIcon style={{ fontSize }} key={key} />,
  recall: (fontSize, key) => <RecallIcon style={{ fontSize }} key={key} />,
  recallplus: (fontSize, key) => (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      key={key}
    >
      <RecallIcon style={{ fontSize, marginRight: '0px !important' }} />
      <AddIcon
        style={{
          fontSize: fontSize - fontSize * 0.2
        }}
      />
    </span>
  ),
  rescheduling: (fontSize, key) => (
    <ReschedulingIcon style={{ fontSize }} key={key} />
  ),
  relos: (fontSize, key) => <RELOsIcon style={{ fontSize }} key={key} />,
  insuranceVerification: (fontSize, key) => (
    <DoneOutlineIcon style={{ fontSize }} key={key} />
  ),
  treatment: (fontSize, key) => (
    <TreatmentIcon style={{ fontSize }} key={key} />
  ),
  websiteChat: (fontSize, key) => (
    <WebsiteChatIcon style={{ fontSize }} key={key} />
  )
};

const icons = {
  ...offeringIcons,
  booleanIcons,
  active: fontSize => (
    <span style={centerContentStyles}>
      <StatusIcon style={{ fontSize, color: theme.palette.success.main }} />
    </span>
  ),
  failedOnboarding: fontSize => (
    <span style={centerContentStyles}>
      <StatusIcon style={{ fontSize, color: theme.palette.danger.main }} />
    </span>
  ),
  inactive: fontSize => (
    <span style={centerContentStyles}>
      <StatusIcon style={{ fontSize, color: theme.palette.danger.main }} />
    </span>
  ),
  onboarding: fontSize => (
    <span style={centerContentStyles}>
      <StatusIcon style={{ fontSize, color: theme.palette.onboarding.main }} />
    </span>
  ),
  paused: fontSize => (
    <span style={centerContentStyles}>
      <StatusIcon style={{ fontSize, color: theme.palette.warning.main }} />
    </span>
  ),
  available: fontSize => <AvailableIcon style={{ fontSize }} />,
  correction: fontSize => <CorrectionIcon style={{ fontSize }} />,
  open: fontSize => <OpenIcon style={{ fontSize }} />,
  promotion: fontSize => <PromotionIcon style={{ fontSize }} />,
  invoiced: fontSize => <InvoicedIcon style={{ fontSize }} />,
  paid: fontSize => (
    <PaidIcon style={{ color: theme.palette.success.main, fontSize }} />
  ),
  pending: fontSize => <PendingIcon style={{ fontSize }} />,
  setup: fontSize => <BuildOutlined style={{ fontSize }} />,
  used: fontSize => <UsedIcon style={{ fontSize }} />,
  void: fontSize => (
    <VoidIcon style={{ color: theme.palette.danger.main, fontSize }} />
  ),
  offerings: (fontSize, object) => {
    const obj = object;
    if (obj.recall && obj.recallplus) delete obj.recall;

    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: 20
        }}
      >
        {Object.keys(obj).map((offering, index) =>
          obj[offering] ? offeringIcons[offering](fontSize, index) : null
        )}
      </span>
    );
  }
};

export default icons;
