/* eslint-disable no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-named-as-default-member */

import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  FieldCheckbox,
  FieldPhone,
  FieldSelect,
  FieldText,
  DecoratedFieldText
} from '../../../../../components/Fields';
import { phone, required } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import helpers from '../../../../../helpers';
import ManageEntryFields from './ManageEntryFields';
import TableRecording from '../../../../../components/TableRecording';
import { notScheduledEntryTypes } from '../../../../../services/options';
import EmployeeFilteredSelect from '../../../../../components/EmployeeFilteredSelect';
import { validations as serviceValidation } from '../../../../../services/validation';
import { specialNotesCategories } from '../../../common/SpecialNotes';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const NotScheduledItemForm = ({
  classes,
  dialogData,
  manConnectData,
  dialogOnClose,
  route,
  item,
  employees,
  offices
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [showSpinnerWhenLoading, setShowSpinnerWhenLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [notesTemplate, setNotesTemplate] = useState('');
  const [validateError, setValidateError] = useState(null);
  const [needsFollowUp, setNeedsFollowUp] = useState(
    item && item.neededFollowUp ? true : false
  );
  const [actionItem, setActionItem] = useState(false);
  const [actionNeeded, setActionNeeded] = useState(
    item && item.actionTaken ? true : false
  );
  const [reasonForCall, setReasonForCall] = useState(
    item && item.reason && item.reason.notes ? true : false
  );

  const [reasonForCallChanged, setReasonForCallChanged] = useState(false);
  const {
    state: { auth, firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const deleteGroups = t(route, 'metadata.permissions.delete').safeArray || [];
  const [doNotBillReasons, setDoNotBillReasons] = useState([]);
  const [mistakeReasons, setMistakeReasons] = useState([]);
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToDelete =
    _.intersection(userGroups, deleteGroups).length > 0;
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;

  const NeedsFollowUpIcon = () => {
    return needsFollowUp ? (
      <RemoveIcon />
    ) : (
      <AddCircleIcon
        onClick={() => {
          setActionItem(!actionItem);
        }}
      />
    );
  };

  const ActionNeededIcon = () => {
    return actionNeeded ? <RemoveIcon /> : <AddCircleIcon />;
  };

  const ReasonForCallIcon = () => {
    return reasonForCall ? <RemoveIcon /> : <AddCircleIcon />;
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (actionItem || values.neededFollowUp) {
      values.actionItem = true;
    }
    if (disableDropDownBasedOnUserGroupAndFreshEntry()) {
      values.entryType = helpers.lowercaseFirstLetter(values.entryType);
    }
    if (item && values.createdBy.employeeId) {
      const {
        createdBy: { employeeId, employeeFirstName, employeeLastName }
      } = values;
      const createdByName = `${employeeFirstName} ${employeeLastName}`;
      values.createdBy = employeeId;
      values.createdByName = createdByName;
    }
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    helpers
      .transformEntryDataForSave(firebase, auth, values)
      .then(data => data)
      .then(data => helpers.saveEntryData(firebase, data, 'notScheduled'))
      .then(() => {
        dialogOnClose(true);
        setError(null);
        setLoading(null);
        setSubmitting(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(null);
        setSubmitting(false);
      });
  };
  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          {showSpinnerWhenLoading ? (
            <Loader type='Oval' color='#696969' height='12' width='12' />
          ) : null}
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };
  const renderConfirmDelete = () => (
    <>
      <Button
        color='secondary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
          setError(null);
          setLoading(`Deleting Entry; Please Keep Browser Window Open`);
          helpers
            .deleteEntry(firebase, dialogData.id, 'notScheduled')
            .then(() => {
              dialogOnClose(true);
              setError(null);
              setLoading(null);
            })
            .catch(err => {
              setError(err.toString());
              setLoading(null);
              setShowSpinnerWhenLoading(true);
            });
        }}
      >
        Yes, Delete it
      </Button>
      <Button
        color='primary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setError(null);
          setLoading(null);
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
        }}
      >
        No, Nevermind
      </Button>
    </>
  );
  const renderActionButtons = isSubmitting => (
    <>
      {userAllowedToDelete && dialogData ? (
        <Button
          color='secondary'
          type='button'
          onClick={() => {
            setShowSpinnerWhenLoading(false);
            setLoading('Please confirm...');
            setConfirmDelete(true);
          }}
          disabled={isSubmitting || loading !== null}
        >
          Delete
        </Button>
      ) : null}
      <Button
        color='primary'
        type='submit'
        disabled={isSubmitting || loading !== null}
      >
        Save
      </Button>
    </>
  );
  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(_.toLower(selectName))}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(_.toLower(selectName))}s
        </span>
      );
    }
    return vanityName || _.startCase(_.toLower(selectName));
  };
  const validations = {
    office: required,
    patientName: required,
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    spanishSpEmp: required,
    entryType: required,
    reason: {
      category: required,
      subCategory: required
    }
  };

  const generateNewCategoryNotes = (
    patientName,
    notesTemplate,
    previousSubCategory,
    currentNotes,
    previousSubCategirynotesTemplate
  ) => {
    const notes = currentNotes
      ? currentNotes.search(patientName) === -1
        ? currentNotes
        : currentNotes.replace(patientName, '{{ name }}')
      : null;
    let isCustomNote = false;
    let newNote = '';

    if (!notes) {
      newNote = notesTemplate.replace('{{ name }}', patientName);
    }

    if (previousSubCategory) {
      let oldNoteTemplate = _.filter(
        subCategories,
        category => category.id === previousSubCategory
      );

      if (oldNoteTemplate.length > 0) {
        oldNoteTemplate = oldNoteTemplate[0].notesTemplate.replace(
          patientName,
          '{{ name }}'
        );
      } else {
        oldNoteTemplate = previousSubCategirynotesTemplate.replace(
          patientName,
          '{{ name }}'
        );
      }

      if (notes && notes.search(oldNoteTemplate) === -1) {
        isCustomNote = true;
      }

      if (isCustomNote && notes) {
        newNote = notes.replace('{{ name }}', patientName);
      }
      if (!isCustomNote && notes) {
        const oldNote = notes.split(oldNoteTemplate);
        const oldNoteTrimmed = oldNote.map(string => string.trim());
        newNote = `${notesTemplate.replace('{{ name }}', patientName)} ${
          oldNoteTrimmed[1] ? oldNoteTrimmed[1] : ''
        }`;
      }
    }

    if (!previousSubCategory && notes) {
      newNote = `${notesTemplate.replace('{{ name }}', patientName)} ${notes}`;
    }

    return newNote;
  };
  const getSubCategory = category => {
    setSubCategories([]);
    firebase
      .firestore()
      .collection('offeringsCategories')
      .doc(category)
      .collection('subcategories')
      .where('offerings', 'array-contains-any', ['recall', 'treatment'])
      .get()
      .then(snapShots => {
        const subCategoriesDocs = [];
        snapShots.forEach(doc => {
          subCategoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setSubCategories(subCategoriesDocs);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userAllowedToManage) {
          const doNotBillReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('doNotBill')
            .collection('reasons')
            .orderBy('value')
            .get();
          const mistakeReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('mistake')
            .collection('reasons')
            .orderBy('value')
            .get();
          const doNotBillReasonsDocs = [];
          const mistakeReasonsDocs = [];

          doNotBillReasonsQuerySnapshot.forEach(doc => {
            doNotBillReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          mistakeReasonsQuerySnapshot.forEach(doc => {
            mistakeReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          setDoNotBillReasons(doNotBillReasonsDocs);
          setMistakeReasons(mistakeReasonsDocs);
        }
        const categoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .where('offerings', 'array-contains', 'notScheduled')
          .get();
        if (item) {
          getSubCategory(item.reason.category);
        }
        const categoriesDocs = [];

        categoriesSnapshot.forEach(doc => {
          categoriesDocs.push({ ...doc.data(), id: doc.id });
        });
        setCategories(categoriesDocs);
        setLoading(null);
      } catch (err) {
        setLoading(null);
        setCategories(null);
        setError(err.toString());
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [firebase, userAllowedToManage, item]);

  const handleSubCategory = newCategory => {
    if (newCategory !== '') {
      getSubCategory(newCategory);
    }
  };

  const initialValues = dialogData
    ? dialogData
    : manConnectData
    ? manConnectData
    : {};

  const validate = value => {
    if (serviceValidation.required(value)) {
      setValidateError('Required');
      return true;
    } else {
      setValidateError(null);
      return false;
    }
  };

  const returnUserGroup = () => {
    const relevantGroups = [];
    for (const group in userGroups) {
      if (
        userGroups[group] === 'treatment' ||
        userGroups[group] === 'scheduled'
      )
        relevantGroups.push(userGroups[group]);
    }

    if (relevantGroups.length === 2 || relevantGroups.length === 0) {
      return false;
    } else {
      return relevantGroups[0] === 'scheduled' ? 'Recall' : 'Treatment';
    }
  };

  if (helpers.isEmpty(initialValues)) {
    initialValues.entryType = returnUserGroup();
  }

  const disableDropDownBasedOnUserGroupAndFreshEntry = () => {
    if (returnUserGroup() && !dialogData) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          {dialogData && userAllowedToManage ? (
            <>
              <ManageEntryFields
                doNotBillReasons={doNotBillReasons}
                loading={loading}
                mistakeReasons={mistakeReasons}
                selectLabel={selectLabel}
                validations={validations}
                values={values}
              />
            </>
          ) : null}
          {dialogData ? (
            <>
              <div className={classes.sectionTitle}>
                <Typography variant='subtitle2'>Entry Recording</Typography>
              </div>
              <div className={classes.row}>
                <TableRecording
                  callId={item ? item.callId : null}
                  createdTime={item ? item.createdTime : null}
                  firestore={firebase}
                />
              </div>
            </>
          ) : null}
          <div className={classes.sectionTitle}>
            <Typography variant='subtitle2'>Entry Details</Typography>
          </div>
          <div className={classes.row}>
            <Field name='patientName' validate={validations.patientName}>
              {({ form, field }) => {
                return (
                  <FieldText
                    form={form}
                    field={field}
                    label='Patient Name'
                    variant='outlined'
                    onChange={e => {
                      field.onBlur(e);
                      field.onChange(e);
                      if (values.reason) {
                        if (e.target.value === '') {
                          values.reason.notes = '';
                        } else if (
                          notesTemplate !== '' &&
                          !values.patientName
                        ) {
                          values.reason.notes = generateNewCategoryNotes(
                            e.target.value,
                            notesTemplate,
                            null,
                            values.reason.notes,
                            null
                          );
                        } else {
                          values.reason.notes = values.reason.notes.replace(
                            values.patientName,
                            e.target.value
                          );
                        }
                      }
                    }}
                  />
                );
              }}
            </Field>
            <Field
              name='patientPhone'
              label='Patient Phone'
              variant='outlined'
              component={FieldPhone}
              disabled={loading !== null}
              validate={validations.patientPhone}
            />
          </div>
          <div className={classes.row}>
            <Field name='patientId'>
              {({ form, field }) => (
                <FieldText
                  form={form}
                  field={field}
                  label='Patient ID'
                  variant='outlined'
                ></FieldText>
              )}
            </Field>
            <FieldCheckbox
              name='newPatient'
              label='New Patient'
              disabled={loading !== null}
              checked={!!values.newPatient}
            />
          </div>
          <Field
            name='entryType'
            loading={notScheduledEntryTypes.length === 0}
            disabled={
              notScheduledEntryTypes.length === 0 ||
              loading !== null ||
              disableDropDownBasedOnUserGroupAndFreshEntry()
            }
            label={selectLabel(notScheduledEntryTypes, 'Entry Type')}
            options={notScheduledEntryTypes}
            optionKey='key'
            optionText='name'
            optionValue='key'
            margin='none'
            component={
              disableDropDownBasedOnUserGroupAndFreshEntry()
                ? FieldText
                : FieldSelect
            }
            variant={
              disableDropDownBasedOnUserGroupAndFreshEntry() ? 'outlined' : null
            }
            validate={validations.entryType}
          />
          <Field
            name='office'
            loading={offices.length === 0}
            disabled={offices.length === 0 || loading !== null}
            label={selectLabel(offices, 'office')}
            options={offices}
            optionKey='id'
            optionText='officeInformation.name'
            optionValue='id'
            margin='none'
            component={FieldSelect}
            validate={validations.office}
          />
          <Field name='reason.category' validate={validations.reason.category}>
            {({ form, field }) => {
              return (
                <FieldSelect
                  margin='none'
                  form={form}
                  field={field}
                  loading={categories.length === 0}
                  disabled={categories.length === 0 || loading !== null}
                  label={selectLabel(categories, 'Category')}
                  options={_.sortBy(categories, 'category')}
                  optionKey='id'
                  optionText='category'
                  optionValue='id'
                  onChange={newCategory => {
                    if (
                      values.reason &&
                      newCategory !== values.reason.category &&
                      values.reason.subcategory
                    ) {
                      values.reason.subcategory = '';
                    }
                    handleSubCategory(newCategory);
                  }}
                />
              );
            }}
          </Field>
          {values.reason ? (
            <>
              <Field
                name='reason.subcategory'
                validate={validations.reason.subCategory}
              >
                {({ form, field }) => {
                  return (
                    <FieldSelect
                      margin='none'
                      form={form}
                      field={field}
                      loading={subCategories.length === 0}
                      disabled={subCategories.length === 0 || loading !== null}
                      label={selectLabel(subCategories, 'Subcategory')}
                      options={_.sortBy(subCategories, 'category')}
                      optionKey='id'
                      optionText='category'
                      optionValue='id'
                      component={FieldSelect}
                      onChange={newSubCategory => {
                        if (!values.reason) {
                          values.reason = {};
                        }
                        if (newSubCategory !== '') {
                          const subCategory = _.filter(
                            subCategories,
                            category => category.id === newSubCategory
                          )[0];
                          if (values.patientName) {
                            values.reason.notes = generateNewCategoryNotes(
                              values.patientName,
                              subCategory.notesTemplate,
                              values.reason ? values.reason.subcategory : null,
                              values.reason.notes,
                              notesTemplate
                            );
                          }
                          setNotesTemplate(subCategory.notesTemplate);
                        } else {
                          values.reason.notes = '';
                        }
                      }}
                    />
                  );
                }}
              </Field>
              <FieldCheckbox
                name='urgent'
                label='Mark as Urgent'
                disabled={loading !== null}
                checked={!!values.urgent}
              />
              <FieldCheckbox
                name='spanishSpEmpHelp'
                label='Spanish-Speaking Caller Helped'
                disabled={loading !== null}
                checked={!!values.spanishSpEmpHelp}
              />
            </>
          ) : null}
          {values.spanishSpEmpHelp ? (
            <Field
              name='spanishSpEmp'
              loading={employees.length === 0}
              disabled={employees.length === 0 || loading !== null}
              label={selectLabel(
                employees,
                'employee',
                'Spanish-Speaking Caller'
              )}
              options={employees}
              optionKey='id'
              optionText={['firstName', 'lastName']}
              optionValue='id'
              component={FieldSelect}
              validate={validations.spanishSpEmp}
            />
          ) : null}
          {values.reason &&
          values.reason.category &&
          specialNotesCategories.includes(values.reason.category) ? (
            <>
              <Field
                name='reason.notes'
                label='Call Notes'
                variant='outlined'
                multiline
                rowsMax={4}
                disabled={loading !== null}
                component={DecoratedFieldText}
                id='reasonForCall'
                oniconclick={() => {
                  if (
                    !reasonForCallChanged &&
                    !reasonForCall &&
                    values.reason &&
                    values.patientName
                  ) {
                    values.reason.notes = generateNewCategoryNotes(
                      values.patientName,
                      notesTemplate,
                      null,
                      values.reason.notes,
                      null
                    );
                    setReasonForCallChanged(!reasonForCallChanged);
                  }
                  setReasonForCall(!reasonForCall);
                }}
                disabler={reasonForCall.toString()}
                iconswitch={reasonForCall.toString()}
                Icon={ReasonForCallIcon}
              />
              <Field
                name='actionTaken'
                variant='outlined'
                multiline
                rowsMax={4}
                disabled={loading !== null}
                component={DecoratedFieldText}
                id='actionTaken'
                label='Action Taken'
                oniconclick={() => {
                  if (actionNeeded) {
                    delete values.actionTaken;
                  }
                  setActionNeeded(!actionNeeded);
                }}
                disabler={actionNeeded.toString()}
                iconswitch={actionNeeded.toString()}
                Icon={ActionNeededIcon}
              />
            </>
          ) : values.reason ? (
            <Field
              name='reason.notes'
              label='Call Notes'
              variant='outlined'
              multiline
              rowsMax={4}
              disabled={loading !== null}
              component={FieldText}
            />
          ) : null}
          {values.reason ? (
            <Field
              name='neededFollowUp'
              variant='outlined'
              multiline
              rowsMax={4}
              disabled={loading !== null}
              component={DecoratedFieldText}
              id='needsFollowUp'
              label='Needed Follow Up'
              oniconclick={() => {
                if (needsFollowUp) {
                  delete values.neededFollowUp;
                }
                setNeedsFollowUp(!needsFollowUp);
              }}
              disabler={needsFollowUp.toString()}
              iconswitch={needsFollowUp.toString()}
              Icon={NeedsFollowUpIcon}
            />
          ) : null}
          {item ? (
            <Field name='createdBy' label='Created By' validate={validate}>
              {({ field }) => {
                return (
                  <EmployeeFilteredSelect
                    label='Created by Employee'
                    error={validateError}
                    onChange={employee => {
                      setFieldValue(
                        field.name,
                        employee
                          ? {
                              employeeId: employee.id,
                              employeeFirstName: employee.firstName,
                              employeeLastName: employee.lastName
                            }
                          : ''
                      );
                    }}
                    previouslySelectedEmployeeId={item ? item.createdBy : null}
                  />
                );
              }}
            </Field>
          ) : null}
          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {confirmDelete
                ? renderConfirmDelete()
                : renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

NotScheduledItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  offices: PropTypes.array,
  employees: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(NotScheduledItemForm);
