import { OFFERINGS_CATEGORIES_INITIALIZE } from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case OFFERINGS_CATEGORIES_INITIALIZE:
      return action.payload;
    default:
      return state;
  }
};
