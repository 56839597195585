import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import HeaderTitleWithTabs from '../../../../../../components/HeaderTitleWithTabs';
import ClientGroupName from './components/ClientGroupName';
import ClientGroupScenes from './components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../../../../contexts';

const ClientGroup = props => {
  const {
    location,
    match: { params }
  } = props;
  const [clientGroup, setClientGroup] = useState(null);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const searchParams = queryString.parse(location.search);
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('clientGroups')
      .doc(params.clientGroupId)
      .onSnapshot(doc => {
        const clientGroup = { ...doc.data(), id: doc.id };
        setClientGroup(clientGroup);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, setClientGroup, params]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const ClientGroupScene = ClientGroupScenes[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: `/clientGroup/${params.clientGroupId}/$tabName?pathname=${searchParams.pathname}`,
        renderComponent: () => (
          <ClientGroupScene
            clientGroup={clientGroup}
            sitemapPath={props.sitemapPath}
            params={params}
            location={location}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      largeAppBar
      params={params}
      renderTitle={() => (
        <ClientGroupName
          clientGroupId={params.clientGroupId}
          pathname={searchParams.pathname}
        />
      )}
      tabId={params.tabId}
      tabs={tabs}
    />
  );
};

ClientGroup.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sitemapPath: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(ClientGroup);
