const OfficeScenes = {};

OfficeScenes.details = require('./Details').default;
OfficeScenes.users = require('./Users').default;
OfficeScenes.reports = require('./Reports').default;
OfficeScenes.billing = require('./Billing').default;
OfficeScenes.voicemails = require('./Voicemails').default;
OfficeScenes.settings = require('./Settings').default;

export default OfficeScenes;
