import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/AddCircleOutline';
import RemoveUserIcon from '@material-ui/icons/RemoveCircleOutline';
import { db } from '../../../../../../../index';
import Select from 'react-select';

const ClientGroupOffices = ({
  values,
  previousPage,
  nextPage,
  FieldArray,
  errors,
  classes
}) => {
  const [offices, setOffices] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const getOffices = async () => {
      const allOffices = [];
      try {
        const offices = await db.collection('clients').get();

        offices.docs.forEach(doc => {
          allOffices.push({
            value: doc.id,
            label: doc.data().officeInformation.name
          });
        });
        setOffices(allOffices);
      } catch (e) {
        console.error(e);
      }
    };

    if (values.offices.length) {
      setFields(values.offices);
    }

    if (!offices.length) {
      getOffices();
    }
  }, [offices.length, values.offices]);

  const createField = field => {
    setFields([...fields, field]);
  };

  const removeField = (index, arrayHelpers) => {
    const newFields = fields.slice();
    newFields.splice(index, 1);
    setFields(newFields);
    arrayHelpers.remove(index);
  };

  const renderSectionActions = arrayHelpers => {
    return (
      <div className={classes.sectionActions}>
        <Button color='secondary' type='reset'>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            previousPage();
          }}
        >
          Back
        </Button>
        <Button
          color='primary'
          onClick={() => {
            nextPage();
          }}
          disabled={!!errors.clientGroupName}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderAddOfficeButton = fields => {
    return (
      <Tooltip title='Add Office'>
        <IconButton
          variant='contained'
          color='secondary'
          aria-label='Add Office'
          onClick={() => createField({})}
        >
          <AddUserIcon className={classes.addIcon} />
        </IconButton>
      </Tooltip>
    );
  };

  const handleChange = (index, value, arrayHelpers) => {
    arrayHelpers.insert(index, value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '56px',
      width: '100%',
      boxShadow: state.isFocused ? null : null
    }),
    container: provided => ({
      ...provided,
      width: '100%'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      marginTop: '20px'
    }),
    valueContainer: provided => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    input: provided => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '30px'
    }),
    menu: provided => ({
      ...provided,
      height: '107px'
    }),
    menuList: provided => ({
      ...provided,
      height: '107px'
    })
  };

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Offices</Typography>
      </div>
      <div className={classes.users}>
        <FieldArray name='offices'>
          {arrayHelpers => {
            return (
              <>
                {fields.map((office, index) => (
                  <span key={index} style={{ display: 'block', width: '100%' }}>
                    <div
                      className={classes.subSectionTitle}
                      style={{
                        marginBottom: 0,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant='caption'>
                        Office #{index + 1}
                      </Typography>
                      <Tooltip title='Remove Office'>
                        <IconButton
                          variant='contained'
                          color='secondary'
                          aria-label='Remove Office'
                          style={{ padding: 0, marginLeft: 8 }}
                          onClick={() => removeField(index, arrayHelpers)}
                        >
                          <RemoveUserIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={classes.officeRow}>
                      <Select
                        className={classes.office}
                        key={'office-selector' + index}
                        label='Office Name'
                        styles={customStyles}
                        onChange={value =>
                          handleChange(index, value, arrayHelpers)
                        }
                        options={offices}
                        placeholder={fields.length ? fields[index].label : null}
                      />
                    </div>
                  </span>
                ))}
                {renderAddOfficeButton(fields)}

                {renderSectionActions(arrayHelpers)}
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

ClientGroupOffices.propTypes = {
  values: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};
export default ClientGroupOffices;
