import moment from 'moment-timezone';
import { CALLFORCE_TZ } from '../../../../services/constants';

export const transactionsColumns = [
  {
    index: 1,
    key: 'clientName',
    essential: true,
    text: 'Office',
    spacing: { xs: 6, sm: 4 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'date',
    essential: true,
    text: 'Date',
    spacing: { xs: 3, sm: 3 },
    formatData: doc => {
      const date = doc.date._seconds ? doc.date._seconds : doc.date.seconds;

      return moment.unix(date).tz(CALLFORCE_TZ).format('MMM D, YYYY');
    },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'desc'
  },
  {
    index: 3,
    key: 'item',
    essential: false,
    text: 'Item',
    spacing: { xs: 3, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'total',
    essential: true,
    text: 'Amount',
    spacing: { xs: 3, sm: 3 },
    formatData: doc =>
      doc.type === 'debit'
        ? `($${parseFloat(doc.total).toFixed(2)})`
        : `$${parseFloat(doc.total).toFixed(2)}`,
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'desc'
  }
];
