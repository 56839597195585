const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#fafafa'
  },
  cardContainer: {
    paddingTop: '25px'
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 350
    },
    width: '90%',
    position: 'relative',
    padding: '0px',
    margin: '0px auto'
  },
  content: {
    padding: '0px'
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  logoutButton: {
    marginLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    }
  },
  header: {
    height: '60px',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px 0px 0px'
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      width: '40%'
    },
    display: 'block',
    width: '40%'
  },
  loginError: {
    textAlign: 'center',
    color: theme.palette.error.main
  },
  textField: {
    width: '100%',
    margin: '16px auto 8px auto',
    '&:hover': {
      borderColor: '#696969'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    margin: '16px 0px 22px 0px'
  },
  socialLogin: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    textAlign: 'center',
    marginTop: '7px'
  },
  socialText: {
    backgroundColor: '#fafafa',
    width: '10%',
    margin: '-10px auto 22px auto'
  },
  googleLogin: {
    width: '100%',
    padding: '8px 14px 8px 14px',
    justifyContent: 'left',
    backgroundColor: '#ffffff',
    marginBottom: '50px'
  },
  googleGLogo: {
    height: '18px',
    width: '18px',
    marginRight: '14px'
  },
  forgotPassword: {
    margin: '16px 0px 22px 0px',
    '& > svg': {
      margin: '16px 0px 22px 0px'
    }
  }
});

export default styles;
