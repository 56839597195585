import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import HeaderTitleWithTabs from '../../../../../../../../../../components/HeaderTitleWithTabs';
import RegionName from './components/RegionName';
import RegionScenes from './components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../../../../../../../../contexts';

const Region = props => {
  const {
    location,
    match: { params }
  } = props;
  const [region, setRegion] = useState(null);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const searchParams = queryString.parse(location.search);
  const regionSitemapPath = '/clientGroup/:clientGroupId/regions';
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const getRegion = firebase
      .firestore()
      .collection('clientGroups')
      .doc(params.clientGroupId)
      .collection('regions')
      .doc(params.regionId)
      .onSnapshot(doc => {
        const region = { ...doc.data(), id: doc.id };
        setRegion(region);
      });

    return () => {
      getRegion();
    };
  }, [firebase, setRegion, params]);

  for (const key in props.tabs) {
    const route = props.tabs[key];
    const RegionScene = RegionScenes[key];

    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: `/clientGroup/${params.clientGroupId}/regions/${params.regionId}/$tabName?pathname=${searchParams.pathname}`,
        renderComponent: () => (
          <RegionScene
            region={region}
            sitemapPath={regionSitemapPath}
            params={params}
            location={location}
            tabId={props.tabId}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  }

  return (
    <HeaderTitleWithTabs
      {...props}
      largeAppBar
      params={params}
      renderTitle={() => (
        <RegionName
          clientGroupRegion={region}
          clientGroupId={params.clientGroupId}
          params={params}
        />
      )}
      tabId={params.tabId}
      tabs={tabs}
    />
  );
};

Region.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Region);
