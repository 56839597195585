import _ from 'lodash';
import countryState from '../options/geoData';
import moment from 'moment-timezone';
import {
  CALL_ANS_SCHEDULED_CATEGORY,
  DAYS,
  defaultOfferingPrices
} from '../options';
import { t } from 'typy';

export const formatAddEditOfficeFormData = item => {
  const usStatesCollection = _.mapKeys(countryState, 'abbreviation').US.states;
  const usStatesObj = _.mapKeys(usStatesCollection, 'abbreviation');
  let vals = {};
  let officeHoursObj = this.state.officeHours;

  vals = JSON.parse(JSON.stringify(item));

  const { officeInformation: info, officeHours, status } = vals;

  if (
    info.address.state &&
    Object.prototype.hasOwnProperty.call(usStatesObj, info.address.state)
  ) {
    vals.country = 'US';
  } else {
    vals.country = 'CA';
  }

  vals.name = info.name.trim();
  vals.doctor = info.doctor.trim();
  vals.phone = info.phone;
  vals.address = info.address.address.trim();
  vals.city = info.address.city.trim();
  vals.state = info.address.state;
  vals.zip = info.address.zip.trim();
  delete vals.officeInformation;

  vals.status = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  _.forEach(officeHours, (val, key) => {
    const openKey = `${key}Open`;
    const closeKey = `${key}Close`;
    const closedKey = `${key}Closed`;
    let openVal;
    let closeVal;
    let closedVal;

    if (val.length === 0) {
      openVal = '';
      closeVal = '';
      closedVal = true;
    } else {
      openVal = val[0];
      closeVal = val[1];
      closedVal = false;
    }

    officeHoursObj = {
      ...officeHoursObj,
      [openKey]: openVal,
      [closeKey]: closeVal,
      [closedKey]: closedVal
    };
  });

  vals.officeHours = officeHoursObj;

  return vals;
};

export const sanitizeAddOfficeValues = values => {
  const formattedValues = {};
  formattedValues.phone = values.phone.replace(/[^0-9]/g, '');
  formattedValues.status = values.status.toLowerCase();
  return values;
};

export const transformOfficeHoursData = values => {
  const data = {};

  DAYS.forEach(day => {
    Object.keys(values).forEach(item => {
      if (item.includes(day.key) && item.includes('Closed')) {
        if (values[item] === true) {
          data[day.key] = [];
        } else {
          data[day.key] = ['00:00', '00:00'];
        }
      }
    });
  });

  Object.keys(data).forEach(item => {
    if (data[item].length > 0) {
      data[item][0] = values[`${item}Open`];
      data[item][1] = values[`${item}Close`];
    }
  });

  return data;
};

export const transformScheduledData = (
  scheduledDataValues,
  adding,
  auth,
  clients,
  newPatient
) =>
  new Promise((resolve, reject) => {
    const values = scheduledDataValues;
    const office = _.mapKeys(clients, 'id')[values.office];
    const officeTimezone = t(office, 'officeInformation.timezone').safeString;
    const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;

    if (adding) {
      const appointmentTime = moment.tz(apptDateTime, officeTimezone);

      delete values.appointmentDate;
      values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
      values.newPatient = newPatient;
      values.createdTime = new Date();
      values.appointmentTime = new Date(appointmentTime.format());
      if (values.newPatient) delete values.lastVisitDate;
      if (auth && !auth.isEmpty) values.createdBy = auth.uid;

      if (values.lastVisitDate && !values.newPatient) {
        const lastVisitDate = moment.tz(values.lastVisitDate, officeTimezone);

        delete values.lastVisitDate;
        values.lastVisitDate = new Date(lastVisitDate.format());
        values.lastVisitDate._seconds = values.lastVisitDate.getTime() / 1000;
      } else {
        delete values.lastVisitDate;
      }

      return resolve(values);
    } else {
      const appointmentTime = moment.tz(apptDateTime, officeTimezone);

      delete values.appointmentDate;
      values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
      values.newPatient = newPatient;
      values.editedTime = new Date();
      values.appointmentTime = new Date(appointmentTime.format());
      if (values.newPatient) delete values.lastVisitDate;
      if (auth && !auth.isEmpty) values.editedBy = auth.uid;

      if (values.lastVisitDate && !values.newPatient) {
        const lastVisitDate = moment.tz(values.lastVisitDate, officeTimezone);

        delete values.lastVisitDate;
        values.lastVisitDate = new Date(lastVisitDate.format());
        values.lastVisitDate._seconds = values.lastVisitDate.getTime() / 1000;
      } else {
        delete values.lastVisitDate;
      }

      return resolve(values);
    }
  });

export const transformEditedSelectedScheduledData = (
  scheduledDataItem,
  officeTimezone
) =>
  new Promise(resolve => {
    const item = scheduledDataItem;
    const apptTime = moment
      .unix(item.appointmentTime.seconds)
      .tz(officeTimezone);
    const appointmentDate = apptTime.format('YYYY-MM-DD');
    const appointmentTime = apptTime.format('HH:mm');

    delete item.appointmentTime;
    item.appointmentDate = appointmentDate;
    item.appointmentTime = appointmentTime;

    if (item.lastVisitDate) {
      const lastVisitDate = moment
        .unix(item.lastVisitDate.seconds)
        .tz(officeTimezone)
        .format('YYYY-MM-DD');

      delete item.lastVisitDate;
      item.lastVisitDate = lastVisitDate;
    }

    return resolve(item);
  });

export const transformCallsAnsweredData = (
  callsAnsweredDataValues,
  adding,
  auth,
  clients,
  newPatient,
  offeringsCategories
) =>
  new Promise((resolve, reject) => {
    const values = callsAnsweredDataValues;
    const callAnsweredCategory = _.mapKeys(offeringsCategories, 'objectID')[
      values.category
    ].category;

    if (callAnsweredCategory === CALL_ANS_SCHEDULED_CATEGORY) {
      const office = _.mapKeys(clients, 'id')[values.office];
      const officeTimezone = t(office, 'officeInformation.timezone').safeString;
      const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;

      if (adding) {
        const appointmentTime = moment.tz(apptDateTime, officeTimezone);

        delete values.appointmentDate;
        values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
        values.newPatient = newPatient;
        values.createdTime = new Date();
        values.appointmentTime = new Date(appointmentTime.format());
        if (values.newPatient) delete values.lastVisitDate;
        if (auth && !auth.isEmpty) values.createdBy = auth.uid;

        if (values.lastVisitDate && !values.newPatient) {
          const lastVisitDate = moment.tz(values.lastVisitDate, officeTimezone);

          delete values.lastVisitDate;
          values.lastVisitDate = new Date(lastVisitDate.format());
          values.lastVisitDate._seconds = values.lastVisitDate.getTime() / 1000;
        } else {
          delete values.lastVisitDate;
        }

        values.reason = {};
        values.reason.category = values.category;
        values.reason.notes = values.notes;
        delete values.category;
        delete values.notes;

        return resolve(values);
      } else {
        const appointmentTime = moment(apptDateTime).tz(officeTimezone);

        delete values.appointmentDate;
        values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
        values.newPatient = newPatient;
        values.editedTime = new Date();
        values.appointmentTime = new Date(appointmentTime.format());
        if (values.newPatient) delete values.lastVisitDate;
        if (auth && !auth.isEmpty) values.editedBy = auth.uid;

        if (values.lastVisitDate && !values.newPatient) {
          const lastVisitDate = moment.tz(values.lastVisitDate, officeTimezone);

          delete values.lastVisitDate;
          values.lastVisitDate = new Date(lastVisitDate.format());
          values.lastVisitDate._seconds = values.lastVisitDate.getTime() / 1000;
        } else {
          delete values.lastVisitDate;
        }

        values.reason = {};
        values.reason.category = values.category;
        values.reason.notes = values.notes;
        delete values.category;
        delete values.notes;

        return resolve(values);
      }
    }
    values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
    values.reason = {};
    values.reason.category = values.category;
    values.reason.notes = values.notes;
    delete values.category;
    delete values.notes;
    delete values.appointmentDate;
    delete values.appointmentTime;
    delete values.lastVisitDate;

    if (adding) {
      values.createdTime = new Date();
      if (auth && !auth.isEmpty) values.createdBy = auth.uid;
    } else {
      values.editedTime = new Date();
      if (auth && !auth.isEmpty) values.editedBy = auth.uid;
    }

    return resolve(values);
  });

export const transformNotScheduledData = (
  notScheduledDataValues,
  adding,
  auth
) =>
  new Promise(resolve => {
    const values = notScheduledDataValues;
    values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
    values.reason = {};
    values.reason.category = values.category;
    values.reason.subcategory = values.subcategory;
    values.reason.notes = values.notes;

    delete values.category;
    delete values.subcategory;
    delete values.notes;

    if (adding) {
      values.createdTime = new Date();
      if (auth && !auth.isEmpty) values.createdBy = auth.uid;
    } else {
      values.editedTime = new Date();
      if (auth && !auth.isEmpty) values.editedBy = auth.uid;
    }

    return resolve(values);
  });

export const transformAddOfficeData = (officeDataValues, days) => {
  const values = officeDataValues;
  let vals = {};
  const addressObj = {};
  // const hoursObj = {};
  const infoObj = {};
  const notesObj = { reportEmails: [], billingEmails: [] };
  const offeringsPricingObj = {};

  vals = JSON.parse(JSON.stringify(values));

  // get office information values
  infoObj.name = vals.name;
  infoObj.phone = vals.phone;
  infoObj.doctor = vals.doctor;
  infoObj.timezone = vals.timezone;
  delete vals.name;
  delete vals.phone;
  delete vals.doctor;
  delete vals.timezone;

  // transform address
  addressObj.address = vals.address;
  addressObj.city = vals.city;
  addressObj.state = vals.state;
  addressObj.zip = vals.zip;
  addressObj.country = vals.country;
  delete vals.address;
  delete vals.city;
  delete vals.state;
  delete vals.zip;
  delete vals.country;
  infoObj.address = addressObj;
  vals.officeInformation = infoObj;

  // transform office users
  if (vals.officeUsers) {
    vals.officeUsers.forEach(user => {
      const notesUserObj = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      };

      if (user.reportEmail) {
        notesObj.reportEmails.push(notesUserObj);
      }

      if (user.billingEmail) {
        notesObj.billingEmails.push(notesUserObj);
      }
    });
  }

  vals.officeNotes = notesObj;

  delete vals.officeHours;
  // vals.officeHours = hoursObj;

  // transform offerings pricing
  Object.keys(vals.offeringsPricing).forEach(key => {
    offeringsPricingObj[key] = {
      plan: vals.offeringsPricing[key]
        ? vals.offeringsPricing[key]
        : defaultOfferingPrices[key],
      pricing: null
    };
  });

  delete vals.offeringsPricing;
  vals.offeringsPricing = offeringsPricingObj;

  if (vals.locationId) {
    vals.metadata = {};
    vals.metadata.locationId = vals.locationId;
    delete vals.locationId;
  }

  return vals;
};
