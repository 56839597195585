import {
  DIALOG_INDI_ENTRIES_DETAILS_CLICK,
  DIALOG_INDI_ENTRIES_DETAILS_ITEM
} from '../actions/types';

export const dialogIndiEntriesDetailsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_INDI_ENTRIES_DETAILS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogIndiEntriesDetailsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_INDI_ENTRIES_DETAILS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogIndiEntriesDetailsReducer = {
  dialogIndiEntriesDetailsOpen,
  dialogIndiEntriesDetailsItem
};
