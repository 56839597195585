/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { t } from 'typy';
import TeamName from './components/TeamName';
import TeamScenes from './components';
import { AppContext } from '../../contexts';
import queryString from 'query-string';
import HeaderTitleWithTabs from '../../components/HeaderTitleWithTabs';

const Team = props => {
  const {
    location,
    match: { params }
  } = props;
  const [team, setTeam] = useState(null);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const searchParams = queryString.parse(location.search);
  const tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('teams')
      .doc(params.teamId)
      .onSnapshot(doc => {
        const team = { ...doc.data(), id: doc.id };
        setTeam(team);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, setTeam, params]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const TeamScene = TeamScenes[key];
    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: `/team/${params.teamId}/$tabName?pathname=${searchParams.pathname}`,
        renderComponent: () => (
          <TeamScene
            team={team}
            sitemapPath={props.sitemapPath}
            profile={profile}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex += 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      renderTitle={() => (
        <TeamName teamId={params.teamId} pathname={searchParams.pathname} />
      )}
      tabId={params.tabId}
      tabs={tabs}
      largeAppBar
    />
  );
};

Team.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sitemapPath: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Team);
