import _ from 'lodash';
import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import DataTable from '../../../../components/DataTable';
import DialogBillingItem from '../../components/DialogBillingItem';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import InvoiceItemForm from './components/InvoiceItemForm';
import qs from 'qs';
import Tooltip from '@material-ui/core/Tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { invoicesColumns } from './columns';
import { invoicesFilters } from './filters';
import { t } from 'typy';

class Invoices extends Component {
  state = { anchorEl: null, dialogOpen: false };

  UNSAFE_componentWillMount() {
    if (this.props.dialogDataParam) this.setState({ dialogOpen: true });
  }

  UNSAFE_componentWillUpdate = nextProps => {
    if (
      nextProps.dialogDataParam &&
      !_.isEqual(this.props.dialogDataParam, nextProps.dialogDataParam)
    ) {
      this.setState({ dialogOpen: true });
    }
  };

  dialogOnClose = refreshData => {
    const { history, location } = this.props;
    let searchObj = qs.parse(location.search.slice(1));
    delete searchObj.dialogData;
    let search = qs.stringify(searchObj);

    this.setState({ dialogOpen: false }, () => {
      history.push(
        `${location.pathname}?${search}${
          refreshData ? '&refreshData=true' : ''
        }`
      );
    });
  };

  handlePopoverClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  renderBillingItemDialog() {
    const { history, location } = this.props;
    const { dialogOpen } = this.state;

    return (
      <DialogBillingItem
        title='Invoice'
        collection='invoices'
        dialogBody={data => (
          <InvoiceItemForm
            data={data}
            history={history}
            location={location}
            dialogOnClose={this.dialogOnClose}
          />
        )}
        dialogOnClose={this.dialogOnClose}
        history={history}
        location={location}
        open={dialogOpen}
      />
    );
  }

  render() {
    const { tabId } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <span>
        <DataTable
          {...this.props}
          collection='invoices'
          columns={invoicesColumns}
          clearSearchPath={`/billing${
            tabId && tabId !== 'undefined' ? '/' + tabId : ''
          }`}
          sceneUri='billing'
          title='Invoices'
          onRowClickLink={(data, pathname) => {
            let path = pathname;

            if (pathname.indexOf('dialogData') > -1) {
              let searchObj = qs.parse(pathname.split('?')[1]);
              delete searchObj.dialogData;
              let search = qs.stringify(searchObj);

              path = `${pathname.split('?')[0]}?${search}`;
            }

            return `${path}${
              path.indexOf('?') > -1 ? '&' : '?'
            }dialogData=${JSON.stringify({
              id: data.id
            })}`;
          }}
          tableActionIcons={[
            {
              render: (key, filtersApplied, onClearFilter) => (
                <span key={key}>
                  <FilterClearChip
                    filtersApplied={filtersApplied}
                    onClearFilter={onClearFilter}
                  />
                  <Tooltip title='Filters'>
                    <IconButton
                      aria-label='Filters'
                      aria-owns={open ? 'simple-popper' : null}
                      onClick={this.handlePopoverClick}
                    >
                      {filtersApplied ? (
                        <Badge color='secondary' variant='dot'>
                          <FilterIcon />
                        </Badge>
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </span>
              )
            }
          ]}
          tableFilterable={true}
          filterData={{
            anchorEl,
            filters: invoicesFilters,
            open,
            handleClose: this.handlePopoverClose
          }}
        />
        {this.renderBillingItemDialog()}
      </span>
    );
  }
}

export default compose(
  connect((state, { location }) => {
    const dialogDataParam = t(qs.parse(location.search.slice(1)), 'dialogData')
      .safeObject;

    return { dialogDataParam, location };
  }, {})
)(Invoices);
