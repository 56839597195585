import moment from 'moment-timezone';
import React from 'react';
import { BlockRounded, CheckRounded } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

export default offices => [
  {
    key: 'patientName',
    essential: true,
    text: 'Patient Name',
    spacing: { xs: 4, sm: 3 },
    formatData: doc => {
      let { patientName } = doc;
      return patientName;
    },
    icon: false
  },
  {
    key: 'office',
    essential: true,
    text: 'Office',
    spacing: { xs: 4, sm: 2 },
    formatData: async doc => {
      if (offices === null) {
        return <CircularProgress size='1rem' />;
      }
      let ret = offices.find(o => o.id === doc.office);
      return ret?.officeInformation.name;
    },
    icon: false
  },
  {
    key: 'createdTime',
    essential: true,
    text: 'Created Time',
    spacing: { xs: 4, sm: 2 },
    formatData: doc => {
      return moment.unix(doc.createdTime.seconds).format('MMM D, YYYY, h:mm a');
    },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'desc'
  },
  {
    key: 'source',
    essential: false,
    text: 'Source',
    spacing: { xs: 6, sm: 1 },
    formatData: doc => {
      let { source } = doc;
      if (source === 'console') {
        return 'Console';
      } else if (source === 'portal') {
        return 'Portal';
      }
      return <span style={{ color: 'red' }}>Unknown</span>;
    },
    icon: false
  },
  {
    key: 'status',
    essential: false,
    text: 'Status',
    spacing: { xs: 6, sm: 2 },
    formatData: doc => {
      let { status } = doc;
      if (status === 'new') {
        return <span style={{ color: 'yellow' }}>New</span>;
      } else if (status === 'complete') {
        return <span style={{ color: 'green' }}>Complete</span>;
      }
      return <span style={{ color: 'red' }}>Unknown</span>;
    },
    icon: false
  },
  {
    key: 'result',
    essential: false,
    text: 'Result',
    spacing: { xs: 1, sm: 1 },
    formatData: doc => {
      let { result } = doc;
      if (result === 'verified') {
        return <CheckRounded fontSize='small'></CheckRounded>;
      } else if (result === 'failed') {
        return <BlockRounded fontSize='small'></BlockRounded>;
      }
      return '—';
    },
    icon: false
  }
];
