import _ from 'lodash';
import React, { Component } from 'react';
import PricingPlanDetailsInLine from '../../../../../../../components/Billing/PricingPlanDetailsInLine';
import qs from 'qs';
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { styles } from './styles';
import { t } from 'typy';
import { defaultOfferingPrices } from '../../../../../../../services/options';

class OfferingSubscriptions extends Component {
  componentDidMount() {
    const { change, formData } = this.props;
    const offeringsPricing = formData.offeringsPricing
      ? formData.offeringsPricing
      : {};

    if (!offeringsPricing.base) {
      Object.keys(defaultOfferingPrices).forEach(offering => {
        offeringsPricing[offering] = defaultOfferingPrices[offering];
      });
    }

    offeringsPricing &&
      Object.keys(offeringsPricing).map(key =>
        change(key, t(offeringsPricing, key).safeObject)
      );
  }

  componentDidUpdate(prevProps) {
    const { change, formSectionValues } = this.props;

    if (
      Object.keys(prevProps.formSectionValues.offeringsPricing).length === 0 &&
      !_.isEqual(
        prevProps.formSectionValues.offeringsPricing,
        formSectionValues.offeringsPricing
      )
    ) {
      const offeringsPricing = formSectionValues.offeringsPricing;

      if (offeringsPricing) {
        for (let pricings in offeringsPricing) {
          offeringsPricing[pricings] = defaultOfferingPrices[pricings];
        }
      }

      offeringsPricing &&
        Object.keys(offeringsPricing).map(key =>
          change(key, t(offeringsPricing, key).safeObject)
        );
    }
  }

  radioButtonGroup = ({ offeringKey, input, ...rest }) => {
    const { classes, offerings } = this.props;
    const pricingPlans = offerings[offeringKey].pricing;

    return (
      <FormControl className={classes.pricingPlans}>
        <RadioGroup {...input} {...rest}>
          {pricingPlans &&
            Object.keys(pricingPlans).map((key, index) => {
              return pricingPlans[key].hideAsOption ? null : (
                <FormControlLabel
                  key={index}
                  value={key}
                  className={classes.radioButtonControlLabel}
                  control={<Radio />}
                  label={
                    <Typography variant='body1' className={classes.radioLabel}>
                      {pricingPlans[key].name
                        ? pricingPlans[key].name
                        : `${_.startCase(key)} Plan`}
                      <br />
                      <PricingPlanDetailsInLine
                        pricingPlans={pricingPlans}
                        planKey={key}
                      />
                    </Typography>
                  }
                />
              );
            })}
        </RadioGroup>
      </FormControl>
    );
  };

  renderOfferingsPricing() {
    const {
      classes,
      formData,
      formSectionValues: { offeringsPricing },
      offerings
    } = this.props;
    const atLeastOneSubSelected =
      _.filter(formData.offerings, o => o).length > 0;

    if (Object.keys(offeringsPricing).length > 0 && atLeastOneSubSelected) {
      return Object.keys(offeringsPricing).map(key =>
        t(formData, `offerings.${key}`).safeObject ? (
          <div key={key}>
            <Typography variant='caption' className={classes.subSectionTitle}>
              {offerings[key].name}
            </Typography>
            <Field
              name={key}
              component={this.radioButtonGroup}
              offeringKey={key}
            />
          </div>
        ) : null
      );
    }

    return (
      <Typography variant='body1' className={classes.sectionInfoMessage}>
        No subscriptions were selected
      </Typography>
    );
  }

  render() {
    const {
      classes,
      formSectionValues,
      onSubmit,
      handleSubmit,
      previousPage
    } = this.props;

    return (
      <form onSubmit={handleSubmit(() => onSubmit(formSectionValues))}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Pricing</Typography>
        </div>
        <div className={classes.offeringsPricing}>
          {this.renderOfferingsPricing()}
        </div>
        <div className={classes.sectionActions}>
          <Button
            color='primary'
            type='button'
            onClick={() => previousPage(formSectionValues)}
          >
            Previous
          </Button>
          <Button color='primary' type='submit'>
            Next
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const offeringsData = state.firestore.ordered['offerings'];
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addOfficeFormData').safeObject
    ? JSON.parse(t(searchObj, 'addOfficeFormData').safeObject)
    : {};
  const selector = formValueSelector('DialogAddOfficeForm');
  let offerings = null;
  let offeringsPricing = {};

  if (isLoaded(offeringsData) && !isEmpty(offeringsData)) {
    offeringsData.forEach(offering => {
      let defaultPlan = null;

      for (let pricingKey in offering.pricing) {
        if (offering.pricing[pricingKey].default) {
          defaultPlan = pricingKey;
        }
      }

      offeringsPricing[offering.id] = selector(state, offering.id)
        ? selector(state, offering.id)
        : defaultPlan;
      offerings = _.mapKeys(offeringsData, 'id');
    });
  }

  return {
    formData,
    formSectionValues: { offeringsPricing },
    offerings
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'offerings',
      storeAs: 'offerings'
    }
  ]),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(OfferingSubscriptions);
