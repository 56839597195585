import axios from 'axios';
import { CLOUD_FUNC_BASE_URL } from '../constants';

const zipToTzEndpoint = `${CLOUD_FUNC_BASE_URL}/getTimezoneFromZip`;

const getZipTzPromise = zip =>
  new Promise((resolve, reject) => {
    axios
      .post(zipToTzEndpoint, {
        zip
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(Error(error));
      });
  });

export default getZipTzPromise;
