import _ from 'lodash';
import React from 'react';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  dialogEditOfficeOpsStatusClick,
  dialogEditOfficeOpsStatusItem
} from '../../../actions';
import { styles } from './styles';
import EditButton from './EditButton';

const OfficeOpsStateData = ({
  classes,
  data,
  dialogEditOfficeOpsStatusItem,
  dialogEditOfficeOpsStatusClick,
  label,
  name,
  showEdit
}) => {
  // status can either be active (green), paused (yellow), or inactive (red). Find correlating value to each.
  let statusColor, status;
  const state = data && data.opsStatuses && data.opsStatuses[name];
  if (name === 'billing') {
    statusColor =
      state === 'current'
        ? 'active'
        : state === 'overdue'
        ? 'paused'
        : state === 'pending suspension'
        ? 'inactive'
        : 'active';
    status = state ? state : 'current';
  }

  const onClick = () => {
    dialogEditOfficeOpsStatusItem({ key: name, label, data });
    dialogEditOfficeOpsStatusClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        <span className={classes[`${statusColor}Status`]}>
          <StatusIcon />
        </span>
        {_.startCase(status)}
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    dialogEditOfficeOpsStatusItem,
    dialogEditOfficeOpsStatusClick
  }),
  withStyles(styles, { withTheme: true })
)(OfficeOpsStateData);
