import { db } from '../../../../index';

const recallFilters = [
  {
    index: 1,
    key: 'office',
    options: () =>
      new Promise(resolve => {
        const options = [];

        return db
          .collection('clients')
          .orderBy('officeInformation.name', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const client = doc.data();
              const clientName = client.officeInformation.name;

              options.push({
                key: doc.id,
                text: clientName,
                value: `office,==,${doc.id}`
              });
            });

            resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Office'
  },
  {
    index: 2,
    key: 'createdBy',
    options: () =>
      new Promise(resolve => {
        const options = [];

        return db
          .collection('employees')
          .orderBy('firstName', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const employee = doc.data();
              const employeeName = `${employee.firstName} ${employee.lastName}`;

              options.push({
                key: doc.id,
                text: employeeName,
                value: `createdBy,==,${doc.id}`
              });
            });

            resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Created By'
  },
  {
    index: 3,
    key: 'createdTime',
    type: 'dateRange',
    label: 'Created Time'
  },
  {
    index: 4,
    key: 'mistake.reportedOn',
    type: 'dateRange',
    label: 'Reported Time'
  }
];

export default recallFilters;
