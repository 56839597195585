import React, { Component } from 'react';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { rrfProps } from '../../../index';
import { CALLFORCE_TZ } from '../../../services/constants';
import CustomDatePicker from './CustomDatePicker';
import CustomCheckbox from './CustomCheckbox';

class FilterFieldDateRange extends Component {
  componentDidMount() {
    const { change, where } = this.props;
    const ts = rrfProps.firebase.firestore.Timestamp.now().toDate();
    const now = moment(ts).tz(CALLFORCE_TZ);
    const lastMonth = now.clone().subtract(1, 'month');

    if (where && new Date(where[0][2]).toString() !== 'Invalid Date') {
      const whereStartDayBegin = moment(where[0][2])
        .tz(CALLFORCE_TZ)
        .startOf('day')
        .toISOString();
      const whereEndDayBegin = moment(where[1][2])
        .tz(CALLFORCE_TZ)
        .startOf('day')
        .toISOString();

      if (whereStartDayBegin === whereEndDayBegin) {
        change('singleDay', true);
        change('date', moment(where[0][2]).tz(CALLFORCE_TZ));
        change('dateStart', lastMonth.clone().startOf('month'));
        change('dateEnd', lastMonth.clone().endOf('month'));
      } else {
        change('singleDay', false);
        change('date', moment(where[0][2]).tz(CALLFORCE_TZ));
        change('dateStart', moment(where[0][2]).tz(CALLFORCE_TZ));
        change('dateEnd', moment(where[1][2]).tz(CALLFORCE_TZ));
      }
    } else {
      change('singleDay', true);
      change('date', lastMonth.clone());
      change('dateStart', lastMonth.clone().startOf('month'));
      change('dateEnd', lastMonth.clone().endOf('month'));
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      dateVal,
      dateStartVal,
      dateEndVal,
      filter,
      singleDayVal
    } = this.props;
    const dateAttr = filter.key === 'period' ? 'dateEnd' : filter.key;
    const singleDayChg = singleDayVal !== nextProps.singleDayVal;
    const dateChg = dateVal !== nextProps.dateVal;
    const dateStartChg = dateStartVal !== nextProps.dateStartVal;
    const dateEndChg = dateEndVal !== nextProps.dateEndVal;

    if (singleDayChg) {
      if (nextProps.singleDayVal) {
        this.updateWhereDate(dateAttr, nextProps.dateVal);
      } else {
        this.updateWhereDateRange(
          dateAttr,
          nextProps.dateStartVal,
          nextProps.dateEndVal
        );
      }
    } else {
      if (dateChg && nextProps.singleDayVal) {
        this.updateWhereDate(dateAttr, nextProps.dateVal);
      }

      if ((dateStartChg || dateEndChg) && !nextProps.singleDayVal) {
        this.updateWhereDateRange(
          dateAttr,
          nextProps.dateStartVal,
          nextProps.dateEndVal
        );
      }
    }
  }

  updateWhereDate(dateAttr, dateVal) {
    const dateBase = moment(dateVal).tz(CALLFORCE_TZ);

    this.props.onSelect([
      [dateAttr, '>=', dateBase.startOf('day').toDate()],
      [dateAttr, '<', dateBase.add(1, 'day').startOf('day').toDate()]
    ]);
  }

  updateWhereDateRange(dateAttr, dateStartVal, dateEndVal) {
    const dateStart = moment(dateStartVal).tz(CALLFORCE_TZ);
    const dateEnd = moment(dateEndVal).tz(CALLFORCE_TZ);

    this.props.onSelect([
      [dateAttr, '>=', dateStart.startOf('day').toDate()],
      [dateAttr, '<', dateEnd.add(1, 'day').startOf('day').toDate()]
    ]);
  }

  renderSingleDay() {
    return (
      <Field
        name='date'
        component={CustomDatePicker}
        label='Date'
        formatDate='MMMM D, YYYY'
        className={this.props.classes.dateField}
      />
    );
  }

  renderDateRange() {
    const { classes } = this.props;

    return (
      <div className={classes.dateRangeFields}>
        <Field
          name='dateStart'
          component={CustomDatePicker}
          label='Start Date'
          formatDate='MMMM D, YYYY'
          className={classes.dateField}
        />
        <Field
          name='dateEnd'
          component={CustomDatePicker}
          label='End Date'
          formatDate='MMMM D, YYYY'
          className={classes.dateField}
        />
      </div>
    );
  }

  render() {
    const { singleDayVal } = this.props;

    return (
      <span>
        <Field name='singleDay' label='Single Day' component={CustomCheckbox} />
        {singleDayVal ? this.renderSingleDay() : this.renderDateRange()}
      </span>
    );
  }
}

const styles = theme => ({
  dateField: {
    margin: '16px auto 8px auto',
    width: '90%',
    display: 'block'
  }
});

export default compose(
  connect((state, props) => {
    const selector = formValueSelector('FilterFieldDateRangeForm');

    return {
      dateVal: selector(state, 'date'),
      dateStartVal: selector(state, 'dateStart'),
      dateEndVal: selector(state, 'dateEnd'),
      singleDayVal: selector(state, 'singleDay')
    };
  }),
  reduxForm({ form: 'FilterFieldDateRangeForm', enableReinitialize: false }),
  withStyles(styles, { withStyles: true })
)(FilterFieldDateRange);
