import _ from 'lodash';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../../components/DialogTransition';
import qs from 'qs';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';

class DialogBillingItem extends Component {
  state = {
    checkedDialogData: false,
    data: null,
    dataFetchError: false
  };

  UNSAFE_componentWillMount() {
    if (this.props.item) this.fetchData(this.props.item.id);
  }

  UNSAFE_componentWillUpdate = nextProps => {
    if (nextProps.item && !_.isEqual(this.props.item, nextProps.item)) {
      this.fetchData(nextProps.item.id);
    }

    if (!_.isEqual(this.props.item, nextProps.item) && !nextProps.item) {
      this.setState({ data: null, dataFetchError: false });
    }
  };

  fetchData = async id => {
    const { collection, firestore } = this.props;

    try {
      let doc = await firestore.get({ collection, doc: id });

      this.setState({ data: { ...doc.data(), id: doc.id } });
    } catch (e) {
      this.setState({ dataFetchError: true });
    }
  };

  onClose = () => {
    this.setState({ data: null, dataFetchError: false }, () => {
      this.props.dialogOnClose();
    });
  };

  render() {
    const { classes, dialogBody, fullScreen, item, open, title } = this.props;
    const { data, dataFetchError } = this.state;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={data && !dataFetchError && open ? true : false}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              {title}
            </Typography>
            <Typography variant='caption' className={classes.dialogSubtitle}>
              {item && item.id}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          {dialogBody(data, title)}
        </DialogContent>
      </Dialog>
    );
  }
}

export default compose(
  connect((state, { location }) => {
    const dialogData = t(qs.parse(location.search.slice(1)), 'dialogData')
      .safeObject;
    const item = dialogData ? JSON.parse(dialogData) : null;

    return { item, location };
  }, {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogBillingItem);
