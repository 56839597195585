import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import generate from 'nanoid/generate';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { formValueSelector, Field, FieldArray, reduxForm } from 'redux-form';
import TextField from '../../../../../../../components/TextField';
import EntryPriceArray from './EntryPriceArray';
import { validations } from '../../../../../../../services/validation';

const AddPricingPlanForm = ({
  change,
  classes,
  firestore,
  formValues,
  handleSubmit,
  offering,
  onClose,
  submitting
}) => {
  const [error, setError] = useState(null);
  const createEntryPriceTiers = tiers => {
    const entryPrice = [];

    tiers.forEach(tier => {
      entryPrice.push({
        entryMin: parseFloat(tier.min),
        entryMax: tier.max ? parseFloat(tier.max) : 2600000,
        entryPrice: parseFloat(tier.price)
      });
    });

    return entryPrice;
  };
  const onSubmit = async values => {
    const ref = { collection: 'offerings', doc: offering.id };
    let pricingPlan = {
      description: values.description,
      key: generate('1234567890abcdef', 20),
      name: values.name,
      periodPrice: parseFloat(values.periodPrice),
      periodType: 'monthly',
      periodEntryMax: null
    };
    pricingPlan = {
      ...pricingPlan,
      entryPrice: !values.tieredEntryPrice
        ? values.entryPrice
          ? parseFloat(values.entryPrice)
          : 0
        : createEntryPriceTiers(values.entryPriceTiers)
    };

    try {
      if (Object.keys(offering.pricing).indexOf(pricingPlan.key) !== -1) {
        throw new Error(`
          A conflicting plan key "${pricingPlan.key}" exists; try saving again
        `);
      }
      await firestore.update(ref, {
        [`pricing.${pricingPlan.key}`]: pricingPlan
      });
      onClose();
    } catch (e) {
      setError(e.toString());
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(values => onSubmit(values))}
    >
      <div className={classes.name}>
        <Field
          name='name'
          component={TextField}
          label='Plan Name'
          className={classes.dialogTextField}
          validate={[validations.required]}
        />
      </div>
      <div className={classes.description}>
        <Field
          name='description'
          component={TextField}
          label='Description'
          className={classes.dialogTextField}
        />
      </div>
      <div className={classes.periodPrice}>
        <Field
          name='periodPrice'
          component={TextField}
          label='Price per Month'
          type='number'
          step='0.01'
          className={classes.dialogTextField}
          validate={[validations.required, validations.isNotNegative]}
        />
      </div>
      <div className={classes.tieredEntryPrice}>
        {offering.collection ? (
          <Field
            name='tieredEntryPrice'
            label='Tiered Entry Price'
            component={({ input, label }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={!!input.value}
                    onChange={() => change('tieredEntryPrice', !input.value)}
                  />
                }
                label={
                  <Typography variant='body1' className={classes.checkboxLabel}>
                    {label}
                  </Typography>
                }
              />
            )}
          />
        ) : null}
      </div>
      {offering.collection ? (
        formValues.tieredEntryPrice ? (
          <FieldArray
            change={change}
            name='entryPriceTiers'
            component={EntryPriceArray}
          />
        ) : (
          <div className={classes.entryPrice}>
            <Field
              name='entryPrice'
              component={TextField}
              label='Price per Entry'
              type='number'
              step='0.01'
              className={classes.dialogTextField}
              validate={[validations.required, validations.isNotNegative]}
            />
          </div>
        )
      ) : null}
      <div className={classes.formActions}>
        <Typography className={classes.errorMessage} variant='body1'>
          {error || null}
        </Typography>
        <Button
          color='primary'
          type='submit'
          variant='outlined'
          disabled={submitting}
        >
          {submitting ? (
            <Loader type='Oval' color='#696969' height='12' width='12' />
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </form>
  );
};

const styles = theme => ({
  form: {
    '& > div': {
      marginTop: 8
    },
    '& > div:first-child': {
      marginTop: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 5
    }
  },
  dialogTextField: {
    width: '100%',
    margin: '8px auto 4px auto'
  },
  checkbox: {
    marginLeft: 12
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '24px !important'
  }
});

const validate = values => {
  const errors = {};
  const entryPriceTiersErrors = [];

  if (values.entryPriceTiers) {
    values.entryPriceTiers.forEach((entryPriceTier, i) => {
      const entryPriceTierErrors = {};

      if (entryPriceTier.min >= entryPriceTier.max) {
        entryPriceTierErrors.max =
          'Max entry count must be greater than min entry count';
        entryPriceTiersErrors[i] = entryPriceTierErrors;
      }
    });
  }

  if (entryPriceTiersErrors.length) {
    errors.entryPriceTiers = entryPriceTiersErrors;
  }

  return errors;
};

AddPricingPlanForm.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  firestore: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default compose(
  connect((state, props) => {
    const selector = formValueSelector('AddPricingPlanForm');

    return {
      formValues: {
        tieredEntryPrice: selector(state, 'tieredEntryPrice')
      },
      initialValues: {
        entryPriceTiers: [{ min: '0' }, {}],
        tieredEntryPrice: false
      }
    };
  }),
  firestoreConnect(),
  reduxForm({
    form: 'AddPricingPlanForm',
    validate
  }),
  withStyles(styles, { withTheme: true })
)(AddPricingPlanForm);
