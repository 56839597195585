/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { useField } from 'formik';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 12
  }
});

const FieldCheckbox = ({ classes, checked, disabled, label, ...props }) => {
  const [field, , helpers] = useField(props);

  return (
    <div className={classes.container}>
      <FormControlLabel
        checked={checked}
        disabled={disabled}
        onChange={() => helpers.setValue(!field.value)}
        control={<Checkbox />}
        label={<Typography variant='body1'>{label}</Typography>}
      />
    </div>
  );
};

FieldCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(FieldCheckbox);
