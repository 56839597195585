import React, { useContext, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { withStyles, List, Button } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { styles } from './styles';
import Uploader from './components/Uploader';
import { AppContext } from '../../../../contexts';
import OverlayLoader from '../../components/OverlayLoader';
import Zip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import FilePreview from './components/FilePreview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../../../index.js';
import helpers from '../../../../helpers';
import axios from 'axios';
import { drProfileValidationObj } from '../../../../validation';
import { drProfileObj, pmsObj } from '../../../../services/options';
import OfficeSelection from './components/OfficeSelection';
import ErrorAlert from '../../components/ErrorAlert';

const ListUpload = ({ classes, offices }) => {
  const {
    state: { profile, firebase }
  } = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [filesStatuses, setFilesStatuses] = useState({});
  const [uploadStatus, setUploadStatus] = useState('notReady');
  const [filesNeedDrProfile, setFilesNeedDrProfile] = useState(false);
  const [autoUpdateDenticonFilesState, setUpdateDenticonFilesState] = useState(
    false
  );
  const [
    filesWithIncompleteDrProfiles,
    setFilesWithIncompleteDrProfiles
  ] = useState([]);
  const [filesWithoutDrProfile, setFilesWithoutDrProfile] = useState([]);
  const [problematicCsvs, setProblematicCsvs] = useState([]);

  useEffect(() => {
    const queueDrProfileFailures = filesWithoutDrProfile => {
      filesWithoutDrProfile.forEach(fileName => {
        const clientId = filesStatuses[fileName].clientId;
        const clientExists = filesStatuses[fileName].clientExists;
        const message = clientExists ? (
          <span>
            {clientId} does not have a DrProfile. Please update or file will not
            be converted.
          </span>
        ) : (
          <span>
            {clientId} is not a client! Please select the office for this file
            below to inherit that office's DrProfile.
          </span>
        );
        toast.error(
          clientExists ? (
            <RedirectToOfficeSettings clientId={clientId} message={message} />
          ) : (
            message
          ),
          {
            closeOnClick: true,
            autoClose: false,
            className: classes.failedProfileToast,
            toastId: clientId
          }
        );
      });
      setFilesWithoutDrProfile([]);
    };

    const queueWarningIncompleteDrProfiles = filesWithIncompleteDrProfiles => {
      filesWithIncompleteDrProfiles.forEach(fileStatus => {
        const officeName = fileStatus.drProfile.officeName;
        const clientId = fileStatus.clientId;
        const missingOrInvalidProps =
          fileStatus.drProfileStatus.missingOrInvalidProps;
        const message = (
          <span>
            {officeName ? officeName : clientId} has an incomplete DrProfile.
            The following parameters are either invalid or missing:&nbsp;
            {missingOrInvalidProps
              .map(prop => {
                return drProfileObj[prop];
              })
              .join(', ')}
          </span>
        );
        toast(
          <RedirectToOfficeSettings clientId={clientId} message={message} />,
          {
            closeOnClick: true,
            autoClose: false,
            className: classes.incompleteProfileToast,
            toastId: clientId + clientId
          }
        );
      });
      setFilesWithIncompleteDrProfiles([]);
    };

    const checkIfFilesNeedDrProfile = () => {
      for (const fileName in filesStatuses) {
        if (
          !filesStatuses[fileName].drProfile ||
          !filesStatuses[fileName].drProfileStatus.complete
        ) {
          return setFilesNeedDrProfile(true);
        }
      }
      return setFilesNeedDrProfile(false);
    };

    const autoUpdateDenticonFiles = () => {
      const denticonFilesWithoutDrProfile = [],
        denticonClientsWithDrProfile = {},
        updatedStatuses = {};

      for (const fileName in filesStatuses) {
        if (/\.xls/.test(fileName) && !filesStatuses[fileName].drProfile) {
          denticonFilesWithoutDrProfile.push(fileName);
        } else if (
          /\.xls/.test(fileName) &&
          filesStatuses[fileName].drProfile
        ) {
          const clientId = stripClientIdFromName(fileName);
          denticonClientsWithDrProfile[clientId] = {
            ...filesStatuses[fileName]
          };
        }
      }

      if (denticonFilesWithoutDrProfile.length === 0)
        return setUpdateDenticonFilesState(false);

      for (const file in denticonFilesWithoutDrProfile) {
        const clientId = stripClientIdFromName(
          denticonFilesWithoutDrProfile[file]
        );
        if (denticonClientsWithDrProfile[clientId]) {
          updatedStatuses[denticonFilesWithoutDrProfile[file]] = {
            ...denticonClientsWithDrProfile[clientId]
          };
        }
      }

      setUpdateDenticonFilesState(false);
      setFilesStatuses({ ...filesStatuses, ...updatedStatuses });
    };

    if (filesNeedDrProfile && autoUpdateDenticonFilesState) {
      autoUpdateDenticonFiles();
    }

    if (filesWithIncompleteDrProfiles.length > 0) {
      queueWarningIncompleteDrProfiles(filesWithIncompleteDrProfiles);
    }

    if (filesWithoutDrProfile.length > 0) {
      queueDrProfileFailures(filesWithoutDrProfile);
    }

    checkIfFilesNeedDrProfile();
  }, [
    filesStatuses,
    filesNeedDrProfile,
    autoUpdateDenticonFilesState,
    filesWithIncompleteDrProfiles,
    filesWithoutDrProfile,
    classes
  ]);

  const queueWarningForDuplicates = duplicateFiles => {
    duplicateFiles.forEach(fileName => {
      toast(`${fileName} failed to upload as it is already uploaded!`, {
        closeOnClick: true,
        autoClose: false,
        className: classes.duplicateProfileToast,
        toastId: fileName
      });
    });
  };

  const RedirectToOfficeSettings = ({ clientId, message, closeToast }) => {
    return (
      <>
        <Link
          to={`/office/${clientId}/settings?pathname=/offices/data`}
          target='_blank'
          onClick={closeToast}
        >
          {message}
        </Link>
      </>
    );
  };

  const queueInfoNotificationNoFilesAreReady = () => {
    toast('No files are ready to be converted.', {
      hideProgressBar: true
    });
  };

  const handleUpload = fileArray => {
    fileArray = preventDuplicates(fileArray);
    setFilesStatusesInitial(fileArray, 'notReady');
    fileArray = [...files, ...fileArray];
    setFiles(fileArray);
  };

  const preventDuplicates = fileArray => {
    const duplicatesInFileArrayHashObj = {},
      duplicates = [];

    const filterOutExistingFiles = fileArray.filter(file => {
      if (filesStatuses[file.name]) {
        duplicates.push(file.name);
        return false;
      }
      if (duplicatesInFileArrayHashObj[file.name]) {
        duplicatesInFileArrayHashObj[file.name]++;
      } else {
        duplicatesInFileArrayHashObj[file.name] = 1;
      }
      return true;
    });

    const filteredFileArray = filterOutExistingFiles.filter(file => {
      if (duplicatesInFileArrayHashObj[file.name] === 1) {
        return true;
      } else {
        duplicates.push(file.name);
        return false;
      }
    });

    if (duplicates.length > 0) {
      queueWarningForDuplicates(duplicates);
    }

    return filteredFileArray;
  };

  const handleSubmit = async () => {
    const filesToBeConverted = files.filter(
      file =>
        filesStatuses[file.name].fileStatus === 'ready' &&
        filesStatuses[file.name].drProfileStatus.complete
    );

    if (filesToBeConverted.length === 0)
      return queueInfoNotificationNoFilesAreReady();
    setUploadStatus('uploading');
    const form = constructFormData(filesToBeConverted);

    try {
      const filteredFileData = await filterAndConvertFiles(form);
      if (!filteredFileData) {
        console.error('Filtered file data returned undefined.');
      } else {
        constructZip(filteredFileData);
        clearFileState();
      }
      setUploadStatus('notReady');
    } catch (e) {
      console.error(e);
      setUploadStatus('notReady');
    }
  };

  const filterAndConvertFiles = async form => {
    try {
      const {
        REACT_APP_CLOUD_FUNCTIONS_BASE_URL
        // REACT_APP_DEV_EMULATOR
      } = process.env;
      const token = await firebase.auth().currentUser.getIdToken();
      const url = `${REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/five9-lists-http-upload`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post(url, form, config);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  const constructFormData = acceptedFiles => {
    const regexDenticonSecondAndThirdFiles = /[2-3]\.xls$/i;
    const regexIsDenticon = /.xls$/i;
    const form = new FormData();
    for (const file in acceptedFiles) {
      form.append(acceptedFiles[file].name, acceptedFiles[file]);
      if (!regexDenticonSecondAndThirdFiles.test(acceptedFiles[file].name)) {
        const filterParams = organizingFilterParamsIntoArray(
          filesStatuses[acceptedFiles[file].name].drProfile
        );
        const officeName =
          filesStatuses[acceptedFiles[file].name].drProfile.officeName;
        form.append(
          `fileFilterParamsField_${
            regexIsDenticon.test(acceptedFiles[file].name)
              ? reformatDenticonFileName(acceptedFiles[file].name)
              : acceptedFiles[file].name
          }`,
          filterParams
        );
        form.append(
          `officeName_${
            regexIsDenticon.test(acceptedFiles[file].name)
              ? reformatDenticonFileName(acceptedFiles[file].name)
              : acceptedFiles[file].name
          }`,
          officeName
        );
      }
    }
    form.append('uploadedByEmployeeIdField', profile.data.displayName);
    return form;
  };

  const reformatDenticonFileName = fileName => {
    const regexDenticon = /_1.xls$/i;
    return fileName.replace(regexDenticon, '.xls');
  };

  const organizingFilterParamsIntoArray = drProfile => {
    const listConfigArr = [helpers.flattenObj(drProfile)];
    return JSON.stringify(listConfigArr);
  };

  const stripClientIdFromName = fileName => {
    const regexCallForceFileExtension = /\.\w+$/;
    return fileName.split('_')[1].replace(regexCallForceFileExtension, '');
  };

  const removeDuplicateFileObjectsBasedOnClientId = clientIdsObjArray => {
    const clientIdHash = {};
    const noDuplicates = [];
    clientIdsObjArray.forEach(clientIdObj => {
      if (clientIdHash[clientIdObj.clientId]) {
        return;
      } else {
        clientIdHash[clientIdObj.clientId] = true;
        noDuplicates.push(clientIdObj);
      }
    });

    return noDuplicates;
  };

  const retrieveDrProfiles = () => {
    const fileNamesForDrProfile = Object.keys(filesStatuses);
    const drProfilesForFiles = [];
    const clientIdsForDrProfiles = fileNamesForDrProfile
      .filter(fileName => {
        if (
          filesStatuses[fileName].clientId &&
          (!filesStatuses[fileName].drProfile ||
            !filesStatuses[fileName].drProfileStatus.complete)
        ) {
          return true;
        }
        return false;
      })
      .map(fileName => {
        return { fileName, clientId: filesStatuses[fileName].clientId };
      });

    const noDuplicateClientIdsArray = removeDuplicateFileObjectsBasedOnClientId(
      clientIdsForDrProfiles
    );

    for (const clientIdObj in noDuplicateClientIdsArray) {
      drProfilesForFiles.push(
        checkDrProfile(noDuplicateClientIdsArray[clientIdObj])
      );
    }

    Promise.all(drProfilesForFiles)
      .then(results => updateFilesStatusesWithDrProfiles(results))
      .catch(e => console.error(e));
  };

  const checkDrProfile = async clientIdObj => {
    const { fileName, clientId } = clientIdObj;
    try {
      const clientRef = db.collection('clients').doc(clientId);

      const drProfileRef = db
        .collection('clients')
        .doc(clientId)
        .collection('drProfile')
        .doc('profile');

      const drProfileDoc = await drProfileRef.get();
      if (!drProfileDoc.exists) {
        const clientDoc = await clientRef.get();
        if (!clientDoc.exists) {
          return { fileName, drProfile: false, clientExists: false };
        } else {
          return { fileName, drProfile: false, clientExists: true };
        }
      } else {
        const { listConfig, pms, officeName } = drProfileDoc.data();
        const drProfile = {
          listConfig,
          pms,
          officeName
        };
        return { drProfile, fileName, clientExists: true };
      }
    } catch (e) {
      console.error(e);
      return { fileName };
    }
  };

  const setFilesStatusesInitial = (fileArray, fileStatus) => {
    const statuses = {};
    let updateDenticonFiles = false;
    for (const file in fileArray) {
      if (/\.xls/.test(fileArray[file].name)) updateDenticonFiles = true;
      const clientId = stripClientIdFromName(fileArray[file].name);
      statuses[fileArray[file].name] = {
        fileStatus,
        clientId
      };
    }

    const updatedFileStatuses = { ...statuses, ...filesStatuses };
    setFilesStatuses(updatedFileStatuses);
    if (updateDenticonFiles) setUpdateDenticonFilesState(true);
  };

  const updateFilesStatusesWithDrProfiles = drProfileArray => {
    const statuses = {};
    const filesWithoutDrProfileArray = [];
    const filesWithIncompleteDrProfilesArray = [];
    const denticonFiles = [];

    for (const result in drProfileArray) {
      statuses[drProfileArray[result].fileName] = {
        ...filesStatuses[drProfileArray[result].fileName],
        fileStatus: drProfileArray[result].drProfile ? 'ready' : 'failed',
        drProfile: drProfileArray[result].drProfile
          ? drProfileArray[result].drProfile
          : false,
        clientExists: drProfileArray[result].clientExists
      };

      if (/\.xls/.test(drProfileArray[result].fileName)) {
        let otherDenticonFileName = '',
          lastDenticonFileName = '';
        if (/1\.xls/.test(drProfileArray[result].fileName)) {
          otherDenticonFileName = drProfileArray[result].fileName.replace(
            '_1.xls',
            '_2.xls'
          );
          lastDenticonFileName = drProfileArray[result].fileName.replace(
            '_1.xls',
            '_3.xls'
          );
        } else if (/2\.xls/.test(drProfileArray[result].fileName)) {
          otherDenticonFileName = drProfileArray[result].fileName.replace(
            '_2.xls',
            '_1.xls'
          );
          lastDenticonFileName = drProfileArray[result].fileName.replace(
            '_2.xls',
            '_3.xls'
          );
        } else {
          otherDenticonFileName = drProfileArray[result].fileName.replace(
            '_3.xls',
            '_1.xls'
          );
          lastDenticonFileName = drProfileArray[result].fileName.replace(
            '_3.xls',
            '_2.xls'
          );
        }
        denticonFiles.push(otherDenticonFileName, lastDenticonFileName);
      }

      if (!drProfileArray[result].drProfile) {
        filesWithoutDrProfile.push(drProfileArray[result].fileName);
      }
    }

    const updatedStatuses =
      denticonFiles.length > 0
        ? updateOtherDenticonFilesStatuses({ ...statuses }, denticonFiles)
        : statuses;
    const updatedAndValidatedStatuses = drProfileObjValidator({
      ...updatedStatuses
    });

    for (const updatedFileStatus in updatedAndValidatedStatuses) {
      if (
        updatedAndValidatedStatuses[updatedFileStatus].drProfile &&
        !updatedAndValidatedStatuses[updatedFileStatus].drProfileStatus.complete
      ) {
        filesWithIncompleteDrProfiles.push(
          updatedAndValidatedStatuses[updatedFileStatus]
        );
      }
    }

    setFilesStatuses({ ...filesStatuses, ...updatedAndValidatedStatuses });
    if (filesWithoutDrProfileArray.length > 0)
      setFilesWithoutDrProfile(filesWithoutDrProfileArray);
    if (filesWithIncompleteDrProfilesArray.length > 0)
      setFilesWithIncompleteDrProfiles(filesWithIncompleteDrProfilesArray);
  };

  const updateOtherDenticonFilesStatuses = (statuses, denticonFiles) => {
    for (const fileName in denticonFiles) {
      if (filesStatuses[denticonFiles[fileName]]) {
        const clientId = stripClientIdFromName(denticonFiles[fileName]);
        const updatedDenticonFileName = Object.keys(
          statuses
        ).filter(statusFileName => statusFileName.includes(clientId))[0];
        statuses[denticonFiles[fileName]] = {
          ...statuses[updatedDenticonFileName]
        };
      }
    }
    return statuses;
  };

  const drProfileObjValidator = updatedStatuses => {
    for (const fileName in updatedStatuses) {
      updatedStatuses[fileName] = {
        ...updatedStatuses[fileName],
        drProfileStatus: drProfileValidationProcess(
          updatedStatuses[fileName].drProfile
        )
      };
    }

    return updatedStatuses;
  };

  const drProfileValidationProcess = drProfileObj => {
    let drProfileStatus = {
      complete: true,
      missingOrInvalidProps: []
    };

    const flattenedDrProfileObj = helpers.flattenObj(drProfileObj),
      numberOfPropsInDrProfile = Object.keys(flattenedDrProfileObj).length,
      propsRequiredInDrProfileArray = Object.keys(drProfileValidationObj),
      numberOfPropsRequiredInDrProfile = propsRequiredInDrProfileArray.length;

    // missingProp check
    if (numberOfPropsInDrProfile !== numberOfPropsRequiredInDrProfile) {
      drProfileStatus.complete = false;
      for (const prop in propsRequiredInDrProfileArray) {
        if (!flattenedDrProfileObj[propsRequiredInDrProfileArray[prop]]) {
          //This longer condition is ensuring that the prop/value is actually missing and not mistakened as a boolean. Check drProfileValidationObj.
          if (
            drProfileValidationObj[propsRequiredInDrProfileArray[prop]] &&
            !drProfileValidationObj[propsRequiredInDrProfileArray[prop]](
              flattenedDrProfileObj[propsRequiredInDrProfileArray[prop]]
            )
          ) {
            drProfileStatus.missingOrInvalidProps.push(
              propsRequiredInDrProfileArray[prop]
            );
          }
        }
      }
      return drProfileStatus;
    }

    // validation check
    for (const prop in flattenedDrProfileObj) {
      if (drProfileValidationObj[prop]) {
        if (!drProfileValidationObj[prop](flattenedDrProfileObj[prop])) {
          if (drProfileStatus.complete) drProfileStatus.complete = false;
          drProfileStatus.missingOrInvalidProps.push(prop);
        }
      }
    }

    return drProfileStatus;
  };

  const constructZip = async filesArray => {
    const problematicCsvs = [];
    try {
      const zip = new Zip();
      const zipDir = zip.folder('CSVs');
      const now = moment().format().split('T')[0];
      for (const fileObj of filesArray) {
        const { csv, csvFileName } = fileObj;
        if (!csv) problematicCsvs.push(csvFileName);
        const blob = new Blob(['\ufeff', csv]);
        const file = new File([blob], csvFileName, {
          type: 'text/csv',
          lastModified: Date.now()
        });
        zipDir.file(csvFileName, file);
        if (problematicCsvs.length > 0) setProblematicCsvs(problematicCsvs);
      }
      return new Promise((resolve, reject) => {
        try {
          resolve(
            zip
              .generateAsync({
                type: 'blob'
              })
              .then(blob => {
                saveAs(blob, `CSVs-${now}`);
              })
          );
        } catch (e) {
          reject(e);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const setOfficeInFileStatuses = (fileName, newClientId) => {
    if (!newClientId) return;
    const copyOfFilesStatuses = { ...filesStatuses };
    copyOfFilesStatuses[fileName] = {
      clientId: newClientId,
      fileStatus: 'notReady',
      selectedOtherOffice: true
    };
    setFilesStatuses(copyOfFilesStatuses);
  };

  const renderFilePreviews = () => {
    return files.map((file, index) => (
      <div key={index}>
        <FilePreview
          fileName={file.name}
          fileSize={file.size}
          clientId={
            filesStatuses[file.name] && filesStatuses[file.name].clientId
          }
          selectedOtherOffice={
            filesStatuses[file.name] &&
            filesStatuses[file.name].selectedOtherOffice
          }
          classes={classes}
          status={
            filesStatuses[file.name] && filesStatuses[file.name].fileStatus
          }
          drProfileStatus={
            filesStatuses[file.name] && filesStatuses[file.name].drProfileStatus
          }
          clientExists={
            filesStatuses[file.name] && filesStatuses[file.name].clientExists
          }
          cancel={cancelFile}
          drProfileObj={drProfileObj}
          uploadStatus={uploadStatus}
          officeName={
            filesStatuses[file.name] &&
            filesStatuses[file.name].drProfile &&
            filesStatuses[file.name].drProfile.officeName
          }
          drProfileParameters={
            filesStatuses[file.name] && filesStatuses[file.name].drProfile
          }
          toOfficeLink={`/office/${
            filesStatuses[file.name] && filesStatuses[file.name].clientId
          }/settings?pathname=/offices/data`}
          pmsObj={pmsObj}
          OfficeSelection={
            <OfficeSelection
              fileName={file.name}
              classes={classes}
              clientId={filesStatuses[file.name].clientId}
              variant='outlined'
              offices={_.sortBy(offices, 'officeInformation.name')}
              optionText='officeInformation.name'
              optionValue='id'
              optionKey='id'
              setOfficeClient={(fileName, newClientId) =>
                setOfficeInFileStatuses(fileName, newClientId)
              }
            />
          }
        />
      </div>
    ));
  };

  const cancelFile = fileName => {
    const newFiles = files.filter(file => file.name !== fileName);
    const updatedFilesStatuses = { ...filesStatuses };
    for (const filesName in updatedFilesStatuses) {
      if (filesName === fileName) {
        delete updatedFilesStatuses[fileName];
      }
    }
    setFiles(newFiles);
    setFilesStatuses(updatedFilesStatuses);
  };

  const clearFileState = () => {
    setFiles([]);
    setFilesStatuses({});
    toast.dismiss();
  };

  const renderButtons = () => {
    return (
      <div className={classes.buttonsArea}>
        <Button
          color='secondary'
          size='large'
          variant='contained'
          onClick={clearFileState}
          disabled={files.length === 0}
        >
          Clear
        </Button>
        <Button
          color='primary'
          size='large'
          variant='contained'
          onClick={retrieveDrProfiles}
          disabled={!filesNeedDrProfile || files.length === 0}
        >
          Retrieve Parameters
        </Button>
        <Button
          color='primary'
          size='large'
          variant='contained'
          onClick={handleSubmit}
          disabled={files.length === 0}
        >
          Submit
        </Button>
      </div>
    );
  };

  const closeModal = () => {
    setProblematicCsvs([]);
  };

  return (
    <>
      <ToastContainer />
      <Paper className={classes.detailsPaper}>
        <Uploader
          profile={profile}
          handleUpload={handleUpload}
          classes={classes}
          maxByteSize={52428800}
        />
      </Paper>
      <Paper className={classes.filePreviewPaper}>
        <div className={classes.filePreviewRoot}>
          <List className={classes.filePreviewList}>
            {files.length > 0 ? renderFilePreviews() : null}
          </List>
        </div>
      </Paper>
      <Paper className={classes.uploadButtonsPaper}>
        <div className={classes.buttonsRoot}>{renderButtons()}</div>
      </Paper>
      <OverlayLoader
        classes={classes}
        open={uploadStatus === 'uploading'}
        multiple={true}
        files={
          filesStatuses &&
          Object.keys(filesStatuses).filter(
            file =>
              filesStatuses[file].drProfileStatus &&
              filesStatuses[file].drProfileStatus.complete
          )
        }
      />
      <ErrorAlert
        classes={classes}
        open={problematicCsvs.length > 0 && uploadStatus !== 'uploading'}
        failedCsvs={problematicCsvs}
        handleClose={closeModal}
      />
    </>
  );
};

export default withRouter(compose(withStyles(styles))(ListUpload));
