export const styles = theme => ({
  billingContainer: {
    minHeight: theme.containers.paper.height.min,
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm },
    '& > div': {
      marginTop: 30
    },
    '& > div:first-child': {
      marginTop: 0
    },
    '& > div:last-child': {
      marginBottom: 48
    }
  },
  sectionTitle: {
    marginBottom: 16,
    fontSize: 16
  },
  sectionContainer: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  }
});
