import {
  TABLE_SELECT_SCHEDULED_SELECT_ENTRY,
  TABLE_SELECT_RESCHEDULED_SELECT_ENTRY,
  TABLE_SELECT_NOTSCHEDULED_SELECT_ENTRY,
  TABLE_SELECT_CALLSANSWERED_SELECT_ENTRY
} from '../actions/types';

const tableSelectedScheduledEntry = (state = null, action) => {
  switch (action.type) {
    case TABLE_SELECT_SCHEDULED_SELECT_ENTRY:
      return action.payload;
    default:
      return state;
  }
};

const tableSelectedNotScheduledEntry = (state = null, action) => {
  switch (action.type) {
    case TABLE_SELECT_NOTSCHEDULED_SELECT_ENTRY:
      return action.payload;
    default:
      return state;
  }
};

const tableSelectedRescheduledEntry = (state = null, action) => {
  switch (action.type) {
    case TABLE_SELECT_RESCHEDULED_SELECT_ENTRY:
      return action.payload;
    default:
      return state;
  }
};

const tableSelectedCallsAnsweredEntry = (state = null, action) => {
  switch (action.type) {
    case TABLE_SELECT_CALLSANSWERED_SELECT_ENTRY:
      return action.payload;
    default:
      return state;
  }
};

export const TableSelectReducer = {
  tableSelectedScheduledEntry,
  tableSelectedNotScheduledEntry,
  tableSelectedRescheduledEntry,
  tableSelectedCallsAnsweredEntry
};
