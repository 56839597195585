const queryToCollection = querySnapshot => {
  const collection = [];

  querySnapshot.forEach(doc => {
    collection.push({ ...doc.data(), id: doc.id });
  });

  return collection;
};

export default queryToCollection;
