import React from 'react';
import BackIcon from '@material-ui/icons/ArrowBack';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { IconButton, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const RegionName = ({ classes, history, clientGroupRegion, clientGroupId }) => {
  const region = clientGroupRegion;
  const onClick = () =>
    history.push(
      `/clientGroup/${clientGroupId}/regions?pathname=/offices/clientGroups/regions`
    );

  return (
    <div>
      <div className={classes.regionNameHeading}>
        <Typography variant='h6' className={classes.regionBreadcrumb}>
          Regions
        </Typography>
        <Typography variant='h6' className={classes.regionNameHeadText}>
          <Tooltip title='Back to Regions'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Back to Regions'
              onClick={onClick}
              className={classes.regionNameBackIcon}
            >
              <BackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.regionNameText}>
            {region ? region.name : ''}
          </div>
          <span
            className={
              region && region.status === 'paused'
                ? classes.pausedStatus
                : region && region.status === 'inactive'
                ? classes.inactiveStatus
                : region && region.status === 'active'
                ? classes.activeStatus
                : region && region.status === 'onboarding'
                ? classes.onboardingStatus
                : region && region.status === 'failedOnboarding'
                ? classes.failedOnboardingStatus
                : ''
            }
          >
            <StatusIcon />
          </span>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const regionData = state.firestore.ordered.regionDetailInfo;
  const region =
    isLoaded(regionData) &&
    !isEmpty(regionData) &&
    regionData[0].id === props.regionId
      ? regionData[0]
      : null;
  return { region };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'regions',
      doc: props.regionId,
      storeAs: 'regionDetailInfo'
    }
  ]),
  withStyles(styles, { withTheme: true }),
  withRouter
)(RegionName);
