/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import helpers from '../../helpers';

const styles = theme => ({
  label: {
    top: -4,
    left: 14
  },
  selectField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '& select': {
      padding: '18.5px 18px 18.5px 14px'
    },
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

class Label extends Component {
  componentDidUpdate() {
    this.props.setLabelWidth(ReactDOM.findDOMNode(this.ref).offsetWidth);
  }

  render() {
    const { classes, label } = this.props;
    return (
      // eslint-disable-next-line no-return-assign
      <InputLabel
        shrink={true}
        className={classes.label}
        ref={ref => (this.ref = ref)}
      >
        {label}
      </InputLabel>
    );
  }
}

const generateErrorMessage = (fieldName, touched, errors, submitCount) => {
  /* this first conditional is to force errorState for categories */
  if (
    errors &&
    errors.reason &&
    fieldName === 'reason.category' &&
    submitCount > 0
  ) {
    return errors['reason']['category'];
  }
  if (fieldName.includes('.')) {
    const reasonProps = fieldName.split('.');
    const reason = reasonProps[0];
    const catSlashSubCat = reasonProps[1];
    if (errors[reason] && touched[reason]) {
      if (
        (touched[reason][catSlashSubCat] || submitCount > 0) &&
        errors[reason][catSlashSubCat]
      ) {
        return errors[reason][catSlashSubCat];
      }
    }
  }
  if ((touched[fieldName] || submitCount > 0) && errors[fieldName]) {
    return errors[fieldName];
  }
  return null;
};

const generateErrorState = (fieldName, touched, errors, submitCount) => {
  /* this first conditional is to force errorState for categories */
  if (
    errors &&
    errors.reason &&
    fieldName === 'reason.category' &&
    submitCount > 0
  ) {
    return true;
  }
  if (fieldName.includes('.')) {
    const reasonProps = fieldName.split('.');
    const reason = reasonProps[0];
    const catSlashSubCat = reasonProps[1];
    if (errors[reason] && touched[reason]) {
      if (
        (touched[reason][catSlashSubCat] || submitCount > 0) &&
        errors[reason][catSlashSubCat]
      ) {
        return true;
      }
    }
  }
  if ((touched[fieldName] || submitCount > 0) && errors[fieldName]) {
    return true;
  }
  return false;
};

const FieldSelect = ({
  classes,
  disabled,
  field,
  form: { errors, touched, submitCount },
  label,
  options,
  optionKey,
  optionText,
  optionValue,
  optionTextDelimiter,
  onChange,
  variant
}) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const renderOptions = options =>
    _.map(options, option => (
      <option key={option[optionKey]} value={option[optionValue]}>
        {optionText.includes('.')
          ? helpers.resolvePath(option, optionText)
          : optionText instanceof Array
          ? optionTextDelimiter
            ? `${option[optionText[0]]}${optionTextDelimiter}${
                option[optionText[1]]
              }`
            : `${option[optionText[0]]} ${option[optionText[1]]}`
          : option[optionText]}
      </option>
    ));

  return (
    <FormControl
      variant={variant}
      className={classes.selectField}
      disabled={disabled}
      error={generateErrorState(field.name, touched, errors, submitCount)}
    >
      <Label classes={classes} label={label} setLabelWidth={setLabelWidth} />
      <Select
        native
        {...field}
        onChange={e => {
          field.onBlur(e);
          field.onChange(e);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        input={<OutlinedInput labelWidth={labelWidth} notched={true} />}
      >
        {field.name !== 'serviceOffering' ? <option value='' /> : null}
        {options ? renderOptions(options) : ''}
      </Select>
      <FormHelperText>
        {generateErrorMessage(field.name, touched, errors, submitCount)}
      </FormHelperText>
    </FormControl>
  );
};

Label.propTypes = {
  classes: PropTypes.object.isRequired
};

FieldSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(FieldSelect);
