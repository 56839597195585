/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import VoicemailTable from './VoicemailTable';
import { getVoicemails } from './FetchVoiceMails';
import { styles } from './styles';
import './styles.css';

const Voicemails = props => {
  const { classes, office } = props;
  const [voicemails, setVoicemails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setVoicemails([]);
  }, [loading]);

  useEffect(() => {
    if (office && office.id && loading === true) {
      getVoicemails(office.id, setVoicemails, voicemails);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [office]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <VoicemailTable
          voicemails={voicemails}
          setVoicemails={setVoicemails}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(Voicemails);
