export const styles = theme => ({
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  dialogSubtitle: {
    position: 'absolute',
    fontSize: 10,
    bottom: 2,
    left: 0,
    color: 'white'
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: 24,
    paddingTop: 24
  }
});
