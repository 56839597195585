import {
  DIALOG_OVERALL_MISTAKES_DETAILS_CLICK,
  DIALOG_OVERALL_MISTAKES_DETAILS_ITEM
} from '../actions/types';

export const dialogOverallMistakesDetailsOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_OVERALL_MISTAKES_DETAILS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogOverallMistakesDetailsItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_OVERALL_MISTAKES_DETAILS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogOverallMistakesDetailsReducer = {
  dialogOverallMistakesDetailsOpen,
  dialogOverallMistakesDetailsItem
};
