import React from 'react';
import ScheduledIcon from '@material-ui/icons/Schedule';
import ScheduledTreatmentIcon from '@material-ui/icons/SettingsBackupRestore';
import NotScheduledIcon from '@material-ui/icons/Announcement';
import RELOsIcon from '@material-ui/icons/CallMissedOutgoing';
import RescheduledIcon from '@material-ui/icons/Restore';
import CallsAnsweredIcon from '@material-ui/icons/PhoneCallback';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

export const CALL_ANS_SCHEDULED_CATEGORY = 'Appointment Scheduled';

export const DAYS = [
  { name: 'Monday', key: 'monday' },
  { name: 'Tuesday', key: 'tuesday' },
  { name: 'Wednesday', key: 'wednesday' },
  { name: 'Thursday', key: 'thursday' },
  { name: 'Friday', key: 'friday' },
  { name: 'Saturday', key: 'saturday' },
  { name: 'Sunday', key: 'sunday' }
];

export const STATUSES = [
  { key: 'active', value: 'Active' },
  { key: 'inactive', value: 'Inactive' },
  { key: 'paused', value: 'Paused' },
  { key: 'onboarding', value: 'Onboarding' },
  { key: 'failedOnboarding', value: 'Failed Onboarding' }
];

export const OPSBILLINGSTATUSES = [
  { key: 'current', value: 'Current' },
  { key: 'overdue', value: 'Overdue' },
  { key: 'pending suspension', value: 'Pending Suspension' }
];

export const OFFERINGS = [
  { name: 'Base', key: 'base' },
  { name: 'Recall', key: 'recall' },
  { name: 'Recall+', key: 'recallplus' },
  { name: 'Treatment', key: 'treatment' },
  { name: 'RELOs', key: 'relos' },
  { name: 'Answering', key: 'answering' },
  { name: 'Rescheduling', key: 'rescheduling' },
  { name: 'Website Chat', key: 'websiteChat' },
  { name: 'Insurance Verification', key: 'insuranceVerification' }
];

export const GROUPS = [
  { name: 'Calls Answered', key: 'callsAnswered' },
  { name: 'Scheduled', key: 'scheduled' },
  { name: 'Treatment', key: 'treatment' },
  { name: 'RELOs', key: 'relos' },
  { name: 'Website Chat', key: 'websiteChat' },
  { name: 'Financial Controller', key: 'financialController' },
  { name: 'Customer Management', key: 'customerManagement' },
  { name: 'Admin', key: 'admin' },
  { name: 'Dev', key: 'dev' },
  { name: 'Calling Management', key: 'callingManagement' },
  { name: 'Team Lead', key: 'teamLead' },
  { name: 'Insurance Verification', key: 'insuranceVerification' },
  { name: 'IP Exempt', key: 'IPExempt' },
  { name: 'No Commissions', key: 'noCommissions' },
  { name: 'Contractor', key: 'contractor' },
];

export const offeringsObject = {
  base: 'Base',
  recall: 'Recall',
  recallplus: 'Recall+',
  treatment: 'Treatment',
  relos: 'RELOs',
  answering: 'Answering',
  rescheduling: 'Rescheduling',
  websiteChat: 'Website Chat',
  insuranceVerification: 'Insurance Verification'
};

export const entryTypes = [
  {
    code: 'scheduled',
    text: 'Scheduled',
    icon(fontSize, marginRight) {
      return <ScheduledIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'scheduledTreatment',
    text: 'Scheduled Treatment',
    icon(fontSize, marginRight) {
      return <ScheduledTreatmentIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'relos',
    text: 'RELOs',
    icon(fontSize, marginRight) {
      return <RELOsIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'insuranceVerification',
    text: 'Insurance Verification',
    icon(fontSize, marginRight) {
      return <DoneOutlineIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'notScheduled',
    text: 'Not Scheduled',
    icon(fontSize, marginRight) {
      return <NotScheduledIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'rescheduled',
    text: 'Rescheduled',
    icon(fontSize, marginRight) {
      return <RescheduledIcon style={{ fontSize, marginRight }} />;
    }
  },
  {
    code: 'callsAnswered',
    text: 'Calls Answered',
    icon(fontSize, marginRight) {
      return <CallsAnsweredIcon style={{ fontSize, marginRight }} />;
    }
  }
];

export const notScheduledEntryTypes = [
  { key: 'recall', name: 'Recall' },
  { key: 'treatment', name: 'Treatment' }
];

export const doNotBillReasons = [
  { value: 'Scheduling Mistake' },
  { value: 'No Show' },
  { value: 'Credit Given' },
  { value: 'Other' }
];

export const officeStatuses = [
  { key: 'active', value: 'Active' },
  { key: 'inactive', value: 'Inactive' },
  { key: 'paused', value: 'Paused' },
  { key: 'onboarding', value: 'Onboarding' }
];

export const mistakeReasons = {
  scheduled: [
    { value: 'Duplicate Appointment' },
    { value: 'Ignored Popup' },
    { value: 'Other' },
    { value: 'Patient is not on the Schedule' },
    { value: 'Scheduled Incorrectly in PMS' },
    { value: 'Scheduled When Office was Closed' },
    { value: 'Wrong Office' },
    { value: 'Wrong Appointment Time' },
    { value: 'Wrong Operatory' },
    { value: 'Wrong Patient Name' },
    { value: 'Wrong Patient Phone' }
  ],
  notScheduled: [
    { value: 'Duplicate Appointment' },
    { value: 'Ignored Popup' },
    { value: 'Other' },
    { value: 'Patient is not on the Schedule' },
    { value: 'Scheduled Incorrectly in PMS' },
    { value: 'Scheduled When Office was Closed' },
    { value: 'Wrong Office' },
    { value: 'Wrong Appointment Time' },
    { value: 'Wrong Operatory' },
    { value: 'Wrong Patient Name' },
    { value: 'Wrong Patient Phone' }
  ],
  rescheduled: [
    { value: 'Duplicate Appointment' },
    { value: 'Ignored Popup' },
    { value: 'Other' },
    { value: 'Patient is not on the Schedule' },
    { value: 'Scheduled Incorrectly in PMS' },
    { value: 'Scheduled When Office was Closed' },
    { value: 'Wrong Office' },
    { value: 'Wrong Appointment Time' },
    { value: 'Wrong Operatory' },
    { value: 'Wrong Patient Name' },
    { value: 'Wrong Patient Phone' }
  ],
  callsAnswered: [
    { value: 'Duplicate Appointment' },
    { value: 'Ignored Popup' },
    { value: 'Other' },
    { value: 'Patient is not on the Schedule' },
    { value: 'Scheduled Incorrectly in PMS' },
    { value: 'Scheduled When Office was Closed' },
    { value: 'Wrong Office' },
    { value: 'Wrong Appointment Time' },
    { value: 'Wrong Operatory' },
    { value: 'Wrong Patient Name' },
    { value: 'Wrong Patient Phone' }
  ]
};

export const mistakeReasonCallsAns = [
  { value: 'Duplicate Appointment' },
  { value: 'Ignored Popup' },
  { value: 'Other' },
  { value: 'Patient is not on the Schedule' },
  { value: 'Scheduled Incorrectly in PMS' },
  { value: 'Scheduled When Office was Closed' },
  { value: 'Wrong Office' },
  { value: 'Wrong Appointment Time' },
  { value: 'Wrong Operatory' },
  { value: 'Wrong Patient Name' },
  { value: 'Wrong Patient Phone' }
];

export const mistakeReasonMessages = [
  { value: 'Duplicate Appointment' },
  { value: 'Ignored Popup' },
  { value: 'Other' },
  { value: 'Patient is not on the Schedule' },
  { value: 'Scheduled Incorrectly in PMS' },
  { value: 'Scheduled When Office was Closed' },
  { value: 'Wrong Office' },
  { value: 'Wrong Appointment Time' },
  { value: 'Wrong Operatory' },
  { value: 'Wrong Patient Name' },
  { value: 'Wrong Patient Phone' }
];

export const mistakeReasonNotScheduled = [
  { value: 'Duplicate Appointment' },
  { value: 'Ignored Popup' },
  { value: 'Other' },
  { value: 'Patient is not on the Schedule' },
  { value: 'Scheduled Incorrectly in PMS' },
  { value: 'Scheduled When Office was Closed' },
  { value: 'Wrong Office' },
  { value: 'Wrong Appointment Time' },
  { value: 'Wrong Operatory' },
  { value: 'Wrong Patient Name' },
  { value: 'Wrong Patient Phone' }
];

export const mistakeReasonScheduled = [
  { value: 'Duplicate Appointment' },
  { value: 'Ignored Popup' },
  { value: 'Other' },
  { value: 'Patient is not on the Schedule' },
  { value: 'Scheduled Incorrectly in PMS' },
  { value: 'Scheduled When Office was Closed' },
  { value: 'Wrong Office' },
  { value: 'Wrong Appointment Time' },
  { value: 'Wrong Operatory' },
  { value: 'Wrong Patient Name' },
  { value: 'Wrong Patient Phone' }
];

export const pms = [
  { name: 'Open Dental', key: 'openDental' },
  { name: 'Curve', key: 'curve' },
  { name: 'Denticon', key: 'denticon' },
  { name: 'Dentrix', key: 'dentrix' },
  { name: 'Dentrix Ascend', key: 'dentrixAscend' },
  { name: 'Practice Works', key: 'practiceWorks' },
  // {name: 'Soft Dent', key: 'softDent'},
  { name: 'Eagle Soft', key: 'eagleSoft' },
  { name: 'Mac Practice', key: 'macPractice' }
];

export const pmsObj = {
  openDental: 'Open Dental',
  curve: 'Curve',
  dentrix: 'Dentrix',
  dentrixAscend: 'Dentrix Ascend',
  practiceWorks: 'Practice Works',
  eagleSoft: 'Eagle Soft',
  denticon: 'Denticon',
  // 'softDent': 'Soft Dent',
  macPractice: 'Mac Practice'
};

export const drProfileObj = {
  officeName: "Office's Name",
  balance: 'Balance',
  location: 'Location',
  minAge: 'Minimum Age',
  maxAge: 'Maximum Age',
  minMonths: 'Minimum Months',
  maxMonths: 'Maximum Months',
  pms: 'Practice Management Software',
  pediatric: 'Pediatric',
  filterMedicaid: 'Filter Medicaid'
};

export const defaultOfferingPrices = {
  answering: 'f3dcbd67b05b774e4229',
  base: 'standard',
  recall: 'newStandard',
  recallplus: 'standard',
  relos: 'standard',
  insuranceVerification: 'standard',
  rescheduling: 'standard',
  treatment: 'c628bd5e19aea1733485',
  websiteChat: 'standard'
};

export const reportTypesOptions = [
  { name: 'Recall Statistics Report', key: 'recallReport' },
  { name: 'Scheduled Appointments Report', key: 'scheduledReport' }
];

export const reportTypeDisplays = {
  'Recall Statistics Report': 'recallReport',
  'Scheduled Appointments Report': 'scheduledReport'
};
