import moment from 'moment-timezone';
import { CALLFORCE_TZ } from '../../../../services/constants';

export const creditsColumns = [
  {
    index: 1,
    key: 'clientName',
    essential: true,
    text: 'Office',
    spacing: { xs: 6, sm: 3 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'createdTime',
    essential: false,
    text: 'Created',
    spacing: { xs: 3, sm: 2 },
    formatData: doc => {
      const createdTime = doc.createdTime._seconds
        ? doc.createdTime._seconds
        : doc.createdTime.seconds;

      return moment.unix(createdTime).tz(CALLFORCE_TZ).format('MMM D, YYYY');
    },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'desc'
  },
  {
    index: 3,
    key: 'type',
    essential: false,
    text: 'Type',
    spacing: { xs: 2, sm: 1 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'status',
    essential: false,
    text: 'Status',
    spacing: { xs: 2, sm: 2 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 5,
    key: 'remaining',
    essential: true,
    text: 'Remaining',
    spacing: { xs: 3, sm: 2 },
    formatData: doc => `$${parseFloat(doc.remaining).toFixed(2)}`,
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'desc'
  },
  {
    index: 6,
    key: 'value',
    essential: true,
    text: 'Value',
    spacing: { xs: 3, sm: 2 },
    formatData: doc => `$${parseFloat(doc.value).toFixed(2)}`,
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'desc'
  }
];
