import React, { Component } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import firebase from 'firebase/app';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';
import qs from 'qs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogAddEditOfficeUserClick,
  dialogAddEditOfficeUserItem
} from '../actions';
import { emailInCollection } from '../services/helpers';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { remail } from '../services/validation';
import { withStyles } from '@material-ui/core/styles';

class DialogAddEditOfficeUserContent extends Component {
  state = {
    errorText: '',
    submitError: false,
    loadingAfterSubmit: false,
    loadingAfterRemove: false,
    showError: false
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { submitError } = this.state;

    if (submitError === false && nextState.submitError === true) {
      this.setState({ showError: true });
    }
  }

  onSubmit = async values => {
    const { firestore, item, office } = this.props;
    const { email, firstName, lastName } = values;
    const accountData = { email, firstName, lastName };

    this.setState({
      loadingAfterSubmit: true,
      submitError: false,
      showError: false
    });

    if (item && item.id) {
      firestore
        .update({ collection: 'accounts', doc: item.id }, accountData)
        .then(() => this.updateOfficeEmails(values, item, office))
        .catch(() =>
          this.setState({ submitError: true, loadingAfterSubmit: false })
        );
    } else {
      const profile = {
        email,
        firstName,
        lastName,
        offices: [office.id],
        username: email.split('@')[0]
      };
      const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/createUser`;
      const reqBody = { email, profile };
      const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };

      axios
        .post(url, qs.stringify(reqBody), config)
        .then(user => {
          this.updateOfficeEmails(values, user, office);
        })
        .catch(err => {
          this.setState({
            submitError: true,
            errorText: err.code,
            loadingAfterSubmit: false
          });
        });
    }
  };

  updateOfficeEmails(values, user, office) {
    const {
      dialogAddEditOfficeUserClick,
      dialogAddEditOfficeUserItem,
      firestore
    } = this.props;
    const { email, firstName, lastName, reportEmail, billingEmail } = values;
    let reportEmails = office.officeNotes.reportEmails
      ? JSON.parse(JSON.stringify(office.officeNotes.reportEmails))
      : [];
    let billingEmails = office.officeNotes.billingEmails
      ? JSON.parse(JSON.stringify(office.officeNotes.billingEmails))
      : [];
    let reportEmailPos = 0;
    let billingEmailPos = 0;

    if (reportEmail) {
      reportEmailPos = emailInCollection(email, reportEmails)
        ? reportEmails.map(e => e.email).indexOf(email)
        : reportEmails.length;
      reportEmails[reportEmailPos] = { firstName, lastName, email };
    } else {
      if (emailInCollection(email, reportEmails)) {
        reportEmailPos = reportEmails.map(e => e.email).indexOf(email);
        reportEmails.splice(reportEmailPos, 1);
      }
    }

    if (billingEmail) {
      billingEmailPos = emailInCollection(email, billingEmails)
        ? billingEmails.map(e => e.email).indexOf(email)
        : billingEmails.length;
      billingEmails[billingEmailPos] = { firstName, lastName, email };
    } else {
      if (emailInCollection(email, billingEmails)) {
        billingEmailPos = billingEmails.map(e => e.email).indexOf(email);
        billingEmails.splice(billingEmailPos, 1);
      }
    }

    firestore
      .update(
        { collection: 'clients', doc: office.id },
        { officeNotes: { reportEmails, billingEmails } }
      )
      .then(() => {
        dialogAddEditOfficeUserClick(false);
        dialogAddEditOfficeUserItem(null);
        this.setState({ loadingAfterSubmit: false });
      })
      .catch(err =>
        this.setState({
          submitError: true,
          errorText: err.code,
          loadingAfterSubmit: false
        })
      );
  }

  removeOfficeEmails() {
    const {
      dialogAddEditOfficeUserClick,
      dialogAddEditOfficeUserItem,
      firestore,
      item,
      office
    } = this.props;
    let reportEmails = office.officeNotes.reportEmails
      ? JSON.parse(JSON.stringify(office.officeNotes.reportEmails))
      : [];
    let billingEmails = office.officeNotes.billingEmails
      ? JSON.parse(JSON.stringify(office.officeNotes.billingEmails))
      : [];

    if (emailInCollection(item.email, reportEmails)) {
      let reportEmailPos = reportEmails.map(e => e.email).indexOf(item.email);
      reportEmails.splice(reportEmailPos, 1);
    }

    if (emailInCollection(item.email, billingEmails)) {
      let billingEmailPos = billingEmails.map(e => e.email).indexOf(item.email);
      billingEmails.splice(billingEmailPos, 1);
    }

    firestore
      .update(
        { collection: 'clients', doc: office.id },
        { officeNotes: { reportEmails, billingEmails } }
      )
      .then(() => {
        dialogAddEditOfficeUserClick(false);
        dialogAddEditOfficeUserItem(null);
        this.setState({ loadingAfterRemove: false });
      })
      .catch(err =>
        this.setState({
          submitError: true,
          errorText: err.code,
          loadingAfterRemove: false
        })
      );
  }

  handleRemoveUser() {
    const { firestore, item, office } = this.props;

    this.setState({
      loadingAfterRemove: true,
      submitError: false,
      showError: false
    });
    firestore
      .update(
        { collection: 'accounts', doc: item.id },
        { offices: firebase.firestore.FieldValue.arrayRemove(office.id) }
      )
      .then(() => this.removeOfficeEmails())
      .catch(err =>
        this.setState({
          submitError: true,
          errorText: err.code,
          loadingAfterRemove: false
        })
      );
  }

  renderTextField(field) {
    const {
      meta: { touched, error }
    } = field;

    return (
      <TextField
        label={field.label}
        className={field.className}
        margin='normal'
        type={field.type}
        autoComplete={
          field.fieldType === 'password' ? 'off' : field.autoComplete
        }
        error={touched && error ? true : false}
        helperText={touched && error}
        variant='outlined'
        disabled={field.disabled}
        {...field.input}
      />
    );
  }

  renderCheckbox(field) {
    return (
      <span className={field.containerClass}>
        <Checkbox
          className={field.checkboxClass}
          checked={field.input.value}
          onChange={() => {
            field.input.onChange(!field.input.value);
          }}
          value={field.name}
        />
        <Typography variant='body1' className={field.checkboxLabelClass}>
          {field.label}
        </Typography>
      </span>
    );
  }

  render() {
    const { handleSubmit, classes, item } = this.props;
    const {
      errorText,
      loadingAfterRemove,
      loadingAfterSubmit,
      showError
    } = this.state;

    return (
      <form
        className={classes.form}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
      >
        {showError ? (
          <Typography
            variant='body1'
            className={classes.errorText}
          >{`Unable to Save (Code: ${errorText})`}</Typography>
        ) : (
          ''
        )}
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Field
              name='firstName'
              component={this.renderTextField}
              label='First Name'
              type='text'
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.secondColumn}>
            <Field
              name='lastName'
              component={this.renderTextField}
              label='Last Name'
              type='text'
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.formRow}>
          <Grid item xs={12}>
            <Field
              name='email'
              component={this.renderTextField}
              label='Email'
              type='email'
              disabled={item ? true : false}
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.formRow}>
          <Grid item xs={12} sm={6}>
            <Field
              name='reportEmail'
              label='Report Email'
              component={this.renderCheckbox}
              containerClass={classes.checkboxContainer}
              checkboxClass={classes.checkbox}
              checkboxLabelClass={classes.checkboxLabel}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.secondColumn}>
            <Field
              name='billingEmail'
              label='Billing Email'
              component={this.renderCheckbox}
              containerClass={classes.checkboxContainer}
              checkboxClass={classes.checkbox}
              checkboxLabelClass={classes.checkboxLabel}
            />
          </Grid>
        </Grid>
        <Grid container spacing={24} className={classes.formRow}>
          <Grid item xs={12} className={classes.buttonContainer}>
            {item ? (
              <Button
                size='large'
                color='primary'
                className={classes.button}
                style={{ marginRight: 8 }}
                onClick={() => this.handleRemoveUser()}
              >
                {loadingAfterRemove ? (
                  <Loader type='Oval' color='#696969' height='13' width='13' />
                ) : (
                  'Remove'
                )}
              </Button>
            ) : null}
            <Button
              size='large'
              variant='outlined'
              color='secondary'
              className={classes.button}
              type='submit'
            >
              {loadingAfterSubmit ? (
                <Loader type='Oval' color='#055f5b' height='13' width='13' />
              ) : item ? (
                'Save'
              ) : (
                'Add'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const styles = theme => ({
  form: {
    marginTop: theme.spacing.unit * 1.5
  },
  errorText: {
    textAlign: 'center',
    color: '#F44336',
    fontStyle: 'italic'
  },
  textField: {
    width: '100%'
  },
  formRow: {
    '& > div > div': {
      marginTop: 0
    }
  },
  secondColumn: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px !important',
      '& > div': {
        marginTop: 0
      }
    }
  },
  checkboxContainer: {
    display: 'flex',
    flex: 1
  },
  checkbox: {
    display: 'flex'
  },
  checkboxLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const validate = values => {
  const errors = {};

  if (!values.email) errors.email = 'Required';
  if (values.email) {
    if (!remail.test(values.email)) {
      errors.email = 'Please enter a valid email address';
    }
  }

  return errors;
};

const mapStateToProps = (state, props) => {
  const { item, office } = props;
  let initialValues = {
    firstName: item && item.firstName ? item.firstName : '',
    lastName: item && item.lastName ? item.lastName : '',
    email: item && item.email ? item.email : '',
    reportEmail:
      office &&
      office.officeNotes.reportEmails &&
      emailInCollection(item && item.email, office.officeNotes.reportEmails)
        ? true
        : false,
    billingEmail:
      office &&
      office.officeNotes.reportEmails &&
      emailInCollection(item && item.email, office.officeNotes.billingEmails)
        ? true
        : false
  };

  return { initialValues };
};

export default compose(
  connect(mapStateToProps, {
    dialogAddEditOfficeUserClick,
    dialogAddEditOfficeUserItem
  }),
  reduxForm({
    validate,
    form: 'DialogAddEditOfficeUserForm',
    enableReinitialize: true
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(DialogAddEditOfficeUserContent);
