import React, { useState, useEffect, useContext } from 'react';
import DialogTransition from '../../../../../../../../../../../../../../components/DialogTransition';
import qs from 'qs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Dialog,
  Tooltip,
  Typography,
  DialogContent,
  withStyles,
  IconButton,
  Button
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from 'react-select';
import { Formik, Form, FieldArray } from 'formik';
import { firestoreConnect } from 'react-redux-firebase';
import { AppContext } from '../../../../../../../../../../../../../../contexts';
import { t } from 'typy';
import { styles } from './styles';
import { Close } from '@material-ui/icons';

const DialogAddRegionOffices = props => {
  const {
    classes,
    onClose,
    openDialog,
    onRegionOfficesAddRedirect,
    fullScreen,
    params,
    regionOffice,
    setOffice,
    firestore
  } = props;
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);
  const [checkedOffices, setCheckedOffices] = useState(false);
  const [offices, setOffices] = useState(null);
  const [regionOffices, setRegionOffices] = useState([]);
  const [validateError, setValidateError] = useState(false);
  const regionOfficeOptions = [];

  useEffect(() => {
    const getAllOffices = async () => {
      try {
        const officeIds = await firestore
          .collection('clientGroups')
          .doc(params.clientGroupId)
          .get();
        setOffices(officeIds.data().offices);

        const regionOfficeIds = await firestore
          .collection('clientGroups')
          .doc(params.clientGroupId)
          .collection('regions')
          .doc(params.regionId)
          .get();

        setRegionOffices(regionOfficeIds.data().regionOffices);
        setCheckedOffices(true);
      } catch (e) {
        console.error(e);
      }
    };

    if (!checkedOffices && !regionOffice) {
      getAllOffices();
    }

    const getOfficeOptions = async () => {
      const clientGroupOffices = [];
      for (const officeId in offices) {
        clientGroupOffices.push(getOfficeOptionsData(offices[officeId]));
      }
      await Promise.all(clientGroupOffices);
    };

    const getOfficeOptionsData = async officeId => {
      try {
        const officeRef = firestore.collection('clients').doc(officeId);

        const officeData = await officeRef.get();
        const officeObj = {
          label: officeData.data().officeInformation.name,
          value: officeId
        };
        regionOfficeOptions.push(officeObj);
        regionOfficeOptions.forEach(office => {
          if (regionOffices[0] && regionOffices.includes(office.value)) {
            const officeIndex = regionOfficeOptions.indexOf(office);
            regionOfficeOptions.splice(officeIndex, 1);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    if (openDialog && !regionOffice) {
      getOfficeOptions();
    }
  }, [
    firestore,
    offices,
    checkedOffices,
    params.clientGroupId,
    params.regionId,
    regionOffice,
    regionOffices,
    openDialog,
    regionOfficeOptions
  ]);

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    const { firestore } = props;
    values.offices = removeOfficeNames(values.offices);
    values.offices.forEach(office => {
      regionOffices.push(office.value);
    });

    try {
      await firestore
        .collection('clientGroups')
        .doc(params.clientGroupId)
        .collection('regions')
        .doc(params.regionId)
        .set({ regionOffices }, { merge: true });

      setLoadingAfterSubmit(false);
      onRegionOfficesAddRedirect(params.regionId);
    } catch (e) {
      console.log(e);
      setLoadingAfterSubmit(false);
      onClose();
    }
  };

  const removeOfficeNames = offices => {
    return offices.map(office => (office.id ? (office = office.id) : office));
  };

  const displayOffices = (offices, arrayHelpers) => {
    return offices.map((office, key) =>
      office.label ? (
        <div key={key} className={classes.officeNames}>
          {office.label}
          <IconButton
            color='inherit'
            aria-label={`Remove ${office.label}`}
            className={classes.officeRemovalIcon}
            onClick={() => arrayHelpers.remove(key)}
            component='span'
            disableRipple
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null
    );
  };

  const removeOffice = async bool => {
    setLoadingAfterSubmit(true);
    const officeId = regionOffice.id;
    const removeFromRegionOnly = bool;
    const { clientGroupId, regionId } = params;
    try {
      const removeOffice = firebase
        .functions()
        .httpsCallable('offices-removeOffice');
      removeOffice({
        removeFromRegionOnly,
        offices,
        regionOffices,
        clientGroupId,
        regionId,
        officeId
      })
        .then(res => {
          setLoadingAfterSubmit(false);
          onRegionOfficesAddRedirect(params.regionId);
          setOffice(null);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      console.error(e);
      setLoadingAfterSubmit(false);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={openDialog}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            {regionOffice ? regionOffice.officeInformation.name : 'Add Offices'}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        {!regionOffice ? (
          <Formik initialValues={{ offices: [] }} onSubmit={onSubmit}>
            {({ isSubmitting, values, setFieldValue, initialValues }) => (
              <Form className={classes.dialogAddRegionOfficeForm}>
                <FieldArray
                  name='offices'
                  label='Choose Offices'
                  render={arrayHelpers => (
                    <>
                      <Select
                        placeholder={'Select Offices'}
                        className={classes.base}
                        label='Office Selection'
                        error={validateError}
                        options={regionOfficeOptions}
                        onChange={office => {
                          if (!office) return setValidateError(false);
                          const { offices } = values;
                          if (!offices.includes(office)) {
                            offices.push(office);
                          } else {
                            setValidateError(
                              `${office.label} is already added`
                            );
                          }
                          setFieldValue('offices', offices);
                        }}
                        previouslySelectedOfficeId={null}
                      />

                      <div className={classes.dialogOfficeList}>
                        {values.offices.length > 0
                          ? displayOffices(values.offices, arrayHelpers)
                          : null}
                      </div>
                    </>
                  )}
                />

                <div className={classes.dialogButtonContainer}>
                  <Button
                    size='large'
                    variant='outlined'
                    color='default'
                    className={classes.dialogButton}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    size='large'
                    variant='outlined'
                    color='secondary'
                    className={classes.dialogButton}
                    type='submit'
                    disabled={!values.offices[0]}
                  >
                    {loadingAfterSubmit ? (
                      <Loader
                        type='Oval'
                        color='#055f5b'
                        height='13'
                        width='13'
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className={classes.dialogViewRemoveButtonContainer}>
            <Button
              className={classes.dialogButton}
              size='large'
              variant='outlined'
              color='default'
            >
              <a
                href={
                  process.env.REACT_APP_ENV === 'prod'
                    ? `https://console.getcallforce.com/office/${regionOffice.id}/details?pathname=/offices/data`
                    : `http://console.local.getcallforce.com:3000/office/${regionOffice.id}/details?pathname=/offices/data`
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                View details
              </a>
            </Button>

            <Button
              size='large'
              variant='outlined'
              color='secondary'
              className={classes.dialogButton}
              disabled={loadingAfterSubmit === true}
              onClick={() => removeOffice(true)}
            >
              {loadingAfterSubmit ? (
                <Loader type='Oval' color='#055f5b' height='13' width='13' />
              ) : (
                'Remove from region'
              )}
            </Button>

            <Button
              size='large'
              variant='outlined'
              color='secondary'
              className={classes.dialogButton}
              disabled={loadingAfterSubmit === true}
              onClick={() => removeOffice(false)}
            >
              {loadingAfterSubmit ? (
                <Loader type='Oval' color='#055f5b' height='13' width='13' />
              ) : (
                'Remove from client group'
              )}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = t(searchObj, 'addRegionOfficesFormData').safeObject
    ? JSON.parse(t(searchObj, 'addRegionOfficesFormData').safeObject)
    : {};

  return { formData };
};

export default compose(
  connect(mapStateToProps, {}),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogAddRegionOffices);
