/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'redux';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { GROUPS } from '../../../../../services/options';
import { t } from 'typy';
import { db } from '../../../../../index';
import { withStyles } from '@material-ui/core/styles';
import { FieldCheckbox, FieldText } from '../../../../../components/Fields';
import GroupsFieldCheckbox from './GroupsFieldCheckbox';
import { phone, required } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import helpers from '../../../../../helpers';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const EmployeeInfoForm = ({ classes, dialogData, dialogOnClose, route }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [showSpinnerWhenLoading, setShowSpinnerWhenLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;

  const onSubmit = async (values, { setSubmitting }) => {
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    try {
      if (values.id) {
        const { id } = values;
        delete values.id;
        await db
          .collection('employees')
          .doc(id)
          .set(values, { merge: true })
          .then(() => {
            dialogOnClose(true);
            setError(null);
            setLoading(null);
            setSubmitting(false);
          })
          .catch(err => {
            setError(err.toString());
            setLoading(null);
            setSubmitting(false);
          });
      } else {
        const { groups, email, active } = values;
        const firstName =
          values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1);
        const lastName =
          values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1);
        const host = `${window.location.protocol}//${window.location.hostname}`;

        const addNewEmployee = firebase
          .functions()
          .httpsCallable('employees-addNewEmployee');
        addNewEmployee({
          email,
          firstName,
          lastName,
          groups,
          active,
          displayName: firstName + ' ' + lastName,
          host
        })
          .then(res => {
            dialogOnClose(true);
            setError(null);
            setLoading(null);
            setSubmitting(false);
          })
          .catch(err => {
            setError(err.toString());
            setLoading(null);
            setSubmitting(false);
          });
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          {showSpinnerWhenLoading ? (
            <Loader type='Oval' color='#696969' height='12' width='12' />
          ) : null}
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };
  const renderConfirmDelete = () => (
    <>
      <Button
        color='secondary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
          setError(null);
          setLoading(`Deleting Entry; Please Keep Browser Window Open`);
          helpers
            .deleteEntry(firebase, dialogData.id, 'callsAnswered')
            .then(() => {
              dialogOnClose(true);
              setError(null);
              setLoading(null);
            })
            .catch(err => {
              setError(err.toString());
              setLoading(null);
              setShowSpinnerWhenLoading(true);
            });
        }}
      >
        Yes, Delete it
      </Button>
      <Button
        color='primary'
        type='button'
        onClick={e => {
          e.preventDefault();
          setError(null);
          setLoading(null);
          setShowSpinnerWhenLoading(true);
          setConfirmDelete(false);
        }}
      >
        No, Nevermind
      </Button>
    </>
  );
  const renderActionButtons = isSubmitting => (
    <>
      {userAllowedToManage && dialogData ? (
        <Button
          color='secondary'
          type='button'
          onClick={() => {
            setShowSpinnerWhenLoading(false);
            setLoading('Please confirm...');
            setConfirmDelete(true);
          }}
          disabled={isSubmitting || loading !== null}
        >
          Delete
        </Button>
      ) : null}
      <Button
        color='primary'
        type='submit'
        disabled={isSubmitting || loading !== null}
      >
        Save
      </Button>
    </>
  );

  const validations = {
    firstName: required,
    lastName: required,
    email: required,
    employeePhone: v => helpers.composeFieldValidation(v, [required, phone]),
    active: required
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(null);
      } catch (err) {
        setLoading(null);
        setError(err.toString());
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const initialValues = dialogData ? dialogData : { active: true };

  return (
    <Formik initialValues={initialValues || {}} onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form className={classes.form}>
          <div className={classes.sectionTitle}>
            <Typography variant='subtitle2'>Employee Details</Typography>
          </div>
          <div className={classes.row}>
            <Field name='firstName' validate={validations.firstName}>
              {({ form, field }) => {
                return (
                  <FieldText
                    form={form}
                    field={field}
                    label='First Name'
                    variant='outlined'
                    onChange={e => {
                      field.onBlur(e);
                      field.onChange(e);
                    }}
                  />
                );
              }}
            </Field>
            <Field name='lastName' validate={validations.lastName}>
              {({ form, field }) => {
                return (
                  <FieldText
                    form={form}
                    field={field}
                    label='Last Name'
                    variant='outlined'
                    onChange={e => {
                      field.onBlur(e);
                      field.onChange(e);
                    }}
                  />
                );
              }}
            </Field>
          </div>
          <div className={classes.row}>
            {initialValues.email ? (
              <Field name='email' validate={validations.email}>
                {({ form, field }) => {
                  return (
                    <FieldText
                      form={form}
                      field={field}
                      label='Email'
                      variant='outlined'
                      disabled={initialValues.email !== ''}
                      onChange={e => {
                        field.onBlur(e);
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </Field>
            ) : (
              <Field name='email' validate={validations.email}>
                {({ form, field }) => {
                  return (
                    <FieldText
                      form={form}
                      field={field}
                      label='Email'
                      variant='outlined'
                      onChange={e => {
                        field.onBlur(e);
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </Field>
            )}
          </div>
          <FieldCheckbox
            name='active'
            label='Employee Active'
            disabled={loading !== null}
            checked={!!values.active}
          />

          <div className={classes.sectionTitle}>
            <Typography variant='subtitle2'>Groups</Typography>
          </div>

          <FieldArray
            name='groups'
            label='Select Groups'
            render={arrayHelpers => {
              const groupPermissionsHash = {};
              GROUPS.forEach(permissions => {
                groupPermissionsHash[permissions.key] = {
                  checked: false
                };
              });

              if (!values.groups) {
                values.groups = [];
              }

              values.groups.forEach((group, index) => {
                groupPermissionsHash[group] = {
                  checked: true,
                  index: index
                };
              });

              return (
                <>
                  {GROUPS.map((groupObject, index) => {
                    return (
                      <GroupsFieldCheckbox
                        key={index}
                        groupKey={groupObject.key}
                        name='groups'
                        groupsHash={groupPermissionsHash}
                        label={groupObject.name}
                        disabled={loading !== null}
                        arrayHelpers={arrayHelpers}
                      />
                    );
                  })}
                </>
              );
            }}
          />

          <div className={classes.actions}>
            {messageArea()}
            <div className={classes.actionButtons}>
              {confirmDelete
                ? renderConfirmDelete()
                : renderActionButtons(isSubmitting)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EmployeeInfoForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  offices: PropTypes.array
};

export default compose(withStyles(styles, { withTheme: true }))(
  EmployeeInfoForm
);
