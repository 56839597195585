import React from 'react';
import { Formik, Form, Field } from 'formik';
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import {
  FieldText,
  FieldCheckbox
} from '../../../../../../../components/Fields';
import { validations } from '../../../../../../../services/validation';

const ListConfigSettingForm = ({
  handleSubmit,
  classes,
  loadingAfterSubmit,
  initialValues,
  settingLabel,
  originalProp
}) => {
  const renderListConfigFieldSetting = (form, field) => {
    switch (field.name) {
      case 'location':
        return (
          <FieldText
            name={originalProp}
            form={form}
            field={field}
            label={settingLabel}
            variant='outlined'
          />
        );
      case 'ageParams.maxAge':
        return (
          <FieldText
            name={'ageParams.maxAge'}
            label={settingLabel + '- Max Age'}
            type='number'
            disabled={false}
            form={form}
            field={field}
            variant='outlined'
          />
        );
      case 'ageParams.minAge':
        return (
          <FieldText
            name={'ageParams.minAge'}
            label={settingLabel + '- Min Age'}
            type='number'
            disabled={false}
            form={form}
            field={field}
            variant='outlined'
          />
        );
      case 'pediatric':
        return (
          <FieldCheckbox
            name={originalProp}
            label={settingLabel}
            form={form}
            field={field}
            disabled={false}
            checked={!!form.values.pediatric}
          />
        );
      case 'balance':
        return (
          <FieldText
            name={originalProp}
            label={settingLabel}
            type='number'
            disabled={false}
            form={form}
            field={field}
            variant='outlined'
          />
        );
      case 'filterMedicaid':
        return (
          <FieldCheckbox
            name={originalProp}
            label={settingLabel}
            form={form}
            field={field}
            disabled={false}
            checked={!!form.values.filterMedicaid}
          />
        );
      case 'timeOverdue.minMonths':
        return (
          <FieldText
            name={'timeOverdue.minMonths'}
            label={settingLabel + '- Minimum Months'}
            type='number'
            disabled={false}
            form={form}
            field={field}
            variant='outlined'
          />
        );
      case 'timeOverdue.maxMonths':
        return (
          <FieldText
            name={'timeOverdue.maxMonths'}
            label={settingLabel + '- Maximum Months'}
            type='number'
            disabled={false}
            form={form}
            field={field}
            variant='outlined'
          />
        );
      default:
        return <h1>Error.</h1>;
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <Form className={classes.form}>
        {originalProp !== 'ageParams' && originalProp !== 'timeOverdue' ? (
          <Field
            className={classes.dialogTextField}
            name={originalProp}
            label={settingLabel}
            variant='outlined'
          >
            {({ form, field }) => {
              return renderListConfigFieldSetting(form, field);
            }}
          </Field>
        ) : (
          Object.keys(initialValues[originalProp]).map((prop, index) => {
            const name = originalProp + '.' + prop;
            return (
              <Field
                key={index}
                className={classes.dialogTextField}
                name={name}
                label={settingLabel}
                variant='outlined'
                validate={validations.required}
              >
                {({ form, field }) => {
                  return renderListConfigFieldSetting(form, field);
                }}
              </Field>
            );
          })
        )}
        <div className={classes.actionSection}>
          <Button
            size='large'
            variant='outlined'
            color='secondary'
            className={classes.dialogButton}
            type='submit'
          >
            {loadingAfterSubmit ? (
              <Loader type='Oval' color='#055f5b' height='13' width='13' />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ListConfigSettingForm;
