import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import DialogTransition from '../../../components/DialogTransition';
import TeamFilteredSelect from '../../../components/TeamFilteredSelect';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'react-loader-spinner';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  Dialog,
  Typography,
  Button,
  Tooltip,
  IconButton,
  DialogContent
} from '@material-ui/core';
import { AppContext } from '../../../contexts';
import { Formik, Form, FieldArray } from 'formik';

const DialogEditOfficeTeams = ({
  classes,
  office,
  offering,
  fullScreen,
  open,
  setOpen
}) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [validateError, setValidateError] = useState(false);
  const {
    state: { firebase }
  } = useContext(AppContext);

  const onSubmit = async values => {
    if (!office.teams[offering]) {
      const newOfferingTeams = [];
      values.newTeams.forEach(t => {
        newOfferingTeams.push(t.id);
      });
      office.teams[offering] = newOfferingTeams;
    } else {
      values.newTeams.forEach(t => {
        office.teams[offering].push(t.id);
      });
    }
    setLoading('Updating Office Teams; Please Keep Browswer Window Open');
    setError(null);
    try {
      const clientRef = firebase
        .firestore()
        .collection('clients')
        .doc(office.id);
      await clientRef.set({ teams: office.teams }, { merge: true });

      const updateTotalOffices = async team => {
        team.totalOffices++;
        try {
          const teamRef = firebase.firestore().collection('teams').doc(team.id);
          await teamRef.update({ totalOffices: team.totalOffices });
        } catch (e) {
          console.error(e);
        }
      };

      const promises = [];
      values.newTeams.forEach(t => promises.push(updateTotalOffices(t)));
      try {
        await Promise.all(promises);
      } catch (e) {
        console.error(e);
      }

      setLoading(null);
      onClose();
    } catch (e) {
      console.error(e);
      setError('Failed To Update Teams');
    }
  };

  const onClose = () => {
    setOpen(false);
    setValidateError(null);
  };

  const validate = newTeams => {
    const teamIds = [];
    newTeams.map(t => {
      if (office.teams[offering]?.includes(t.id)) {
        setValidateError(`${t.name} is already assigned to this office`);
        newTeams.pop();
      } else {
        teamIds.push(t.id);
      }

      if (teamIds.slice(0, -1).includes(t.id)) {
        setValidateError(`${t.name} is already set to be added`);
        newTeams.pop();
      }
      return validateError;
    });
  };

  const displayTeams = (newTeams, arrayHelpers) => {
    return newTeams.map((t, i) =>
      t.name ? (
        <div key={i} className={classes.teamName}>
          {t.name}
          <IconButton
            color='inherit'
            aria-label={`Remove ${t.name}`}
            className={classes.teamRemovalIcon}
            onClick={() => arrayHelpers.remove(i)}
            component='span'
            disableRipple
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null
    );
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }
    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button color='primary' type='submit' disabled={isSubmitting || loading}>
      Save
    </Button>
  );

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            {`Edit ${offering} Teams`}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{ newTeams: [] }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue, initialValues }) => (
            <Form className={classes.form}>
              <FieldArray
                name='newTeams'
                label='Select Teams'
                render={arrayHelpers => (
                  <>
                    <TeamFilteredSelect
                      label='Team Selection'
                      error={validateError}
                      offering={offering}
                      onChange={team => {
                        setValidateError(false);
                        if (!team) return setValidateError(false);
                        const { newTeams } = values;
                        newTeams.push({
                          name: team.name,
                          id: team.id,
                          totalOffices: team.totalOffices
                        });
                        validate(newTeams);
                        setFieldValue('newTeams', newTeams);
                      }}
                      previouslySelectedTeamId={null}
                    />
                    <div className={classes.dialogTeamsList}>
                      {displayTeams(values.newTeams, arrayHelpers)}
                    </div>
                  </>
                )}
              />
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DialogEditOfficeTeams.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired
};

export default DialogEditOfficeTeams;
