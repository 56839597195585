export const styles = theme => ({
  root: {
    // height: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing.unit
    }
  },
  heading: {
    height: theme.spacing.unit * 5,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    alignItems: 'flex-start'
    // [theme.breakpoints.down('xs')]: {
    //   paddingBottom: theme.spacing.unit
    // },
  },
  headText: {
    color: 'white',
    lineHeight: 1,
    fontSize: '1.55rem'
  },
  headerPadding: {
    height: 150,
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -150,
    padding: '32px',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '1100px'
    }
  },
  section: {
    padding: theme.spacing.unit * 5,
    paddingTop: 0,
    paddingBottom: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  lastSection: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.unit * 30
    }
  },
  sectionTitle: {
    textTransform: 'uppercase'
  },
  overviewSectionTitle: {
    color: '#ffffff'
  },
  sectionBody: {
    marginTop: 20
  },
  sectionSectionBody: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 12
    }
  },
  paperRoot: {
    position: 'relative',
    //     width: '95%',
    padding: 15,
    paddingBottom: 2,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 35,
      width: 'unset'
    }
  },
  teamName: {
    color: '#ffffff',
    fontSize: 8
  },
  statLabel: {
    color: '#ffffff',
    fontSize: 12,
    lineHeight: 1.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  statLabelIcon: {
    fontSize: 12,
    marginRight: 3
  },
  secondSection: {
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing.unit * 3
    }
  }
});
