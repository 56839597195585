import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress
} from '@material-ui/core';

const OverlayLoader = ({ open, files, classes, multiple }) => {
  const renderFiles = () => {
    if (multiple) {
      return files.map((fileName, index) => (
        <Typography key={index}> {fileName} </Typography>
      ));
    } else {
      return files.map((file, index) => (
        <Typography key={index}> {file.name} </Typography>
      ));
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='lg'
      open={open}
      className={'root-dialog'}
    >
      <DialogTitle>Converting Files to CSVs:</DialogTitle>
      <DialogContent className={classes.dialogFileListing}>
        {files.length > 0 && renderFiles()}
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default OverlayLoader;
