import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import theme from '../services/theme';
import toRenderProps from 'recompose/toRenderProps';
import withWidth from '@material-ui/core/withWidth';
import { Area, AreaChart, Tooltip, XAxis } from 'recharts';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

const WithWidth = toRenderProps(withWidth());

const graphWidths = {
  xs: 300,
  sm: 550,
  md: 825,
  lg: 900,
  xl: 900
};

class StatsChart extends Component {
  state = { chartData: [] };

  UNSAFE_componentWillMount() {
    this.setState({ chartData: this.generateBaseChartData() });
  }

  componentDidMount() {
    const { dateRangeStart, dateRangeEnd, stats } = this.props;

    this.setState({
      chartData: _.sortBy(
        this.generateNewChartData(dateRangeStart, dateRangeEnd, stats),
        'date'
      )
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { dateRangeStart, dateRangeEnd, stats } = this.props;

    if (
      !_.isEqual(stats, nextProps.stats) ||
      !_.isEqual(dateRangeStart, nextProps.dateRangeStart) ||
      !_.isEqual(dateRangeEnd, nextProps.dateRangeEnd)
    ) {
      this.setState({
        chartData: _.sortBy(
          this.generateNewChartData(
            nextProps.dateRangeStart,
            nextProps.dateRangeEnd,
            nextProps.stats
          ),
          'date'
        )
      });
    }
  }

  generateNewChartData(startDate, endDate, stats) {
    return _.values(
      _.merge(
        _.keyBy(this.generateBaseChartData(startDate, endDate), 'date'),
        _.keyBy(this.generateDateRangeData(startDate, endDate, stats), 'date')
      )
    );
  }

  generateBaseChartData(startDate, endDate) {
    const {
      dateRangeStart,
      dateRangeEnd,
      dataLabel,
      dateLabelFormat
    } = this.props;
    let chartData = [];
    let start = startDate ? moment(startDate) : moment(dateRangeStart);
    let end = endDate ? moment(endDate) : moment(dateRangeEnd);

    while (start <= end) {
      chartData.push({ date: start.format(dateLabelFormat), [dataLabel]: 0 });

      start.add(1, 'days');
    }

    return chartData;
  }

  generateDateRangeData(start, end, stats) {
    let chartData = [];

    stats.forEach(day => {
      if (
        moment(day.date) >= moment(start) &&
        moment(day.date) <= moment(end)
      ) {
        chartData.push(day);
      }
    });

    return chartData;
  }

  render() {
    const { chartData } = this.state;
    const { dataLabel } = this.props;

    return (
      <WithWidth>
        {({ width }) => (
          <AreaChart
            width={graphWidths[width]}
            height={200}
            data={chartData}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id='colorData' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={1}
                />
                <stop
                  offset='95%'
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey='date'
              minTickGap={20}
              axisLine={false}
              tickSize={3}
              orientation='bottom'
            />
            <Tooltip />
            <Area
              type='monotone'
              dataKey={`${dataLabel}`}
              stroke={theme.palette.secondary.main}
              fillOpacity={1}
              fill='url(#colorData)'
            />
          </AreaChart>
        )}
      </WithWidth>
    );
  }
}

const styles = theme => ({});

const mapStateToProps = (state, props) => {
  const orderedData = state.firestore.ordered;
  const statsData = state.firestore.ordered['statsChartTableData'];
  let teamStatsData = { daily: {}, id: props.collection };
  let stats = [];

  if (props.team) {
    for (let key in orderedData) {
      if (
        key.includes('statsChartTableData-teamMember') &&
        key.split('-')[2] === props.teamId &&
        orderedData[key].length > 0
      ) {
        for (let day in orderedData[key][0]) {
          teamStatsData.daily[day] = teamStatsData.daily[day]
            ? teamStatsData.daily[day] + orderedData[key][0][day]
            : orderedData[key][0][day];
        }
      }
    }
  }

  if ((isLoaded(statsData) && !isEmpty(statsData)) || props.team) {
    if (props.team) {
      delete teamStatsData.daily.id;
    } else {
      delete statsData[0].id;
    }

    let dailyStats = props.team
      ? teamStatsData.daily
      : statsData[0]
      ? statsData[0]
      : statsData[0][props.collection];

    if (dailyStats) {
      for (let day in dailyStats) {
        stats.push({
          date: moment(day).format(props.dateLabelFormat),
          [props.dataLabel]: dailyStats[day]
        });
      }
    }
  }

  return { stats };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    let queries = [];

    if (props.individual && props.collection) {
      if (props.mistakes) {
        queries.push({
          collection: 'statistics',
          doc: 'employees',
          subcollections: [
            {
              collection: props.individualId,
              doc: 'statistics',
              subcollections: [
                {
                  collection: 'mistakes',
                  doc: props.collection,
                  subcollections: [
                    {
                      collection: 'statistics',
                      doc: 'daily'
                    }
                  ]
                }
              ]
            }
          ],
          storeAs: 'statsChartTableData'
        });
      } else {
        queries.push({
          collection: 'statistics',
          doc: 'employees',
          subcollections: [
            {
              collection: props.individualId,
              doc: 'statistics',
              subcollections: [{ collection: props.collection, doc: 'daily' }]
            }
          ],
          storeAs: `statsChartTableData`
        });
      }
    }

    if (props.overall) {
      if (props.mistakes) {
        queries.push({
          collection: 'statistics',
          doc: 'overall',
          subcollections: [
            {
              collection: 'mistakes',
              doc: props.collection,
              subcollections: [
                {
                  collection: 'statistics',
                  doc: 'daily'
                }
              ]
            }
          ],
          storeAs: 'statsChartTableData'
        });
      } else {
        queries.push({
          collection: 'statistics',
          doc: 'overall',
          subcollections: [
            {
              collection: props.collection,
              doc: 'daily'
            }
          ],
          storeAs: 'statsChartTableData'
        });
      }
    }

    if (props.team && props.teamMembers) {
      props.teamMembers.forEach(member => {
        if (props.mistakes) {
          queries.push({
            collection: 'statistics',
            doc: 'employees',
            subcollections: [
              {
                collection: member,
                doc: 'statistics',
                subcollections: [
                  {
                    collection: 'mistakes',
                    doc: props.collection,
                    subcollections: [
                      {
                        collection: 'statistics',
                        doc: 'daily'
                      }
                    ]
                  }
                ]
              }
            ],
            storeAs: `statsChartTableData-teamMember-${props.teamId}-${member}`
          });
        } else {
          queries.push({
            collection: 'statistics',
            doc: 'employees',
            subcollections: [
              {
                collection: member,
                doc: 'statistics',
                subcollections: [{ collection: props.collection, doc: 'daily' }]
              }
            ],
            storeAs: `statsChartTableData-teamMember-${props.teamId}-${member}`
          });
        }
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(StatsChart);
