import _ from 'lodash';
import React, { useContext } from 'react';
import Loading from './Loading';
import helpers from '../helpers';
import PropTypes from 'prop-types';
import sitemap from '../sitemap';
import { AppContext } from '../contexts';
import { ALLOWED_IPs } from '../services/auth';
import { OPEN_IP_GROUPS } from '../services/usergroups';
import { Redirect, Route } from 'react-router-dom';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';

const SecureRoute = ({ appRoot, component, location, path, ...rest }) => {
  const {
    state: { authenticated, auth, clientIpAddress, profile }
  } = useContext(AppContext);
  const Component = component;
  const userLoaded = auth.isLoaded && profile.isLoaded;
  const pathArr = location.pathname.substr(1).split('/');
  let sitemapPath;
  let route;

  if (sitemap[pathArr[0]]) {
    sitemapPath =
      pathArr[0] && sitemap[pathArr[0]].metadata.routesInterpretRouterPath
        ? helpers.getLocationWithParams(path, location)
        : location.pathname;
    const pathKey =
      location.pathname !== '/' &&
      sitemapPath
        .substr(1)
        .replace(/\//g, '.')
        .split('.')
        .splice(0, sitemap[pathArr[0]].metadata.maxDepth)
        .join('.');
    route = location.pathname === '/' ? sitemap.home : _.get(sitemap, pathKey);
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!authenticated && userLoaded) {
          return (
            <Redirect
              to={`/login?redirectUri=${location.pathname}${location.search}`}
            />
          );
        } else if (authenticated && userLoaded) {
          const openIpUser =
            _.intersection(profile.data.groups, OPEN_IP_GROUPS).length > 0;

          if (!openIpUser && !ALLOWED_IPs.includes(clientIpAddress)) {
            return <Redirect to='/error/403' />;
          }

          return appRoot ? (
            <Component {...rest} />
          ) : (
            <AuthorizeRoute
              appRoot={appRoot}
              component={component}
              path={path}
              profile={profile}
              route={route}
              sitemapPath={sitemapPath}
              {...props}
            />
          );
        } else {
          return <Loading {...props} />;
        }
      }}
    />
  );
};

const AuthorizeRoute = ({
  component: Component,
  path,
  profile,
  route,
  sitemapPath,
  ...rest
}) => {
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const readGroups = t(route, 'metadata.permissions.read').safeArray || [];

  if (
    route.metadata.restricted &&
    _.intersection(userGroups, readGroups).length <= 0
  ) {
    return <Redirect to='/' />;
  }

  return route.metadata.parent ? (
    <Component
      tabs={_.get(sitemap, route.metadata.parent)}
      sitemapPath={sitemapPath}
      {...rest}
    />
  ) : (
    <Component sitemapPath={sitemapPath} {...rest} />
  );
};

SecureRoute.propTypes = {
  appRoot: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  location: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
};

AuthorizeRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  path: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  sitemapPath: PropTypes.string.isRequired
};

export default withRouter(SecureRoute);
