/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { t } from 'typy';
import { withStyles } from '@material-ui/core/styles';
import {
  FieldCheckbox,
  FieldDate,
  FieldPhone,
  FieldSelect,
  FieldText,
  FieldTime
} from '../../../../../components/Fields';
import { date, phone, required, time } from '../../../../../validation';
import { AppContext } from '../../../../../contexts';
import ManageEntryFields from '../../components/ManageEntryFields';
import helpers from '../../../../../helpers';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  form: {
    marginTop: 12
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  loadingOptionsContainer: {
    display: 'flex',
    '& > div': {
      marginRight: 8
    }
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const ScheduleItemForm = ({
  classes,
  dialogData,
  dialogOnClose,
  route,
  item,
  employees,
  offices,
  manConnectData,
  mistake
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState('Fetching Form Options');
  const [doNotBillReasons, setDoNotBillReasons] = useState([]);
  const [mistakeReasons, setMistakeReasons] = useState([]);
  const {
    state: { auth, firebase, profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const manageGroups = t(route, 'metadata.permissions.manage').safeArray || [];
  const userAllowedToManage =
    _.intersection(userGroups, manageGroups).length > 0;
  const onSubmit = (values, { setSubmitting }) => {
    if (item && values.createdBy.employeeId) {
      let {
        createdBy: { employeeId, employeeFirstName, employeeLastName }
      } = values;
      let createdByName = `${employeeFirstName} ${employeeLastName}`;
      values.createdBy = employeeId;
      values.createdByName = createdByName;
    }
    setError(null);
    setLoading(`Saving Entry; Please Keep Browser Window Open`);
    helpers
      .transformEntryDataForSave(firebase, auth, values)
      .then(data => data)
      .then(data => helpers.saveEntryData(firebase, data, 'scheduled'))
      .then(() => {
        dialogOnClose(true);
        setError(null);
        setLoading(null);
        setSubmitting(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(null);
        setSubmitting(false);
      });
  };
  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };
  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );
  const selectLabel = (optionsList, selectName, vanityName) => {
    if (optionsList === null) {
      return `Error Fetching ${_.startCase(_.toLower(selectName))}s`;
    }
    if (optionsList.length === 0) {
      return (
        <span className={classes.loadingOptionsContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          Fetching {_.startCase(_.toLower(selectName))}s
        </span>
      );
    }
    return vanityName || _.startCase(_.toLower(selectName));
  };
  const validations = {
    appointmentDate: v => helpers.composeFieldValidation(v, [required, date]),
    appointmentTime: v => helpers.composeFieldValidation(v, [required, time]),
    lastVisitDate: date,
    office: required,
    patientName: required,
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    type: required,
    doNotBillCategory: required,
    mistakeCategory: required,
    createdByName: required
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userAllowedToManage) {
          const doNotBillReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('doNotBill')
            .collection('reasons')
            .orderBy('value')
            .get();
          const mistakeReasonsQuerySnapshot = await firebase
            .firestore()
            .collection('options')
            .doc('mistake')
            .collection('reasons')
            .orderBy('value')
            .get();
          const doNotBillReasonsDocs = [];
          const mistakeReasonsDocs = [];

          doNotBillReasonsQuerySnapshot.forEach(doc => {
            doNotBillReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          mistakeReasonsQuerySnapshot.forEach(doc => {
            mistakeReasonsDocs.push({ ...doc.data(), id: doc.id });
          });

          setDoNotBillReasons(doNotBillReasonsDocs);
          setMistakeReasons(mistakeReasonsDocs);
        }

        const officesQuerySnapshot = await firebase
          .firestore()
          .collection('clients')
          .where('offerings.recall', '==', true)
          .where('status', '==', 'active')
          .get();
        const employeesQuerySnapshot = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        const treatmentTypesQuerySnapshot = await firebase
          .firestore()
          .collection('options')
          .doc('scheduledTreatment')
          .collection('types')
          .orderBy('name')
          .get();
        const employeeDocs = [];
        const officeDocs = [];
        const typesDocs = [];

        employeesQuerySnapshot.forEach(doc => {
          employeeDocs.push({ ...doc.data(), id: doc.id });
        });

        officesQuerySnapshot.forEach(doc => {
          officeDocs.push({ ...doc.data(), id: doc.id });
        });

        treatmentTypesQuerySnapshot.forEach(doc => {
          typesDocs.push({ ...doc.data(), id: doc.id });
        });

        setLoading(null);
      } catch (err) {
        setDoNotBillReasons(null);
        setMistakeReasons(null);
        setLoading(null);
        setError(err.toString());
      }
    };
    fetchData();
  }, [firebase, userAllowedToManage]);

  const initialValues = dialogData
    ? dialogData
    : manConnectData
    ? manConnectData
    : {};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form className={classes.form}>
            {dialogData && userAllowedToManage ? (
              <>
                <ManageEntryFields
                  doNotBillReasons={doNotBillReasons}
                  loading={loading}
                  mistakeReasons={mistakeReasons}
                  selectLabel={selectLabel}
                  validations={validations}
                  values={values}
                />
              </>
            ) : null}
            <Field
              name='patientName'
              label='Patient Name'
              variant='outlined'
              component={FieldText}
              disabled={true}
              validate={validations.patientName}
            />
            <div className={classes.row}>
              <Field
                name='patientPhone'
                label='Patient Phone'
                variant='outlined'
                component={FieldPhone}
                disabled={true}
                validate={validations.patientPhone}
              />
              <FieldCheckbox
                checked={!!values.newPatient}
                name='newPatient'
                label='New Patient'
                disabled={true}
              />
            </div>
            {!values.newPatient ? (
              <Field
                name='lastVisitDate'
                label='Last Visit Date'
                variant='outlined'
                placeholder='mm/dd/yyyy'
                component={FieldDate}
                disabled={true}
                validate={validations.lastVisitDate}
              />
            ) : null}
            <Field
              name='office'
              loading={offices.length === 0}
              disabled={true}
              label={selectLabel(offices, 'office')}
              options={_.sortBy(offices, 'officeInformation.name')}
              optionKey='id'
              optionText='officeInformation.name'
              optionValue='id'
              margin='none'
              component={FieldSelect}
              validate={validations.office}
            />
            <div className={classes.row}>
              <Field
                name='appointmentDate'
                label='Appointment Date'
                variant='outlined'
                placeholder='mm/dd/yyyy'
                component={FieldDate}
                disabled={true}
                validate={validations.appointmentDate}
              />
              <Field
                name='appointmentTime'
                label='Appointment Time'
                variant='outlined'
                placeholder='hh:mm'
                component={FieldTime}
                disabled={true}
                validate={validations.appointmentTime}
              />
            </div>
            <Field
              name='notes'
              label='Notes'
              variant='outlined'
              multiline
              rowsMax={4}
              disabled={true}
              component={FieldText}
            />
            {item ? (
              <Field
                name='createdByName'
                label='Created by Employee'
                variant='outlined'
                component={FieldText}
                validate={validations.createdByName}
                disabled={true}
              />
            ) : null}
            <div className={classes.actions}>
              {messageArea()}
              <div className={classes.actionButtons}>
                {renderActionButtons(isSubmitting)}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

ScheduleItemForm.propTypes = {
  dialogData: PropTypes.object,
  dialogOnClose: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object,
  item: PropTypes.object,
  employees: PropTypes.array,
  offices: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(ScheduleItemForm);
