import React from 'react';
import PropTypes from 'prop-types';
import SectionOverviewGraph from './components/SectionOverviewGraph';
import SectionOverview from './components/SectionOverview';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const Overview = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.heading}>
      <Typography variant='h6' className={classes.headText}>
        Overview
      </Typography>
    </div>
    <div className={classes.headerPadding} />
    <div className={classes.body}>
      <div className={classes.section}>
        <SectionOverviewGraph classes={classes} />
      </div>
      <div className={`${classes.section} ${classes.lastSection}`}>
        <SectionOverview classes={classes} />
      </div>
    </div>
  </div>
);

Overview.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Overview);
