import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import PhoneTextMask from './PhoneTextMask';

const CustomTextField = field => {
  const {
    autoComplete,
    className,
    disabled,
    input,
    meta,
    label,
    shrink,
    step,
    type
  } = field;

  const nativeInputProps = {
    inputProps: step ? { step } : {}
  };
  const inputLocalName = input.name.split('.')[
    input.name.split('.').length - 1
  ];

  return (
    <TextField
      label={label}
      className={className}
      margin='normal'
      type={type}
      autoComplete={autoComplete}
      variant='outlined'
      disabled={disabled}
      error={!!(meta && meta.touched && meta.error)}
      helperText={meta && meta.touched && meta.error}
      InputProps={
        input && input.name === 'phone'
          ? { inputComponent: PhoneTextMask }
          : input &&
            type === 'number' &&
            (inputLocalName.toLowerCase().includes('amount') ||
              inputLocalName.toLowerCase().includes('value') ||
              inputLocalName.toLowerCase().includes('price'))
          ? {
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              )
            }
          : {}
      }
      InputLabelProps={{ shrink }}
      {...nativeInputProps}
      {...input}
    />
  );
};

export default CustomTextField;
