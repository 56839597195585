import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const OfferingLabel = ({ classes, offering }) => (
  <Typography variant='body1'>{offering.name}</Typography>
);

const styles = theme => ({});

OfferingLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  offering: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(OfferingLabel);
