import React, { useState, useEffect } from 'react';
import AddUserIcon from '@material-ui/icons/AddCircleOutline';
import RemoveUserIcon from '@material-ui/icons/RemoveCircleOutline';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import Select from 'react-select';
import { TextField } from '@material-ui/core';

const AddInsurance = ({
  form: {
    values: { insurances }
  },
  classes,
  valuesPush,
  valuesRemove,
  valuesReplace,
  statusOptions
}) => {
  const [insuranceTextField] = useState({
    name: '',
    status: statusOptions[0],
    notes: ''
  });

  useEffect(() => {
    if (Array.isArray(insurances) && !insurances.length) {
      valuesPush(insuranceTextField);
    }
  }, [insuranceTextField, insurances, valuesPush]);

  const addInsurance = () => {
    valuesPush(insuranceTextField);
  };

  const removeInsurance = index => {
    valuesRemove(index);
  };

  const handleChange = (e, index) => {
    const {
      target: { value, name }
    } = e;
    const insuranceCopy = insurances[index];
    insuranceCopy[name] = value;
    valuesReplace(index, insuranceCopy);
  };

  const changeStatus = (option, index) => {
    const insuranceCopy = { ...insurances[index] };
    if (
      insuranceCopy.status.value === 'conditional' &&
      option.value !== 'conditional'
    ) {
      insuranceCopy.notes = '';
    }
    insuranceCopy.status = option;
    valuesReplace(index, insuranceCopy);
  };

  const renderAddInsuranceButton = () => {
    return (
      <Tooltip title='Add Insurance'>
        <IconButton
          variant='contained'
          color='secondary'
          aria-label='Add Insurance'
          onClick={() => addInsurance()}
        >
          <AddUserIcon className={classes.addIcon} />
        </IconButton>
      </Tooltip>
    );
  };

  const customStyles = {
    container: provided => ({
      ...provided,
      width: '100%'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      marginTop: '20px'
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '56px',
      width: '100%',
      boxShadow: state.isFocused ? null : null
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0 6px'
    }),
    input: provided => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '30px'
    }),
    menu: provided => ({
      ...provided,
      width: '300px',
      minHeight: '300px',
      height: '300px',
      maxHeight: '300px'
    })
  };

  return (
    <>
      {insurances.map((insuranceField, index) => {
        return (
          <span key={index} style={{ display: 'block', width: '100%' }}>
            <div
              className={classes.subSectionTitle}
              style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant='caption'>Insurance #{index + 1}</Typography>
              <Tooltip title='Remove Insurance'>
                <IconButton
                  variant='contained'
                  color='secondary'
                  aria-label='Remove Insurance'
                  style={{ padding: 0, marginLeft: 8 }}
                  onClick={() => removeInsurance(index)}
                >
                  <RemoveUserIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.insurancesRow}>
              <TextField
                name='name'
                value={insuranceField.name}
                label='Name'
                color='secondary'
                variant='outlined'
                onChange={e => handleChange(e, index)}
              />
              <Select
                key={index}
                value={insuranceField.status}
                label='Status'
                variant='outlined'
                options={statusOptions}
                onChange={e => changeStatus(e, index)}
                styles={customStyles}
              />
              {insuranceField.status.value === 'conditional' ? (
                <TextField
                  name='notes'
                  value={insuranceField.notes}
                  label='Notes'
                  variant='outlined'
                  onChange={e => handleChange(e, index)}
                />
              ) : null}
            </div>
          </span>
        );
      })}

      {renderAddInsuranceButton()}
    </>
  );
};

export default AddInsurance;
