export const appBarHeight = 64;
export const appBarHeightSm = 56;
export const headingHeight = 40;
export const headingHeightLg = 64;

export const styles = theme => ({
  root: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 8
    }
  },
  tabBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    position: 'fixed',
    top: appBarHeightSm + headingHeight,
    width: '100%',
    '& button': {
      minWidth: 'unset',
      minHeight: 24,
      marginRight: 24,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      '& > span > span': {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 8,
        paddingTop: 8,
        '& > span': {
          textTransform: 'none'
        }
      }
    },
    '& button:first-child': {
      marginLeft: 24
    }
  },
  tabBarLarge: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    position: 'fixed',
    top: appBarHeight + headingHeightLg,
    [theme.breakpoints.down('sm')]: {
      top: appBarHeightSm + headingHeightLg
    },
    width: '100%',
    '& button': {
      minWidth: 'unset',
      minHeight: 24,
      marginRight: 24,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      '& > span > span': {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 8,
        paddingTop: 8,
        '& > span': {
          textTransform: 'none'
        }
      }
    },
    '& button:first-child': {
      marginLeft: 24
    }
  },
  tabsRoot: {
    minHeight: 'unset'
  },
  headerTab: {
    '&:focus': {
      outline: 'none',
      backgroundColor: '#898989'
    }
  },
  indicator: {
    backgroundColor: '#ffffff',
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2
  },
  headerPadding: {
    height: 0,
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  body: {
    marginTop: headingHeight + 26,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 40,
    marginBottom: 200,
    [theme.breakpoints.down('xs')]: {
      marginTop: headingHeight + 48,
      paddingTop: 2
    }
  },
  bodyAppBarLarge: {
    marginTop: headingHeightLg + 26,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 40,
    marginBottom: 200,
    [theme.breakpoints.down('xs')]: {
      marginTop: headingHeightLg + 48,
      paddingTop: 2
    }
  }
});
