import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Column from './Column';
import { capitalizeFirstLetter } from '../../../../../services/helpers';

const Credits = ({ classes, data: { credits, creditAmounts } }) => (
  <div>
    {credits.map(credit => (
      <Grid container spacing={24} key={credit.id} className={classes.row}>
        <Column className={classes.creditTitle} justify='flex-start'>
          <Typography variant='body1'>{credit.title}</Typography>
          <Typography variant='caption'>
            {capitalizeFirstLetter(credit.type)} Credit
          </Typography>
        </Column>
        <Column justify='center'>
          <Typography
            variant='caption'
            style={{ fontSize: 12, textAlign: 'center' }}
          >
            Value: ${credit.value.toFixed(2)}, Used: $
            {creditAmounts[credit.id].toFixed(2)}, Remaining: $
            {(credit.value - creditAmounts[credit.id]).toFixed(2)}
          </Typography>
        </Column>
        <Column justify='flex-end'>
          ($
          {creditAmounts[credit.id].toFixed(2)})
        </Column>
      </Grid>
    ))}
  </div>
);

const styles = theme => ({
  row: {
    marginBottom: 12
  },
  creditTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default withStyles(styles, { withTheme: true })(Credits);
