import React, { Component } from 'react';
import axios from 'axios';
import Charges from '../Charges';
import Credits from '../Credits';
import icons from '../../../../../../services/icons';
import InvoiceActions from '../InvoiceActions';
import moment from 'moment-timezone';
import qs from 'qs';
import Totals from '../Totals';
import { CALLFORCE_TZ } from '../../../../../../services/constants';
import { capitalizeFirstLetter } from '../../../../../../services/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { dialogBillingStatusChange } from '../../../../../../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { Typography, withStyles } from '@material-ui/core';
import { styles } from './styles';

class InvoiceItemForm extends Component {
  state = {
    actionError: '',
    changingStatus: false,
    regeneratingInvoice: false,
    status: ''
  };

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (data && data.status) this.setState({ status: data.status });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { data } = this.props;
    const statusUpdate =
      (!data && nextProps.data && nextProps.data.status) ||
      (data && nextProps.data && data.status !== nextProps.data.status);

    if (statusUpdate) {
      this.setState({ status: nextProps.data.status });
    }
  }

  onStatusChange = async status => {
    const { data, firestore, history, location } = this.props;

    try {
      this.setState({ actionError: '', changingStatus: true });
      await firestore.update(
        { collection: 'invoices', doc: data.id },
        { status }
      );
      let path = `${location.pathname}${location.search}`;
      let querySeparator = path.indexOf('?') > -1 ? '&' : '?';

      this.setState({ status, changingStatus: false }, () =>
        history.push(`${path}${querySeparator}refreshData=true`)
      );
    } catch (e) {
      this.setState({ actionError: e.toString(), changingStatus: false });
    }
  };

  regenerateInvoice = async () => {
    const { data, dialogOnClose } = this.props;
    const baseUrl = process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL;
    const url = `${baseUrl}/invoices-recreateInvoice`;
    const invoiceBody = {
      clientId: data.client,
      invoiceId: data.id,
      startDate: moment.unix(data.dateStart.seconds).toISOString(),
      endDate: moment.unix(data.dateEnd.seconds).toISOString()
    };
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    try {
      this.setState({ actionError: '', regeneratingInvoice: true });
      await axios.post(url, qs.stringify(invoiceBody), config);
      dialogOnClose(true);
      this.setState({ regeneratingInvoice: false });
    } catch (e) {
      this.setState({
        actionError: e.response.data.data,
        regeneratingInvoice: false
      });
    }
  };

  render() {
    const { classes, data } = this.props;
    const {
      actionError,
      changingStatus,
      regeneratingInvoice,
      status
    } = this.state;

    if (data) {
      return (
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant='body1' className={classes.clientName}>
              {data.clientName}
            </Typography>
            <Typography variant='body1' className={classes.dateRange}>
              {`
                ${moment
                  .unix(data.dateStart.seconds)
                  .tz(CALLFORCE_TZ)
                  .format('MMMM D, YYYY')} - 
                ${moment
                  .unix(data.dateEnd.seconds)
                  .tz(CALLFORCE_TZ)
                  .format('MMMM D, YYYY')}
              `}
            </Typography>
            <div className={classes.statusContainer}>
              <Typography variant='body1' className={classes.statusText}>
                {status && capitalizeFirstLetter(status)}
              </Typography>
              {status && icons[status](14)}
            </div>
          </div>
          <div className={classes.body}>
            <Charges data={data} />
            <Credits data={data} />
            <Totals data={data} />
            <InvoiceActions
              actionError={actionError}
              changingStatus={changingStatus}
              onStatusChange={this.onStatusChange}
              regeneratingInvoice={regeneratingInvoice}
              regenerateInvoice={this.regenerateInvoice}
              status={status}
            />
          </div>
          <div className={classes.footer}>
            <Typography variant='caption'>
              {capitalizeFirstLetter(data.createdType)} Invoice
            </Typography>
            <Typography variant='caption'>
              Created{' '}
              {moment
                .unix(
                  data.createdType === 'regenerated'
                    ? data.regeneratedTime.seconds
                    : data.createdTime.seconds
                )
                .tz(CALLFORCE_TZ)
                .format('MMM D, YYYY, hh:mm a')}
            </Typography>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default compose(
  connect(null, { dialogBillingStatusChange }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(InvoiceItemForm);
