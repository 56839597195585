import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTransition from '../../../../../../components/DialogTransition';
import ClientGroupInformation from './components/ClientGroupInformation';
import ClientGroupOffices from './components/ClientGroupOffices';
import ClientGroupReview from './components/ClientGroupReview';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { required } from '../../../../../../validation';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  MobileStepper,
  Stepper,
  Step,
  StepLabel,
  withStyles
} from '@material-ui/core';
import { styles } from './styles';
import { Formik, Form, FieldArray, Field } from 'formik';

const DialogAddClientGroup = ({
  firestore,
  history,
  location,
  cancel,
  clientGroups,
  offices,
  classes,
  onClose,
  openDialog
}) => {
  const [page, setPage] = useState(0);
  const steps = ['Info', 'offices', 'Review'];

  const onSubmit = async values => {
    let offices = [];
    values.offices.forEach(office => {
      offices.push(office.value);
    });
    const currentTime = firestore.Timestamp.now().toDate();
    try {
      await firestore.add(
        { collection: 'clientGroups' },
        {
          name: values.clientGroupName,
          offices: offices,
          createdTime: currentTime,
          status: 'active'
        }
      );
      history.push('clientGroups/?refreshData=true');
      setPage(0);
    } catch (e) {
      console.error(e);
    }
  };

  const updatePage = updatePageNum => {
    const nextPage = page + updatePageNum;
    setPage(nextPage);
  };

  const nextPage = values => updatePage(1, values);

  const previousPage = values => updatePage(-1, values);

  const handleReset = () => {
    onClose();
  };

  const validations = {
    name: required,
    offices: required
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={true}
      open={openDialog}
      onClose={onClose}
      onEscapeKeyDown={onClose}
      aria-labelledby='responsive-dialog-title'
      disableBackdropClick
    >
      <div className={classes.dialogBody}>
        <div className={classes.stepperContainer}>
          <Stepper activeStep={page}>
            {steps.map(label => {
              const props = {};
              const labelProps = {};

              return (
                <Step key={label} {...props}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className={classes.mobileStepperContainer}>
          <MobileStepper
            variant='dots'
            steps={steps.length}
            position='static'
            activeStep={page}
            className={classes.mobileStepper}
            nextButton={null}
            backButton={null}
          />
        </div>
        <DialogTitle id='responsive-dialog-title'>
          <span className={classes.dialogTitle}>Add Client Group</span>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              clientGroupName: '',
              offices: []
            }}
            onSubmit={onSubmit}
            onReset={handleReset}
          >
            {({ values, errors, touched }) => (
              <Form>
                {page === 0 && (
                  <ClientGroupInformation
                    values={values}
                    location={location}
                    nextPage={values => nextPage(values)}
                    classes={classes}
                    Field={Field}
                    name='clientGroupName'
                    cancel={cancel}
                    errors={errors}
                    touched={touched}
                    validations={validations}
                  />
                )}
                {page === 1 && (
                  <ClientGroupOffices
                    values={values}
                    location={location}
                    previousPage={values => previousPage(values)}
                    nextPage={values => nextPage(values)}
                    FieldArray={FieldArray}
                    Field={Field}
                    classes={classes}
                    name='offices'
                    allOffices={offices}
                    cancel={cancel}
                    errors={errors}
                    touched={touched}
                    validations={validations}
                  />
                )}
                {page === 2 && (
                  <ClientGroupReview
                    values={values}
                    location={location}
                    previousPage={values => previousPage(values)}
                    onSubmit={values => onSubmit(values)}
                    updatePage={(updatePageNum, values) => {
                      updatePage(updatePageNum, values);
                    }}
                    Field={Field}
                    pageNum={2}
                    classes={classes}
                    clientGroups={clientGroups}
                    errors={errors}
                    allOffices={offices}
                    validations={validations}
                  />
                )}
              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

DialogAddClientGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  firestore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  firestoreConnect()
)(DialogAddClientGroup);
