import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { db } from '../../index';
import WizardOverlay from './scenes/components/Overlay';

const Reports = props => {
  const [clientGroups, setClientGroups] = useState([]);
  const [offices, setOffices] = useState([]);
  const [open, setOpen] = useState(true);

  const cancel = () => {
    setOpen(!open);
    props.history.push('/dashboard/');
    handleBlur();
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    });
  };

  useEffect(() => {
    const getClientGroups = async () => {
      const clientGroupsActiveInactivePaused = [];
      const officesActiveInactivePaused = [];
      try {
        const clientGroups = await db.collection('clientGroups').get();

        const offices = await db.collection('clients').get();

        clientGroups.docs.forEach(doc => {
          clientGroupsActiveInactivePaused.push({ ...doc.data(), id: doc.id });
        });

        offices.docs.forEach(doc => {
          officesActiveInactivePaused.push({ ...doc.data(), id: doc.id });
        });

        setClientGroups(clientGroupsActiveInactivePaused);
        setOffices(officesActiveInactivePaused);
      } catch (e) {
        console.error(e);
      }
    };

    if (!clientGroups.length) {
      getClientGroups();
    }
  }, [clientGroups, offices]);

  return (
    <WizardOverlay
      {...props}
      params={props.match.params}
      clientGroups={clientGroups}
      offices={offices}
      open={open}
      cancel={cancel}
    />
  );
};

export default withRouter(Reports);
