import _ from 'lodash';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import OfferingTimeActiveItem from './OfferingTimeActiveItem';

class OfferingTimeActive extends Component {
  renderOfferingTimeActiveItems() {
    const { offeringTimeActive, offering, office } = this.props;
    const itemsOrdered = _.orderBy(offeringTimeActive, 'startDate', 'desc');

    return itemsOrdered.map((item, index) =>
      item.startDate && item.pricing ? (
        <OfferingTimeActiveItem
          key={index}
          item={item}
          offering={offering}
          office={office}
        />
      ) : null
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.timeActiveItemContainer}>
          {this.renderOfferingTimeActiveItems()}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  timeActiveItemContainer: {
    '& > div': {
      marginTop: 24
    },
    '& > div:first-child': {
      marginTop: 0
    }
  }
});

export default withStyles(styles, { withTheme: true })(OfferingTimeActive);
