import _ from 'lodash';
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import SelectField from '../../../components/SelectField';
import { Button, Typography, withStyles } from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../services/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

class ChangeFive9SkillForm extends Component {
  state = { formError: null, loadingAfterSubmit: false, loadingText: null };

  onSubmit = async values => {
    const {
      firestore,
      item: {
        key,
        data: { id: clientId }
      }
    } = this.props;
    let newSkillId = values.newAvailableSkill;
    let clientRef = { collection: 'clients', doc: clientId };
    this.setState({
      formError: null,
      loadingAfterSubmit: true,
      loadingText: "Updating office's skill in CallForce system"
    });

    try {
      await firestore.update(clientRef, {
        [`five9.skills.${key}`]: newSkillId
      });

      this.setState({
        formError: null,
        loadingAfterSubmit: false,
        loadingText: null
      });
      this.props.onClose();
    } catch (e) {
      console.error(e);
      let formError = "Error: Office's skill not updated in CallForce system.";
      this.setState({
        formError,
        loadingAfterSubmit: false,
        loadingText: null
      });
    }
  };

  renderLoadingText = () => {
    const { classes } = this.props;
    const { loadingText } = this.state;

    if (loadingText) {
      return (
        <Typography variant='body1' className={classes.dialogLoadingText}>
          {loadingText}
        </Typography>
      );
    }

    return null;
  };

  renderFormError = () => {
    const { classes } = this.props;
    const { formError } = this.state;

    if (formError) {
      return (
        <Typography variant='body1' className={classes.dialogFormErrorText}>
          {formError}
        </Typography>
      );
    }

    return null;
  };

  renderSubmitButtonText = () => {
    const { loadingAfterSubmit } = this.state;

    if (loadingAfterSubmit) {
      return <Loader type='Oval' color='#055f5b' height='13' width='13' />;
    }

    return 'Save Skill Change';
  };

  render() {
    const {
      availableSkills,
      classes,
      handleSubmit,
      item,
      newAvailableSkillValue,
      submitting
    } = this.props;
    const { formError, loadingText } = this.state;

    return (
      <form className={classes.dialogForm} noValidate autoComplete='off'>
        <Field
          name='newAvailableSkill'
          component={SelectField}
          selected={newAvailableSkillValue === null ? false : true}
          label={`Available ${item &&
            item.key &&
            capitalizeFirstLetter(item && item.key)} Skills`}
          type='text'
          options={_.sortBy(availableSkills, 'name')}
          optionKey='id'
          optionText={o => `${o.name} (${o.id})`}
          optionTextDelimiter=' - '
          optionValue='id'
          margin='none'
          textFieldContainerClass={classes.dialogTextField}
          validate={[validations.required]}
        />
        <div
          className={
            formError || loadingText
              ? classes.dialogButtonContainerWithText
              : classes.dialogButtonContainer
          }
          style={{ marginBottom: 24 }}
        >
          {this.renderFormError()}
          {this.renderLoadingText()}
          <Button
            size='large'
            variant='outlined'
            color='secondary'
            className={classes.dialogButton}
            type='submit'
            onClick={handleSubmit(this.onSubmit)}
            disabled={submitting ? true : false}
          >
            {this.renderSubmitButtonText()}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('DialogEditOfficeChangeFive9Skill');

  return {
    newAvailableSkillValue: selector(state, 'newAvailableSkill')
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'DialogEditOfficeChangeFive9Skill',
    enableReinitialize: true
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(ChangeFive9SkillForm);
