import React, { useState, useContext, useEffect } from 'react';
import DialogTransition from '../../../../../components/DialogTransition';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'react-loader-spinner';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog
} from '@material-ui/core';
import { FieldText, FieldSelect } from '../../../../../components/Fields';
import { AppContext } from '../../../../../contexts';
import { Formik, Form, Field } from 'formik';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { validations } from '../../../../../services/validation';
import { styles } from './styles';

const DialogEditReloContact = ({
  classes,
  fullScreen,
  miscOpen,
  relo,
  item,
  onClose
}) => {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const {
    state: { firebase }
  } = useContext(AppContext);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const subcategoriesSnapshot = await firebase
          .firestore()
          .collection('offeringsCategories')
          .doc(relo.contacts[item.contactId].reason.category)
          .collection('subcategories')
          .where('offerings', 'array-contains', 'answering')
          .get();

        const subcategoriesDocs = [];
        subcategoriesSnapshot.forEach(doc => {
          subcategoriesDocs.push({ ...doc.data(), id: doc.id });
        });

        setSubcategories(subcategoriesDocs);
        setLoading(null);
      } catch (e) {
        console.error(e);
        setSubcategories(null);
        setLoading(null);
        setError('Failed To Fetch Subcategories');
      }
    };

    if (item?.key === 'subcategory') {
      setLoading('Fetching Subcategories');
      fetchSubcategories();
    }
  }, [firebase, relo, item]);

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader type='Oval' color='#696969' height='12' width='12' />
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const renderActionButtons = isSubmitting => (
    <Button
      color='primary'
      type='submit'
      disabled={isSubmitting || loading !== null}
    >
      Save
    </Button>
  );

  const onSubmit = async values => {
    const contacts = relo.contacts;
    if (values.reason) {
      const data = values.reason.subcategory;
      contacts[item.contactId].reason.subcategory = data;
    } else {
      const data = values[item.key].trim();
      contacts[item.contactId][item.key] = data;
    }
    const reloRef = firebase.firestore().collection('relos').doc(relo.id);

    setError(null);
    setLoading('Saving Entry; Please Keep Browser Window Open');
    try {
      await reloRef.set({ contacts }, { merge: true });
      onClose();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setError('Failed To Update Subcategory');
      setLoading(null);
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={miscOpen}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {item && item.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton
            color='inherit'
            aria-label='Close'
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              {item.key === 'subcategory' ? (
                <Field
                  name='reason.subcategory'
                  validate={validations.required}
                >
                  {({ form, field }) => {
                    return (
                      <FieldSelect
                        margin='none'
                        form={form}
                        field={field}
                        loading={subcategories.length === 0}
                        disabled={
                          subcategories.length === 0 || loading !== null
                        }
                        label='Subcategory'
                        options={subcategories}
                        optionKey='id'
                        optionText='category'
                        optionValue='id'
                        component={FieldSelect}
                      />
                    );
                  }}
                </Field>
              ) : (
                <Field name={item.key}>
                  {({ form, field }) => {
                    return (
                      <FieldText
                        form={form}
                        field={field}
                        label={item.label}
                        variant='outlined'
                        type='text'
                        disabled={loading !== null}
                        validate={validations[item.key]}
                      />
                    );
                  }}
                </Field>
              )}
              <div className={classes.actions}>
                {messageArea()}
                <div className={classes.actionButtons}>
                  {renderActionButtons(isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(styles, { withTheme: true }),
  firestoreConnect(),
  withMobileDialog()
)(DialogEditReloContact);
