/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import compose from 'compose-func';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DialogTransition from '../../../../../components/DialogTransition';

export const styles = theme => ({
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    minHeight: 48,
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  dialogSubtitle: {
    position: 'absolute',
    fontSize: 10,
    bottom: 2,
    left: 0,
    color: 'white'
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: 24
  }
});

const DialogAddCallAnswered = ({
  classes,
  dialogBody,
  dialogOnClose,
  dialogOpen,
  fullScreen,
  item,
  title
}) => {
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={dialogOpen}
      onClose={() => dialogOnClose(false)}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            {title}
          </Typography>
          <Typography variant='caption' className={classes.dialogSubtitle}>
            {item && item.id}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton
            color='inherit'
            aria-label='Close'
            onClick={() => dialogOnClose(false)}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        {dialogBody()}
      </DialogContent>
    </Dialog>
  );
};

DialogAddCallAnswered.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogBody: PropTypes.func.isRequired,
  dialogOnClose: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  item: PropTypes.object,
  title: PropTypes.string.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter
)(DialogAddCallAnswered);
