import { INITIALIZED_CLIENTS } from '../actions/types';

const initializedClients = (state = false, action) => {
  switch (action.type) {
    case INITIALIZED_CLIENTS:
      return action.payload;
    default:
      return state;
  }
};

export const StatusReducer = {
  initializedClients
};
