export const styles = theme => ({
  // dialogBody: {
  //   maxWidth: '1000px',
  //   margin: '0 auto',
  //   marginBottom: '20px',
  //   overflowY: 'scroll',
  //   '-webkit-overflow-scrolling': 'touch',
  //   '&::-webkit-scrollbar': {
  //     display: 'none'
  //   },
  //   width: '100%'
  // },
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    minHeight: 48,
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  dialogSubtitle: {
    position: 'absolute',
    fontSize: 10,
    bottom: 2,
    left: 0,
    color: 'white'
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: 24
  },
  teamMembersNames: {
    width: '100%',
    color: '#212121',
    margin: 6,
    padding: 4,
    '& svg': { fontSize: 12, marginRight: 0 },
    [theme.breakpoints.down('sm')]: { width: '70%' }
  },
  dialogAddTeamForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
    marginBottom: 12
  },
  dialogButton: {
    margin: 5
  },
  dialogEmployeeList: {
    height: 150,
    width: '100%',
    overflowY: 'scroll',
    overflow: 'hidden',
    border: 'solid #cbcbcb 1px',
    borderRadius: '8px'
  },
  teamMemberRemovalIcon: {
    padding: 0,
    marginLeft: 10,
    fontSize: 14,
    '& svg': {
      fontSize: '20px !important',
      height: 20
    },
    '&:focus': {
      backgroundColor: '#ddd',
      height: 20
    }
  }
});
