const setStatusObject = status => {
  switch (status.toLowerCase()) {
    case 'loading':
      return { hasError: false, isLoading: true, isReady: false };
    case 'ready':
      return { hasError: false, isLoading: false, isReady: true };
    case 'error':
      return { hasError: true, isLoading: false, isReady: false };
    default:
      return { hasError: false, isLoading: true, isReady: false };
  }
};

export default setStatusObject;
