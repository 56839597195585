import React from 'react';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { IconButton, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const ReloName = ({ classes, history, relo, pathname }) => {
  const onClick = () =>
    pathname ? history.push(pathname) : history.push('/data/relos');

  return (
    <div>
      <div className={classes.reloNameHeading}>
        <Typography variant='h6' className={classes.reloBreadcrumb}>
          RELOs
        </Typography>
        <Typography variant='h6' className={classes.reloNameHeadText}>
          <Tooltip title='Back to RELOs'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Back to RELOs'
              onClick={onClick}
              className={classes.reloNameBackIcon}
            >
              <BackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.reloNameText}>
            {relo && relo.patientName ? relo.patientName : ''}
          </div>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const reloData = state.firestore.ordered.reloDetailInfo;
  const relo =
    isLoaded(reloData) && !isEmpty(reloData) && reloData[0].id === props.reloId
      ? reloData[0]
      : null;
  return { relo };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'relos',
      doc: props.reloId,
      storeAs: 'reloDetailInfo'
    }
  ]),
  withStyles(styles, { withTheme: true }),
  withRouter
)(ReloName);
