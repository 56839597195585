import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  ButtonBase,
  Typography,
  InputBase,
  TablePagination
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import AscIcon from '@material-ui/icons/ArrowDownward';
import DescIcon from '@material-ui/icons/ArrowUpward';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../scenes/Lists/styles';
import Loader from 'react-loader-spinner';
import moment from 'moment';

const ListsTable = ({
  classes,
  updatedOffices,
  loading,
  contactsRetrieved,
  rowsCount,
  setRowsCount
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderByValue, setOrderByValue] = useState([
    'lastContact.seconds',
    'apptsScheduled'
  ]);
  const [listOffices, setListOffices] = useState([]);
  const [orderByOrder, setOrderByOrder] = useState(['asc', 'asc']);
  const [searchTerm, setSearchTerm] = useState(null);
  const rowsPerPageOptions = [10, 25, 50, 100];
  const startIndex = rowsPerPage * page;
  const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    const currentOffices = [];
    if (searchTerm) {
      const searchResults = [];
      updatedOffices.forEach(o => {
        if (
          o.officeInformation.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          searchResults.push(o);
        }
      });
      _.orderBy(searchResults, orderByValue, orderByOrder).forEach((o, i) => {
        if (i >= startIndex && i < endIndex) {
          const office = { ...o, index: i + 1 };
          currentOffices.push(office);
          setListOffices(currentOffices);
          setRowsCount(searchResults.length);
        }
      });
    } else {
      _.orderBy(updatedOffices, orderByValue, orderByOrder).forEach((o, i) => {
        if (i >= startIndex && i < endIndex) {
          const office = { ...o, index: i + 1 };
          currentOffices.push(office);
          if (currentOffices.length === rowsPerPage) {
            setListOffices(currentOffices);
          } else if (currentOffices.length === rowsCount - startIndex) {
            setListOffices(currentOffices);
          }
        }
        setRowsCount(updatedOffices.length);
      });
    }
  }, [
    updatedOffices,
    rowsPerPage,
    page,
    searchTerm,
    orderByOrder,
    endIndex,
    orderByValue,
    rowsCount,
    setRowsCount,
    startIndex
  ]);

  const renderSortIcon = key => {
    if (orderByValue[0].includes(key)) {
      if (orderByOrder[0] === 'desc') {
        return <DescIcon className={classes.sortIcon} />;
      } else {
        return <AscIcon className={classes.sortIcon} />;
      }
    }
    return null;
  };

  const getOrderArr = (key, firstDefaultOrder) => {
    return orderByValue[0].includes(key)
      ? [`${orderByOrder[0] === 'desc' ? 'asc' : 'desc'}`, 'asc']
      : [firstDefaultOrder, 'asc'];
  };

  const changeSort = key => {
    setPage(0);
    switch (key) {
      case 'officeInformation.name':
        setOrderByValue(['officeInformation.name', 'lastContact.seconds']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'patientsContacted':
        setOrderByValue(['patientsContacted', 'lastContact.seconds']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'lastContact.seconds':
        setOrderByValue(['lastContact.seconds', 'apptsScheduled']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      case 'apptsScheduled':
        setOrderByValue(['apptsScheduled', 'lastContact.seconds']);
        setOrderByOrder(getOrderArr(key, 'asc'));
        break;
      default:
        setOrderByValue(['lastContact.seconds', 'apptsScheduled']);
        setOrderByOrder(['asc', 'asc']);
    }
  };

  const renderTableHeaderRow = () => (
    <Grid container spacing={24} className={classes.headerRow}>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('officeInformation.name')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Office Name
        </Typography>
        {renderSortIcon('officeInformation.name')}
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('lastContact.seconds')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Last Contact Date
        </Typography>
        {renderSortIcon('lastContact.seconds')}
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('patientsContacted')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Patients Contacted
        </Typography>
        {renderSortIcon('patientsContacted')}
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        onClick={() => changeSort('apptsScheduled')}
      >
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Appts Scheduled
        </Typography>
        {renderSortIcon('apptsScheduled')}
      </Grid>
    </Grid>
  );

  const renderTableBody = () => {
    if (!contactsRetrieved && loading) {
      return (
        <div className={classes.loaderContainer}>
          <div className={classes.loadingMessage}>
            <Loader type='Oval' color='#696969' height='20' width='20' />
            <div className={classes.loadingText}>Last Contact Dates</div>
          </div>
          <div className={classes.loadingMessage}>
            <Loader type='Oval' color='#696969' height='20' width='20' />
            <div className={classes.loadingText}>Appointments Scheduled</div>
          </div>
          <div className={classes.loadingMessage}>
            <Loader type='Oval' color='#696969' height='20' width='20' />
            <div className={classes.loadingText}>Patients Contacted</div>
          </div>
        </div>
      );
    } else if (!loading && !updatedOffices[0]) {
      return (
        <Typography className={classes.messageText}>
          No Offices have been assigned to this team
        </Typography>
      );
    } else if (contactsRetrieved && !listOffices[0]) {
      return (
        <div className={classes.loaderContainer}>
          <div className={classes.loadingMessage}>
            <DoneIcon />
            <div className={classes.loadingText}>Last Contacts Retrieved</div>
          </div>
          <div className={classes.loadingMessage}>
            <DoneIcon />
            <div className={classes.loadingText}>
              Appointments Scheduled Retrieved
            </div>
          </div>
          <div className={classes.loadingMessage}>
            <Loader type='Oval' color='#696969' height='20' width='20' />
            <div className={classes.loadingText}>Patients Contacted</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.tableContainer}>
          {listOffices.map((o, i) => {
            return (
              <Link
                key={o.id}
                //currently, link just takes user to office details page
                to={`/office/${o.id}/details?pathname=/offices/data`}
              >
                <ButtonBase className={classes.buttonBase}>
                  <Grid container spacing={24} className={classes.tableRow}>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={3}
                      className={`${classes.cell} ${classes.firstCell}`}
                      style={{ paddingLeft: 12 }}
                    >
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.tableText}
                          ${classes.rowIndex}
                          ${
                            o.index > 99
                              ? classes.rowIndexTripleDig
                              : o.index > 9
                              ? classes.rowIndexDoubleDig
                              : ''
                          }
                        `}
                      >
                        {o.index}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`${classes.tableText} ${classes.firstColumn}`}
                      >
                        {o.officeInformation.name ?? ''}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`${classes.lastContact} ${classes.tableText}`}
                      >
                        {o.lastContact?.seconds
                          ? moment
                              .unix(o.lastContact.seconds)
                              .format('MMM D, YYYY, h:mm a')
                          : 'No Entries Found'}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.ptsContacted}
                          ${classes.tableText}
                        `}
                      >
                        {o.totalPatients
                          ? `${o.patientsContacted} / ${o.totalPatients}`
                          : o.patientsContacted}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.apptsScheduled}
                          ${classes.tableText}
                        `}
                      >
                        {o.apptsScheduled}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </Link>
            );
          })}
          <div className={classes.emptyRow} />
        </div>
      );
    }
  };

  const renderTableFooter = () => (
    <div className={classes.footer}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={1000}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          disabled: !!(loading || page === 0)
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          disabled: !!(
            loading ||
            (listOffices && listOffices.length < rowsPerPage)
          )
        }}
        onChangePage={(e, page) => onChangePage(e, page)}
        onChangeRowsPerPage={e => onChangeRowsPerPage(e)}
        labelDisplayedRows={({ from, to, count }) =>
          rowsCount && !loading
            ? `${from}-${rowsCount <= to ? rowsCount : to} of ${rowsCount}`
            : `${from}-${to}`
        }
      />
    </div>
  );

  const renderSearchBar = () => (
    <div className={classes.searchFieldContainer}>
      <span className={classes.searchIcon}>
        <SearchIcon />
      </span>
      <InputBase
        className={classes.searchField}
        onChange={e => {
          setSearchTerm(e.target.value);
          setPage(0);
        }}
        placeholder={'Search Offices...'}
        value={searchTerm ?? ''}
      />
    </div>
  );

  const onChangeRowsPerPage = e => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const onChangePage = (e, page) => {
    setPage(page);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={24} style={{ marginBottom: 12 }}>
        <Grid item xs={12}>
          <Paper className={classes.body} elevation={4}>
            <div className={classes.titleContainer}>{renderSearchBar()}</div>
            {renderTableHeaderRow()}
            {renderTableBody()}
            {renderTableFooter()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

ListsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  updatedOffices: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  contactsRetrieved: PropTypes.bool.isRequired,
  rowsCount: PropTypes.number.isRequired,
  setRowsCount: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ListsTable);
