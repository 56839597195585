import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import Billing from '../../scenes/Billing';
import DashboardOverview from '../../scenes/Dashboards/scenes/Overview';
import DashboardManagement from '../../scenes/Dashboards/scenes/Management';
import DashboardTeam from '../../scenes/Dashboards/scenes/Team';
import DashboardPersonal from '../../scenes/Dashboards/scenes/Personal';
import DashboardLists from '../../scenes/Dashboards/scenes/Lists';
import Data from '../../scenes/Data';
import RELO from '../../scenes/Data/scenes/RELO';
import Employees from '../../scenes/Employees';
import Team from '../../scenes/Team';
import helpers from '../../helpers';
import Offices from '../../scenes/Offices';
import Office from '../../scenes/Office';
import ClientGroup from '../../scenes/Offices/scenes/ClientGroups/components/ClientGroup';
import Region from '../../scenes/Offices/scenes/ClientGroups/components/ClientGroup/components/Regions/components/Region';
import Lists from '../../scenes/Lists';
import Mistake from '../../scenes/Mistake';
import Reports from '../../scenes/Reports';
import SecureRoute from '../SecureRoute';
import sitemap from '../../sitemap';
import { AppContext } from '../../contexts';
import './styles.css';

const Main = ({ location }) => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = profile.data.groups;

  return (
    <main id='main'>
      <Switch>
        <SecureRoute
          path='/dashboards/overview'
          component={DashboardOverview}
        />
        <SecureRoute
          path='/dashboards/management/:tabId'
          component={DashboardManagement}
        />
        <SecureRoute path='/dashboards/team/:tabId' component={DashboardTeam} />
        <SecureRoute
          path='/dashboards/personal/:tabId'
          component={DashboardPersonal}
        />
        <SecureRoute
          path='/dashboards/lists/:tabId'
          component={DashboardLists}
        />
        <SecureRoute
          path='/data/:tabId/:where/:orderByKey/:orderBySort/:page/:rowsPerPage/:rowsPerPageOptions/:pageForward/:lastVisible/:firstVisible'
          component={Data}
        />
        <SecureRoute
          path='/mistake/:tabId/:where/:orderByKey/:orderBySort/:page/:rowsPerPage/:rowsPerPageOptions/:pageForward/:lastVisible/:firstVisible'
          component={Mistake}
        />
        <SecureRoute path='/data/:tabId' component={Data} />
        <SecureRoute path='/relo/:reloId/:tabId' component={RELO} />
        <SecureRoute path='/relo/:reloId' component={RELO} />
        <SecureRoute path='/mistake/:tabId' component={Mistake} />
        <SecureRoute
          path='/offices/:tabId/:where/:orderByKey/:orderBySort/:page/:rowsPerPage/:rowsPerPageOptions/:pageForward/:lastVisible/:firstVisible'
          component={Offices}
        />
        <SecureRoute path='/offices/:tabId' component={Offices} />
        <SecureRoute path='/office/:officeId/:tabId' component={Office} />
        <SecureRoute path='/office/:officeId' component={Office} />
        <SecureRoute
          path='/clientGroup/:clientGroupId/regions/:regionId/:tabId'
          component={Region}
        />
        <SecureRoute
          path='/clientGroup/:clientGroupId/regions/:regionId'
          component={Region}
        />
        <SecureRoute
          path='/clientGroup/:clientGroupId/:tabId'
          component={ClientGroup}
        />
        <SecureRoute
          path='/clientGroup/:clientGroupId'
          component={ClientGroup}
        />

        <SecureRoute
          path='/billing/:tabId/:where/:orderByKey/:orderBySort/:page/:rowsPerPage/:rowsPerPageOptions/:pageForward/:lastVisible/:firstVisible'
          component={Billing}
        />
        <SecureRoute path='/billing/:tabId' component={Billing} />
        <SecureRoute
          path='/employees/:tabId/:where/:orderByKey/:orderBySort/:page/:rowsPerPage/:rowsPerPageOptions/:pageForward/:lastVisible/:firstVisible'
          component={Employees}
        />
        <SecureRoute path='/employees/:tabId' component={Employees} />
        <SecureRoute path='/team/:teamId/:tabId' component={Team} />
        <SecureRoute path='/team/:teamId/' component={Team} />
        <SecureRoute path='/lists/:tabId' component={Lists} />
        <SecureRoute path='/reports/:tabId' component={Reports} />

        {/* entry scene redirects */}
        <Redirect from='/scheduled' to={`/data/recall${location.search}`} />
        <Redirect
          from='/rescheduled'
          to={`/data/reschedule${location.search}`}
        />
        <Redirect
          from='/calls-answered'
          to={`/data/calls-answered${location.search}`}
        />
        <Redirect
          from='/not-scheduled'
          to={`/data/not-scheduled${location.search}`}
        />

        {/* default tab redirects */}
        <Redirect
          from='/dashboards/management'
          to={sitemap.dashboards.management.metadata.routesDefault}
        />
        <Redirect
          from='/dashboards/team'
          to={sitemap.dashboards.team.metadata.routesDefault}
        />
        <Redirect
          from='/dashboards/personal'
          to={sitemap.dashboards.personal.metadata.routesDefault}
        />
        <Redirect
          from='/dashboards/lists'
          to={sitemap.dashboards.lists.metadata.routesDefault}
        />
        <Redirect
          from='/data'
          to={helpers.getDefaultRoute(userGroups, 'data')}
        />
        <Redirect
          from='/mistake'
          to={helpers.getDefaultRoute(userGroups, 'mistake')}
        />
        <Redirect from='/offices' to={sitemap.offices.metadata.routesDefault} />
        <Redirect from='/billing' to={sitemap.billing.metadata.routesDefault} />
        <Redirect
          from='/employees'
          to={sitemap.employees.metadata.routesDefault}
        />
        <Redirect from='/lists' to={sitemap.lists.metadata.routesDefault} />
        <Redirect from='/reports' to={sitemap.reports.metadata.routesDefault} />

        {/* home redirect */}
        <Redirect from='/' to='/dashboards/overview' />
      </Switch>
    </main>
  );
};

Main.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Main);
