import _ from 'lodash';
import React from 'react';
import FilterFieldDateRange from './FieldDateRange';
import FilterFieldSelect from './FieldSelect';

const FilterField = ({ filter, onSelect, selected, where }) => {
  if (
    selected === filter.key ||
    selected in _.mapKeys(filter.options, 'value')
  ) {
    switch (filter.type) {
      case 'dateRange':
        return (
          <FilterFieldDateRange
            filter={filter}
            onSelect={onSelect}
            selected={selected}
            where={where}
          />
        );
      case 'select':
        return (
          <FilterFieldSelect
            filter={filter}
            onSelect={onSelect}
            selected={selected}
            where={where}
          />
        );
      case 'array-contains':
        return (
          <FilterFieldSelect
            filter={filter}
            onSelect={onSelect}
            selected={selected}
            where={where}
            arrayContains={true}
          />
        );
      default:
        return null;
    }
  }

  return null;
};

export default FilterField;
