import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Loader from 'react-loader-spinner';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

class DataTableHeader extends Component {
  state = { anchorEl: null, searchTerm: '' };

  UNSAFE_componentWillMount() {
    const { searchActive } = this.props;

    this.setState({ searchTerm: searchActive ? searchActive : '' });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { searchActive } = this.props;

    if (searchActive !== nextProps.searchActive) {
      this.setState({
        searchTerm: nextProps.searchActive ? nextProps.searchActive : ''
      });
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSearchTermChange = e => {
    this.setState({ searchTerm: e.target.value });
  };

  renderFilterIcon = () => {
    const { filtersApplied } = this.props;

    if (filtersApplied) {
      return (
        <Badge color='secondary' variant='dot'>
          <FilterIcon />
        </Badge>
      );
    }

    return <FilterIcon />;
  };

  renderSearchBar() {
    const { classes, clearSearchPath, history, location, title } = this.props;
    const { searchTerm } = this.state;

    return (
      <div className={classes.searchFieldContainer}>
        <span className={classes.searchIcon}>
          <SearchIcon />
        </span>
        <InputBase
          className={classes.searchField}
          onKeyDown={e => {
            if (e.keyCode === 27) history.push(clearSearchPath);
          }}
          onKeyPress={e => {
            if (e.charCode === 13) {
              const uriParams = location.pathname.split('/');
              const pagePosition = 6;
              if (uriParams.length >= pagePosition) uriParams[pagePosition] = 0;
              const path = uriParams.join('/');

              e.target.value
                ? history.push(`${path}?term=${e.target.value}`)
                : history.push(clearSearchPath);
            }
          }}
          onChange={this.handleSearchTermChange}
          placeholder={`Search ${title}...`}
          value={searchTerm}
        />
      </div>
    );
  }

  renderClearSearch() {
    const { classes, clearSearchPath, history } = this.props;
    const { searching } = this.state;

    if (searching) {
      return (
        <div className={classes.loaderContainer}>
          <Loader type='Oval' color='#696969' height='20' width='20' />
        </div>
      );
    }

    return (
      <Tooltip title='Clear Search'>
        <IconButton
          aria-label='Clear Search'
          onClick={() => {
            history.push(clearSearchPath);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderActions() {
    const { onClearFilter, searchActive, tableActionIcons, where } = this.props;
    const filtersApplied = where ? true : false;

    return searchActive
      ? this.renderClearSearch()
      : tableActionIcons.map((icon, index) =>
          icon.render(index, filtersApplied, onClearFilter)
        );
  }

  renderHeaderContent() {
    const { classes } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid item xs={5} className={classes.titleContainer}>
          <div className={classes.title}>{this.renderSearchBar()}</div>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.actions}>{this.renderActions()}</div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, searchActive } = this.props;
    return (
      <div
        className={
          searchActive
            ? `${classes.root} ${classes.rootSearchActive}`
            : classes.root
        }
      >
        {this.renderHeaderContent()}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: '8px 24px 8px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    minHeight: 48
  },
  rootSearchActive: {
    backgroundColor: 'white',
    borderBottom: '1px solid #bfbfbf',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    width: '100%'
  },
  searchFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  searchField: {
    width: '100%'
  },
  searchIcon: {
    marginRight: 6
  },
  actions: {
    color: theme.palette.text.secondary,
    textAlign: 'right'
  }
});

export default compose(
  connect(null, {}),
  withStyles(styles, { withTheme: true })
)(DataTableHeader);
