import {
  DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK,
  DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM
} from './types';

export const dialogEditOfficeOpsStatusClick = status => ({
  type: DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK,
  payload: status
});

export const dialogEditOfficeOpsStatusItem = item => ({
  type: DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM,
  payload: item
});
