import { authRef } from '../index';
import {
  AUTH_IS_ADMIN,
  AUTH_IS_CALLER,
  AUTH_IS_CALLER_SCHEDULED,
  AUTH_IS_CALLER_CALLS_ANSWERED,
  AUTH_IS_CALLER_RESCHEDULED,
  AUTH_IS_CALLING_MANAGER,
  AUTH_IS_CUSTOMER_MANAGER,
  AUTH_IS_FINANCIAL_CONTROLLER,
  AUTH_IS_ONBOARDER,
  AUTH_IS_TEAM_LEAD,
  AUTH_IS_TECHNICAL_SUPPORT,
  AUTH_LOGOUT_USER
} from './types';

export const authIsAdmin = bool => ({ type: AUTH_IS_ADMIN, payload: bool });

export const authIsCaller = bool => ({ type: AUTH_IS_CALLER, payload: bool });

export const authIsCallerScheduled = bool => ({
  type: AUTH_IS_CALLER_SCHEDULED,
  payload: bool
});

export const authIsCallerCallsAnswered = bool => ({
  type: AUTH_IS_CALLER_CALLS_ANSWERED,
  payload: bool
});

export const authIsCallerRescheduled = bool => ({
  type: AUTH_IS_CALLER_RESCHEDULED,
  payload: bool
});

export const authIsCallingManager = bool => ({
  type: AUTH_IS_CALLING_MANAGER,
  payload: bool
});

export const authIsCustomerManager = bool => ({
  type: AUTH_IS_CUSTOMER_MANAGER,
  payload: bool
});

export const authIsFinancialController = bool => ({
  type: AUTH_IS_FINANCIAL_CONTROLLER,
  payload: bool
});

export const authIsOnboarder = bool => ({
  type: AUTH_IS_ONBOARDER,
  payload: bool
});

export const authIsTeamLead = bool => ({
  type: AUTH_IS_TEAM_LEAD,
  payload: bool
});

export const authIsTechnicalSupport = bool => ({
  type: AUTH_IS_TECHNICAL_SUPPORT,
  payload: bool
});

export const authLogout = () => dispatch => {
  authRef
    .signOut()
    .then(() => {
      dispatch({ type: AUTH_LOGOUT_USER, payload: null });
    })
    .catch(error => console.log(error));
};
