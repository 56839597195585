import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import DataTable from '../../../../components/DataTable';
import { Add } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { teamColumns } from './columns';
import { withRouter } from 'react-router-dom';
import { t } from 'typy';
import { AppContext } from '../../../../contexts';
import DialogAddTeam from './components/DialogAddTeam';

const EmployeesTeam = props => {
  const { history, location, route, tabId } = props;
  const {
    state: { profile }
  } = useContext(AppContext);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const createGroups = t(route, 'metadata.permissions.create').safeArray || [];
  const userAllowedToAddTeam =
    _.intersection(userGroups, createGroups).length > 0;
  const addTeamDialogOpen =
    queryString.parse(location.search.slice(1)).addTeamDialogOpen === 'true';
  return (
    <>
      <DataTable
        {...props}
        collection='teams'
        title='Teams'
        columns={teamColumns}
        onRowClickLink={(data, pathname) =>
          `/team/${data.id}/details?pathname=${pathname}`
        }
        clearSearchPath={`/employees${
          tabId && tabId !== 'undefined' ? `/${tabId}` : ''
        }`}
        tableActionIcons={[
          {
            render: key =>
              userAllowedToAddTeam ? (
                <span key={key}>
                  <Tooltip title='Add Team'>
                    <IconButton
                      variant='contained'
                      color='primary'
                      aria-label='Add Team'
                      onClick={() => {
                        const pathname = location.pathname;
                        const param =
                          location.search === ''
                            ? '?addTeamDialogOpen=true'
                            : '&addTeamDialogOpen=true';
                        history.push(`${pathname}${location.search}${param}`);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </span>
              ) : null
          }
        ]}
        tableFilterable={false}
        filterData={{}}
        sceneUri='employees'
      />
      {userAllowedToAddTeam ? (
        <DialogAddTeam
          history={history}
          location={location}
          onClose={() => {
            let searchObj = queryString.parse(location.search.slice(1));
            delete searchObj.addTeamDialogOpen;
            history.push(
              `${location.pathname}?${queryString.stringify(searchObj)}`
            );
          }}
          onTeamAddRedirect={(newTeamId, defaultPathName) => {
            if (defaultPathName) {
              history.push(`/team/${newTeamId}/details?pathname=/teams/data`);
            } else {
              history.push(
                `/team/${newTeamId}/details?pathname=${location.pathname}`
              );
            }
          }}
          openDialog={addTeamDialogOpen}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state, props) => ({});

EmployeesTeam.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tabId: PropTypes.string.isRequired
};

export default withRouter(compose(connect(mapStateToProps, {}))(EmployeesTeam));
