import { db } from '../../../../index';

export const chargesFilters = [
  {
    index: 1,
    key: 'client',
    options: () =>
      new Promise((resolve, reject) => {
        const options = [];

        return db
          .collection('clients')
          .orderBy('officeInformation.name', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const client = doc.data();
              const clientName = client.officeInformation.name;

              options.push({
                key: doc.id,
                text: clientName,
                value: `client,==,${doc.id}`
              });
            });

            return resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Office'
  },
  {
    index: 2,
    key: 'type',
    options: [
      { key: 'answering', text: 'Answering' },
      { key: 'base', text: 'Base' },
      { key: 'recall', text: 'Recall' },
      { key: 'recallplus', text: 'Recall+' },
      { key: 'rescheduling', text: 'Rescheduling' },
      { key: 'treatment', text: 'Treatment' },
      { key: 'websiteChat', text: 'Website Chat' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Type'
  },
  {
    index: 3,
    key: 'category',
    type: 'select',
    options: [
      {
        key: 'entry',
        text: 'Entry Charge',
        value: 'entryCharge',
        parentKey: 'category'
      },
      {
        key: 'immediate',
        text: 'Immediate Charge',
        value: 'immediateCharge',
        parentKey: 'category'
      },
      {
        key: 'period',
        text: 'Period Charge',
        value: 'periodCharge',
        parentKey: 'category'
      }
      // TODO: add otherCharge attr to charge doc
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'value',
    optionValBool: true,
    label: 'Category'
  },
  {
    index: 4,
    key: 'period',
    type: 'dateRange',
    label: 'Period'
  },
  {
    index: 5,
    key: 'status',
    options: [
      { key: 'invoiced', text: 'Invoiced' },
      { key: 'open', text: 'Open' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Status'
  }
];
