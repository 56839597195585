/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ActionItemIcon from '@material-ui/icons/AlarmOn';
import DoNotBillIconOn from '@material-ui/icons/MoneyOff';
import ErrorIcon from '@material-ui/icons/ReportProblem';
import UrgentIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/AssignmentLate';
import iconNames from '../../scenes/Data/scenes/icon_names';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const DataTableRow = ({
  classes,
  columns,
  data,
  location,
  onRowClickLink,
  page,
  rowIndex,
  rowsPerPage
}) => {
  if (onRowClickLink) {
    return (
      <Link to={onRowClickLink(data, `${location.pathname}${location.search}`)}>
        <ButtonBase className={classes.root}>
          <Grid container spacing={24}>
            <Columns
              classes={classes}
              columns={columns}
              data={data}
              page={page}
              rowIndex={rowIndex}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
        </ButtonBase>
      </Link>
    );
  }

  return (
    <ButtonBase className={classes.root}>
      <Grid container spacing={24}>
        <Columns
          classes={classes}
          columns={columns}
          data={data}
          page={page}
          rowIndex={rowIndex}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </ButtonBase>
  );
};

const tableIcons = (text, classes) => {
  return (
    <>
      {text.includes(iconNames.emergencyIconName) ? (
        <span className={`${classes.icon} ${classes.errIcon}`}>
          <WarningIcon />
        </span>
      ) : null}
      {text.includes(iconNames.actionIconName) ? (
        <span className={classes.icon}>
          <ActionItemIcon />
        </span>
      ) : null}
      {text.includes(iconNames.urgentIconName) ? (
        <span className={classes.icon}>
          <UrgentIcon />
        </span>
      ) : null}
      {text.includes(iconNames.mistakeIconName) ? (
        <span className={`${classes.icon} ${classes.errIcon}`}>
          <ErrorIcon />
        </span>
      ) : null}
      {text.includes(iconNames.mistakeNotYetHandledIconName) ? (
        <span className={classes.icon}>
          <PriorityHighIcon />
        </span>
      ) : null}
      {text.includes(iconNames.mistakeHandledIconName) ? (
        <span className={`${classes.icon} ${classes.errIcon}`}>
          <CheckIcon className={classes.greenCheckmark} />
        </span>
      ) : null}
      {text.includes(iconNames.noBillIconName) ? (
        <span className={`${classes.icon} ${classes.errIcon}`}>
          <DoNotBillIconOn />
        </span>
      ) : null}
    </>
  );
};

const renderTableText = (text, classes) => {
  const regex = new RegExp(
    `(${iconNames.actionIconName}|${iconNames.urgentIconName}|${iconNames.mistakeIconName}|${iconNames.mistakeNotYetHandledIconName}|${iconNames.mistakeHandledIconName}|${iconNames.noBillIconName})+`
  );
  if (text) {
    return (
      <>
        <span>{text.replace(regex, '')}</span>
        {tableIcons(text, classes)}
      </>
    );
  }
  return '';
};

const Columns = ({ classes, columns, data, page, rowIndex, rowsPerPage }) =>
  _.orderBy(columns, 'index', 'asc').map((column, idx) => (
    <Grid
      item
      onClick={() => null}
      {...{
        key: idx,
        className: !column.essential
          ? classes.nonEssentialColumn
          : idx === 0
          ? classes.firstColumn
          : idx === columns.length - 1
          ? classes.lastColumn
          : column.icon
          ? classes.iconCell
          : null,
        ...(column.spacing.xs && { xs: column.spacing.xs }),
        ...(column.spacing.sm && { sm: column.spacing.sm }),
        ...(column.spacing.md && { md: column.spacing.md }),
        ...(column.spacing.lg && { lg: column.spacing.lg }),
        ...(column.spacing.xl && { xl: column.spacing.xl })
      }}
    >
      <RowIndex
        classes={classes}
        idx={idx}
        page={page}
        rowIndex={rowIndex}
        rowsPerPage={rowsPerPage}
      />
      <Typography variant='body1' className={classes.tableText}>
        {typeof data[column.key] === 'string'
          ? renderTableText(data[column.key], classes)
          : data[column.key]}
      </Typography>
    </Grid>
  ));

const RowIndex = ({ classes, idx, page, rowIndex, rowsPerPage }) => {
  if (idx === 0) {
    const index = rowIndex + page * rowsPerPage;

    return (
      <Typography
        variant='body1'
        className={`
          ${classes.rowIndex} 
          ${index + 1 > 9 ? classes.rowIndexDoubleDig : ''}
        `}
      >
        {index + 1}
      </Typography>
    );
  }

  return null;
};

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: 0,
    height: 48,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  firstColumn: {
    paddingLeft: '34px !important',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  },
  lastColumn: {
    paddingRight: '34px !important'
  },
  nonEssentialColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  },
  tableText: {
    fontSize: theme.spacing.unit * 1.5,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  rowIndex: {
    marginRight: 10,
    fontSize: '10px !important',
    paddingRight: 2
  },
  rowIndexDoubleDig: {
    marginRight: 6
  },
  iconCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    verticalAlign: 'middle',
    marginLeft: '2px',
    '& svg': {
      fontSize: '0.675rem',
      marginRight: '0px'
    }
  },
  errIcon: {
    '& svg': {
      color: theme.palette.error.dark
    }
  },
  greenCheckmark: {
    background: 'rgb(76, 175, 80)',
    fill: 'white'
  }
});

export default compose(
  connect(null, {}),
  withStyles(styles, { withTheme: true })
)(DataTableRow);
