import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import helpers from '../../../../../helpers';
import ReloInformation from './ReloInformation';
import ReloContact from './ReloContact';
import { Paper, IconButton, StepConnector } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import DialogAddContact from './DialogAddContact';
import ContactItemForm from './ContactItemForm';
import queryString from 'query-string';
import { compose } from 'redux';
import { AppContext } from '../../../../../contexts';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { t } from 'typy';
import moment from 'moment';

const Details = ({
  classes,
  relo,
  sitemapPath,
  match,
  location,
  history,
  route
}) => {
  const [reloRetrieved, setReloRetrieved] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [offices, setOffices] = useState([]);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);
  const allParams = queryString.parse(location.search);
  const userGroups = t(profile, 'data.groups').safeArray;
  const dialogDataParam = queryString.parse(location.search).dialogData;
  const param = dialogDataParam ? JSON.parse(dialogDataParam) : {};
  const loadingEditData = !!(param.id && !dialogData);

  const allowedToEdit = data => {
    const permission = helpers.allowedTo('edit', sitemapPath, userGroups);
    let itemIsOlderThanOneHour = moment()
      .subtract(1, 'hours')
      .isAfter(
        moment.unix(data?.createdOn?.seconds ?? data?.contactedOn?.seconds ?? 0)
      );
    return !!(!itemIsOlderThanOneHour || permission);
  };

  const dialogOnClose = refreshData => {
    const searchObj = queryString.parse(location.search);
    if (refreshData) searchObj.refreshData = true;
    delete searchObj.dialogData;
    const search = queryString.stringify(searchObj);
    history.push(`${location.pathname}?${search}`);
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchDialogData = async () => {
      const data = dialogDataParam ? JSON.parse(dialogDataParam) : {};
      try {
        if (data.id) {
          const entryData = await helpers.transformEntryDataForEdit(
            firebase,
            data.id,
            'relos'
          );
          setDialogData(entryData);
        } else {
          setDialogData(null);
        }
      } catch (err) {
        console.error(err);
        setDialogData(null);
      }
    };
    const fetchData = async () => {
      try {
        const officesQuerySnapshot = await firebase
          .firestore()
          .collection('clients')
          .orderBy('officeInformation.name')
          .get();
        const employeesQuerySnapshot = await firebase
          .firestore()
          .collection('employees')
          .orderBy('firstName')
          .get();
        const employeeDocs = [];
        const officeDocs = [];

        employeesQuerySnapshot.forEach(doc => {
          employeeDocs.push({ ...doc.data(), id: doc.id });
        });

        officesQuerySnapshot.forEach(doc => {
          officeDocs.push({ ...doc.data(), id: doc.id });
        });

        setEmployees(employeeDocs);
        setOffices(officeDocs);
      } catch (err) {
        setEmployees(null);
        setOffices(null);
      }
    };
    fetchData();
    fetchDialogData();
  }, [dialogDataParam, firebase]);

  const handleManConnect = () => {
    if (
      !_.isEmpty(allParams) &&
      !allParams.dialogData &&
      offices.length > 0 &&
      !allParams.refreshData &&
      !allParams.term
    ) {
      const path = location.pathname;
      const separator = path.indexOf('?') > -1 ? '&' : '?';
      const manConnectFields = {};
      history.push(
        `${path}${separator}dialogData=${JSON.stringify(manConnectFields)}`
      );
    }
  };

  let reloFromParams;
  if (!relo && !reloRetrieved) {
    const { reloId } = match.params;
    const getRelo = async () => {
      const relo = await firebase
        .firestore()
        .collection('relos')
        .doc(reloId)
        .get();

      reloFromParams = relo.data();
      setReloRetrieved(true);
    };
    getRelo();
  }

  return (
    <>
      <Paper elevation={2} className={classes.detailsPaper}>
        <ReloInformation
          relo={relo ? relo : reloFromParams}
          allowedToEdit={allowedToEdit}
        />
      </Paper>
      {relo && relo.contacts[0]
        ? relo.contacts.map((contact, key) => (
            <div key={key}>
              <StepConnector
                className={classes.stepConnector}
                orientation='vertical'
              />
              <Paper elevation={2} className={classes.detailsPaper}>
                <ReloContact
                  relo={relo ?? reloFromParams}
                  contact={contact}
                  contactId={key + 1}
                  allowedToEdit={allowedToEdit}
                />
              </Paper>
            </div>
          ))
        : null}
      {relo?.contacts.length < 3 && !relo.result ? (
        <>
          <StepConnector
            className={classes.stepConnector}
            orientation='vertical'
          />
          <div className={classes.newContactButton}>
            <Tooltip title='New Contact'>
              <IconButton
                variant='contained'
                color='primary'
                aria-label='New Contact'
                onClick={() => {
                  handleManConnect();
                  setDialogOpen(true);
                }}
              >
                <AddCircleOutlineIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ) : null}
      <DialogAddContact
        title='New Call Record'
        collection='relos'
        dialogBody={() =>
          loadingEditData ? null : (
            <ContactItemForm
              relo={relo}
              dialogOnClose={dialogOnClose}
              loadingEditData={loadingEditData}
              route={route}
              employees={employees}
              offices={offices}
            />
          )
        }
        dialogOnClose={dialogOnClose}
        dialogOpen={!loadingEditData && dialogOpen}
        location={location}
      />
    </>
  );
};

Details.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  relo: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(Details);
