import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { styles } from './styles';
import { AppContext } from '../../../../contexts';
import NewListForm from './components/NewListForm';
import DropzoneButton from './components/DropzoneButton';
import FilePreview from './components/FilePreview';
import { db } from '../../../..';
import OverlayLoader from '../../components/OverlayLoader';
import ErrorAlert from '../../components/ErrorAlert';

const NewList = ({ classes, item, offices }) => {
  const {
    state: { profile, firebase }
  } = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [drProfile, setDrProfile] = useState(null);
  const [isDenticon, setIsDenticon] = useState({
    denticon: false,
    filesRemaining: 3,
    fileNames: [],
    warning: ''
  });
  const [fileNameError, setFileNameError] = useState({
    error: false,
    reason: ''
  });
  const [statusMessage, setStatusMessage] = useState('Please upload list.');
  const [clientId, setClientId] = useState(null);
  const [fileStatus, setFileStatus] = useState('');
  const [autoLocation, setAutoLocation] = useState(null);
  const [drProfileChecked, setDrProfileChecked] = useState(false);
  const [multipleDenticonFiles, setMultipleDenticonFiles] = useState(false);
  const [problematicCsvs, setProblematicCsvs] = useState([]);

  useEffect(() => {
    const autoFillLocation = clientId => {
      const drProfileOffice = offices.find(office => office.id === clientId);
      if (drProfileOffice) {
        setAutoLocation(drProfileOffice.officeInformation.name);
      } else {
        setFileNameError({
          error: true,
          reason:
            'UID is either incorrect or office is no longer active. Please check and re-upload.'
        });
      }
    };

    if (clientId) {
      autoFillLocation(clientId);
    }
  }, [clientId, offices]);

  const clearFileStateAndSetResponse = str => {
    setFiles([]);
    if (isDenticon.denticon)
      setIsDenticon({ denticon: false, filesRemaining: 3, fileNames: [] });
    setDrProfileChecked(false);
    setDrProfile(null);
    setMultipleDenticonFiles(false);
    setStatusMessage(str);
  };

  const handleUpload = file => {
    const { name } = file[0];
    if (!checkFileNamePrefix(name)) {
      setFileNameError({
        error: true,
        reason: "File name is missing prefix 'callforce_'"
      });
    } else {
      // Clears any listed error reasons for this current upload if succeeds previous condition.
      if (fileNameError) setFileNameError({ error: false, reason: '' });
      if (isDenticon.denticon && !checkIfDenticon(name)) {
        setIsDenticon({ denticon: false, filesRemaining: 3, fileNames: [] });
        getClientId(name);
        setFiles(file);
        setFileStatus('ready');
      } else if (checkIfDenticon(name)) {
        if (file.length > 1) setMultipleDenticonFiles(true);
        handleDenticonFiles(file);
      } else {
        getClientId(name);
        setFiles(file);
        setFileStatus('ready');
      }
    }
  };

  const getClientId = name => {
    setClientId(name.split('_')[1].replace(/(\.txt|\.lst|\.xls|\.csv)/i, ''));
  };

  const checkFileNamePrefix = name => {
    const regexCallForcePrefix = /^callforce_/;
    return regexCallForcePrefix.test(name);
  };

  const handleDenticonFiles = file => {
    const { name } = file[0];
    if (!isDenticon.denticon) {
      // First file(s) of Denticon Compilation uploads
      setFileStatus('denticonNotReady');
      const officeId = name.split('_')[1];
      setClientId(officeId);
      if (file.length > 1) {
        checkDrProfile(officeId);
      }
      setFiles(file);
      setIsDenticon({
        denticon: true,
        filesRemaining: 3 - files.length,
        fileNames: [name]
      });
    } else {
      if (isDenticon.fileNames.length > 0 && duplicateDenticonFiles(name)) {
        return setIsDenticon({
          ...isDenticon,
          warning: `${name} was already uploaded!`
        });
      }
      if (isDenticon.warning) setIsDenticon({ ...isDenticon, warning: '' });
      file = [...files, ...file];
      setFiles(file);
    }
  };

  const duplicateDenticonFiles = name => {
    return isDenticon.fileNames.includes(name);
  };

  const checkIfDenticon = fileName => {
    const xlsRegex = /\.xls/;
    return xlsRegex.test(fileName);
  };

  const renderFilePreview = () => {
    return files.map((file, index) => (
      <div key={index}>
        <FilePreview
          clientId={removeClientId(file.name)}
          checkDrProfile={checkDrProfile}
          index={index}
          file={file}
          classes={classes}
          cancel={clearFileStateAndSetResponse}
          status={fileStatus}
          drProfileChecked={false}
          multipleDenticonFiles={multipleDenticonFiles}
          isDenticon={isDenticon.denticon}
        />
      </div>
    ));
  };

  const checkDrProfile = async clientId => {
    if (drProfileChecked) return;
    setDrProfileChecked(true);

    try {
      const drProfileRef = db
        .collection('clients')
        .doc(clientId)
        .collection('drProfile')
        .doc('profile');

      const drProfileDoc = await drProfileRef.get();

      if (!drProfileDoc.exists) {
        setDrProfile({});
        setStatusMessage('New file! Please fill out form to update DrProfile.');
      } else {
        const drProfile = { ...drProfileDoc.data(), id: clientId };
        setStatusMessage(
          'DrProfile found! Please update any missing fields and submit!'
        );
        setDrProfile(drProfile);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const removeClientId = name => {
    if (isDenticon.denticon) {
      return name.split('_')[1];
    } else {
      const regexCallForcePrefix = /^callforce_/,
        regexCallForceFileExtension = /\.\w+$/,
        regexNumber = /_\d/;
      return name
        .replace(regexCallForcePrefix, '')
        .replace(regexCallForceFileExtension, '')
        .replace(regexNumber, '');
    }
  };

  const renderFileNameError = () => (
    <Typography color='secondary'>{fileNameError.reason}</Typography>
  );

  const closeModal = () => {
    setProblematicCsvs([]);
  };

  const addProblematicCsvs = csvsArray => {
    setProblematicCsvs(csvsArray);
  };

  return (
    <>
      <Paper className={classes.detailsPaper}>
        <div className={classes.newListRoot}>
          <div className={classes.statusMessage}>{statusMessage}</div>
          <NewListForm
            classes={classes}
            drProfile={drProfile}
            isDenticon={isDenticon.denticon}
            filesRemaining={isDenticon.filesRemaining}
            allOffices={offices}
            files={files}
            employeeFullName={profile.data.displayName}
            setStatusMessage={setStatusMessage}
            clearFileStateAndSetResponse={clearFileStateAndSetResponse}
            clientId={clientId}
            setFileStatus={setFileStatus}
            firebase={firebase}
            autoLocation={autoLocation}
            handleUpload={handleUpload}
            denticonWarning={isDenticon.warning}
            denticonIsReady={files.length === 3}
            addProblematicCsvs={addProblematicCsvs}
          />
        </div>
      </Paper>
      <Paper className={classes.filePreviewPaper}>
        <div
          className={
            isDenticon.denticon && files.length === 3
              ? classes.filePreviewRootDenticon2
              : isDenticon.denticon && files.length === 2
              ? classes.filePreviewRootDenticon1
              : classes.filePreviewRoot
          }
        >
          {files.length > 0 ? (
            <List className={classes.filePreviewList}>
              {renderFilePreview()}
            </List>
          ) : (
            <div className={classes.dropzoneButtonRoot}>
              <DropzoneButton handleUpload={handleUpload} />
              {fileNameError.error ? (
                <div className={classes.fileNameErrorText}>
                  {renderFileNameError()}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </Paper>
      <OverlayLoader
        classes={classes}
        open={fileStatus === 'uploading'}
        files={files}
      />
      <ErrorAlert
        classes={classes}
        open={problematicCsvs.length > 0 && fileStatus !== 'uploading'}
        failedCsvs={problematicCsvs}
        handleClose={closeModal}
      />
    </>
  );
};

export default withRouter(compose(withStyles(styles))(NewList));
