import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

const Totals = ({ classes, data }) => (
  <div className={classes.root}>
    <Grid container spacing={24} className={classes.row}>
      <Grid item xs={6} sm={6} md={7} />
      <Grid item xs={3} sm={3} md={2}>
        <Typography variant='body2' className={classes.totalsText}>
          Subtotal:
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='body1' className={classes.totalsText}>
          ${data.subtotal.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={24} className={classes.row}>
      <Grid item xs={6} sm={6} md={7} />
      <Grid item xs={3} sm={3} md={2}>
        <Typography variant='body2' className={classes.totalsText}>
          Sales Tax:
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='body1' className={classes.totalsText}>
          ${data.salesTax.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={24} className={classes.row}>
      <Grid item xs={6} sm={6} md={7} />
      <Grid item xs={3} sm={3} md={2}>
        <Typography variant='body2' className={classes.totalsText}>
          <b>Total:</b>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='body1' className={classes.totalsText}>
          <b>${data.total.toFixed(2)}</b>
        </Typography>
      </Grid>
    </Grid>
  </div>
);

const styles = theme => ({
  root: {
    marginTop: 24,
    marginBottom: 18
  },
  row: {
    marginBottom: 0
  },
  totalsText: {
    textAlign: 'right'
  }
});

export default withStyles(styles, { withTheme: true })(Totals);
