/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import StripeInput from './StripeInput';
import { AppContext } from '../../../../../../../contexts';
import { compose } from 'redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { firestoreConnect } from 'react-redux-firebase';
import { TextField, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import '../elementStyles.css';

const CardForm = props => {
  const {
    classes,
    toggleSaveEditButton,
    setStripeId,
    stripeId,
    token,
    old_id,
    setCard,
    office,
    firestore,
    editingCard,
    noCard,
    saving,
    setSaving,
    setErrorMessage,
    theme: {
      palette: { primary }
    }
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const {
    state: { firebase }
  } = useContext(AppContext);

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();
    setSaving(true);

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    try {
      let billingToken = token;
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });

      if (error) throw error;
      if (!billingToken) {
        billingToken = await firebase.auth().currentUser.getIdToken();
      }

      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/billing-stripe-http-paymentMethods`,
        headers: { Authorization: `Bearer ${billingToken}` },
        params: {
          stripeEnv: process.env.REACT_APP_ENV === 'prod' ? 'live' : 'test',
          customer: stripeId,
          new_payment_id: paymentMethod.id,
          old_payment_id: old_id,
          officeName: office.officeInformation.name
        }
      })
        .then(res => res)
        .catch(e => {
          console.error(e);
          setSaving(false);
        });

      if (!res && paymentMethod) {
        return setErrorMessage(
          'Stripe Payment Success! But failed to update database.'
        );
      } else if (res && paymentMethod) {
        setErrorMessage('');
      }

      setCard({
        last4: paymentMethod.card.last4,
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
        type: paymentMethod.card.brand,
        id: paymentMethod.id
      });

      if (!stripeId) {
        await firestore
          .collection('clients')
          .doc(office.id)
          .collection('stripe')
          .doc('customer')
          .set({ id: res.data.customer });
        setStripeId(res.data.customer);
      }

      setSaving(false);
      toggleSaveEditButton();
    } catch (e) {
      setSaving(false);
      console.error(e);
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={classes.formInput}>
        <TextField
          className={classes.cardField}
          label='Card Information'
          variant='outlined'
          required
          fullWidth
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardElement
            }
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction='row'
        justify='flex-end'
        alignItems='center'
      >
        {editingCard && !noCard && (
          <Button
            onClick={e => {
              toggleSaveEditButton();
            }}
          >
            cancel
          </Button>
        )}
        <Button
          onClick={e => {
            handleSubmit(e);
          }}
          disabled={saving}
        >
          {saving ? (
            <>
              <span>saving</span>
              <Loader
                type='Oval'
                color={primary.main}
                height='10'
                width='10'
                style={{ padding: '5px' }}
              />
            </>
          ) : (
            'Save Card'
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = _theme => ({
  cardField: {
    height: 'unset'
  },
  formInput: {
    padding: '10px 0 10px 0'
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  firestoreConnect()
)(CardForm);
