import {
  DIALOG_EDIT_TEAM_SERVICE_CLICK,
  DIALOG_EDIT_TEAM_SERVICE_ITEM
} from '../actions/types';

export const dialogEditTeamServiceItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_SERVICE_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditTeamServiceOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_SERVICE_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditTeamServiceReducer = {
  dialogEditTeamServiceItem,
  dialogEditTeamServiceOpen
};
