import _ from 'lodash';
import React, { Component } from 'react';
import AddCreditIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCreditIcon from '@material-ui/icons/RemoveCircleOutline';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Field } from 'redux-form';
import TextField from '../../../../../../../components/TextField';
import { styles } from './styles';
import { validations } from '../../../../../../../services/validation';

class SignUpCreditArray extends Component {
  componentDidMount() {
    const { fields, formData } = this.props;

    formData.signUpCredit &&
      formData.signUpCredit.forEach(credit => {
        fields.push(credit);
      });
  }

  renderAddCreditButton(fields) {
    const { classes } = this.props;

    return (
      <Tooltip title='Add Credit'>
        <IconButton
          variant='contained'
          color='secondary'
          aria-label='Add Credit'
          onClick={() =>
            fields.push({
              title: 'Sign-Up Credit',
              description: 'Promotional credit for signing up'
            })
          }
        >
          <AddCreditIcon className={classes.addIcon} />
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    const { classes, fields } = this.props;
    const creditTextFields = [
      {
        key: 'title',
        label: 'Title',
        validations: [validations.required],
        type: 'text'
      },
      {
        key: 'description',
        label: 'Description',
        validations: [validations.required],
        type: 'text'
      },
      {
        key: 'value',
        label: 'Value',
        validations: [validations.required, validations.isPositive],
        type: 'number',
        step: '0.01'
      }
    ];

    return (
      <span>
        {fields.map((credit, index) => (
          <span key={index} style={{ display: 'block', width: '100%' }}>
            <div
              className={classes.subSectionTitle}
              style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant='caption'>Credit #{index + 1}</Typography>
              <Tooltip title='Remove Credit'>
                <IconButton
                  variant='contained'
                  color='secondary'
                  aria-label='Remove Credit'
                  style={{ padding: 0, marginLeft: 8 }}
                  onClick={() => fields.remove(index)}
                >
                  <RemoveCreditIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.creditRow}>
              {_.map(creditTextFields, field => (
                <Field
                  key={field.key}
                  name={`${credit}.${field.key}`}
                  component={TextField}
                  type={field.type}
                  step={field.step}
                  label={field.label}
                  className={classes.dialogTextField}
                  validate={field.validations}
                />
              ))}
            </div>
          </span>
        ))}
        {this.renderAddCreditButton(fields)}
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SignUpCreditArray);
