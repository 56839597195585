import _ from 'lodash';
import React, { Component } from 'react';
import qs from 'qs';
import TextField from '../../../../../../../components/TextField';
import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { styles } from './styles';
import { validations } from '../../../../../../../services/validation';

class SetupFee extends Component {
  componentDidMount() {
    const { change, formData } = this.props;
    const setupFeeAmount = _.get(formData, 'setupFeeAmount', '199.00');

    change('setupFeeAmount', setupFeeAmount);
  }

  render() {
    const {
      classes,
      formSectionValues,
      onSubmit,
      handleSubmit,
      previousPage
    } = this.props;

    return (
      <form onSubmit={handleSubmit(() => onSubmit(formSectionValues))}>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Setup Fee</Typography>
        </div>
        <div className={classes.setupFee}>
          <Field
            key='setupFeeAmount'
            name='setupFeeAmount'
            component={TextField}
            type='number'
            step='0.01'
            label='Amount'
            className={classes.dialogTextField}
            validate={[validations.required, validations.isNotNegative]}
          />
        </div>
        <div className={classes.sectionActions}>
          <Button
            color='primary'
            type='button'
            onClick={() => previousPage(formSectionValues)}
          >
            Previous
          </Button>
          <Button color='primary' type='submit'>
            Next
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const searchObj = qs.parse(location.search.slice(1));
  const formData = JSON.parse(_.get(searchObj, 'addOfficeFormData', {}));
  const selector = formValueSelector('DialogAddOfficeForm');

  return {
    formData,
    formSectionValues: {
      setupFeeAmount: selector(state, 'setupFeeAmount')
    }
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'DialogAddOfficeForm',
    destroyOnUmount: false,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles, { withTheme: true })
)(SetupFee);
