import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'compose-func';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  Button
} from '@material-ui/core';
import DialogTransition from '../../../../../components/DialogTransition';
import FilteredSelect from '../../../../../components/FilteredSelect';
import { Close } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import { required } from '../../../../../validation';
import Loader from 'react-loader-spinner';
import {
  FieldSelect,
  FieldText,
  FieldCheckbox
} from '../../../../../components/Fields';
import { firestoreConnect } from 'react-redux-firebase';
import { AppContext } from '../../../../../contexts';
import moment from 'moment';

const styles = theme => ({
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    minHeight: 48,
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  dialogSubtitle: {
    position: 'absolute',
    fontSize: 10,
    bottom: 2,
    left: 0,
    color: 'white'
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': {
      color: 'white'
    }
  },
  dialogContent: {
    paddingBottom: 24
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 8
    }
  }
});

const DialogAddVerification = ({
  dialogOpen,
  fullScreen,
  classes,
  item,
  dialogOnClose,
  firestore,
  route,
  offices,
  employees
}) => {
  let [confirmDelete, setConfirmDelete] = useState(false);
  const {
    state: { auth, profile }
  } = useContext(AppContext);

  const userGroups = profile?.data?.groups ?? [];
  const manageGroups = route?.metadata?.permissions?.manage ?? [];

  const renderActionButtons = (isSubmitting, setSubmitting) => {
    let itemIsOlderThanOneHour = moment()
      .subtract(1, 'hours')
      .isAfter(moment.unix(item?.data?.()?.createdTime?.seconds ?? 0));
    let cannotEdit = !!(
      !userGroups.some(group => manageGroups.includes(group)) &&
      itemIsOlderThanOneHour &&
      item.id
    );
    if (confirmDelete) {
      let confirmDeleteButton = (
        <Button
          color='secondary'
          onClick={async () => {
            setSubmitting(true);
            await item.ref.delete();
            dialogOnClose(true);
          }}
          disabled={cannotEdit}
        >
          Confirm Delete
        </Button>
      );
      return (
        <>
          <Button
            color='primary'
            onClick={() => {
              setConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          {cannotEdit ? (
            <Tooltip title='Permission denied' placement='top'>
              <div>{confirmDeleteButton}</div>
            </Tooltip>
          ) : (
            confirmDeleteButton
          )}
        </>
      );
    }

    let saveButton = (
      <Button
        color='primary'
        disabled={isSubmitting || cannotEdit}
        type='submit'
      >
        Save
      </Button>
    );
    return (
      <>
        {item && item.id ? (
          <Button
            color='secondary'
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            Delete
          </Button>
        ) : (
          <Button
            color='secondary'
            onClick={() => {
              dialogOnClose();
            }}
          >
            Cancel
          </Button>
        )}
        {cannotEdit ? (
          <Tooltip title='Permission denied' placement='top'>
            <div>{saveButton}</div>
          </Tooltip>
        ) : (
          saveButton
        )}
      </>
    );
  };

  const validations = {
    failedNote: required,
    patientName: required,
    office: required,
    result: required
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={dialogOpen}
      onClose={dialogOnClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Insurance Verification Entry
          </Typography>
          <Typography variant='caption' className={classes.dialogSubtitle}>
            {item && item.id}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton
            color='inherit'
            aria-label='Close'
            onClick={() => dialogOnClose(false)}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        {item ? (
          <Formik
            initialValues={item.id ? item.data() : { newPatient: false }}
            onSubmit={async values => {
              if (item && item.id) {
                // editing an existing
                await item.ref.set(
                  {
                    ...values,
                    meta: {
                      editedBy: auth.data.uid,
                      editedByName: employees.find((employee) => employee.id === auth.data.uid).displayName,
                      editedTime: firestore.FieldValue.serverTimestamp()
                    },
                    officeName: offices.find((office) => office.id === values.office).officeInformation.name
                  },
                  { merge: true }
                );
                dialogOnClose(true);
              } else {
                // creating a new!
                await firestore.collection('insuranceVerifications').add({
                  ...values,
                  meta: {
                    createdBy: auth.data.uid,
                    createdByName: employees.find((employee) => employee.id === auth.data.uid).displayName,
                    editedBy: auth.data.uid,
                    editedByName: employees.find((employee) => employee.id === auth.data.uid).displayName,
                    editedTime: firestore.FieldValue.serverTimestamp()
                  },
                  officeName: offices.find((office) => office.id === values.office).officeInformation.name,
                  createdTime: firestore.FieldValue.serverTimestamp(),
                  source: 'console',
                  status: 'complete'
                });
                dialogOnClose(true);
              }
            }}
          >
            {({ isSubmitting, values, setSubmitting, setFieldValue }) => (
              <Form>
                <div className={classes.row}>
                  <Field name='patientName' validate={validations.patientName}>
                    {({ form, field }) => (
                      <FieldText
                        form={form}
                        field={field}
                        label='Patient Name'
                        variant='outlined'
                      ></FieldText>
                    )}
                  </Field>
                </div>
                <div className={classes.row}>
                  <Field name='patientId'>
                    {({ form, field }) => (
                      <FieldText
                        form={form}
                        field={field}
                        label='Patient ID'
                        variant='outlined'
                      ></FieldText>
                    )}
                  </Field>
                  <FieldCheckbox
                    name='newPatient'
                    label='New Patient'
                    disabled={false}
                    checked={!!values.newPatient}
                  />
                </div>
                <div className={classes.row}>
                  <Field name='office' validate={validations.office}>
                    {({ form, field, meta: { initialValue } }) => (
                      <FilteredSelect
                        key={offices?.length ?? 0}
                        selectOptions={{
                          label: 'Office',
                          getOptionLabel: d => d.officeInformation.name,
                          getOptionValue: d => d.id,
                          isClearable: true,
                          isLoading: true
                        }}
                        optionsAreEqual={(l, r) => l.id === r}
                        showLimit={4}
                        onChange={choice => {
                          setFieldValue('office', choice && choice.id);
                        }}
                        optionsProvider={
                          offices
                            ? async () => {
                                return offices;
                              }
                            : () => new Promise(() => {})
                        }
                        previousOption={initialValue || null}
                      ></FilteredSelect>
                    )}
                  </Field>
                </div>
                <div className={classes.row}>
                  <Field name='result' validate={validations.result}>
                    {({ form, field }) => (
                      <FieldSelect
                        label='Result'
                        form={form}
                        field={field}
                        options={[
                          { label: 'Verified', val: 'verified' },
                          { label: 'Failed', val: 'failed' }
                        ]}
                        optionText='label'
                        optionValue='val'
                        optionKey='val'
                      ></FieldSelect>
                    )}
                  </Field>
                </div>
                {values.result === 'failed' ? (
                  <Field name='failedNote' validate={validations.failedNote}>
                    {({ form, field }) => (
                      <FieldText
                        form={form}
                        field={field}
                        label='Failure Reason'
                        variant='outlined'
                      ></FieldText>
                    )}
                  </Field>
                ) : null}
                <div className={classes.row}>
                  <div className={classes.actionButtons}>
                    {isSubmitting ? (
                      <Loader
                        type='Oval'
                        color='#696969'
                        height='15'
                        width='15'
                      />
                    ) : null}
                    {renderActionButtons(isSubmitting, setSubmitting)}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '4rem'
            }}
          >
            <Loader type='Oval' color='#696969' height='40' width='40' />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter,
  firestoreConnect()
)(DialogAddVerification);
