export const styles = theme => ({
  dialog: {
    minWidth: theme.spacing.unit * 75,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  subtitle: {
    position: 'absolute',
    fontSize: 10,
    bottom: 2,
    left: 0,
    color: 'white'
  },
  dialogTitleArea: {
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.lighter})`,
    display: 'flex',
    minHeight: 48,
    padding: '0px 16px'
  },
  dialogTitleTextArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative'
  },
  dialogTitle: {
    color: 'white',
    fontSize: theme.spacing.unit * 2.5
  },
  dialogCloseButton: {
    marginLeft: 'auto',
    '& svg': { color: 'white' }
  },
  dialogContent: {
    paddingBottom: (theme.spacing.unit * 3) / 2
  },
  dialogForm: {
    marginTop: 12,
    width: '100%',
    [theme.breakpoints.up('md')]: { width: 500 }
  },
  dialogTextField: {
    width: '100%'
  },
  dialogButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 12,
    marginBottom: 12
  },
  dialogButtonContainerWithText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12
  },
  dialogCheckboxLabelContainer: {
    display: 'flex'
  },
  dialogCheckbox: {
    display: 'flex'
  },
  dialogCheckboxLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  pricingPlans: {
    width: '100%'
  },
  radioLabel: {
    marginTop: 24
  },
  sectionTitle: {
    width: '100%',
    textTransform: 'uppercase',
    marginTop: 24,
    marginLeft: 8
  },
  warningText: {
    color: theme.palette.onboarding.main,
    fontStyle: 'italic',
    marginTop: 24
  }
});
