import {
  DIALOG_EDIT_TEAM_MEMBERS_CLICK,
  DIALOG_EDIT_TEAM_MEMBERS_ITEM,
  DIALOG_DELETE_TEAM_MEMBERS_CLICK,
  DIALOG_DELETE_TEAM_MEMBERS_ITEM
} from '../actions/types';

export const dialogEditTeamMembersItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_MEMBERS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditTeamMembersOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_MEMBERS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogDeleteTeamMembersItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_DELETE_TEAM_MEMBERS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogDeleteTeamMembersOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_DELETE_TEAM_MEMBERS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditTeamMembersReducer = {
  dialogEditTeamMembersItem,
  dialogEditTeamMembersOpen,
  dialogDeleteTeamMembersItem,
  dialogDeleteTeamMembersOpen
};
