import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  dialogEditTeamLeadClick,
  dialogEditTeamLeadItem
} from '../../../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import FilteredSelect from '../../../components/FilteredSelect';
import { validations } from '../../../services/validation';

const DialogEditTeamLead = props => {
  const {
    classes,
    item,
    fullScreen,
    firestore,
    open,
    dialogEditTeamLeadItem,
    dialogEditTeamLeadClick
  } = props;
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);
  const [validateError, setValidateError] = useState(false);

  const onClose = () => {
    if (validateError) setValidateError(false);
    setLoadingAfterSubmit(false);
    dialogEditTeamLeadClick(!open);
    dialogEditTeamLeadItem(null);
  };

  const onSubmit = async values => {
    if (validateError) return;
    setLoadingAfterSubmit(true);
    const { lead } = values;
    let {
      key,
      membersKey,
      data: { id, members }
    } = item;
    const ref = { collection: 'teams', doc: id };
    members = modifyMembersArrayWithNewLead(lead, members);
    try {
      await firestore.update(ref, { [key]: lead, [membersKey]: members });
      onClose();
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  const validate = value => {
    if (validations.required(value)) {
      setValidateError('Required');
      return true;
    } else {
      setValidateError(null);
      return false;
    }
  };

  const modifyMembersArrayWithNewLead = (newLead, members) => {
    let {
      data: { lead }
    } = item;
    if (members.find(employee => employee === newLead)) {
      return members;
    } else {
      members = members.filter(employee => employee !== lead);
      members.push(newLead);
      return members;
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth='md'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {item && item.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentTeamLead}>
        <Formik initialValues={{ lead: '' }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              {item ? (
                <Field name='lead' label='Team Lead' validate={validate}>
                  {({ field }) => {
                    return (
                      <FilteredSelect
                        optionsProvider={async () => {
                          let o = await firestore
                            .collection('employees')
                            .where('active', '==', true)
                            .get();
                          let d = o.docs;
                          return d;
                        }}
                        error={validateError}
                        previousOption={item ? { id: item.data.lead } : null}
                        onChange={employee => {
                          if (employee) setFieldValue(field.name, employee.id);
                        }}
                        showLimit={3}
                        optionsAreEqual={(l, r) => {
                          return l.id === r.id;
                        }}
                        selectOptions={{
                          label: 'Team Lead',
                          getOptionLabel: o => {
                            let d = o.data();
                            return d.displayName
                              ? d.displayName
                              : `${d.firstName} ${d.lastName}`;
                          },
                          getOptionValue: o => {
                            return o.id;
                          },
                          isClearable: true
                        }}
                      ></FilteredSelect>
                    );
                  }}
                </Field>
              ) : null}
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogEditTeamLeadOpen: open, dialogEditTeamLeadItem: item },
  props
) => {
  let initialValues = item ? { [`${item.key}`]: item.data[item.key] } : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditTeamLeadClick,
    dialogEditTeamLeadItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditTeamLead);
