import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { dialogDeleteTeamItem, dialogDeleteTeamClick } from '../../../actions';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { withRouter } from 'react-router-dom';
import { db } from '../../../index';

const DialogDeleteTeam = ({
  classes,
  item,
  fullScreen,
  open,
  dialogDeleteTeamItem,
  dialogDeleteTeamClick,
  initialValues,
  history
}) => {
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onClose = () => {
    setLoadingAfterSubmit(false);
    dialogDeleteTeamClick(!open);
    dialogDeleteTeamItem(null);
  };

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);

    try {
      await db
        .collection('teams')
        .doc(values)
        .delete();
      onClose();
      history.push('/employees/teams');
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth='sm'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Delete Team
          </Typography>
          <Typography variant='caption' className={classes.dialogSubtitle}>
            {item && item.team.id}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogText}>
          Please confirm the deletion of '{item && item.team.name}.'
        </DialogContentText>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogDeleteTeamOpen: open, dialogDeleteTeamItem: item },
  props
) => {
  let initialValues = item ? item.team.id : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogDeleteTeamClick,
    dialogDeleteTeamItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter
)(DialogDeleteTeam);
