import React, { useState, useContext, useEffect } from 'react';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Countdown from 'react-countdown-now';
import Loader from 'react-loader-spinner';
import logo from '../../assets/ReachLogo.png';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { AppContext } from '../../contexts';
import { FieldText } from '../../components/Fields';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { slideUpTransitionOptions } from '../../services/transitions';
import { styles } from './styles';
import { withFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const CreatePassword = ({
  classes,
  history,
  oobCode,
  emailParam,
  redirectUri,
  validate
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [errorText, setErrorText] = useState('');
  const [successText] = useState('');
  const [successWaitAuthText, setSuccessWaitAuthText] = useState('');
  const [successWaitRedirectText, setSuccessWaitRedirectText] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [invalidCode, setInvalidCode] = useState(null);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  useEffect(() => {
    firebase
      .auth()
      .verifyPasswordResetCode(oobCode)
      .then(() => setInvalidCode(false))
      .catch(() => setInvalidCode(true));
  }, [firebase, oobCode]);

  const onSubmit = (values, actions) => {
    const { resetForm } = actions;
    const { password } = values;

    resetForm();
    setFormSuccess(false);
    setFormError(false);
    setLoadingAfterSubmit(true);

    firebase
      .auth()
      .confirmPasswordReset(oobCode, password)
      .then(res => {
        createPasswordSuccess(password);
      })
      .catch(err => {
        const requestAgainText =
          'Please try requesting a create password link again';
        const tryAgainText = 'Please try again';
        switch (err.code) {
          case 'auth/expired-action-code':
            setErrorText(
              `Your create password code has expired. ${requestAgainText}.`
            );
            break;
          case 'auth/invalid-action-code':
            setErrorText(
              `Your create password code is invalid. ${requestAgainText}.`
            );
            break;
          case 'auth/user-disabled':
            setErrorText(`The user for which you are attempting to create the password has been disabled. 
            ${requestAgainText}.`);
            break;
          case 'auth/user-not-found':
            setErrorText(`The user for which you are attempting to create the password cannot be found. 
            ${requestAgainText}.`);
            break;
          case 'auth/weak-password':
            setErrorText(`Your password is too weak. ${tryAgainText}.`);
            break;
          default:
            setErrorText(
              `An error occured while creating your password. ${requestAgainText}.`
            );
        }
        setErrorText('There was an error.');
        setFormError(true);
        setFormSuccess(false);
        setLoadingAfterSubmit(false);
      });
  };

  const renderControls = () => {
    if (invalidCode) {
      return (
        <div className={classes.controls}>
          <Button
            onClick={() => history.push('/login')}
            variant='outlined'
            color='secondary'
            className={classes.loginButton}
          >
            <BackIcon /> Login
          </Button>
        </div>
      );
    }

    return null;
  };

  const renderText = () => {
    if (formError) {
      return (
        <Typography variant='body1' className={classes.loginError}>
          {errorText}
        </Typography>
      );
    } else if (formSuccess) {
      if (successText) {
        return (
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {successText}
          </Typography>
        );
      } else if (successWaitAuthText) {
        return (
          <span style={{ textAlign: 'center' }}>
            <Typography variant='body1'>
              <b>Success!</b>
              <br />
              Your password was successfully created. Please wait while you are
              authenticated.
              <br />
            </Typography>
            <Loader type='Oval' color='#ffffff' height='20' width='20' />
          </span>
        );
      } else if (successWaitRedirectText) {
        return (
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            <b>Success!</b>
            <br />
            Your password was successfully created. You will be redirected to
            login in
            <Countdown
              date={Date.now() + 3000}
              renderer={({ seconds }) => {
                return <span>{` ${seconds}`}</span>;
              }}
              zeroPadLength={0}
              onComplete={() => history.push('/login')}
            />
            ...
          </Typography>
        );
      } else {
        return null;
      }
    } else {
      return (
        <Typography variant='body1'>
          Please enter and confirm you new password below.
        </Typography>
      );
    }
  };

  const authenticateUser = password => {
    const decodedEmail = decodeURIComponent(emailParam);
    firebase
      .login({ email: decodedEmail, password })
      .then(res => {
        history.push(`${decodeURIComponent(redirectUri)}`);
      })
      .catch(err => {
        console.error(err);
        history.push(`/login?redirectUri=${redirectUri}`);
      });
  };

  const createPasswordSuccess = password => {
    if (redirectUri) {
      setSuccessWaitAuthText(true);
      setFormSuccess(true);
      setFormError(false);
      setLoadingAfterSubmit(false);
      authenticateUser(password, redirectUri);
    } else {
      setSuccessWaitRedirectText(true);
      setFormSuccess(true);
      setFormError(false);
      setLoadingAfterSubmit(false);
    }
  };

  const renderButtonText = () => {
    if (loadingAfterSubmit) {
      return <Loader type='Oval' color='#ffffff' height='13' width='13' />;
    } else {
      return 'Save Password';
    }
  };

  const renderFormFields = () => {
    if (!formSuccess) {
      return (
        <span>
          <Field name='password'>
            {({ form, field }) => {
              return (
                <FieldText
                  form={form}
                  field={field}
                  label='New Password'
                  variant='outlined'
                  type='password'
                  onChange={e => {
                    field.onBlur(e);
                    field.onChange(e);
                  }}
                />
              );
            }}
          </Field>
          <Field name='passwordConfirm'>
            {({ form, field }) => {
              return (
                <FieldText
                  form={form}
                  field={field}
                  label='Confirm Password'
                  type='password'
                  variant='outlined'
                  onChange={e => {
                    field.onBlur(e);
                    field.onChange(e);
                  }}
                />
              );
            }}
          </Field>
          <div className={classes.buttonContainer}>
            <Button
              size='large'
              variant='contained'
              color='primary'
              className={classes.button}
              type='submit'
            >
              {renderButtonText()}
            </Button>
          </div>
        </span>
      );
    }

    return null;
  };

  const renderForm = () => {
    if (invalidCode === null) {
      return (
        <div className={classes.initialLoading}>
          <Loader type='Oval' color='#ffffff' height='20' width='20' />
        </div>
      );
    } else if (invalidCode) {
      return (
        <Typography variant='body1' className={classes.loginError}>
          Your create password code has either expired or is invalid. Please try
          requesting a create password link again.
        </Typography>
      );
    } else {
      return (
        <Formik initialValues={{}} onSubmit={onSubmit} validate={validate}>
          <Form className={classes.form}>{renderFormFields()}</Form>
        </Formik>
      );
    }
  };

  return (
    <div className={`CreatePassword ${classes.root}`}>
      {renderControls()}
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.content}>
            <div className={classes.header}>
              <img src={logo} alt='Logo' className={classes.logo} />
            </div>
            <div>
              <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
                <span>{renderText(classes)}</span>
                {renderForm()}
              </ReactCSSTransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const params = ownProps.match.params;
  let redirectUri = '%2F';

  const validate = values => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Please enter your new password';
    }

    if (values.password.length < 6) {
      errors.password = 'Password must include at least 6 characters';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Please confirm your new password';
    }

    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = "Your passwords don't match.";
    }

    return errors;
  };

  return {
    oobCode: params.oobCode,
    emailParam: params.emailParam,
    redirectUri,
    validate
  };
};

CreatePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  redirectUri: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter,
  withStyles(styles, { withTheme: true })
)(CreatePassword);
