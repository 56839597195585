export const styles = theme => ({
  detailsPaper: {
    minHeight: '30vh',
    display: 'flex',
    position: 'relative',
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  uploadButtonsPaper: {
    minHeight: '10vh',
    display: 'flex',
    position: 'relative',
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  buttonsRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonsArea: {
    height: 50,
    display: 'flex',
    marginRight: 10,
    '& > button': {
      margin: 2
    }
  },
  listsInfoRoot: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  filePreviewPaper: {
    minHeight: '30vh',
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  filePreviewRoot: {
    // height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  dropzoneArea: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dropzoneText: {
    display: 'flex',
    alignItems: 'center'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'lightgrey'
  },
  fileText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  incompleteProfileToast: {
    backgroundColor: 'lightYellow',
    fontSize: 14
  },
  failedProfileToast: {
    backgroundColor: '#f20500',
    fontSize: 14
  },
  duplicateProfileToast: {
    backgroundColor: 'rgb(221,221,221)',
    fontSize: 14
  },
  fileStatusReady: {
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    height: 50,
    borderRadius: '8px',
    backgroundColor: '#e8fce8',
    transition: 'background-color 700ms linear'
  },
  fileStatusNotReady: {
    backgroundColor: 'lightgrey',
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    height: 50,
    borderRadius: '8px'
  },
  fileStatusIncompleteDrProfile: {
    backgroundColor: 'lightyellow',
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    height: 50,
    borderRadius: '8px',
    transition: 'background-color 700ms linear'
  },
  fileStatusFailed: {
    backgroundColor: '#FFCCCB',
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    height: 50,
    borderRadius: '8px',
    transition: 'background-color 700ms linear'
  },
  filePreviewAvatar: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filePreviewList: {
    width: '100%',
    maxHeight: '100%'
    // overflow: 'auto'
  },
  errorDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  errorDialogNames: {
    paddingTop: 20
  },
  officeName: {
    width: 150,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dialogFileListing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  filePreviewSize: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filePreviewProgress: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  formControlOfficeSelection: {
    width: 200,
    height: 45
  },
  selectOfficeSelection: {
    height: 45
  }
});
