const getAllEmployees = firestore =>
  new Promise(async (resolve, reject) => {
    try {
      const teamMembers = await firestore
        .collection('employees')
        .get()
        .then(snapshot => {
          return Promise.resolve(
            snapshot.docs.map(doc => {
              return { ...doc.data(), id: doc.id };
            })
          );
        });

      return resolve(teamMembers);
    } catch (e) {
      return reject(e);
    }
  });

export default getAllEmployees;
