import {
  DIALOG_EDIT_TEAM_LEAD_CLICK,
  DIALOG_EDIT_TEAM_LEAD_ITEM
} from '../actions/types';

export const dialogEditTeamLeadItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_LEAD_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditTeamLeadOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_LEAD_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditTeamLeadReducer = {
  dialogEditTeamLeadItem,
  dialogEditTeamLeadOpen
};
