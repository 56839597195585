import React, { Component, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import FilteredSelect from 'react-select';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { AppContext } from '../contexts';
import { FormHelperText } from '@material-ui/core';

function CustomNoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customNoOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const CustomInput = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

class CustomControl extends Component {
  render() {
    const { classes, error } = this.props.selectProps;
    const label = this.props.selectProps.label
      ? this.props.selectProps.label
      : 'Office';

    return (
      <FormControl
        fullWidth
        className={classes.formControl}
        variant='outlined'
        margin='normal'
        error={error ? true : false}
      >
        <InputLabel
          variant='outlined'
          className={classes.label}
          animated='false'
          shrink={true}
          ref={ref => {
            this.labelRef = ReactDOM.findDOMNode(ref);
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          focused='true'
          fullWidth
          labelWidth={100}
          notched={true}
          margin='dense'
          children={this.props.children}
          inputComponent={CustomInput}
          inputProps={{
            className: classes.input,
            inputRef: this.props.innerRef,
            children: this.props.children,
            ...this.props.innerProps
          }}
        />
        <FormHelperText>{error ? error : null}</FormHelperText>
      </FormControl>
    );
  }
}

function CustomOption(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function CustomPlaceholder(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.customPlaceholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomSingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.customSingleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function CustomValueContainer(props) {
  return (
    <div className={props.selectProps.classes.customValueContainer}>
      {props.children}
    </div>
  );
}

function CustomMultiValue(props) {
  const chipClass = props.selectProps.classes.chip;
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={
        chipClass
          ? chipClass
          : '' + props.isFocused
          ? props.selectProps.classes.chipFocused
          : ''
      }
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

const CustomMenu = ({ selectProps, innerProps, children }) => {
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
};

const components = {
  Control: CustomControl,
  Menu: CustomMenu,
  MultiValue: CustomMultiValue,
  NoOptionsMessage: CustomNoOptionsMessage,
  Option: CustomOption,
  Placeholder: CustomPlaceholder,
  SingleValue: CustomSingleValue,
  ValueContainer: CustomValueContainer
};

const styles = theme => ({
  root: {
    display: 'flex'
  },
  base: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: '10px 14px',
    paddingRight: '0px'
  },
  customValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  customNoOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  customSingleValue: {
    fontSize: 16
  },
  customPlaceholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    paddingLeft: '14px'
  },
  paper: {
    position: 'relative',
    marginTop: '-25px',
    left: 0,
    right: 0
  },
  label: {
    backgroundColor: theme.palette.background.default,
    paddingRight: '5px',
    paddingLeft: '5px'
  }
});

const OfficeFilteredSelect = ({
  onChange,
  classes,
  theme,
  label,
  error,
  offering
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [offices, setOffices] = useState([]);
  const [ready, setReady] = useState(false);
  const [currentlySelectedOffice, setCurrentlySelectedOffice] = useState(null);

  useEffect(() => {
    const getOffices = async () => {
      let snapshot;
      if (!offering) {
        snapshot = await firebase.firestore().collection('clients').get();
      } else {
        snapshot = await firebase
          .firestore()
          .collection('clients')
          .where(`offerings.${offering}`, '==', true)
          .get();
      }

      return snapshot.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
    };

    getOffices().then(offices => {
      let previouslySelectedOfficeId;
      if (previouslySelectedOfficeId) {
        previouslySelectedOfficeId = offices.find(
          office => office.id === previouslySelectedOfficeId
        );
      }
      setOffices(offices);
      setReady(true);
      setCurrentlySelectedOffice(previouslySelectedOfficeId);
    });
  }, [firebase, offering, ready]);

  const getOptionLabel = option => {
    return option.officeInformation.name;
  };

  const getOptionValue = option => {
    return option.id;
  };

  const filterOptions = ({ label, value, data }, query) => {
    if (query.length < 3) {
      return false;
    }

    const lcLabel = label.toLowerCase();
    const lcQuery = query.toLowerCase();
    if (lcLabel.includes(lcQuery)) {
      return true;
    }
    return false;
  };

  const noOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < 3) {
      return <span>Type at least 3 characters to populate menu</span>;
    } else {
      return <span>No results found</span>;
    }
  };

  const handleChange = office => {
    onChange(office);
    setCurrentlySelectedOffice(office);
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };

  return (
    <div className={classes.root}>
      <FilteredSelect
        placeholder={'Search Offices...'}
        className={classes.base}
        classes={classes}
        styles={selectStyles}
        options={offices}
        onChange={handleChange}
        components={components}
        value={currentlySelectedOffice}
        isLoading={!ready}
        isDisabled={!ready}
        isClearable={true}
        filterOption={filterOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        noOptionsMessage={noOptionsMessage}
        label={label}
        error={error}
      ></FilteredSelect>
    </div>
  );
};

OfficeFilteredSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default compose(
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(OfficeFilteredSelect);
