import _ from 'lodash';
import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import resolvePath from '../../../services/helpers/resolvePath';
import Select from '@material-ui/core/Select';
import theme from '../../../services/theme';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

class FilterFieldSelect extends Component {
  state = { opts: null };

  componentDidMount() {
    const { filter } = this.props;

    if (filter.options instanceof Function) {
      filter.options().then(opts => this.setState({ opts }));
    }
  }

  render() {
    const { filter, onSelect, selected, arrayContains } = this.props;
    const {
      key,
      label,
      options,
      optionKey,
      optionText,
      optionValue,
      optionValBool
    } = filter;
    const { opts } = this.state;
    return (
      <Field
        name={key}
        label={label}
        disabled={options instanceof Function ? (opts ? false : true) : false}
        component={renderSelectField}
        onChange={e => {
          optionValBool
            ? onSelect([e.target.value, '==', true])
            : onSelect([
                selected,
                arrayContains ? 'array-contains' : '==',
                e.target.value
              ]);
        }}
      >
        <option value={null}></option>
        {options instanceof Function
          ? renderOptions(opts, optionKey, optionText, optionValue)
          : options &&
            renderOptions(options, optionKey, optionText, optionValue)}
      </Field>
    );
  }
}

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <div style={{ width: '100%' }}>
    <FormControl
      variant='outlined'
      style={{
        margin: '8px auto 0px auto',
        width: '90%',
        display: 'block',
        '&:hover': {
          borderColor: theme.palette.text.primary
        }
      }}
      error={touched && error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        native
        input={
          <OutlinedInput
            style={{ width: '100%' }}
            labelWidth={Math.pow(label.length, 2) + 10}
          />
        }
        children={children}
        {...custom}
        {...input}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  </div>
);

const renderOptions = (options, optionKey, optionText, optionValue) => {
  return _.map(options, option => {
    return (
      <option key={option[optionKey]} value={option[optionValue]}>
        {optionText.includes('.')
          ? resolvePath(option, optionText)
          : optionText instanceof Array
          ? `${option[optionText[0]]} ${option[optionText[1]]}`
          : option[optionText]}
      </option>
    );
  });
};

export default compose(
  connect((state, { filter: { key, optionValBool }, selected, where }) => ({
    initialValues: optionValBool
      ? { [key]: selected === key ? null : selected }
      : { [key]: where && where[2] }
  })),
  reduxForm({ form: 'FilterFieldSelectForm', enableReinitialize: false }),
  withStyles(null, { withTheme: true })
)(FilterFieldSelect);
