import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { FieldSelect } from '../../../components/Fields';
import { OFFERINGS as serviceOfferingOptions } from '../../../services/options';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  dialogEditTeamServiceClick,
  dialogEditTeamServiceItem
} from '../../../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

const DialogEditTeamService = props => {
  const {
    classes,
    item,
    fullScreen,
    firestore,
    open,
    dialogEditTeamServiceItem,
    dialogEditTeamServiceClick
  } = props;
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onClose = () => {
    setLoadingAfterSubmit(false);
    dialogEditTeamServiceClick(!open);
    dialogEditTeamServiceItem(null);
  };

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    //Need to Refactor onSubmit When FieldArray is used as information will be in array form by default.
    const serviceOfferingArray = [];
    const { serviceOffering } = values;
    serviceOfferingArray.push(serviceOffering);
    const {
      key,
      data: { id }
    } = item;
    const ref = { collection: 'teams', doc: id };
    try {
      await firestore.update(ref, { [key]: serviceOfferingArray });
      onClose();
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth='md'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {item && item.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentTeamLead}>
        <Formik
          initialValues={{
            serviceOffering: item && item.data.serviceOffering[0]
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              {item ? (
                <Field
                  name='serviceOffering'
                  label='Service Offering'
                  options={serviceOfferingOptions}
                  optionKey='key'
                  optionText='name'
                  optionValue='key'
                  margin='none'
                  component={FieldSelect}
                  validate={validations.required}
                />
              ) : //*** Will need to refactor to FieldArray to support multiple inputs properly through Formik */
              // <FieldArray
              //   formData={item && item.data.serviceOffering}
              //   name="serviceOffering"
              //   component={ServiceOfferingArray}
              // />
              null}
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogEditTeamServiceOpen: open, dialogEditTeamServiceItem: item },
  props
) => {
  let initialValues = item ? { [`${item.key}`]: item.data[item.key] } : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditTeamServiceClick,
    dialogEditTeamServiceItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditTeamService);
