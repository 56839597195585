import {
  DIALOG_INDI_ENTRIES_DETAILS_CLICK,
  DIALOG_INDI_ENTRIES_DETAILS_ITEM
} from './types';

export const dialogIndiEntriesDetailsClick = status => ({
  type: DIALOG_INDI_ENTRIES_DETAILS_CLICK,
  payload: status
});

export const dialogIndiEntriesDetailsItem = item => ({
  type: DIALOG_INDI_ENTRIES_DETAILS_ITEM,
  payload: item
});
