/* eslint-disable no-shadow */
import React, { useContext, useEffect } from 'react';
import Error from './Error';
import Header from '../components/Header';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import CreatePassword from './CreatePassword';
import publicIp from 'public-ip';
import SecureRoute from '../components/SecureRoute';
import WebFont from 'webfontloader';
import { LastLocationProvider } from 'react-router-last-location';
import { AppContext } from '../contexts';
import { Switch, Route } from 'react-router-dom';
import { UPDATE } from '../types';

import './styles.css';

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700,900', 'sans-serif']
  }
});

const App = () => {
  const {
    state: { firebase },
    dispatch
  } = useContext(AppContext);

  useEffect(() => {
    const initialize = () => {
      firebase.auth().onAuthStateChanged(async user => {
        try {
          const clientIpAddress = await publicIp.v4();

          if (user) {
            const doc = await firebase
              .firestore()
              .collection('employees')
              .doc(user.uid)
              .get();
            const profile = { ...doc.data(), id: doc.id };

            dispatch({
              type: UPDATE,
              payload: {
                authenticated: true,
                auth: { data: user, isLoaded: true, isEmpty: false },
                clientIpAddress,
                profile: { data: profile, isLoaded: true, isEmpty: false }
              }
            });
          } else {
            dispatch({
              type: UPDATE,
              payload: {
                authenticated: false,
                auth: { data: null, isLoaded: true, isEmpty: true },
                clientIpAddress,
                profile: { data: null, isLoaded: true, isEmpty: true }
              }
            });
          }
        } catch (err) {
          return err;
        }
      });
    };
    initialize();
  }, [firebase, dispatch]);

  return (
    <div className='App'>
      <LastLocationProvider>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route
            path='/reset-password/:oobCode/:redirectUri/:emailParam'
            component={ResetPassword}
          />
          <Route
            path='/reset-password/:oobCode/:emailParam'
            component={ResetPassword}
          />
          <Route
            path='/create-password/:oobCode/:redirectUri/:emailParam'
            component={CreatePassword}
          />
          <Route
            path='/create-password/:oobCode/:emailParam'
            component={CreatePassword}
          />
          <Route exact path='/error/:errorCode' component={Error} />
          <Route exact path='/error/' component={Error} />
          <SecureRoute appRoot path='/' component={Header} />
        </Switch>
      </LastLocationProvider>
    </div>
  );
};

export default App;
