export const styles = theme => ({
  heading: {
    height: theme.spacing.headingHeight,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 0,
    alignItems: 'center',
    position: 'fixed',
    top: theme.spacing.appBarHeightSm,
    width: '100%',
    zIndex: 1100,
    '& span': {
      color: '#ffffff',
      marginRight: 6,
      '& svg': {
        fontSize: 13.6
      }
    }
  },
  headText: {
    color: 'white',
    lineHeight: 1,
    fontSize: '1.55rem',
    '& svg': {
      fontSize: 16,
      marginLeft: 4
    }
  },
  titleText: {
    display: 'inline-block',
    marginTop: 6,
    [theme.breakpoints.down('xs')]: {
      width: 120,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
});
