import {
  DIALOG_EDIT_OFFICE_HOURS_CLICK,
  DIALOG_EDIT_OFFICE_HOURS_ITEM
} from '../actions/types';

export const dialogEditOfficeHoursItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_HOURS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeHoursOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_HOURS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeHoursReducer = {
  dialogEditOfficeHoursItem,
  dialogEditOfficeHoursOpen
};
