import {
  DIALOG_EDIT_OFFICE_OFFERINGS_CLICK,
  DIALOG_EDIT_OFFICE_OFFERINGS_ITEM
} from './types';

export const dialogEditOfficeOfferingsClick = status => ({
  type: DIALOG_EDIT_OFFICE_OFFERINGS_CLICK,
  payload: status
});

export const dialogEditOfficeOfferingsItem = item => ({
  type: DIALOG_EDIT_OFFICE_OFFERINGS_ITEM,
  payload: item
});
