import _ from 'lodash';
import React, { useContext, useState } from 'react';
import DataTable from '../../../../components/DataTable';
import DialogAddClientGroup from './components/DialogAddClientGroup';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Add } from '@material-ui/icons';
import { AppContext } from '../../../../contexts';
import { IconButton, Tooltip } from '@material-ui/core';
import { clientGroupColumns } from './columns';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';

const OfficesClientGroup = props => {
  const { history, location, route, tabId } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    state: { profile }
  } = useContext(AppContext);
  const open = Boolean(anchorEl);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const createGroups = t(route, 'metadata.permissions.create').safeArray || [];
  const userAllowedToAddClientGroup =
    _.intersection(userGroups, createGroups).length > 0;
  const addClientGroupDialogOpen =
    queryString.parse(location.search.slice(1)).addClientGroupDialogOpen ===
    'true';
  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <DataTable
        {...props}
        collection='clientGroups'
        columns={clientGroupColumns}
        clearSearchPath={`/offices${
          tabId && tabId !== 'undefined' ? '/' + tabId : ''
        }`}
        sceneUri='clientGroup'
        title='Client Groups'
        onRowClickLink={(data, pathname) =>
          `/clientGroup/${data.id}/details?pathname=${pathname}`
        }
        tableActionIcons={[
          {
            render: key =>
              userAllowedToAddClientGroup ? (
                <span key={key}>
                  <Tooltip title='Add Client Group'>
                    <IconButton
                      variant='contained'
                      color='primary'
                      aria-label='Add Client Group'
                      onClick={() => {
                        const pathname = location.pathname;
                        const param =
                          location.search === ''
                            ? '?addClientGroupDialogOpen=true'
                            : '&addClientGroupDialogOpen=true';
                        history.push(`${pathname}${location.search}${param}`);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </span>
              ) : null
          }
        ]}
        tableFilterable={true}
        filterData={{
          anchorEl,
          open,
          handleClose: handlePopoverClose
        }}
      />
      {userAllowedToAddClientGroup ? (
        <DialogAddClientGroup
          firestoreCollection='clientGroups'
          history={history}
          location={location}
          onClose={() => {
            let searchObj = queryString.parse(location.search.slice(1));
            delete searchObj.addClientGroupDialogOpen;
            history.push(
              `${location.pathname}?${queryString.stringify(searchObj)}`
            );
          }}
          openDialog={addClientGroupDialogOpen}
        />
      ) : null}
    </>
  );
};

OfficesClientGroup.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tabId: PropTypes.string.isRequired
};

export default withRouter(OfficesClientGroup);
