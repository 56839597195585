import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import FieldText from '../../../components/FieldText';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import {
  dialogEditTeamNameClick,
  dialogEditTeamNameItem
} from '../../../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

const DialogEditTeamName = props => {
  const {
    classes,
    item,
    fullScreen,
    firestore,
    open,
    dialogEditTeamNameItem,
    dialogEditTeamNameClick
  } = props;
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const onClose = () => {
    setLoadingAfterSubmit(false);
    dialogEditTeamNameClick(!open);
    dialogEditTeamNameItem(null);
  };

  const onSubmit = async values => {
    setLoadingAfterSubmit(true);
    const { name } = values;
    const {
      key,
      data: { id }
    } = item;
    const ref = { collection: 'teams', doc: id };
    try {
      await firestore.update(ref, { [key]: name });
      onClose();
    } catch (e) {
      console.log(e);
      onClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth='md'
      className={classes.dialog}
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            Edit {item && item.label}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent className={classes.dialogContentTeamLead}>
        <Formik
          initialValues={{ name: item && item.data.name }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={classes.form}>
              {item ? (
                <Field
                  className={classes.dialogTextField}
                  name='name'
                  label='Team Name'
                  component={FieldText}
                  variant='outlined'
                  validate={validations.required}
                />
              ) : null}
              <div className={classes.dialogButtonContainer}>
                <Button
                  size='large'
                  variant='outlined'
                  color='secondary'
                  className={classes.dialogButton}
                  type='submit'
                >
                  {loadingAfterSubmit ? (
                    <Loader
                      type='Oval'
                      color='#055f5b'
                      height='13'
                      width='13'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (
  { dialogEditTeamNameOpen: open, dialogEditTeamNameItem: item },
  props
) => {
  let initialValues = item ? { [`${item.key}`]: item.data[item.key] } : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditTeamNameClick,
    dialogEditTeamNameItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditTeamName);
