import {
  DIALOG_EDIT_TEAM_NAME_CLICK,
  DIALOG_EDIT_TEAM_NAME_ITEM
} from '../actions/types';

export const dialogEditTeamNameItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_NAME_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditTeamNameOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TEAM_NAME_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditTeamNameReducer = {
  dialogEditTeamNameItem,
  dialogEditTeamNameOpen
};
