import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Add, Search } from '@material-ui/icons';
import {
  Paper,
  Grid,
  withStyles,
  ButtonBase,
  InputBase,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { AppContext } from '../../../../contexts';
import { styles } from './styles';
import DialogAddTeamOffices from './components/DialogAddTeamOffices';
import DialogViewRemoveTeamOffices from './components/DialogViewRemoveTeamOffices';
import PropTypes from 'prop-types';

const TeamOffices = ({ classes, team }) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [teamOffices, setTeamOffices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [officesRetrieved, setOfficesRetrieved] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [viewRemoveOffice, setViewRemoveOffice] = useState(null);

  useEffect(() => {
    const getTeamOffices = async () => {
      const officesQuerySnapshot = await firebase
        .firestore()
        .collection('clients')
        .where(`teams.${team.serviceOffering[0]}`, 'array-contains', team.id)
        .get();
      const officesDocs = [];
      officesQuerySnapshot.forEach(doc => {
        officesDocs.push({ ...doc.data(), id: doc.id });
      });
      setTeamOffices(officesDocs);
      setOfficesRetrieved(true);
    };

    if (!officesRetrieved && team) {
      getTeamOffices();
    }

    if (searchTerm) {
      const searchResults = [];
      teamOffices.forEach(o => {
        if (
          o.officeInformation.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          searchResults.push(o);
        }
      });
      setOffices(searchResults);
    } else {
      setOffices(teamOffices);
    }
  }, [firebase, team, teamOffices, officesRetrieved, searchTerm]);

  const renderSearchBar = () => (
    <div className={classes.searchFieldContainer}>
      <span className={classes.searchIcon}>
        <Search />
      </span>
      <InputBase
        className={classes.searchField}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder={'Search Offices...'}
        value={searchTerm ?? ''}
      />
    </div>
  );

  const renderTableHeaderRow = () => (
    <Grid container spacing={24} className={classes.headerRow}>
      <Grid item xs={6} sm={3} md={3}>
        <Typography
          variant='button'
          className={`${classes.headerRowText} ${classes.clickableText}`}
        >
          Office Name
        </Typography>
      </Grid>
    </Grid>
  );

  const renderTableBody = () => {
    return (
      <div className={classes.tableContainer}>
        {offices[0] ? (
          offices.map((o, i) => {
            return (
              <div key={o.id}>
                <ButtonBase
                  className={classes.buttonBase}
                  onClick={() => setViewRemoveOffice(o)}
                >
                  <Grid container spacing={24} className={classes.tableRow}>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={3}
                      className={`${classes.cell} ${classes.firstCell}`}
                      style={{ paddingLeft: 12 }}
                    >
                      <Typography
                        variant='body1'
                        className={`
                          ${classes.tableText}
                          ${classes.rowIndex}
                        `}
                      >
                        {i + 1}
                      </Typography>
                      <Typography
                        variant='body1'
                        className={`${classes.tableText} ${classes.firstColumn}`}
                      >
                        {o.officeInformation.name ?? ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </div>
            );
          })
        ) : (
          <Typography className={classes.messageText} variant='body1'>
            No offices have been assigned to this team
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={24} style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <Paper className={classes.offices} elevation={4}>
              <div className={classes.titleContainer}>
                {renderSearchBar()}
                <Tooltip title='Add Office'>
                  <IconButton
                    variant='contained'
                    color='primary'
                    aria-label='Add Office'
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </div>
              {renderTableHeaderRow()}
              {!officesRetrieved ? (
                <div className={classes.loaderContainer}>
                  <Loader type='Oval' color='#696969' height='30' width='30' />
                </div>
              ) : (
                renderTableBody()
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
      {open ? (
        <DialogAddTeamOffices
          open={open}
          setOpen={setOpen}
          team={team}
          setOfficesRetrieved={setOfficesRetrieved}
        />
      ) : null}
      {viewRemoveOffice ? (
        <DialogViewRemoveTeamOffices
          office={viewRemoveOffice}
          team={team}
          setOffice={setViewRemoveOffice}
          setOfficesRetrieved={setOfficesRetrieved}
        />
      ) : null}
    </>
  );
};

TeamOffices.propTypes = {
  classes: PropTypes.object.isRequired,
  team: PropTypes.object,
  sitemapPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(TeamOffices);
