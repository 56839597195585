import {
  EMPLOYEES_ADD,
  EMPLOYEES_DELETE,
  EMPLOYEES_EDIT,
  EMPLOYEES_INITIALIZE,
  EMPLOYEES_INITIALIZE_BY_UID,
  EMPLOYEES_INITIALIZE_BY_USERNAME,
  EMPLOYEES_SEARCH
} from '../actions/types';

const employees = (state = null, action) => {
  switch (action.type) {
    case EMPLOYEES_ADD:
      return action.payload;
    case EMPLOYEES_DELETE:
      return action.payload;
    case EMPLOYEES_EDIT:
      return action.payload;
    case EMPLOYEES_INITIALIZE:
      return action.payload;
    case EMPLOYEES_SEARCH:
      return action.payload;
    default:
      return state;
  }
};

const employeesByUid = (state = null, action) => {
  switch (action.type) {
    case EMPLOYEES_INITIALIZE_BY_UID:
      return action.payload;
    default:
      return state;
  }
};

const employeesByUsername = (state = null, action) => {
  switch (action.type) {
    case EMPLOYEES_INITIALIZE_BY_USERNAME:
      return action.payload;
    default:
      return state;
  }
};

export const EmployeesReducer = {
  employees,
  employeesByUid,
  employeesByUsername
};
