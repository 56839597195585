import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EditButton from './EditButton';
import {
  dialogEditTeamServiceItem,
  dialogEditTeamServiceClick
} from '../../../actions';
import { styles } from './styles';
import { offeringsObject as serviceOptions } from '../../../services/options';

const TeamServiceData = ({
  classes,
  data,
  label,
  name,
  showEdit,
  dialogEditTeamServiceClick,
  dialogEditTeamServiceItem
}) => {
  const onClick = () => {
    dialogEditTeamServiceItem({ key: name, label, data });
    dialogEditTeamServiceClick(true);
  };

  const formatData = data => {
    data =
      data &&
      data.map(service => {
        service = serviceOptions[service];
        return service;
      });
    return typeof data === 'object' ? data.join(', ') : data;
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>{data && formatData(data[name])}</div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditTeamServiceItem, dialogEditTeamServiceClick }),
  withStyles(styles, { withTheme: true })
)(TeamServiceData);
