import {
  DIALOG_EDIT_OFFICE_INFO_CLICK,
  DIALOG_EDIT_OFFICE_INFO_ITEM
} from '../actions/types';

export const dialogEditOfficeInfoItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_INFO_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeInfoOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_INFO_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeInfoReducer = {
  dialogEditOfficeInfoItem,
  dialogEditOfficeInfoOpen
};
