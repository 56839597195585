import React, { useState, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import helpers from '../../../../../helpers';
import moment from 'moment-timezone';
import { CALLFORCE_TZ } from '../../../../../services/constants';
import { AppContext } from '../../../../../contexts';
import { Link } from 'react-router-dom';
import {
  FieldCheckbox,
  FieldPhone,
  FieldText,
  FieldSelect
} from '../../../../../components/Fields';
import { phone, required } from '../../../../../validation';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  form: {
    marginTop: 12,
    marginBottom: 25
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },
  actionButtons: {
    position: 'absolute',
    maxWidth: '165px',
    right: '25px'
  },
  errorMessage: {
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 4
    }
  },
  loadingMessage: {
    fontStyle: 'italic',
    paddingRight: 8
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  reloOption: {
    height: 145,
    border: 'solid #cbcbcb 1px',
    borderRadius: '8px',
    width: '100%-300px',
    padding: '15px',
    marginBottom: 12,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  },
  optionItem: {
    marginBottom: 12
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const ReloItemForm = ({
  classes,
  offices,
  appointmentTypes,
  addReloRedirect
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [patients, setPatients] = useState([]);
  const [checkedPatients, setCheckedPatients] = useState(false);
  const [createNewEntry, setCreateNewEntry] = useState(false);
  const [showSpinnerWhenLoading] = useState(true);
  const {
    state: { firebase, profile }
  } = useContext(AppContext);

  const onSubmit = async (values, { setSubmitting }) => {
    const office = offices.find(office => office.id === values.office);
    values.officeName = office.officeInformation.name;
    values.officeTimeone = office.officeInformation.timezone;
    values.patientPhone = values.patientPhone.replace(/[^0-9]/g, '');
    values.createdTime = firebase.firestore.Timestamp.now().toDate();
    values.createdBy = profile.data.id;
    values.createdByName = profile.data.displayName;
    values.contacts = [];
    values.status = 'new';
    values.result = null;

    setError(null);
    setLoading('Saving Entry; Please Keep Browser Window Open');

    try {
      let newRelo = await firebase.firestore().collection('relos').add(values);

      addReloRedirect(newRelo.id);
    } catch (e) {
      console.error(e);
      setError('Failed To Save RELO');
      setLoading(null);
      setSubmitting(false);
    }
  };

  const messageArea = () => {
    if (error) {
      return (
        <Typography className={classes.errorMessage} variant='body1'>
          {error}
        </Typography>
      );
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          {showSpinnerWhenLoading ? (
            <Loader type='Oval' color='#696969' height='12' width='12' />
          ) : null}
          <Typography className={classes.loadingMessage} variant='body1'>
            {loading}
          </Typography>
        </div>
      );
    }

    return <Typography />;
  };

  const validations = {
    patientPhone: v => helpers.composeFieldValidation(v, [required, phone]),
    patientName: required,
    office: required
  };

  const validatePhone = async value => {
    const phoneNumber = value.replace(/\D/g, '');
    if (phoneNumber.length < 10) {
      setCheckedPatients(false);
    }
    if (!validations.patientPhone(value) && !checkedPatients) {
      try {
        const patientsData = await firebase
          .firestore()
          .collection('relos')
          .where('patientPhone', '==', phoneNumber)
          .get();

        const patientsDocs = [];
        patientsData.forEach(doc => {
          patientsDocs.push({ ...doc.data(), id: doc.id });
        });
        setPatients(patientsDocs);
        setCheckedPatients(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const renderNewReloEntry = (isSubmitting, values) => {
    return (
      <>
        <div className={classes.row}>
          <Field name='patientName' validate={validations.patientName}>
            {({ form, field }) => {
              return (
                <FieldText
                  form={form}
                  field={field}
                  label='Patient Name'
                  variant='outlined'
                />
              );
            }}
          </Field>
        </div>
        <Field name='patientId'>
          {({ form, field }) => (
            <FieldText
              form={form}
              field={field}
              label='Patient ID'
              variant='outlined'
            ></FieldText>
          )}
        </Field>
        <Field
          name='office'
          loading={offices.length === 0}
          disabled={offices.length === 0 || loading !== null}
          label='Office'
          options={offices}
          optionKey='id'
          optionText='officeInformation.name'
          optionValue='id'
          margin='none'
          component={FieldSelect}
          validate={validations.office}
        />
        <Field
          name='appointmentType'
          loading={appointmentTypes.length === 0}
          disabled={appointmentTypes.length === 0 || loading !== null}
          label='Appointment Type'
          options={_.sortBy(appointmentTypes, 'name')}
          optionKey='id'
          optionText='name'
          optionValue='key'
          margin='none'
          component={FieldSelect}
          validate={validations.appointmentType}
        />
        <FieldCheckbox
          name='newPatient'
          label='New Patient'
          disabled={loading !== null}
          checked={!!values.newPatient}
        />
        <div className={classes.actions}>
          {messageArea()}
          <Button
            className={classes.actionButtons}
            color='primary'
            type='submit'
            disabled={isSubmitting || loading !== null}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const formatCreatedTime = patient => {
    return moment
      .unix(patient.createdTime.seconds)
      .tz(CALLFORCE_TZ)
      .format('MMM D, YYYY, h:mm a');
  };

  const renderPatientOptions = () => {
    return (
      <>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>Existing RELOs</Typography>
        </div>
        {patients.map((p, key) => (
          <div key={key} className={classes.reloOption}>
            <Link to={`/relo/${p.id}/details?pathname=/data/relos`}>
              <div className={classes.optionItem}>Patient: {p.patientName}</div>
              <div className={classes.optionItem}>
                Created Time: {formatCreatedTime(p)}
              </div>
              <div className={classes.optionItem}>Office: {p.officeName}</div>
              <div className={classes.optionItem}>
                Contacts: {p.contacts ? p.contacts.length : 0}
              </div>
              <div className={classes.optionItem}>
                Appointment Type: {p.appointmentType}
              </div>
            </Link>
          </div>
        ))}
      </>
    );
  };

  return (
    <Formik
      initialValues={{ newPatient: true }}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={classes.form}>
          <Field
            name='patientPhone'
            label='Patient Phone'
            variant='outlined'
            component={FieldPhone}
            disabled={loading !== null}
            validate={validatePhone}
          />
          {patients[0] ? renderPatientOptions() : null}
          {checkedPatients && !createNewEntry && patients[0] ? (
            <div className={classes.row}>
              <Button
                className={classes.actionButtons}
                color='secondary'
                type='button'
                onClick={e => {
                  setPatients([]);
                  setCreateNewEntry(true);
                }}
              >
                Create New Entry
              </Button>
            </div>
          ) : null}
          {checkedPatients && !patients[0]
            ? renderNewReloEntry(isSubmitting, values)
            : null}
        </Form>
      )}
    </Formik>
  );
};

ReloItemForm.propTypes = {
  classes: PropTypes.object,
  addReloRedirect: PropTypes.func.isRequired,
  offices: PropTypes.array.isRequired,
  appointmentTypes: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(ReloItemForm);
