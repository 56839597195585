import { UPDATE } from './types';

export const AppInit = {
  authenticated: false,
  auth: { data: null, isLoaded: false, isEmpty: true },
  profile: { data: null, isLoaded: false, isEmpty: true }
};

export const AppReducer = (state, action) => {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
