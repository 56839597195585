export const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#fafafa'
  },
  cardContainer: {
    paddingTop: '25px'
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 350
    },
    width: '90%',
    position: 'relative',
    padding: '0px',
    margin: '0px auto'
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '0 15px'
  },
  content: {
    padding: '0px',
    textAlign: 'center'
  },
  header: {
    height: '60px',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px 0px 0px'
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    display: 'block',
    width: '60%'
  }
});
