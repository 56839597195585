import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ClientGroupInfoData from './ClientGroupInfoData';
import { styles } from './styles';

const ClientGroupInformation = ({
  classes,
  clientGroupData,
  allowedToEdit
}) => (
  <div className={classes.clientGroupInfoRoot}>
    <ClientGroupInfoData
      data={clientGroupData}
      label='UID'
      name='id'
      showEdit={false}
    />
    <ClientGroupInfoData
      data={clientGroupData}
      label='Name'
      name='name'
      showEdit={allowedToEdit}
    />
  </div>
);

const mapStateToProps = (state, { clientGroup }) => {
  let clientGroupData = {
    name: clientGroup ? clientGroup.name : null,
    status: '-',
    id: clientGroup ? clientGroup.id : null
  };

  return { clientGroupData };
};

ClientGroupInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  clientGroupData: PropTypes.object
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(ClientGroupInformation);
