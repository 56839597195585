export const slideUpTransitionOptions = {
  transitionName: 'fade-slide-up',
  transitionAppear: true,
  transitionAppearTimeout: 1500,
  transitionEnter: false,
  transitionLeave: false
};

export const transitionOptions = {
  transitionName: 'fade',
  transitionAppear: true,
  transitionAppearTimeout: 1500,
  transitionEnter: false,
  transitionLeave: false
};
