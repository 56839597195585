import {
  DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK,
  DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM
} from '../actions/types';

export const dialogEditOfficeOpsStatusItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_OPSSTATUS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeOpsStatusOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_OPSSTATUS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeOpsStatusReducer = {
  dialogEditOfficeOpsStatusItem,
  dialogEditOfficeOpsStatusOpen
};
