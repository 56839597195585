import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const CustomCheckbox = field => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <Checkbox
      checked={field.input.value}
      onChange={(e, change) => field.input.onChange(change)}
      disabled={field.disabled ? field.disabled : false}
    />
    <Typography variant='body1'>{field.label}</Typography>
  </span>
);

export default CustomCheckbox;
