import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const EditButton = ({ classes, label, onClick }) => (
  <div className={classes.dataEdit} onClick={onClick}>
    {label ? label : 'Edit'}
  </div>
);

export default withStyles(styles, { withTheme: true })(EditButton);
