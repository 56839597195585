import moment from 'moment-timezone';
import iconNames from './icon_names';
import { CALLFORCE_TZ } from '../../../../services/constants';

const mistakeColumns = [
  {
    index: 1,
    key: 'mistake',
    essential: true,
    text: 'Mistake',
    spacing: { xs: 6, sm: 3 },
    formatData: doc => {
      let { mistake } = doc;
      if (mistake && !mistake.mistakeHandled) {
        mistake.category += iconNames.mistakeNotYetHandledIconName;
      }
      if (mistake && mistake.mistakeHandled) {
        mistake.category += iconNames.mistakeHandledIconName;
      }
      return mistake.category;
    },
    icon: false,
    sortable: false,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'officeName',
    essential: true,
    text: 'Office',
    spacing: { xs: 6, sm: 3 },
    icon: false,
    sortable: false,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 3,
    key: 'reportedOn',
    essential: false,
    text: 'Reported On',
    spacing: { xs: 3, sm: 3 },
    formatData: doc => {
      const reportedOn =
        doc.mistake && doc.mistake.reportedOn && doc.mistake.reportedOn.seconds
          ? doc.mistake.reportedOn.seconds
          : 'No Reported Date';

      return `
            ${moment
              .unix(reportedOn)
              .tz(CALLFORCE_TZ)
              .format('MMM D, YYYY, h:mm a')}
          `;
    },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'desc'
  },
  {
    index: 4,
    key: 'createdByName',
    essential: false,
    text: 'Created By',
    spacing: { xs: 3, sm: 3 },
    icon: false,
    sortable: false,
    defaultSortColumn: false,
    defaultSort: 'asc'
  }
];

export default mistakeColumns;
