import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EditButton from './EditButton';
import {
  dialogEditTeamLeadItem,
  dialogEditTeamLeadClick
} from '../../../actions';
import { styles } from './styles';

const TeamInfoData = ({
  classes,
  data,
  label,
  name,
  showEdit,
  dialogEditTeamLeadClick,
  dialogEditTeamLeadItem,
  relevantEmployeeLead,
  membersKey
}) => {
  const onClick = () => {
    dialogEditTeamLeadItem({ key: name, membersKey, label, data });
    dialogEditTeamLeadClick(true);
  };

  const formatName = () => {
    const { firstName, lastName } = relevantEmployeeLead;
    return `${firstName} ${lastName}`;
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        {data && relevantEmployeeLead && formatName()}
      </div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditTeamLeadClick, dialogEditTeamLeadItem }),
  withStyles(styles, { withTheme: true })
)(TeamInfoData);
