import _ from 'lodash';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import SelectField from '../../../components/SelectField';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfficeOpsStatusClick,
  dialogEditOfficeOpsStatusItem
} from '../../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { OPSBILLINGSTATUSES } from '../../../services/options';
import { styles } from './styles';
import { validations } from '../../../services/validation';

class DialogEditOfficeOpsStatus extends Component {
  state = {
    loadingAfterSubmit: false
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const { item } = this.props;

    if (!_.isEqual(item, nextProps.item) && nextProps.item) {
      this.setState(
        {
          officeName: nextProps.item.data.name
        },
        () => {
          this.setState({
            validOfficeName: (value, allValues) =>
              value && allValues.officeName !== this.state.officeName
                ? 'Office name does not match'
                : undefined
          });
        }
      );
    }
  }

  onClose = () => {
    this.props.dialogEditOfficeOpsStatusClick(!this.props.open);
    this.props.dialogEditOfficeOpsStatusItem(null);
  };

  onSubmit = async values => {
    const { firestore, item } = this.props;
    const ref = { collection: 'clients', doc: item.data.id };
    let opsStatuses = values.opsStatuses;

    try {
      await firestore.update(ref, { opsStatuses });
      this.setState({ loadingAfterSubmit: false }, () => this.onClose());
    } catch (e) {
      console.log(e);
      this.setState({ loadingAfterSubmit: false });
    }
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin='normal'
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...field.input}
      />
    );
  };

  render() {
    const {
      classes,
      fullScreen,
      handleSubmit,
      item,
      open,
      statusFormValue
    } = this.props;
    const { loadingAfterSubmit, validOfficeName } = this.state;

    const dialogText = {
      billing: `Doing so will immediately effect the portal of ${item &&
        item.data.name}. Status of 'overdue' will
  result in a toast notification that the office will see at the top
  of their dashboard, status of 'pending suspension' will result in
  a full page modal whenever the client logs into the portal to
  inform them that they are close to having our services suspended,
  and 'current' will incur no changes.`
    };

    // required for name in component below.
    const opsKey = item && item.key ? `opsStatuses.${item.key}` : ' ';

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogWarningTitleArea}>
          <div className={classes.dialogWarningTitleRow}>
            <div className={classes.dialogWarningTextArea}>
              <div className={classes.dialogWarningTitleTextArea}>
                <WarningIcon />
                <Typography variant='h6' className={classes.dialogTitle}>
                  Change {item && item.label}?
                </Typography>
              </div>
            </div>
            <Tooltip title='Close' className={classes.dialogCloseButton}>
              <IconButton
                color='inherit'
                aria-label='Close'
                onClick={this.onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.dialogWarningSubtitleTextArea}>
            <Typography variant='h6' className={classes.dialogWarningSubtitle}>
              {dialogText[item && item.key]}
            </Typography>
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          <form
            className={classes.dialogForm}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name={opsKey}
              component={SelectField}
              selected={statusFormValue === null ? false : true}
              label={`Office ${item && item.label}`}
              type='text'
              options={_.sortBy(_.mapKeys(OPSBILLINGSTATUSES, 'key'), 'key')}
              optionKey='key'
              optionText='value'
              optionValue='key'
              margin='none'
              textFieldContainerClass={classes.dialogTextField}
              validate={[validations.required]}
            />
            <Field
              name='officeName'
              component={this.renderTextField}
              label='Enter Office Name'
              type='text'
              className={classes.dialogTextField}
              validate={[validations.required, validOfficeName]}
            />
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DialogEditOfficeOpsStatus');
  const {
    dialogEditOfficeOpsStatusOpen: open,
    dialogEditOfficeOpsStatusItem: item
  } = state;

  let initialValues = { officeName: null };
  if (item && item.data && item.data.opsStatuses) {
    initialValues = { opsStatuses: item.data.opsStatuses };
  }

  return {
    open,
    initialValues,
    item,
    statusFormValue: selector(state, 'opsStatuses.billing'),
    officeNameFormValue: selector(state, 'officeName')
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeOpsStatusClick,
    dialogEditOfficeOpsStatusItem
  }),
  reduxForm({ form: 'DialogEditOfficeOpsStatus', enableReinitialize: true }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeOpsStatus);
