export const styles = theme => ({
  dataRow: {
    display: 'flex',
    marginBottom: 24
  },
  dataColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  stripeLink: {
    color: '#4CAF50',
    fontSize: '12px',
    'a:visited': {
      color: 'blue'
    }
  },
  dataLabel: {
    width: '20%',
    marginRight: 5,
    '& > div:first-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    [theme.breakpoints.down('sm')]: {
      width: '30%'
    }
  },
  dataText: {
    width: '80%',
    color: '#212121',
    '& svg': {
      fontSize: 12,
      marginRight: 4
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  loading: {
    display: 'inline-block',
    height: 15,
    backgroundColor: '#DDD',
    width: '90%'
  },
  editButton: {
    fontSize: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
