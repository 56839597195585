import React from 'react';
import { t } from 'typy';

const PricingArray = ({ office, pricingArray, type, minId, maxId }) => {
  const listSize = t(office, 'officeNotes.recallListSize').safeObject;
  let pricing;

  listSize &&
    pricingArray.forEach(price => {
      if (listSize >= price[minId] && listSize <= price[maxId]) {
        pricing = price[type];
      }
    });

  return (
    <span>
      {listSize ? (
        <span>${parseFloat(pricing).toFixed(2)}</span>
      ) : (
        pricingArray.map((price, index) =>
          index === pricingArray.length - 1 ? (
            <span key={index}>
              ${parseFloat(price[type]).toFixed(2)} ({price[minId]}
              +)
            </span>
          ) : (
            <div key={index}>
              ${parseFloat(price[type]).toFixed(2)} ({price[minId]} -{' '}
              {price[maxId]})
            </div>
          )
        )
      )}
    </span>
  );
};

export default PricingArray;
