import {
  DIALOG_BILLING_ITEM_CLICK,
  DIALOG_BILLING_ITEM,
  DIALOG_BILLING_ITEM_STATUS_CHANGE
} from '../actions/types';

const dialogBillingItemOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_BILLING_ITEM_CLICK:
      return action.payload;
    default:
      return state;
  }
};

const dialogBillingItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_BILLING_ITEM:
      return action.payload;
    default:
      return state;
  }
};

const dialogBillingItemStatusChange = (state = false, action) => {
  switch (action.type) {
    case DIALOG_BILLING_ITEM_STATUS_CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export const DialogBillingItemReducer = {
  dialogBillingItemOpen,
  dialogBillingItem,
  dialogBillingItemStatusChange
};
