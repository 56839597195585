import React, { useState } from 'react';
import {
  Button,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  IconButton
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from 'react-select';
import OfficeFilteredSelect from '../../../../../components/OfficeFilteredSelect';
import { db } from '../../../../../index.js';
import PropTypes from 'prop-types';

const SelectOffices = ({
  FieldArray,
  setFieldValue,
  classes,
  nextPage,
  previousPage,
  name,
  allOffices,
  clientGroups,
  values,
  validations,
  errors
}) => {
  const [clientType, setClientType] = useState('');
  const [clientGroupsRegions, setClientGroupsRegions] = useState([]);

  const valuesCheck = () => {
    if (clientType === 'Client Group') {
      if (
        !values.offices.length ||
        !values.clientGroupName ||
        !values.regionName
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!values.offices.length) {
        return true;
      } else {
        return false;
      }
    }
  };

  const errorsCheck = () => {
    if (errors.offices) {
      return true;
    } else {
      return false;
    }
  };

  const handleClientType = event => {
    const { value } = event.target;
    setClientType(value);
    if (value === 'All Offices') {
      addAllActiveOffices();
    } else {
      clearOfficeValues();
    }
  };

  const addAllActiveOffices = () => {
    const activeOffices = allOffices
      .filter(office => office.status === 'active')
      .map(officeObj => {
        const {
          officeInformation: { name },
          id
        } = officeObj;
        return { name, id };
      });

    values.offices = activeOffices;
  };

  const clearOfficeValues = () => {
    values.offices = [];
  };

  const formikClearRegionName = () => {
    values.regionName = '';
  };

  const formikSetClientGroupName = name => {
    values.clientGroupName = name;
  };

  const formikSetRegionName = region => {
    values.regionName = region;
  };

  const formikClearRegions = () => {
    values.clientGroupsRegions = [];
  };

  const transformClientGroups = options => {
    return options.map(option => {
      const { id, name, offices } = option;
      return { value: { id, offices }, label: name };
    });
  };

  const transformClientGroupsRegions = (
    allClientGroupsRegions,
    allClientGroupOffices
  ) => {
    const allOffices = {
      label: 'All DSO Offices',
      value: allClientGroupOffices
    };
    const transformedClientGroupRegions = [allOffices];
    const allRegionOffices = { label: 'All DSO Region Offices', value: [] };

    for (const region in allClientGroupsRegions) {
      if (allClientGroupsRegions[region].regionOffices.length) {
        allClientGroupsRegions[region].regionOffices.forEach(office => {
          allRegionOffices.value.push(office);
        });
        const clientGroupRegionObj = {
          label: allClientGroupsRegions[region].name,
          value: allClientGroupsRegions[region].regionOffices
        };
        transformedClientGroupRegions.push(clientGroupRegionObj);
      }
    }

    if (allRegionOffices.value.length) {
      transformedClientGroupRegions.push(allRegionOffices);
    }

    return transformedClientGroupRegions;
  };

  const handleClientGroupSelect = async (clientGroupObj, helpers) => {
    const {
      value: { id, offices },
      label
    } = clientGroupObj;
    formikSetClientGroupName(label);
    try {
      const clientGroupRegions = await getRegions(id);
      if (!clientGroupRegions) {
        formikClearRegions();
        clearOfficeValues();
        formikClearRegionName();
        setClientGroupsRegions([]);
        for (const office in offices) {
          helpers.push(offices[office]);
        }
      } else {
        formikClearRegions();
        clearOfficeValues();
        formikClearRegionName();
        const transformedClientGroupRegions = transformClientGroupsRegions(
          clientGroupRegions,
          offices
        );
        values.clientGroupsRegions = transformedClientGroupRegions;
        setClientGroupsRegions(transformedClientGroupRegions);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClientGroupRegionSelect = (region, helpers) => {
    if (values.offices.length) {
      values.offices = [];
    }
    const { value, label } = region;
    formikSetRegionName(label);
    for (const office in value) {
      helpers.push(value[office]);
    }
  };

  const getRegions = async clientGroupId => {
    const officesInRegions = [];
    try {
      const clientGroupDocRegions = await db
        .collection('clientGroups')
        .doc(clientGroupId)
        .collection('regions')
        .get();

      if (clientGroupDocRegions.docs) {
        clientGroupDocRegions.docs.forEach(doc => {
          officesInRegions.push(doc.data());
        });
        return officesInRegions;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const displayOffices = (offices, arrayHelpers) => {
    return offices.map((office, key) =>
      office.name ? (
        <div key={key} className={classes.officeNames}>
          {office.name}
          <IconButton
            color='inherit'
            aria-label={`Remove ${office.name}`}
            className={classes.officeRemovalIcon}
            onClick={() => {
              arrayHelpers.remove(key);
              values.offices.splice(key, 1);
            }}
            component='span'
            disableRipple
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ) : null
    );
  };

  const customStyles = {
    container: provided => ({
      ...provided,
      width: '100%'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      marginTop: '20px'
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '56px',
      width: '50%',
      boxShadow: state.isFocused ? null : null
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0 6px'
    }),
    input: provided => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '30px'
    }),
    menu: provided => ({
      ...provided,
      width: '300px',
      minHeight: '300px',
      height: '300px',
      maxHeight: '300px',
      zIndex: 5
    })
  };

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>
          Select Client Group or Custom Offices
        </Typography>
      </div>
      <FieldArray
        name={name}
        label='Select Offices'
        variant='outlined'
        validate={validations.offices}
        render={arrayHelpers => {
          return (
            <>
              <div className={classes.clientTypeSection}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='clientType'
                    name='clientType'
                    value={clientType}
                    onChange={handleClientType}
                  >
                    <FormControlLabel
                      value={'Client Group'}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
                <Select
                  label='Client Groups'
                  key={`react-select-${clientType}`}
                  styles={customStyles}
                  options={transformClientGroups(clientGroups)}
                  placeholder={
                    values.clientGroupName
                      ? values.clientGroupName
                      : 'Select Client Group'
                  }
                  onChange={value => {
                    setClientType('Client Group');
                    handleClientGroupSelect(value, arrayHelpers);
                  }}
                />
              </div>
              {clientType === 'Client Group' ? (
                <>
                  <div className={classes.sectionTitle}>
                    <Typography variant='subtitle2'>
                      Select Region or All Offices
                    </Typography>
                  </div>
                  <Select
                    label='Region'
                    className={classes.regionSelect}
                    key={'react-select-regionClientGroups'}
                    styles={customStyles}
                    options={
                      values.clientGroupsRegions.length
                        ? values.clientGroupsRegions
                        : clientGroupsRegions
                    }
                    placeholder={
                      values.regionName ? values.regionName : 'Select'
                    }
                    onChange={value =>
                      handleClientGroupRegionSelect(value, arrayHelpers)
                    }
                  />
                </>
              ) : null}
              <div className={classes.clientTypeSection}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='clientType'
                    name='clientType'
                    value={clientType}
                    onChange={handleClientType}
                  >
                    <FormControlLabel
                      value={'Custom Offices'}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
                <div className={classes.officeSelect}>
                  <OfficeFilteredSelect
                    label='Office'
                    placeholder={'Select Offices'}
                    onChange={office => {
                      setClientType('Custom Offices');
                      if (!office) return;
                      const { offices } = values;
                      offices.push({
                        name: `${office.officeInformation.name}`,
                        id: office.id
                      });
                      setFieldValue('offices', offices);
                    }}
                    previouslySelectedOfficeId={null}
                  />
                </div>
              </div>
              {clientType === 'Custom Offices' ? (
                <div className={classes.dialogOfficeList}>
                  {values.offices.length
                    ? displayOffices(values.offices, arrayHelpers)
                    : null}
                </div>
              ) : null}
              <div className={classes.clientTypeSection}>
                <div className={classes.allOffices}>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      aria-label='clientType'
                      name='clientType'
                      value={clientType}
                      onChange={handleClientType}
                    >
                      <FormControlLabel
                        value={'All Offices'}
                        control={<Radio />}
                        label='All Offices'
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </>
          );
        }}
      />
      <div className={classes.sectionActions}>
        <div className={classes.buttonContainer}>
          <Button color='secondary' variant='outlined' type='reset'>
            Cancel
          </Button>
          <Button color='primary' variant='outlined' onClick={previousPage}>
            Back
          </Button>
          <Button
            color='primary'
            variant='outlined'
            onClick={nextPage}
            disabled={valuesCheck() || errorsCheck()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

SelectOffices.propTypes = {
  values: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  clientGroups: PropTypes.array.isRequired,
  FieldArray: PropTypes.func.isRequired,
  validations: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  allOffices: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired
};

export default SelectOffices;
