import {
  DIALOG_EDIT_DRPROFILE_CLICK,
  DIALOG_EDIT_DRPROFILE_ITEM
} from '../actions/types';

export const dialogEditDrProfileOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_DRPROFILE_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditDrProfileItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_DRPROFILE_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const DialogDrProfileReducer = {
  dialogEditDrProfileOpen,
  dialogEditDrProfileItem
};
