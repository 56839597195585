import _ from 'lodash';
import React from 'react';
import Loader from 'react-loader-spinner';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TableRow from './Row';

const DataTableBody = ({
  classes,
  collection,
  columns,
  docs,
  location,
  onRowClickLink,
  page,
  rowsPerPage,
  tableData,
  tableDataError
}) =>
  tableData.isReady ? (
    <Ready
      classes={classes}
      collection={collection}
      columns={columns}
      docs={docs}
      location={location}
      onRowClickLink={onRowClickLink}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  ) : tableData.isLoading ? (
    <Loading classes={classes} />
  ) : tableData.hasError ? (
    <Error classes={classes} error={tableDataError} />
  ) : null;

const Ready = ({
  classes,
  collection,
  columns,
  docs,
  location,
  onRowClickLink,
  page,
  rowsPerPage
}) => {
  if (docs.length > 0) {
    return (
      <div className={classes.root}>
        {docs.map((doc, index) => (
          <TableRow
            key={doc.id}
            collection={collection}
            columns={columns}
            data={doc}
            location={location}
            onRowClickLink={onRowClickLink}
            page={page}
            rowIndex={index}
            rowsPerPage={rowsPerPage}
          />
        ))}
      </div>
    );
  }

  return (
    <Typography className={classes.messageText} variant='body1'>
      No {collection} documents match query
    </Typography>
  );
};

const Loading = ({ classes }) => (
  <div className={classes.loadingRoot}>
    <Loader type='Oval' color='#696969' height='30' width='30' />
  </div>
);

const Error = ({ classes, error }) => (
  <Typography className={classes.messageText} variant='body1'>
    {_.get(
      error,
      'data',
      _.get(error, 'response.data.raw', 'There was an unexpected error')
    )}
  </Typography>
);

const styles = theme => ({
  root: {
    width: '100%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    // TODO: fix to fill all available height
    height: '51vh',
    [theme.breakpoints.down('xs')]: {
      height: '49vh'
    }
  },
  messageText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '8vh',
    fontSize: 14,
    fontStyle: 'italic'
  },
  loadingRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '8vh'
  }
});

export default compose(
  connect(null),
  withStyles(styles, { withTheme: true })
)(DataTableBody);
