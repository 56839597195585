import {
  DIALOG_EDIT_OFFICE_HOURS_CLICK,
  DIALOG_EDIT_OFFICE_HOURS_ITEM
} from './types';

export const dialogEditOfficeHoursClick = status => ({
  type: DIALOG_EDIT_OFFICE_HOURS_CLICK,
  payload: status
});

export const dialogEditOfficeHoursItem = item => ({
  type: DIALOG_EDIT_OFFICE_HOURS_ITEM,
  payload: item
});
