import React from 'react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AndUpIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { Field } from 'redux-form';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import TextField from '../../../../../../../components/TextField';
import { validations } from '../../../../../../../services/validation';

const EntryPriceArray = ({ change, classes, fields }) => (
  <>
    <Typography variant='body1' className={classes.directionsText}>
      Fill out the price per entry for each entry-count range below.
    </Typography>
    {fields.map((entryPriceTier, index) => (
      <div key={index} className={classes.tierRow}>
        <Field
          name={`${entryPriceTier}.min`}
          component={TextField}
          label='Min Count'
          type='number'
          className={classes.rowItem}
          validate={[validations.required]}
          disabled
        />
        {index === fields.length - 1 ? (
          <div className={classes.andUpIconContainer}>
            <AndUpIcon />
          </div>
        ) : (
          <Field
            name={`${entryPriceTier}.max`}
            component={TextField}
            label='Max Count'
            type='number'
            className={classes.rowItem}
            validate={[validations.required]}
            onChange={(event, newValue, previousValue, name) => {
              change(
                `entryPriceTiers[${index + 1}].min`,
                parseFloat(newValue) + 1
              );
            }}
          />
        )}
        <Field
          name={`${entryPriceTier}.price`}
          component={TextField}
          label='Price per Entry'
          type='number'
          step='0.01'
          className={classes.rowItem}
          validate={[validations.required]}
        />
        <div className={classes.removeIconContainer}>
          <RemoveTierButton classes={classes} fields={fields} index={index} />
        </div>
      </div>
    ))}
    <AddTierButton classes={classes} fields={fields} />
  </>
);

const AddTierButton = ({ classes, fields }) => (
  <Tooltip title='Add Entry Price Tier'>
    <IconButton
      variant='contained'
      color='secondary'
      aria-label='Add Entry Price Tier'
      onClick={() => fields.push({})}
    >
      <AddIcon />
    </IconButton>
  </Tooltip>
);

const RemoveTierButton = ({ classes, fields, index }) => (
  <Tooltip
    title='Remove Entry Price Tier'
    style={{
      visibility:
        index === 0 || index === fields.length - 1 ? 'hidden' : 'unset'
    }}
  >
    <IconButton
      variant='contained'
      color='secondary'
      aria-label='Remove Entry Price Tier'
      onClick={() => fields.remove(index)}
    >
      <RemoveIcon />
    </IconButton>
  </Tooltip>
);

const styles = theme => ({
  directionsText: {
    fontStyle: 'italic',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 8,
    fontSize: 14
  },
  tierRow: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    '& > div': {
      marignLeft: 8,
      marginRight: 8
    },
    '& > div:first-child': {
      marignLeft: 0
    },
    '& > div:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        display: 'block',
        marginRight: 0,
        marginLeft: 0,
        width: '100%'
      },
      '& > div > div': {
        width: '100%'
      },
      '& > div:last-child > div': {
        width: 'calc(100% - 14px)'
      },
      '& > label': {
        width: '100%',
        marginTop: 8,
        marginLeft: 0
      }
    }
  },
  rowItem: {
    flex: 1
  },
  andUpIconContainer: {
    display: 'flex',
    flex: 1,
    paddingTop: 32
  },
  removeIconContainer: {
    paddingTop: 20
  }
});

EntryPriceArray.propTypes = {
  fields: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(EntryPriceArray);
