import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import DataTable from '../../../../components/DataTable';
import DialogAddCredit from './components/DialogAddCredit';
// import DialogBillingItem from '../../components/DialogBillingItem';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
// import CreditItemForm from './components/CreditItemForm';
import qs from 'qs';
import Tooltip from '@material-ui/core/Tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { creditsColumns } from './columns';
import { creditsFilters } from './filters';

class Credits extends Component {
  state = { anchorEl: null };

  handlePopoverClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  renderAddCreditDialog() {
    const { addCreditDialogOpen, history, location } = this.props;

    return (
      <DialogAddCredit
        onClose={() => {
          let searchObj = qs.parse(location.search.slice(1));
          delete searchObj.addCreditDialogOpen;
          history.push(`${location.pathname}?${qs.stringify(searchObj)}`);
        }}
        onSave={() => {
          let searchObj = qs.parse(location.search.slice(1));
          searchObj.refreshData = true;
          delete searchObj.addCreditDialogOpen;
          history.push(`${location.pathname}?${qs.stringify(searchObj)}`);
        }}
        openDialog={addCreditDialogOpen}
      />
    );
  }

  // renderBillingItemDialog() {
  //   const { history, location } = this.props;

  //   return (
  //     <DialogBillingItem
  //       title='Credit'
  //       collection='credits'
  //       dialogBody={(data) => (
  //         <CreditItemForm
  //           data={data}
  //           history={history}
  //           location={location}
  //         />
  //       )}
  //       history={history}
  //       location={location}
  //     />
  //   );
  // }

  render() {
    const { location, history, tabId } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <span>
        <DataTable
          {...this.props}
          collection='credits'
          columns={creditsColumns}
          clearSearchPath={`/billing${
            tabId && tabId !== 'undefined' ? '/' + tabId : ''
          }`}
          sceneUri='billing'
          title='Credits'
          // onRowClickLink={(data, pathname) => {
          //   let path = pathname;

          //   if (pathname.indexOf('dialogData') > -1) {
          //     let searchObj = qs.parse(pathname.split('?')[1])
          //     delete searchObj.dialogData
          //     let search = qs.stringify(searchObj)

          //     path = `${pathname.split('?')[0]}?${search}`
          //   }

          //   return `${path}${path.indexOf('?') > -1 ? '&' : '?'}dialogData=${JSON.stringify({ id: data.id })}`;
          // }}
          tableActionIcons={[
            {
              render: (key, filtersApplied, onClearFilter) => (
                <span key={key}>
                  <FilterClearChip
                    filtersApplied={filtersApplied}
                    onClearFilter={onClearFilter}
                  />
                  <Tooltip title='Filters'>
                    <IconButton
                      aria-label='Filters'
                      aria-owns={open ? 'simple-popper' : null}
                      onClick={this.handlePopoverClick}
                    >
                      {filtersApplied ? (
                        <Badge color='secondary' variant='dot'>
                          <FilterIcon />
                        </Badge>
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </span>
              )
            },
            {
              render: key => (
                <span key={key}>
                  <Tooltip title='Add Credit'>
                    <IconButton
                      variant='contained'
                      color='primary'
                      aria-label='Add Credit'
                      onClick={() => {
                        const pathname = location.pathname;
                        let searchObj = qs.parse(location.search.slice(1));
                        delete searchObj.refreshData;
                        const param =
                          qs.stringify(searchObj) === ''
                            ? '?addCreditDialogOpen=true'
                            : '&addCreditDialogOpen=true';

                        history.push(
                          `${pathname}${qs.stringify(searchObj)}${param}`
                        );
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              )
            }
          ]}
          tableFilterable={true}
          filterData={{
            anchorEl,
            filters: creditsFilters,
            open,
            handleClose: this.handlePopoverClose
          }}
        />
        {this.renderAddCreditDialog()}
        {/* {this.renderBillingItemDialog()} */}
      </span>
    );
  }
}

const mapStateToProps = (state, { location }) => {
  const addCreditDialogOpen =
    qs.parse(location.search.slice(1)).addCreditDialogOpen === 'true';

  return { addCreditDialogOpen, location };
};

export default compose(connect(mapStateToProps, {}))(Credits);
