import React, { useContext } from 'react';
import { AppContext } from '../../../../contexts';
import { withRouter } from 'react-router-dom';
import { styles } from './styles';
import { withStyles } from '@material-ui/core';
import CustomReport from './steps/';
import PropTypes from 'prop-types';

const ReportsWizard = props => {
  const { location, classes, cancel, clientGroups, offices } = props;
  const {
    state: { firebase }
  } = useContext(AppContext);

  return (
    <CustomReport
      location={location}
      classes={classes}
      firestore={firebase.firestore}
      functions={firebase.functions()}
      cancel={cancel}
      clientGroups={clientGroups}
      offices={offices}
    />
  );
};

ReportsWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  clientGroups: PropTypes.array.isRequired
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ReportsWizard)
);
