import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import HeaderTitleWithTabs from '../../components/HeaderTitleWithTabs';
import HeaderTitleText from '../../components/HeaderTitleText';
import PropTypes from 'prop-types';
import { AppContext } from '../../contexts';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';
import ListsScenes from './scenes';
import { db } from '../../index';

const Lists = props => {
  const {
    state: { profile }
  } = useContext(AppContext);
  const [clients, setClients] = useState([]);
  let tabs = [];
  let tabIndex = 0;

  useEffect(() => {
    const getClients = async () => {
      const clientsActiveInactivePaused = [];
      try {
        const allClients = await db.collection('clients').get();

        allClients.docs.forEach(doc => {
          if (
            doc.data().status === 'active' ||
            doc.data().status === 'paused' ||
            doc.data().status === 'onboarding'
          ) {
            clientsActiveInactivePaused.push({ ...doc.data(), id: doc.id });
          }
        });
        setClients(clientsActiveInactivePaused);
      } catch (e) {
        console.error(e);
      }
    };

    if (clients.length === 0) {
      getClients();
    }
  }, [clients]);

  Object.keys(props.tabs).forEach(key => {
    const route = props.tabs[key];
    const TabScene = ListsScenes[key];
    if (key !== 'metadata') {
      const userGroups = t(profile, 'data.groups').safeArray || [];
      const readGroups = t(route, 'metadata.permissions.read').safeArray || [];
      const tabData = {
        key,
        index: tabIndex,
        name: key,
        title: route.metadata.title,
        handleChangePath: '/lists/$tabName',
        renderComponent: () => (
          <TabScene
            {...props}
            tabId={props.match.params.tabId}
            params={props.match.params}
            route={route}
            offices={clients}
          />
        )
      };

      if (
        !route.metadata.restricted ||
        (route.metadata.restricted &&
          _.intersection(userGroups, readGroups).length > 0)
      ) {
        tabs.push(tabData);
        tabIndex = tabIndex + 1;
      }
    }
  });

  return (
    <HeaderTitleWithTabs
      {...props}
      params={props.match.params}
      renderTitle={() => <HeaderTitleText text='Lists' />}
      tabId={props.match.params.tabId}
      tabs={tabs}
    />
  );
};

Lists.propTypes = {
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired
};

export default withRouter(Lists);
