export const styles = theme => ({
  detailsPaper: {
    minHeight: '40vh',
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  newListRoot: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  filePreviewPaper: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  filePreviewRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 66,
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  filePreviewRootDenticon1: {
    display: 'flex',
    flexDirection: 'column',
    height: 132,
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  filePreviewRootDenticon2: {
    display: 'flex',
    flexDirection: 'column',
    height: 198,
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'lightgrey'
  },
  fileText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  fileStatusReady: {
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    height: 50,
    borderRadius: '8px',
    backgroundColor: '#e8fce8'
  },
  fileStatusNotReady: {
    backgroundColor: '#FFCCCB'
  },
  filePreviewList: {
    width: '100%'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    '& > *': {
      flex: 1,
      marginLeft: 6,
      marginRight: 6
    },
    '& > *:first-child': {
      marginLeft: 0
    },
    '& > *:last-child': {
      marginRight: 0
    },
    height: 70
  },
  dropzoneButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  submitButtonRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  filterMedicaidRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  isPediatricRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  filePreview: {
    display: 'flex',
    width: '100%',
    height: 50,
    border: 'black solid 1px',
    borderRadius: '8px'
  },
  filePreviewName: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filePreviewSize: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filePreviewProgress: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  fileNameErrorText: {
    paddingTop: 5,
    marginBottom: -5,
    height: 25,
    display: 'flex',
    justifyContent: 'center'
  },
  statusMessage: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: 20,
    marginTop: -20
  },
  denticonWarning: {
    position: 'absolute',
    marginTop: '70px'
  },
  dialogFileListing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});
