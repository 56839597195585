import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import FieldSelect from '../../../../../../../components/FieldSelect';
import { pms as pmsOptions } from '../../../../../../../services/options';
import AddInsurance from './components/AddInsurance';

const GeneralSettingForm = ({
  handleSubmit,
  classes,
  loadingAfterSubmit,
  initialValues,
  settingLabel,
  originalProp
}) => {
  const insuranceStatuses = [
    { value: 'accepted', label: 'Accepted' },
    { value: 'notAccepted', label: 'Not Accepted' },
    { value: 'conditional', label: 'Conditional' }
  ];

  const renderGeneralFieldSetting = (form, field) => {
    switch (originalProp) {
      case 'pms':
        return (
          <FieldSelect
            name={originalProp}
            form={form}
            field={field}
            options={pmsOptions}
            margin='none'
            optionValue='key'
            optionKey='key'
            optionText='name'
            label={settingLabel}
          />
        );
      default:
        return <h1>Error</h1>;
    }
  };

  if (initialValues) {
    if (initialValues.insurances === '-') {
      initialValues.insurances = [];
    }

    if (
      Array.isArray(initialValues.insurances) &&
      initialValues.insurances.length
    ) {
      initialValues.insurances.forEach(insurance => {
        if (insurance.status === insuranceStatuses[0].value) {
          insurance.status = insuranceStatuses[0];
        } else if (insurance.status === insuranceStatuses[1].value) {
          insurance.status = insuranceStatuses[1];
        } else if (insurance.status === insuranceStatuses[2].value) {
          insurance.status = insuranceStatuses[2];
        }
      });
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <Form className={classes.form}>
        {settingLabel && settingLabel === 'Insurances' ? (
          <FieldArray
            className={classes.dialogTextField}
            name={originalProp}
            label={settingLabel}
            render={({ form, push, insert, remove, replace }) => (
              <AddInsurance
                name={originalProp}
                label={settingLabel}
                form={form}
                classes={classes}
                valuesPush={push}
                valuesInsert={insert}
                valuesRemove={remove}
                valuesReplace={replace}
                statusOptions={insuranceStatuses}
              />
            )}
          />
        ) : settingLabel ? (
          <Field
            className={classes.dialogTextField}
            name={originalProp}
            label={settingLabel}
            variant='outlined'
          >
            {({ form, field }) => {
              return renderGeneralFieldSetting(form, field);
            }}
          </Field>
        ) : null}
        <div className={classes.actionSection}>
          <Button
            size='large'
            variant='outlined'
            color='secondary'
            className={classes.dialogButton}
            type='submit'
          >
            {loadingAfterSubmit ? (
              <Loader type='Oval' color='#055f5b' height='13' width='13' />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default GeneralSettingForm;
