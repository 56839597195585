import _ from 'lodash';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import OfficeName from './OfficeName';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { mistakeReasons } from '../services/options';
import { withStyles } from '@material-ui/core/styles';

class EmployeeMistakesTable extends Component {
  state = { mistakes: null };

  UNSAFE_componentWillUpdate(nextProps) {
    const { mistakeEntries } = this.props;

    if (!_.isEqual(mistakeEntries, nextProps.mistakeEntries)) {
      this.setState({
        mistakes: this.formatMistakeData(nextProps.mistakeEntries)
      });
    }
  }

  formatMistakeData(data) {
    let formattedData = [];

    data.forEach(item => {
      formattedData.push({
        id: item.id,
        patientName: item.patientName,
        office: item.office,
        date: moment.unix(item.createdTime.seconds).format('YYYY-MM-DD'),
        category: item.mistake.category,
        notes: item.mistake.notes
      });
    });

    return formattedData;
  }

  renderNoRows() {
    const { mistakes } = this.state;
    const { classes } = this.props;

    if (!mistakes || (mistakes && mistakes.length === 0)) {
      return (
        <div className={classes.noRowsContainer}>
          <Typography variant='body1' className={classes.noRowsText}>
            No Mistakes
          </Typography>
        </div>
      );
    }
  }

  render() {
    const { classes, orderByValue, orderByOrder } = this.props;
    const { mistakes } = this.state;

    return (
      <div className={classes.tableContainer}>
        {this.renderNoRows()}
        {mistakes && mistakes.length > 0
          ? _.orderBy(mistakes, orderByValue, orderByOrder).map(
              (mistake, index) => (
                <Grid
                  container
                  spacing={24}
                  key={mistake.id}
                  className={classes.tableRow}
                >
                  <Grid
                    item
                    xs={3}
                    className={`${classes.cell} ${classes.firstCell}`}
                    style={{ padding: 0, paddingLeft: 12 }}
                  >
                    <Typography variant='body1' className={classes.entryInfo}>
                      <span className={classes.officeName}>
                        {mistake.office ? (
                          <OfficeName office={mistake.office} />
                        ) : null}
                      </span>
                      <span className={classes.patientName}>
                        {mistake.patientName ? mistake.patientName : null}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.cell}
                    style={{ padding: 0 }}
                  >
                    <Typography variant='body1' className={classes.mistakeDate}>
                      {mistake.date ? mistake.date : null}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.cell}
                    style={{ padding: 0 }}
                  >
                    <Typography
                      variant='body1'
                      className={classes.mistakeCategory}
                    >
                      {mistake.category ? mistake.category : null}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={`${classes.cell} ${classes.lastCell}`}
                    style={{ padding: 0 }}
                  >
                    <Typography
                      variant='body1'
                      className={classes.mistakeNotes}
                    >
                      {mistake.notes ? mistake.notes : null}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )
          : null}
      </div>
    );
  }
}

const styles = theme => ({
  loaderContainer: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableContainer: {
    paddingTop: theme.spacing.unit * 2,
    width: '90%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: theme.spacing.unit * 32,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing.unit * 37
    }
  },
  noRowsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noRowsText: {
    marginTop: theme.spacing.unit * 2,
    fontStyle: 'italic',
    fontSize: theme.spacing.unit * 1.5
  },
  tableRow: {
    marginBottom: theme.spacing.unit * 2,
    borderBottom: `1px solid #f5f5f5`
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  firstCell: {
    justifyContent: 'flex-start'
  },
  lastCell: {
    justifyContent: 'flex-start'
  },
  entryInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  patientName: {
    fontSize: theme.spacing.unit * 1.1,
    fontStyle: 'italic'
  },
  officeName: {
    fontSize: theme.spacing.unit * 1.5
  },
  mistakeDate: {
    fontSize: theme.spacing.unit * 1.4
  },
  mistakeCategory: {
    fontSize: theme.spacing.unit * 1.4,
    textAlign: 'center'
  },
  mistakeNotes: {
    fontSize: theme.spacing.unit * 1.4
  }
});

const mapStateToProps = (state, props) => {
  const orderedData = state.firestore.ordered;
  let mistakeEntries = [];

  if (!_.isEmpty(orderedData)) {
    for (let key in orderedData) {
      if (key.split('-')[0] === 'employeeMistakeEntries') {
        if (orderedData[key][0]) {
          mistakeEntries.push(orderedData[key][0]);
        }
      }
    }
  }

  return { mistakeEntries };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const employeeUid = props.auth && props.auth.uid;
    let queries = [];

    if (employeeUid) {
      mistakeReasons[props.collection].forEach(mistakeReason => {
        queries.push({
          collection: props.collection,
          where: [
            ['createdBy', '==', employeeUid],
            ['mistake.category', '==', mistakeReason.value]
          ],
          storeAs: `employeeMistakeEntries-${_.camelCase(mistakeReason.value)}`
        });
      });
    }

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(EmployeeMistakesTable);
