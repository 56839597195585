import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { Paper, withStyles } from '@material-ui/core';
import OfferingLabel from './components/OfferingLabel';
import OfferingContent from './components/OfferingContent';
import DialogAddPricingPlan from './components/DialogAddPricingPlan';
import DataRow from './components/DataRow';

const Offerings = ({ classes, offerings }) => {
  const [addPricingPlanDialogOpen, setAddPricingPlanDialogOpen] = useState(
    false
  );
  const [selectedOffering, setSelectedOffering] = useState(null);

  return (
    <>
      <Paper elevation={2} className={classes.paperRoot}>
        <div className={classes.paperContent}>
          {offerings.length === 0
            ? [...Array(6)].map((_, i) => <DataRow key={i} loading />)
            : offerings.map(offering => (
                <DataRow
                  key={offering.id}
                  label={<OfferingLabel offering={offering} />}
                  content={
                    <OfferingContent
                      offering={offering}
                      setAddPricingPlanDialogOpen={setAddPricingPlanDialogOpen}
                      setSelectedOffering={setSelectedOffering}
                    />
                  }
                />
              ))}
        </div>
      </Paper>
      {selectedOffering ? (
        <DialogAddPricingPlan
          addPricingPlanDialogOpen={addPricingPlanDialogOpen}
          offering={selectedOffering}
          setAddPricingPlanDialogOpen={setAddPricingPlanDialogOpen}
        />
      ) : null}
    </>
  );
};

const styles = theme => ({
  paperRoot: {
    minHeight: theme.containers.paper.height.min,
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  paperContent: {
    padding: theme.containers.paper.content.padding.default,
    [theme.breakpoints.down('xs')]: {
      padding: theme.containers.paper.content.padding.xs
    }
  }
});

Offerings.propTypes = {
  classes: PropTypes.object.isRequired,
  offerings: PropTypes.array.isRequired
};

export default compose(
  connect((state, props) => {
    const oData = state.firestore.ordered.offerings;
    let offerings = [];
    if (isLoaded(oData) && !isEmpty(oData)) offerings = oData;

    return { offerings };
  }),
  firestoreConnect(props => [
    {
      collection: 'offerings',
      storeAs: 'offerings'
    }
  ]),
  withStyles(styles, { withTheme: true })
)(Offerings);
