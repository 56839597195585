import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Button, Typography, withStyles } from '@material-ui/core';

const InvoiceActions = ({
  actionError,
  classes,
  changingStatus,
  onStatusChange,
  regenerateInvoice,
  regeneratingInvoice,
  status
}) => {
  const executingAction = changingStatus || regeneratingInvoice;
  const paidActions = () => null;
  const voidActions = () => null;
  const pendingActions = () => (
    <>
      <div className={classes.actionsRoot}>
        <Button
          color='secondary'
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={() => regenerateInvoice()}
          disabled={executingAction}
          style={{ marginRight: '1%' }}
        >
          {regeneratingInvoice ? (
            <>
              <Loader type='Oval' color='#696969' height='10' width='10' />
              <span className={classes.loadingText}>Regenerating</span>
            </>
          ) : (
            'Regenerate'
          )}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={() => onStatusChange('paid')}
          disabled={executingAction}
          style={{ marginRight: '1%' }}
        >
          {executingAction ? (
            <Loader type='Oval' color='#696969' height='10' width='10' />
          ) : (
            'Mark as Paid'
          )}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={() => onStatusChange('void')}
          disabled={executingAction}
          style={{ marginRight: '1%' }}
        >
          {executingAction ? (
            <Loader type='Oval' color='#696969' height='10' width='10' />
          ) : (
            'Void Invoice'
          )}
        </Button>
      </div>
      <Typography className={classes.errorMessage} variant='body1'>
        {actionError || null}
      </Typography>
    </>
  );

  return status === 'pending'
    ? pendingActions()
    : status === 'paid'
    ? paidActions()
    : status === 'void'
    ? voidActions()
    : null;
};

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64
  },
  errorMessage: {
    flex: 1,
    color: theme.palette.danger.main,
    fontStyle: 'italic',
    paddingRight: 8,
    marginTop: 16,
    textAlign: 'center'
  },
  loadingText: {
    marginLeft: 4
  },
  actionsRoot: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 5
    }
  }
});

InvoiceActions.propTypes = {
  actionError: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  changingStatus: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  regenerateInvoice: PropTypes.func.isRequired,
  regeneratingInvoice: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(InvoiceActions);
