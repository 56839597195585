import _ from 'lodash';
import React, { useContext, useState } from 'react';
import DataTable from '../../../../components/DataTable';
import DialogAddOffice from './components/DialogAddOffice';
import FilterClearChip from '../../../../components/DataTable/Filter/ClearChip';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Add, FilterList } from '@material-ui/icons';
import { AppContext } from '../../../../contexts';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { officesColumns } from './columns';
import { officesFilters } from './filters';
import { t } from 'typy';
import { withRouter } from 'react-router-dom';

const OfficesData = props => {
  const { history, location, route, tabId } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    state: { profile }
  } = useContext(AppContext);
  const open = Boolean(anchorEl);
  const userGroups = t(profile, 'data.groups').safeArray || [];
  const createGroups = t(route, 'metadata.permissions.create').safeArray || [];
  const userAllowedToAddOffice =
    _.intersection(userGroups, createGroups).length > 0;
  const addOfficeDialogOpen =
    queryString.parse(location.search.slice(1)).addOfficeDialogOpen === 'true';
  const handlePopoverClick = e => setAnchorEl(e.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <DataTable
        {...props}
        collection='clients'
        columns={officesColumns}
        clearSearchPath={`/offices${
          tabId && tabId !== 'undefined' ? '/' + tabId : ''
        }`}
        sceneUri='offices'
        title='Offices'
        onRowClickLink={(data, pathname) =>
          `/office/${data.id}/details?pathname=${pathname}`
        }
        tableActionIcons={[
          {
            render: (key, filtersApplied, onClearFilter) => (
              <span key={key}>
                <FilterClearChip
                  filtersApplied={filtersApplied}
                  onClearFilter={onClearFilter}
                />
                <Tooltip title='Filters'>
                  <IconButton
                    aria-label='Filters'
                    aria-owns={open ? 'simple-popper' : null}
                    onClick={handlePopoverClick}
                  >
                    {filtersApplied ? (
                      <Badge color='secondary' variant='dot'>
                        <FilterList />
                      </Badge>
                    ) : (
                      <FilterList />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )
          },
          {
            render: key =>
              userAllowedToAddOffice ? (
                <span key={key}>
                  <Tooltip title='Add Office'>
                    <IconButton
                      variant='contained'
                      color='primary'
                      aria-label='Add Office'
                      onClick={() => {
                        const pathname = location.pathname;
                        const param =
                          location.search === ''
                            ? '?addOfficeDialogOpen=true'
                            : '&addOfficeDialogOpen=true';
                        history.push(`${pathname}${location.search}${param}`);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </span>
              ) : null
          }
        ]}
        tableFilterable={true}
        filterData={{
          anchorEl,
          filters: officesFilters,
          open,
          handleClose: handlePopoverClose
        }}
      />
      {userAllowedToAddOffice ? (
        <DialogAddOffice
          firestoreCollection='clients'
          history={history}
          location={location}
          onClose={() => {
            let searchObj = queryString.parse(location.search.slice(1));
            delete searchObj.addOfficeDialogOpen;
            history.push(
              `${location.pathname}?${queryString.stringify(searchObj)}`
            );
          }}
          onOfficeAddRedirect={(newOfficeId, defaultPathName) => {
            if (defaultPathName) {
              history.push(
                `/office/${newOfficeId}/details?pathname=/offices/data`
              );
            } else {
              history.push(
                `/office/${newOfficeId}/details?pathname=${location.pathname}`
              );
            }
          }}
          openDialog={addOfficeDialogOpen}
        />
      ) : null}
    </>
  );
};

OfficesData.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tabId: PropTypes.string.isRequired
};

export default withRouter(OfficesData);
