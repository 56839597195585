/* eslint-disable no-async-promise-executor */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment-timezone';
import sitemap from './sitemap';
import { CALLFORCE_TZ } from './services/constants';
import { t } from 'typy';
import { reportTypeDisplays } from './services/options/';

/**
 * add charge
 *
 * @param {Object} data - data for adding charge
 * @param {Object} data.firestore - instance of firestore
 * @param {Object} data.charge - charge data
 * @param {string} data.charge.officeId - client id
 * @param {string} data.charge.officeName - client name
 * @param {string} data.charge.immediateCharge -
 * @param {string} data.charge.entryCharge -
 * @param {string} data.charge.periodCharge -
 * @param {Object[]|string} data.charge.entryPrice -
 * @param {number} data.charge.quantity -
 * @param {number} data.charge.type -
 * @param {number} data.charge.status -
 */
const addCharge = ({ firestore, charge }) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        officeId,
        officeName,
        immediateCharge,
        entryCharge,
        periodCharge,
        quantity,
        type,
        entryPrice,
        status
      } = charge;
      let r = null;
      const now = firestore.Timestamp.now().toDate();
      const unitPrice = _.isArray(entryPrice)
        ? _.filter(entryPrice, o => o.entryMin === 0)[0].entryPrice
        : entryPrice;
      const total = Math.round(quantity * unitPrice * 100) / 100;
      const dateEnd = moment(now)
        .tz(CALLFORCE_TZ)
        .endOf('month')
        .toDate();
      const dateStart = moment(now)
        .tz(CALLFORCE_TZ)
        .startOf('month')
        .toDate();
      const data = {
        client: officeId,
        clientName: officeName,
        createdTime: firestore.Timestamp.now().toDate(),
        dateEnd,
        dateStart,
        immediateCharge,
        entryCharge,
        periodCharge,
        quantity: parseFloat(quantity),
        total: parseFloat(total),
        type,
        unitPrice: parseFloat(unitPrice),
        status: status ? status : 'open'
      };
      r = await firestore.add({ collection: 'charges' }, data);

      resolve(r);
    } catch (e) {
      reject(e);
    }
  });

/**
 * @function
 * @name allowedTo
 *    check if user is allowed to perform a type of acion within a specified
 *    route
 *
 * @param {String} action - Defined on the permissions obj on a route def
 * @param {String} route - Defined on route key in sitemap
 * @param {Object[]} userGroups - A user's groups defined on their profile
 * @returns {Boolean} - Whether or not user is allowed to perform action
 */
const allowedTo = (action, route, userGroups) => {
  let allowedTo = false;
  const routeObj = _.get(sitemap, route.substr(1).replace(/\//g, '.'));
  const allowedToGroups = t(routeObj, `metadata.permissions.${action}`)
    .safeArray;

  if (_.intersection(userGroups, allowedToGroups).length > 0) {
    allowedTo = true;
  }

  return allowedTo;
};

/**
 * @function
 * @name composeFieldValidation
 *    a funtion to validate a form field based on multiple validation functions
 *
 * @param {String} value - Value of the field
 * @param {Object[]} validations - Validation functions for field
 * @returns {undefined|Object} - Validation messages or undefined if valid
 */
const composeFieldValidation = (value, validations) => {
  for (const validation of validations) {
    const result = validation(value);

    if (result) {
      return result;
    }
  }

  return undefined;
};

/**
 * @function
 * @name getDefaultRoute
 *    determines default route for drawer link based on user's groups
 *
 * @param {Object[]} userGroups - User's assigned groups
 * @param {String} route - Route that corresponds to route in app drawer
 * @returns {String} - An authorized default route for user
 */
const getDefaultRoute = (userGroups, route) => {
  const defaultRoutes = sitemap[route].metadata.routesDefault;
  let userDefaultRoute = '/';

  for (const group of Object.keys(defaultRoutes)) {
    if (userGroups.indexOf(group) > -1) {
      userDefaultRoute = defaultRoutes[group];
      break;
    }
  }

  return userDefaultRoute;
};

/**
 * @function
 * @name getLocationWithParams
 *    returns location pathname with match params replaced with param keys
 *
 * @param {String} path - Path passed to route component with param keys
 * @param {Object} location - Object from react router dom
 * @returns {String} - Location with match params
 */
const getLocationWithParams = (path, location) => {
  const pathArray = path.substr(1).split('/');
  const locationArray = location.pathname.substr(1).split('/');
  const lastParamArray = locationArray.splice(pathArray.length - 1, 1);
  const pathArrayLessLastParam = pathArray.splice(0, pathArray.length - 1);
  const locationWithParams = _.concat(pathArrayLessLastParam, lastParamArray);
  const locationWithParamsStr = `/${locationWithParams.join('/')}`;

  return locationWithParamsStr;
};

/**
 * @function
 * @name resolvePath
 *    traverses object based on path in dot notation
 *
 * @param {Object} obj - Object to traverse
 * @param {String} objPath - Path to the value
 * @returns {Object} - Value found at object path
 */
const resolvePath = (obj, objPath) => {
  let path = objPath;
  path = path.split('.');
  let current = obj;

  while (path.length) {
    if (typeof current !== 'object') return undefined;
    current = current[path.shift()];
  }

  return current;
};

/**
 * @function
 * @name deleteEntry
 *    deletes a scheduledTreatment document from firestore
 *
 * @param {Object} firebase - Instance of firebase sdk
 * @param {Object} docId - Document id of the treatment entry
 */
const deleteEntry = (firebase, docId, collection) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(collection)
      .doc(docId)
      .delete()
      .then(() => resolve())
      .catch(err => reject(err));
  });

  
/**
 * @function
 * @name parseAsUSPhoneNumber
 *    strips prefix data from phone number string to return US number
 *
 * @param {string} phoneNumber - Phone number to parse
 */
const parseAsUSPhoneNumber = phoneNumber => 
  phoneNumber.substring(phoneNumber.length - 10)
  
/**
 * @function
 * @name saveEntryData
 *    save  entry data to firestore
 *
 * @param {Object} firebase - Instance of firebase sdk
 * @param {Object} treatmentEntryValues - Transformed form data for  entry
 * @param {string} collection - Name of collection to which entry will be saved
 */
const saveEntryData = (firebase, EntryValues, collection) =>
  new Promise(async (resolve, reject) => {
    const values = EntryValues;
    const docId = EntryValues.id ? EntryValues.id : null;
    delete values.id;
    const saveDataQuery = docId
      ? firebase
          .firestore()
          .collection(collection)
          .doc(docId)
          .set(values)
      : firebase
          .firestore()
          .collection(collection)
          .add(values);

    saveDataQuery.then(() => resolve()).catch(err => reject(err));
  });

/**
 * @function
 * @name transformEntryDataForEdit
 *    transforms treatment entry document to url encoded object for edit
 *
 * @param {Object} docId - Document id of treatment entry
 * @returns {String} - Url-encoded object for edit
 */
const transformEntryDataForEdit = async (firebase, docId, collection) => {
  const doc = await firebase
    .firestore()
    .collection(collection)
    .doc(docId)
    .get();
  const entry = { ...doc.data(), id: doc.id };
  const appointmentTime = entry.appointmentTime
    ? entry.appointmentTime.seconds
    : null;
  const lastVisitDate = entry.lastVisitDate
    ? entry.lastVisitDate.seconds
    : null;
  let officeTimezone = entry.officeTimezone;

  delete entry.spanishSpEmpName;
  if (!officeTimezone) {
    const officeDoc = await firebase
      .firestore()
      .collection('clients')
      .doc(entry.office)
      .get();
    const office = { ...officeDoc.data(), id: officeDoc.id };
    officeTimezone = _.get(office, 'officeInformation.timezone', CALLFORCE_TZ);
  }
  if (appointmentTime) {
    entry.appointmentDate = appointmentTime
      ? moment.unix(appointmentTime).format('YYYY-MM-DD')
      : null;
    entry.appointmentTime = moment
      .unix(appointmentTime)
      .tz(officeTimezone)
      .format('HH:mm');
  }
  if (lastVisitDate) {
    entry.lastVisitDate = moment
      .unix(lastVisitDate)
      .tz(officeTimezone)
      .format('YYYY-MM-DD');
  }
  if (entry.mistake) {
    entry.mistakeCategory = entry.mistake.category;
    entry.mistakeNotes = entry.mistake.notes;
    entry.mistakeHandledNotes = entry.mistake.mistakeHandledNotes;
    entry.rescheduleNotes = entry.mistake.rescheduleNotes;
    entry.reschedulePatient = entry.mistake.reschedulePatient;
    entry.rescheduled = entry.mistake.rescheduled;
    entry.mistakeHandled = entry.mistake.mistakeHandled;
    entry.mistake = true;
  }
  if (entry.doNotBill) {
    entry.doNotBillCategory = entry.doNotBill.category;
    entry.doNotBillNotes = entry.doNotBill.notes;
    entry.doNotBill = true;
  }

  return entry;
};

/**
 * @function
 * @name transformEntryDataForSave
 *    transforms form data from treatment entry to structure for firestore
 *
 * @param {Object} firebase - Instance of firebase sdk
 * @param {Object} auth - Authenticated user information
 * @param {Object} entryValues - Form data for entry
 * @returns {Object} - Data to save in firestore
 */
const transformEntryDataForSave = (firebase, auth, entryValues) =>
  new Promise((resolve, reject) => {
    const values = JSON.parse(JSON.stringify(entryValues));
    const promises = [
      firebase
        .firestore()
        .collection('clients')
        .doc(values.office)
        .get(),
      firebase
        .firestore()
        .collection('employees')
        .doc(auth.data.uid)
        .get()
    ];

    Promise.all(promises)
      .then(results => {
        const [clientDoc, employeeDoc] = results;
        const client = { ...clientDoc.data(), id: clientDoc.id };
        const employee = { ...employeeDoc.data(), id: employeeDoc.id };
        return { client, employee };
      })
      .then(({ client, employee }) => {
        const officeTimezone = client.officeInformation.timezone;

        if (values.appointmentDate && values.appointmentTime) {
          const apptDateTime = `${values.appointmentDate} ${values.appointmentTime}`;
          const appointmentTime = moment.tz(apptDateTime, officeTimezone);
          delete values.appointmentDate;
          values.appointmentTime = new Date(appointmentTime.format());
          values.officeTimezone = officeTimezone;
          values.isAppointment = true;
        } else if (!values.appointmentDate) {
          values.isAppointment = false;
        }

        values.patientPhone = values.patientPhone
          ? values.patientPhone.replace(/[^0-9]/g, '')
          : null;
        values.officeName = client.officeInformation.name;

        if (values.newPatient) delete values.lastVisitDate;
        if (values.lastVisitDate) {
          values.lastVisitDate = moment
            .tz(entryValues.lastVisitDate, officeTimezone)
            .toDate();
          values.officeTimezone = officeTimezone;
        }
        if (!values.spanishSpEmpHelp) {
          delete values.spanishSpEmpHelp;
          delete values.spanishSpEmp;
        }

        if (values.id) {
          values.editedTime = new Date();
          values.editedBy = auth.data.uid;
          values.editedByName = `${employee.firstName} ${employee.lastName}`;
          values.createdTime = moment.unix(values.createdTime.seconds).toDate();
        } else {
          values.createdTime = new Date();
          values.createdBy = auth.data.uid;
          values.createdByName = `${employee.firstName} ${employee.lastName}`;
        }

        if (values.doNotBill) {
          values.doNotBill = {
            category: values.doNotBillCategory,
            notes: values.doNotBillNotes ? values.doNotBillNotes : ''
          };
          delete values.doNotBillCategory;
          delete values.doNotBillNotes;
        } else {
          delete values.doNotBill;
          delete values.doNotBillCategory;
          delete values.doNotBillNotes;
        }

        if (values.mistake) {
          values.mistake = {
            category: values.mistakeCategory,
            notes: values.mistakeNotes ? values.mistakeNotes : '',
            hasMistake: true,
            mistakeHandledNotes: values.mistakeHandledNotes
              ? values.mistakeHandledNotes
              : '',
            reschedulePatient: !!values.reschedulePatient,
            rescheduleNotes: values.rescheduleNotes
              ? values.rescheduleNotes
              : '',
            rescheduled: !!values.rescheduled,
            mistakeHandled: !!values.mistakeHandled,
            reportedOn: firebase.firestore.Timestamp.now().toDate()
          };

          delete values.mistakeCategory;
          delete values.mistakeNotes;
          delete values.mistakeHandledNotes;
          delete values.reschedulePatient;
          delete values.rescheduleNotes;
          delete values.rescheduled;
          delete values.mistakeHandled;
        } else {
          delete values.doNotCountMistake;
          delete values.mistake;
          delete values.mistakeCategory;
          delete values.mistakeNotes;
        }

        resolve(values);
      })
      .catch(err => {
        reject(err);
      });
  });

/**
 * @function
 * @name isEmpty
 *    traverses keys in objects to see if object is empty.
 *
 * @param {Object} obj - Object to check if empty
 */

const isEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

/**
 * @function
 * @name lowercaseFirstLetter
 *    lowercases the first character in a string and returns the new string
 *
 * @param {String} str - String to lowercase first char
 */

const lowercaseFirstLetter = str => {
  const lowercaseFirstChar = str.charAt(0).toLowerCase(),
    stringWithoutFirstLetter = str.slice(1);
  return lowercaseFirstChar + stringWithoutFirstLetter;
};

/**
 * @function
 * @name flattenObj
 *    traverses obj and flattens each object layer to the first layer of the
 *    original obj.
 * @param {Object} obj - Object to be flattened
 */

const flattenObj = (obj, parent, res = {}) => {
  for (let key in obj) {
    let propName = key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

/**
 *  Transforms reportType value, while selectively pulling in specific
 *  property values from 'values' object, to then be sent to custom report functions in google cloud.
 * @name transformCustomReportValues
 * @param {Object} values - Final values gathered in formik state at point of submission.
 * @param {string} values.reportType - String of the report type
 * @param {Array<string>} values.offices - Array of offices that will be receiving the report.
 * @param {string} values.startDate - String of the beginning of the date range of the report.
 * @param {string} values.endDate - String of the end of the date range of the report.
 * @param {string} values.host - String of the protocol and host to be validated server-side.
 * @param {string} values.clientGroupName - String of the clientGroup chosen to be part of filename.
 * @param {string} values.regionName - String of the region of the clientGroup chosen to be part of filename.
 */
const transformCustomReportValues = values => {
  const transformedValues = {
    reportType: reportTypeDisplays[values.reportType],
    clientGroupName: values.clientGroupName,
    regionName: values.regionName,
    offices: values.offices,
    startDate: values.startDate,
    endDate: values.endDate,
    host: values.host
  };

  return transformedValues;
};

const helpers = {
  addCharge,
  allowedTo,
  composeFieldValidation,
  deleteEntry,
  getDefaultRoute,
  getLocationWithParams,
  resolvePath,
  saveEntryData,
  transformEntryDataForEdit,
  transformEntryDataForSave,
  isEmpty,
  lowercaseFirstLetter,
  flattenObj,
  transformCustomReportValues,
  parseAsUSPhoneNumber
};

export default helpers;
