import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { FieldText } from '../../../../../../../components/Fields';

const ClientGroupInformation = ({
  name,
  Field,
  classes,
  nextPage,
  errors,
  values,
  validations
}) => {
  const valuesCheck = () => {
    return !values.clientGroupName;
  };

  const errorsCheck = () => {
    return !!errors.clientGroupName;
  };

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Client Group Name</Typography>
      </div>
      <div className={classes.name}>
        <Field name={name} validate={validations.name}>
          {({ form, field }) => {
            return (
              <FieldText
                field={field}
                form={form}
                label='Client Group Name'
                variant='outlined'
                onChange={e => {
                  field.onBlur(e);
                  field.onChange(e);
                }}
              />
            );
          }}
        </Field>
      </div>
      <div className={classes.sectionActions}>
        <Button color='secondary' type='reset'>
          Cancel
        </Button>
        <Button
          color='primary'
          type='submit'
          onClick={nextPage}
          disabled={valuesCheck() || errorsCheck()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

ClientGroupInformation.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired
};

export default ClientGroupInformation;
