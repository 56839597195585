import {
  DIALOG_EDIT_OFFICE_STATUS_CLICK,
  DIALOG_EDIT_OFFICE_STATUS_ITEM
} from '../actions/types';

export const dialogEditOfficeStatusItem = (state = null, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_STATUS_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export const dialogEditOfficeStatusOpen = (state = false, action) => {
  switch (action.type) {
    case DIALOG_EDIT_OFFICE_STATUS_CLICK:
      return action.payload;
    default:
      return state;
  }
};

export const DialogEditOfficeStatusReducer = {
  dialogEditOfficeStatusItem,
  dialogEditOfficeStatusOpen
};
