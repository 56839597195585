import _ from 'lodash';
import React from 'react';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  dialogEditOfficeStatusItem,
  dialogEditOfficeStatusClick
} from '../../../actions';
import { styles } from './styles';
import EditButton from './EditButton';

const OfficeStatusData = ({
  classes,
  data,
  data: { status },
  dialogEditOfficeStatusItem,
  dialogEditOfficeStatusClick,
  label,
  name,
  showEdit
}) => {
  const onClick = () => {
    dialogEditOfficeStatusItem({ key: name, label, data });
    dialogEditOfficeStatusClick(true);
  };

  return (
    <div className={classes.dataRow}>
      <div className={classes.dataLabel}>
        <div>{label}</div>
        {showEdit ? <EditButton onClick={onClick} /> : null}
      </div>
      <div className={classes.dataText}>
        <span className={classes[`${status}Status`]}>
          <StatusIcon />
        </span>
        {_.startCase(status)}
      </div>
    </div>
  );
};

export default compose(
  connect(null, { dialogEditOfficeStatusItem, dialogEditOfficeStatusClick }),
  withStyles(styles, { withTheme: true })
)(OfficeStatusData);
