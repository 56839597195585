import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { IconButton, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const ClientGroupName = ({ classes, history, clientGroup, pathname }) => {
  const onClick = () =>
    pathname ? history.push(pathname) : history.push('/clientGroups');

  return (
    <div>
      <div className={classes.clientGroupNameHeading}>
        <Typography variant='h6' className={classes.clientGroupBreadcrumb}>
          Client Groups
        </Typography>
        <Typography variant='h6' className={classes.clientGroupNameHeadText}>
          <Tooltip title='Back to Client Groups'>
            <IconButton
              variant='contained'
              color='secondary'
              aria-label='Back to Client Groups'
              onClick={onClick}
              className={classes.clientGroupNameBackIcon}
            >
              <BackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.clientGroupNameText}>
            {clientGroup ? clientGroup.name : ''}
          </div>
          <span
            className={
              clientGroup && clientGroup.status === 'paused'
                ? classes.pausedStatus
                : clientGroup && clientGroup.status === 'inactive'
                ? classes.inactiveStatus
                : clientGroup && clientGroup.status === 'active'
                ? classes.activeStatus
                : clientGroup && clientGroup.status === 'onboarding'
                ? classes.onboardingStatus
                : clientGroup && clientGroup.status === 'failedOnboarding'
                ? classes.failedOnboardingStatus
                : ''
            }
          >
            <StatusIcon />
          </span>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const clientGroupData = state.firestore.ordered.clientGroupDetailInfo;
  const clientGroup =
    isLoaded(clientGroupData) &&
    !isEmpty(clientGroupData) &&
    clientGroupData[0].id === props.clientGroupId
      ? clientGroupData[0]
      : null;
  return { clientGroup };
};

ClientGroupName.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'clientGroups',
      doc: props.clientGroupId,
      storeAs: 'clientGroupDetailInfo'
    }
  ]),
  withStyles(styles, { withTheme: true }),
  withRouter
)(ClientGroupName);
