import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import RootRef from '@material-ui/core/RootRef';

const DropzoneButton = ({ handleUpload }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      const notDenticonCheck = fileArray => {
        return fileArray.some(file => !isDenticon(file.name));
      };

      if (
        (acceptedFiles.length > 1 && notDenticonCheck(acceptedFiles)) ||
        acceptedFiles.length > 3
      )
        return;
      if (acceptedFiles.length > 1 && failedDenticonGrouping(acceptedFiles))
        return;
      handleUpload(acceptedFiles);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'text/plain, text/csv, application/vnd.ms-excel'
  });

  const isDenticon = fileName => {
    const isDenticonRegex = /[1-3]\.xls/;
    return isDenticonRegex.test(fileName);
  };

  const failedDenticonGrouping = fileArray => {
    const denticonUid = new RegExp(fileArray[0].name.split('_')[1]);
    return fileArray.some(file => !denticonUid.test(file.name));
  };

  const { ref, ...rootProps } = getRootProps();

  return (
    <RootRef rootRef={ref}>
      <Button variant='outlined' color='primary' type='button' {...rootProps}>
        <input {...getInputProps()} />
        Upload a List
      </Button>
    </RootRef>
  );
};

export default DropzoneButton;
