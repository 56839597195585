/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

const FieldText = ({
  classes,
  field,
  form,
  label,
  multiline,
  placeholder,
  rowsMax,
  variant,
  ...props
}) => {
  const { errors, submitCount, touched } = form;

  return (
    <TextField
      {...field}
      {...props}
      className={classes.textField}
      margin='normal'
      placeholder={placeholder}
      label={label}
      error={!!((touched[field.name] || submitCount > 0) && errors[field.name])}
      helperText={
        (touched[field.name] || submitCount > 0) && errors[field.name]
          ? errors[field.name]
          : ''
      }
      variant={variant}
      multiline={multiline || false}
      rowsMax={rowsMax || null}
      value={field.value === undefined ? '' : field.value}
      InputProps={
        field.name === 'balance'
          ? {
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              )
            }
          : null
      }
    />
  );
};

FieldText.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  rowsMax: PropTypes.number,
  variant: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(FieldText);
