export const styles = theme => ({
  buttonBase: {
    width: '100%',
    marginBottom: 0,
    minHeight: 48,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
    fontSize: theme.spacing.unit * 2.5
  },
  clickableText: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  firstCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '28px !important',
    marginRight: '34px !important'
  },
  firstColumn: {
    marginLeft: 10,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  },
  headerRow: {
    height: 24,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
    margin: '0px auto',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px !important'
    },
    '& > div:first-child': {
      padding: '0px !important',
      paddingLeft: '34px !important',
      justifyContent: 'flex-start'
    },
    '& span': {
      fontSize: theme.spacing.unit * 1.4
    },
    [theme.breakpoints.down('xs')]: {}
  },
  headerRowText: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '8vh'
  },
  offices: {
    height: theme.containers.paper.height.default,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '62vh'
    }
  },
  messageText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '8vh',
    fontSize: 14,
    fontStyle: 'italic'
  },
  root: {
    width: theme.containers.paper.width.default,
    margin: theme.containers.paper.margin,
    [theme.breakpoints.down('lg')]: { width: theme.containers.paper.width.lg },
    [theme.breakpoints.down('md')]: { width: theme.containers.paper.width.md },
    [theme.breakpoints.down('sm')]: { width: theme.containers.paper.width.sm }
  },
  rowIndex: {
    marginRight: 10,
    fontSize: '10px !important',
    paddingRight: 2
  },
  searchField: {
    width: '100%'
  },
  searchFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  searchIcon: {
    marginRight: 6
  },
  tableContainer: {
    width: '100%',
    margin: '0px auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '55vh',
    [theme.breakpoints.down('xs')]: {
      height: '49vh'
    }
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tableText: {
    fontSize: theme.spacing.unit * 1.5,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  titleContainer: {
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    height: theme.spacing.unit * 5
  }
});
