export const clientGroupColumns = [
  {
    index: 1,
    key: 'name',
    essential: true,
    text: 'Name',
    spacing: { xs: 8, sm: 4 },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'offices.length',
    essential: false,
    text: 'Offices',
    spacing: { xs: 3, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 3,
    key: 'status',
    essential: true,
    text: 'Status',
    spacing: { xs: 4, sm: 1 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  }
];
