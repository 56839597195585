import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import Schedule from '@material-ui/icons/Schedule';
import PhoneCallback from '@material-ui/icons/PhoneCallback';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import theme from '../../../../../services/theme';
import DashboardCard from './DashboardCard';

const SectionOverview = props => {
  const { classes } = props;

  return (
    <div>
      <Typography
        className={`${classes.sectionTitle} ${classes.overviewSectionTitle}`}
        variant='subtitle2'
      />
      <Grid container className={classes.sectionSectionBody} spacing={24}>
        <Grid item xs={12} sm={6}>
          <DashboardCard
            accentColor='#055f5bE6'
            elevation={4}
            icon={<Schedule />}
            stat={props.schToday}
            secondStat={props.schCurrentMonth}
            secondStatText={`${props.now.format('MMMM')} Total`}
            titleText={props.now.format('MMM Do')}
            subtitleText='Scheduled Appts'
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.secondSection}>
          <DashboardCard
            accentColor={`${theme.palette.tertiary.main}E6`}
            elevation={4}
            icon={<PhoneCallback />}
            stat={props.callsAnsToday}
            secondStat={props.callsAnsMonth}
            secondStatText={`${props.now.format('MMMM')} Total`}
            titleText={props.now.format('MMM Do')}
            subtitleText='Calls Answered'
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const now = moment.tz('America/Denver');
  const today = now.format('YYYY-MM-DD');
  const currentMonth = now.format('YYYY-MM');
  const colls = {
    scheduled: {
      daily: state.firestore.ordered.overallStatsSch,
      monthly: state.firestore.ordered.overallStatsSchMonthly
    },
    callsAnswered: {
      daily: state.firestore.ordered.overallStatsCallsAns,
      monthly: state.firestore.ordered.overallStatsCallsAnsMonthly
    }
  };
  let dataReady = false;
  let schToday = 0;
  let schCurrentMonth = 0;
  let callsAnsToday = 0;
  let callsAnsMonth = 0;

  for (const collection in colls) {
    const { daily } = colls[collection];
    const { monthly } = colls[collection];
    dataReady =
      isLoaded(daily) &&
      !isEmpty(daily) &&
      isLoaded(monthly) &&
      !isEmpty(monthly);
  }

  if (dataReady) {
    schToday = colls.scheduled.daily[0][today]
      ? colls.scheduled.daily[0][today]
      : 0;
    schCurrentMonth = colls.scheduled.monthly[0][currentMonth]
      ? colls.scheduled.monthly[0][currentMonth]
      : 0;
    callsAnsToday = colls.callsAnswered.daily[0][today]
      ? colls.callsAnswered.daily[0][today]
      : 0;
    callsAnsMonth = colls.callsAnswered.monthly[0][currentMonth]
      ? colls.callsAnswered.monthly[0][currentMonth]
      : 0;
  }

  return { now, schToday, schCurrentMonth, callsAnsToday, callsAnsMonth };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(_ => [
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'scheduled', doc: 'daily' }],
      storeAs: 'overallStatsSch'
    },
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'scheduled', doc: 'monthly' }],
      storeAs: 'overallStatsSchMonthly'
    },
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'callsAnswered', doc: 'daily' }],
      storeAs: 'overallStatsCallsAns'
    },
    {
      collection: 'statistics',
      doc: 'overall',
      subcollections: [{ collection: 'callsAnswered', doc: 'monthly' }],
      storeAs: 'overallStatsCallsAnsMonthly'
    }
  ])
)(SectionOverview);
