import React, { useEffect, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Tooltip
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';
import Fade from 'react-reveal/Fade';

const FilePreview = ({
  file,
  classes,
  status,
  checkDrProfile,
  clientId,
  drProfileChecked,
  cancel,
  multipleDenticonFiles,
  isDenticon
}) => {
  const checkDrProfileUseCallback = useCallback(checkDrProfile, []);

  useEffect(() => {
    if (!drProfileChecked && !isDenticon) {
      setTimeout(() => {
        checkDrProfileUseCallback(clientId);
      }, 420);
    }
  }, [
    clientId,
    checkDrProfileUseCallback,
    drProfileChecked,
    multipleDenticonFiles,
    isDenticon
  ]);

  const convertBytes = bytes => {
    if (bytes === 0) return 'n/a';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (index === 0) return bytes + ' ' + sizes[index];
    return (bytes / Math.pow(1024, index)).toFixed(1) + ' ' + sizes[index];
  };

  const renderActionAndStatus = () => {
    if (
      status === 'ready' ||
      status === 'denticonNotReady' ||
      status === 'denticonReady'
    ) {
      return (
        <Tooltip title={<Typography color='inherit'>Remove File</Typography>}>
          <Close
            color='primary'
            onClick={() => cancel('File removed.')}
            className={classes.closeIcon}
          />
        </Tooltip>
      );
    } else if (status === 'uploading') {
      return <CircularProgress variant='indeterminate' color='secondary' />;
    }
  };

  return (
    <Fade bottom duration={400}>
      <ListItem className={classes.fileStatusReady}>
        <ListItemAvatar>
          <Avatar>
            <Description />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.fileText}
          primary={file.name}
          secondary={convertBytes(file.size)}
        />
        {renderActionAndStatus()}
      </ListItem>
    </Fade>
  );
};

export default FilePreview;
