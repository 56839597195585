import React from 'react';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

const OfficeSelection = ({
  offices,
  setOfficeClient,
  variant,
  fileName,
  optionValue,
  optionText,
  optionKey,
  classes
}) => {
  const resolvePath = (obj, objPath) => {
    let path = objPath;
    path = path.split('.');
    let current = obj;
    while (path.length) {
      if (typeof current !== 'object') return undefined;
      current = current[path.shift()];
    }
    return current;
  };

  const renderOffices = offices => {
    return _.map(offices, office => {
      return (
        <option key={office[optionKey]} value={office[optionValue]}>
          {optionText.includes('.')
            ? resolvePath(office, optionText)
            : optionText instanceof Array
            ? `${office[optionText[0]]} ${office[optionText[1]]}`
            : office[optionText]}
        </option>
      );
    });
  };

  return (
    <>
      <FormControl
        variant={variant}
        className={classes.formControlOfficeSelection}
      >
        <Select
          native
          className={classes.selectOfficeSelection}
          onChange={event => setOfficeClient(fileName, event.target.value)}
          input={<OutlinedInput labelWidth={0} />}
        >
          <option value=''>Select Office</option>
          {offices ? renderOffices(offices) : ''}
        </Select>
      </FormControl>
    </>
  );
};

export default OfficeSelection;
