import {
  DIALOG_EDIT_TEAM_NAME_CLICK,
  DIALOG_EDIT_TEAM_NAME_ITEM
} from './types';

export const dialogEditTeamNameClick = status => ({
  type: DIALOG_EDIT_TEAM_NAME_CLICK,
  payload: status
});

export const dialogEditTeamNameItem = item => ({
  type: DIALOG_EDIT_TEAM_NAME_ITEM,
  payload: item
});
