import {
  DIALOG_TEAM_ENTRIES_DETAILS_CLICK,
  DIALOG_TEAM_ENTRIES_DETAILS_ITEM
} from './types';

export const dialogTeamEntriesDetailsClick = status => ({
  type: DIALOG_TEAM_ENTRIES_DETAILS_CLICK,
  payload: status
});

export const dialogTeamEntriesDetailsItem = item => ({
  type: DIALOG_TEAM_ENTRIES_DETAILS_ITEM,
  payload: item
});
