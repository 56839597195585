import _ from 'lodash';
import React, { Component } from 'react';
import AddUserIcon from '@material-ui/icons/AddCircleOutline';
import RemoveUserIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import { Field } from 'redux-form';
import TextField from '../../../../../../../components/TextField';
import { styles } from './styles';
import { validations } from '../../../../../../../services/validation';

class OfficeUsersArray extends Component {
  componentDidMount() {
    const { fields, formData } = this.props;

    formData.officeUsers &&
      formData.officeUsers.forEach(user => {
        fields.push(user);
      });
  }

  renderAddUserButton(fields) {
    const { classes } = this.props;

    return (
      <Tooltip title='Add User'>
        <IconButton
          variant='contained'
          color='secondary'
          aria-label='Add User'
          onClick={() => fields.push({})}
        >
          <AddUserIcon className={classes.addIcon} />
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    const { classes, change, fields } = this.props;
    const userTextFields = [
      {
        key: 'email',
        label: 'Email',
        validations: [validations.required, validations.email]
      },
      {
        key: 'firstName',
        label: 'First Name',
        validations: [validations.required]
      },
      {
        key: 'lastName',
        label: 'Last Name',
        validations: [validations.required]
      }
    ];
    const userCheckboxFields = [
      { key: 'reportEmail', label: 'Report Email' },
      { key: 'billingEmail', label: 'Billing Email' },
      { key: 'isAdmin', label: 'Admin' }
    ];

    return (
      <span>
        {fields.map((user, index) => (
          <span key={index} style={{ display: 'block', width: '100%' }}>
            <div
              className={classes.subSectionTitle}
              style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant='caption'>User #{index + 1}</Typography>
              <Tooltip title='Remove User'>
                <IconButton
                  variant='contained'
                  color='secondary'
                  aria-label='Remove User'
                  style={{ padding: 0, marginLeft: 8 }}
                  onClick={() => fields.remove(index)}
                >
                  <RemoveUserIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.userRow}>
              {_.map(userTextFields, field => (
                <Field
                  key={field.key}
                  name={`${user}.${field.key}`}
                  component={TextField}
                  label={field.label}
                  className={classes.dialogTextField}
                  validate={field.validations}
                />
              ))}
              {_.map(userCheckboxFields, checkboxField => (
                <Field
                  key={checkboxField.key}
                  name={`${user}.${checkboxField.key}`}
                  label={checkboxField.label}
                  component={({ input, label }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.offeringCheckbox}
                          checked={!!input.value}
                          onChange={() => {
                            change(
                              `${user}.${checkboxField.key}`,
                              !input.value
                            );
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant='body1'
                          className={classes.offeringCheckboxLabel}
                        >
                          {label}
                        </Typography>
                      }
                    />
                  )}
                />
              ))}
            </div>
          </span>
        ))}
        {this.renderAddUserButton(fields)}
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OfficeUsersArray);
