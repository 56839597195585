import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  FieldCheckbox,
  FieldSelect,
  FieldText
} from '../../../../../components/Fields';
import { Button, Typography } from '@material-ui/core';
import DropzoneButton from './DropzoneButton';
import { pms as pmsOptions } from '../../../../../services/options';
import helpers from '../../../../../helpers';
import { required } from '../../../../../validation';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { db } from '../../../../../index';

const NewListForm = ({
  classes,
  isDenticon,
  drProfile,
  allOffices,
  files,
  employeeFullName,
  setStatusMessage,
  clearFileStateAndSetResponse,
  clientId,
  setFileStatus,
  firebase,
  autoLocation,
  handleUpload,
  denticonWarning,
  denticonIsReady,
  addProblematicCsvs
}) => {
  const [disableInputs, setDisableInputs] = useState({
    pms: true,
    listConfig: true,
    office: true
  });
  const [updateDrProfile, setUpdateDrProfile] = useState(false);
  const [fileIsReady, setFileIsReady] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(null);
  const [initialValues] = useState({
    office: '',
    pms: '',
    officeName: '',
    listConfig: {
      ageParams: {
        minAge: 1,
        maxAge: 200
      },
      pediatric: false,
      balance: 300,
      location: '',
      filterMedicaid: false,
      timeOverdue: {
        minMonths: 6,
        maxMonths: 36
      }
    }
  });

  const pmsToFileExtensionCheck = values => {
    let correctExtension = true;
    const pmsFileExtension = Object.freeze({
      dentrixAscend: '.csv',
      dentrix: '.txt',
      denticon: '.xlsx',
      eagleSoft: '.txt',
      openDental: '.txt',
      curve: '.csv'
    });
    const { pms } = values;
    const fileExtension = pmsFileExtension[pms];
    const errorMsg = `${pms} requires a ${fileExtension} file extension.`;
    // Denticon requires three separate xlsx files. Checking each name.
    if (pms === 'denticon') {
      for (const file of files) {
        const { name } = file;
        if (!name.includes(fileExtension)) {
          setStatusMessage(errorMsg);
          correctExtension = false;
        }
      }
    } else {
      const { name } = files[0];
      if (!name.includes(fileExtension)) {
        setStatusMessage(errorMsg);
        correctExtension = false;
      }
    }

    return correctExtension;
  };

  const onSubmit = async values => {
    if (pmsToFileExtensionCheck(values)) {
      setFileStatus('uploading');
      try {
        const { listConfig, pms, office } = values;
        if (!updateDrProfile) {
          saveConvertedFile(listConfig, pms);
        } else {
          const updatedValues = { listConfig, pms, officeName: autoLocation };
          const response = await updateDrProfileDb(updatedValues, office);
          if (response) {
            await saveConvertedFile(listConfig, pms);
          } else {
            setStatusMessage(
              'Error in updating drProfile with current values. Contact admin.'
            );
            setFileStatus('ready');
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const saveConvertedFile = async (listConfig, pms) => {
    const problematicCsvs = [];
    try {
      const form = constructFormData(listConfig, pms);
      const filteredFileData = await filterAndConvertFile(form);
      if (!filteredFileData) {
        clearFileStateAndSetResponse(
          'Error in converting file. Contact Admin.'
        );
        setFileStatus('ready');
      } else {
        const [data] = filteredFileData;
        const { csv, csvFileName } = data;
        if (!csv) problematicCsvs.push(csvFileName);
        const blob = new Blob(['\ufeff', csv]);
        if (problematicCsvs.length > 0) addProblematicCsvs(problematicCsvs);
        saveAs(blob, csvFileName);
        setFileStatus('');
        clearFileStateAndSetResponse('List converted. Please select new file.');
      }
    } catch (e) {
      console.error(e);
      setFileStatus('');
      clearFileStateAndSetResponse(
        'Error: Undefined data returned. Contact Admin.'
      );
    }
  };

  const filterAndConvertFile = async form => {
    try {
      const {
        REACT_APP_CLOUD_FUNCTIONS_BASE_URL
        // REACT_APP_DEV_EMULATOR
      } = process.env;
      const token = await firebase.auth().currentUser.getIdToken();
      const url = `${REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/five9-lists-http-upload`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post(url, form, config);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  const updateDrProfileDb = async (values, clientId) => {
    if (helpers.isEmpty(drProfile)) return;
    try {
      let updated;
      await db
        .collection('clients')
        .doc(clientId)
        .collection('drProfile')
        .doc('profile')
        .set(values, { merge: true })
        .then(() => (updated = true))
        .catch(e => {
          console.error(e);
          updated = false;
        });
      return updated;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const constructFormData = (listConfig, pms) => {
    const form = new FormData();
    const fileName = files[0].name;
    for (const file in files) {
      form.append(files[file].name, files[file]);
    }
    const filterParams = organizingFilterParamsIntoArray({ listConfig, pms });
    form.append('uploadedByEmployeeIdField', employeeFullName);
    form.append(
      `fileFilterParamsField_${
        checkIfDention(fileName) ? reformatDenticonFileName(fileName) : fileName
      }`,
      filterParams
    );
    form.append(
      `officeName_${
        checkIfDention(fileName) ? reformatDenticonFileName(fileName) : fileName
      }`,
      drProfile.officeName ? drProfile.officeName : autoLocation
    );
    return form;
  };

  const checkIfDention = fileName => {
    const regexIsDenticon = /.xls$/i;
    return regexIsDenticon.test(fileName);
  };

  const reformatDenticonFileName = fileName => {
    const regexDenticon = /_[1-3].xls/i;
    return fileName.replace(regexDenticon, '.xls');
  };

  const organizingFilterParamsIntoArray = drProfile => {
    // Array of single object is neccessary for backend converter data format.
    const listConfigArr = [helpers.flattenObj(drProfile)];
    return JSON.stringify(listConfigArr);
  };

  useEffect(() => {
    const updatedValuesConstruction = updatedValues => {
      return {
        office: updatedValues.id ? updatedValues.id : initialValues.office,
        pms: updatedValues.pms ? updatedValues.pms : initialValues.pms,
        officeName: updatedValues.officeName
          ? updatedValues.officeName
          : initialValues.officeName,
        listConfig: {
          ageParams: {
            minAge: updatedValues.listConfig
              ? updatedValues.listConfig.ageParams.minAge
              : initialValues.listConfig.ageParams.minAge,
            maxAge: updatedValues.listConfig
              ? updatedValues.listConfig.ageParams.maxAge
              : initialValues.listConfig.ageParams.maxAge
          },
          balance: updatedValues.listConfig
            ? updatedValues.listConfig.balance
            : initialValues.listConfig.balance,
          location: updatedValues.listConfig
            ? updatedValues.listConfig.location
            : autoLocation
            ? autoLocation
            : initialValues.listConfig.location,
          pediatric: updatedValues.listConfig
            ? updatedValues.listConfig.pediatric
            : initialValues.listConfig.pediatric,
          filterMedicaid: updatedValues.listConfig
            ? updatedValues.listConfig.filterMedicaid
            : initialValues.listConfig.filterMedicaid,
          timeOverdue: {
            minMonths: updatedValues.listConfig
              ? updatedValues.listConfig.timeOverdue.minMonths
              : initialValues.listConfig.timeOverdue.minMonths,
            maxMonths: updatedValues.listConfig
              ? updatedValues.listConfig.timeOverdue.maxMonths
              : initialValues.listConfig.timeOverdue.maxMonths
          }
        }
      };
    };

    if (drProfile) {
      const drProfileExistsButLacksPMSAndListConfig =
          !drProfile.listConfig && !drProfile.pms && drProfile.id,
        drProfileExistsButLacksListConfig =
          !drProfile.listConfig && drProfile.pms && drProfile.id,
        drProfileExistsButLacksPms =
          drProfile.listConfig && !drProfile.pms && drProfile.id,
        drProfileObjDoesNotExist = helpers.isEmpty(drProfile);
      let updatedValues;
      if (drProfileObjDoesNotExist) {
        updatedValues = updatedValuesConstruction({ id: clientId });
        newListBehavior(updatedValues);
      } else if (drProfileExistsButLacksPMSAndListConfig) {
        updatedValues = updatedValuesConstruction(drProfile);
        defaultBehaviorIfOnlyDrProfileObjectExists(updatedValues);
      } else if (drProfileExistsButLacksListConfig) {
        updatedValues = updatedValuesConstruction(drProfile);
        defaultBehaviorIfOfficeAndPMSExist(updatedValues);
      } else if (drProfileExistsButLacksPms) {
        updatedValues = updatedValuesConstruction(drProfile);
        defaultBehaviorIfOfficeAndListConfigExist(updatedValues);
      } else {
        updatedValues = updatedValuesConstruction(drProfile);
        defaultBehaviorIfDrProfileObjectsExist(updatedValues);
      }
    } else {
      defaultBehaviorIfReset();
    }
  }, [drProfile, autoLocation, initialValues, clientId]);

  const defaultBehaviorIfDrProfileObjectsExist = updatedValues => {
    setUpdatedValues(updatedValues);
    setDisableInputs({ office: true, pms: true, listConfig: true });
    setFileIsReady(true);
  };

  const defaultBehaviorIfOnlyDrProfileObjectExists = updatedValues => {
    setUpdatedValues(updatedValues);
    setUpdateDrProfile(true);
    setDisableInputs({ office: true, pms: false, listConfig: false });
    setFileIsReady(true);
  };

  const defaultBehaviorIfOfficeAndListConfigExist = updatedValues => {
    setUpdatedValues(updatedValues);
    setUpdateDrProfile(true);
    setDisableInputs({ office: true, pms: false, listConfig: true });
    setFileIsReady(true);
  };

  const defaultBehaviorIfOfficeAndPMSExist = updatedValues => {
    setUpdatedValues(updatedValues);
    setUpdateDrProfile(true);
    setDisableInputs({ office: true, pms: true, listConfig: false });
    setFileIsReady(true);
  };

  const newListBehavior = updatedValues => {
    setUpdatedValues(updatedValues);
    setUpdateDrProfile(true);
    setDisableInputs({
      office: updatedValues.office && true,
      pms: false,
      listConfig: false
    });
    setFileIsReady(true);
  };

  const defaultBehaviorIfReset = () => {
    setUpdatedValues(null);
    setDisableInputs({ office: true, pms: true, listConfig: true });
    setFileIsReady(false);
  };

  const renderWarning = warning => (
    <Typography color='secondary' className={classes.denticonWarning}>
      {warning}
    </Typography>
  );

  return (
    <Formik
      initialValues={updatedValues ? updatedValues : initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className={classes.row}>
            <Field
              disabled={disableInputs.office}
              name='office'
              label='Office'
              options={_.sortBy(allOffices, 'officeInformation.name')}
              optionKey='id'
              optionText='officeInformation.name'
              optionValue='id'
              margin='none'
              component={FieldSelect}
              validate={disableInputs.office ? null : required}
            />

            <Field
              disabled={disableInputs.pms}
              name='pms'
              label='PMS'
              options={pmsOptions}
              optionKey='key'
              optionText='name'
              optionValue='key'
              margin='none'
              component={FieldSelect}
              validate={disableInputs.pms ? null : required}
            />
          </div>

          <div className={classes.row}>
            <Field
              disabled={disableInputs.listConfig}
              type={'number'}
              name='listConfig.ageParams.minAge'
              label='Min Age'
              margin='none'
              variant='outlined'
              component={FieldText}
              validate={disableInputs.listConfig ? null : required}
            />

            <Field
              disabled={disableInputs.listConfig}
              name='listConfig.ageParams.maxAge'
              label='Max Age'
              type={'number'}
              margin='none'
              variant='outlined'
              component={FieldText}
              validate={disableInputs.listConfig ? null : required}
            />

            <div className={classes.isPediatricRoot}>
              <FieldCheckbox
                disabled={disableInputs.listConfig}
                name='listConfig.pediatric'
                label='Is Pediatric'
                checked={!!values.listConfig.pediatric}
                component={FieldCheckbox}
                // validate is not necessary for pediatric
              />
            </div>
          </div>

          <div className={classes.row}>
            <Field
              disabled={disableInputs.listConfig}
              name='listConfig.balance'
              label='Balance'
              margin='none'
              variant='outlined'
              type={'number'}
              component={FieldText}
              validate={disableInputs.listConfig ? null : required}
            />

            <Field
              disabled={disableInputs.listConfig}
              name='listConfig.location'
              label='Location'
              margin='none'
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
              component={FieldText}
              // validate is not required for location
            />
          </div>

          <div className={classes.row}>
            <Field
              disabled={disableInputs.listConfig}
              name='listConfig.timeOverdue.minMonths'
              type={'number'}
              label='Minimum Months'
              margin='none'
              variant='outlined'
              component={FieldText}
              validate={disableInputs.listConfig ? null : required}
            />

            <Field
              disabled={disableInputs.listConfig}
              name='listConfig.timeOverdue.maxMonths'
              type={'number'}
              label='Maximum Months'
              margin='none'
              variant='outlined'
              component={FieldText}
              validate={disableInputs.listConfig ? null : required}
            />
          </div>

          <div className={classes.row}>
            <div className={classes.filterMedicaidRoot}>
              <FieldCheckbox
                name='listConfig.filterMedicaid'
                label='Filter Medicaid'
                disabled={disableInputs.listConfig}
                // validate is not required for filter medicaid
              />
            </div>

            {isDenticon && !denticonIsReady ? (
              <div className={classes.dropzoneButtonRoot}>
                <DropzoneButton handleUpload={handleUpload} />
                {denticonWarning ? renderWarning(denticonWarning) : null}
              </div>
            ) : null}

            <div className={classes.submitButtonRoot}>
              {(!isDenticon && fileIsReady) ||
              (isDenticon && denticonIsReady) ? (
                <Button color='primary' variant='outlined' type='submit'>
                  Submit
                </Button>
              ) : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewListForm;
