import moment from 'moment';

const rdate = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const rphone = /^\+?[1-9]{1}[0-9]{3,14}$/;
const rtime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

const isFutureDate = value => moment(value).add(1, 'days').isAfter(moment());

const futureDate = value =>
  value && !rdate.test(value)
    ? 'Invalid Date (YYYY-MM-DD)'
    : value && !isFutureDate(value)
    ? 'Please Select A Future Date'
    : undefined;
const date = value =>
  value && !rdate.test(value) ? 'Invalid Date (YYYY-MM-DD)' : undefined;
const phone = value =>
  value && !rphone.test(value.replace(/[^0-9]/g, ''))
    ? 'Invalid Phone Number'
    : undefined;
const required = value => (value ? undefined : 'Required');
const time = value =>
  value && !rtime.test(value) ? 'Invalid Time' : undefined;

export const drProfileValidationObj = {
  maxAge: value => typeof value === 'number',
  minAge: value => typeof value === 'number',
  balance: value => typeof value === 'number',
  filterMedicaid: value => typeof value === 'boolean',
  location: value => value !== '' && typeof value === 'string',
  pediatric: value => typeof value === 'boolean',
  maxMonths: value => typeof value === 'number',
  minMonths: value => typeof value === 'number',
  pms: value => value !== '' && typeof value === 'string',
  officeName: value => value !== '' && typeof value === 'string'
};

export { futureDate, date, phone, required, time };
