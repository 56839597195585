import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import PricingPlan from './PricingPlan';

const OfferingContent = ({
  classes,
  offering,
  setAddPricingPlanDialogOpen,
  setSelectedOffering
}) => {
  const offeringDescription = offering.description ? (
    <>
      <Typography variant='caption' className={classes.contentSectionHeader}>
        Description
      </Typography>
      <Typography variant='body2' className={classes.contentSectionContent}>
        {offering.description}
      </Typography>
    </>
  ) : null;

  return (
    <div className={classes.contentSection}>
      {offeringDescription}
      <span className={classes.contentSectionHeaderContainer}>
        <Typography variant='caption' className={classes.contentSectionHeader}>
          Pricing Plans
        </Typography>
        <Tooltip title={`Add ${offering.name} Pricing Plan`}>
          <IconButton
            color='inherit'
            aria-label={`Add ${offering.name} Pricing Plan`}
            className={classes.contentSectionHeaderIcon}
            onClick={() => {
              setSelectedOffering(offering);
              setAddPricingPlanDialogOpen(true);
            }}
            component='span'
            disableRipple
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </span>
      {Object.keys(offering.pricing).map((key, i) =>
        offering.pricing[key].hideAsOption ? null : (
          <PricingPlan key={i} id={key} plan={offering.pricing[key]} />
        )
      )}
    </div>
  );
};

const styles = theme => ({
  contentSection: {
    '& > span': {
      marginTop: 12
    },
    '& > span:first-child': {
      marginTop: 4
    }
  },
  contentSectionHeaderContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  contentSectionHeader: {
    color: theme.palette.secondary.main,
    fontSize: theme.spacing.unit * 1.4,
    textTransform: 'uppercase',
    marginLeft: 4,
    marginRight: 4
  },
  contentSectionHeaderIcon: {
    padding: 2,
    '& svg': {
      marginRight: 0
    },
    '&:focus': {
      backgroundColor: '#ddd'
    }
  },
  contentSectionContent: {
    marginLeft: 4
  }
});

OfferingContent.propTypes = {
  classes: PropTypes.object.isRequired,
  offering: PropTypes.object.isRequired,
  setAddPricingPlanDialogOpen: PropTypes.func.isRequired,
  setSelectedOffering: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(OfferingContent);
