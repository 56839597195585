import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import PricingPlanDetails from '../../../../../../../components/Billing/PricingPlanDetails';
import { CALLFORCE_TZ } from '../../../../../../../services/constants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfferingTimeActiveEntryClick,
  dialogEditOfferingTimeActiveEntryItem
} from '../../../../../../../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { withStyles } from '@material-ui/core';

class OfferingTimeActiveItem extends Component {
  state = { planDetailsOpen: false, planName: null };

  async componentDidMount() {
    const {
      firestore,
      item: { pricing },
      offering
    } = this.props;
    let planName;

    try {
      const doc = await firestore.get({
        collection: 'offerings',
        doc: offering.id
      });
      const offeringData = { ...doc.data(), id: doc.id };

      if (t(offeringData, `pricing.${pricing.plan}.name`).safeString) {
        planName = `${offeringData.pricing[pricing.plan].name.replace(
          ' Plan',
          ''
        )} Plan`;
      } else {
        planName = `${_.startCase(pricing.plan)} Plan`;
      }

      this.setState({ planName });
    } catch (err) {
      console.log(err);
    }
  }

  changePlanDetailsView = () => {
    this.setState({ planDetailsOpen: !this.state.planDetailsOpen });
  };

  renderPlanDetailsButton() {
    const { classes } = this.props;
    const { planDetailsOpen } = this.state;

    return (
      <span
        onClick={this.changePlanDetailsView}
        className={classes.planDetailsButton}
      >
        {planDetailsOpen ? 'Hide' : 'Show'}
      </span>
    );
  }

  render() {
    const { classes, item, office, offering } = this.props;
    const { planDetailsOpen, planName } = this.state;

    return (
      <div>
        <div className={classes.timeActiveTitle}>
          <span style={{ fontSize: 12, marginRight: 12 }}>End:</span>
          {item.current && !item.endDate
            ? 'Present'
            : moment
                .unix(item.endDate.seconds)
                .tz(CALLFORCE_TZ)
                .format('MMM D, YYYY, h:mm a z')}
        </div>
        <div className={classes.timeActiveTitle}>
          <span style={{ fontSize: 12, marginRight: 6 }}>Start:</span>
          {moment
            .unix(item.startDate.seconds)
            .tz(CALLFORCE_TZ)
            .format('MMM D, YYYY, h:mm a z')}
        </div>
        <div className={classes.timeActiveTitle}>
          <span style={{ fontSize: 12, marginRight: 9 }}>Plan:</span>
          {planName ? planName : null}
          {this.renderPlanDetailsButton()}
        </div>
        <div style={!planDetailsOpen ? { display: 'none' } : { marginTop: 12 }}>
          <PricingPlanDetails
            office={office}
            pricingDetails={
              item.pricing.plan === 'custom'
                ? item.pricing.pricing
                : offering.pricing[item.pricing.plan]
            }
          />
        </div>
        <span
          onClick={() => {
            this.props.dialogEditOfferingTimeActiveEntryClick(true);
            this.props.dialogEditOfferingTimeActiveEntryItem({
              office,
              offering,
              entryItem: item
            });
          }}
          className={classes.editButton}
        >
          Edit
        </span>
      </div>
    );
  }
}

export const styles = theme => ({
  timeActiveTitle: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  planDetailsButton: {
    fontSize: 10,
    marginLeft: 8,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editButton: {
    fontSize: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default compose(
  connect(null, {
    dialogEditOfferingTimeActiveEntryClick,
    dialogEditOfferingTimeActiveEntryItem
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(OfferingTimeActiveItem);
