import {
  DIALOG_EDIT_OFFICE_INFO_CLICK,
  DIALOG_EDIT_OFFICE_INFO_ITEM
} from './types';

export const dialogEditOfficeInfoClick = status => ({
  type: DIALOG_EDIT_OFFICE_INFO_CLICK,
  payload: status
});

export const dialogEditOfficeInfoItem = item => ({
  type: DIALOG_EDIT_OFFICE_INFO_ITEM,
  payload: item
});
