export const SCHEDULED = 'SCHEDULED';
export const SCHEDULED_OVERALL_COUNT = 'SCHEDULED_OVERALL_COUNT';
export const RESCHEDULED = 'RESCHEDULED';
export const RESCHEDULED_OVERALL_COUNT = 'RESCHEDULED_OVERALL_COUNT';
export const NOT_SCHEDULED = 'NOT_SCHEDULED';
export const NOT_SCHEDULED_OVERALL_COUNT = 'NOT_SCHEDULED_OVERALL_COUNT';
export const CALLS_ANSWERED = 'CALLS_ANSWERED';
export const CALLS_ANSWERED_OVERALL_COUNT = 'CALLS_ANSWERED_OVERALL_COUNT';
export const OFFICES = 'OFFICES';
export const EMPLOYEES = 'EMPLOYEES';
export const DIALOG_ADD_ENTRY_CLIENTS = 'DIALOG_ADD_ENTRY_CLIENTS';
export const TEAM_LEAD_TEAM = 'TEAM_LEAD_TEAM';
export const TEAM_MEMBER_DETAILS = 'TEAM_MEMBER_DETAILS';
export const TEAM_MEMBER_STATS = 'TEAM_MEMBER_STATS';
export const EMPLOYEE_DETAILS = 'EMPLOYEE_DETAILS';
export const TEAM_MEMBER_MISTAKES_STATS = 'TEAM_MEMBER_MISTAKES_STATS';
export const OVERALL_STATISTICS = 'OVERALL_STATISTICS';
export const ENTRIES_CALLING_MANAGMENT = 'ENTRIES_CALLING_MANAGMENT';
