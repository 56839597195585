export const employeesColumns = [
  {
    index: 1,
    key: 'email',
    essential: true,
    text: 'Email',
    spacing: { xs: 8, sm: 4 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 2,
    key: 'firstName',
    essential: false,
    text: 'First Name',
    spacing: { xs: null, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: true,
    defaultSort: 'asc'
  },
  {
    index: 3,
    key: 'lastName',
    essential: false,
    text: 'Last Name',
    spacing: { xs: null, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 4,
    key: 'username',
    essential: false,
    text: 'Username',
    spacing: { xs: null, sm: 2 },
    icon: false,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  },
  {
    index: 5,
    key: 'active',
    essential: true,
    text: 'Active',
    spacing: { xs: 4, sm: 2 },
    icon: true,
    sortable: true,
    defaultSortColumn: false,
    defaultSort: 'asc'
  }
];
