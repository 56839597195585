// import { db } from '../../../../index';

const recallFilters = [
  {
    index: 1,
    key: 'active',
    options: [
      { key: 'true', text: 'Active', value: 'active,==,true' },
      { key: 'false', text: 'Inactive', value: 'active,==,false' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Active Status'
  },
  {
    index: 2,
    key: 'groups',
    options: [
      {
        key: 'callsAnswered',
        text: 'Calls Answered',
        value: 'groups,array-contains,callsAnswered'
      },
      {
        key: 'scheduled',
        text: 'Scheduled',
        value: 'groups,array-contains,scheduled'
      },
      {
        key: 'treatment',
        text: 'Treatment',
        value: 'groups,array-contains,treatment'
      },
      {
        key: 'websiteChat',
        text: 'Website Chat',
        value: 'groups,array-contains,websiteChat'
      },
      {
        key: 'financialController',
        text: 'Financial Controller',
        value: 'groups,array-contains,financialController'
      },
      {
        key: 'customerManagement',
        text: 'Customer Management',
        value: 'groups,array-contains,customerManagement'
      },
      { key: 'admin', text: 'Admin', value: 'groups,array-contains,admin' },
      { key: 'dev', text: 'Dev', value: 'groups,array-contains,dev' },
      {
        key: 'callingManagement',
        text: 'Calling Management',
        value: 'groups,array-contains,callingManagement'
      },
      {
        key: 'teamLead',
        text: 'Team Lead',
        value: 'groups,array-contains,teamLead'
      },
      {
        key: 'noCommissions',
        text: 'No Commissions',
        value: 'groups,array-contains,noCommissions'
      },
      {
        key: 'contractor',
        text: 'Contractor',
        value: 'groups,array-contains,contractor'
      }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'array-contains',
    label: 'Groups'
  }
];

export default recallFilters;
