import {
  SCHEDULED_ADD,
  SCHEDULED_DELETE,
  SCHEDULED_EDIT,
  SCHEDULED_INITIALIZE,
  SCHEDULED_SEARCH
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case SCHEDULED_ADD:
      return action.payload;
    case SCHEDULED_DELETE:
      return action.payload;
    case SCHEDULED_EDIT:
      return action.payload;
    case SCHEDULED_INITIALIZE:
      return action.payload;
    case SCHEDULED_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
