import React from 'react';
import {
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Tooltip
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';
import Fade from 'react-reveal/Fade';
import helpers from '../../../../../helpers';

const FilePreview = ({
  classes,
  status,
  cancel,
  fileName,
  fileSize,
  uploadStatus,
  drProfileStatus,
  officeName,
  drProfileParameters,
  toOfficeLink,
  drProfileObj,
  pmsObj,
  clientExists,
  OfficeSelection,
  selectedOtherOffice,
  clientId
}) => {
  const convertBytes = bytes => {
    if (bytes === 0) return 'n/a';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (index === 0) return bytes + ' ' + sizes[index];
    return (bytes / Math.pow(1024, index)).toFixed(1) + ' ' + sizes[index];
  };

  const renderActionAndStatus = () => {
    if (uploadStatus !== 'uploading') {
      return (
        <Tooltip title={<Typography color='inherit'>Remove File</Typography>}>
          <Close
            color='primary'
            onClick={() => cancel(fileName)}
            className={classes.closeIcon}
          />
        </Tooltip>
      );
    } else if (status === 'uploading') {
      return <CircularProgress variant='indeterminate' color='secondary' />;
    }
  };

  const renderDrProfileParameters = () => {
    const flattenedDrProfile = helpers.flattenObj(drProfileParameters);
    return Object.keys(flattenedDrProfile).map((parameter, index) => {
      const pmsLabel = pmsObj[flattenedDrProfile[parameter]];
      const otherLabel =
        flattenedDrProfile[parameter] ||
        typeof flattenedDrProfile[parameter] === 'boolean'
          ? flattenedDrProfile[parameter].toString()
          : 'Error';

      return (
        <li key={index}>
          {drProfileObj[parameter]}: {pmsLabel ? pmsLabel : otherLabel}
        </li>
      );
    });
  };

  const redirectToOfficeSettings = () => {
    window.open(toOfficeLink);
  };

  return (
    <Fade bottom duration={400}>
      <ListItem
        className={
          status === 'ready' && drProfileStatus && drProfileStatus.complete
            ? classes.fileStatusReady
            : status === 'ready' && drProfileStatus && !drProfileStatus.complete
            ? classes.fileStatusIncompleteDrProfile
            : status === 'notReady'
            ? classes.fileStatusNotReady
            : classes.fileStatusFailed
        }
      >
        <ListItemAvatar>
          <Tooltip
            title={
              drProfileParameters ? (
                <>
                  <Typography color='inherit'>
                    {' '}
                    DrProfile Parameters{' '}
                  </Typography>
                  {renderDrProfileParameters()}
                </>
              ) : (
                <Typography color='inherit'>
                  {' '}
                  Need DrProfile Params.{' '}
                </Typography>
              )
            }
          >
            <Avatar
              onClick={() => clientExists && redirectToOfficeSettings()}
              className={classes.filePreviewAvatar}
            >
              <Description />
            </Avatar>
          </Tooltip>
        </ListItemAvatar>

        <ListItemText className={classes.fileText}>
          {officeName ? (
            <Typography noWrap className={classes.officeName}>
              {officeName}
            </Typography>
          ) : !clientExists && status === 'failed' ? (
            OfficeSelection
          ) : selectedOtherOffice ? (
            <Typography noWrap className={classes.officeName}>
              {clientId} Chosen
            </Typography>
          ) : (
            <Typography noWrap className={classes.officeName}>
              Office Name
            </Typography>
          )}
        </ListItemText>
        <ListItemText
          className={classes.fileText}
          primary={
            status === 'ready' &&
            drProfileStatus &&
            drProfileStatus.complete ? (
              <Typography> Ready </Typography>
            ) : status === 'ready' &&
              drProfileStatus &&
              !drProfileStatus.complete ? (
              <Typography> Incomplete DrProfile </Typography>
            ) : status === 'notReady' ? (
              <Typography> Need DrProfile Params </Typography>
            ) : (
              <Typography> No DrProfile </Typography>
            )
          }
        />
        <ListItemText
          className={classes.fileText}
          primary={fileName}
          secondary={convertBytes(fileSize)}
        />
        {renderActionAndStatus()}
      </ListItem>
    </Fade>
  );
};

export default FilePreview;
