import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions
} from '@material-ui/core';

const ErrorAlert = ({ failedCsvs, open, handleClose, classes }) => {
  const renderFileNames = () => {
    return failedCsvs.map((fileName, index) => {
      return (
        <DialogContentText key={index} className={classes.errorDialogNames}>
          {fileName}
        </DialogContentText>
      );
    });
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      className={'root-dialog'}
      maxWidth='lg'
    >
      <DialogTitle>Severe Warning.</DialogTitle>
      <DialogContent className={classes.errorDialog}>
        <DialogContentText>
          The following csvs should be checked for errors as their data returned
          incomplete:
        </DialogContentText>
        {renderFileNames()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorAlert;
