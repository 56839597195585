import {
  DIALOG_EDIT_TEAM_MEMBERS_CLICK,
  DIALOG_EDIT_TEAM_MEMBERS_ITEM,
  DIALOG_DELETE_TEAM_MEMBERS_CLICK,
  DIALOG_DELETE_TEAM_MEMBERS_ITEM
} from './types';

export const dialogEditTeamMembersClick = status => ({
  type: DIALOG_EDIT_TEAM_MEMBERS_CLICK,
  payload: status
});

export const dialogEditTeamMembersItem = item => ({
  type: DIALOG_EDIT_TEAM_MEMBERS_ITEM,
  payload: item
});

export const dialogDeleteTeamMembersClick = status => ({
  type: DIALOG_DELETE_TEAM_MEMBERS_CLICK,
  payload: status
});

export const dialogDeleteTeamMembersItem = item => ({
  type: DIALOG_DELETE_TEAM_MEMBERS_ITEM,
  payload: item
});
