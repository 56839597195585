import React from 'react';
import {
  Dashboard,
  Domain,
  Group,
  Error,
  MonetizationOn,
  Person,
  Public,
  Storage,
  SupervisorAccount,
  SupervisedUserCircle,
  Assignment,
  Description
} from '@material-ui/icons';

/* groups
 * admin
 * callsAnswered
 * callingManagement
 * customerManagement
 * employee
 * financialController
 * recall (scheduled)
 * reschedule (rescheduled)
 * technicalSupport
 * treatment
 */

const sitemap = {
  home: {
    metadata: {
      route: '/',
      hide: true,
      restricted: false
    }
  },
  dashboards: {
    metadata: {
      route: '/dashboards',
      title: 'Dashboards',
      icon: () => <Dashboard />,
      maxDepth: 3,
      restricted: false,
      routesType: 'scenes'
    },
    overview: {
      metadata: {
        route: '/dashboards/overview',
        title: 'Overview',
        icon: () => <Public />,
        restricted: false
      }
    },
    management: {
      metadata: {
        route: '/dashboards/management',
        title: 'Management',
        icon: () => <SupervisorAccount />,
        restricted: true,
        permissions: {
          read: ['admin', 'callingManagement']
        },
        routesType: 'tabs',
        routesDefault: '/dashboards/management/entries'
      },
      entries: {
        metadata: {
          parent: 'dashboards.management',
          route: '/dashboards/management/entries',
          title: 'Entries',
          restricted: true,
          permissions: {
            read: ['admin', 'callingManagement']
          }
        }
      },
      mistakes: {
        metadata: {
          parent: 'dashboards.management',
          route: '/dashboards/management/mistakes',
          title: 'Mistakes',
          restricted: true,
          permissions: {
            read: ['admin', 'callingManagement']
          }
        }
      }
    },
    team: {
      metadata: {
        route: '/dashboards/team',
        title: 'Team',
        icon: () => <Group />,
        restricted: true,
        permissions: {
          read: ['teamLead']
        },
        routesType: 'tabs',
        routesDefault: '/dashboards/team/entries'
      },
      entries: {
        metadata: {
          parent: 'dashboards.team',
          route: '/dashboards/team/entries',
          title: 'Entries',
          restricted: true,
          permissions: {
            read: ['teamLead']
          }
        }
      },
      mistakes: {
        metadata: {
          parent: 'dashboards.team',
          route: '/dashboards/team/mistakes',
          title: 'Mistakes',
          restricted: true,
          permissions: {
            read: ['teamLead']
          }
        }
      }
    },
    personal: {
      metadata: {
        route: '/dashboards/personal',
        title: 'Personal',
        icon: () => <Person />,
        restricted: true,
        permissions: {
          read: [
            'recall',
            'scheduled',
            'treatment',
            'reschedule',
            'rescheduled',
            'callsAnswered'
          ]
        },
        routesType: 'tabs',
        routesDefault: '/dashboards/personal/entries'
      },
      entries: {
        metadata: {
          parent: 'dashboards.personal',
          route: '/dashboards/personal/entries',
          title: 'Entries',
          restricted: true,
          permissions: {
            read: [
              'recall',
              'scheduled',
              'treatment',
              'reschedule',
              'rescheduled',
              'callsAnswered'
            ]
          }
        }
      },
      mistakes: {
        metadata: {
          parent: 'dashboards.personal',
          route: '/dashboards/personal/mistakes',
          title: 'Mistakes',
          restricted: true,
          permissions: {
            read: [
              'recall',
              'scheduled',
              'treatment',
              'reschedule',
              'rescheduled',
              'callsAnswered'
            ]
          }
        }
      }
    },
    lists: {
      metadata: {
        route: '/dashboards/lists',
        title: 'Lists',
        icon: () => <Assignment />,
        restricted: true,
        permissions: {
          // only teamLead? admin? dev?
          read: ['teamLead']
        },
        routesType: 'tabs',
        routesDefault: '/dashboards/lists/teamLists'
      },
      // Team Lists ?
      teamLists: {
        metadata: {
          parent: 'dashboards.lists',
          route: '/dashboards/lists/teamLists',
          title: 'Team Lists',
          restricted: true,
          permissions: {
            // only teamLead? admin? dev?
            read: ['teamLead']
          }
        }
      }
    }
  },
  mistake: {
    metadata: {
      route: '/mistake',
      title: 'Mistakes',
      icon: () => <Error />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: ['admin', 'customerManagement', 'teamLead']
      },
      routesType: 'tabs',
      routesDefault: {
        admin: '/mistake/recall',
        recall: '/mistake/recall',
        scheduled: '/mistake/recall',
        treatment: '/mistake/treatment',
        reschedule: '/mistake/reschedule',
        rescheduled: '/mistake/reschedule',
        callsAnswered: '/mistake/calls-answered',
        websiteChat: '/mistake/websiteChat',
        voiceMails: '/mistake/voicemails',
        callingManagement: '/mistake/recall',
        customerManagement: '/mistake/recall',
        financialController: '/mistake/recall'
      }
    },
    recall: {
      metadata: {
        parent: 'mistake',
        route: '/mistake/recall',
        title: 'Recall',
        restricted: true,
        permissions: {
          read: ['admin', 'customerManagement', 'teamLead'],
          manage: ['admin', 'customerManagement', 'teamLead']
        }
      }
    },
    treatment: {
      metadata: {
        parent: 'mistake',
        route: '/mistake/treatment',
        title: 'Treatment',
        restricted: true,
        permissions: {
          read: ['admin', 'customerManagement', 'teamLead'],
          manage: ['admin', 'customerManagement', 'teamLead']
        }
      }
    },
    'calls-answered': {
      metadata: {
        parent: 'mistake',
        route: '/mistake/calls-answered',
        title: 'Calls Answered',
        restricted: true,
        permissions: {
          read: ['admin', 'customerManagement', 'teamLead'],
          manage: ['admin', 'customerManagement', 'teamLead']
        }
      }
    },
    'not-scheduled': {
      metadata: {
        parent: 'mistake',
        route: '/mistake/not-scheduled',
        title: 'Not Scheduled',
        restricted: true,
        permissions: {
          read: ['admin', 'customerManagement', 'teamLead'],
          manage: ['admin', 'customerManagement', 'teamLead']
        }
      }
    },
    websiteChat: {
      metadata: {
        parent: 'mistake',
        route: '/mistake/websiteChat',
        title: 'Website Chat',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'websiteChat',
            'teamLead'
          ],
          manage: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    }
  },
  data: {
    metadata: {
      route: '/data',
      title: 'Data',
      icon: () => <Storage />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: [
          'admin',
          'recall',
          'scheduled',
          'treatment',
          'reschedule',
          'rescheduled',
          'callsAnswered',
          'websiteChat',
          'relos',
          'callingManagement',
          'customerManagement',
          'financialController',
          'insuranceVerification'
        ]
      },
      routesType: 'tabs',
      routesDefault: {
        admin: '/data/recall',
        recall: '/data/recall',
        scheduled: '/data/recall',
        treatment: '/data/treatment',
        rescheduled: '/data/reschedule',
        callsAnswered: '/data/calls-answered',
        websiteChat: '/data/websiteChat',
        voiceMails: '/data/voicemails',
        relos: '/data/relos',
        callingManagement: '/data/recall',
        customerManagement: '/data/recall',
        financialController: '/data/recall',
        insuranceVerification: '/data/insurance-verifications'
      }
    },
    recall: {
      metadata: {
        parent: 'data',
        route: '/data/recall',
        title: 'Recall',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'recall',
            'scheduled'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    },
    treatment: {
      metadata: {
        parent: 'data',
        route: '/data/treatment',
        title: 'Treatment',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'treatment'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    },
    'calls-answered': {
      metadata: {
        parent: 'data',
        route: '/data/calls-answered',
        title: 'Calls Answered',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'callsAnswered'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    },
    relos: {
      metadata: {
        parent: 'data',
        route: '/data/relos',
        title: 'RELOs',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'relos'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: ['admin', 'callingManagement', 'customerManagement']
        }
      }
    },
    'not-scheduled': {
      metadata: {
        parent: 'data',
        route: '/data/not-scheduled',
        title: 'Not Scheduled',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'recall',
            'scheduled',
            'treatment',
            'relos'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    },
    websiteChat: {
      metadata: {
        parent: 'data',
        route: '/data/websiteChat',
        title: 'Website Chat',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'websiteChat'
          ],
          manage: ['admin', 'callingManagement', 'customerManagement'],
          delete: [
            'admin',
            'callingManagement',
            'customerManagement',
            'teamLead'
          ]
        }
      }
    },
    'insurance-verifications': {
      metadata: {
        parent: 'data',
        route: '/data/insurance-verifications',
        title: 'Insurance Verifications',
        restricted: true,
        permissions: {
          read: [
            'admin',
            'insuranceVerification',
            'customerManagement',
            'financialController'
          ],
          manage: ['admin', 'customerManagement'],
          delete: []
        }
      }
    }
  },
  relo: {
    metadata: {
      route: '/relo',
      hide: true,
      maxDepth: 3,
      restricted: true,
      permissions: {
        read: ['employee']
      },
      routesType: 'tabs',
      routesInterpretRouterPath: true
    },
    ':reloId': {
      metadata: {
        route: '/relo/:tabId',
        hide: true,
        restricted: true,
        permissions: {
          read: ['employee']
        },
        routesType: 'tabs',
        routesDefault: '/relo/:tabId/details'
      },
      details: {
        metadata: {
          parent: 'relo.:reloId',
          route: '/relo/:reloId/details',
          title: 'Details',
          restricted: true,
          permissions: {
            read: ['employee'],
            edit: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      }
    }
  },
  offices: {
    metadata: {
      route: '/offices',
      title: 'Offices',
      icon: () => <Domain />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: ['employee']
      },
      routesType: 'tabs',
      routesDefault: '/offices/data'
    },
    data: {
      metadata: {
        parent: 'offices',
        route: '/offices/data',
        title: 'Data',
        restricted: true,
        permissions: {
          read: ['employee'],
          create: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'technicalSupport'
          ]
        }
      }
    },
    clientGroups: {
      metadata: {
        parent: 'offices',
        route: '/offices/clientGroups',
        title: 'Client Groups',
        restricted: true,
        permissions: {
          read: ['employee'],
          create: [
            'admin',
            'callingManagement',
            'customerManagement',
            'financialController',
            'technicalSupport'
          ]
        }
      }
    }
  },
  office: {
    metadata: {
      route: '/office',
      hide: true,
      maxDepth: 3,
      restricted: true,
      permissions: {
        read: ['employee']
      },
      routesType: 'tabs',
      routesInterpretRouterPath: true
    },
    ':officeId': {
      metadata: {
        route: '/office/:tabId',
        hide: true,
        restricted: true,
        permissions: {
          read: ['employee']
        },
        routesType: 'tabs',
        routesDefault: '/office/:tabId/details'
      },
      details: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/details',
          title: 'Details',
          restricted: true,
          permissions: {
            read: ['employee'],
            edit: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      },
      users: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/users',
          title: 'Users',
          restricted: true,
          permissions: {
            read: ['employee'],
            manage: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      },
      reports: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/reports',
          title: 'Reports',
          restricted: true,
          permissions: {
            read: ['employee']
          }
        }
      },
      voicemails: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/voicemails',
          title: 'Voicemails',
          restricted: true,
          permissions: {
            read: ['admin', 'customerManagement']
          }
        }
      },
      billing: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/billing',
          title: 'Billing',
          restricted: true,
          permissions: {
            read: ['admin', 'financialController', 'technicalSupport'],
            update: ['admin', 'financialController', 'technicalSupport'],
            delete: ['admin', 'financialController', 'technicalSupport']
          }
        }
      },
      settings: {
        metadata: {
          parent: 'office.:officeId',
          route: '/office/:officeId/billing',
          title: 'Settings',
          restricted: true,
          permissions: {
            read: ['dev', 'admin', 'listManagement'],
            edit: ['dev', 'admin', 'listManagement']
          }
        }
      }
    }
  },
  clientGroup: {
    metadata: {
      route: '/clientGroup',
      hide: true,
      maxDepth: 5,
      restricted: true,
      permissions: {
        read: ['employee']
      },
      routesType: 'tabs',
      routesInterpretRouterPath: true
    },
    ':clientGroupId': {
      metadata: {
        route: '/clientGroup/:tabId',
        hide: true,
        restricted: true,
        permissions: {
          read: ['employee']
        },
        routesType: 'tabs',
        routesDefault: '/clientGroup/:tabId/details'
      },
      details: {
        metadata: {
          parent: 'clientGroup.:clientGroupId',
          route: '/clientGroup/:clientGroupId/details',
          title: 'Details',
          restricted: true,
          permissions: {
            read: ['employee'],
            edit: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      },
      offices: {
        metadata: {
          parent: 'clientGroup.:clientGroupId',
          route: '/clientGroup/:clientGroupId/offices',
          title: 'Offices',
          restricted: true,
          permissions: {
            read: ['employee'],
            manage: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      },
      regions: {
        metadata: {
          parent: 'clientGroup.:clientGroupId',
          route: '/clientGroup/:clientGroupId/regions',
          title: 'Regions',
          restricted: true,
          permissions: {
            read: ['employee'],
            manage: [
              'admin',
              'callingManagement',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          },
          routesType: 'tabs',
          routesInterpretRouterPath: true
        },
        ':regionId': {
          metadata: {
            route: '/clientGroup/:clientGroupId/regions',
            hide: true,
            maxDepth: 2,
            restricted: true,
            permissions: {
              read: ['employee']
            },
            routesType: 'tabs',
            routesInterpretRouterPath: true
          },
          details: {
            metadata: {
              parent: 'clientGroup.:clientGroupId.regions.:regionId',
              route: '/clientGroup/:clientGroupId/regions/:regionId/details',
              title: 'Details',
              restricted: true,
              permissions: {
                read: ['employee'],
                edit: [
                  'admin',
                  'callingManagement',
                  'customerManagement',
                  'financialController',
                  'technicalSupport'
                ]
              }
            }
          },
          regionOffices: {
            metadata: {
              parent: 'clientGroup.:clientGroupId.regions.:regionId',
              route:
                '/clientGroup/:clientGroupId/regions/:regionId/regionOffices',
              title: 'Region Offices',
              restricted: true,
              permissions: {
                read: ['employee'],
                edit: [
                  'admin',
                  'callingManagement',
                  'customerManagement',
                  'financialController',
                  'technicalSupport'
                ]
              },
              routesType: 'tabs',
              routesInterpretRouterPath: true
            }
          }
        }
      }
    }
  },
  billing: {
    metadata: {
      route: '/billing',
      title: 'Billing',
      icon: () => <MonetizationOn />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: ['admin', 'financialController']
      },
      routesType: 'tabs',
      routesDefault: '/billing/invoices'
    },
    invoices: {
      metadata: {
        parent: 'billing',
        route: '/billing/invoices',
        title: 'Invoices',
        restricted: true,
        permissions: {
          read: ['admin', 'financialController']
        }
      }
    },
    charges: {
      metadata: {
        parent: 'billing',
        route: '/billing/charges',
        title: 'Charges',
        restricted: true,
        permissions: {
          read: ['admin', 'financialController']
        }
      }
    },
    credits: {
      metadata: {
        parent: 'billing',
        route: '/billing/credits',
        title: 'Credits',
        restricted: true,
        permissions: {
          read: ['admin', 'financialController']
        }
      }
    },
    transactions: {
      metadata: {
        parent: 'billing',
        route: '/billing/transactions',
        title: 'Transactions',
        restricted: true,
        permissions: {
          read: ['admin', 'financialController']
        }
      }
    },
    offerings: {
      metadata: {
        parent: 'billing',
        route: '/billing/offerings',
        title: 'Offerings',
        restricted: true,
        permissions: {
          read: ['admin', 'financialController']
        }
      }
    }
  },
  employees: {
    metadata: {
      route: '/employees',
      title: 'Employees',
      icon: () => <SupervisedUserCircle />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: ['admin', 'callingManagement', 'teamLead']
      },
      routesType: 'tabs',
      routesDefault: '/employees/data'
    },
    data: {
      metadata: {
        parent: 'employees',
        route: '/employees/data',
        title: 'Data',
        restricted: true,
        permissions: {
          read: ['admin', 'callingManagement', 'teamLead'],
          manage: ['admin']
        }
      }
    },
    teams: {
      metadata: {
        parent: 'employees',
        route: '/employees/teams',
        title: 'Teams',
        restricted: true,
        permissions: {
          read: ['admin', 'callingManagement', 'teamLead'],
          create: ['admin', 'callingManagement', 'teamLead']
        }
      }
    }
  },
  team: {
    metadata: {
      route: '/team',
      hide: true,
      maxDepth: 3,
      icon: () => <SupervisedUserCircle />,
      title: 'Team',
      restricted: true,
      permissions: {
        read: ['admin', 'callingManagement', 'teamLead']
      },
      routesType: 'tabs',
      routesInterpretRouterPath: true
    },
    ':teamId': {
      metadata: {
        route: '/team/:teamId',
        hide: true,
        restricted: true,
        permissions: {
          read: ['admin', 'callingManagement', 'teamLead']
        },
        routesType: 'tabs',
        routesDefault: '/team/:teamId/details'
      },
      details: {
        metadata: {
          parent: 'team.:teamId',
          route: '/team/:teamId/details',
          title: 'Details',
          restricted: true,
          permissions: {
            read: ['employee'],
            edit: [
              'admin',
              'callingManagement',
              'teamLead',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      },
      teamOffices: {
        metadata: {
          parent: 'team.:teamId',
          route: '/team/:teamId/teamOffices',
          title: 'Offices',
          restricted: true,
          permissions: {
            read: ['employee'],
            edit: [
              'admin',
              'callingManagement',
              'teamLead',
              'customerManagement',
              'financialController',
              'technicalSupport'
            ]
          }
        }
      }
    }
  },
  lists: {
    metadata: {
      route: '/lists',
      title: 'Lists',
      icon: () => <Assignment />,
      maxDepth: 2,
      restricted: true,
      permissions: {
        read: ['dev', 'listManagement', 'admin']
      },
      routesType: 'tabs',
      routesDefault: '/lists/upload'
    },
    upload: {
      metadata: {
        parent: 'lists',
        route: '/lists/upload',
        title: 'Upload Lists',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    },
    newlist: {
      metadata: {
        parent: 'lists',
        route: '/lists/newlist',
        title: 'New List',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    },
    recallLists: {
      metadata: {
        parent: 'lists',
        route: '/lists/recallLists',
        title: 'Recall Lists',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    },
    treatmentLists: {
      metadata: {
        parent: 'lists',
        route: '/lists/treatmentLists',
        title: 'Treatment Lists',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    },
    recallTeams: {
      metadata: {
        parent: 'lists',
        route: '/lists/recallTeams',
        title: 'Recall Teams',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    },
    treatmentTeams: {
      metadata: {
        parent: 'lists',
        route: '/lists/treatmentTeams',
        title: 'Treatment Teams',
        restricted: true,
        permissions: {
          read: ['dev', 'listManagement', 'admin']
        }
      }
    }
  },
  reports: {
    metadata: {
      route: '/reports',
      title: 'Reports',
      maxDepth: 2,
      icon: () => <Description />,
      restricted: true,
      permissions: {
        read: ['dev', 'admin']
      },
      routesType: 'wizard',
      routesDefault: '/reports/wizard'
    },
    wizard: {
      metadata: {
        parent: 'reports',
        route: '/reports/wizard',
        title: 'Reports Wizard',
        restricted: true,
        permissions: {
          read: ['dev', 'admin']
        }
      }
    }
  }
};

export default sitemap;
