export const ERRORS = {
  403: {
    error: 'You do not have permissions to access the requested resource.',
    animationFile: 'error.json'
  },
  404: {
    error: 'The page you are trying to reach cannot be found.',
    animationFile: 'error.json'
  }
};
