import _ from 'lodash';
import React, { Component } from 'react';
import AnsweringIcon from '@material-ui/icons/PhoneCallback';
import BaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import RecallIcon from '@material-ui/icons/Schedule';
import RELOsIcon from '@material-ui/icons/CallMissedOutgoing';
import ReschedulingIcon from '@material-ui/icons/Restore';
import TreatmentIcon from '@material-ui/icons/SettingsBackupRestore';
import WarningIcon from '@material-ui/icons/Warning';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WebsiteChatIcon from '@material-ui/icons/ChatBubbleOutline';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfficeOfferingsClick,
  dialogEditOfficeOfferingsItem
} from '../../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

export const OFFERINGS = [
  { name: 'Base', key: 'base', icon: <BaseIcon /> },
  { name: 'Recall', key: 'recall', icon: <RecallIcon /> },
  { name: 'Recall+', key: 'recallplus', icon: <RecallIcon /> },
  { name: 'Treatment', key: 'treatment', icon: <TreatmentIcon /> },
  { name: 'RELOs', key: 'relos', icon: <RELOsIcon /> },
  {
    name: 'Insurance Verification',
    key: 'insuranceVerification',
    icon: <DoneOutlineIcon />
  },
  { name: 'Answering', key: 'answering', icon: <AnsweringIcon /> },
  { name: 'Rescheduling', key: 'rescheduling', icon: <ReschedulingIcon /> },
  { name: 'Website Chat', key: 'websiteChat', icon: <WebsiteChatIcon /> }
];

class DialogEditOfficeOfferings extends Component {
  state = { loadingAfterSubmit: false };

  UNSAFE_componentWillUpdate(nextProps) {
    const { item } = this.props;

    if (!_.isEqual(item, nextProps.item) && nextProps.item) {
      this.setState(
        {
          officeName: nextProps.item.data.name
        },
        () => {
          this.setState({
            validOfficeName: (value, allValues) =>
              value && allValues.officeName !== this.state.officeName
                ? 'Office name does not match'
                : undefined
          });
        }
      );
    }
  }

  onClose = () => {
    this.props.dialogEditOfficeOfferingsClick(!this.props.open);
    this.props.dialogEditOfficeOfferingsItem(null);
  };

  onSubmit = async values => {
    const { firestore, item } = this.props;
    const ref = { collection: 'clients', doc: item.data.id };
    let offerings = values;
    delete offerings.officeName;

    try {
      await firestore.update(ref, { offerings });
      this.setState({ loadingAfterSubmit: false }, () => this.onClose());
    } catch (e) {
      console.log(e);
      this.setState({ loadingAfterSubmit: false });
    }
  };

  renderCheckbox = field => {
    const {
      input: { onChange, value, name },
      label
    } = field;

    return (
      <span className={field.dialogCheckboxLabelContainer}>
        <Checkbox
          className={field.dialogCheckbox}
          checked={value}
          onChange={e => {
            if (name === 'recallplus' && !value) field.change('recall', true);
            if (name === 'recall' && value) field.change('recallplus', false);
            onChange(!value);
          }}
        />
        <Typography variant='body1' className={field.dialogCheckboxLabel}>
          {label}
        </Typography>
      </span>
    );
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin='normal'
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...field.input}
      />
    );
  };

  renderOfferingsCheckboxes() {
    const { classes, change } = this.props;

    return _.map(OFFERINGS, offering => {
      return (
        <div key={offering.key} className={classes.offeringRowContainer}>
          <div className={classes.checkboxContainer}>
            <Field
              name={offering.key}
              label={offering.name}
              component={this.renderCheckbox}
              dialogCheckbox={classes.dialogCheckbox}
              dialogCheckboxLabelContainer={
                classes.dialogCheckboxLabelContainer
              }
              dialogCheckboxLabel={classes.dialogCheckboxLabel}
              change={change}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const { classes, fullScreen, handleSubmit, item, open } = this.props;
    const { loadingAfterSubmit, validOfficeName } = this.state;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogWarningTitleArea}>
          <div className={classes.dialogWarningTitleRow}>
            <div className={classes.dialogWarningTextArea}>
              <div className={classes.dialogWarningTitleTextArea}>
                <WarningIcon />
                <Typography variant='h6' className={classes.dialogTitle}>
                  Change Offerings Subscriptions?
                </Typography>
              </div>
            </div>
            <Tooltip title='Close' className={classes.dialogCloseButton}>
              <IconButton
                color='inherit'
                aria-label='Close'
                onClick={this.onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.dialogWarningSubtitleTextArea}>
            <Typography variant='h6' className={classes.dialogWarningSubtitle}>
              Doing so will immediately effect the billing of{' '}
              {item ? item.data.name : `{officeName}`}. If you are sure you want
              to change this office's subscriptions, select the offerings below
              and enter the office's name.
            </Typography>
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          <form
            className={classes.dialogForm}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {this.renderOfferingsCheckboxes()}
            <Field
              name='officeName'
              component={this.renderTextField}
              label='Enter Office Name'
              type='text'
              className={classes.dialogTextField}
              validate={[validations.required, validOfficeName]}
            />
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DialogEditOfficeOfferings');
  const {
    dialogEditOfficeOfferingsOpen: open,
    dialogEditOfficeOfferingsItem: item
  } = state;
  let initialValues = {
    recall: false,
    answering: false,
    rescheduling: false,
    websiteChat: false
  };
  if (item && item.data.offerings) {
    for (let key in item.data.offerings) {
      initialValues = {
        ...initialValues,
        [key]: item.data.offerings[key]
      };
    }
  }

  return {
    open,
    initialValues,
    item,
    officeNameFormValue: selector(state, 'officeName')
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeOfferingsClick,
    dialogEditOfficeOfferingsItem
  }),
  reduxForm({ form: 'DialogEditOfficeOfferings', enableReinitialize: true }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeOfferings);
