import { db } from '../../../../index';

export const transactionsFilters = [
  {
    index: 1,
    key: 'client',
    options: () =>
      new Promise((resolve, reject) => {
        const options = [];

        return db
          .collection('clients')
          .orderBy('officeInformation.name', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const client = doc.data();
              const clientName = client.officeInformation.name;

              options.push({
                key: doc.id,
                text: clientName,
                value: `client,==,${doc.id}`
              });
            });

            return resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Office'
  },
  {
    index: 2,
    key: 'date',
    type: 'dateRange',
    label: 'Date'
  }
];
