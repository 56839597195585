import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import PhoneTextMask from '../../../components/PhoneTextMask';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfficeInfoClick,
  dialogEditOfficeInfoItem
} from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

class DialogEditOfficeInfo extends Component {
  state = { loadingAfterSubmit: false };

  onClose = () => {
    this.props.dialogEditOfficeInfoClick(!this.props.open);
    this.props.dialogEditOfficeInfoItem(null);
  };

  onSubmit = async values => {
    const { firestore, item } = this.props;
    const ref = { collection: 'clients', doc: item.data.id };
    let data =
      item.key === 'phone'
        ? values[item.key].trim().replace(/[^0-9]/g, '')
        : values[item.key].trim();

    try {
      item.key === 'locationId'
        ? await firestore.update(ref, { [`metadata.${item.key}`]: data })
        : await firestore.update(ref, {
            [`officeInformation.${item.key}`]: data
          });
      this.setState({ loadingAfterSubmit: false }, () => this.onClose());
    } catch (e) {
      console.log(e);
      this.setState({ loadingAfterSubmit: false });
    }
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin='normal'
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        InputProps={
          field.input.name === 'phone' ? { inputComponent: PhoneTextMask } : {}
        }
        InputLabelProps={{ shrink: true }}
        {...field.input}
      />
    );
  };

  render() {
    const { classes, fullScreen, handleSubmit, item, open } = this.props;
    const { loadingAfterSubmit } = this.state;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              Edit {item && item.label}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          <form
            className={classes.dialogForm}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {item ? (
              <Field
                name={item.key}
                component={this.renderTextField}
                label={`Office ${item.label}`}
                type='text'
                className={classes.dialogTextField}
                validate={
                  validations[`${item.key}`]
                    ? [validations.required, validations[`${item.key}`]]
                    : []
                }
              />
            ) : null}
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (
  { dialogEditOfficeInfoOpen: open, dialogEditOfficeInfoItem: item },
  props
) => {
  let initialValues = item ? { [`${item.key}`]: item.data[item.key] } : {};
  return { open, initialValues, item };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeInfoClick,
    dialogEditOfficeInfoItem
  }),
  reduxForm({ form: 'DialogEditOfficeInfo', enableReinitialize: true }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeInfo);
