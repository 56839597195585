import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const PricingPlanDetailsInLine = ({
  classes,
  pricingPlans,
  planKey,
  showPlanName
}) => (
  <span className={classes.planDetails}>
    {showPlanName ? `${_.startCase(planKey)} Plan - ` : null}
    {`${
      pricingPlans[planKey] && pricingPlans[planKey].entryPrice instanceof Array
        ? pricingPlans[planKey].entryPrice.map((price, index) =>
            index === pricingPlans[planKey].entryPrice.length - 1
              ? ` $${parseFloat(price.entryPrice).toFixed(2)} (${
                  price.entryMin
                }+)`
              : ` $${parseFloat(price.entryPrice).toFixed(2)} (${
                  price.entryMin
                }-${price.entryMax})`
          )
        : `$${parseFloat(pricingPlans[planKey].entryPrice).toFixed(2)}`
    } per entry | ${
      pricingPlans[planKey] &&
      pricingPlans[planKey].periodPrice instanceof Array
        ? pricingPlans[planKey].periodPrice.map((price, index) =>
            index === pricingPlans[planKey].periodPrice.length - 1
              ? ` $${parseFloat(price.periodPrice).toFixed(2)} (${
                  price.listSizeMin
                }+)`
              : ` $${parseFloat(price.periodPrice).toFixed(2)} (${
                  price.listSizeMin
                }-${price.listSizeMax})`
          )
        : `$${parseFloat(pricingPlans[planKey].periodPrice).toFixed(2)}`
    } per ${
      pricingPlans[planKey].periodType === 'monthly'
        ? 'month'
        : pricingPlans[planKey].periodType === 'yearly'
        ? 'year'
        : pricingPlans[planKey].periodType === 'daily'
        ? 'day'
        : 'month'
    }
      `}
  </span>
);

const styles = theme => ({
  planDetails: {
    fontSize: 12
  }
});

export default withStyles(styles, { withTheme: true })(
  PricingPlanDetailsInLine
);
