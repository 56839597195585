import _ from 'lodash';
import React, { Component, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DialogTransition from '../../../../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import PricingPlanDetailsInLine from '../../../../../../components/Billing/PricingPlanDetailsInLine';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CALLFORCE_TZ } from '../../../../../../services/constants';
import { DateTimePicker } from 'material-ui-pickers';
import {
  dialogEditOfferingTimeActiveEntryClick,
  dialogEditOfferingTimeActiveEntryItem
} from '../../../../../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { t } from 'typy';
import { styles } from './styles';
import { validations } from '../../../../../../services/validation';

class DialogEditOfferingTimeActiveEntry extends Component {
  state = {
    loadingAfterSubmit: false,
    error: false,
    errorMessage: '',
    showAreYouSure: false
  };

  onClose = () => {
    this.props.dialogEditOfferingTimeActiveEntryClick(false);
    this.props.dialogEditOfferingTimeActiveEntryItem(null);
    this.setState({
      error: false,
      errorMessage: '',
      loadingAfterSubmit: false,
      showAreYouSure: false
    });
  };

  onSubmit = async values => {
    const { firestore, item } = this.props;
    const ref = { collection: 'clients', doc: t(item, 'office.id').safeObject };
    const offeringId = t(item, 'offering.id').safeObject;
    const offeringsTimeActive = _.orderBy(
      t(item, `office.offeringsTimeActive.${offeringId}`).safeObject,
      'startDate'
    );
    const entryItemIndex = _.indexOf(
      offeringsTimeActive,
      t(item, 'entryItem').safeObject
    );
    const entryItem = {
      ...t(item, 'entryItem').safeObject,
      startDate: moment
        .unix(t(item, 'entryItem.startDate.seconds').safeObject)
        .toDate(),
      endDate: moment
        .unix(t(item, 'entryItem.endDate.seconds').safeObject)
        .toDate()
    };
    const transformedObj = {
      ...t(item, 'entryItem').safeObject,
      current: values.makeCurrentSubscpt
        ? true
        : t(item, 'entryItem.current').safeObject,
      startDate: values.startDate.toDate(),
      endDate:
        values.makeCurrentSubscpt || !values.endDate.isValid()
          ? null
          : values.endDate.toDate(),
      pricing: {
        ...t(item, 'entryItem.pricing').safeObject,
        plan: values.pricingPlan
      }
    };

    try {
      if (!_.isEqual(entryItem, transformedObj)) {
        offeringsTimeActive[entryItemIndex] = transformedObj;
        await firestore.update(ref, {
          [`offeringsTimeActive.${offeringId}`]: offeringsTimeActive
        });
      }

      this.onClose();
    } catch (e) {
      this.setState({
        error: true,
        errorMessage: e.toString(),
        loadingAfterSubmit: false,
        showAreYouSure: false
      });
    }
  };

  showAreYouSure = () => {
    this.setState({
      error: true,
      errorMessage: 'Please confirm...',
      showAreYouSure: true
    });
  };

  removeSubEntry = async () => {
    const {
      firebase,
      firestore,
      item: { entryItem, office, offering }
    } = this.props;
    const ref = { collection: 'clients', doc: office.id };

    try {
      await firestore.update(ref, {
        [`offeringsTimeActive.${offering.id}`]: firebase.firestore.FieldValue.arrayRemove(
          entryItem
        )
      });

      this.onClose();
    } catch (e) {
      this.setState({
        error: true,
        errorMessage: e.toString(),
        loadingAfterSubmit: false,
        showAreYouSure: false
      });
    }
  };

  renderSubmitError() {
    const { classes } = this.props;
    const { error, errorMessage } = this.state;

    return (
      <div>
        {error ? (
          <Typography className={classes.errorMessage} variant='body1'>
            {errorMessage}
          </Typography>
        ) : null}
      </div>
    );
  }

  dateTimePicker = field => {
    const {
      meta: { error, visited }
    } = field;
    const [selectedDate, handleDateChange] = useState(new Date());

    return (
      <DateTimePicker
        label={field.label}
        className={field.className}
        value={selectedDate}
        onChange={handleDateChange}
        format={field.formatDate}
        animateYearScrolling
        margin='normal'
        error={visited && error ? true : false}
        helperText={visited && error}
        variant='outlined'
        {...field.input}
      />
    );
  };

  radioButton = ({ input, ...rest }) => {
    const { classes, item } = this.props;
    const pricingPlans = t(item, 'offering.pricing').safeObject;

    return (
      <FormControl>
        <RadioGroup {...input} {...rest}>
          {pricingPlans &&
            Object.keys(pricingPlans).map((key, index) => (
              <FormControlLabel
                key={index}
                value={key}
                control={<Radio />}
                label={
                  <Typography variant='body1' className={classes.radioLabel}>
                    {pricingPlans[key].name
                      ? pricingPlans[key].name
                      : `${_.startCase(key)} Plan`}
                    <br />
                    <PricingPlanDetailsInLine
                      pricingPlans={pricingPlans}
                      planKey={key}
                    />
                  </Typography>
                }
              />
            ))}
        </RadioGroup>
      </FormControl>
    );
  };

  renderCheckbox = field => {
    const {
      input: { onChange, value },
      label
    } = field;

    return (
      <span className={field.dialogCheckboxLabelContainer}>
        <Checkbox
          className={field.dialogCheckbox}
          checked={value}
          onChange={() => {
            if (!value) field.change('endDate', moment(new Date('')));
            if (value) {
              field.change(
                'endDate',
                moment.unix(this.props.initialEndDate).tz(CALLFORCE_TZ)
              );
            }
            onChange(!value);
          }}
        />
        <Typography variant='body1' className={field.dialogCheckboxLabel}>
          {label}
        </Typography>
      </span>
    );
  };

  renderDialogInstructions() {
    const { classes, firestore, item, startDate } = this.props;
    const now = firestore.Timestamp.now().toDate();
    const effectsCurrentActiveSub =
      t(item, `office.offerings.${t(item, 'offering.id').safeObject}`)
        .safeObject &&
      (startDate >=
        moment(now)
          .tz(CALLFORCE_TZ)
          .startOf('month') ||
        startDate <=
          moment(now)
            .tz(CALLFORCE_TZ)
            .endOf('month'))
        ? true
        : false;
    const effectsInvoices =
      startDate <
      moment(now)
        .tz(CALLFORCE_TZ)
        .startOf('month')
        ? true
        : false;

    return (
      <span>
        <Typography className={classes.warningText} variant='caption'>
          {effectsCurrentActiveSub
            ? `WARNING: Changing this subscription entry will effect an active 
                subscription in the current billing period.`
            : null}
        </Typography>
        <Typography className={classes.warningText} variant='caption'>
          {effectsInvoices
            ? `WARNING: Because this subscription entry has a start date that 
                is older than the start of the current billing period, changing 
                this entry WILL effect existing invoices. Invoices that include 
                this entry will need to be recreated.`
            : null}
        </Typography>
      </span>
    );
  }

  renderTimeActiveFormFields() {
    const { classes, endDate } = this.props;

    return (
      <div>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>
            {endDate.isValid()
              ? 'Subscription Active Period'
              : 'Current Subscription Start'}
          </Typography>
        </div>
        <Field
          name='startDate'
          component={this.dateTimePicker}
          label='Start Date'
          formatDate='MMMM D, YYYY, h:mm a z'
          className={classes.dialogTextField}
          validate={[
            validations.required,
            validations.beforeEndDate,
            validations.conflictingSubEntry
          ]}
        />
        {endDate.isValid() ? (
          <Field
            name='endDate'
            component={this.dateTimePicker}
            label='End Date'
            formatDate='MMMM D, YYYY, h:mm a z'
            className={classes.dialogTextField}
            validate={[
              validations.required,
              validations.afterStartDate,
              validations.conflictingSubEntry
            ]}
          />
        ) : null}
      </div>
    );
  }

  renderPricingPlanFields() {
    const { classes, endDate } = this.props;

    return (
      <div>
        <div className={classes.sectionTitle}>
          <Typography variant='subtitle2'>
            {endDate.isValid() ? 'Pricing Plan' : 'Current Pricing Plan'}
          </Typography>
        </div>
        <Field name='pricingPlan' component={this.radioButton} />
      </div>
    );
  }

  renderMakeCurrentSubFields() {
    const { change, classes, item } = this.props;
    const offeringsTimeActive = _.orderBy(
      t(item, `office.offeringsTimeActive.${t(item, 'offering.id').safeObject}`)
        .safeObject,
      'startDate'
    );
    const entryItemIndex = _.indexOf(
      offeringsTimeActive,
      t(item, 'entryItem').safeObject
    );
    const eligibleToBeCurrent =
      t(item, 'entryItem.endDate').safeObject &&
      entryItemIndex !== -1 &&
      entryItemIndex === offeringsTimeActive.length - 1;

    if (eligibleToBeCurrent) {
      return (
        <Field
          name='makeCurrentSubscpt'
          label='Change End Date to "Present"'
          component={this.renderCheckbox}
          dialogCheckbox={classes.dialogCheckbox}
          dialogCheckboxLabelContainer={classes.dialogCheckboxLabelContainer}
          dialogCheckboxLabel={classes.dialogCheckboxLabel}
          change={change}
        />
      );
    }

    return null;
  }

  renderForm() {
    const { classes, handleSubmit } = this.props;
    const { showAreYouSure } = this.state;

    return (
      <form
        className={classes.dialogForm}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(this.onSubmit)}
      >
        {this.renderDialogInstructions()}
        {this.renderTimeActiveFormFields()}
        {this.renderMakeCurrentSubFields()}
        {this.renderPricingPlanFields()}
        <div className={classes.dialogButtonContainer}>
          {this.renderSubmitError()}
          <div>
            <Button
              size='large'
              color='primary'
              className={classes.dialogButton}
              style={{ marginRight: 8 }}
              onClick={
                showAreYouSure
                  ? () => this.removeSubEntry()
                  : () => this.showAreYouSure()
              }
            >
              {this.state.loadingAfterSubmit ? (
                <Loader type='Oval' color='#696969' height='13' width='13' />
              ) : (
                `${showAreYouSure ? 'Yes, delete it' : 'Remove'}`
              )}
            </Button>
            {showAreYouSure ? (
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                onClick={e => {
                  e.preventDefault();
                  this.onClose();
                }}
              >
                {this.state.loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'No, nevermind'
                )}
              </Button>
            ) : (
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {this.state.loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { classes, fullScreen, item, open } = this.props;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              {`${t(item, 'offering.name').safeObject} Subscription Entry`}
            </Typography>
            <Typography variant='caption' className={classes.subtitle}>
              {t(item, 'office.officeInformation.name').safeObject}
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          {this.renderForm()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DialogEditOfferingTimeActiveEntry');
  const {
    dialogEditOfferingTimeActiveEntryOpen: open,
    dialogEditOfferingTimeActiveEntryItem: item
  } = state;
  const offeringId = t(item, 'offering.id').safeObject;
  const startDate = t(item, 'entryItem.startDate.seconds').safeObject;
  const endDate = t(item, 'entryItem.endDate.seconds').safeObject;
  const initialEndDate = t(item, 'entryItem.endDate.seconds').safeObject;
  const pricingPlan = t(item, 'entryItem.pricing.plan').safeObject;
  const otherSubEntries = t(item, `office.offeringsTimeActive.${offeringId}`)
    .safeObject;
  let initialValues = {
    startDate: moment.unix(startDate).tz(CALLFORCE_TZ),
    endDate: moment.unix(endDate).tz(CALLFORCE_TZ),
    pricingPlan
  };

  return {
    endDate: selector(state, 'endDate'),
    initialValues,
    initialEndDate,
    item,
    open,
    otherSubEntries,
    startDate: selector(state, 'startDate')
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfferingTimeActiveEntryClick,
    dialogEditOfferingTimeActiveEntryItem
  }),
  reduxForm({
    form: 'DialogEditOfferingTimeActiveEntry',
    enableReinitialize: true
  }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfferingTimeActiveEntry);
