let ListsScenes = {};

ListsScenes['upload'] = require('./Upload').default;
ListsScenes['newlist'] = require('./NewList').default;
ListsScenes['recallLists'] = require('./ServiceLists/RecallLists').default;
ListsScenes[
  'treatmentLists'
] = require('./ServiceLists/TreatmentLists').default;
ListsScenes['recallTeams'] = require('./TeamLists/RecallTeams').default;
ListsScenes['treatmentTeams'] = require('./TeamLists/TreatmentTeams').default;

export default ListsScenes;
