import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const FieldPhone = ({ classes, disabled, field, form, label, variant }) => {
  const { errors, submitCount, touched } = form;

  return (
    <TextField
      className={classes.textField}
      disabled={disabled}
      type='tel'
      margin='normal'
      label={label}
      error={!!((touched[field.name] || submitCount > 0) && errors[field.name])}
      helperText={
        (touched[field.name] || submitCount > 0) && errors[field.name]
          ? errors[field.name]
          : ''
      }
      InputProps={{ inputComponent: TextMaskCustom }}
      InputLabelProps={{ shrink: true }}
      variant={variant}
      {...field}
    />
  );
};

FieldPhone.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(FieldPhone);