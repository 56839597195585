import { db } from '../../../../index';

export const invoicesFilters = [
  {
    index: 1,
    key: 'client',
    options: () =>
      new Promise((resolve, reject) => {
        const options = [];

        return db
          .collection('clients')
          .orderBy('officeInformation.name', 'asc')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const client = doc.data();
              const clientName = client.officeInformation.name;

              options.push({
                key: doc.id,
                text: clientName,
                value: `client,==,${doc.id}`
              });
            });

            return resolve(options);
          });
      }),
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Office'
  },
  {
    index: 2,
    key: 'period',
    type: 'dateRange',
    label: 'Period'
  },
  {
    index: 3,
    key: 'status',
    options: [
      { key: 'pending', text: 'Pending', value: 'status,==,pending' },
      { key: 'paid', text: 'Paid', value: 'status,==,paid' },
      { key: 'void', text: 'Void', value: 'status,==,void' }
    ],
    optionKey: 'key',
    optionText: 'text',
    optionValue: 'key',
    type: 'select',
    label: 'Status'
  }
];
