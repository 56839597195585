import _ from 'lodash';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import countryState from '../../../services/options/geoData';
import DialogTransition from '../../../components/DialogTransition';
import Loader from 'react-loader-spinner';
import PhoneTextMask from '../../../components/PhoneTextMask';
import SelectField from '../../../components/SelectField';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogEditOfficeAddressClick,
  dialogEditOfficeAddressItem
} from '../../../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { styles } from './styles';
import { validations } from '../../../services/validation';

class DialogEditOfficeAddress extends Component {
  state = { loadingAfterSubmit: false };

  onClose = () => {
    this.props.dialogEditOfficeAddressClick(!this.props.open);
    this.props.dialogEditOfficeAddressItem(null);
  };

  onSubmit = async values => {
    const { firestore, item } = this.props;
    const ref = { collection: 'clients', doc: item.data.id };
    const { address, city, state, zip, country } = values;
    let data = {
      address: address.trim(),
      city: city.trim(),
      state,
      zip: zip.trim(),
      country
    };

    try {
      await firestore.update(ref, { 'officeInformation.address': data });
      this.setState({ loadingAfterSubmit: false }, () => this.onClose());
    } catch (e) {
      console.log(e);
      this.setState({ loadingAfterSubmit: false });
    }
  };

  renderTextField = field => {
    const {
      autoComplete,
      className,
      disabled,
      meta: { touched, error },
      label,
      type
    } = field;

    return (
      <TextField
        label={label}
        className={className}
        margin='normal'
        type={type}
        autoComplete={autoComplete}
        variant='outlined'
        disabled={disabled}
        error={touched && error ? true : false}
        helperText={touched && error}
        InputProps={
          field.input.name === 'phone' ? { inputComponent: PhoneTextMask } : {}
        }
        InputLabelProps={{ shrink: true }}
        {...field.input}
      />
    );
  };

  render() {
    const {
      classes,
      fullScreen,
      handleSubmit,
      open,
      countryFormValue,
      stateFormValue
    } = this.props;
    const { loadingAfterSubmit } = this.state;

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        maxWidth='md'
        className={classes.dialog}
        disableBackdropClick
      >
        <div className={classes.dialogTitleArea}>
          <div className={classes.dialogTitleTextArea}>
            <Typography variant='h6' className={classes.dialogTitle}>
              Edit Address
            </Typography>
          </div>
          <Tooltip title='Close' className={classes.dialogCloseButton}>
            <IconButton
              color='inherit'
              aria-label='Close'
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent className={classes.dialogContent}>
          <form
            className={classes.dialogForm}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name='address'
              component={this.renderTextField}
              label='Address'
              type='text'
              className={classes.dialogTextField}
              validate={[validations.required]}
            />
            <Field
              name='city'
              component={this.renderTextField}
              label='City'
              type='text'
              className={classes.dialogTextField}
              validate={[validations.required]}
            />
            <Field
              name='state'
              component={SelectField}
              selected={stateFormValue === null ? false : true}
              label={countryFormValue === 'US' ? 'State' : 'Province'}
              type='text'
              options={
                countryFormValue &&
                _.sortBy(
                  _.mapKeys(countryState, 'abbreviation')[countryFormValue]
                    .states,
                  'name'
                )
              }
              optionKey='abbreviation'
              optionText='abbreviation'
              optionValue='abbreviation'
              margin='none'
              textFieldContainerClass={classes.dialogTextField}
              validate={[validations.required]}
            />
            <Field
              name='zip'
              component={this.renderTextField}
              label={countryFormValue === 'US' ? 'Zip Code' : 'Postal Code'}
              type='text'
              className={classes.dialogTextField}
              validate={
                countryFormValue === 'US'
                  ? [validations.required, validations.zipUS]
                  : [validations.required, validations.zipCA]
              }
            />
            <Field
              name='country'
              component={SelectField}
              selected={countryFormValue === null ? false : true}
              label='Country'
              type='text'
              options={_.sortBy(
                _.mapKeys(countryState, 'abbreviation'),
                'name'
              )}
              optionKey='abbreviation'
              optionText='abbreviation'
              optionValue='abbreviation'
              margin='none'
              textFieldContainerClass={classes.dialogTextField}
              validate={[validations.required]}
            />
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                className={classes.dialogButton}
                type='submit'
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DialogEditOfficeAddress');
  const {
    dialogEditOfficeAddressOpen: open,
    dialogEditOfficeAddressItem: item
  } = state;
  let initialValues = {};
  if (item && item.data) {
    const { address, city, zip, country } = item.data;
    initialValues = { address, city, state: item.data.state, zip, country };
  }

  return {
    open,
    initialValues,
    item,
    addressFormValue: selector(state, 'address'),
    cityFormValue: selector(state, 'city'),
    stateFormValue: selector(state, 'state'),
    countryFormValue: selector(state, 'country'),
    zipFormValue: selector(state, 'zip')
  };
};

export default compose(
  connect(mapStateToProps, {
    dialogEditOfficeAddressClick,
    dialogEditOfficeAddressItem
  }),
  reduxForm({ form: 'DialogEditOfficeAddress', enableReinitialize: true }),
  firestoreConnect(),
  withStyles(styles, { withTheme: true }),
  withMobileDialog()
)(DialogEditOfficeAddress);
