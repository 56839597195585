export const ADMIN = 'admin';
export const APPOINTMENTS = 'appointments';
export const CALLSANSWERED = 'callsanswered';
export const EMPLOYEE = 'employee';
export const HR = 'hr';
export const OPEN_IP_GROUPS = [
  'admin',
  'callingManagement',
  'customerManagement',
  'financialController',
  'technicalSupport',
  'IPExempt'
];
