import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  selectField: {
    width: theme.textField.width,
    margin: theme.textField.margin,
    '&:hover': {
      borderColor: theme.palette.text.primary
    }
  }
});

class FieldSelect extends Component {
  state = {
    labelWidth: 0
  };

  componentDidMount() {
    const { autoPopulateFromParam, editField, selected } = this.props;

    if (editField || autoPopulateFromParam || selected) {
      this.handleSelectClick();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { field, handleValueChange } = this.props;

    if (handleValueChange && field.value !== nextProps.field.value) {
      handleValueChange(nextProps.field.value);
    }
  }

  handleSelectClick() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  resolvePath(obj, objPath) {
    let path = objPath;
    path = path.split('.');
    let current = obj;
    while (path.length) {
      if (typeof current !== 'object') return undefined;
      current = current[path.shift()];
    }

    return current;
  }

  renderOptions(options) {
    const { optionKey, optionText, optionValue } = this.props;

    return _.map(options, option => {
      return (
        <option key={option[optionKey]} value={option[optionValue]}>
          {optionText.includes('.')
            ? this.resolvePath(option, optionText)
            : optionText instanceof Array
            ? `${option[optionText[0]]} ${option[optionText[1]]}`
            : option[optionText]}
        </option>
      );
    });
  }

  render() {
    const {
      classes,
      form: { touched, errors },
      field,
      label,
      options,
      setFieldTouched,
      setFieldValue,
      variant
    } = this.props;

    return (
      <div>
        <FormControl
          variant={variant}
          className={classes.selectField}
          error={touched[field.name] && errors[field.name] ? true : false}
        >
          <InputLabel ref={ref => (this.InputLabelRef = ref)}>
            {label}
          </InputLabel>
          <Select
            native
            onFocus={() => this.handleSelectClick()}
            onClick={() => this.handleSelectClick()}
            onChange={event => setFieldValue(field.name, event.target.value)}
            onBlur={() => setFieldTouched(field.name)}
            input={<OutlinedInput labelWidth={this.state.labelWidth} />}
            {...field}
          >
            <option value='' />
            {options ? this.renderOptions(options) : ''}
          </Select>
          {touched[field.name] && errors[field.name] ? (
            <FormHelperText>{errors[field.name]}</FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FieldSelect);
