import _ from 'lodash';
import React, { Component } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { entryTypes } from '../services/options';

class EntryTypePopover extends Component {
  getPersonalTypes() {
    const { profile } = this.props;
    let types = [];

    if (profile && profile.groups) {
      const { groups } = profile;

      if (groups.includes('teamLead')) {
        types = types.concat(
          _.filter(
            entryTypes,
            o => o.code !== 'callsAnswered' && o.code !== 'rescheduled'
          )
        );
      }

      if (groups.includes('callsAnswered')) {
        types = types.concat(
          _.filter(entryTypes, o => o.code === 'callsAnswered')
        );
      }

      if (groups.includes('rescheduled')) {
        types = types.concat(
          _.filter(entryTypes, o => o.code === 'rescheduled')
        );
      }

      if (groups.includes('scheduled')) {
        types = types.concat(
          _.filter(
            entryTypes,
            o => o.code === 'scheduled' || o.code === 'notScheduled'
          )
        );
      }

      if (groups.includes('admin')) {
        types = entryTypes;
      }
    }

    return types;
  }

  render() {
    const {
      open,
      anchorEl,
      handleClose,
      handleClickEntry,
      teams,
      personal
    } = this.props;
    const types = teams
      ? _.filter(
          entryTypes,
          o => o.code !== 'callsAnswered' && o.code !== 'rescheduled'
        )
      : personal
      ? this.getPersonalTypes()
      : entryTypes;

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transitionDuration={0}
      >
        <MenuList>
          {types.map(type => (
            <MenuItem
              key={type.code}
              onClick={() => handleClickEntry(type.code)}
            >
              {type.icon(14, 5)} {type.text}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    );
  }
}

export default EntryTypePopover;
