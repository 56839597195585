import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import AccountIcon from '@material-ui/icons/AccountCircle';
import DrawerList from './components/DrawerList';
import ProfilePopper from './components/ProfilePopper';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ReachLogo from './ReachLogo.png';
import { styles } from './styles';
import Main from '../Main';

class Header extends Component {
  state = {
    open: false,
    anchorEl: null,
    openPopper: false,
    placement: null
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClick = placement => event => {
    const { currentTarget } = event;

    this.setState({
      anchorEl: currentTarget,
      openPopper: this.state.placement !== placement || !this.state.openPopper,
      placement
    });
  };

  renderProfilePopper() {
    const { auth } = this.props;
    const { anchorEl, openPopper, placement } = this.state;

    if (isLoaded(this.props.auth)) {
      return (
        <ProfilePopper
          auth={auth}
          open={openPopper}
          anchorEl={anchorEl}
          placement={placement}
          transition
        />
      );
    }

    return null;
  }

  renderAccountIcon() {
    const { auth, classes } = this.props;

    if (auth) {
      return this.renderAccountPhoto();
    }

    return (
      <div className={classes.accountIconContainer}>
        <AccountIcon className={classes.accountIcon} />
      </div>
    );
  }

  renderAccountPhoto() {
    const { auth, classes } = this.props;

    if (auth.photoURL) {
      return (
        <div
          className={classes.accountIconContainer}
          onClick={this.handleClick('bottom-end')}
        >
          <img src={auth.photoURL} alt={auth.displayName} />
        </div>
      );
    }

    return (
      <div
        className={classes.accountIconContainer}
        onClick={this.handleClick('bottom-end')}
      >
        <AccountIcon className={classes.accountIcon} />
      </div>
    );
  }

  renderDrawer() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.toolbar}>
          <img src={ReachLogo} alt='Logo' className={classes.logo} />
        </div>
        <Divider />
        <DrawerList setDrawerOpen={this.handleDrawerClose} />
      </div>
    );
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        {this.renderProfilePopper()}
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            {this.renderAccountIcon()}
          </Toolbar>
        </AppBar>
        <Hidden lgUp>
          <SwipeableDrawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.open}
            onClose={this.handleDrawerToggle}
            onOpen={this.handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            {this.renderDrawer()}
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation='css'>
          <Drawer
            className={classes.permanentDrawer}
            variant='permanent'
            open
            onClose={this.handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
          >
            {this.renderDrawer()}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Main />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ firebase: { auth, profile } }, _) => {
  return { auth };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Header)
);
