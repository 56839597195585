import React, { useContext, useState } from 'react';
import DialogTransition from '../../../../../components/DialogTransition';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  Button,
  Typography,
  IconButton,
  withStyles
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { AppContext } from '../../../../../contexts';

const DialogViewRemoveTeamOffices = ({
  office,
  team,
  setOffice,
  setOfficesRetrieved,
  classes,
  fullScreen
}) => {
  const {
    state: { firebase }
  } = useContext(AppContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingAfterSubmit, setLoadingAfterSubmit] = useState(false);

  const removeOfficeFromTeam = async () => {
    setLoadingAfterSubmit(true);
    const newOfferingTeams = office.teams[team.serviceOffering[0]].filter(
      t => t !== team.id
    );
    const newOfficeTeams = {
      ...office.teams,
      [team.serviceOffering[0]]: newOfferingTeams
    };
    try {
      const clientRef = firebase
        .firestore()
        .collection('clients')
        .doc(office.id);

      const teamRef = firebase
        .firestore()
        .collection('teams')
        .doc(team.id);

      await clientRef.update({ teams: newOfficeTeams });
      await teamRef.update({ totalOffices: team.totalOffices - 1 });
    } catch (e) {
      console.error(e);
    }
    setOfficesRetrieved(false);
    onClose();
  };

  const onClose = () => {
    setOffice(null);
    setLoadingAfterSubmit(false);
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      fullWidth
      open={!!office}
      onClose={onClose}
      maxWidth='sm'
      disableBackdropClick
    >
      <div className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleTextArea}>
          <Typography variant='h6' className={classes.dialogTitle}>
            {office.officeInformation.name}
          </Typography>
        </div>
        <Tooltip title='Close' className={classes.dialogCloseButton}>
          <IconButton color='inherit' aria-label='Close' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent style={{ margin: '0 auto', padding: '24px' }}>
        {!confirmDelete ? (
          <>
            <Button
              size='large'
              variant='outlined'
              color='secondary'
              onClick={() => setConfirmDelete(true)}
              className={classes.dialogButton}
            >
              Remove Office
            </Button>
            <Link to={`/office/${office.id}/details?pathname=/offfices/data`}>
              <Button
                size='large'
                variant='outlined'
                color='primary'
                className={classes.dialogButton}
              >
                View Office
              </Button>
            </Link>
          </>
        ) : (
          <>
            <DialogContentText className={classes.dialogText}>
              {`Please Confirm The Removal of ${office.officeInformation.name}`}
            </DialogContentText>
            <div className={classes.dialogButtonContainer}>
              <Button
                size='large'
                variant='outlined'
                color='secondary'
                onClick={() => removeOfficeFromTeam()}
                style={{ margin: '0 auto' }}
                disabled={loadingAfterSubmit}
              >
                {loadingAfterSubmit ? (
                  <Loader type='Oval' color='#055f5b' height='13' width='13' />
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(
  DialogViewRemoveTeamOffices
);
