import _ from 'lodash';
import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import DialogTeamEntriesDetails from './DialogTeamEntriesDetails';
import DialogTeamMistakesDetails from './DialogTeamMistakesDetails';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import theme from '../services/theme';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  dialogTeamEntriesDetailsClick,
  dialogTeamEntriesDetailsItem,
  dialogTeamMistakesDetailsClick,
  dialogTeamMistakesDetailsItem
} from '../actions';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';

class TeamStatsTable extends Component {
  state = { teams: null };

  UNSAFE_componentWillUpdate(nextProps) {
    const { collection, tms } = this.props;

    if (!_.isEqual(collection, nextProps.collection)) {
      this.setState({ employees: null });
    }

    if (!_.isEqual(tms, nextProps.tms)) {
      this.setState({
        teams: _.filter(nextProps.tms, o => o.name !== 'Test Team')
      });
    }
  }

  renderLoader() {
    const { teams } = this.state;
    const { classes } = this.props;

    if (!teams) {
      return (
        <div className={classes.loaderContainer}>
          <Loader
            type='Oval'
            color={theme.palette.primary.main}
            height='30'
            width='30'
          />
        </div>
      );
    }
  }

  renderDialogs() {
    const { mistakes, collection } = this.props;

    return mistakes ? (
      <DialogTeamMistakesDetails collection={collection} />
    ) : (
      <DialogTeamEntriesDetails collection={collection} />
    );
  }

  render() {
    const {
      classes,
      mistakes,
      orderByValue,
      orderByOrder,
      dialogTeamEntriesDetailsClick,
      dialogTeamEntriesDetailsItem,
      dialogTeamMistakesDetailsClick,
      dialogTeamMistakesDetailsItem
    } = this.props;
    const { teams } = this.state;

    return (
      <div className={classes.teamTableContainer}>
        {this.renderLoader()}
        {this.renderDialogs()}
        {teams
          ? _.orderBy(teams, orderByValue, orderByOrder).map((team, index) => (
              <ButtonBase
                className={classes.buttonBase}
                key={team.id}
                onClick={() => {
                  if (mistakes) {
                    dialogTeamMistakesDetailsItem(team);
                    dialogTeamMistakesDetailsClick(true);
                  } else {
                    dialogTeamEntriesDetailsItem(team);
                    dialogTeamEntriesDetailsClick(true);
                  }
                }}
              >
                <Grid container spacing={24} className={classes.tableRow}>
                  <Grid
                    item
                    xs={3}
                    className={`${classes.cell} ${classes.firstCell}`}
                    style={{ padding: 0, paddingLeft: 12 }}
                  >
                    <Typography variant='button' className={classes.teamName}>
                      {team.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    {team.dayTotal}
                  </Grid>
                  <Grid item xs={3} className={classes.cell}>
                    {team.monthTotal}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.cell}
                    style={{ justifyContent: 'flex-start' }}
                  >
                    {team.memberNames.length > 0 ? (
                      <Typography variant='button' style={{ fontSize: 8 }}>
                        {team.memberNames.sort().join(', ')}
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </Grid>
                </Grid>
              </ButtonBase>
            ))
          : null}
      </div>
    );
  }
}

const styles = theme => ({
  loaderContainer: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  teamTableContainer: {
    paddingBottom: theme.spacing.unit * 3
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  buttonBase: {
    width: '100%',
    marginBottom: 0,
    marginTop: 3,
    minHeight: 40
  },
  tableRow: {
    borderBottom: `1px solid #f5f5f5`,
    width: '95%',
    margin: '0px auto'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    fontSize: theme.spacing.unit * 2
  },
  firstCell: {
    justifyContent: 'flex-start'
  },
  teamName: {
    fontSize: theme.spacing.unit * 1.4
  }
});

const mapStateToProps = (state, props) => {
  const orderedData = state.firestore.ordered;
  const now = new Date();
  const today = moment(now).format('YYYY-MM-DD');
  const currentMonth = moment(now).format('YYYY-MM');
  let tms = [];

  if (!_.isEmpty(orderedData)) {
    props.teams.forEach(team => {
      let teamMonthTotal = 0;
      let teamDayTotal = 0;
      let teamMemberNames = [];
      let teamObj = {};

      for (let key in orderedData) {
        let keyItems = key.split('-');
        let keyName = keyItems[0];
        let teamId = keyItems[2];

        if (orderedData[key][0] && teamId === team.id) {
          if (keyName === 'overallMemberStats') {
            teamDayTotal = orderedData[key][0][today]
              ? teamDayTotal + orderedData[key][0][today]
              : teamDayTotal;
          }

          if (keyName === 'overallMemberMonthlyStats') {
            teamMonthTotal = orderedData[key][0][currentMonth]
              ? teamMonthTotal + orderedData[key][0][currentMonth]
              : teamMonthTotal;
          }

          if (keyName === 'teamMemberDetails') {
            teamMemberNames.push(
              `${orderedData[key][0].firstName} ${orderedData[key][0].lastName
                .charAt(0)
                .toUpperCase()}`
            );
          }
        }
      }

      teamObj.id = team.id;
      teamObj.name = team.name;
      teamObj.monthTotal = teamMonthTotal;
      teamObj.dayTotal = teamDayTotal;
      teamObj.memberNames = teamMemberNames;
      teamObj.members = team.members;
      tms.push(teamObj);
    });
  }

  return { tms };
};

export default compose(
  connect(mapStateToProps, {
    dialogTeamEntriesDetailsClick,
    dialogTeamEntriesDetailsItem,
    dialogTeamMistakesDetailsClick,
    dialogTeamMistakesDetailsItem
  }),
  firestoreConnect(props => {
    const teams = props.teams;
    let queries = [];

    teams.forEach(team => {
      team.members.forEach(member => {
        if (props.mistakes) {
          queries.push(
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: member,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: 'mistakes',
                      doc: props.collection,
                      subcollections: [
                        {
                          collection: 'statistics',
                          doc: 'daily'
                        }
                      ]
                    }
                  ]
                }
              ],
              storeAs: `overallMemberStats-${member}-${team.id}`
            },
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: member,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: 'mistakes',
                      doc: props.collection,
                      subcollections: [
                        {
                          collection: 'statistics',
                          doc: 'monthly'
                        }
                      ]
                    }
                  ]
                }
              ],
              storeAs: `overallMemberMonthlyStats-${member}-${team.id}`
            }
          );
        } else {
          queries.push(
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: member,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: props.collection,
                      doc: 'daily'
                    }
                  ]
                }
              ],
              storeAs: `overallMemberStats-${member}-${team.id}`
            },
            {
              collection: 'statistics',
              doc: 'employees',
              subcollections: [
                {
                  collection: member,
                  doc: 'statistics',
                  subcollections: [
                    {
                      collection: props.collection,
                      doc: 'monthly'
                    }
                  ]
                }
              ],
              storeAs: `overallMemberMonthlyStats-${member}-${team.id}`
            }
          );
        }
        queries.push({
          collection: 'employees',
          doc: member,
          storeAs: `teamMemberDetails-${member}-${team.id}`
        });
      });
    });

    return queries;
  }),
  withStyles(styles, { withTheme: true })
)(TeamStatsTable);
