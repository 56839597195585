import {
  TABLE_FILTER_SCHEDULED_APPLY_FILTERS,
  TABLE_FILTER_CALLSANSWERED_APPLY_FILTERS,
  TABLE_FILTER_NOTSCHEDULED_APPLY_FILTERS,
  TABLE_FILTER_RESCHEDULED_APPLY_FILTERS
} from '../actions/types';
import {
  SCHEDULED,
  NOT_SCHEDULED,
  RESCHEDULED,
  CALLS_ANSWERED
} from '../services/store/keys';

export const generateInitialState = (collection, storeAs) => ({
  query: {
    collection,
    orderBy: [['createdTime', 'desc']],
    limit: 25,
    storeAs
  },
  filtersApplied: false
});

const tableFiltersScheduled = (
  state = generateInitialState('scheduled', SCHEDULED),
  action
) => {
  switch (action.type) {
    case TABLE_FILTER_SCHEDULED_APPLY_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

const tableFiltersNotScheduled = (
  state = generateInitialState('notScheduled', NOT_SCHEDULED),
  action
) => {
  switch (action.type) {
    case TABLE_FILTER_NOTSCHEDULED_APPLY_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

const tableFiltersRescheduled = (
  state = generateInitialState('rescheduled', RESCHEDULED),
  action
) => {
  switch (action.type) {
    case TABLE_FILTER_RESCHEDULED_APPLY_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

const tableFiltersCallsAnswered = (
  state = generateInitialState('callsAnswered', CALLS_ANSWERED),
  action
) => {
  switch (action.type) {
    case TABLE_FILTER_CALLSANSWERED_APPLY_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const TableFilterReducer = {
  tableFiltersScheduled,
  tableFiltersNotScheduled,
  tableFiltersRescheduled,
  tableFiltersCallsAnswered
};
