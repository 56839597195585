/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  FieldCheckbox,
  FieldSelect,
  FieldText
} from '../../../../components/Fields';

const styles = theme => ({
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    textTransform: 'uppercase',
    '& > *': {
      color: theme.palette.secondary.main
    }
  }
});

const ManageEntryFields = ({
  classes,
  doNotBillReasons,
  loading,
  mistakeReasons,
  selectLabel,
  validations,
  values
}) => {
  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Manage Handling of Mistake</Typography>
      </div>
      <FieldCheckbox
        name='mistakeHandled'
        label='Mistake Handled'
        disabled={loading !== null}
        checked={!!values.mistakeHandled}
      />
      <Field
        name='mistakeHandledNotes'
        label='Mistake Handled Notes'
        variant='outlined'
        multiline
        rowsMax={4}
        disabled={loading !== null}
        component={FieldText}
      />
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Manage Rescheduling</Typography>
      </div>
      <FieldCheckbox
        name='reschedulePatient'
        checked={!!values.reschedulePatient}
        label='Needs to be rescheduled'
        disabled={loading !== null}
      />
      <Field
        name='rescheduleNotes'
        label='Reschedule Notes'
        variant='outlined'
        multiline
        rowsMax={4}
        disabled={loading !== null}
        component={FieldText}
      />
      <FieldCheckbox
        checked={!!values.rescheduled}
        name='rescheduled'
        label='Rescheduled'
        disabled={loading !== null}
      />
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Manage Entry</Typography>
      </div>
      <FieldCheckbox
        checked={!!values.doNotBill}
        name='doNotBill'
        label='Do Not Bill'
        disabled={true}
      />
      {values.doNotBill ? (
        <>
          <Field
            name='doNotBillCategory'
            loading={doNotBillReasons.length === 0}
            disabled={true}
            label={selectLabel(
              doNotBillReasons,
              'do not bill reason',
              'Do Not Bill Reason'
            )}
            options={doNotBillReasons}
            optionKey='value'
            optionText='value'
            optionValue='value'
            component={FieldSelect}
            validate={validations.doNotBillCategory}
          />
          <Field
            name='doNotBillNotes'
            label='Do Not Bill Notes'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={true}
            component={FieldText}
          />
        </>
      ) : null}
      <FieldCheckbox
        checked={!!values.mistake}
        name='mistake'
        label='Mark as Mistake'
        disabled={true}
      />
      {values.mistake ? (
        <>
          <Field
            name='mistakeCategory'
            loading={mistakeReasons.length === 0}
            disabled={true}
            label={selectLabel(
              mistakeReasons,
              'mistake reason',
              'Mistake Reason'
            )}
            options={mistakeReasons}
            optionKey='value'
            optionText='value'
            optionValue='value'
            component={FieldSelect}
            validate={validations.mistakeCategory}
          />
          <FieldCheckbox
            checked={!!values.doNotCountMistake}
            name='doNotCountMistake'
            label="Do Not Count Towards Caller's Mistake Total"
            disabled={loading !== null}
          />
          <Field
            name='mistakeNotes'
            label='Mistake Notes'
            variant='outlined'
            multiline
            rowsMax={4}
            disabled={loading !== null}
            component={FieldText}
          />
        </>
      ) : null}
      <div className={classes.sectionTitle}>
        <Typography variant='subtitle2'>Entry Details</Typography>
      </div>
    </>
  );
};

ManageEntryFields.propTypes = {
  classes: PropTypes.object.isRequired,
  doNotBillReasons: PropTypes.array.isRequired,
  loading: PropTypes.string,
  mistakeReasons: PropTypes.array.isRequired,
  selectLabel: PropTypes.func.isRequired,
  validations: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ManageEntryFields);
