import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FilterForm from './Form';

const FilterPopover = ({
  anchorEl,
  classes,
  filters,
  handleClose,
  onFilter,
  open,
  title,
  where
}) => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    transitionDuration={0}
  >
    <div className={`${classes.header} ${classes.root}`}>
      <div className={classes.text}>
        <Typography variant='body1' className={classes.title}>
          Filter {title}
        </Typography>
      </div>
      <div className={classes.iconContainer}>
        <FilterListIcon className={classes.icon} />
      </div>
    </div>
    <div className={`${classes.body} ${classes.root}`}>
      <FilterForm
        filters={filters}
        onClose={handleClose}
        onFilter={onFilter}
        where={where}
      />
    </div>
  </Popover>
);

const styles = theme => ({
  root: {
    width: 230
  },
  header: {
    display: 'flex',
    padding: 12,
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main
  },
  text: {
    alignItems: 'center',
    marginRight: 10
  },
  title: {
    color: theme.palette.text.white
  },
  iconContainer: {
    marginLeft: 'auto'
  },
  icon: {
    color: theme.palette.text.white
  },
  body: {
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    overflowY: 'auto',
    maxHeight: 435,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 250
    }
  }
});

export default withStyles(styles, { withTheme: true })(FilterPopover);
